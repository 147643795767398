package root_pages.aurinko_pages.app.virtual_api.components.dialogs

import com.github.uosis.laminar.webcomponents.material
import com.raquo.airstream.core.Observer
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import common.ui.{AuFormCustomValidator, AuFormField, AuFormSelect, AuFormState, AuFormTextArea}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.mat_components_styles.fixMwcDialogOverflow
import org.scalajs.dom
import portal_router.PortalRouter
import root_pages.aurinko_pages.app.virtual_api.components
import root_pages.aurinko_pages.app.virtual_api.helpers.builder
import service.apis.dynamic_api.DataMapperModels.{MappingModels, MetadataModels}
import service.apis.dynamic_api.DataMapperModels
import wvlet.log.Logger

import scala.reflect.ClassTag
import scala.util.matching.Regex

case object mappingOld {
  private val log = Logger.of[mappingOld.type]

  private val SuggestBox_DefaultRowsLimit: Int = 50

  private def diff[T: ClassTag](a: Iterable[T], b: Iterable[T]): Seq[T] = {
    val aa = a.toSeq
    val bb = b.toSeq
    val res = aa.diff(bb) ++ bb.diff(aa)
    log.debug(s"difference between (${a.mkString(",")}) and (${b.mkString(",")}) equals (${res.mkString(", ")})")
    res
  }

  private def shrinkValidator(signals: Seq[Signal[Boolean]]): Signal[Boolean] =
    Signal.combineSeq[Boolean](signals)
      .map(x => {
        log.info(s"custom validation: ${x.map(_.toString).mkString(", ")}")
        !x.contains(false)
      })

  class Class(clientClassImmutable: Boolean = true) {
    private val model: Var[Option[MappingModels.Class]] = Var(None)
    private val providerClassNameList: Var[List[String]] = Var(Nil)
    private val clientClassNameList: Var[List[String]] = Var(Nil)
    private val providerMetadataName: Var[Option[String]] = Var(None)

    private val changing: EventBus[(String, MappingModels.Class)] = new EventBus()

    private case class MutableModel(src: MappingModels.Class) {
      val providerClass: Var[Option[String]] = Var(src.providerClass)
      val clientClass: Var[String] = Var(src.clientClass)
      val fieldMappings: Var[List[MappingModels.Field]] = Var(src.fieldMappings)
      val virtual: Var[Boolean] = Var(src.virtual.getOrElse(false))
      val relationMappings: Var[List[MappingModels.Relation]] = Var(src.relationMappings)
      val comment: Var[Option[String]] = Var(src.comment)

      def toImmutableModel: MappingModels.Class = src.copy(
        providerClass = this.providerClass.now(),
        clientClass = this.clientClass.now(),
        fieldMappings = this.fieldMappings.now(),
        virtual = Some(this.virtual.now()),
        relationMappings = this.relationMappings.now(),
        comment = this.comment.now(),
      )
    }

    private case class Controller(m: MutableModel) {
      private val commentAuFormEl = AuFormField(material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Comment",
        _.required := false,
        _.value <-- m.comment.signal.map(_.getOrElse[String]("")),
        _ => onInput.mapToValue.map(Some(_)) --> m.comment.writer,
      ))

      private def customValidation: Seq[Signal[Boolean]] = Seq(
        m.clientClass.signal.map(_.trim.nonEmpty),
        m.providerClass.signal
          .map[Boolean](_.getOrElse[String]("").trim.nonEmpty)
          .combineWith(m.virtual.signal)
          .map(x => (x._1 && !x._2) || (!x._1 && x._2)),
      )

      def buttons: HtmlElement = {
        val clientClassAuFormEl: AuFormCustomValidator = AuFormCustomValidator()
        val providerClassAuFormEl: AuFormCustomValidator = AuFormCustomValidator()
        val virtualAuFormEl: AuFormCustomValidator = AuFormCustomValidator()
        val state: AuFormState = AuFormState(
          commentAuFormEl :: Nil,
          Nil,
          Nil,
          Nil,
          clientClassAuFormEl :: providerClassAuFormEl :: virtualAuFormEl :: Nil,
        )

        div(
          m.providerClass.signal
            .combineWith[Option[String]](model.signal.map(_.flatMap(_.providerClass)))
            .map[(Boolean, Option[String])](x => (x._1.getOrElse[String]("") != x._2.getOrElse[String](""), x._1))
            --> Observer.combine(
            providerClassAuFormEl.dirty.writer.contramap((x: (Boolean, Option[String])) => x._1),
            providerClassAuFormEl.valid.writer.contramap((x: (Boolean, Option[String])) => x._1 && x._2.getOrElse[String]("").nonEmpty),
          ),
          m.clientClass.signal
            .combineWith[Option[String]](model.signal.map(_.map(_.clientClass)))
            .map[(Boolean, String)](x => (x._1 != x._2.getOrElse[String](""), x._1))
            --> Observer.combine(
            clientClassAuFormEl.dirty.writer.contramap((x: (Boolean, String)) => x._1),
            clientClassAuFormEl.valid.writer.contramap((x: (Boolean, String)) => x._1 && x._2.nonEmpty),
          ),
          m.virtual.signal
            .combineWith[Boolean](model.signal.map(_.flatMap(_.virtual).getOrElse[Boolean](false)))
            .map[(Boolean, Boolean)](x => (x._1 != x._2, true))
            --> Observer.combine(
            virtualAuFormEl.dirty.writer.contramap((x: (Boolean, Boolean)) => x._1),
            virtualAuFormEl.valid.writer.contramap((x: (Boolean, Boolean)) => x._1 && x._2),
          ),

          cls := "slds-col slds-size--1-of-1 slds-m-top--large",
          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
            div(cls := "slds-col"),
            div(
              cls := "slds-col",

              ButtonsPairComponent[(String, DataMapperModels.MappingModels.Class), dom.MouseEvent](
                primaryDisabled = state.dirtySignal.combineWith(shrinkValidator(customValidation)).map(v => !v._1 || !v._2),
                primaryEffect = () => EventStream.fromValue((model.now().getOrElse(MappingModels.Class()).clientClass, m.toImmutableModel)),
                primaryObserver = Observer.combine(changing.writer, model.writer.contramap((_: (String, MappingModels.Class)) => None)),
                secondaryObserver = model.writer.contramap((_: dom.MouseEvent) => None),
              ).node
            ),
          ),
        )
      }

      val clientClass: HtmlElement = components.SuggestBox[String](
        (txt: String) => clientClassNameList.signal
          .map(x => components.SuggestBox.Seek.containsOrEmpty[String](x, txt).filter(_.nonEmpty)),
        onChange = m.clientClass.writer.contramap((x: Option[String]) => x.getOrElse[String]("")),
        delay = 100,
        limit = SuggestBox_DefaultRowsLimit,
        value = m.clientClass.now() match {
          case cName if cName.nonEmpty => Some(cName)
          case _ => None
        },
        required = Signal.fromValue(true),
      )(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Virtual object",
      )
      val providerClass: HtmlElement = {
        val virtualProviderClassLabel = "Virtual data object"
        components.SuggestBox[String](
          (txt: String) => providerClassNameList.signal
            .combineWith(m.clientClass.signal.map(_.toLowerCase))
            .map(x => components.SuggestBox.Seek.containsOrEmpty[String](x._1, txt)
              .filter(_.nonEmpty)
              .sortWith((aClassName: String, bClassName: String) => {
                var a = aClassName.toLowerCase == x._2
                var b = bClassName.toLowerCase == x._2
                if (a != b)
                  a
                else {
                  a = aClassName.toLowerCase.contains(x._2)
                  b = bClassName.toLowerCase.contains(x._2)
                  if (a != b)
                    a
                  else
                    aClassName < bClassName
                }
              })
            )
            .map(x => (virtualProviderClassLabel :: Nil) ++ x),
          onChange = Observer.combine(
            m.providerClass.writer.contramap((x: (Option[String], Boolean)) => x._1),
            m.virtual.writer.contramap((x: (Option[String], Boolean)) => x._2),
          ).contramap {
            case Some(x) if x == virtualProviderClassLabel => (None, true)
            case x => (x, false)
          },
          delay = 100,
          limit = SuggestBox_DefaultRowsLimit,
          value = m.providerClass.now() match {
            case Some(cName) if cName.nonEmpty => Some(cName)
            case _ if m.virtual.now() => Some(virtualProviderClassLabel)
            case _ => None
          },
          required = m.virtual.signal.map(x => !x),
        )(
          _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
          _.label := "Provider object",
        )
      }

      def comment: material.Textfield.El = commentAuFormEl.node
    }

    def node: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.mapping.class",

      material.Dialog(
        _ => cls := "width--medium",
        _.open <-- model.signal
          .map(_.isDefined)
          .map(x => {
            log.info(s"mapping.class dialog ${if (x) "open" else "close"}")
            x
          }),
        _.onClosing.mapTo(None) --> model.writer,
        _.heading <-- model.signal
          .map(_.flatMap {
            case v if v.clientClass.nonEmpty => Some(v.clientClass)
            case _ => None
          })
          .combineWith(providerMetadataName.signal.map(_.map(_.trim + " ").getOrElse("")))
          .map[String] {
            case (Some(className), providerName) =>
              s"Edit $className ${providerName}mapping object"
            case (_, providerName) =>
              s"Create new ${providerName}mapping object"
          },
        _.hideActions := true,

        _ => child.maybe <-- model.signal.map {
          case Some(m) =>
            val model = MutableModel(m)
            val controller = Controller(model)

            Some(div(
              if (clientClassImmutable && m.clientClass.nonEmpty)
                None
              else
                controller.clientClass,
              controller.providerClass,
              controller.comment,
              controller.buttons,
            ))
          case _ => None
        },

        _ => onMountCallback(fixMwcDialogOverflow)
      ),
    )

    def events: EventStream[(String, MappingModels.Class)] = changing.events

    def providerClasses: Observer[List[MetadataModels.Class]] = providerClassNameList.writer
      .contramap((x: List[MetadataModels.Class]) => x.map(_.name))

    def clientClasses: Observer[List[MetadataModels.Class]] = clientClassNameList.writer
      .contramap((x: List[MetadataModels.Class]) => x.map(_.name))

    def providerName: Observer[String] = providerMetadataName.writer.contramap((x: String) => Some(x))

    def writer: Observer[MappingModels.Class] = model.writer
      .contramap((x: MappingModels.Class) => Some(x))
  }

  // TODO: deprecated
  class Field(
               private val providerName: String,
               private val providerClassList: List[MetadataModels.Class],
               private val providerFieldList: List[MetadataModels.Field],
               private val clientClassList: List[MetadataModels.Class],
               private val clientFieldList: List[MetadataModels.Field],
               private val mappingClientFieldNames: List[String],
               private val mappingProviderFieldNames: List[String],
               private val isVirtualMode: Boolean = false,
               portalRouter: PortalRouter
             ) {
    private val rxName: Regex = "^[A-Za-z][A-Za-z0-9_-]+$".r
    private val model: Var[Option[MappingModels.Field]] = Var(None)
    private val changing: EventBus[(Seq[String], MappingModels.Field)] = new EventBus()

    private case class MutableModel(src: MappingModels.Field) {
      val providerValue: Var[String] = Var(src.rawValue.orElse(src.providerFields.find(_.trim.nonEmpty)).getOrElse[String]("")) // without model, combine raw\json and custom field value, not support field array
      val clientFields: Var[Seq[String]] = Var(src.clientFields)
      val direction: Var[MappingModels.Direction.Direction] = Var(src.direction.getOrElse(MappingModels.Direction.bidirectional))
      val mapper: Var[MappingModels.MappingFunction.Mapper] = Var(src.mapper.getOrElse(MappingModels.MappingFunction.identity))
      val structureMapper: Var[MappingModels.StructureMappingFunction.Mapper] = Var(src.structureMapper.getOrElse(MappingModels.StructureMappingFunction.unknown))
      val providerFieldSelector: Var[MappingModels.ProviderFieldSelector.ProviderFieldSelector] = Var(src.providerFieldSelector.getOrElse(MappingModels.ProviderFieldSelector.none))
      val description: Var[String] = Var(src.description.getOrElse(""))
      val tag: Var[String] = Var(src.tag.getOrElse(""))

      def $isCustomValue: Signal[Boolean] = providerValue.signal.map(v => v.trim.nonEmpty && !rxName.matches(v))

      def $isRawValue: Signal[Boolean] = providerValue.signal.map(v => v.trim.nonEmpty && !rxName.matches(v) && common.CirceStringOps(v).decodeError.isEmpty)

      private def mkProviderFields: Option[Seq[String]] = {
        val v = this.providerValue.now()
        Option.when(v.trim.nonEmpty && mkRawValue.isEmpty)(v :: Nil)
      }

      private def mkRawValue: Option[String] = {
        val v = this.providerValue.now()
        Option.when(v.trim.nonEmpty && common.CirceStringOps(v).decodeError.isEmpty)(v)
      }

      private def mkMapper: Option[MappingModels.MappingFunction.Mapper] = {
        val m = this.mapper.now()
        Option.when(m != MappingModels.MappingFunction.identity && m != MappingModels.MappingFunction.unknown)(m)
      }

      private def mkStructureMapper: Option[MappingModels.StructureMappingFunction.Mapper] = {
        val m = this.structureMapper.now()
        Option.when(m != MappingModels.StructureMappingFunction.direct && m != MappingModels.StructureMappingFunction.unknown)(m)
      }

      private def mkProviderFieldSelector: Option[MappingModels.ProviderFieldSelector.ProviderFieldSelector] = {
        val s = this.providerFieldSelector.now()
        Option.when(s != MappingModels.ProviderFieldSelector.unknown && s != MappingModels.ProviderFieldSelector.none)(s)
      }

      private def mkDescription: Option[String] = {
        val d = this.description.now()
        Option.when(d.trim.nonEmpty)(d)
      }

      private def mkTag: Option[String] = {
        val t = this.tag.now()
        Option.when(t.trim.nonEmpty)(t)
      }

      def toImmutableModel: MappingModels.Field = src.copy(
        providerFields = this.mkProviderFields.getOrElse(Nil),
        clientFields = this.clientFields.now(),
        rawValue = this.mkRawValue,
        direction = Some(this.direction.now()),
        mapper = this.mkMapper,
        structureMapper = this.mkStructureMapper,
        providerFieldSelector = this.mkProviderFieldSelector,
        description = this.mkDescription,
        tag = this.mkTag,
      )

      def toHtml: HtmlElement = div(
        cls := "slds-grid slds-grid--vertical",
        div(cls := "slds-col", s"ProviderFields: ", child.text <-- providerValue.signal),
        div(cls := "slds-col", s"ClientFields: ", child.text <-- clientFields.signal.map[String](_.mkString(", "))),
        div(cls := "slds-col", s"RawValue: ", child.text <-- $isCustomValue.map(_.toString)),
        div(cls := "slds-col", s"Direction: ", child.text <-- direction.signal.map[String](_.label)),
        div(cls := "slds-col", s"Mapper: ", child.text <-- mapper.signal.map[String](_.label)),
        div(cls := "slds-col", s"StructureMapper: ", child.text <-- structureMapper.signal.map[String](_.label)),
        div(cls := "slds-col", s"ProviderFieldSelector: ", child.text <-- providerFieldSelector.signal.map[String](_.label)),
        div(cls := "slds-col", s"Description: ", child.text <-- description.signal),
        div(cls := "slds-col", s"Tag: ", child.text <-- tag.signal),
      )
    }

    private case class Controller(m: MutableModel) {
      val isCustomValue: Var[Boolean] = {
        val v = m.providerValue.now()
        val r = v.trim.nonEmpty && !rxName.matches(v)
        log.info(s"isCustomValue init to $r")
        Var(r)
      } // Custom value: clientWorkPhone.code, clientAddresses[type=home].city, clientTags|key1:*

      private val directionError = Var[String]("")
      private val directionAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Direction",
        _.value <-- m.direction.signal.map(_.value),
        _.required := true,
        _ => children <-- m.$isRawValue
          .map(_ || isVirtualMode)
          .map[Seq[MappingModels.Direction.Direction]] {
            case true => MappingModels.Direction.readOnly :: Nil
            case _ => MappingModels.Direction.all
          }
          .map(_.map(d => material.List.ListItem(
            _.value := d.value,
            _.selected <-- m.direction.signal.map(_.value == d.value),
            _ => d.description,
            _ => composeEvents(onClick.stopPropagation)(_.mapTo(d)) --> m.direction.writer,
          )))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no directions",
              _.selected := false,
              _.value := "no-directions",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
        _ => cls <-- directionError.signal
          .map(_.nonEmpty)
          .map {
            case true => "with_error"
            case _ => ""
          },
        _.helper <-- directionError.signal,
        _.helperPersistent := true,
      ))
      private val providerFieldSelectorAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Field selector",
        _.value <-- m.providerFieldSelector.signal.map(_.value),
        _.required := false,
        _ => children <-- Signal.fromValue(MappingModels.ProviderFieldSelector.all)
          .map(s => (MappingModels.ProviderFieldSelector.none :: Nil) ++ s)
          .map(_.map(d => material.List.ListItem(
            _.value := d.value,
            _.selected <-- m.providerFieldSelector.signal.map(_.value == d.value),
            _ => d.label,
            _ => composeEvents(onClick.stopPropagation)(_
              .mapTo(d)
              .withCurrentValueOf(m.providerFieldSelector.signal)
              .map(x => Option.when(x._2.value != x._1.value)(x._1).getOrElse(MappingModels.ProviderFieldSelector.none)))
              --> m.providerFieldSelector.writer,
          )))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no items",
              _.selected := false,
              _.value := "no-items",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
      ))
      private val mapperValueError = Var[String]("")
      private val mapperAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Mapper",
        _.value <-- m.mapper.signal.map(_.name),
        _.required := false,
        _ => children <-- $clientFields
          .combineWith($providerFields)
          .combineWith(isCustomValue)
          .map {
            case (c, p, false) if c.exists(f => (MetadataModels.DataType.integer :: MetadataModels.DataType.string :: MetadataModels.DataType.stringNotNull :: Nil).contains(f.`type`) && f.role.contains(MetadataModels.FieldRole.id)) =>
              val ignore = MappingModels.MappingFunction.identity ::
                MappingModels.MappingFunction.stringToInteger ::
                MappingModels.MappingFunction.integerToString ::
                MappingModels.MappingFunction.stringToDecimal ::
                MappingModels.MappingFunction.decimalToString ::
                MappingModels.MappingFunction.stringToEmailField ::
                MappingModels.MappingFunction.emailFieldToString ::
                MappingModels.MappingFunction.stringAsDateNormalize ::
                MappingModels.MappingFunction.htmlToString ::
                MappingModels.MappingFunction.splitAtSemicolon ::
                Nil
              MappingModels.MappingFunction.activeFor(Some(p), Some(c)).filter(mapper => !ignore.contains(mapper))
            case (c, p, false) =>
              MappingModels.MappingFunction.activeFor(Some(p), Some(c))
            case _ =>
              MappingModels.MappingFunction.all
          }
          .map(_.sortWith((a, b) =>
            if (a == MappingModels.MappingFunction.identity) true
            else if (b == MappingModels.MappingFunction.identity) false
            else a.label.compareTo(b.label) > 0
          ))
          .map(_.map(mapper => material.List.ListItem(
            _.value := mapper.name,
            _.selected <-- m.mapper.signal.map(_.name == mapper.name),
            _ => mapper.label,
            _ => onClick.mapTo(mapper) --> m.mapper.writer,
          )))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no mappers",
              _.selected := false,
              _.value := "no-mappers",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
        _ => cls <-- mapperValueError.signal
          .map(_.nonEmpty)
          .map {
            case true => "with_error"
            case _ => ""
          },
        _.helper <-- mapperValueError.signal,
        _.helperPersistent := true,
      ))
      private val structureMapperAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Structure mapper",
        _.value <-- m.structureMapper.signal.map(_.value),
        _.required := false,
        _ => children <-- Signal.fromValue(MappingModels.StructureMappingFunction.all
          .sortWith((a, b) =>
            if (a == MappingModels.StructureMappingFunction.direct) true
            else if (b == MappingModels.StructureMappingFunction.direct) false
            else a.label.compareTo(b.label) > 0
          ))
          .map(_.map(mapper => material.List.ListItem(
            _.value := mapper.value,
            _.selected <-- m.mapper.signal.map(_.name == mapper.value),
            _ => mapper.label,
            _ => onClick.mapTo(mapper) --> m.structureMapper.writer,
          )))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no structure mappers",
              _.selected := false,
              _.value := "no-structure-mappers",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
      ))
      private val tagAuFormEl = AuFormField(material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Tag",
        _.required := false,
        _.value <-- m.tag.signal,
        _ => onInput.mapToValue --> m.tag.writer
      ))
      private val descriptionAuFormEl = AuFormTextArea(material.Textarea(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Description",
        _.required := false,
        _.rows := 3,
        _.value <-- m.description.signal,
        _ => onInput.mapToValue --> m.description.writer
      ))

      private val customValueError = Var[String]("")
      private val customValueAuFormEl = AuFormTextArea(material.Textarea(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Custom value",
        _.required <-- isCustomValue.signal,
        _.value <-- m.providerValue.signal
          .combineWith(isCustomValue)
          .map {
            case (txt, true) => txt
            case _ => ""
          },
        _ => onInput.mapToValue --> m.providerValue.writer,
        _ => cls <-- customValueError.signal
          .map(_.nonEmpty)
          .map {
            case true => "with_error"
            case _ => ""
          },
        _.helper <-- customValueError.signal
          .map {
            case txt if txt.nonEmpty => txt
            case _ => "enter valid json or custom field pattern"
          },
        _.helperPersistent := true,
        _.rows := 5,
      ))

      private val clientFieldsError = Var[String]("")
      private val providerFieldsError = Var[String]("")


      private def customValidation: Seq[Signal[Boolean]] = Seq(
        m.clientFields.signal
          .map(_.mkString("").trim.nonEmpty),
        m.clientFields.signal
          .map(_.toSet)
          .combineWith(model.signal.map(_.map(_.clientFields)).map(_.map(m => mappingClientFieldNames.filter(fieldName => !m.contains(fieldName))).getOrElse(mappingClientFieldNames).toSet))
          .map(x => (x._1, x._1.intersect(x._2)))
          .map[String] {
            case (_, intersectFields) if intersectFields.nonEmpty => s"already exists"
            case _ => ""
          }
          .map[Boolean](msg => {
            clientFieldsError.set(msg)
            msg.isEmpty
          }),
        m.providerValue.signal
          .map(_.trim.nonEmpty),
        m.providerValue.signal
          .combineWith(isCustomValue.signal)
          .combineWith(model.signal.map(_.map(_.providerFields)).map(_.map(m => mappingProviderFieldNames.filter(fieldName => !m.contains(fieldName))).getOrElse(mappingProviderFieldNames).toSet))
          .map {
            case (fieldName, false, fieldsNames) if fieldName.nonEmpty && fieldsNames.contains(fieldName) =>
              "already exists"
            case (fieldName, false, _) if fieldName.nonEmpty && !rxName.matches(fieldName) =>
              "incorrect value"
            case (value, true, _) if value.nonEmpty && rxName.matches(value) =>
              "incorrect value"
            case _ => ""
          }
          .map(err => {
            customValueError.set(err)
            providerFieldsError.set(err)
            err.isEmpty
          }),
        m.direction.signal
          .map {
            case MappingModels.Direction.unknown => "incorrect value"
            case _ => ""
          }
          .map(err => {
            directionError.set(err)
            err.isEmpty
          }),
        m.mapper.signal
          .map(_.name == MappingModels.MappingFunction.identity.name)
          .combineWith($clientFields
            .map(x => (x.map(_.`type`), x.map(_.role)))
            .map { case (types, roles) =>
              (
                Option.when(types.nonEmpty && !types.contains(MetadataModels.DataType.unknown))(types),
                roles.filter(_.isDefined).map(_.get),
              )
            })
          .combineWith($providerFields
            .map(x => (x.map(_.`type`), x.map(_.role)))
            .map { case (types, roles) =>
              (
                Option.when(types.nonEmpty && !types.contains(MetadataModels.DataType.unknown))(types),
                roles.filter(_.isDefined).map(_.get),
              )
            })
          .combineWith(m.$isCustomValue)
          .map(x => (
            x._1, // isIdentity
            x._2, // client fields type
            x._4, // provider fields type
            x._6, // isCustomValue
            x._2.isDefined && x._4.isDefined && diff(
              x._2.get.map(_.basisType).toSet,
              x._4.get.map(_.basisType).toSet
            ).nonEmpty, // has diff of types between client and provider fields
            x._3, // client fields role
            x._5, // provider fields role
          ))
          .map {
            case (_, Some(cTypes), Some(pTypes), false, hasDiff, cRoles, pRoles) if cRoles.contains(MetadataModels.FieldRole.id) && !cTypes.contains(MetadataModels.DataType.integer) && hasDiff =>
              log.info(s"unable to convert from ${cRoles.map(_.label).mkString(",")}:${cTypes.mkString(", ")} to ${pRoles.map(_.label).mkString(",")}:${pTypes.mkString(",")} type")
              s"unable to transform ID value of ${cTypes.map(_.label).toSet.mkString(", ")} to ${pTypes.map(_.label).toSet.mkString(", ")}"
            case (isIdentity, Some(cTypes), Some(pTypes), false, hasDiff, _, _) if isIdentity && hasDiff =>
              log.info(s"need to convert from ${cTypes.mkString(",")} to ${pTypes.mkString(",")} type")
              s"transform ${cTypes.map(_.label).toSet.mkString(", ")} to ${pTypes.map(_.label).toSet.mkString(", ")}"
            case (isIdentity, Some(cTypes), Some(pTypes), false, hasDiff, _, _) if !isIdentity && !hasDiff =>
              log.info(s"no need to convert from ${cTypes.mkString(",")} to ${pTypes.mkString(",")} type")
              "no need to convert"
            case _ =>
              ""
          }
          .map(err => {
            mapperValueError.set(err)
            err.isEmpty
          }),
      )

      def $clientFields: Signal[Seq[MetadataModels.Field]] = m.clientFields.signal
        .map {
          case fieldNames if fieldNames.exists(fn => !rxName.matches(fn)) =>
            Nil
          case fieldNames =>
            fieldNames.map(fn => clientFieldList.find(_.name == fn)).filter(_.isDefined).map(_.get)
        }

      def $providerFields: Signal[Seq[MetadataModels.Field]] = m.providerValue.signal
        .map(_ :: Nil)
        .map {
          case fieldNames if fieldNames.exists(fn => !rxName.matches(fn)) =>
            Nil
          case fieldNames =>
            fieldNames.map(fn => providerFieldList.find(_.name == fn)).filter(_.isDefined).map(_.get)
        }

      def buttons: HtmlElement = {
        val clientFieldsAuFormEl: AuFormCustomValidator = AuFormCustomValidator()
        val state: AuFormState = AuFormState(
          tagAuFormEl :: Nil,
          descriptionAuFormEl :: customValueAuFormEl :: Nil,
          Nil,
          directionAuFormEl :: mapperAuFormEl :: structureMapperAuFormEl :: Nil,
          clientFieldsAuFormEl :: Nil
        )

        div(
          m.clientFields.signal
            .combineWith[Seq[String]](model.signal.map(_.map(_.clientFields).getOrElse(Nil)))
            .map[(Boolean, Seq[String])](x => (diff(x._1, x._2).nonEmpty, x._1))
            --> Observer.combine(
            clientFieldsAuFormEl.dirty.writer.contramap((x: (Boolean, Seq[String])) => x._1),
            clientFieldsAuFormEl.valid.writer.contramap((x: (Boolean, Seq[String])) => x._1 && x._2.nonEmpty),
          ),

          cls := "slds-col slds-size--1-of-1 slds-m-top--large",
          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
            div(
              cls := "slds-col",
              //span("IsRawValue: ", child.text <--isRawValue.signal.map(_.toString)),
              //span("IsCustomValue: ", child.text <--isCustomValue.signal.map(_.toString)),
            ),
            div(
              cls := "slds-col",
              ButtonsPairComponent[(Seq[String], DataMapperModels.MappingModels.Field), dom.MouseEvent](
                primaryButtonText = "Apply",
                primaryDisabled = state.dirtySignal
                  .combineWith(shrinkValidator(customValidation))
                  .map(v => !v._1 || !v._2),
                primaryEffect = () => EventStream.fromValue((model.now().getOrElse(MappingModels.Field()).clientFields, m.toImmutableModel)),
                primaryObserver = Observer.combine(changing.writer, model.writer.contramap((_: (Seq[String], MappingModels.Field)) => None)),
                secondaryObserver = model.writer.contramap((_: dom.MouseEvent) => None),
              ).node
            ),
          ),
        )
      }

      def clientFields: HtmlElement = div(
        child.maybe <-- model.signal
          .map(_.map(_.clientFields).getOrElse(Nil))
          .map(_.map(fieldName => clientFieldList.find(_.name == fieldName)).filter(_.isDefined).map(_.get))
          .map(currentClientFields => Some(components.SuggestBox[MetadataModels.Field](
            (txt: String) => Signal.fromValue(clientFieldList)
              .map {
                case items if txt.nonEmpty =>
                  val n = txt.toLowerCase()
                  items.filter(_.name.toLowerCase().contains(n))
                case items =>
                  items
              },
            onChange = m.clientFields.writer
              .contramap((x: Option[MetadataModels.Field]) => x.map[Seq[String]](_.name :: Nil).getOrElse(Nil)),
            delay = 100,
            limit = SuggestBox_DefaultRowsLimit,
            value = currentClientFields.headOption,
            required = Signal.fromValue(true),
            error = clientFieldsError.signal,
            onRender = ($field: Signal[MetadataModels.Field]) => span(
              cls := "slds-grid slds-grid--vertical-align-center",
              child.text <-- $field.map(_.name),
              child <-- $field.map(f => small(
                cls := "light slds-m-left--xx-small",
                builder.metadata.makeFieldTypeEl(Some(f), clientClassList.filter(_.embedded).map(_.name), portalRouter = portalRouter),
              )),
            ),
            onSelect = (field: MetadataModels.Field) => span(field.name),
          )(
            _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
            _.label := "Virtual field",
          )))
      )

      def providerFields: HtmlElement = {
        val customValue: String = "Custom value or pattern"

        div(
          child <-- model.signal
            .map(_.map(_.providerFields).getOrElse(Nil))
            .map(_.map(fieldName => providerFieldList.find(_.name == fieldName)).filter(_.isDefined).map(_.get))
            .map(currentProviderFields => components.SuggestBox[MetadataModels.Field](
              (txt: String) => Signal.fromValue(providerFieldList)
                .combineWith($clientFields.map(x => (
                  x.map(_.name).toSet,
                  x.map(_.`type`).toSet,
                  x.map(_.structure).toSet,
                  x.map(_.name.toLowerCase.replaceAll("[^a-z]", "")).toSet,
                )))
                .map(x => x._1.sortWith((aField: MetadataModels.Field, bField: MetadataModels.Field) => {
                  var a = x._2.contains(aField.name)
                  var b = x._2.contains(bField.name)
                  if (a != b) {
                    a
                  } else {
                    a = x._3.exists(_.basisType == aField.`type`.basisType)
                    b = x._3.exists(_.basisType == bField.`type`.basisType)
                    if (a != b) {
                      a
                    } else {
                      a = x._4.exists(_.name == aField.structure.name)
                      b = x._4.exists(_.name == bField.structure.name)
                      if (a != b) {
                        a
                      } else {
                        a = x._5.contains(aField.name.toLowerCase.replaceAll("[^a-z]", ""))
                        b = x._5.contains(bField.name.toLowerCase.replaceAll("[^a-z]", ""))
                        if (a != b) {
                          a
                        } else {
                          aField.name.toLowerCase < bField.name.toLowerCase
                        }
                      }
                    }
                  }
                }))
                .map(x => (MetadataModels.Field(name = customValue) :: Nil) ++ x)
                .map {
                  case items if txt.nonEmpty =>
                    val n = txt.toLowerCase()
                    items.filter(_.name.toLowerCase().contains(n))
                  case items =>
                    items
                },
              onChange = Observer.combine(
                m.providerValue.writer.contramap((x: (String, Boolean)) => x._1),
                isCustomValue.writer.contramap((x: (String, Boolean)) => x._2),
              ).contramap {
                case Some(value) if value.name == customValue => ("", true)
                case value => (value.map(_.name).getOrElse(""), false)
              },
              delay = 100,
              limit = SuggestBox_DefaultRowsLimit,
              value = Option.when(isCustomValue.now())(MetadataModels.Field(name = customValue)).orElse(currentProviderFields.headOption),
              required = isCustomValue.signal.map(_ == false),
              error = providerFieldsError.signal,
              onRender = ($field: Signal[MetadataModels.Field]) => span(
                cls := "slds-grid slds-grid--vertical-align-center",
                child.text <-- $field.map(_.name),
                child.maybe <-- $field.map {
                  case field if field.name != customValue => Some(small(
                    cls := "light slds-m-left--xx-small",
                    builder.metadata.makeFieldTypeEl(Some(field), providerClassList.filter(_.embedded).map(_.name), portalRouter = portalRouter),
                  ))
                  case _ => None
                },
              ),
              onSelect = (field: MetadataModels.Field) => span(field.name),
            )(
              _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
              _.label := "Provider field",
            ))
        )
      }

      def rawValue: HtmlElement = div(
        cls <-- isCustomValue.signal
          .map {
            case true => ""
            case _ => "hidden"
          },
        customValueAuFormEl.node,
      )

      def direction: HtmlElement = directionAuFormEl.node

      def mapper: HtmlElement = div(
        cls <-- $clientFields
          .map(_.headOption)
          .combineWith($providerFields.map(_.headOption))
          .combineWith(isCustomValue.signal)
          .map(x => x._3 || x._1.forall(f => x._2.forall(_.`type`.basisType != f.`type`.basisType)))
          .map {
            case true => ""
            case _ =>
              m.mapper.set(MappingModels.MappingFunction.identity)
              log.info(s"mapper reset")
              log.info(s"mapper hide")
              "hidden"
          },
        mapperAuFormEl.node,
      )

      def structureMapper: HtmlElement = div(
        cls <-- $clientFields
          .map(_.headOption)
          .combineWith($providerFields.map(_.headOption))
          .combineWith(isCustomValue.signal)
          .map(x => x._3 || x._1.forall(f => x._2.forall(_.structure.name != f.structure.name)))
          .map {
            case true => ""
            case _ =>
              m.structureMapper.set(MappingModels.StructureMappingFunction.unknown)
              log.info(s"structure mapper reset")
              log.info(s"structure mapper hide")
              "hidden"
          },
        structureMapperAuFormEl.node
      )

      def providerFieldSelector: HtmlElement = div(
        cls <-- isCustomValue.signal
          .map {
            case false => ""
            case _ =>
              m.providerFieldSelector.set(MappingModels.ProviderFieldSelector.unknown)
              log.info(s"provider field selector reset")
              log.info(s"provider field selector hide")
              "hidden"
          },
        providerFieldSelectorAuFormEl.node,
      )

      def tag: HtmlElement = tagAuFormEl.node

      def description: HtmlElement = descriptionAuFormEl.node
    }

    private def renderVirtualSection(controller: Controller): HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.mapping.field.virtual_section",
      controller.clientFields,
      controller.tag,
      controller.description,
    )

    private def renderMappingSection(controller: Controller): HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.mapping.field.mapping_section",
      controller.direction,
      controller.mapper,
      controller.structureMapper,
    )

    private def renderProviderSection(controller: Controller): HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.mapping.field.provider_section",
      controller.providerFields,
      controller.rawValue,
      controller.providerFieldSelector,
    )

    def node: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.mapping.field",

      material.Dialog(
        _ => cls := "mwc-dialog",
        _ => styleAttr := "--mdc-dialog-max-width: calc(75vw - 20rem); --mdc-dialog-min-width: calc(75vw - 20rem)",
        _.open <-- model.signal
          .map(_.isDefined)
          .map(x => {
            log.info(s"mapping.field dialog ${if (x) "open" else "close"}")
            x
          }),
        _.onClosing.mapTo(None) --> model.writer,
        _.heading <-- model.signal
          .map(_.flatMap {
            case v if v.direction.nonEmpty && v.clientFields.nonEmpty => Some(v.clientFields.mkString(","))
            case _ => None
          })
          .map[String] {
            case Some(fields) =>
              s"Edit $fields field of $providerName mapping object"
            case _ =>
              s"Create new field of $providerName mapping object"
          },
        _.hideActions := true,
        _ => child.maybe <-- model.signal.map {
          case Some(m) =>
            val model = MutableModel(m)
            val controller = Controller(model)

            if (isVirtualMode || (controller.isCustomValue.now() && common.CirceStringOps(model.providerValue.now()).decodeError.isEmpty)) {
              model.direction.set(MappingModels.Direction.readOnly)
            }

            Some(div(
              cls := "slds-grid slds-grid--vertical",
              div(
                cls := "slds-col slds-grid",
                div(cls := "slds-size--4-of-12", renderVirtualSection(controller)),
                div(cls := "slds-size--4-of-12", div(cls := "slds-m-horizontal--large", renderMappingSection(controller))),
                div(cls := "slds-size--4-of-12", renderProviderSection(controller)),
              ),
              div(cls := "slds-col", controller.buttons),
              // debug information
              div(
                cls := "slds-col slds-m-top--small slds-grid hidden",
                div(cls := "slds-col slds-size--5-of-12", model.toHtml),
                div(cls := "slds-col slds-size--2-of-12"),
                div(
                  cls := "slds-col slds-size--5-of-12",
                  div(
                    cls := "slds-grid slds-grid--vertical",
                    div(cls := "slds-col", s"ProviderFields: ", m.providerFields.mkString(", ")),
                    div(cls := "slds-col", s"ClientFields: ", m.clientFields.mkString(", ")),
                    div(cls := "slds-col", s"RawValue: ", m.rawValue.getOrElse[String]("")),
                    div(cls := "slds-col", s"Direction: ", m.direction.map(_.label).getOrElse[String]("")),
                    div(cls := "slds-col", s"Mapper: ", m.mapper.map(_.label).getOrElse[String]("")),
                    div(cls := "slds-col", s"StructureMapper: ", m.structureMapper.map(_.label).getOrElse[String]("")),
                    div(cls := "slds-col", s"ProviderFieldSelector: ", m.providerFieldSelector.map(_.label).getOrElse[String]("")),
                    div(cls := "slds-col", s"Description: ", m.description.getOrElse[String]("")),
                    div(cls := "slds-col", s"Tag: ", m.tag.getOrElse[String]("")),
                  ),
                )
              ),
            ))
          case _ => None
        },
        _ => onMountCallback(fixMwcDialogOverflow),
      ),
    )

    def events: EventStream[(Seq[String], MappingModels.Field)] = changing.events

    def writer: Observer[MappingModels.Field] = model.writer
      .contramap((x: MappingModels.Field) => Some(x))
  }

  class Relation(clientRelationImmutable: Boolean = true) {
    private val model: Var[Option[MappingModels.Relation]] = Var(None)
    private val metadataClientRelationNameList: Var[List[String]] = Var(Nil)
    private val metadataProviderRelationNameList: Var[List[String]] = Var(Nil)
    private val mappingClientRelationList: Var[List[String]] = Var(Nil)
    private val providerMetadataName: Var[Option[String]] = Var(None)

    private val changing: EventBus[(String, MappingModels.Relation)] = new EventBus()

    private case class MutableModel(src: MappingModels.Relation) {
      val providerRelation: Var[String] = Var(src.providerRelation)
      val clientRelation: Var[String] = Var(src.clientRelation)

      def toImmutableModel: MappingModels.Relation = src.copy(
        providerRelation = this.providerRelation.now(),
        clientRelation = this.clientRelation.now(),
      )
    }

    private case class Controller(m: MutableModel) {
      private def customValidation: Seq[Signal[Boolean]] = Seq(
        m.clientRelation.signal
          .map(_.trim.nonEmpty),
        m.clientRelation.signal
          .combineWith(mappingClientRelationList.signal
            .combineWith(model.signal.map(_.map(_.clientRelation)))
            .map(x => x._2.map(n => x._1.filter(_ != n)).getOrElse(x._1)))
          .map[String] {
            case (newValue, names) if names.contains(newValue) => "already exists"
            //case (newValue, _) if !rxName.matches(newValue) => "incorrect value"
            case _ => ""
          }
          .map[Boolean](x => {
            log.info(s"custom-validation[client-relation]: $x") // todo: rethink and show error
            x.isEmpty
          }),
        m.providerRelation.signal
          .map(_.trim.nonEmpty),
      )

      def buttons: HtmlElement = {
        val clientRelationAuFormEl: AuFormCustomValidator = AuFormCustomValidator()
        val providerRelationAuFormEl: AuFormCustomValidator = AuFormCustomValidator()
        val state: AuFormState = AuFormState(
          Nil,
          Nil,
          Nil,
          Nil,
          clientRelationAuFormEl :: providerRelationAuFormEl :: Nil
        )

        div(
          m.clientRelation.signal
            .withCurrentValueOf[String](model.signal.map(_.map(_.clientRelation).getOrElse[String]("")))
            .map[(Boolean, String)](x => (x._1.nonEmpty && x._1 != x._2, x._1))
            --> Observer.combine(
            clientRelationAuFormEl.dirty.writer.contramap((x: (Boolean, String)) => x._1),
            clientRelationAuFormEl.valid.writer.contramap((x: (Boolean, String)) => x._1 && x._2.nonEmpty),
          ),
          m.providerRelation.signal
            .withCurrentValueOf[String](model.signal.map(_.map(_.providerRelation).getOrElse[String]("")))
            .map[(Boolean, String)](x => (x._1.nonEmpty && x._1 != x._2, x._1))
            --> Observer.combine(
            providerRelationAuFormEl.dirty.writer.contramap((x: (Boolean, String)) => x._1),
            providerRelationAuFormEl.valid.writer.contramap((x: (Boolean, String)) => x._1 && x._2.nonEmpty),
          ),

          cls := "slds-col slds-size--1-of-1 slds-m-top--large",
          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
            div(cls := "slds-col"),
            div(
              cls := "slds-col",

              ButtonsPairComponent[(String, DataMapperModels.MappingModels.Relation), dom.MouseEvent](
                primaryButtonText = "Apply",
                primaryDisabled = state.dirtySignal.combineWith(shrinkValidator(customValidation)).map(v => !v._1 || !v._2),
                primaryEffect = () => EventStream.fromValue((model.now().getOrElse(MappingModels.Relation()).clientRelation, m.toImmutableModel)),
                primaryObserver = Observer.combine(changing.writer, model.writer.contramap((_: (String, MappingModels.Relation)) => None)),
                secondaryObserver = model.writer.contramap((_: dom.MouseEvent) => None),
              ).node
            ),
          ),
        )
      }

      def clientRelation: HtmlElement = components.SuggestBox[String](
        (txt: String) => metadataClientRelationNameList.signal.map(x => components.SuggestBox.Seek.containsOrEmpty[String](x, txt).filter(_.nonEmpty)),
        onChange = m.clientRelation.writer.contramap((x: Option[String]) => x.getOrElse[String]("")),
        delay = 100,
        limit = SuggestBox_DefaultRowsLimit,
        value = if (m.clientRelation.now().nonEmpty) Some(m.clientRelation.now()) else None,
        required = Signal.fromValue(true),
      )(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Virtual relation",
      )

      def providerRelation: HtmlElement = components.SuggestBox[String](
        (txt: String) => metadataProviderRelationNameList.signal.map(x => components.SuggestBox.Seek.containsOrEmpty[String](x, txt).filter(_.nonEmpty)),
        onChange = m.providerRelation.writer.contramap((x: Option[String]) => x.getOrElse[String]("")),
        delay = 100,
        limit = SuggestBox_DefaultRowsLimit,
        value = if (m.providerRelation.now().nonEmpty) Some(m.providerRelation.now()) else None,
        required = Signal.fromValue(true),
      )(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Provider relation",
      )
    }

    def node: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.mapping.relation",

      material.Dialog(
        _ => cls := "width--medium",
        _.open <-- model.signal
          .map(_.isDefined)
          .map(x => {
            log.info(s"mapping.relation dialog ${if (x) "open" else "close"}")
            x
          }),
        _.onClosing.mapTo(None) --> model.writer,
        _.heading <-- model.signal
          .map(_.flatMap {
            case v if v.providerRelation.nonEmpty => Some(v.providerRelation)
            case _ => None
          })
          .combineWith(providerMetadataName.signal.map(_.map(_.trim + " ").getOrElse("")))
          .map[String] {
            case (Some(relations), providerName) =>
              s"Edit $relations relation of ${providerName}mapping object"
            case (_, providerName) =>
              s"Create new relation of ${providerName}mapping object"
          },
        _.hideActions := true,
        _ => child.maybe <-- model.signal.map {
          case Some(m) =>
            val model = MutableModel(m)
            val controller = Controller(model)

            Some(div(
              if (clientRelationImmutable && m.clientRelation.nonEmpty)
                None
              else
                controller.clientRelation,
              controller.providerRelation,
              controller.buttons,
            ))
          case _ => None
        },
        _ => onMountCallback(fixMwcDialogOverflow),
      ),
    )

    def mappingRelations: Observer[List[MappingModels.Relation]] = mappingClientRelationList.writer
      .contramap((x: List[MappingModels.Relation]) => x.map(_.clientRelation))

    def providerRelations: Observer[List[MetadataModels.Relation]] = metadataProviderRelationNameList.writer
      .contramap((x: List[MetadataModels.Relation]) => x.map(_.name))

    def clientRelations: Observer[List[MetadataModels.Relation]] = metadataClientRelationNameList.writer
      .contramap((x: List[MetadataModels.Relation]) => x.map(_.name))

    def providerName: Observer[String] = providerMetadataName.writer.contramap((x: String) => Some(x))

    def events: EventStream[(String, MappingModels.Relation)] = changing.events

    def writer: Observer[MappingModels.Relation] = model.writer
      .contramap((x: MappingModels.Relation) => Some(x))
  }
}


//
