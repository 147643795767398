package root_pages.aurinko_pages.app.dashboard

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common._
import common.airstream_ops.ValueToObservableOps
import common.ui.icons.{IconColor, IconComponent, IconSize, IconType, MaterialIcons}
import org.scalajs.dom
import org.scalajs.dom.html
import portal_router.{AccountsPage, PortalRouter}
import root_pages.aurinko_pages.app.dashboard.ScoreCardColor.ScoreCardColor
import service.exception_handler.UnknownException
import wvlet.log.Logger

class AccountsStatsComponent(appKey: AppKey,
                             stats: Signal[AccountsStats],
                             portalRouter: PortalRouter
                            ) {

  private val log = Logger.of[AccountsStatsComponent]

  def navigateToAccounts(filter: Option[String] = None, accType: Option[String]): Unit =
    portalRouter.navigate(AccountsPage(appKey = appKey, filter = filter, accType = accType))


  val node: ReactiveHtmlElement[html.Div] = div(
    p(
      cls := "title--level-2",
      "Accounts"
    ),
    child <-- stats.map(st =>
      div(
        cls := "slds-grid slds-grid_pull-padded-medium slds-m-top--large",

        ScoreCard(
          topLabel = "Total".signaled,
          value = stats.map(_.total.total.toString),
          clickable = stats.map(_.total.total > 0),
          cssClass = "slds-col slds-m-horizontal--medium".signaled.some,
          handleClick = _ => navigateToAccounts(accType = st match {
            case s if s.personal.inactive + s.managed.inactive == 0 && s.daemon.inactive > 0 => Some("daemon")
            case _ => None
          }),
          color = ScoreCardColor.green
        ).node,
        ScoreCard(
          topLabel ="Inactive".signaled,
          value = stats.map(_.total.inactive.toString),
          clickable = stats.map(_.total.inactive > 0),
          cssClass = "slds-col slds-m-horizontal--medium".signaled.some,
          handleClick = _ => navigateToAccounts(
            filter = Some("inactive"),
            accType = st match {
              case s if s.personal.inactive + s.managed.inactive > 0 && s.daemon.inactive > 0 => Some("both")
              case s if s.personal.inactive + s.managed.inactive == 0 && s.daemon.inactive > 0 => Some("daemon")
              case _ => None
            }),
          color = ScoreCardColor.orange
        ).node,
        ScoreCard(
          topLabel = "Errors".signaled,
          value = stats.map(_.total.hasErrors.toString),
          clickable = stats.map(_.total.hasErrors > 0),
          cssClass = "slds-col slds-m-horizontal--medium".signaled.some,
          handleClick = _ => navigateToAccounts(
            filter = Some("hasErrors"),
            accType = st match {
              case s if s.personal.hasErrors + s.managed.hasErrors > 0 && s.daemon.hasErrors > 0 => Some("both")
              case s if s.personal.hasErrors + s.managed.hasErrors == 0 && s.daemon.hasErrors > 0 => Some("daemon")
              case _ => None
            }),
          color = ScoreCardColor.red
        ).node),
    ))
}

object ScoreCardColor extends Enumeration {
  type ScoreCardColor = Value
  val green, red, orange = Value

  implicit final class ScoreCardColorOps(private val color: ScoreCardColor) extends AnyVal {
    def toCssColor: String = color match {
      case ScoreCardColor.green => "var(--au-green)"
      case ScoreCardColor.red => "var(--au-red)"
      case ScoreCardColor.orange => "var(--au-orange)"
      case _ => throw UnknownException(s"Wrong color: $color")
    }
  }
}

case class ScoreCard(
                      value: Signal[String],
                      topLabel: Signal[String],
                      clickable: Signal[Boolean] = Signal.fromValue(false),
                      handleClick: Unit => Unit = _ => (),
                      cssClass: Option[Signal[String]] = None,
                      color: ScoreCardColor
                    ) {

  val clickBus: EventBus[dom.MouseEvent] = new EventBus[dom.MouseEvent]
  val clickStream: EventStream[Unit] = clickBus.events.sample(clickable).filter(_ == true).mapTo(())

  val node: Div = div(
    cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread border-around--light",
    cssClass.map {className => cls <-- className},

    cls <-- clickable.map { case true => "clickable" case false => "" },
    onClick.mapTo(()) --> Observer[Unit](_ => handleClick()),
    div(
      cls := "slds-grid slds-grid--vertical slds-grid--vertical-align-center slds-m-vertical--small slds-p-vertical--large slds-p-left--medium",
      borderLeft := s"${color.toCssColor} solid 3px",

      p(
        cls := "text-small",
        child.text <-- topLabel),
      p(
        cls := "mdc-typography--headline4",
        child.text <-- value
      )
    ),
    IconComponent(
      icon = MaterialIcons.arrowRight,
      color = IconColor.grey,
      size = IconSize.medium,
      iconType = IconType.outlined
    )
  )
}
