package root_pages.aurinko_pages.app.virtual_api.components.dialogs

import com.github.uosis.laminar.webcomponents.material
import com.raquo.airstream.core.Observer
import com.raquo.laminar.api.L._
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.element_binders.DialogModifying
import common.ui.mat_components_styles.fixMwcDialogOverflow
import org.scalajs.dom
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import wvlet.log.Logger

case object general {
  private val log = Logger.of[general.type]

  class Confirmation(portalApi: PortalApi,
                     portalState: PortalState
                    ) {
    case class Handle[T](
                          title: String,
                          message: String,
                          model: T,
                          onSuccess: Observer[T],
                          onClose: Observer[T] = Observer.empty,
                          primaryButtonText: String = "Delete",
                          primaryButtonCls: String = "red",
                        )

    private val visibleBus: EventBus[Option[Handle[Any]]] = new EventBus[Option[Handle[Any]]]

    def node: HtmlElement =
      div(
        common.ui.Attribute.Selector := "data_mapper.components.dialogs.general.confirmation",

        child.maybe <-- visibleBus.events.map {
          case Some(m) =>

            Some(div(
              visibleBus.events
                .filter(x => x.isEmpty)
                .mapTo(m.model)
                --> m.onClose,

              material.Dialog(
                _ => cls := "width--medium",
                _.open := true,
                _.onClosing
                  .mapTo[Option[Handle[Any]]](None)
                  --> visibleBus.writer,
                _.heading := m.title,

                _.slots.default(p(m.message)),

                _.slots.primaryAction(
                  div(
                    cls := "slds-m-bottom--small slds-m-right--small",
                    ButtonsPairComponent[Option[Handle[Any]], dom.MouseEvent](
                      primaryButtonText = m.primaryButtonText,
                      primaryCls = m.primaryButtonCls,
                      primaryEffect = () => EventStream.fromValue(None),
                      primaryObserver = Observer.combine(
                        m.onSuccess.contramap((_: Option[Handle[Any]]) => {
                          log.info(s"confirmed: $title")
                          m.model
                        }),
                        visibleBus.writer,
                      ),
                      secondaryObserver = visibleBus.writer.contramap((_: dom.MouseEvent) => None),
                    ).node
                  )
                ),
                _ => onMountCallback(fixMwcDialogOverflow)
              ).withPing(portalApi)
                .closeOnSessionExpired(portalState.Session.$sessionExpiredEvents),
            ))
          case _ =>
            None
        },
      )

    def mkHandle[T](
                     title: String,
                     message: String,
                     model: T,
                     onSuccess: Observer[T],
                     onClose: Observer[T] = Observer.empty,
                     primaryButtonText: String = "Delete",
                     primaryButtonCls: String = "red",
                   ): Handle[T] =
      Handle[T](
        title = title,
        message = message,
        model = model,
        onSuccess = onSuccess,
        onClose = onClose,
        primaryButtonText = primaryButtonText,
        primaryButtonCls = primaryButtonCls,
      )

    def writer[T]: Observer[Handle[T]] =
      visibleBus.writer
        .contramap((m: Handle[Any]) => Some(m))
        .contramap((m: Handle[T]) => m.asInstanceOf[Handle[Any]])
  }
}
