package root_pages.aurinko_pages.app.sync.widgets

import com.github.uosis.laminar.webcomponents.material.Icon
import com.raquo.laminar.api.L._
import common.SortOptionState
import common.SortOptionState.SortOptionState
import org.scalajs.dom

case object SortableTableColumnHeader {
  def apply(label: String, onSorted: Observer[dom.MouseEvent], state: Signal[SortOptionState] ,cssClass: String = ""): Div = {
    div(
//      cls := cssClass + " slds-grid slds-grid--vertical-align--end clickable",
      cls := cssClass + " slds-grid slds-grid--vertical-align-center clickable",
      cls <-- state.signal.map { case SortOptionState.none => "gray" case _ => "secondary" },

      span(label, cls := "slds-m-right--small"),

      Icon(
        _ => "arrow_drop_down",
        _ => cls := "expand-icon",
//        _ => cls <-- state.signal.map { case SortOptionState.none => "gray" case _ => "secondary" },
        _ => cls <-- state.signal.map {
          case SortOptionState.descending => "rotated"
          case _ => ""
        },
      ),

      onClick --> onSorted

    )
  }
}

