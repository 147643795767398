package root_pages.aurinko_pages.app.virtual_api

import com.raquo.laminar.api.L._
import com.raquo.waypoint.SplitRender
import portal_router.VirtualAPIsPage

// TODO: rethink
case object VirtualApiRoot {
  def apply($page: Signal[VirtualAPIsPage],
             childrenRender: Signal[VirtualAPIsPage] => SplitRender[VirtualAPIsPage, HtmlElement]): Div =

    div(
      child <-- childrenRender($page).$view
    )

}
