package root_pages.aurinko_pages.app.sync.widgets

import com.github.uosis.laminar.webcomponents.material.{Icon, Menu}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.AccountSummary
import org.scalajs.dom.html

case object ServicesGrid {
  private def render(
                      @annotation.unused accountId: Int,
                      initial: AccountSummary,
                      $summary: Signal[AccountSummary],
                    ): ReactiveHtmlElement[html.Div] =
    div(
      cls := "table-row border-bottom--light",
      span(cls := "slds-size--1-of-12", child.text <-- $summary.map(_.accountId)),
      span(
        cls := "slds-size--3-of-12",
        child.text <-- $summary.map(_.accountName.getOrElse("")),
        child.text <-- $summary.map(_.source).map { case true => " (source)" case false => "" },
      ),
      span(cls := "slds-size--3-of-12", child.text <-- $summary.map(_.foreignState.getOrElse("").toLowerCase.capitalize)),
      span(cls := "slds-size--4-of-12", child.text <-- $summary.map(_.foreignId.getOrElse(""))),
      child.maybe <-- $summary.map(_.pushError).map {
        case None => None
        case Some(error) =>
          val showError = Var(false)
          Some(div(
            cls := "slds-size--1-of-12 slds-is-relative slds-grid slds-grid--align-end",
            Icon(
              _ => "feedback",
              _ => cls := "medium secondary clickable light",
              _ => onClick.mapTo(!showError.now) --> showError,
            ),
            Menu(
              _.open <-- showError,
              _.onClosed.mapTo(false) --> showError.writer,
              _ => p(
                cls := "slds-p-horizontal--large",
                styleAttr := "white-space: normal; word-break: break-all;",
                error.errorMessage,
              ),
              _ => cls := "info-popup",
            )
          ))
      }
    )


  def apply($accountSummary: Signal[List[AccountSummary]]): Div =
    div(
      common.ui.Attribute.Selector := "sync.widgets.services_grid",

      child.maybe <-- $accountSummary.map(v => v.nonEmpty).map {
        case true => Some(div(
          cls := "slds-grid slds-grid--vertical",
          p(cls := "gray slds-size--1-of-8  au-truncate slds-m-top--x-large", "Services"),
          div(
            cls := "data-table slds-size--7-of-8",
            div(
              cls := "table-header slds-m-top--xx-small",
              span(cls := "slds-size--1-of-12", "Id"),
              span(cls := "slds-size--3-of-12", "Account name"),
              span(cls := "slds-size--3-of-12", "State"),
              span(cls := "slds-size--4-of-12", "Foreign id"),
            ),
            children <-- $accountSummary.split(_.accountId)(render),
          )
        ))
        case false => None
      }
    )
}
