package root_pages.auth_pages.not_verified

import com.raquo.laminar.api.L._
import com.raquo.waypoint.Router
import common.PortalUser
import common.ui.buttons_pair.ButtonsPairComponent
import org.scalajs.dom
import root_pages.auth_pages.AuthPagesLogo
import portal_router.{BufferPage, Page}
import service.apis.portal_api.PortalApi
import service.ui.messages.Messages

class NotVerifiedComponent(portalApi: PortalApi, messagesService: Messages, portalRouter: Router[Page]) {

  def skip(): Unit = portalRouter.pushState(BufferPage)

  val $user: EventStream[PortalUser] = portalApi.getMe

  val node: Div = div(
    cls := "slds-align--absolute-center auth-page",
    div(
      cls := "form wide",
      AuthPagesLogo,

          $user --> Observer[PortalUser](onNext = u => {
            if (u.verified) skip()
          }),


      child <-- $user.map(u =>
        div(
          p(cls := "title--level-1 slds-m-top--xx-large slds-m-bottom--small", "Thank you!"),

          p(cls := "slds-m-bottom--small", s"We just sent an email to ${u.email}."),

          p(
            cls := "slds-m-bottom--large",

            "Please click the link in the email to verify your account! If you have not received the verification email," +
            " please click the link below to receive a new verification email. Once you have verified your email address" +
            " you can start using Aurinko API platform."

          ),

          ButtonsPairComponent[dom.MouseEvent, Unit](
            primaryButtonText = "Skip",
            secondaryButtonText = "Send verification email",
            primaryObserver = Observer[dom.MouseEvent](onNext = _ => skip()),
            secondaryObserver = Observer[Unit]
              (onNext = _ => messagesService.addMessage("Please check your mailbox.")),
            secondaryEffect = portalApi.startEmailVerification
          ).node
        )
      )
    )
  )
}
