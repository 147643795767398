package root_pages.loading

import com.raquo.laminar.api.L._
import com.raquo.waypoint.Router
import common.PortalUser
import portal_router.{Page, TeamAppsPage}
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import wvlet.log.Logger

class BufferPageComponent(portalApi: PortalApi,
                          portalState: PortalState,
                          portalRouter: Router[Page]
                         ) {

  private val log = Logger.of[BufferPageComponent]

  private val $me = portalApi.getMe

  private val eventBinders = List(
    onMountCallback((_: MountContext[Div]) => {
      portalState.updateTeamId(None)
      portalState.updateApp(None)
    }),

    $me --> Observer[PortalUser](me => {
      portalState.updateMe(me)

      val team = me.teams.find(_.primary)
        .orElse(me.teams.find(_.apps.nonEmpty))
        .orElse(me.teams.headOption)

      team.foreach(t => portalRouter.pushState(TeamAppsPage(t.id)))


    })


  )

  def node: Div = div().amend(eventBinders)

}

