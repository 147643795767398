package root_pages.aurinko_pages.app.accounts

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Button, Checkbox, Dialog, Formfield, Radio, Select, Textfield}
import com.raquo.laminar.api.L._
import common._
import common.airstream_ops._
import common.ui.branded.Buttons
import common.ui.mat_components_styles.setStyles
import common.ui.notifications.LocalMessagesView
import io.circe.generic.auto.{exportDecoder, exportEncoder}
import io.circe.syntax.EncoderOps
import org.scalajs.dom
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import service.scroll_ops.ScrollOps
import wvlet.log.Logger

import scala.collection.mutable.ListBuffer

case class AccountAuthorization(
                                 appKey: AppKey,
                                 initialAccount: Option[PortalAccount] = None,
                                 daemonMode: Signal[Boolean],
                                 onSuccess: Observer[AuthAccountResponse],
                                 visible: Var[Boolean],
                                 portalApi: PortalApi,
                                 portalState: PortalState,
                                 documentScrollOps: ScrollOps,
                                 apiOrigin: String
                               ) {
  private val log = Logger.of[AccountAuthorization]

  object AuthPermission extends Enumeration with JsonEnum {
    case class Val(name: String, label: String) extends super.Val(name)

    type AuthPermission = Val
    type EnumValue = Val

    def apply(name: String): AuthPermission = fromString(name)

    val read: AuthPermission = Val("Read", "Read")
    val readWrite: AuthPermission = Val("ReadWrite", "Read & write")
    val drafts: AuthPermission = Val("Drafts", "Drafts")
    val send: AuthPermission = Val("Send", "Send")

    override protected def fromString(str: String): EnumValue =
      try {
        withName(str)
      } catch {
        case _: Throwable =>
          log.warn(s"undefined auth permission: $str")
          Val(str, str)
      }
  }

  object AuthPermissionGroup extends Enumeration with JsonEnum {
    case class Val(name: String, label: String) extends super.Val(name)

    type AuthPermissionGroup = Val
    type EnumValue = Val

    def apply(name: String): AuthPermissionGroup = fromString(name)

    val calendar: AuthPermissionGroup = Val("Calendar", "Calendar")
    val mail: AuthPermissionGroup = Val("Mail", "Mail")
    val contacts: AuthPermissionGroup = Val("Contacts", "Contacts")
    val tasks: AuthPermissionGroup = Val("Tasks", "Tasks")
    val chat: AuthPermissionGroup = Val("Chat", "Chat")

    override protected def fromString(str: String): EnumValue =
      try {
        withName(str)
      } catch {
        case _: Throwable =>
          log.warn(s"undefined auth permission group: $str")
          Val(str, str)
      }
  }

  val daemon: Var[Boolean] = Var(false)
  val appRegs: Var[List[PortalAppRegistration]] = Var(Nil)

  val $serviceRegTypes: Signal[Seq[ServiceType]] = portalState.$app
    .map(_.allowedServiceTypes.filter(_.isDaemon))
    .combineWith(appRegs.signal.map(_.map(_.serviceType).filter(_.isDaemon).toSet))
    .logINFO(log)(x => s"ServiceRegTypes: ${x._1.map(st => s"${st.value}:${st.authOptions.exists(_.hasAppRegistration).toString}").mkString(", ")} | ${x._2.map(_.value).mkString(", ")}")
    .map {
      case (availableServiceTypes, appRegs) =>
        availableServiceTypes.filter(_.authOptions.exists(!_.hasAppRegistration)) ++
          availableServiceTypes.intersect(appRegs)
      case _ =>
        Set.empty
    }
    .map(_.toSeq.sortBy(_.weight))

  private val $userRegTypes: Signal[Seq[ServiceType]] = portalState.$app
    .map(_.allowedServiceTypes.filter(_.isPersonal))
    .logINFO(log)(x => s"UserRegTypes: ${x.map(_.value).mkString(", ")}")
    .map(_.toSeq.sortBy(_.weight))

  // model overview
  private val selectedType: Var[Option[ServiceType]] = Var(initialAccount.map(_.serviceType))
  private val clientOrgId: Var[String] = Var(initialAccount.flatMap(_.clientOrgId).getOrElse(""))
  private val selectedScopes: Var[List[String]] = Var(initialAccount.flatMap(_.tokens).map(_.sortBy(_.id).lastOption).flatMap(_.flatMap(_.scopes)).getOrElse(Nil))

  private def checkScopes(scopes: List[String], st: ServiceType, daemon: Boolean): List[String] =
    scopes.filterNot {
      scope =>
        val sName = scope.split("\\.").headOption.getOrElse[String](scope)
        val sPermission = scope.split("\\.").lastOption.getOrElse[String](scope)

        !st.authScopes.exists(s => s.name == sName && s.permission == sPermission) ||
          (sPermission == AuthPermission.readWrite.name && daemon)
    }

  private val askPermissions: Signal[Boolean] = selectedType.signal.map {
    case Some(t) =>
      log.info(s"askPermissions type: $t")
      t.authScopes.nonEmpty
    case None =>
      log.info(s"askPermissions type: None")
      false
  }

  private def onScopeChange(
                             scopeGroup: AuthPermissionGroup.AuthPermissionGroup,
                             scopePermission: AuthPermission.AuthPermission,
                             scopeValue: String,
                           )(checked: Boolean): Unit = {
    val readWriteScopeString = s"${scopeGroup.name}.${AuthPermission.readWrite.name}"
    val readScopeString = s"${scopeGroup.name}.${AuthPermission.read.name}"

    val scopesList = selectedScopes.now.to(ListBuffer)

    if (checked && !scopesList.contains(scopeValue)) {
      scopesList += scopeValue

      if (scopesList.contains(readWriteScopeString) && scopePermission == AuthPermission.read) {
        scopesList -= readWriteScopeString
      }
      if (scopesList.contains(readScopeString) && scopePermission == AuthPermission.readWrite) {
        scopesList -= readScopeString
      }
    } else if (!checked && scopesList.contains(scopeValue)) {
      scopesList -= scopeValue
    }

    selectedScopes.set(scopesList.toList)
  }

  private val errorMessage: Var[Option[String]] = Var(None)

  val node: Dialog.El = Dialog(
    // load app regs
    _ => portalState.$appRegs
      .flatMap {
        case Some(regs) if regs.nonEmpty => EventStream.fromValue[List[PortalAppRegistration]](regs)
        case _ => portalApi.requestAppRegs(appKey).tap(portalState.updateAppRegs)
      }
      --> appRegs.writer,

    _ => daemonMode.changes --> daemon,
    _ => visible.signal.changes.sample(daemonMode) --> Observer[Boolean](onNext = isDaemonMode => {
      errorMessage.set(None)

      println("reset account authorization dialog model")

      // reset selection of selected type when switching between personal and daemon
      if (initialAccount.isEmpty) {
        selectedType.set(None)
        selectedScopes.set(Nil)
      }

      daemon.set(isDaemonMode)
      clientOrgId.set("")
    }),

    _ => selectedType.signal
      .combineWith(daemon.signal)
      .changes
      .filter(_._1.nonEmpty)
      .mapTo(checkScopes(
        selectedScopes.now,
        selectedType.now.getOrElse(throw EmptyFieldException("selectedType")),
        daemon.now
      )) --> selectedScopes,

    _ => cls := "width--small",
    _.heading := "Authentication",
    _.open <-- visible.signal,
    _.onClosing.mapTo(false) --> visible,
    //    _.slots.default(
    _ => child <-- visible.signal.changes.filter(_ == true)
      .sample(portalState.$me.map(_.verified))
      .map {
        case false => p("Please verify your email.")
        case true => div(
          LocalMessagesView(errorMessage.signal).amend(cls := "slds-m-bottom--medium"),
          div(
            // cls := "slds-size--1-of-2",
            Select(
              _ => cls := "slds-size--1-of-1 menu-unset-min-width",
              _.outlined := true,
              _.label := "Account type",
              _.value := initialAccount.map(_.serviceType.label).getOrElse[String](""),
              _.required := true,
              _.disabled := initialAccount.isDefined,
              _ => children <-- daemon.signal
                .flatMap {
                  case true => $serviceRegTypes
                  case _ => $userRegTypes
                }
                .combineWith(portalState.$app.map(_.internalServicesEnabled))
                .map { case (list, internalServicesEnabled) => list.filterNot(_ == ServiceType.ews365 && !internalServicesEnabled) }
                .map {
                  serviceTypes =>
                    // TODO: rethink
                    if (initialAccount.isEmpty && selectedType.now.isDefined && !serviceTypes.contains(selectedType.now.get)) {
                      selectedType.set(None)
                    }
                    serviceTypes
                      .map {
                        serviceType =>
                          ListItem(
                            _.value := serviceType.label,
                            _ => serviceType.label,
                            _.selected <-- selectedType.signal.map(_.contains(serviceType)),
                            _ => onClick.mapTo(serviceType.some) --> selectedType
                          )
                      }
                },
              _ => onMountCallback(setStyles(".mdc-select__menu" :: ".mdc-list" :: "ul.mdc-list" :: Nil, "max-height" -> "150px" :: Nil))
            ),
          ),
          div(
            cls := "slds-m-vertical--large",
            Textfield(
              _.outlined := true,
              _ => cls := "slds-size--1-of-1",
              _.label := "Client org id",
              _.value <-- clientOrgId,
              _ => onInput.mapToValue --> clientOrgId
            )),
          child.maybe <-- selectedType.signal
            .combineWith(daemon.signal)
            .map {
              case (Some(serviceType), isDaemon) =>
                serviceType
                  .authScopes
                  .groupBy(s => AuthPermissionGroup(s.name))
                  .map(s => (s._1, s._2.map(s => (AuthPermission(s.permission), s.value))))
                  .map {
                    case (AuthPermissionGroup.mail, permissions) if isDaemon =>
                      // daemon is not use ReadWrite permission into MAIL
                      (AuthPermissionGroup.mail, permissions.filter(_._1 != AuthPermission.readWrite))
                    case (AuthPermissionGroup.mail, permissions) if serviceType == ServiceType.smtp =>
                      (AuthPermissionGroup.mail, permissions.filter(_._1 == AuthPermission.send))
                    case scopes =>
                      scopes
                  }
                  .toSeq
                  .sortBy(_._1)
              case _ => Seq.empty
            }
            .map(_.flatMap {
              case (scopeGroup, scopePermissions) =>
                div(
                  cls := "slds-grid slds-grid--vertical-align-center",
                  p(cls := "slds-size--1-of-4 gray", scopeGroup.name),
                  scopePermissions
                    .sortBy(_._1)
                    .map {
                      case (scopePermission, scopeValue) =>
                        div(
                          cls := "slds-grid slds-grid--vertical-align-center slds-m-right--x-large",
                          Formfield(
                            _.label := scopePermission.label,
                            _.slots.default(
                              Checkbox(
                                _.checked <-- selectedScopes.signal.map(_.contains(scopeValue)),
                                _.onChange.mapToChecked --> Observer[Boolean](onNext = {
                                  onScopeChange(scopeGroup, scopePermission, scopeValue)
                                }
                                ),
                              )
                            )
                          ),
                        )
                    },
                ).some
              case _ =>
                Option.empty
            })
            .map {
              case items if items.nonEmpty =>
                div(
                  p("Request API permissions", cls := "gray slds-p-vertical--medium"),
                  items
                ).some
              case _ =>
                Option.empty
            },
          p("Specify type of your account", cls := "gray slds-p-top--small"),
          div(
            cls := "slds-grid slds-grid--vertical-align-center",
            Formfield(
              _ => cls := "slds-m-right--x-large",
              _.label := "User",
              _.slots.default(Radio(
                _.checked <-- daemon.signal.map(!_),
                _.onChange.mapToChecked.map(!_) --> daemon,
                _.disabled := initialAccount.isDefined,
                _.disabled <-- selectedType.signal
                  .combineWith(daemon.signal)
                  .combineWith($serviceRegTypes)
                  .combineWith($userRegTypes)
                  .map {
                    case (t, daem, _, serviceRegTypes) if daem && t.isDefined => !serviceRegTypes.contains(t.get) || initialAccount.isDefined
                    case (t, _, userRegTypes, _) if t.isDefined => !userRegTypes.contains(t.get) || initialAccount.isDefined
                    case _ => initialAccount.isDefined
                  }
              ))
            ),

            Formfield(
              _.label := "Service",

              _.slots.default(Radio(
                _.checked <-- daemon.signal,
                _.onChange.mapToChecked --> daemon,
                _.disabled := initialAccount.isDefined,
                _.disabled <-- selectedType.signal
                  .combineWith(daemon.signal)
                  .combineWith($serviceRegTypes)
                  .combineWith($userRegTypes)
                  .map {
                    case (t, daem, _, serviceRegTypes) if daem && t.isDefined => !serviceRegTypes.contains(t.get)
                    case (t, _, userRegTypes, _) if t.isDefined => !userRegTypes.contains(t.get)
                    case _ => false
                  }
              ))
            ),
          ),
        )
      },
    _.slots.primaryAction(
      div(
        cls := "slds-grid slds-grid--vertical-align-center",
        Button(
          _ => cls := "slds-m-right--xx-small",
          _.label := "Cancel",
          _ => onClick.mapTo(false) --> visible
        ),
        child <-- selectedType.signal.
          combineWith(daemon.signal)
          .map {
            case (Some(ServiceType.office365) | Some(ServiceType.msTeamsBot), _) =>
              Buttons.officeOauthButtonLight(
                selectedType.signal.map(_.isEmpty)
                  .combineWith(askPermissions)
                  .combineWith(selectedScopes.signal.map(_.isEmpty))
                  .combineWith(portalState.$me.map(_.verified))
                  .map { case (typesIdEmpty, askPerm, scopesIsEmpty, verified) => typesIdEmpty || askPerm && scopesIsEmpty || !verified }
              )
            case (Some(ServiceType.google), false) =>
              Buttons.googleOAuthButtonLight(
                selectedType.signal.map(_.isEmpty)
                  .combineWith(askPermissions)
                  .combineWith(selectedScopes.signal.map(_.isEmpty))
                  .combineWith(portalState.$me.map(_.verified))
                  .map { case (typesIdEmpty, askPerm, scopesIsEmpty, verified) => typesIdEmpty || askPerm && scopesIsEmpty || !verified }
              )
            case _ =>
              Button(
                _.label <-- selectedType.signal.combineWith(daemon.signal).map {
                  case (Some(ServiceType.office365) | Some(ServiceType.msTeamsBot), _) => ""
                  case (Some(ServiceType.google), false) => ""
                  case _ => "Auth"
                },
                _.disabled <-- selectedType.signal
                  .combineWith(askPermissions)
                  .combineWith(selectedScopes)
                  .combineWith(portalState.$me.map(_.verified))
                  .map { case (typ, askPerm, scopes, verified) => typ.isEmpty || askPerm && scopes.isEmpty || !verified },
                _ => child.maybe <-- selectedType.signal.combineWith(daemon.signal).map {
                  case (t, _) if t.isDefined && (t.get == ServiceType.office365 || t.get == ServiceType.msTeamsBot) => Some(img(src := "microsoft.png", slot := "icon"))
                  case (t, d) if t.isDefined && t.get == ServiceType.google && !d => Some(img(src := "google_oauth_button.png", slot := "icon"))
                  case _ => None
                },
                _ => cls <-- selectedType.signal.combineWith(daemon.signal).map {
                  case (t, _) if t.isDefined && (t.get == ServiceType.office365 || t.get == ServiceType.msTeamsBot) => "white"
                  case (t, d) if t.isDefined && t.get == ServiceType.google && !d => "white"
                  case _ => "secondary"
                },
                _.raised := true,
              )
          }.map(_.amend(composeEvents(
            onClick.mapTo({
              val authUrl = portalApi.accountAuthUrl(
                appKey,
                selectedType.now.getOrElse(throw EmptyFieldException("selectedType")),
                daemon.now,
                selectedScopes.now,
                clientOrgId.now,
                initialAccount.map(_.id)
              )
              println(s"auth to $authUrl")
              dom.window.open(
                authUrl,
                "_blank",
                "width=800,height=600,resizable=1,scrollbars=1"
              )
            })
          )(_ => windowEvents.onMessage
            .filter(_.data.isInstanceOf[String])
            .map(_.data)
            .map(_.asInstanceOf[String])
            .debugLog()
            .filter(r => r.startsWith("{") && r.endsWith("}"))
            .map(_.safelyDecodeAs[AuthAccountResponse])
            .collect {
              case Some(resp) => resp
            }
          ) --> onSuccess.contramap {
            response: AuthAccountResponse => {
              if (response.status == "success") {
                response.isDaemon = Some(daemon.now)
                visible.set(false)
                documentScrollOps.scrollToTop()
                response
              } else {
                log.warn(s"Auhtorization failed. ${response.asJson}")
                errorMessage.set(response.details)
                response
              }
            }
          }))
      )
    ),
  )
}
