package root_pages.aurinko_pages.app.settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Dialog, Textfield}
import com.raquo.airstream.core.Observer
import com.raquo.laminar.api.L._
import common.ServiceType
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalOptionOps}
import common.forms.FormsLocalExceptionHandling
import common.{AppRegistrationModel, PingTools, PortalAppRegistration}
import common.ui.{AuFormField, AuFormState, confirmDeletionPopup}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.icons.TrashIcon
import org.scalajs.dom
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import wvlet.log.Logger

case class FishbowlOAuth (
                           $appReg: Signal[Option[PortalAppRegistration]],
                           componentHeader: String,
                           bus: EventBus[Unit],
                           serviceType: ServiceType,
                           portalApi: PortalApi,
                           portalState: PortalState,
                           $initialExpand: Signal[Boolean],
                           isDaemon: Boolean = false
                         ) extends AppRegistrationComponent {
  private val showDeletePopup: Var[Boolean] = Var(false)

  private def serverUrlField(model: AppRegistrationModel): AuFormField = AuFormField(Textfield(
    _ => cls := "width-medium slds-m-bottom_medium",
    _.outlined := true,
    _.label := "Server URL",
    _.required <-- $appReg.map {
      case Some(_) => false
      case None => true
    },
    _.pattern := "^(https?:\\/\\/.+\\.\\w{2,}(?::\\d{2,})?)$",
    _.value <-- model.intermediateCallbackUrl,
    _ => onInput.mapToValue --> model.intermediateCallbackUrl
  ))

  private def appIdField(model: AppRegistrationModel): AuFormField = AuFormField(Textfield(
    _ => cls := "width-medium slds-m-bottom_medium",
    _.outlined := true,
    _.label := "Application Id",
    _.required <-- $appReg.map {
      case Some(_) => false
      case None => true
    },
    _.`type` := "number",
    _.value <-- model.clientId.signal,
    _ => onInput.mapToValue --> model.clientId
  ))

  private def appNameField(model: AppRegistrationModel): AuFormField = AuFormField(Textfield(
    _ => cls := "width-medium",
    _.outlined := true,
    _.label := "Application Name",
    _.required <-- $appReg.map {
      case Some(_) => false
      case None => true
    },
    _.value <-- model.topicName,
    _ => onInput.mapToValue --> model.topicName

  ))

  val deletePopup: Dialog.El = confirmDeletionPopup(
    onConfirm = Observer[Unit](onNext = _ => {
      bus.emit()
      showDeletePopup.set(false)
    }),
    onCancel = showDeletePopup.writer.contramap((_: Unit) => false),
    heading = "Delete registration",
    $visible = showDeletePopup.signal,
    onClose = showDeletePopup.writer.contramap((_: Unit) => false),
    onConfirmEventTransfer = () => EventStream.fromValue(())
      .sample($appReg)
      .map(_.get)
      .withCurrentValueOf(portalState.$teamApp.map(_.appKey))
      .flatMap(t => portalApi.deleteAppReg(t._2, t._1))
  ).amend(PingTools.dialogBinders(portalApi))

  val node: Div =
    div(
      ExpansionPanelComponent(
        header =
          p(
            componentHeader,
            cls := "title--level-2",
            cls <-- $appReg.map(x => if (x.isEmpty) "light" else "")
          ),
        body = Some(
          div(cls := "slds-p-bottom_medium",
            child.maybe <-- errorView,
            $appReg.signal.map{
              case Some(v) => v.toEditModel.some
              case None => AppRegistrationModel(serviceType = serviceType, clientSecret = Var("-")).some
            } --> editModel,

            children <-- editModel.signal.nestedMap(model => {

              val serverUrl = serverUrlField(model)
              val appName = appNameField(model)
              val appId = appIdField(model)

              formState.set(AuFormState(serverUrl :: appId :: appName :: Nil).some)

              deletePopup ::
                div(cls := "slds-m-bottom_medium", "OAuth keys used to authenticate users to your app.") ::
                div(cls := "slds-grid slds-grid_vertical-align-end", serverUrl.node) ::
                div(cls := "slds-grid slds-grid_vertical-align-end", appId.node) ::
                div(cls := "slds-grid slds-grid_vertical-align-end", appName.node) ::
                div(
                  cls := "slds-p-top_xx-large slds-grid footer-buttons",
                  cls <-- $appReg.map { case None => "slds-grid_align-end" case _ => "slds-grid_align-spread" },
                  div(
                    cls := "slds-col",
                    cls <-- $appReg.map { case None => "hidden" case _ => "" },
                    TrashIcon(showDeletePopup.writer.contramap((_: dom.MouseEvent) => true)),
                  ),
                  div(
                    cls := "dialog-submit-buttons",
                    ButtonsPairComponent[Unit, Option[PortalAppRegistration]](

                      disabled = formState.signal.flatMap(_.$traverse(_.dirtySignal)).$contains(false),

                      primaryDisabled = formState.signal.flatMap(_.$traverse(_.validSignal))
                        .combineWith($appReg)
                        .combineWith(model.clientId)
                        .combineWith(model.topicName)
                        .map(t => !t._1.contains(true) || (t._2.isEmpty && (t._3.isEmpty || t._4.isEmpty))),

                      primaryEffect = () => EventStream.fromValue(())
                        .sample(portalState.$teamApp)
                        .map(_.appKey)
                        .flatMap(key => portalApi.createOrUpdateAppReg(key, model.toImmutableModel))
                        .withErrorHandlingAndCollect(
                          FormsLocalExceptionHandling
                            .handler(str => editModel.now.foreach(_.formError.set(str.some))))
                        .mapTo(()),

                      primaryObserver = bus.writer,

                      secondaryEffect = () => EventStream.fromValue(()).sample($appReg),

                      secondaryObserver = Observer[Option[PortalAppRegistration]](onNext = {

                        case Some(value) =>
                          model.updateModelFromImmutable(value)
                          formState.now.foreach(_.reset())
                        case None =>
                          model.resetModel()
                          formState.now.foreach(_.reset())
                      })
                    ).node,


                  )
                ) ::
                Nil
            }).map(_.toList.flatten)
          )
        ),
        bordered = Signal.fromValue(true),
        expanded = $initialExpand.combineWith(isExpanded.signal).map(t => t._1 || t._2),
        onExpansionChange = isExpanded.writer

      ).node,
    )
}

