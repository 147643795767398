package root_pages.aurinko_pages.app.virtual_api.components.dialogs.mapping

import com.github.uosis.laminar.webcomponents.material
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import service.apis.dynamic_api.DataMapperModels.{MappingModels, MetadataModels}
import common.ui.mat_components_styles.{fixMwcDialogOverflow, fixMwcDialogOverflowEx, setStyles}
import common.ui.buttons_pair.ButtonsPairComponent
import root_pages.aurinko_pages.app.virtual_api.components
import wvlet.log.Logger

case object Field {
  private val log = Logger("components.dialogs.mapping.Field")
  //  private val rxName: Regex = "^[A-Za-z][A-Za-z0-9_-]+$".r

  private case class MutableModel(private[mapping] val src: MappingModels.Field) {
    val providerValue: Var[String] = Var(src.rawValue
      .flatMap {
        case v if src.direction.contains(MappingModels.Direction.readOnly) => Some(v)
        case _ => None
      }
      .orElse(src.providerFields.headOption)
      .getOrElse(""))
    val virtualValue: Var[String] = Var(src.rawValue
      .flatMap {
        case v if src.direction.contains(MappingModels.Direction.writeOnly) => Some(v)
        case _ => None
      }
      .orElse(src.clientFields.headOption)
      .getOrElse(""))
    val direction: Var[MappingModels.Direction.Direction] = Var(src.direction.getOrElse(MappingModels.Direction.bidirectional))
    val mapper: Var[MappingModels.MappingFunction.Mapper] = Var(src.mapper.getOrElse(MappingModels.MappingFunction.identity))
    val providerFieldSelector: Var[MappingModels.ProviderFieldSelector.ProviderFieldSelector] = Var(src.providerFieldSelector.getOrElse(MappingModels.ProviderFieldSelector.none))
    val tag: Var[Option[String]] = Var(src.tag.flatMap(v => Option.when(v.nonEmpty)(v)))
    val description: Var[Option[String]] = Var(src.description.flatMap(v => Option.when(v.nonEmpty)(v)))

    def toImmutableModel: MappingModels.Field = {
      val p = this.providerValue.now()
      val v = this.virtualValue.now()
      val m = this.mapper.now()
      val s = this.providerFieldSelector.now()

      val vRaw = Option.when(v.trim.nonEmpty && common.CirceStringOps(v).decodeError.isEmpty)(v)
      val pRaw = Option.when(p.trim.nonEmpty && common.CirceStringOps(p).decodeError.isEmpty)(p)

      src.copy(
        clientFields = Option.when(vRaw.isEmpty)(v :: Nil ++ this.src.clientFields.drop(1)).getOrElse(Nil).distinct.filter(_.trim.nonEmpty),
        providerFields = Option.when(vRaw.nonEmpty || pRaw.isEmpty)(p :: Nil ++ this.src.providerFields.drop(1)).getOrElse(Nil).distinct.filter(_.trim.nonEmpty),
        rawValue = vRaw.orElse(pRaw),
        direction = Some(this.direction.now()),
        mapper = Option.when(m != MappingModels.MappingFunction.identity && m != MappingModels.MappingFunction.unknown)(m),
        providerFieldSelector = Option.when(s != MappingModels.ProviderFieldSelector.unknown && s != MappingModels.ProviderFieldSelector.none)(s),
        tag = this.tag.now(),
        description = this.description.now(),
      )
    }

    def $errors: Signal[Map[String, String]] =
      providerValue.signal
        .combineWith(virtualValue.signal)
        .combineWith(direction.signal)
        .combineWith(mapper.signal)
        .map(_ => this.toImmutableModel)
        .combineWith(virtualField.signal)
        .combineWith(providerField.signal)
        .map(x => validationField(x._1, x._2, x._3))
        .map {
          err =>
            log.info(s"model.errors: ${err.toList.map(e => s"${e._1} = ${e._2}").mkString("; ")}")
            err
        }

    def $valid: Signal[Boolean] = $errors.map(_.isEmpty)

    def $hasChanges: Signal[Boolean] = providerValue.signal
      .combineWith(virtualValue.signal)
      .combineWith(direction.signal)
      .combineWith(mapper.signal)
      .map(_ => this.toImmutableModel)
      .map {
        m =>
          Seq(
            m.clientFields.mkString(";") != src.clientFields.mkString(";"),
            m.providerFields.mkString(";") != src.providerFields.mkString(";"),
            m.direction.map(_.value).getOrElse("") != src.direction.map(_.value).getOrElse(""),
            m.rawValue.getOrElse("") != src.rawValue.getOrElse(""),
            m.mapper.toString != src.mapper.toString,
            m.providerFieldSelector.map(_.value).getOrElse("") != src.providerFieldSelector.map(_.value).getOrElse(""),
            m.tag.getOrElse("") != src.tag.getOrElse(""),
            m.description.getOrElse("") != src.description.getOrElse("")
          )
      }
      .map {
        x =>
          log.info(s"model.hasChanges: ${x.mkString("; ")}")
          x
      }
      .map(_.contains(true))

    val virtualField = Var[Option[MetadataModels.Field]](None)
    val providerField = Var[Option[MetadataModels.Field]](None)
  }

  private object Tab extends Enumeration {
    type Type = Value

    val virtualField, providerField, mapping, none = Value

    def from(m: MutableModel): Type =
      Option
        .when(m.virtualValue.now().isEmpty)(virtualField)
        .orElse(Option.when(m.providerValue.now().isEmpty)(providerField))
        .getOrElse(mapping)
  }

  // validation fields
  private val fnVirtual = "virtualField"
  private val fnProvider = "providerField"
  private val fnDirection = "direction"
  private val fnMapper = "mapper"

  private def validationField(
                               mappingField: MappingModels.Field,
                               virtualField: Option[MetadataModels.Field],
                               providerField: Option[MetadataModels.Field],
                             ): Map[String, String] = {
    val providerValue: String = mappingField.providerFields.headOption.getOrElse("")
    val virtualValue: String = mappingField.clientFields.headOption.getOrElse("")
    val direction: MappingModels.Direction.Direction = mappingField.direction.getOrElse(MappingModels.Direction.unknown)
    val rawValue: String = mappingField.rawValue.getOrElse("")
    val mapper: MappingModels.MappingFunction.Mapper = mappingField.mapper.getOrElse(MappingModels.MappingFunction.unknown)
    //val providerFieldSelector: MappingModels.ProviderFieldSelector.ProviderFieldSelector = mappingField.providerFieldSelector.getOrElse(MappingModels.ProviderFieldSelector.none)
    //val description: String = mappingField.description.getOrElse("")
    //val tag: String = mappingField.tag.getOrElse("")

    var r = Seq.empty[(String, String)]

    if (virtualValue.nonEmpty && providerValue.nonEmpty && rawValue.nonEmpty) {
      r = r.appended((fnVirtual, "can not to use virtual fields with raw value"))
      r = r.appended((fnProvider, "can not to use provider fields with raw value"))
    } else {
      if (virtualValue.isEmpty && rawValue.isEmpty) {
        r = r.appended((fnVirtual, "select field or enter custom value"))
      } else if (virtualValue.isEmpty && rawValue.nonEmpty && common.CirceStringOps(rawValue).decodeError.nonEmpty) {
        r = r.appended((fnVirtual, "enter correct custom value"))
      } else if (virtualValue.nonEmpty && common.CirceStringOps(virtualValue).decodeError.isEmpty) {
        r = r.appended((fnVirtual, "enter correct custom value"))
      }
      if (providerValue.isEmpty && rawValue.isEmpty) {
        r = r.appended((fnProvider, "select field or enter custom value"))
      } else if (providerValue.isEmpty && rawValue.nonEmpty && common.CirceStringOps(rawValue).decodeError.nonEmpty) {
        r = r.appended((fnProvider, "enter correct custom value"))
      } else if (providerValue.nonEmpty && common.CirceStringOps(providerValue).decodeError.isEmpty) {
        r = r.appended((fnProvider, "enter correct custom value"))
      }
    }

    if (rawValue.nonEmpty && !r.exists(_._1 == fnProvider) && !r.exists(_._1 == fnVirtual)) {
      if (virtualValue.isEmpty && providerValue.nonEmpty && direction.compareTo(MappingModels.Direction.writeOnly) != 0) {
        r = r.appended((fnDirection, s"raw value only uses with ${MappingModels.Direction.writeOnly.label.toLowerCase()}"))
      } else if (virtualValue.nonEmpty && providerValue.isEmpty && direction.compareTo(MappingModels.Direction.readOnly) != 0) {
        r = r.appended((fnDirection, s"raw value only uses with ${MappingModels.Direction.readOnly.label.toLowerCase()}"))
      }
    }

    if (virtualField.isDefined && providerField.isDefined &&
      virtualField.get.`type`.basisType.value != providerField.get.`type`.basisType.value &&
      !MappingModels.MappingFunction.activeFor(providerField.map(_ :: Nil), virtualField.map(_ :: Nil)).exists(_.name == mapper.name)) {
      r = r.appended((fnMapper, s"convert ${virtualField.get.`type`.basisType.label} to ${providerField.get.`type`.basisType.label}"))
    }

    r.toMap
  }

  def apply(
             `#virtualClassLoader`: String => EventStream[MetadataModels.Class],
             `#virtualSchemaLoader`: String => EventStream[MetadataModels.Schema],
             `#providerClassLoader`: String => EventStream[MetadataModels.Class],
             `#providerSchemaLoader`: String => EventStream[MetadataModels.Schema],
             providerName: String,
             fieldPathMG: String,
             fieldDataMG: MappingModels.Field,
             classMG: MappingModels.Class,
             virtualClassMD: Signal[Option[MetadataModels.Class]],
             providerClassMD: Signal[Option[MetadataModels.Class]],
             onChange: Observer[Option[MappingModels.Class]],
           ): material.Dialog.El = {
    val isOpen = Var[Boolean](true)
    val model = MutableModel(fieldDataMG)
    val tab = Var[Field.Tab.Type](Field.Tab.from(model))
    material.Dialog(
      _ => cls := "width--medium",
      _.open <-- isOpen.signal,
      _.onClosing.mapTo(false) --> isOpen.writer,
      _.heading := fieldDataMG.direction
        .flatMap(_ => fieldDataMG.clientFields.headOption.map(f => s"Edit $f field of $providerName mapping object"))
        .getOrElse(s"Create new field of $providerName mapping object"),
      _.hideActions := true,
      _.slots.default(div(
        cls := "slds-grid slds-grid--vertical",
        renderProgress(tab.signal, model, onChangeTab = tab.writer),
        div(
          cls := "slds-col",
          common.ui.Attribute.Selector := "mapping.field.virtual",
          cls <-- tab.signal
            .map {
              case Field.Tab.virtualField => ""
              case _ => "hidden"
            },
          renderVirtualField(
            `#virtualClassLoader`,
            `#virtualSchemaLoader`,
            model,
            virtualClassMD,
            onChangeTab = tab.writer,
            onDialogVisible = isOpen.writer,
          ),
        ),
        div(
          common.ui.Attribute.Selector := "mapping.field.provider",
          cls <-- tab.signal
            .map {
              case Field.Tab.providerField => ""
              case _ => "hidden"
            },
          renderProviderField(
            (className: String) => `#providerClassLoader`(className),
            (schemaName: String) => `#providerSchemaLoader`(schemaName),
            model,
            providerClassMD,
            onChangeTab = tab.writer,
            onDialogVisible = isOpen.writer,
          ),
        ),
        div(
          common.ui.Attribute.Selector := "mapping.field.details",
          cls <-- tab.signal
            .map {
              case Field.Tab.virtualField => "hidden"
              case Field.Tab.providerField => "hidden"
              case _ => ""
            },
          renderDetailsOfMapping(
            model,
            fieldPathMG,
            fieldDataMG,
            classMG,
            onChangeTab = tab.writer,
            onDialogVisible = isOpen.writer,
            onBeforeSave = () => EventStream.fromValue(model.toImmutableModel)
              .map {
                m =>
                  val mappings = if (fieldPathMG.isEmpty) {
                    classMG.fieldMappings
                  } else {
                    classMG.fieldMappings.filter(_.toString != fieldDataMG.toString)
                  }
                  classMG.copy(fieldMappings = mappings.appended(m))
              },
            onAfterSave = onChange,
          )
        ),
        //        p(
        //          cls := "slds-grid slds-grid--vertical slds-m-top--x-larges", //hidden
        //          div(
        //            cls := "slds-col slds-m-bottom--small",
        //            p(cls := "slds-col slds-m-left--small", s"Virtual field: ", child.text <-- model.virtualField.signal.map(_.map(_.name).getOrElse("<nil>"))),
        //            p(cls := "slds-col slds-m-left--small", s"Provider field: ", child.text <-- model.providerField.signal.map(_.map(_.name).getOrElse("<nil>"))),
        //          ),
        //        ),
        //        p(
        //          cls := "slds-grid slds-grid--vertical slds-m-top--x-larges", //hidden
        //          div(
        //            cls := "slds-col slds-m-bottom--small",
        //            p(cls := "slds-col slds-m-left--small", s"Virtual value: ", child.text <-- model.virtualValue.signal),
        //            p(cls := "slds-col slds-m-left--small", s"Provider value: ", child.text <-- model.providerValue.signal),
        //            p(cls := "slds-col slds-m-left--small", s"Direction: ", child.text <-- model.direction.signal.map(_.label)),
        //            p(cls := "slds-col slds-m-left--small", s"Mapper: ", child.text <-- model.mapper.signal.map(_.label)),
        //            p(cls := "slds-col slds-m-left--small", s"Field selector: ", child.text <-- model.providerFieldSelector.signal.map(_.label)),
        //            p(cls := "slds-col slds-m-left--small", s"Tag: ", child.text <-- model.tag.signal.map(_.getOrElse("<nil>"))),
        //            p(cls := "slds-col slds-m-left--small", s"Description: ", child.text <-- model.description.signal.map(_.getOrElse("<nil>"))),
        //          ),
        //        ),
        //        p(
        //          cls := "slds-grid slds-grid--vertical slds-m-top--x-larges", //hidden
        //          child <-- model.virtualField.signal
        //            .combineWith(model.providerField.signal)
        //            .combineWith(model.direction.signal)
        //            .combineWith(model.mapper.signal)
        //            .combineWith(model.providerFieldSelector.signal)
        //            .combineWith(model.tag.signal)
        //            .combineWith(model.description.signal)
        //            .map(_ => model.toImmutableModel)
        //            .map {
        //              model =>
        //                div(
        //                  cls := "slds-col slds-m-bottom--small",
        //                  p(cls := "slds-col slds-m-left--small", s"Virtual field: ", model.clientFields.mkString("; ")),
        //                  p(cls := "slds-col slds-m-left--small", s"Provider field: ", model.providerFields.mkString("; ")),
        //                  p(cls := "slds-col slds-m-left--small", s"Direction: ", model.direction.map(_.label).getOrElse[String]("")),
        //                  p(cls := "slds-col slds-m-left--small", s"RawValue: ", model.rawValue.getOrElse[String]("")),
        //                  p(cls := "slds-col slds-m-left--small", s"Mapper: ", model.mapper.map(_.label).getOrElse[String]("")),
        //                  p(cls := "slds-col slds-m-left--small", s"Field selector: ", model.providerFieldSelector.map(_.label).getOrElse[String]("")),
        //                  p(cls := "slds-col slds-m-left--small", s"Tag: ", model.tag.getOrElse[String]("<nil>")),
        //                  p(cls := "slds-col slds-m-left--small", s"Description: ", model.description.getOrElse[String]("<nil>")),
        //                )
        //            }
        //        )
      )),
      _ => onMountCallback(fixMwcDialogOverflow)
    )
  }

  private def renderProgress(
                              tabValue: Signal[Field.Tab.Type],
                              model: MutableModel,
                              onChangeTab: Observer[Field.Tab.Type],
                            ): Div =
    div(
      common.ui.Attribute.Selector := "mapping.progress",
      cls := "slds-progress slds-m-bottom--medium",
      ol(
        cls := "slds-progress__list",
        padding := "0",
        li(
          cls := "slds-progress__item slds-is-completed",
          cls <-- model.$errors
            .map(_.contains(fnVirtual))
            .combineWith(tabValue)
            .map {
              case (true, Field.Tab.virtualField) => "slds-has-error"
              case (true, Field.Tab.providerField) => "slds-has-error"
              case (true, Field.Tab.mapping) => "slds-has-error"
              case (false, Field.Tab.virtualField) => "slds-progress__item-current"
              case _ => ""
            },
          button(
            cls := "slds-button slds-button_icon slds-progress__marker slds-progress__marker_icon",
            title <-- model.virtualValue.signal,
            "V",
            onClick.mapTo(Field.Tab.virtualField) --> onChangeTab,
          )
        ),
        li(
          cls := "slds-progress__item slds-is-completed",
          cls <-- model.$errors
            .map(_.contains(fnProvider))
            .combineWith(tabValue)
            .map {
              case (true, Field.Tab.providerField) => "slds-has-error"
              case (true, Field.Tab.mapping) => "slds-has-error"
              case (false, Field.Tab.providerField) => "slds-progress__item-current"
              case _ => ""
            },
          button(
            cls := "slds-button slds-button_icon slds-progress__marker slds-progress__marker_icon",
            title <-- model.providerValue.signal,
            "P",
            onClick.mapTo(Field.Tab.providerField) --> onChangeTab,
          )
        ),
        li(
          cls := "slds-progress__item slds-is-completed",
          cls <-- model.$errors
            .map(_.exists(x => x._1 == fnDirection || x._1 == fnMapper))
            .combineWith(tabValue)
            .map {
              case (true, Field.Tab.mapping) => "slds-has-error"
              case (false, Field.Tab.mapping) => "slds-progress__item-current"
              case _ => ""
            },
          button(
            cls := "slds-button slds-button_icon slds-progress__marker slds-progress__marker_icon",
            title <-- model.direction.signal.map(_.label),
            "M",
            onClick.mapTo(Field.Tab.mapping) --> onChangeTab,
          )
        )
      ),
      div(
        cls := "slds-progress-bar slds-progress-bar_x-small",
        span(
          cls := "slds-progress-bar__value",
          width <-- tabValue
            .map {
              case Field.Tab.virtualField => "0%"
              case Field.Tab.providerField => "50%"
              case _ => "100%"
            }
        )
      )
    )

  private def renderVirtualField(
                                  `#classLoader`: String => EventStream[MetadataModels.Class],
                                  `#schemaLoader`: String => EventStream[MetadataModels.Schema],
                                  model: MutableModel,
                                  virtualClassMD: Signal[Option[MetadataModels.Class]],
                                  onChangeTab: Observer[Field.Tab.Type],
                                  onDialogVisible: Observer[Boolean],
                                ): Seq[Div] = {
    val virtualError = model.$errors.map(_.getOrElse(fnVirtual, ""))
    Seq(
      div(
        cls := "slds-col slds-p-bottom--x-small",
        components.SuggestBoxOfField(
          "Virtual field",
          virtualClassMD.map(_.flatMap(_.fields).getOrElse(Nil)),
          `#classLoader`,
          `#schemaLoader`,
          onChange = Observer.combine(
            // change deep value
            model.virtualValue.writer
              .contramap((x: Option[(String, Option[MetadataModels.Field], Option[String])]) => x.map(_._1).getOrElse[String]("")),
            // change field in the last level
            model.virtualField.writer
              .contramap((x: Option[(String, Option[MetadataModels.Field], Option[String])]) => x.flatMap(_._2)),
          ),
          $value = Signal.fromValue(model.virtualValue.now()).map(Some(_)),
          required = Signal.fromValue(true),
          customizable = Signal.fromValue(true),
          error = virtualError,
        )
      ),
      div(
        cls := "slds-col slds-p-bottom--x-small slds-p-top--x-small",
        ButtonsPairComponent[Field.Tab.Type, Boolean](
          primaryButtonText = "Next",
          primaryDisabled = virtualError.map(_.nonEmpty),
          primaryEffect = () => EventStream.fromValue(Field.Tab.providerField),
          secondaryEffect = () => EventStream.fromValue(false),
          primaryObserver = onChangeTab,
          secondaryObserver = onDialogVisible,
          secondaryCls = "slds-m-right--large",
        ).node
      ),
    )
  }

  private def renderProviderField(
                                   `#classLoader`: String => EventStream[MetadataModels.Class],
                                   `#schemaLoader`: String => EventStream[MetadataModels.Schema],
                                   model: MutableModel,
                                   providerClassMD: Signal[Option[MetadataModels.Class]],
                                   onChangeTab: Observer[Field.Tab.Type],
                                   onDialogVisible: Observer[Boolean],
                                 ): Seq[Div] = {
    val providerError = model.$errors.map(_.getOrElse(fnProvider, ""))
    Seq(
      div(
        cls := "slds-col slds-p-bottom--x-small",
        components.SuggestBoxOfField(
          "Provider field",
          providerClassMD.map(_.flatMap(_.fields).getOrElse(Nil)),
          `#classLoader`,
          `#schemaLoader`,
          onChange = Observer.combine(
            // change deep value
            model.providerValue.writer
              .contramap((x: Option[(String, Option[MetadataModels.Field], Option[String])]) => x.map(_._1).getOrElse[String]("")),
            // change field in the last level
            model.providerField.writer
              .contramap((x: Option[(String, Option[MetadataModels.Field], Option[String])]) => x.flatMap(_._2)),
          ),
          $value = Signal.fromValue(model.providerValue.now()).map(Some(_)),
          required = Signal.fromValue(true),
          customizable = Signal.fromValue(true),
          error = providerError,
        )
      ),
      div(
        cls := "slds-col slds-grid slds-p-bottom--x-small slds-p-top--x-small slds-grid--align-end",
        material.Button(
          _ => cls := "slds-m-right--large",
          _ => cls := "",
          _.label := "Cancel",
          _.raised := false,
          _ => composeEvents(onClick)(_.mapTo(false)) --> onDialogVisible,
          _.disabled := false,
        ),
        ButtonsPairComponent[Field.Tab.Type, Field.Tab.Type](
          primaryButtonText = "Next",
          secondaryButtonText = "Previous",
          primaryDisabled = providerError.map(_.nonEmpty),
          primaryEffect = () => EventStream.fromValue(Field.Tab.mapping),
          secondaryEffect = () => EventStream.fromValue(Field.Tab.virtualField),
          primaryObserver = onChangeTab,
          secondaryObserver = onChangeTab,
        ).node
      ),
    )
  }

  private def renderDetailsOfMapping(
                                      model: MutableModel,
                                      fieldPathMG: String,
                                      fieldDataMG: MappingModels.Field,
                                      classMG: MappingModels.Class,
                                      onChangeTab: Observer[Field.Tab.Type],
                                      onDialogVisible: Observer[Boolean],
                                      onBeforeSave: () => EventStream[MappingModels.Class],
                                      onAfterSave: Observer[Option[MappingModels.Class]],
                                    ): Seq[Div] = {
    val directionError = model.$errors.map(_.getOrElse(fnDirection, ""))
    val mapperError = model.$errors.map(_.getOrElse(fnMapper, ""))
    Seq(
      div(
        cls := "slds-col slds-p-bottom--x-small",
        material.Select(
          _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
          _.label := "Direction",
          _.value <-- model.direction.signal.map(_.value),
          _.required := true,
          _ => children <-- Signal.fromValue(MappingModels.Direction.all)
            .map(_.map(d => material.List.ListItem(
              _.value := d.value,
              _.selected <-- model.direction.signal.map(_.value == d.value),
              _ => d.description,
              _ => composeEvents(onClick.stopPropagation)(_.mapTo(d)) --> model.direction.writer,
            )))
            .map {
              case items if items.isEmpty => material.List.ListItem(
                _ => "no directions",
                _.selected := false,
                _.value := "no-directions",
                _.disabled := true,
              ) :: Nil
              case items => items
            },
          _ => cls <-- directionError
            .map(_.nonEmpty)
            .map {
              case true => "with_error"
              case _ => ""
            },
          _.helper <-- directionError,
          _.helperPersistent := true,
        )
      ),
      div(
        cls := "slds-col slds-p-bottom--x-small",
        material.Select(
          _ => width := "100%",
          _.label := "Mapper",
          _.value <-- model.mapper.signal.map(_.name),
          _.required := false,
          _ => children <-- model.virtualField.signal
            .combineWith(model.providerField.signal)
            .map(x => (x._1, x._2, Seq(x._1.map(_.`type`.name), x._2.map(_.`type`.name)).filter(_.isDefined).map(_.get)))
            .map {
              case (Some(fVirtual), Some(fProvider), _) if fVirtual.role.contains(MetadataModels.FieldRole.id) =>
                val ignore = MappingModels.MappingFunction.identity ::
                  MappingModels.MappingFunction.stringToDecimal ::
                  MappingModels.MappingFunction.decimalToString ::
                  MappingModels.MappingFunction.stringToEmailField ::
                  MappingModels.MappingFunction.emailFieldToString ::
                  MappingModels.MappingFunction.stringAsDateNormalize ::
                  MappingModels.MappingFunction.htmlToString ::
                  MappingModels.MappingFunction.splitAtSemicolon ::
                  Nil
                MappingModels.MappingFunction.activeFor(
                  Some(fProvider :: Nil),
                  Some(fVirtual :: Nil),
                ).filter(mapper => !ignore.contains(mapper))
              case (_, _, types) if types.contains(MetadataModels.DataType.embedded.name) || types.contains(MetadataModels.DataType.record.name) || types.contains(MetadataModels.DataType.unknown.name) =>
                MappingModels.MappingFunction.all
              case (Some(fVirtual), fProvider, _) =>
                MappingModels.MappingFunction.activeFor(
                  fProvider.map(_ :: Nil),
                  Some(fVirtual :: Nil),
                )
              case _ =>
                MappingModels.MappingFunction.all
            }
            .map(MappingModels.MappingFunction.identity :: Nil ++ _.filter(m => m != MappingModels.MappingFunction.identity))
            .map(_.sortWith((a, b) =>
              if (a == MappingModels.MappingFunction.identity) true
              else if (b == MappingModels.MappingFunction.identity) false
              else a.label.compareTo(b.label) > 0
            ))
            .map(_.map(mapper => material.List.ListItem(
              _.value := mapper.name,
              _.selected <-- model.mapper.signal.map(_.name == mapper.name),
              _ => mapper.label,
              _ => onClick.mapTo(mapper) --> model.mapper.writer,
            )))
            .map {
              case items if items.isEmpty => material.List.ListItem(
                _ => "no mappers",
                _.selected := false,
                _.value := "no-mappers",
                _.disabled := true,
              ) :: Nil
              case items => items
            },
          _ => cls <-- mapperError
            .map(_.nonEmpty)
            .map {
              case true => "with_error"
              case _ => ""
            },
          _.helper <-- mapperError,
          _.helperPersistent := true,
          _ => onMountCallback(setStyles(".mdc-select__menu" :: ".mdc-list" :: "ul.mdc-list" :: Nil, "max-height" -> "150px" :: Nil))
        )
      ),
      div(
        cls := "slds-col slds-grid slds-grid--vertical",
        fieldDataMG.providerFieldSelector
          .map(x => MappingModels.ProviderFieldSelector.all.exists(_.value == x.value))
          .flatMap {
            case true =>
              Some(material.Select(
                _ => cls := "slds-col slds-size--1-of-1 slds-p-bottom--x-small",
                _.label := "Field selector",
                _.value <-- model.providerFieldSelector.signal.map(_.value),
                _.required := false,
                _ => children <-- Signal.fromValue(MappingModels.ProviderFieldSelector.all)
                  .map(s => (MappingModels.ProviderFieldSelector.none :: Nil) ++ s)
                  .map(_.map(d => material.List.ListItem(
                    _.value := d.value,
                    _.selected <-- model.providerFieldSelector.signal.map(_.value == d.value),
                    _ => d.label,
                    _ => composeEvents(onClick.stopPropagation)(_
                      .mapTo(d)
                      .withCurrentValueOf(model.providerFieldSelector.signal)
                      .map(x => Option.when(x._2.value != x._1.value)(x._1).getOrElse(MappingModels.ProviderFieldSelector.none)))
                      --> model.providerFieldSelector.writer,
                  )))
                  .map {
                    case items if items.isEmpty => material.List.ListItem(
                      _ => "no items",
                      _.selected := false,
                      _.value := "no-items",
                      _.disabled := true,
                    ) :: Nil
                    case items => items
                  },
              ))
            case _ => None
          },
        fieldDataMG.tag
          .map(_.nonEmpty)
          .flatMap {
            case true =>
              Some(material.Textfield(
                _ => cls := "slds-col slds-size--1-of-1 slds-p-bottom--x-small",
                _.label := "Option: TAG",
                _.required := false,
                _.value <-- model.tag.signal.map(_.getOrElse("")),
                _ => onInput.mapToValue.map(x => Option.when(x.nonEmpty)(x)) --> model.tag.writer
              ))
            case _ => None
          },
        fieldDataMG.description
          .map(_.nonEmpty)
          .flatMap {
            case true =>
              Some(material.Textarea(
                _ => cls := "slds-col slds-size--1-of-1 slds-p-bottom--x-small",
                _.label := "Option: DESCRIPTION",
                _.required := false,
                _.rows := 3,
                _.value <-- model.description.signal.map(_.getOrElse("")),
                _ => onInput.mapToValue.map(x => Option.when(x.nonEmpty)(x)) --> model.description.writer
              ))
            case _ => None
          }
      ),
      div(
        cls := "slds-col slds-grid slds-p-bottom--x-small slds-p-top--x-small slds-grid--align-end",
        material.Button(
          _ => cls := "slds-m-right--large",
          _ => cls := "",
          _.label := "Cancel",
          _.raised := false,
          _ => composeEvents(onClick)(_.mapTo(false)) --> onDialogVisible,
          _.disabled := false,
        ),
        ButtonsPairComponent[MappingModels.Class, Field.Tab.Type](
          primaryButtonText = "Apply",
          secondaryButtonText = "Previous",
          primaryDisabled = model.$valid.combineWith(model.$hasChanges).map(x => !x._1 || !x._2),
          primaryEffect = onBeforeSave,
          primaryObserver = Observer.combine(
            onAfterSave.contramap((c: MappingModels.Class) => Some(c)),
            onDialogVisible.contramap((_: MappingModels.Class) => false),
          ),
          secondaryEffect = () => EventStream.fromValue(Field.Tab.providerField),
          secondaryObserver = onChangeTab,
        ).node
      ),
    )
  }
}
