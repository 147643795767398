package common

import common.value_opps.{OptionOpps, ValueOpps}
import io.circe.{Decoder, Encoder}
import wvlet.log.Logger

import scala.util.Try

sealed abstract class ServiceType {

  def value: String

  def label: String

  def capabilities: Set[PortalAppCapability]

  def authOptions: Seq[AuthOption.AuthType]

  def authScopes: Seq[AuthScope]

  def isPersonal: Boolean

  def isDaemon: Boolean

  def isCRM: Boolean = capabilities.contains(PortalAppCapability.apiCrm)

  def isMailbox: Boolean = capabilities.contains(PortalAppCapability.apiMailbox)

  def isMessaging: Boolean = capabilities.contains(PortalAppCapability.apiChat)

  override def equals(other: Any): Boolean = other match {
    case that: ServiceType => that.value == value
    case that => super.equals(that)
  }

  override def toString: String = value
}

object ServiceType {
  private val log = Logger.of[ServiceType.type]


  private case class ServiceTypeImpl(
                                      override val value: String,
                                      override val label: String = "",
                                      override val capabilities: Set[PortalAppCapability] = Set.empty,
                                      override val authScopes: Seq[AuthScope] = Seq.empty,
                                      override val isPersonal: Boolean,
                                      override val isDaemon: Boolean,
                                      override val authOptions: Seq[AuthOption.AuthType] = Seq.empty,
                                    ) extends ServiceType

  def apply(name: String): ServiceType = ALL
    .find(_.value == name)
    .getOrElse(ServiceTypeImpl(
      value = name,
      label = name,
      capabilities = Set.empty,
      isPersonal = false,
      isDaemon = false,
    ))

  val google: ServiceType = ServiceTypeImpl("Google", "Google", capabilities = Set(PortalAppCapability.apiMailbox), isPersonal = true, isDaemon = true, authScopes = AuthScope.Calendar ++ AuthScope.Mail ++ AuthScope.Contacts ++ AuthScope.Tasks, authOptions = AuthOption.GoogleOAuthAppRegSettings :: Nil)
  val office365: ServiceType = ServiceTypeImpl("Office365", "Office 365", capabilities = Set(PortalAppCapability.apiMailbox), isPersonal = true, isDaemon = true, authScopes = AuthScope.Calendar ++ AuthScope.Mail ++ AuthScope.Contacts ++ AuthScope.Tasks, authOptions = AuthOption.OAuth2BasicAppRegSettings :: AuthOption.DaemonBasicAppRegSettings :: AuthOption.SecretFields :: Nil)
  val ews: ServiceType = ServiceTypeImpl("EWS", "MS Exchange", capabilities = Set(PortalAppCapability.apiMailbox), isPersonal = true, isDaemon = true, authScopes = AuthScope.Calendar ++ AuthScope.Mail ++ AuthScope.Contacts ++ AuthScope.Tasks)
  val ews365: ServiceType = ServiceTypeImpl("EWS365", "Office 365 (EWS)", capabilities = Set(PortalAppCapability.apiMailbox), isPersonal = true, isDaemon = true, authScopes = AuthScope.Calendar ++ AuthScope.Mail ++ AuthScope.Contacts ++ AuthScope.Tasks, authOptions = AuthOption.OAuth2BasicAppRegSettings :: AuthOption.DaemonBasicAppRegSettings :: AuthOption.SecretFields :: Nil)
  val imap: ServiceType = ServiceTypeImpl("IMAP", "IMAP", capabilities = Set(PortalAppCapability.apiMailbox), isPersonal = true, isDaemon = false, authScopes = AuthScope.Mail, authOptions = AuthOption.CustomForm :: Nil)
  val smtp: ServiceType = ServiceTypeImpl("SMTP", "SMTP", capabilities = Set(PortalAppCapability.apiMailbox), isPersonal = true, isDaemon = false, authScopes = AuthScope.Mail, authOptions = AuthOption.CustomForm :: Nil)
  val icloud: ServiceType = ServiceTypeImpl("iCloud", "iCloud", capabilities = Set(PortalAppCapability.apiMailbox), isPersonal = true, isDaemon = false, authScopes = AuthScope.Calendar ++ AuthScope.Mail, authOptions = AuthOption.CustomForm :: Nil)
  val slack: ServiceType = ServiceTypeImpl("Slack", "Slack", capabilities = Set(PortalAppCapability.apiChat), isPersonal = false, isDaemon = false, authScopes = AuthScope.Empty)
  val webex: ServiceType = ServiceTypeImpl("Webex", "Webex", capabilities = Set(PortalAppCapability.apiChat), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: AuthOption.CustomForm :: Nil)
  val zoom: ServiceType = ServiceTypeImpl("Zoom", "Zoom", capabilities = Set(PortalAppCapability.apiChat), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: Nil)

  val salesforce: ServiceType = ServiceTypeImpl("Salesforce", "Salesforce", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: Nil)
  val repfabric: ServiceType = ServiceTypeImpl("Repfabric", "Repfabric", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm :: Nil)
  val sugarCRM: ServiceType = ServiceTypeImpl("SugarCRM", "Sugar CRM", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2SpecialAppRegSettings :: Nil)
  val hubspot: ServiceType = ServiceTypeImpl("Hubspot", "Hubspot", capabilities = Set(PortalAppCapability.apiCrm, PortalAppCapability.emailMarketing), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: AuthOption.NativeScopes :: Nil)
  val autoQuotes: ServiceType = ServiceTypeImpl("AutoQuotes", "AutoQuotes", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm :: Nil)
  val eclipseERP: ServiceType = ServiceTypeImpl("EclipseERP", "Eclipse ERP", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty)
  val netSuite: ServiceType = ServiceTypeImpl("NetSuite", "NetSuite", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: Nil)
  val salesflare: ServiceType = ServiceTypeImpl("Salesflare", "Salesflare", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm :: Nil)
  val highLevel: ServiceType = ServiceTypeImpl("HighLevel", "HighLevel", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: AuthOption.NativeScopes :: Nil)
  val mcTrade: ServiceType = ServiceTypeImpl("MCTrade", "MC Trade", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = false, isDaemon = true, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm :: Nil)
  val creatio: ServiceType = ServiceTypeImpl("Creatio", "Creatio", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = false, isDaemon = true, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm :: Nil)
  val teamwork: ServiceType = ServiceTypeImpl("Teamwork", "Teamwork", capabilities = Set(PortalAppCapability.apiCrm, PortalAppCapability.apiPM), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: AuthOption.CustomForm :: Nil)
  val zoho: ServiceType = ServiceTypeImpl("Zoho", "Zoho", capabilities = Set(PortalAppCapability.apiCrm, PortalAppCapability.apiMailbox), isPersonal = true, isDaemon = false, authScopes = AuthScope.Mail, authOptions = AuthOption.OAuth2BasicAppRegSettings :: Nil)
  val pipedrive: ServiceType = ServiceTypeImpl("Pipedrive", "Pipedrive", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: Nil)
  val clientify: ServiceType = ServiceTypeImpl("Clientify", "Clientify", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm  :: Nil)
  val quickBooks: ServiceType = ServiceTypeImpl("QuickBooks", "QuickBooks", capabilities = Set(PortalAppCapability.apiAccounting), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: Nil)
  val activeCampaign: ServiceType = ServiceTypeImpl("ActiveCampaign", "ActiveCampaign", capabilities = Set(PortalAppCapability.apiCrm, PortalAppCapability.emailMarketing), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm :: Nil)
  val constantContact: ServiceType = ServiceTypeImpl("ConstantContact", "ConstantContact", capabilities = Set(PortalAppCapability.emailMarketing), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2BasicAppRegSettings :: AuthOption.NativeScopes :: Nil)
  val fishbowl: ServiceType = ServiceTypeImpl("Fishbowl", "Fishbowl", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm :: AuthOption.OAuth2SpecialAppRegSettings :: Nil)
  val specPath: ServiceType = ServiceTypeImpl("SpecPath", "SpecPath", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm :: Nil)
  val acumatica: ServiceType = ServiceTypeImpl("Acumatica", "Acumatica", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2SpecialAppRegSettings :: Nil)
  val esiAccountManager: ServiceType = ServiceTypeImpl("ESIAccountManager", "ESI Account Manager", capabilities = Set(PortalAppCapability.apiCrm), isPersonal = true, isDaemon = false, authScopes = AuthScope.Empty, authOptions = AuthOption.OAuth2SpecialAppRegSettings :: Nil)

  val msTeamsBot: ServiceType = ServiceTypeImpl("MsTeamsBot", "MS Teams Bot", capabilities = Set(PortalAppCapability.apiChat), isPersonal = false, isDaemon = true, authScopes = AuthScope.Chat, authOptions = AuthOption.OAuth2SpecialAppRegSettings :: Nil)
  val googleBot: ServiceType = ServiceTypeImpl("GoogleBot", "Google Bot", capabilities = Set(PortalAppCapability.apiChat), isPersonal = false, isDaemon = true, authScopes = AuthScope.Empty, authOptions = AuthOption.CustomForm :: Nil)
  val webexBot: ServiceType = ServiceTypeImpl("WebexBot", "Webex Bot", capabilities = Set(PortalAppCapability.apiChat), isPersonal = true, isDaemon = false, authScopes = AuthScope.Chat)
  val slackBot: ServiceType = ServiceTypeImpl("SlackBot", "Slack Bot", capabilities = Set(PortalAppCapability.apiChat), isPersonal = false, isDaemon = true, authScopes = AuthScope.Chat, authOptions = AuthOption.OAuth2SpecialAppRegSettings :: Nil)
  val zoomBot: ServiceType = ServiceTypeImpl("ZoomBot", "Zoom Bot", capabilities = Set(PortalAppCapability.apiChat), isPersonal = false, isDaemon = true, authScopes = AuthScope.Chat, authOptions = AuthOption.CustomForm :: Nil)

  val unknown: ServiceType = ServiceTypeImpl("unknown", "Unknown", isPersonal = false, isDaemon = false)

  val ALL: List[ServiceType] = List(
    google,
    office365,
    ews,
    ews365,
    imap,
    smtp,
    slack,
    webex,
    zoom,
    salesforce,
    repfabric,
    sugarCRM,
    hubspot,
    autoQuotes,
    eclipseERP,
    netSuite,
    salesflare,
    highLevel,
    mcTrade,
    teamwork,
    zoho,
    pipedrive,
    clientify,
    quickBooks,
    msTeamsBot,
    googleBot,
    webexBot,
    slackBot,
    zoomBot,
    creatio,
    activeCampaign,
    constantContact,
    fishbowl,
    specPath,
    icloud,
    acumatica,
    esiAccountManager
  )

  import io.circe._
  import io.circe.generic.codec.DerivedAsObjectCodec.deriveCodec

  implicit def encoder: Encoder[ServiceType] =
    Encoder[String].contramap(_.value)

  implicit def decoder: Decoder[ServiceType] =
    Decoder.decodeJson.emapTry {
      case raw if raw.isObject =>
        case class obj(name: String,
                       label: String,
                       capabilities: Set[PortalAppCapability],
                       authScopes: Seq[String],
                       isPersonal: Boolean,
                       isDaemon: Boolean,
                       authOptions: Seq[AuthOption.AuthType])
        raw.as[obj].toTry
          .map(obj => ServiceTypeImpl(
            value = obj.name,
            label = obj.label,
            capabilities = obj.capabilities,
            authScopes = obj.authScopes.map(s => AuthScope(s)),
            isPersonal = obj.isPersonal,
            isDaemon = obj.isDaemon,
            authOptions = obj.authOptions,
          ))
      case raw if raw.isString =>
        val n = raw.asString.getOrFail(new Exception(s"could not recognize service type from string: $raw"))
        Try(ServiceType.apply(n))
      case raw =>
        log.warn(s"undefined json type, should be check $raw")
        Try(ServiceType.unknown)
    }

  private val bots: Seq[ServiceType] = msTeamsBot :: googleBot :: webexBot :: slackBot :: zoomBot :: Nil

  implicit final class ServiceTypeExtension(private val serviceType: ServiceType) extends AnyVal {
    // split apiChat to bots and messaging
    def isBot: Boolean = ServiceType.bots.exists(_.value == serviceType.value)

    // TODO: rethink
    def weight: Int = ALL
      .indexWhere(e => e.value == serviceType.value)
      .condition(_ >= 0, index => index, _ => ALL.length + 1)
  }
}


case class AuthScope(value: String) {
  def name: String = value.split('.').headOption.getOrElse(value)

  def permission: String = value.split('.').lastOption.getOrElse(value)

  override def toString: String = value
}

object AuthScope {

  private val calendarRead: AuthScope = AuthScope("Calendar.Read")
  private val calendarReadWrite: AuthScope = AuthScope("Calendar.ReadWrite")

  private val mailRead: AuthScope = AuthScope("Mail.Read")
  private val mailReadWrite: AuthScope = AuthScope("Mail.ReadWrite")
  private val mailSend: AuthScope = AuthScope("Mail.Send")
  private val mailDrafts: AuthScope = AuthScope("Mail.Drafts")
  private val mailAll: AuthScope = AuthScope("Mail.All")

  private val contactsRead: AuthScope = AuthScope("Contacts.Read")
  private val contactsReadWrite: AuthScope = AuthScope("Contacts.ReadWrite")

  private val tasksRead: AuthScope = AuthScope("Tasks.Read")
  private val tasksReadWrite: AuthScope = AuthScope("Tasks.ReadWrite")

  val Empty: Seq[AuthScope] = Nil
  val Calendar: Seq[AuthScope] = AuthScope.calendarRead :: AuthScope.calendarReadWrite :: Nil
  val Mail: Seq[AuthScope] = AuthScope.mailRead :: AuthScope.mailReadWrite :: AuthScope.mailSend :: AuthScope.mailDrafts :: Nil
  val Contacts: Seq[AuthScope] = AuthScope.contactsRead :: AuthScope.contactsReadWrite :: Nil
  val Tasks: Seq[AuthScope] = AuthScope.tasksRead :: AuthScope.tasksReadWrite :: Nil
  val Chat: Seq[AuthScope] = Nil

  val All: Seq[AuthScope] = calendarRead ::
    calendarReadWrite ::
    mailRead ::
    mailReadWrite ::
    mailSend ::
    mailDrafts ::
    mailAll ::
    contactsRead ::
    contactsReadWrite ::
    tasksRead ::
    tasksReadWrite ::
    Nil

  implicit def encoder: Encoder[AuthScope] = Encoder[String].contramap(_.toString)

  implicit def decoder: Decoder[AuthScope] = Decoder[String]
    .map {
      raw =>
        All.find(_.value == raw)
          .getOrElse(new Exception(s"could not recognize auth scope from string: $raw"))
          .asInstanceOf[AuthScope]
    }
}

object AuthOption extends Enumeration with common.JsonEnum {
  type AuthType = Value
  type EnumValue = Value
  // if authorization is obtained using the authorization form
  val CustomForm: AuthType = Value

  // if authorization is obtained using settings (OAuth authorization) with basic fields
  // basic authorization types
  val OAuth2BasicAppRegSettings: AuthType = Value
  val DaemonBasicAppRegSettings: AuthType = Value

  // if authorization is obtained using settings (OAuth authorization) with special fields
  // specific authorization types
  val OAuth2SpecialAppRegSettings: AuthType = Value
  //val SugarCrmOAuthAppRegSettings: AuthType = Value // todo: remove
  //val MsTeamsBotAuthAppRegSettings: AuthType = Value // todo: remove
  //val SlackOAuthAppRegSettings: AuthType = Value // todo: remove
  val GoogleOAuthAppRegSettings: AuthType = Value // app reg is not daemon, but this service type show anyway in authorization on daemon

  val SecretFields: AuthType = Value
  val NativeScopes: AuthType = Value

  private val appRegistrations = Seq(
    OAuth2SpecialAppRegSettings,
    OAuth2BasicAppRegSettings,
    DaemonBasicAppRegSettings,
  )

  private val specialAppRegistrations = Seq(
    OAuth2SpecialAppRegSettings,
    GoogleOAuthAppRegSettings,
  )

  implicit final class AuthOptionExtension(private val authOption: AuthOption.AuthType) extends AnyVal {
    // service type has registration form for daemon authorization
    def hasAppRegistration: Boolean = AuthOption.appRegistrations.contains(authOption)

    // service type has special registration form, that is
    def hasSpecialAppReg: Boolean = AuthOption.specialAppRegistrations.contains(authOption)
  }
}
