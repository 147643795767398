package service.apis.portal_api

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import common.BillingModels.{BillingApiMany, BillingInfo, BillingInvoice, BillingPlan, CountryInfo, PaymentMethod, PaymentMethodUpdate}
import common.PortalAppType.PortalAppType
import common.StoreType.StoreType
import common.TeamMemberRole.TeamMemberRole
import common.airstream_ops.ValueToObservableOps
import common.{AccountError, AccountFilters, Usage, AccountsStats, AppKey, AurinkoApiPage, BookProfile, CirceStringOps, ClientSubscription, ClientSubscriptionEvent, EndUser, OrgFilters, Organization, OrganizationEditModel, PortalAccount, PortalAccountEditModel, PortalAppCapability, PortalAppRegistration, PortalApplication, PortalInvitation, PortalInvitationState, PortalTeam, PortalTeamMember, PortalUser, PortalUserAppPermission, PortalUserAppPermissions, ProcessingErrors, ServiceType, Storage, StoreType, TeamMemberOutDto, TokenInfo, UserFilters}
import io.circe.generic.auto.{exportDecoder, exportEncoder}
import io.circe.{Decoder, Encoder, parser}
import io.circe.syntax.EncoderOps
import org.scalajs.dom
import root_pages.auth_pages.sign_in.SignInModel
import root_pages.auth_pages.sign_up.SignUpModel
import service.apis.API
import service.aurinko_api.AurinkoModels.Book
import service.exception_handler.{ApiException, ApiNotFoundException, ForbiddenError, UnexpectedApiResponse, XhrFailedException}
import service.http.ApiRequester
import service.ui.spinner.Spinner
import service.urls.Urls
import wvlet.log.Logger

import java.time._
import scala.scalajs.js.URIUtils
import scala.util.{Failure, Success}

class PortalApi(apiUrl: String, urls: Urls, spinner: Spinner) extends API {

  private val log = Logger.of[PortalApi]

  private val apiRequester: ApiRequester = ApiRequester(apiUrl, validateResponse, spinner)

  def validateResponse(resp: dom.XMLHttpRequest): String = resp.status match {
    case c if c >= 200 && c < 300 => resp.responseText
    case 401 =>
      log.info(s"${resp.status}: portal-srv not authorized")

      throw service.exception_handler.NotAuthorizedException(
        username = Option(resp.getResponseHeader("X-Aurinko-UsernameHint"))
      )
    case 403 => throw ForbiddenError()
    case 404 => throw parser.decode[ApiNotFoundException](resp.responseText).fold(throw _, v => v)
    case c if c == 0 => throw XhrFailedException()
    case _ =>
      log.warn(s"${resp.status}: ${resp.responseText}")
      throw parser.decode[ApiException](resp.responseText).fold(throw _, v => v)
  }

  val standardApiPageSize = 50

  def getServiceTypes: EventStream[List[common.ServiceType]] = ServiceType.ALL.streamed
  //    apiRequester.get("/common/catalog/service_types")
  //      .recoverToTry
  //      .map {
  //        case Success(dataset) =>
  //          dataset.decodeAs[List[common.ServiceType]]
  //        case Failure(exception) =>
  //          exception match {
  //            case _: ApiNotFoundException =>
  //              log.warn(s"getServiceTypes method not supported, uses default constants")
  //              ServiceType.ALL
  //            case e =>
  //              log.trace(s"get /common/catalog/service_types, exception: ${e.getMessage}")
  //              throw e
  //          }
  //      }

  def getCapabilities: EventStream[List[common.PortalAppCapability]] = PortalAppCapability.ALL.streamed
  //    apiRequester.get("/common/catalog/capabilities")
  //      .recoverToTry
  //      .map {
  //        case Success(dataset) =>
  //          dataset.decodeAs[List[common.PortalAppCapability]]
  //        case Failure(exception) =>
  //          exception match {
  //            case _: ApiNotFoundException =>
  //              log.warn(s"getCapabilities method not supported, uses default constants")
  //              PortalAppCapability.ALL
  //            case e =>
  //              log.trace(s"get /common/catalog/capabilities, exception: ${e.getMessage}")
  //              throw e
  //          }
  //      }

  def signIn(model: SignInModel): EventStream[String] = apiRequester.post("/user/login", Some(model.asJson))

  def signUp(model: SignUpModel): EventStream[String] = apiRequester.post("/user/register", Some(model.asJson))

  def signOut: EventStream[Unit] = apiRequester.post("/user/logout")
    .recoverToTry
    .map {
      case Success(_) => ()
      case Failure(exception) =>
        exception match {
          case e: service.exception_handler.NotAuthorizedException =>
            throw service.exception_handler.NotAuthorizedSilentException(e.getMessage, e.username, None)
          case e => throw e
        }
    }

  override def ping: EventStream[Unit] = apiRequester
    .post(s"/user/ping")
    .mapTo(())

  def getMe: EventStream[PortalUser] =
    apiRequester.get("/user/me")
      .recoverToTry
      .map {
        case Success(x) => x.decodeAs[PortalUser]
        case Failure(exception) =>
          exception match {
            case e: service.exception_handler.NotAuthorizedException =>
              throw service.exception_handler.NotAuthorizedSilentException(e.getMessage, e.username, urls.wlHref.some)
            case e => throw e
          }
      }

  def checkUserState(invKey: String, invId: String): EventStream[PortalInvitationState] = apiRequester
    .get(s"/invitations/$invId/key/$invKey").map(_.decodeAs[PortalInvitationState])

  def acceptInviteWithSignUp(invKey: String, invId: String, model: SignUpModel): EventStream[Unit] = apiRequester
    .post(s"/invitations/$invId/key/$invKey/register_user", Some(model.asJson)).map(_ => ())

  def acceptInvite(invKey: String, invId: String): EventStream[Unit] = apiRequester
    .post(s"/invitations/$invId/key/$invKey").map(_ => ())

  def declineInvite(invKey: String, invId: String): EventStream[Unit] = apiRequester
    .delete(s"/invitations/$invId/key/$invKey").map(_ => ())

  def resetPassword(email: String): EventStream[Unit] = apiRequester
    .post("/user/password_reset/start", Some(Map("email" -> email).asJson)).map(_ => ())

  def newPassword(token: String, password: String): EventStream[Unit] = apiRequester
    .post("/user/password_reset/apply", Some(Map("password" -> password, "key" -> token).asJson)).map(_ => ())

  def startEmailVerification(): EventStream[Unit] = apiRequester.post("/user/email_verify/start").mapTo(())

  def applyEmailVerification(key: String): EventStream[Unit] = apiRequester
    .post("/user/email_verify/apply", body = Some(Map("key" -> key).asJson), showSpinner = false)
    .mapTo(())

  def updateMe(name: String, email: String): EventStream[Unit] = apiRequester
    .put("/user/me", body = Some(Map("name" -> name, "email" -> email).asJson)).map(_ => ())

  def deleteMe(): EventStream[Unit] = apiRequester
    .delete("/some/url").map(_ => ())

  def updatePassword(newPassword: String, oldPassword: String): EventStream[Unit] = apiRequester.put(
    "/user/me/password",
    body = Some(Map("newPassword" -> newPassword, "oldPassword" -> oldPassword).asJson)
  ).map(_ => ())

  def application(appKey: AppKey): EventStream[PortalApplication] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}").map(_.decodeAs[PortalApplication])

  def updateApiApplication(appKey: AppKey, givenAppModel: PortalApplication): EventStream[PortalApplication] = apiRequester.put(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}",
    body = Some(givenAppModel.asJson)
  ).map(_.decodeAs[PortalApplication])

  def createApp(name: String, teamId: Int, appType: PortalAppType, capabilities: Set[PortalAppCapability]): EventStream[PortalApplication] = apiRequester
    .post(s"/teams/$teamId/apps", body = Some(Map(
      "name" -> name,
      "applicationType" -> appType.name //wait for api
    ).asJson
      //two different maps because of decoder
      .deepMerge(Map(
        "capabilities" -> capabilities
      ).asJson))
    )
    .map(_.decodeAs[PortalApplication])

  def deleteApp(appKey: AppKey): EventStream[Unit] = apiRequester.delete(s"/teams/${appKey.teamId}/apps/${appKey.appId}").map(_ => ())

  def requestAppRegs(appKey: AppKey): EventStream[List[PortalAppRegistration]] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/oauth_regs")
    .map(_.decodeAs[AurinkoApiPage[PortalAppRegistration]])
    .map(_.records)


  def accountStats(appKey: AppKey): EventStream[AccountsStats] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/stats", showSpinner = false).map(_.decodeAs[AccountsStats])

  def getMembers(teamId: Int): EventStream[List[PortalTeamMember]] = apiRequester.get(
    s"/teams/$teamId/members").map(_.decodeAs[AurinkoApiPage[PortalTeamMember]]).map(_.records)

  def updateTeamMember(teamId: Int, memberId: Int, dto: TeamMemberOutDto): EventStream[Unit] = apiRequester
    .patch(
      s"/teams/$teamId/members/$memberId",
      body = Some(dto.asJson))
    .map(_ => ())

  def deleteMember(teamId: Int, memberId: Int): EventStream[Unit] = apiRequester.delete(
    s"/teams/$teamId/members/$memberId").map(_ => ())

  def getInvitations(teamId: Int): EventStream[List[PortalInvitation]] = apiRequester.get(
    s"/teams/$teamId/invitations").map(_.decodeAs[AurinkoApiPage[PortalInvitation]]).map(_.records)

  def createTeam(name: String): EventStream[PortalTeam] = apiRequester
    .post(
      s"/teams",
      Map("name" -> name).asJson.some)
    .map(_.decodeAs[PortalTeam])

  def upadateTeam(teamName: String, teamId: Int): EventStream[Unit] = apiRequester.put(
    s"/teams/$teamId", body = Some(Map("name" -> teamName).asJson)).map(_ => ())

  def leaveTeam(teamId: Int): EventStream[Unit] = apiRequester.delete(
    s"/teams/$teamId/members/me").map(_ => ())

  def inviteMember(teamId: Int, invite: PortalInvitation): EventStream[Unit] =
    apiRequester.post(
        s"/teams/$teamId/invitations",
        body = Some(invite.asJson.deepDropNullValues))
      .map(_ => ())

  def deleteInvite(teamId: Int, invite: PortalInvitation): EventStream[Unit] = apiRequester.delete(
    s"/teams/$teamId/invitations/${invite.id.get}").map(_ => ())

  def getUsersAppPermissions(teamId: Int, appId: Int): EventStream[List[PortalUserAppPermissions]] = apiRequester
    .get(s"/teams/$teamId/apps/$appId/permissions")
    .map(_.decodeAs[AurinkoApiPage[PortalUserAppPermissions]])
    .map(_.records)

  def updateUserAppPermissions(teamId: Int, appId: Int, uid: Int, permissions: Option[List[PortalUserAppPermission]]): EventStream[Unit] = apiRequester
    .patch(
      s"/teams/$teamId/apps/$appId/permissions/$uid",
      Map("appPermissions" -> permissions).asJson.some
    )
    .mapTo(())

  def updateAllowedUrls(appKey: AppKey, allowedUrlRedirects: List[String]): EventStream[Unit] = apiRequester.put(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/allowedRedirects", body = Some(Map("urls" -> allowedUrlRedirects).asJson)).map(_ => ())

  def updateAllowedOrigins(appKey: AppKey, allowedOrigins: List[String]): EventStream[Unit] = apiRequester.put(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/allowedOrigins", body = Some(Map("origins" -> allowedOrigins).asJson)).map(_ => ())

  def createOrUpdateAppReg(appKey: AppKey, appReg: PortalAppRegistration): EventStream[Unit] = apiRequester
    .put(s"/teams/${appKey.teamId}/apps/${appKey.appId}/oauth_regs/${if (appReg.daemon) "daemon" else "user"}/${appReg.serviceType.value}",
      body = Some(appReg.asJson.deepDropNullValues)).map(_ => ())

  def appClientSecret(appKey: AppKey): EventStream[String] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/clientSecret")
    .map(_.decodeAs[Map[String, String]])
    .map(_.getOrElse("key", throw UnexpectedApiResponse("missing client secret")))

  //generateNew=true|false
  def appSigningSecret(
                        appKey: AppKey,
                        createNew: Boolean = false,
                      ): EventStream[String] = apiRequester
    .post(
      path = s"/teams/${appKey.teamId}/apps/${appKey.appId}/signingSecret",
      queryParams = Map("generateNew" -> Some(createNew.toString)),
    )
    .map(_.decodeAs[Map[String, String]])
    .map(_.getOrElse("key", throw UnexpectedApiResponse("missing signing secret")))


  def accounts(
                appKey: AppKey,
                daemon: Boolean = false,
                offset: Int = 0,
                limit: Int = standardApiPageSize,
                //zoomId: Option[Int] = None,
                serviceType: Option[ServiceType] = None,
                accountFilters: AccountFilters = AccountFilters(),
              ): EventStream[AurinkoApiPage[PortalAccount]] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts", queryParams = Map(
      "daemon" -> Some(s"$daemon"),
      "offset" -> Some(s"$offset"),
      "limit" -> Some(s"$limit"),
      "serviceType" -> (if (serviceType.isDefined) Some(serviceType.get.value) else None),
    )
      //"zoomId" -> Option.when(zoomId.isDefined) {zoomId.get.toString})
      ++ accountFilters.toMap).map(_.decodeAs[AurinkoApiPage[PortalAccount]])

  def deleteAppReg(appKey: AppKey, par: PortalAppRegistration): EventStream[Unit] = apiRequester.delete(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/oauth_regs/${if (par.daemon) "daemon" else "user"}/${par.serviceType}").map(_ => ())

  def organizations(appKey: AppKey, limit: Int = standardApiPageSize, offset: Int = 0, orgFilters: OrgFilters): EventStream[AurinkoApiPage[Organization]] = apiRequester.get(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/organizations", queryParams = Map(
      "limit" -> Some(s"$limit"),
      "offset" -> Some(s"$offset")
    )
      ++ orgFilters.toMap
  ).map(_.decodeAs[AurinkoApiPage[Organization]])

  def organization(appKey: AppKey, orgId: Int): EventStream[Organization] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/organizations/$orgId").map(_.decodeAs[Organization])

  def updateOrganization(
                          appKey: AppKey,
                          orgId: Int,
                          organizationModel: OrganizationEditModel,
                        ): EventStream[Organization] =
    apiRequester.patch(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/organizations/$orgId",
      body = Some(organizationModel.toJson),
    ).map(_ => organizationModel.toApiModel)


  def deleteOrganization(appKey: AppKey, orgId: String): EventStream[Unit] = apiRequester
    .delete(s"/teams/${appKey.teamId}/apps/${appKey.appId}/users/$orgId").mapTo(())

  def users(appKey: AppKey, limit: Int = standardApiPageSize, offset: Int = 0, userFilters: UserFilters): EventStream[AurinkoApiPage[EndUser]] = apiRequester.get(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/users", queryParams = Map(
      "limit" -> Some(s"$limit"),
      "offset" -> Some(s"$offset")
    )
      ++ userFilters.toMap
  ).map(_.decodeAs[AurinkoApiPage[EndUser]])

  def user(appKey: AppKey, userId: String): EventStream[EndUser] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/users/$userId").map(_.decodeAs[EndUser])

  def getWebhooks(appKey: AppKey, accId: Int, pageNum: Int, limit: Int = 15): EventStream[AurinkoApiPage[ClientSubscription]] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId/webhookSubscriptions", queryParams = Map(
      "offset" -> Some(s"${pageNum * limit}"),
      "limit" -> Some(s"$limit"),
    )).map(_.decodeAs[AurinkoApiPage[ClientSubscription]])

  def getProcessingErrors(appKey: AppKey, accId: Int, dataConsumerId: Int, pageNum: Int, limit: Int = 10): EventStream[AurinkoApiPage[ProcessingErrors]] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId/webhookSubscriptions/$dataConsumerId/processingErrors", queryParams = Map(
      "offset" -> Some(s"${pageNum * limit}"),
      "limit" -> Some(s"$limit"),
    )).map(_.decodeAs[AurinkoApiPage[ProcessingErrors]])

  def getWebhooksEvents(appKey: AppKey, accId: Int, dataConsumerId: Int, pageNum: Int, limit: Int = 15, deliver: Boolean = true): EventStream[AurinkoApiPage[ClientSubscriptionEvent]] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId/webhookSubscriptions/$dataConsumerId/events", queryParams = Map(
      "offset" -> Some(s"${pageNum * limit}"),
      "limit" -> Some(s"$limit"),
      "undelivered" -> Some(s"$deliver")

    )).map(_.decodeAs[AurinkoApiPage[ClientSubscriptionEvent]])

  def account(appKey: AppKey, accId: Int): EventStream[PortalAccount] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId").map(_.decodeAs[PortalAccount])

  def accountToken(appKey: AppKey, accId: Int, tokenId: Int): EventStream[String] = apiRequester.get(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId/tokens/$tokenId").map(_.decodeAs[TokenInfo]).map(_.token)

  def accountErrors(appKey: AppKey, accId: Int, pageNum: Int, limit: Int = 15): EventStream[AurinkoApiPage[AccountError]] = apiRequester.get(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId/errors", queryParams = Map(
      "offset" -> Some(s"${pageNum * limit}"),
      "limit" -> Some(s"$limit"),
    )).map(_.decodeAs[AurinkoApiPage[AccountError]])

  def updateAccount(
                     appKey: AppKey,
                     accId: Int,
                     accountModel: PortalAccountEditModel,
                   ): EventStream[PortalAccount] =
    apiRequester.patch(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId",
      body = Some(accountModel.toJson),
    ).map(_ => accountModel.toApiModel)

  def deleteAccount(appKey: AppKey, accId: Int): EventStream[Unit] = apiRequester.delete(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId").map(_ => ())

  def accountAuthUrl(appKey: AppKey, serviceType: ServiceType, daemon: Boolean, scopes: List[String], clientOrgId: String, accountId: Option[Int]) =
    s"""$apiUrl/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/authorize/${serviceType.value}?daemon=$daemon${
      if (scopes.nonEmpty) {
        s"""&scopes=${scopes.mkString(" ")}"""
      } else ""
    }${accountId.map(id => s"&accountId=$id").getOrElse("")}${
      Option.when(clientOrgId.nonEmpty) {
        s"&clientOrgId=$clientOrgId"
      }.getOrElse("")
    }"""

  def storage(appKey: AppKey, storeType: StoreType, instId: Option[String],
              offset: Int, limit: Int = standardApiPageSize, search: String): EventStream[Storage] = apiRequester
    .get(
      if (storeType == StoreType.application) s"/teams/${appKey.teamId}/apps/${appKey.appId}/storage"
      else s"/teams/${appKey.teamId}/apps/${appKey.appId}/$storeType/${instId.get}/storage",
      queryParams = Map(
        "limit" -> Some(limit.toString),
        "offset" -> Some(offset.toString),
        "search" -> Option.when(search.nonEmpty) {
          search
        }
      )).map(_.decodeAs[Storage])


  def storageValue(appKey: AppKey, storeType: StoreType, instId: Option[String], key: String): EventStream[String] = apiRequester
    .get(
      if (storeType == StoreType.application) s"/teams/${appKey.teamId}/apps/${appKey.appId}/storage/${URIUtils.encodeURIComponent(key)}"
      else s"/teams/${appKey.teamId}/apps/${appKey.appId}/$storeType/${instId.get}/storage/${URIUtils.encodeURIComponent(key)}"
    ).map(_.decodeAs[Map[String, String]]).map(_.getOrElse("value", ""))

  def updateStorageValue(appKey: AppKey, storeType: StoreType, instId: Option[String], key: String, newValue: String): EventStream[Unit] = apiRequester
    .put(
      if (storeType == StoreType.application) s"/teams/${appKey.teamId}/apps/${appKey.appId}/storage/${URIUtils.encodeURIComponent(key)}"
      else s"/teams/${appKey.teamId}/apps/${appKey.appId}/$storeType/${instId.get}/storage/${URIUtils.encodeURIComponent(key)}",
      body = Some(Map("value" -> newValue).asJson)
    ).mapTo(())

  def deleteStorageMapping(appKey: AppKey, storeType: StoreType, instId: Option[String], key: String): EventStream[Unit] = apiRequester
    .delete(
      if (storeType == StoreType.application) s"/teams/${appKey.teamId}/apps/${appKey.appId}/storage/${URIUtils.encodeURIComponent(key)}"
      else s"/teams/${appKey.teamId}/apps/${appKey.appId}/$storeType/${instId.get}/storage/${URIUtils.encodeURIComponent(key)}"
    ).mapTo(())

  def updateAppSettings(appKey: AppKey, appModel: PortalApplication): EventStream[PortalApplication] = apiRequester
    .put(
      path = s"/teams/${appKey.teamId}/apps/${appKey.appId}/settings",
      body = Some(appModel.asJson),
    )
    .map(_.decodeAs[Map[String, String]]).map(_.getOrElse("status", "").toLowerCase() == "ok").map(if (_) appModel else null)

  // usage
  def profileUsage(appKey: AppKey,
                   fromDate: String,
                   toDate: String,
                   limit: Int = 5,
                   // pastDays: Int = 30
                  ): EventStream[List[Usage]] = {
    // println(s"Sending request from=$fromDate to=$toDate")
    apiRequester
      .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/usage/profiles",
        queryParams = Map(
          "from" -> Some(s"$fromDate"),
          "to" -> Some(s"$toDate"),
          "limit" -> Some(s"$limit"),
          // "pastDays" -> Some(s"$pastDays")
        ),
        showSpinner = false).map(_.decodeAs[AurinkoApiPage[Usage]]).map(_.records
      )
  }

  def appsUsage(teamId: Int,
                //                fromDate: String,
                //                toDate: String,
                pastDays: Int = 30
               ): EventStream[List[Usage]] = apiRequester
    .get(s"/teams/${teamId}/usage/apps", queryParams = Map(
      //   "from" -> Some(s"$fromDate"),
      //   "to" -> Some(s"$toDate"),
      "pastDays" -> Some(s"$pastDays")
    ), showSpinner = false).map(_.decodeAs[AurinkoApiPage[Usage]]
    ).map(_.records)

  def appUsage(appKey: AppKey,
               pastDays: Int = 30
              ): EventStream[List[Usage]] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/usage",
      queryParams = Map(
        "pastDays" -> Some(s"$pastDays")
      ),
      showSpinner = false).map(_.decodeAs[AurinkoApiPage[Usage]]).map(_.records)

  def accountUsage(appKey: AppKey,
                   accId: Int,
                   //                   fromDate: String,
                   //                   toDate: String,
                   pastDays: Int = 30
                  ): EventStream[List[Usage]] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId/usage", queryParams = Map(
      //      "from" -> Some(s"$fromDate"),
      //      "to" -> Some(s"$toDate"),
      "pastDays" -> Some(s"$pastDays")
    ), showSpinner = false).map(_.decodeAs[AurinkoApiPage[Usage]]).map(_.records)

  /* billing */
  object Billing {

    def getBillingInfo(teamId: Int): EventStream[BillingInfo] = apiRequester
      .get(s"/teams/$teamId/billing/info")
      .map(_.decodeAs[BillingInfo])

    def getPlans(teamId: Int): EventStream[List[BillingPlan]] = apiRequester
      .get(s"/teams/$teamId/billing/plans")
      .map(_.decodeAs[List[BillingPlan]])

    def defaultPlan(teamId: Int) = apiRequester
      .get(s"/teams/$teamId/billing/plans/default")
      .map(_.decodeAs[BillingPlan])


    def getPaymentMethod(teamId: Int): EventStream[PaymentMethod] = apiRequester
      .get(path = s"/teams/$teamId/billing/payment_method")
      .map(_.decodeAs[PaymentMethod])


    def updatePaymentMethod(teamId: Int, paymentMethod: PaymentMethodUpdate): EventStream[PaymentMethod] = apiRequester
      .put(
        path = s"/teams/$teamId/billing/payment_method",
        body = paymentMethod.asJson.deepDropNullValues.some)
      .map(_.decodeAs[PaymentMethod])

    def getInvoices(teamId: Int, pageToken: Option[String]): EventStream[BillingApiMany[BillingInvoice]] =
      apiRequester
        .get(
          path = s"/teams/$teamId/billing/invoices",
          queryParams = Map("nextPageToken" -> pageToken))
        .map(_.decodeAs[BillingApiMany[BillingInvoice]])
    //    MockData.invoicesReply.streamed

    def getInvoice(teamId: Int, invoiceId: String): EventStream[BillingInvoice] = apiRequester
      .get(invoiceId match {
        case "upcoming" => s"/teams/$teamId/billing/invoice/$invoiceId"
        case _ => s"/teams/$teamId/billing/invoices/$invoiceId"
      },
        showSpinner = false
      )
      .map(_.decodeAs[BillingInvoice])

    def getCountries(teamId: Int, search: Option[String] = None): EventStream[List[CountryInfo]] = apiRequester
      .get(s"/teams/$teamId/billing/countries", queryParams = Map("search" -> search))
      .map(_.decodeAs[Map[String, List[CountryInfo]]])
      .map(_("list"))

    //    def getUpcomingInvoice(teamId: Int): EventStream[BillingInvoice] = apiRequester
    //      .get(
    //        path = s"/teams/$teamId/billing/invoices/upcoming")
    //      .map(_.decodeAs[BillingInvoice])


  }

  def getBookProfile(appKey: AppKey, accId: Int, pageNum: Int, limit: Int = 10): EventStream[AurinkoApiPage[BookProfile]] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId/book/profiles", queryParams = Map(
      "limit" -> Some(s"$limit"),
      "offset" -> Some(s"${pageNum * limit}")
    )).map(_.decodeAs[AurinkoApiPage[BookProfile]])

  def createBook(appKey: AppKey, accId: Int, book: Book): EventStream[Unit] = apiRequester
    .post(s"/teams/${appKey.teamId}/apps/${appKey.appId}/accounts/$accId/book/profiles",
      body = Some(book.asJson.dropNullValues)
    )
    .mapTo(())

  def getRelativePath[T](path: String, responseType: String = ""): EventStream[T] = apiRequester.getFromCustomUrl(urls.wlo + path, responseType = responseType)


  implicit val ldtDecoder: Decoder[Instant] = Decoder[String].map(Instant.parse)
  implicit val ldtEncoder: Encoder[Instant] = Encoder[String].contramap(_.toString)
}

case class TeamApp(team: PortalTeam, app: PortalApplication) {
  val appKey: AppKey = AppKey(team.id, app.id)
}

object MockData {
  val invoices =
    s"""[{
       |    "id": "",
       |    "amountDue": 300,
       |    "amountPaid": 0,
       |    "amountRemaining": 300,
       |    "attemptCount": 0,
       |    "attempted": false,
       |    "created": "2023-08-28T11:50:56Z",
       |    "periodStart": "2023-07-28T11:50:56Z",
       |    "periodEnd": "2023-08-28T11:50:56Z",
       |    "currency": "usd",
       |    "status": "upcoming",
       |    "details": [
       |        {
       |            "appId": 3,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 0,
       |                    "units": 0
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 0,
       |            "units": 0
       |        },
       |        {
       |            "appId": 5,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 300,
       |                    "units": 3
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 300,
       |            "units": 3
       |        }
       |    ]
       |},
										|{
       |    "id": "in_1NaFPHCRgCJMX6npL9GWY4rg1",
       |    "amountDue": 300,
       |    "amountPaid": 0,
       |    "amountRemaining": 300,
       |    "attemptCount": 0,
       |    "attempted": false,
       |    "created": "2023-08-28T11:50:56Z",
       |    "periodStart": "2023-07-28T11:50:56Z",
       |    "periodEnd": "2023-08-28T11:50:56Z",
       |    "currency": "usd",
       |    "status": "paid",
       |    "details": [
       |        {
       |            "appId": 3,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 0,
       |                    "units": 0
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 0,
       |            "units": 0
       |        },
       |        {
       |            "appId": 5,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 300,
       |                    "units": 3
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 300,
       |            "units": 3
       |        }
       |    ]
       |},
										|{
       |    "id": "in_2NaFPHCRgCJMX6npL9GWY4rg2",
       |    "amountDue": 300,
       |    "amountPaid": 0,
       |    "amountRemaining": 300,
       |    "attemptCount": 0,
       |    "attempted": false,
       |    "created": "2023-08-28T11:50:56Z",
       |    "periodStart": "2023-07-28T11:50:56Z",
       |    "periodEnd": "2023-08-28T11:50:56Z",
       |    "currency": "usd",
       |    "status": "paid",
       |    "details": [
       |        {
       |            "appId": 3,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 0,
       |                    "units": 0
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 0,
       |            "units": 0
       |        },
       |        {
       |            "appId": 5,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 300,
       |                    "units": 3
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 300,
       |            "units": 3
       |        }
       |    ]
       |},
										|{
       |    "id": "in_1NaFPHCRgCJMX6npL9GWY5rg4",
       |    "amountDue": 300,
       |    "amountPaid": 0,
       |    "amountRemaining": 300,
       |    "attemptCount": 0,
       |    "attempted": false,
       |    "created": "2023-08-28T11:50:56Z",
       |    "periodStart": "2023-07-28T11:50:56Z",
       |    "periodEnd": "2023-08-28T11:50:56Z",
       |    "currency": "usd",
       |    "status": "unpaid",
       |    "details": [
       |        {
       |            "appId": 3,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 0,
       |                    "units": 0
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 0,
       |            "units": 0
       |        },
       |        {
       |            "appId": 5,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 300,
       |                    "units": 3
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 300,
       |            "units": 3
       |        }
       |    ]
       |},
										|{
       |    "id": "in_1NaFPHCRgCJMX6npL9GWY4rg5",
       |    "amountDue": 300,
       |    "amountPaid": 0,
       |    "amountRemaining": 300,
       |    "attemptCount": 0,
       |    "attempted": false,
       |    "created": "2023-08-28T11:50:56Z",
       |    "periodStart": "2023-07-28T11:50:56Z",
       |    "periodEnd": "2023-08-28T11:50:56Z",
       |    "currency": "usd",
       |    "status": "paid",
       |    "details": [
       |        {
       |            "appId": 3,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 0,
       |                    "units": 0
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 0,
       |            "units": 0
       |        },
       |        {
       |            "appId": 5,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 300,
       |                    "units": 3
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 300,
       |            "units": 3
       |        }
       |    ]
       |},
										|{
       |    "id": "in_1NaFPHCDgCJMX6npL9GWY4rg6",
       |    "amountDue": 300,
       |    "amountPaid": 0,
       |    "amountRemaining": 300,
       |    "attemptCount": 0,
       |    "attempted": false,
       |    "created": "2023-08-28T11:50:56Z",
       |    "periodStart": "2023-07-28T11:50:56Z",
       |    "periodEnd": "2023-08-28T11:50:56Z",
       |    "currency": "usd",
       |    "status": "paid",
       |    "details": [
       |        {
       |            "appId": 3,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 0,
       |                    "units": 0
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 0,
       |            "units": 0
       |        },
       |        {
       |            "appId": 5,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 300,
       |                    "units": 3
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 300,
       |            "units": 3
       |        }
       |    ]
       |},
										|{
       |    "id": "id_1NaFPHCRgCJMX6npL9GWY4rdt7",
       |    "amountDue": 300,
       |    "amountPaid": 0,
       |    "amountRemaining": 300,
       |    "attemptCount": 0,
       |    "attempted": false,
       |    "created": "2023-08-28T11:50:56Z",
       |    "periodStart": "2023-07-28T11:50:56Z",
       |    "periodEnd": "2023-08-28T11:50:56Z",
       |    "currency": "usd",
       |    "status": "paid",
       |    "details": [
       |        {
       |            "appId": 3,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 0,
       |                    "units": 0
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 0,
       |            "units": 0
       |        },
       |        {
       |            "appId": 5,
       |            "tiers": [
       |                {
       |                    "tierId": 1,
       |                    "tierName": "Tier 1",
       |                    "amount": 300,
       |                    "units": 3
       |                },
       |                {
       |                    "tierId": 2,
       |                    "tierName": "Tier 2",
       |                    "amount": 0,
       |                    "units": 0
       |                }
       |            ],
       |            "amount": 300,
       |            "units": 3
       |        }
       |    ]
       |}]""".stripMargin

  val invoicesReply = BillingApiMany[BillingInvoice](
    nextPageToken = "nextPageTokenTest".some,
    length = 6,
    records = invoices.decodeAs[List[BillingInvoice]]

  )
}
