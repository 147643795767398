package root_pages.aurinko_pages.app.sync

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Fab
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import common.airstream_ops.SignalOps
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.mat_components_styles.fixFabStyle
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.{CalendarQuery, InstantOps, JsonEnum, SyncApiPage, SyncObjFilters, SyncRuleType}
import org.scalajs.dom
import root_pages.aurinko_pages.app.sync.widgets.{Flags, MergeHistoryGrid, ServicesGrid, SortableTableColumnHeader}
import portal_router.SyncUserPage
import service.apis.sync_api.SyncApi
import service.scroll_ops.ScrollOps

case object UserCalendarSyncComponent {
  case class Handler(
                      $route: Signal[SyncUserPage],
                      syncApi: SyncApi,
                      filters: SyncObjFilters
                    ) {
    val reloadBus = new EventBus[Unit]
    val $events: EventStream[SyncApiPage[CalendarQuery]] = reloadBus.events.sample($route)
      .flatMap { route =>
        a
        syncApi.ruleQuery(
          route.appKey,
          route.orgId,
          route.userId,
          SyncRuleType.Calendar,
          filters,
        )
      }
      .map(_.asInstanceOf[SyncApiPage[CalendarQuery]])


    def renderRow(routeInfo: SyncUserPage)(
      @annotation.unused id: Int,
      initialEvent: CalendarQuery,
      $shortEvent: EventStream[CalendarQuery],
    ): Div = {
      val event: Var[(CalendarQuery, Boolean)] = Var[(CalendarQuery, Boolean)]((CalendarQuery(id = 0), false))
      val $event: Signal[CalendarQuery] = event.signal.map(_._1)
      val expanded: Var[Boolean] = Var(false)

      val panel = ExpansionPanelComponent(
        header = div(
          cls := "slds-grid slds-grid--vertical-align-center growing-block au-truncate",
          span(cls := "slds-size--1-of-12 au-truncate", child.text <-- $event.map(_.id)),
          span(cls := "slds-size--3-of-12 slds-m-right--small au-truncate", child.text <-- $event.map(_.subject.getOrElse(""))),
          span(cls := "slds-size--1-of-12 slds-m-right--small au-truncate", child.text <-- $event.map(_.source).map { case None => "" case Some(v) => v }),
          span(cls := "slds-size--2-of-12 slds-m-right--small au-truncate", child.text <-- $event.map(_.startTime).map { case None => "" case Some(v) => v.toPrettyLocalFormat }),
          span(cls := "slds-size--2-of-12 slds-m-right--small au-truncate", child.text <-- $event.map(_.updateTime).map { case None => "" case Some(v) => v.toPrettyLocalFormat }),
          div(
            cls := "slds-size--3-of-12 au-truncate",
            Flags(
              $event
                .map(e => (
                  (e.autoMerged, Flags.Badge(label = "auto merged")) ::
                    (e.syncEnabled, Flags.Badge(label = "sync enabled")) ::
                    (e.sameAsId > 0 && e.skipMerge, Flags.Badge(label = "target to merge")) ::
                    (e.sameAsId > 0 && e.sureMatch, Flags.Badge(label = "duplicate")) ::
                    (e.errorsDuringSync, Flags.Badge(label = "error", cls = "orange")) ::
                    (e.`private`, Flags.Badge(label = "private")) ::
                    Nil
                  ).filter(v => v._1).map(_._2)),
            ),
          ),
        ),
        body = Some(div(
          child.maybe <-- $event.map(_.projName).map {
            case None => None
            case Some(calName) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Calendar"),
              span(cls := "", calName),
            ))
          },

          child.maybe <-- $event.map(_.eventTypes).map {
            case Nil => None
            case types => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Event type"),
              span(cls := "", types.mkString(", ").capitalize),
            ))
          },

          div(
            cls := "slds-grid  slds-grid--vertical slds-m-bottom--medium",
            small(cls := "gray", "Reminder"),
            span(
              cls := "",
              child.text <-- $event.map {
                case e if e.remind => s"${e.reminderPeriod} ${e.reminderUnit.getOrElse("")}"
                case _ => "None"
              }
            ),
          ),

          Flags.Grid(
            $event.map(e => (
              (e.autoMerged, Flags.Badge(label = "Auto merged")) ::
                (e.syncEnabled, Flags.Badge(label = "Sync enabled")) ::
                (e.sameAsId > 0 && e.skipMerge, Flags.Badge(label = "Target to merge")) ::
                (e.sameAsId > 0 && e.sureMatch, Flags.Badge(label = "Duplicate")) ::
                (e.errorsDuringSync, Flags.Badge(label = "Error", cls = "orange")) ::
                (e.`private`, Flags.Badge(label = "Private")) ::
                Nil
              ).filter(v => v._1).map(_._2))
          ),

          child.maybe <-- $event.map(_.updateTime).map {
            case None => None
            case Some(updateTime) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Updated at"),
              span(cls := "", updateTime.toPrettyLocalFormat),

            ))
          },

          child.maybe <-- $event.map(_.description).map {
            case None => None
            case Some(description) => Some(div(
              cls := "slds-grid  slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Description"),
              pre(cls := "slds-m-around--none", description),
            ))
          },

          ServicesGrid($event.map(_.accountsSummary.getOrElse(Nil))),

          child.maybe <-- $event.map(_.eventContacts).map {
            case attendees if attendees.getOrElse(Nil).nonEmpty => Some(div(
              cls := "slds-grid  slds-grid--vertical",
              p(cls := "gray slds-size--1-of-8 au-truncate slds-m-top--x-large", "Attendees"),
              div(
                cls := "data-table slds-size--7-of-8 slds-m-bottom--medium",
                div(
                  cls := "table-header slds-m-top--xx-small",
                  span(cls := "slds-size--4-of-6", "Name"),
                  span(cls := "slds-size--1-of-6", "Type"),
                  span(cls := "slds-size--1-of-6", "Response"),
                ),
                attendees.getOrElse(Nil).map { attendee =>
                  div(
                    cls := "table-row border-top--light",
                    span(cls := "slds-size--4-of-6", attendee.displayName),
                    span(cls := "slds-size--1-of-6", attendee.`type`.toLowerCase.capitalize),
                    span(cls := "slds-size--1-of-6", attendee.response.getOrElse("").toLowerCase.capitalize),
                  )
                }
              )
            ))
            case _ => None
          },

          MergeHistoryGrid($event.map(_.mergeHistory.getOrElse(Nil))),

        )),
        horizontalPadding = false,
        expanded = expanded.signal,
        onExpansionChange = expanded.writer
      )

      div(
        $shortEvent --> Observer.combine(
          event.writer.contramap((x: CalendarQuery) => (x, true)),
          expanded.writer.contramap((_: CalendarQuery) => false),
        ),

        panel.$isExpanded
          .withCurrentValueOf(event.signal.map(_._2)).changes
          .filter(x => x._1 && x._2)
          .flatMap(_ => syncApi.ruleObject(routeInfo.appKey, routeInfo.orgId, routeInfo.userId, SyncRuleType.Calendar, initialEvent.id))
          .map(_.asInstanceOf[CalendarQuery])
          --> event.writer.contramap((x: CalendarQuery) => (x, false)),

        panel.node
      )
    }
  }

  object Filters extends Enumeration with JsonEnum {
    case class Val(name: String, label: String) extends super.Val(name)

    type Filters = Val
    type EnumValue = Val

    val autoMerged: Filters = Val("autoMerged", "Auto merged")
    val syncEnabled: Filters = Val("syncEnabled", "Sync enabled")
    val showDupes: Filters = Val("showDupes", "Duplicate")
    val `private`: Filters = Val("private", "Private")
    val errors: Filters = Val("errors", "Errors")

    val All: List[Filters] = autoMerged :: syncEnabled :: showDupes :: `private` :: errors :: Nil
  }

  object Columns extends Enumeration {
    case class Val(label: String, sizeMargCss: String, sortValue: Option[String] = None)

    type Columns = Val

    val id = Val("Id", "slds-size--1-of-12")
    val subject = Val("Subject", "slds-size--3-of-12 slds-m-right--small", Some("subject"))
    val source = Val("Source", "slds-size--1-of-12 slds-m-right--small")
    val startTime = Val("Start time", "slds-size--2-of-12  slds-m-right--small", Some("startTime"))
    val updateTime = Val("Update time", "slds-size--2-of-12  slds-m-right--small", Some("updateTime"))
    val badges = Val("", "slds-size--3-of-12", None)

    val all = id :: subject :: source :: startTime :: updateTime :: badges :: Nil
  }

  def apply(
             $route: Signal[SyncUserPage],
             syncApi: SyncApi,
             defaultFilters: Var[Set[String]] = Var(Set.empty),
             $reload: EventStream[Unit],
             documentScrollOps: ScrollOps
           ): HtmlElement = {
    val handler = Handler($route, syncApi, SyncObjFilters(defaultFilters))

    div(
      common.ui.Attribute.Selector := "sync.user_calendar_sync",

      $reload --> handler.reloadBus.writer,

      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread slds-m-top--medium",
        div(
          cls := "slds-grid slds-grid--vertical-align-center",
          Filters.All.map(filter =>
            Fab(
              _.label := filter.label,
              _.extended := true,
              _.showIconAtEnd := true,
              _.icon <-- handler.filters.$filters.map(_.contains(filter.name)).map { case true => "cancel" case false => "" },
              _ => cls := "slds-m-right--x-small",
              _ => cls <-- handler.filters.$filters.map(_.contains(filter.name)).map { case true => "" case _ => "inactive" },
              _ => onClick --> Observer[dom.MouseEvent](onNext = _ => handler.filters.toggleFilter(filter.name)),
              _ => onMountCallback(fixFabStyle)
            )
          )
        ),
        SearchInputComponent(onChange = handler.filters.searchText.writer).node
      ),

      child <-- $route.map { route =>

        div(
          child <-- handler.$events.map(_.summary.nonEmpty).toSignal().map {
            case true => div(
              cls := "data-table slds-m-top--medium",
              div(
                cls := "table-header with-expansion-panels",

                Columns.all.map {
                  case c if c.sortValue.isEmpty => span(c.label, cls := c.sizeMargCss)
                  case column => SortableTableColumnHeader(
                    label = column.label,
                    onSorted = Observer[dom.MouseEvent](onNext = _ => handler.filters.toggleSortParam(column.sortValue.get)),
                    state = handler.filters.sortParamState(column.sortValue.get),
                    cssClass = column.sizeMargCss
                  )
                }
              ),

              div(children <-- handler.$events.map(_.summary).split(_.id)(handler.renderRow(route))),

              div(
                cls <-- handler.$events.map(_.total < handler.filters.pagesLimit.now).map { case true => "hidden" case false => "" },
                Paginator(
                  pageNum = handler.filters.pageNum.signal,
                  totalCount = handler.$events.map(_.total).startWith(0),
                  pageSize = handler.filters.pagesLimit.signal,
                  onPageChange = Observer[(Int, Int)](onNext = i => {
                    handler.filters.pageNum.set(i._1)
                    handler.filters.pagesLimit.set(i._2)
                    handler.reloadBus.emit(())
                  }),
                  documentScrollTopAfterPageChange = true,
                  documentScrollOps.some,
                  itemsPluralLabel = "Events",
                ).node
              ),
            )
            case _ => p(cls := "gray slds-grid slds-grid--align-center slds-m-around--large", "No events")
          }
        )
      },

      handler.filters.$filters
        .combineWith(handler.filters.searchText.signal)
        .combineWith(handler.filters.$sorting)
        --> Observer[(Set[String], String, Set[String])](onNext = _ => {
        handler.filters.pageNum.set(0)
        handler.reloadBus.emit(())
      }),
    )
  }
}
