package root_pages.aurinko_pages.app.sync.widgets

import common.{InstantOps, MessageInfo}
import com.raquo.laminar.api.L._
import org.scalajs.dom
import com.github.uosis.laminar.webcomponents.material

case object MessageGrid {
  def render(
              @annotation.unused id: Int,
              initial: MessageInfo,
              $message: Signal[MessageInfo],
            ): Div = {

    val showEmailPanel: Var[Boolean] = Var(false)
    val $showEmailPanel: Signal[Boolean] = showEmailPanel.signal
    div(
      cls := "expansion-panel sync-emails",
      div(
        cls := "header",
        onClick --> Observer[dom.MouseEvent](_ => showEmailPanel.set(!showEmailPanel.now())),
        div(
          cls := "slds-grid slds-grid--vertical-align-center growing-block",
          div(
            cls := "au-truncate slds-size--1-of-12 slds-m-right--small",
            child.text <-- $message.map(_.id)
          ),
          div(
            cls := "slds-size--3-of-12 slds-m-right--small",
            child.text <-- $message.map(_.receivedTime.toPrettyLocalFormat)
          ),
          div(
            cls := "slds-size--6-of-12  slds-m-right--small au-truncate",
            child.maybe <-- $message.map(_.emailFrom.map(span(_))),
            span(cls := "slds-m-right--small", ","),
            child.maybe <-- $message.map(_.emailTo.map(span(_)))
          ),

          small(
            cls := "badge au-truncate slds-m-right--small slds-size--1-of-12",
            child.text <-- $message.map {
              case message if message.forwarded.contains(1) => "forwarded"
              case _ => ""
            }
          ),
          material.Icon(
            _ => cls := "slds-size--1-of-12  lighter mdc-theme--text-secondary-on-background slds-m-right--small au-truncate",
            _ => child.text <-- $showEmailPanel.map {
              case true => "expand_less"
              case _ => "expand_more"
            },
          ),
        ),
      ),
      div(cls := "body",
        styleAttr <-- $showEmailPanel.map(exp => if (exp) "display: block" else "display: none"),
        div(
          div(
            cls := "slds-grid slds-grid--vertical-align-end slds-m-bottom--medium",
            small(cls := "slds-size--1-of-12 gray slds-m-right--small", "Email from"),
            span(
              cls := "slds-m-right--small",
              child.text <-- $message.map(_.emailFrom.getOrElse(""))
            ),
            small(cls := "gray slds-m-right--small", "to"),
            span(
              child.text <-- $message.map(_.emailTo.getOrElse(""))
            )
          ),
          div(
            cls := "slds-grid slds-grid--vertical-align-start slds-m-bottom--medium",
            div(
              cls := "slds-size--1-of-12 slds-m-right--medium",
              small(cls := "gray", "Subject"),
            ),
            div(
              child.text <-- $message.map(_.subject.getOrElse(""))
            )
          ),
          div(
            cls := "slds-grid slds-grid--vertical-align-start slds-m-bottom--medium",
            div(
              cls := "slds-size--1-of-12 slds-m-right--medium",
              small(cls := "gray", "Details")
            ),
            div(
              Some(div(
                child.maybe <-- $message.map(_.message).map {
                  case None => Some("No details")
                  case Some(message) => Some(div(
                    cls := "slds-scrollable_y",
                    maxHeight := 200 + "px",
                    span(s"$message")
                  ))
                })))
          )
        )
      )
    )
  }

  def apply($messageInfoStream: Signal[List[MessageInfo]]): Div =
    div(
      common.ui.Attribute.Selector := "sync.widgets.merge_history_grid",
      child.maybe <-- $messageInfoStream.map(v => v.nonEmpty).map {
        case true => Some(div(
          cls := "slds-grid slds-size--9-of-12 slds-grid--vertical slds-m-bottom--medium",
          p(cls := "gray slds-size--1-of-8 au-truncate slds-m-bottom--medium", "Emails"),
          div(
            cls := "table-header",
            span(cls := "slds-size--1-of-12 slds-m-right--small", "Id"),
            span(cls := "slds-size--3-of-12 slds-m-right--small", "Received time"),
            span(cls := "slds-size--6-of-12", "Email from/ Email to"),
            span(cls := "slds-size--1-of-12")
          ),
          children <-- $messageInfoStream.split(_.id)(render),
        ))
        case false => None
      }
    )
}
