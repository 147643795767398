package root_pages.aurinko_pages.app.settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Checkbox, Dialog, Formfield, Icon, Textfield}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.{AppRegistrationModel, PingTools, PortalAppRegistration, ServiceType, asteriskString, forms}
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalNestedOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.ui.{AuFormStateExp, confirmDeletionPopup}
import common.forms.{CheckboxFormOps, FormsLocalExceptionHandling, TextareaFormOps, TextfieldFormOps}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.icons.{MaterialIcons, TrashIcon}
import common.ui.mat_components_styles.styleTextfieldWithPlaceholder
import common.value_opps.ValueOpps
import org.scalajs.dom
import org.scalajs.dom.html
import service.apis.portal_api.PortalApi
import service.clipboard_service.ClipboardService
import service.portal_state.PortalState
import wvlet.log.Logger

import scala.scalajs.js.timers.setTimeout

case class MSBot(
                  $appReg: Signal[Option[PortalAppRegistration]],
                  bus: EventBus[Unit],
                  portalApi: PortalApi,
                  portalState: PortalState,
                  serviceType: ServiceType,
                  $initialExpand: Signal[Boolean],
                  isDaemon: Boolean = false,
                  apiOrigin: String,
                  clipboardService: ClipboardService
                ) extends AppRegistrationComponent {

  private val log = Logger.of[DaemonBasic]

  val showDeletePopup: Var[Boolean] = Var(false)

  val deletePopup: Dialog.El = confirmDeletionPopup(
    onConfirm = Observer[Unit](onNext = _ => {
      bus.emit()
      showDeletePopup.set(false)
    }),
    onCancel = showDeletePopup.writer.contramap((_: Unit) => false),
    heading = "Delete registration",
    $visible = showDeletePopup.signal,
    onClose = showDeletePopup.writer.contramap((_: Unit) => false),
    onConfirmEventTransfer = () => EventStream.fromValue(())
      .sample($appReg)
      .map(_.get)
      .withCurrentValueOf(portalState.$teamApp.map(_.appKey))
      .flatMap(t => portalApi.deleteAppReg(t._2, t._1))
  ).amend(PingTools.dialogBinders(portalApi))

  private val iconImageBreadPoint: Var[String] = Var(MaterialIcons.copy)

  val node: Div = div(
    ExpansionPanelComponent(
      header =
        p(
          "MS Teams Bot OAuth",
          cls := "title--level-2",
          cls <-- $appReg.map(x => if (x.isEmpty) "light" else "")
        ),
      body = Some(
        div(
          cls := "slds-p-bottom_medium",
          child.maybe <-- errorView,

          $appReg.signal.map {
            case Some(v) => v.toEditModel.some
            case None => AppRegistrationModel(serviceType = ServiceType.msTeamsBot).some
          } --> editModel,

          children <-- editModel.signal.nestedMap(model => {
            val botEndpointField = Textfield(
              _ => cls := "low-disabling width-large",
              _.outlined := true,
              _.label := "Bot messaging endpoint",
              _.value <-- portalState.$app.map(app => if (app.domainAlias.isEmpty) // TODO: REVIEW: rethink via Option.map
                apiOrigin + "/push/office/bot/" + app.clientId.getOrElse(throw new Exception("Client ID is not defined")) + "/chat"
              else
                "https://" + app.domainAlias.get + "/push/office/bot/" + app.clientId.getOrElse(throw new Exception("Client ID is not defined")) + "/chat"
              ),
              _.disabled := true
            ).bindToForm(model.formState)

            deletePopup ::
              div(cls := "slds-m-bottom_medium", span("OAuth keys used to authenticate users to your app.")) ::
              div(
                cls := "slds-grid slds-m-bottom--medium",
                Textfield(
                  _ => cls := "width-medium",
                  _.outlined := true,
                  _.label := "Redirect URI",
                  _.`type` := "url",
                  _.placeholder <-- portalState.$app.map(_.defaultRedirect(apiOrigin)),
                  _.value <-- model.intermediateCallbackUrl,
                  _ => onInput.mapToValue --> model.intermediateCallbackUrl,
                  _ => onMountCallback(styleTextfieldWithPlaceholder),
                  _.helper := "Note: Please enter the url above as one of return URIs for your Azure AD app registration.",
                  _.helperPersistent := true

                ).bindToForm(model.formState),
                div(
                  cls := "slds-p-left_medium slds-p-top--large",
                  iconCopy($redirectUri(model, portalState, apiOrigin), clipboardService)
              )) ::
              div(
                cls := "slds-grid slds-grid_vertical-align-center slds-m-bottom_medium",
               botEndpointField,
                div(
                  cls := "slds-p-left_medium",
                  child <-- iconImageBreadPoint.signal.map { icon =>
                    Icon(
                      _ => icon,
                      _ => cls := "clickable light",
                      _ => cls := "slds-m-right--medium",
                    )
                  }).amend(
                  onClick.stopPropagation --> Observer[dom.MouseEvent](onNext = _ => {
                    iconImageBreadPoint.set(MaterialIcons.done)
                    setTimeout(1500) {
                      iconImageBreadPoint.set(MaterialIcons.copy)
                    }
                  clipboardService.copy(botEndpointField.ref.value)
                  }),
                )) ::
              div(cls := "slds-grid slds-grid_vertical-align-end",
                Textfield(
                  _ => cls := "slds-m-bottom_medium width-medium",
                  _.outlined := true,
                  _.label := "Client Id",
                  _.value <-- model.clientId.signal,
                  _ => onInput.mapToValue --> model.clientId
                ).bindToForm(model.formState)
            ) ::
              div(cls := "slds-grid slds-grid_vertical-align-end",
                Textfield(
                  _ => cls := "slds-m-bottom_medium width-medium",
                  _.outlined := true,
                  _.label := "Client secret",
                  _.required <-- $appReg.map {
                    case Some(_) => false
                    case None => true
                  },
                  _.placeholder <-- $appReg.map {
                    case Some(_) => asteriskString
                    case None => ""
                  },
                  _.value <-- model.clientSecret,
                  _ => onInput.mapToValue --> model.clientSecret,
                  _ => onMountCallback(ctx => if (model.hasSecret) styleTextfieldWithPlaceholder(ctx))
                ).bindToForm(model.formState)) ::
              div(cls := "slds-grid slds-grid_vertical-align-end",
                Textfield(
                  _ => cls := "width-medium",
                  _.outlined := true,
                  _.label := "Bot ID",
                  _.required := true,
                  _.value <-- model.topicName,
                  _ => onInput.mapToValue --> model.topicName,
                  _.helper := "Id from your manifest file (if your manifest has not been published to the store yet).",
                  _.helperPersistent := true
                ).bindToForm(model.formState)) ::
              Formfield(
                _ => cls := "slds-p-bottom_large",
                _.label := "App is installed from AppSource",
                _.slots.default(
                  Checkbox(
                    _.checked <-- model.externalBotId,
                    _.onChange.mapToChecked --> model.externalBotId
                  ).bindToForm(model.formState))
              ) ::
              KeyInputComponent(
                model.clientSecret2,
                "Private key",
                Signal.fromValue(model.hasPk)
              ).node ::
              KeyInputComponent(
                model.clientSecret3,
                "Certificate",
                Signal.fromValue(model.hasPk)
              ).node ::
              div(
                cls := "slds-p-top_xx-large slds-grid footer-buttons",
                cls <-- $appReg.map { case None => "slds-grid_align-end" case _ => "slds-grid_align-spread" },
                div(
                  cls := "",
                  cls <-- $appReg.map { case None => "hidden" case _ => "" },
                  TrashIcon(showDeletePopup.writer.contramap((_: dom.MouseEvent) => true))
                ),
                div(
                  cls := "dialog-submit-buttons",
                  ButtonsPairComponent[Unit, Option[PortalAppRegistration]](

                    disabled = editModel.signal.flatMap(_.$traverse(_.formState.$dirty)).$contains(false)
                      .combineWith(model.clientSecret2.signal.map(_.isEmpty))
                      .combineWith(model.clientSecret3.signal.map(_.isEmpty))
                      .map(t => t._1 && t._2 && t._3),

                    primaryDisabled = editModel.signal.flatMap(_.$traverse(_.formState.$submitAllowed)).$contains(false)
                      .combineWith(model.clientSecret2)
                      .combineWith(model.clientSecret3)
                      .combineWith(model.clientSecret)
                      .combineWith($appReg)
                      .map(t => t._1 || (t._2.isEmpty && t._3.nonEmpty) || (t._3.isEmpty && t._2.nonEmpty) || (t._4.isEmpty && t._5.isEmpty)),

                    primaryEffect = () => editModel.signal.stream
                      .collect{case Some(model) => model}
                      .withCurrentValueOf(portalState.$teamApp)
                      .flatMap{
                        case (model, teamApp) => portalApi.createOrUpdateAppReg(teamApp.appKey, model.toImmutableModel)}
                    .withErrorHandlingAndCollect(
                    FormsLocalExceptionHandling
                      .handler(str => editModel.now.foreach(_.formError.set(str.some)))),

                    primaryObserver = Observer[Unit](onNext = _ => {
                      bus.emit(())
                      model.clientSecret.set("")
                      model.clientSecret2.set("")
                      model.clientSecret3.set("")
                    }),

                    secondaryEffect = () => EventStream.fromValue(()).sample($appReg),

                    secondaryObserver = Observer[Option[PortalAppRegistration]](onNext = {

                      case Some(value) =>
                        model.updateModelFromImmutable(value)
                        model.formState.validate()
                      case None =>
                        model.resetModel()
                        model.formState.validate()
                    })
                  ).node,
                )
              ) :: Nil
          }).map(_.toList.flatten)
        )
      ),
      bordered = Signal.fromValue(true),
      expanded = $initialExpand.combineWith(isExpanded.signal).map(t => t._1 || t._2),
      onExpansionChange = isExpanded.writer
    ).node
  )

}

