package common.ui.paginator

import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Icon, Menu}
import com.raquo.laminar.api.L._
import service.scroll_ops.ScrollOps
import service.local_storage.{LocalStorageService, LocalStorageServiceImp}
import org.scalajs.dom

import wvlet.log.Logger

case class Paginator(
                      pageNum: Signal[Int],
                      totalCount: Signal[Int],
                      pageSize: Signal[Int],
                      onPageChange: Observer[(Int, Int)],
                      documentScrollTopAfterPageChange: Boolean = true, // TODO: REVIEW: not sure why we need this option
                      documentScrollOps: Option[ScrollOps],
                      itemsPluralLabel: String,
                    ) {

  private val pagesCount: Var[Int] = Var(0)
  private val page: Var[Int] = Var(1)
  private val showPagesMenu: Var[Boolean] = Var(false)
  private val localStorage = LocalStorageServiceImp()
  val pageSizeValue: Var[Int] = Var(50)
  val rowsStartValue: Var[Int] = Var(0)
  val rowsEndValue: Var[Int] = Var(0)
  val totalCountRows: Var[Int] = Var(0)
  private val showOffsetMenu: Var[Boolean] = Var(false)

  private val scrollObserver = Observer[(Int, Int)](onNext = _ => {
    if (documentScrollTopAfterPageChange) {
      documentScrollOps.foreach(_.scrollToTop())
    }

  })
  //  private val savePageSizeObserver = Observer(pageSize => {
  //    localStorage.setItem("pageSize", pageSize.toString)
  //  })

  private val log = Logger.of[Paginator]

  val node: Div = div(
    cls := "slds-m-top_xx-large",

    totalCount
      .combineWith(pageSize)
      .map {
        case (total, limit) => (total.toFloat / limit).ceil.toInt
      } --> pagesCount.writer,

    pageNum.map(_ + 1) --> page.writer,
    pageSize.map(x => x) --> pageSizeValue.writer,
    totalCount.map(x => x) --> totalCountRows.writer,

    pageSize
      .combineWith(pageNum)
      .map(d => d._1 * d._2 + 1) --> rowsStartValue.writer,

    pageSize
      .combineWith(pageNum)
      .map(d => d._1 * d._2 + d._1) --> rowsEndValue.writer,

    //    pageSize
    //      .combineWith(pageNum)
    //      .map {
    //        case (limit, num) => ((limit * num) + limit)
    //      } --> rowsEndValue.writer,

//    totalCountRows.signal
//      .combineWith(pageSizeValue.signal) --> Observer[(Int, Int)](
//      t => log.info(s"totalCount: ---> ${t._1} -> pageSizeValue: ---> ${t._2}")
//    ),

    div(
      cls := "slds-grid slds-grid_vertical-align-start",
      child <-- pagesCount.signal
        .map { count =>
//          log.info(s"count in pagesCount: $count")
          val totalList = List.range(1, count + 1)
          val selectedValue: Var[String] = Var("Go to page")
          div(
            cls := "slds-is-relative",
            div(
              cls := "clickable border-around--light slds-p-horizontal--x-small gray slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
              span(
                cls := "text-x-small",
                child.text <-- selectedValue),
              onClick.mapTo(!showPagesMenu.now) --> showPagesMenu.writer,
              Icon(
                _ => "chevron_right",
                _ => cls := "slds-m-left--small moved-right--x-small"
              )
            ),
            Menu(
              _ => cls := "dropdown_menu left paginator-select",
              _.open <-- showPagesMenu,
              _.onClosed.mapTo(false) --> showPagesMenu.writer,

              _ => div(
                maxHeight := "100px",
                totalList.map(pages => {
                  ListItem(
                    _.value := s"$pages",
                    _ => height := 30 + "px",
                    _ => span(pages),
                    _ => onClick --> Observer[dom.MouseEvent](_ => {
                      showPagesMenu.set(false)
                    }),
                    _ => onClick.mapTo((pages - 1) -> pageSizeValue.now()) --> Observer.combine(onPageChange, scrollObserver),
                  )
                })
              )
            )
          )
        },

      child <-- page.signal
        .combineWith(pagesCount.signal)
        .combineWith(totalCountRows.signal)
        .combineWith(rowsStartValue.signal)
        .combineWith(rowsEndValue.signal)
        .map { case (num, count, rows, rowsStart, rowsEnd) =>
          log.info(s"num: $num, count: $count")
          div(
            cls := "slds-grid slds-grid_vertical slds-grid_vertical-align-center slds-grid_align-center slds-size--10-of-12",
            div(
              cls := "slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-m-bottom_small",

              Icon(
                _ => "chevron_left",
                _ => cls.toggle("secondary clickable")(num > 1),
                _ => cls.toggle("light-grey")(num <= 1),
                _ => onClick
                  .filter(_ => num >= 2)
                  .mapTo(Signal.fromValue(num).now() - 2 -> pageSizeValue.now()) --> Observer.combine(onPageChange, scrollObserver)
              ),

              if (num > 3) {
                div(
                  span("1"), cls := "paginator_page-button",
                  onClick.mapTo(0 -> pageSizeValue.now()) --> Observer.combine(onPageChange, scrollObserver)
                )
              } else None,
              cls := "slds-grid",
              for (i <- num - 2 to num + 2 if i > 0 && i <= count)
                yield {
                  log.info(s"count: $count num: $num i: $i")
                  val showDots: Boolean = i > 1 && i == num - 2 || i < count && i == num + 2
                  div(
                    cls := "paginator_page-button",
                    cls := (if (i == num) "selected" else ""),
                    cls := (if (showDots) "disabled" else ""),
                    span(if (showDots) "..." else i),
                    onClick.mapTo(i - 1 -> pageSizeValue.now()).filter(_ => !showDots) --> Observer.combine(onPageChange, scrollObserver)
                  )
                },
              if (count - num >= 3) {
                div(
                  span(count),
                  cls := "paginator_page-button",
                  onClick.mapTo(count - 1 -> pageSizeValue.now()) --> Observer.combine(onPageChange, scrollObserver)
                )
              } else None,

              Icon(
                _ => "chevron_right",
                _ => cls.toggle("secondary clickable")(num < count),
                _ => cls.toggle("light-grey")(num == count),
                _ => onClick
                  .filter(_ => num < count)
                  .mapTo(Signal.fromValue(num).now() -> pageSizeValue.now()) --> Observer.combine(onPageChange, scrollObserver)
              )
            ),

            span(
              cls := "gray slds-m-top--small text-x-small",

              if (rowsEnd <= rows) s"$itemsPluralLabel from  $rowsStart to $rowsEnd of $rows"
              else if (rowsStart <= rowsEnd || rowsEnd == rows) s"$itemsPluralLabel from  $rowsStart to $rows of $rows"
              else s"$itemsPluralLabel from  $rows to $rows of $rows"
            )
          )
        }

      //      child <-- totalCountRows.signal
      //        .combineWith(pageNum).map { case (total, num) =>
      //        val rowsList: List[Int] = List(15, 30, 50)
      //        //val rowsList: List[Int] = List(10, 15, 20, 30, 50)
      //
      //        div(
      //          cls := "slds-grid slds-grid--vertical-align-center",
      //          span(
      //            cls := "slds-m-right_medium gray text-x-small",
      //            "Shows rows per page"
      //          ),
      //          div(
      //            cls := "slds-is-relative",
      //            div(
      //              cls := "clickable border-around--light slds-p-horizontal--x-small gray slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
      //              child.text <-- pageSizeValue,
      //              onClick.mapTo(!showOffsetMenu.now) --> showOffsetMenu.writer,
      //              Icon(
      //                _ => "arrow_drop_down",
      //                _ => cls := "slds-m-left--small lighter"
      //              )
      //            ),
      //            Menu(
      //              _ => cls := "dropdown_menu right paginator-select",
      //              _.open <-- showOffsetMenu,
      //              _.onClosed.mapTo(false) --> showOffsetMenu.writer,
      //              _ => div(
      //                maxHeight := "100px",
      //                rowsList.map(t => {
      //                  ListItem(
      //                    _ => cls := "slds-scrollable_y",
      //                    _ => height := 30 + "px",
      //                    _.value := s"${t}",
      //                    _.selected := t == 30,
      //                    _ => span(t),
      //                    _ => onClick.mapTo(t) --> Observer[Int](onNext = selectedValue => {
      //                      showOffsetMenu.set(false)
      //                      pageSizeValue.set(selectedValue)
      //                      localStorage.setItem("pageSize", selectedValue.toString)
      //
      //                    }),
      //                    _ => onClick.mapTo(0 -> pageSizeValue.now) --> Observer.combine(onPageChange, scrollObserver),
      //
      //                  )
      //                }
      //                )
      //              )
      //            )
      //          )
      //        )
      //      },
    ),
  )
}
