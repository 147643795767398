package root_pages.aurinko_pages.app.settings

import com.raquo.laminar.api.L._
import com.github.uosis.laminar.webcomponents.material.Slider.onInput
import com.github.uosis.laminar.webcomponents.material.Textarea.El
import com.github.uosis.laminar.webcomponents.material.{Textarea, Textfield}
import com.raquo.airstream.core.Signal
import com.raquo.airstream.state.Var
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.{ApplicationLogo, PortalAppRegistration, asteriskString}
import common.ui.AuFormField
import common.ui.mat_components_styles.{styleTextareaWithPlaceholder, styleTextfieldWithPlaceholder}
import org.scalajs.dom
import org.scalajs.dom.{FileList, FileReader, html}
import org.scalajs.dom.raw.HTMLImageElement

import java.awt.TextArea
import java.nio.ByteBuffer
import java.util.Base64
import scala.scalajs.js.typedarray.{ArrayBuffer, TypedArrayBuffer}

case class KeyInputComponent(keyStringVar: Var[String], fieldLabel: String, $showPlaceholder: Signal[Boolean]) {
  val formats: List[String] = List(
    "application/pkcs8",
    "application/pkcs10",
    "application/pkix-cert",
    "application/pkix-crl",
    "application/pkcs7-mime",
    "application/x-x509-ca-cert",
    "application/x-x509-user-cert",
    "application/x-pkcs7-crl",
    "application/x-pem-file",
    "application/x-pkcs12",
    "application/x-pkcs7-certificates",
    "application/x-pkcs7-certreqresp"
  )

  val hiddenFileInput: ReactiveHtmlElement[html.Input] = input(
    typ := "file",
    display := "none",
    onInput.map(e => e.target.asInstanceOf[dom.html.Input].files) --> Observer[dom.FileList](onNext = setVarFromKeyFile)
  )

  def keyArea(showPlaceholder: Boolean): El = Textarea(
    _ => cls := "width-large light slds-m-bottom_medium",
    _.outlined := true,
    _.rows <-- keyStringVar.signal.map(_.count(_ == '\n')),
    _.`type` := "password",
    _.label := fieldLabel,
    _.placeholder := (if (showPlaceholder) {
      asteriskString
    } else {
      ""
    }),
    _.value <-- keyStringVar,
    _ => onInput.mapToValue --> keyStringVar,
    _ => onMountCallback(ctx => if (showPlaceholder) styleTextareaWithPlaceholder(ctx))
  )

  val node: ReactiveHtmlElement[html.Div] = div(
    cls := "slds-grid slds-grid_vertical-align-center",
    child <-- $showPlaceholder.map(keyArea),
    span(
      cls := "clickable blue slds-m-left--medium",
      "Upload File",
      onClick --> Observer[dom.MouseEvent](onNext = _ => hiddenFileInput.ref.click())
    ),
    hiddenFileInput
  )

  def setVarFromKeyFile(givenFile: FileList): Unit = if (givenFile.length > 0) {
    val file = givenFile.item(0)

    if (formats.contains(file.`type`)) {
      val fileReader = new FileReader
      fileReader.onload = _ => {
        val res: String = fileReader.result.asInstanceOf[String]
        keyStringVar.set(res)
      }
      fileReader.readAsText(file)
      hiddenFileInput.ref.value = ""
    }
  } else ()
}

case object KeyInputComponent {
  def stringReplacer(initStr: String): String = initStr.replace("-----BEGIN CERTIFICATE-----", "").
    replace("-----END CERTIFICATE-----", "").replace("-----BEGIN PRIVATE KEY-----", "").
    replace("-----END PRIVATE KEY-----", "").replace("\n", "")
}
