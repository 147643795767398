package common

import com.raquo.airstream.core.{Observer, Signal}
import com.raquo.airstream.state.{StrictSignal, Var}
import common.SortOptionState.SortOptionState
import common.SyncDirection.SyncDirection
import common.SyncErrorNotification.SyncErrorNotification
import common.SyncRuleType.SyncRuleType
import common.SyncServiceType.SyncServiceType
import common.SyncState.SyncState
import root_pages.aurinko_pages.app.sync.{UserCalendarSyncComponent, UserContactsSyncComponent, UserEmailsSyncComponent, UserTasksSyncComponent}

import java.time.Instant
import scala.util.Try
import scala.language.implicitConversions

case class SyncApiPage[T](
                           limit: Int,
                           summary: List[T],
                           total: Int
                         )

object HerokuOrgs {
  val ids = List(2038, 2444, 2449, 2899, 2934, 2971, 3082, 3114, 3158, 3196, 3233, 3308, 3324, 3460, 3484, 3499, 3645, 3666, 3717, 3723, 3762, 3766, 3767, 3776, 3823, 3824, 3854, 3870, 3880, 3894, 3904, 3923, 3926, 3954, 4026, 4031, 4050, 4054, 4074, 4095, 4140, 4141, 4193, 4245, 4249, 4254, 4258, 4264, 4265, 4270, 4290, 4322, 4323, 4341, 4378, 4381, 4387, 4390, 4392, 4407, 4420, 4421, 4433, 4450, 4462, 4499, 4500, 4513, 4515, 4522, 4524, 4526, 4531, 4556, 4569, 4571, 4578, 4580, 4583, 4590, 4592, 4593, 4595, 4600, 4614, 4616, 4622, 4623, 4631, 4640, 4641, 4645, 4648, 4655, 4661, 4667, 4688, 4692, 4694, 4702, 4704, 4713, 4716, 4718, 4722, 4737, 4746, 4762, 4766, 4773, 4776, 4777, 4780, 4789, 4793, 4801, 4803, 4806, 4808, 4823, 4824, 4828, 4833, 4842, 4843, 4845, 4852, 4861, 4877, 4886, 4892, 4894, 4900, 4901, 4902, 4910, 4914, 4931, 4936, 4937, 4949, 4956, 4967, 4969, 4977, 4978, 4983, 5001, 5002, 5003, 5014, 5022, 5038, 5041, 5046, 5056, 5060, 5061, 5076, 5082, 5083, 5085, 5087, 5107, 5112, 5113, 5123, 5147, 5154, 5155, 5171, 5180, 5187, 5190, 5194, 5195, 5212, 5218, 5223, 5238, 5243, 5251, 5256, 5258, 5260, 5267, 5271, 5273, 5288, 5294, 5295, 5296, 5309, 5312, 5317, 5322, 5327, 5333, 5336, 5343, 5359, 5360, 5369, 5378, 5389, 5390, 5407, 5409, 5417, 5418, 5419, 5421, 5429, 5439, 5446, 5450, 5451)
}

case class SyncOrganization(
                             id: Int,
                             deleted: Option[Boolean],
                             name: String,
                             domain: String,
                             extId: Option[String],
                             options: Int,
                             regDate: Instant,
                             supportEmail: Option[String],
                             coupon: Option[String],
                             dfltTemplate: Option[DefaultTemplate],
                             defaultSyncSettings: Option[TemplateSyncSettings],
                             apiSignupUrl: Option[String],
                             useAurinko: Boolean
                           ) {
  def isActive: Boolean = (options & 8) > 0

  val fromHeroku: Boolean = apiSignupUrl.isDefined
  //  val fromHeroku: Boolean = HerokuOrgs.ids.contains(id)

  //  val orgId: String = if (fromHeroku) s"${id}_h" else s"$id"

  def setNewActiveStatus(boolArg: Boolean): Int =
    if (boolArg)
      options | 8
    else
      options & ~8

//  def getPercent: Double = if (coupon.isDefined)
//    if (coupon.get.head == '%')
//      coupon.get.tail.toDouble
//    else coupon.get.toDouble
//  else 0


  def toEditableModel: SyncOrgEditModel = SyncOrgEditModel(
    Var(name),
    ByteOptions(Var(options)),
    Var(supportEmail.getOrElse("")),
    Var(Try(coupon.getOrElse("").split("%").last.toDouble).map(_.toString).getOrElse("0")),
    Option.when(dfltTemplate.isDefined) {
      dfltTemplate.get.toEditModel
    },
    Option.when(defaultSyncSettings.isDefined) {
      defaultSyncSettings.get.toEditModel
    },
    this

  )
}

case class ByteOptions(value: Var[Int]) {
  def toBoolean: Boolean = (value.now & 8) > 0

  val $boolean: Signal[Boolean] = value.signal.map(v => (v & 8) > 0)


  def update(boolValue: Boolean): Unit = value.update(v => if (boolValue)
    v | 8
  else
    v & ~8)

}

case class SyncOrgEditModel(
                             name: Var[String] = Var(""),
                             options: ByteOptions,
                             supportEmail: Var[String] = Var(""),
                             percent: Var[String],
                             dfltTemplate: Option[DefaultTemplateEditModel],
                             defaultSyncSettings: Option[TemplateSyncSettingsEditModel],
                             source: SyncOrganization
                           ) extends FormModel {

  def toApiModel: SyncOrganization = SyncOrganization(
    id = source.id,
    deleted = source.deleted,
    name = name.now,
    domain = source.domain,
    extId = source.extId,
    options = options.value.now,
    regDate = source.regDate,
    supportEmail = Some(supportEmail.now),
    coupon = Some(s"%${percent.now}"),
    dfltTemplate = None,
    //    dfltTemplate = Option.when(source.dfltTemplate.isDefined && defaultSyncSettings.isDefined) {
    //      source.dfltTemplate.get.copy(
    //        importTasks = defaultSyncSettings.get.syncTasks.now,
    //        importEvents = defaultSyncSettings.get.syncCalendar.now,
    //        importContacts = defaultSyncSettings.get.syncCalendar.now,
    //
    //      )
    //    },
    defaultSyncSettings = Option.when(defaultSyncSettings.isDefined) {
      source.defaultSyncSettings.get.copy(
        syncCalendar = defaultSyncSettings.get.syncCalendar.now,
        syncContacts = defaultSyncSettings.get.syncContacts.now,
        syncTasks = defaultSyncSettings.get.syncTasks.now,
        calSyncDirection = Some(defaultSyncSettings.get.calSyncDirection.now),
        contSyncDirection = Some(defaultSyncSettings.get.contSyncDirection.now),
        taskSyncDirection = Some(defaultSyncSettings.get.taskSyncDirection.now)
      )
    },
    apiSignupUrl = source.apiSignupUrl,
    useAurinko = source.useAurinko
  )

  def update(model: SyncOrgEditModel): Unit = {
    name.set(model.name.now)
    supportEmail.set(model.supportEmail.now)
    options.value.set(model.options.value.now)

    model.defaultSyncSettings.foreach(mdss => {
      defaultSyncSettings.foreach(_.syncTasks.set(mdss.syncTasks.now))
      defaultSyncSettings.foreach(_.syncCalendar.set(mdss.syncCalendar.now))
      defaultSyncSettings.foreach(_.syncContacts.set(mdss.syncContacts.now))
      defaultSyncSettings.foreach(_.calSyncDirection.set(mdss.calSyncDirection.now))
      defaultSyncSettings.foreach(_.taskSyncDirection.set(mdss.taskSyncDirection.now))
      defaultSyncSettings.foreach(_.contSyncDirection.set(mdss.contSyncDirection.now))
    })
  }
}

case class SyncAdmin(
                      id: Int,
                      name: Option[String],
                      firstName: Option[String],
                      lastName: Option[String],
                      email: Option[String],
                      extId: Option[String],
                      timeZoneInfo: Option[String],
                      disabled: Boolean,
                      admin: Boolean,
                      accessToken: Option[String]
                    )

case class DefaultTemplate(
                            id: Int,
                            displayName: Option[String],
                            `type`: SyncServiceType,
                            importContacts: Boolean,
                            importTasks: Boolean,
                            importEvents: Boolean,
                            logEmail: Boolean
                          ) {

  def toEditModel: DefaultTemplateEditModel = DefaultTemplateEditModel(
    Var(importContacts),
    Var(importTasks),
    Var(importEvents),
    Var(logEmail),
    this
  )

  val activeRules: List[String] = List((importContacts, "contacts"), (importTasks, "tasks"), (importEvents, "calendar"), (logEmail, "email"))
    .filter(_._1).map(_._2)
}

case class DefaultTemplateEditModel(
                                     importContacts: Var[Boolean],
                                     importTasks: Var[Boolean],
                                     importEvents: Var[Boolean],
                                     logEmail: Var[Boolean],
                                     source: DefaultTemplate
                                   ) {
  def toApiModel: DefaultTemplate = DefaultTemplate(
    source.id,
    source.displayName,
    source.`type`,
    importContacts.now,
    importTasks.now,
    importEvents.now,
    logEmail.now
  )

  def activeRules: List[String] = List((importContacts.now, "contacts"), (importTasks.now, "tasks"), (importEvents.now, "calendar"), (logEmail.now, "email"))
    .filter(_._1).map(_._2)
}

case class ResyncOptions() {
  val fullResyncFor: Var[Set[SyncRuleType]] = Var(Set.empty)

  def toggleRuleObserver(rule: SyncRuleType): Observer[Boolean] = Observer[Boolean](onNext = {
    case true => fullResyncFor.update(_ + rule)
    case _ => fullResyncFor.update(_ - rule)
  })

  def reset(): Unit = fullResyncFor.set(Set.empty)

  def toApiBody: Option[FullSyncApiBody] = Option.when(fullResyncFor.now.nonEmpty) {
    FullSyncApiBody(fullResyncFor.now.toList.map(_.value))
  }

  def checked(rule: SyncRuleType): Signal[Boolean] = fullResyncFor.signal.map(_.contains(rule))

}

case class FullSyncApiBody(
                            full: List[String]
                          )

case class SyncUser(
                     id: Int, // non-null
                     name: Option[String] = None,
                     firstName: Option[String],
                     lastName: Option[String],
                     email: Option[String],
                     extId: Option[String] = None,
                     timeZoneInfo: Option[String] = None,
                     disabled: Boolean,
                     admin: Boolean,
                     manager: Boolean,
                     syncEnabled: Boolean,
                     syncError: Boolean,
                     createdAt: Option[Instant] = None,
                     lastSynced: Option[Instant] = None,
                     templGroupId: Option[Int] = None,
                     templGroupName: Option[String] = None,
                     accountsSummary: Option[List[AccountSummary]] = None,
                     // defaultSyncSettings: Option[TemplateSyncSettings],
                     useAurinko: Boolean,
                     pkgCheckFailed: Option[Boolean]

                     /*
                       id: Int,
                       lastName: String,
                       firstName: Option[String] = None,
                       extLogin: Option[String] = None,
                       extId: String,
                       disabled: Option[Boolean] = None,
                       syncEnabled: Option[Boolean] = None,
                       lastSynced: Option[Instant] = None,
                       templGroupName: Option[String] = None,
                       templGroupId: Option[Int] = None,
                       email: String,
                       admin: Option[Boolean] = None,
                       syncError: Option[Boolean] = None,
                       pkgCheckFailed: Option[Boolean] = None,
                       uid: Option[Int] = None,
                       timeZoneInfo: Option[String] = None,
                       accountsSummary: Option[List[AccountSummary]] = None,
                       department: Option[String] = None,
                       division: Option[String] = None,
                       var active: Option[Boolean] = None

                      */
                   ) {

  def toEditModel: SyncUserEditModel = SyncUserEditModel(
    firstName = Var(firstName.getOrElse("")),
    lastName = Var(lastName.getOrElse("")),
    email = Var(email.getOrElse("")),
    syncEnabled = Var(syncEnabled),
    disabled = Var(disabled),
    admin = Var(admin),
    manager = Var(manager),
    templGroupId = Var(templGroupId),
    // defaultSyncSettings: Option[TemplateSyncSettings],
    source = this
  )

}

case class SyncUserEditModel(
                              firstName: Var[String] = Var(""),
                              lastName: Var[String] = Var(""),
                              email: Var[String] = Var(""),
                              syncEnabled: Var[Boolean],
                              disabled: Var[Boolean],
                              admin: Var[Boolean],
                              manager: Var[Boolean],
                              templGroupId: Var[Option[Int]],
                              //   defaultSyncSettings: Option[TemplateSyncSettings],
                              source: SyncUser,
                            ) extends FormModel {
  var templGroupName: Option[String] = None // modification only with the templGroupId field

  def toApiModel: SyncUser = SyncUser(
    id = source.id,
    firstName = Some(firstName.now),
    lastName = Some(lastName.now),
    email = Some(email.now),
    disabled = disabled.now,
    admin = admin.now,
    manager = manager.now,
    syncEnabled = syncEnabled.now,
    syncError = source.syncError,
    templGroupId = templGroupId.now(),
    templGroupName = if (templGroupId.now().getOrElse(0) == source.templGroupId.getOrElse(0)) source.templGroupName else templGroupName,
    useAurinko = source.useAurinko,
    pkgCheckFailed = source.pkgCheckFailed,
    //      defaultSyncSettings = Option.when(defaultSyncSettings.isDefined) {
    //      source.defaultSyncSettings.get.copy(
    //        syncCalendar = defaultSyncSettings.get.syncCalendar,
    //        syncContacts = defaultSyncSettings.get.syncContacts,
    //        syncTasks = defaultSyncSettings.get.syncTasks,
    //      )
    //    },
  )

  def update(model: SyncUserEditModel): Unit = {
    firstName.set(model.firstName.now)
    lastName.set(model.lastName.now)
    email.set(model.email.now)
    syncEnabled.set(model.syncEnabled.now)
    disabled.set(model.disabled.now)
    admin.set(model.admin.now)
    manager.set(model.manager.now)
    templGroupId.set(model.templGroupId.now)
  }
}

case class AccountSummary(
                           accountName: Option[String],
                           foreignState: Option[String],
                           foreignId: Option[String],
                           pushError: Option[AccountPushError],
                           serviceType: Option[SyncServiceType],
                           source: Boolean,
                           accountId: Int
                         )

case class AccountPushError(
                             errorMessage: Option[String],
                             accountProtocol: Option[String],
                             errorCode: Option[String]
                           )

case class SyncStatus(
                       userSyncEnabled: Boolean,
                       pkgCheckFailed: Boolean,
                       userLastSynced: Option[Instant],
                       syncState: SyncState,
                       lastRequest: Option[Instant],
                       //needReview: Option[List[String]]
                       errors: Option[List[SyncError]]
                     )

case class SyncError(
                      notificationType: SyncErrorNotification,
                      details: Option[String],
                      message: Option[String],
                      runtime: Boolean,
                      timestamp: Option[Instant],
                      serviceId: Option[String],
                      errorId: Option[String],
                      hide: Boolean
                    )

case class SyncService(
                        id: Int,
                        `type`: String,
                        offline: Boolean,
                        displayName: String,
                        extName: Option[String],
                        fullName: Option[String],
                        email: Option[String],
                        domain: Option[String],
                        authDomains: Option[String],
                        server: Option[String],
                        username: Option[String],
                        password: Option[String],
                        sandbox: Boolean,
                        //syncData: ApiServiceProps,
                        scanEmail: Boolean,
                        //scanEmails: ApiServiceScanEmail,
                        fwdEmail: Boolean,
                        //fwdEmails: ApiServicefwdEmail,
                        importContacts: Boolean,
                        //importContacts: ApiServiceContacts,
                        importTasks: Boolean,
                        //tasks: ApiServiceTasks,
                        importEvents: Boolean,
                        //calendar: APIServiceCalendar,
                        //                        authRedirectUrl: Option[String],
                        //                        appKeyPrefix: Option[String],
                        //                        accessToken: Option[String],
                        //                        refreshToken: Option[String],
                        //                        templLocked: Boolean,
                        //                        trustServer: Boolean
                      ) {
  val serviceType: SyncServiceType = SyncServiceType.fromString(`type`)


  val activeSyncRules: Seq[SyncRuleType] = List(
    (importContacts, SyncRuleType.Contacts),
    (importTasks, SyncRuleType.Tasks),
    (importEvents, SyncRuleType.Calendar),
    (if (serviceType.isMailbox) scanEmail else fwdEmail, SyncRuleType.Emails),
  ).filter(_._1).map(_._2)

  val activeSyncApisLabel: String = activeSyncRules.map(_.toString.toLowerCase).mkString(", ").capitalize
}

case class TemplateSyncSettings(
                                 templGroupId: Int,
                                 mailboxTypes: List[SyncServiceType],

                                 logEmail: Boolean,
                                 emailCreateContacts: Option[Boolean],
                                 emailFetchAttachments: Option[Boolean],
                                 emailSkipDomains: Option[String],
                                 emailSkipContactTags: Option[String],
                                 emailApplyFilters: Option[Boolean],
                                 emailAutoDetectDeal: Option[Boolean],
                                 emailLogAsTask: Option[Boolean],
                                 emailAllowPrivateContacts: Option[Boolean],
                                 emailCreateCompany: Option[Boolean],
                                 emailRelateAllContacts: Option[Boolean],

                                 syncCalendar: Boolean,
                                 calAutoDetectDeal: Option[Boolean],
                                 calConvertLeads: Option[Boolean],
                                 calCreateContacts: Option[Boolean],
                                 calFetchAttachments: Option[Boolean],
                                 calPastWeeks: Option[Int],
                                 calFutureWeeks: Option[Int],
                                 calMailboxSyncAll: Option[Boolean],
                                 calMailboxSyncMeetingsOnly: Option[Boolean],
                                 calMatchRequired: Option[Boolean],
                                 calSyncPrivate: Option[Boolean],
                                 calMailboxSyncTags: Option[String],
                                 calSkipDomains: Option[String],
                                 calSkipContactTags: Option[String],
                                 calSyncDirection: Option[SyncDirection],
                                 calAllowPrivateContacts: Option[Boolean],
                                 calCreateCompany: Option[Boolean],
                                 calRelateAllContacts: Option[Boolean],

                                 syncContacts: Boolean,
                                 contMailboxSyncAll: Option[Boolean],
                                 contMailboxSyncTags: Option[String],
                                 contPortalSyncAll: Option[Boolean],
                                 contPortalSyncMine: Option[Boolean],
                                 //                                   contAndFilterFields: Var[List[FieldNameValue]] = Var(Nil),
                                 contPortalSyncTags: Option[String],
                                 //                                   contTagModeFields: List[String] = Nil,
                                 contSyncDirection: Option[SyncDirection],
                                 contMailboxSyncDeleted: Option[Boolean],
                                 contAllowPrivateContacts: Option[Boolean],
                                 contCreateCompany: Option[Boolean],

                                 syncTasks: Boolean,
                                 taskMailboxSyncAll: Option[Boolean],
                                 taskMailboxSyncTags: Option[String],
                                 taskSyncDirection: Option[SyncDirection],

                                 mappingFields: Option[Map[String, List[PurposeField]]],
                                 standardMappingFields: Option[Map[String, List[PurposeField]]],

                                 defaultFields: Option[Map[String, List[PurposeField]]],
                                 filterFields: Option[Map[String, List[PurposeField]]],
                                 tagModeFields: Option[Map[String, List[PurposeField]]],
                                 updateCondFields: Option[Map[String, List[PurposeField]]],
                                 deleteFlagFields: Option[Map[String, List[PurposeField]]],

                                 regexExtractFields: Option[Map[String, List[PurposeField]]],
                                 regexMatchFields: Option[Map[String, List[PurposeField]]],
                                 //
                                 //
                                 calLogRelationConf: Option[LogRelationConfig],
                                 emailLogRelationConf: Option[LogRelationConfig],

                                 contactFields: Option[List[ContactField]]

                               ) {
  def toEditModel: TemplateSyncSettingsEditModel = TemplateSyncSettingsEditModel(
    syncCalendar = Var(syncCalendar),
    syncContacts = Var(syncContacts),
    syncTasks = Var(syncTasks),
    calSyncDirection = Var(calSyncDirection.getOrElse(SyncDirection.BOTH)),
    contSyncDirection = Var(contSyncDirection.getOrElse(SyncDirection.BOTH)),
    taskSyncDirection = Var(taskSyncDirection.getOrElse(SyncDirection.BOTH))
  )
}

case class PurposeField(
                         name: String,
                         value: Option[String],
                         flag: Option[String],
                         variables: Option[List[String]]
                       )

case class LogRelationConfig(
                              objectName: String,
                              `type`: String,
                              lookupRules: Option[List[LookupRule]]
                            )

case class LookupRule(
                       remoteField: String,
                       operation: String,
                       valueVariable: String
                     )

case class ContactField(
                         contactType: String,
                         label: Option[String],
                         location: String,
                         name: String,
                         objectType: String
                       )

case class TemplateSyncSettingsEditModel(
                                          syncCalendar: Var[Boolean],
                                          syncContacts: Var[Boolean],
                                          syncTasks: Var[Boolean],
                                          calSyncDirection: Var[SyncDirection],
                                          contSyncDirection: Var[SyncDirection],
                                          taskSyncDirection: Var[SyncDirection]
                                        )

object SortOptionState extends Enumeration {
  type SortOptionState = Value

  val ascending, descending, none = Value
}


case class SyncObjFilters(
                           private val filters: Var[Set[String]] = Var(Set.empty),
                           private val limit: Int = 15,
                         ) {

  val $filters: Signal[Set[String]] = filters.signal


  val searchText: Var[String] = Var("")

  val pageNum: Var[Int] = Var(0)

  val pagesLimit: Var[Int] = Var(30)

  def offset: Int = pagesLimit.now() * pageNum.now

  def toggleFilter(filter: String): Unit = if (filters.now.contains(filter)) {
    filters.update(_ - filter)
  } else {
    filters.update(_ + filter)
  }

  private val sorting: Var[Set[String]] = Var(Set("-updateTime"))
  val $sorting: StrictSignal[Set[String]] = sorting.signal

  def toggleSortParam(sortParam: String): Unit = if (sorting.now.contains(sortParam)) {
    sorting.update(s => s - sortParam + s"-$sortParam")
  } else if (sorting.now.contains(s"-$sortParam")) {
    sorting.update(_ - s"-$sortParam")
  } else {
    sorting.set(Set(sortParam))
  }

  def sortParamState(sortParam: String): Signal[SortOptionState] = $sorting.map {
    case l if l.contains(sortParam) => SortOptionState.ascending
    case l if l.contains(s"-$sortParam") => SortOptionState.descending
    case _ => SortOptionState.none
  }

  def toMap: Map[String, Option[String]] = Map(
    "limit" -> Some(s"${pagesLimit.now}"),
    "offset" -> Some(s"$offset"),
    "search" -> Option.when(searchText.now.trim.nonEmpty) {
      searchText.now
    },
    "flags" -> Option.when(filters.now.nonEmpty) {
      filters.now.mkString(",")
    },

    "sort" -> Option.when(sorting.now.nonEmpty) {
      sorting.now.mkString(",")
    }
  )
}

object SyncServiceType extends Enumeration with JsonEnum {
  case class Val(value: String, label: String, isMailbox: Boolean = true) extends super.Val(value)

  type SyncServiceType = Val
  type EnumValue = Val

  val google: SyncServiceType = Val("GOOGLE", "Google")
  val office365: SyncServiceType = Val("OFFICE365", "Office365")
  val exchange: SyncServiceType = Val("EXCHANGE", "Ms Exchange")
  val repfabric: SyncServiceType = Val("REPFABRIC", "Repfabric", isMailbox = false)
  val salesforce: SyncServiceType = Val("SFORCE", "Salesforce", isMailbox = false)
  val teamwork: SyncServiceType = Val("TEAMWORKPM", "Teamwork", isMailbox = false)
  val zurmo: SyncServiceType = Val("ZURMO", "Zurmo", isMailbox = false)
  val hubspotem: SyncServiceType = Val("HUBSPOTEM", "Hubspot", isMailbox = false)
  val imap: SyncServiceType = Val("IMAP", "Imap")
  val unknown: SyncServiceType = Val("UNKNOWN", "UNKNOWN", isMailbox = false)

  val list: List[SyncServiceType] = google :: office365 :: exchange :: repfabric :: salesforce :: teamwork :: hubspotem :: imap:: unknown :: Nil

  override def fromString(str: String): EnumValue = {
    if (str == "SALESFORCE") {
      /*println("got sforce");*/ salesforce
    }
    else {
      /*println("got not sforce " + withName(str).toString);*/
      try {
        withName(str)
      } catch {
        case _: Throwable => unknown //throw new Exception(s"Unrecognized service type: $str")
      }
    }
  }
}

object SyncDirection extends Enumeration with JsonEnum {
  case class Val(value: String) extends super.Val(value) {
    def generateLabel(crmName: String): String = value match {
      case "BOTH" => "Sync both ways"
      case "TO_PORTAL" => s"Sync to $crmName"
      case "FROM_PORTAL" => s"Sync from $crmName"
      case _ => "Custom sync"
    }
  }

  type SyncDirection = Val
  type EnumValue = Val

  val BOTH: SyncDirection = Val("BOTH")
  val TO_PORTAL: SyncDirection = Val("TO_PORTAL")
  val FROM_PORTAL: SyncDirection = Val("FROM_PORTAL")
  val CUSTOM: SyncDirection = Val("CUSTOM")

  val selectableValues: List[SyncDirection] = BOTH :: TO_PORTAL :: FROM_PORTAL :: Nil
  val syncDirectionUsers: List[SyncDirection] = BOTH :: TO_PORTAL :: FROM_PORTAL :: CUSTOM :: Nil
}

object SyncState extends Enumeration with JsonEnum {
  type SyncState = Value
  type EnumValue = Value

  val REQUESTED, QUEUED, EXECUTING, IDLE = Value
}

object SyncRuleType extends Enumeration with JsonEnum {
  case class Val(value: String, label: String) extends super.Val(value)

  type SyncRuleType = Val
  type EnumValue = Val

  val Contacts: SyncRuleType = Val("CONTACTS", "Contacts")
  val Calendar: SyncRuleType = Val("CALENDAR", "Calendar")
  val Tasks: SyncRuleType = Val("TASKS", "Tasks")
  val Emails: SyncRuleType = Val("EMAIL", "Emails")

  val all: List[SyncRuleType] = Contacts :: Calendar :: Tasks :: Emails :: Nil
}

case class CalendarQuery(
                          id: Int,
                          subject: Option[String] = None,
                          summary: Option[String] = None,
                          startTime: Option[Instant] = None,
                          source: Option[String] = None,
                          endTime: Option[Instant] = None,
                          updateTime: Option[Instant] = None,
                          syncEnabled: Boolean = false,
                          errorsDuringSync: Boolean = false,
                          projName: Option[String] = None,
                          recurring: Boolean = false,
                          allDay: Boolean = false,
                          meeting: Boolean = false,
                          remind: Boolean = false,
                          reminderPeriod: Int = 0,
                          reminderUnit: Option[String] = None,
                          eventContacts: Option[List[EventContact]] = None,
                          description: Option[String] = None,
                          autoMerged: Boolean = false,
                          sameAsId: Int = 0,
                          skipMerge: Boolean = false,
                          sureMatch: Boolean = false,
                          `private`: Boolean = false,
                          accountsSummary: Option[List[AccountSummary]] = None,
                          mergeHistory: Option[List[MergeInfo]] = None,
                        ) {
  val eventTypes: List[String] = List((recurring, "recurring"), (allDay, "all day event"), (meeting, "meeting")).filter(_._1).map(_._2)
}

case class EventContact(
                         email: String,
                         displayName: String,
                         name: Option[String],
                         response: Option[String],
                         self: Boolean,
                         `type`: String
                       )

// ContactsQuery - json example:
//  {
//      "id": 1,
//      "updateTime": 1536104200000,
//      "createTime": 1503708500000,
//      "autoMerged": false,
//      "syncEnabled": false,
//      "sameAsId": 0,
//      "sameAsName": null,
//      "sureMatch": false,
//      "skipMerge": false,
//      "mergeHistory": null,
//      "mergeTime": null,
//      "mergeChild": false,
//      "name": "MMM NNN",
//      "summary": "email@domen.org",
//      "firstName": "MMM",
//      "lastName": "NNN",
//      "jobTitle": null,
//      "companyName": null,
//      "background": null,
//      "keywords": null,
//      "department": null,
//      "contCount": 1,
//      "email": [ "email@domen.org" ],
//      "phone": [],
//      "twitter": [],
//      "web": [],
//      "addr": [],
//      "errorsDuringSync": false,
//      "fidInfo": null,
//      "accountsSummary": null
//  }
case class ContactsQuery(
                          id: Int,
                          updateTime: Option[Instant] = None,
                          //                          createTime: Option[Instant] = None,
                          autoMerged: Boolean = false,
                          syncEnabled: Boolean = false,
                          sameAsId: Int = 0,
                          //                          sameAsName: Any, // not detect field type
                          sureMatch: Boolean = false,
                          skipMerge: Boolean = false,
                          mergeHistory: Option[List[MergeInfo]] = None,
                          //                          mergeTime: Any, // not detect field type
                          //                          mergeChild: Boolean,
                          name: Option[String] = None,
                          summary: Option[String] = None,
                          firstName: Option[String] = None,
                          lastName: Option[String] = None,
                          jobTitle: Option[String] = None,
                          companyName: Option[String] = None,
                          background: Option[String] = None, // not detect field type
                          keywords: Option[String] = None, // not detect field type
                          department: Option[String] = None, // not detect field type
                          //                          contCount: Int = 0,
                          email: List[String] = Nil,
                          phone: List[String] = Nil,
                          //                          twitter: Any, // not detect field type
                          web: List[String] = Nil,
                          addr: List[String] = Nil,
                          errorsDuringSync: Boolean = false,
                          //                          fidInfo: Any,
                          accountsSummary: Option[List[AccountSummary]] = None,
                        ) {
}

// EmailsQuery - json example:
//  {
//      "id": 136882,
//      "updateTime": 1522895310000,
//      "createTime": 1522833984000,
//      "subject": "12 Days Of Bosch Deals starts now!",
//      "fromSummary": "Simon Morrow (1)",
//      "keywords": null,
//      "discovered": true,
//      "forwarded": 0,
//      "message": null,
//      "emailFrom": null,
//      "emailTo": null,
//      "emailCc": null,
//      "msgList": null,
//      "closed": true,
//      "dripRuleId": null,
//      "dripNum": 0,
//      "dripFailed": false,
//      "dripRules": null,
//      "dripTargets": null,
//      "emailOthers": null,
//      "dripResponder": null,
//      "dripActivate": false,
//      "dripEnded": false,
//      "errorsDuringSync": false,
//      "accountsSummary": null
//  }

case class MessageInfo(
                        id: Int,
                        receivedTime: Instant,
                        emailFrom: Option[String] = None,
                        emailTo: Option[String] = None,
                        message: Option[String] = None,
                        subject: Option[String] = None,
                        forwarded: Option[Int] = None,
                      )

case class EmailsQuery(
                        id: Int,
                        updateTime: Option[Instant] = None,
                        createTime: Option[Instant] = None,
                        subject: Option[String] = None,
                        fromSummary: Option[String] = None,
                        discovered: Boolean = false,
                        forwarded: Int = 1,
                        errorsDuringSync: Boolean = false,
                        accountsSummary: Option[List[AccountSummary]] = None,
                        msgList: Option[List[MessageInfo]] = None,
                        //                        keywords: Any,
                        //                        message: Any,
                        //                        emailCc: Any,
                        //                        msgList: Any,
                        //                        closed: Any,
                        //                        dripRuleId: Any,
                        //                        dripNum: Any,
                        //                        dripFailed: Any,
                        //                        dripRules: Any,
                        //                        dripTargets: Any,
                        //                        emailOthers: Any,
                        //                        dripResponder: Any,
                        //                        dripActivate: Any,
                        //                        dripEnded: Any,
                        //                        accountsSummary: Any,
                      )

// TasksQuery - json example
//  {
//      "id": 1,
//      "updateTime": 1403681352000,
//      "createTime": 1403681352000,
//      "autoMerged": false,
//      "syncEnabled": true,
//      "sameAsId": 0,
//      "sameAsName": null,
//      "sureMatch": false,
//      "skipMerge": false,
//      "mergeHistory": null,
//      "mergeTime": null,
//      "mergeChild": false,
//      "subject": "Test task 1",
//      "summary": "Test task 1",
//      "notes": null,
//      "ctgName": "Salesforce",
//      "projName": null,
//      "dueDate": 1403766000000,
//      "startDate": null,
//      "errorsDuringSync": false,
//      "source": "SFORCE",
//      "complete": false,
//      "remind": false,
//      "remindAt": null,
//      "accountsSummary": null,
//      "private": false
//  }
case class TasksQuery(
                       id: Int,
                       updateTime: Option[Instant] = None,
                       //                          createTime: Option[Instant],
                       autoMerged: Boolean = false,
                       syncEnabled: Boolean = false,
                       sameAsId: Int = 0,
                       sameAsName: Option[String] = None,
                       sureMatch: Boolean = false,
                       skipMerge: Boolean = false,
                       mergeHistory: Option[List[MergeInfo]] = None,
                       //                       mergeTime: Any,
                       mergeChild: Boolean = false,
                       subject: Option[String] = None,
                       summary: Option[String] = None,
                       notes: Option[String] = None,
                       ctgName: Option[String] = None,
                       //                       projName: Any,
                       dueDate: Option[Instant] = None,
                       startDate: Option[Instant] = None,
                       errorsDuringSync: Boolean = false,
                       source: Option[String] = None,
                       complete: Boolean = false,
                       remind: Boolean = false,
                       remindAt: Option[Instant] = None,
                       accountsSummary: Option[List[AccountSummary]] = None,
                       `private`: Boolean = false,
                     )

case class MergeInfo(
                      summary: String,
                      mergeTime: Instant,
                    )

object SyncErrorNotification extends Enumeration with JsonEnum {
  case class Val(
                  value: String,
                  syncType: Option[SyncRuleType] = None,
                  syncFilters: Option[Set[String]] = None,
                ) extends super.Val(value)

  type SyncErrorNotification = Val
  type EnumValue = Val

  val TSS_SYNC_ERROR: SyncErrorNotification = Val("TSS_SYNC_ERROR")
  val SYNC_ERROR: SyncErrorNotification = Val("SYNC_ERROR")

  val PUSH_CONTACTS: SyncErrorNotification = Val("PUSH_CONTACTS", Some(SyncRuleType.Contacts), Some(Set[String](UserContactsSyncComponent.Filters.errors.name)))
  val PUSH_EVENTS: SyncErrorNotification = Val("PUSH_EVENTS", Some(SyncRuleType.Calendar), Some(Set[String](UserCalendarSyncComponent.Filters.errors.name)))
  val PUSH_TASKS: SyncErrorNotification = Val("PUSH_TASKS", Some(SyncRuleType.Tasks), Some(Set[String](UserTasksSyncComponent.Filters.errors.name)))
  val PUSH_THREADS: SyncErrorNotification = Val("PUSH_THREADS", Some(SyncRuleType.Emails), Some(Set[String](UserEmailsSyncComponent.Filters.errors.name)))
}

case class TemplateGroup(
                          id: Int,
                          name: String,
                          default: Option[Boolean] = Some(false),
                          //departments: Option[Any],
                          //divisions: Option[Any],
                          //emailDomains: Option[Any],
                          //extPortalUrl: Option[Any],
                          preserveServices: Option[Boolean] = Some(false),
                          //profiles: Option[Any],
                          //roles: Option[Any],
                        )


//{"id":"CONTACTS","type":"CONTACTS","enabled":true,"needReview":true,"svcIds":[43,42,41]}
case class SyncUserRule(
                         id: String,
                         `type`: SyncRuleType,
                         enabled: Boolean,
                         needReview: Option[Boolean],
                         svcIds: Option[List[Int]],
                       )


case class SyncUserSettings(
                         portalType: SyncServiceType,
                         mailboxTypes: List[SyncServiceType],

                         logEmail: Boolean,
                         emailSkipDomains: Option[String],
                         emailSkipContactTags: Option[String],
                         emailRelateAllContacts: Option[Boolean],
                         emailCreateLeads: Option[Boolean],
                         emailFetchAttachments: Option[Boolean],
                         emailCreateContacts: Option[Boolean],
                         emailApplyFilters: Option[Boolean],
                         emailAutoDetectDeal: Option[Boolean],
                         emailLogAsTask: Option[Boolean],
                         emailCreateCompany: Option[Boolean],
                         emailAllowPrivateContacts: Option[Boolean],

                         syncCalendar: Boolean,
                         calSyncDirection: Option[SyncDirection],
                         calSkipDomains: Option[String],
                         calSkipContactTags: Option[String],
                         calRelateAllContacts: Option[Boolean],
                         calCreateContacts: Option[Boolean],
                         calCreateCompany: Option[Boolean],
                         calAllowPrivateContacts: Option[Boolean],
                         calMatchRequired: Option[Boolean],
                         calSyncPrivate: Option[Boolean],
                         calCreateLeads: Option[Boolean],
                         calFetchAttachments: Option[Boolean],
                         calMailboxSyncAll: Option[Boolean],
                         calMailboxSyncTags: Option[String],
                         calMailboxSyncMeetingsOnly: Option[Boolean],
                         calPastWeeks: Option[Int],
                         calFutureWeeks: Option[Int],
                         calConvertLeads: Option[Boolean],
                         calAutoDetectDeal: Option[Boolean],


                         syncContacts: Boolean,
                         contSyncDirection: Option[SyncDirection],
                         contCreateCompany: Option[Boolean],
                         contAllowPrivateContacts: Option[Boolean],
                         contPortalSyncAll: Option[Boolean],
                         // contPortalSyncAll: Var[Boolean] = Var(false),
                         contMailboxSyncAll: Option[Boolean],
                         contPortalSyncMine: Option[Boolean],
                         // contPortalSyncMine: Var[Boolean] = Var(false),
                         contPortalSyncTags: Option[String],
                         contMailboxSyncTags: Option[String],
                         contMailboxSyncDeleted: Option[Boolean],

                         syncTasks: Boolean,
                         taskSyncDirection: Option[SyncDirection],
                         taskMailboxSyncAll: Option[Boolean],
                         taskMailboxSyncTags: Option[String],
                       )
