

import io.circe.{Decoder, parser}
import com.github.uosis.laminar.webcomponents.material.{Textarea, Textfield}
import com.raquo.domtypes.generic.codecs.{BooleanAsIsCodec, StringAsIsCodec}
import com.raquo.laminar.api.L._
import org.scalajs.dom
import wvlet.log.Logger

import java.net.URLDecoder
import java.time.{Instant, LocalDateTime, ZoneId}
import java.time.format.DateTimeFormatter
import java.util.TimeZone


package object common {
  private val log = Logger("common")

  implicit final class CirceStringOps(private val str: String) extends AnyVal {
    def decodeAs[A: Decoder]: A = parser
      .decode[A](str)
      .fold(
        e => {
          log.warn(s"CirceStringOps: invalid json: ${e.getMessage}")
          println(s"CirceStringOps: invalid json: ${e.getMessage}")
          throw e
        },
        v => v
      )

    def safelyDecodeAs[A: Decoder]: Option[A] = parser
      .decode[A](str)
      .fold(
        e => {
          log.warn(s"CirceStringOps: invalid json: ${e.getMessage}")
          println(s"CirceStringOps: invalid json: ${e.getMessage}")
          None
        },
        v => Some(v)
      )

    def decodeError: Option[String] = parser
      .parse(str)
      .fold(
        e => Some(e.message),
        _ => None,
      )

    def decodeAsRawData: Option[io.circe.Json] = parser
      .parse(str)
      .fold(
        e => {
          log.warn(s"CirceStringOps: invalid json: ${e.getMessage}")
          println(s"CirceStringOps: invalid json: ${e.getMessage}")
          throw e
        },
        x => Some(x),
      )

    def safelyDecodeAsRawData: Option[io.circe.Json] = parser
      .parse(str)
      .fold(
        e => {
          log.warn(s"CirceStringOps: invalid json: ${e.getMessage}")
          println(s"CirceStringOps: invalid json: ${e.getMessage}")
          None
        },
        x => Some(x),
      )
  }

  implicit final class TextFieldOps(private val inp: Textfield.El) extends AnyVal {
    def isValid: Boolean = inp.ref.validity.asInstanceOf[dom.ValidityState].valid

//    def isTouched: Boolean = inp.ref.validity.asInstanceOf[dom.ValidityState].
  }
  implicit final class TextAreaOps(private val inp: Textarea.El) extends AnyVal {
    def isValid: Boolean = inp.ref.validity.asInstanceOf[dom.ValidityState].valid
  }


  val graphic: Prop[String] = customProp("graphic", StringAsIsCodec)
  val divider: Prop[String] = customProp("divider", StringAsIsCodec)
  val download: Prop[String] = customProp("download", StringAsIsCodec)
  val minLength: Prop[String] = customProp("minlength", StringAsIsCodec)
  val stayOpenOnBodyClick: Prop[Boolean] = customProp("stayOpenOnBodyClick", BooleanAsIsCodec)

  val nameFieldPattern: String = "^[a-zA-Z\\dÀ-ÖØ-öø-ÿ' \\-,.\\s]+$"


  def findQueryParam(name: String): Option[String] = {
    print(s"wlo ${dom.window.location.search}")
    val txt = s"$name="
    dom.window.location.search
      .split("&")
      .find(_.contains(txt))
      .collect {
        case x: String => URLDecoder.decode(x.split(txt).last, "UTF-8")//??
      }
  }

  def getLastUrlParam: String = dom.window.location.href.split("/").last.split("\\?").head

//  val dateTimeFormatter: DateTimeFormatter = DateTimeFormatter.ofPattern("MMM dd, yyyy \'at\' h:mm a")
  val dateTimeFormatter: DateTimeFormatter = DateTimeFormatter.ofPattern("M/dd/yyyy HH:mm")
  val shortDateFormatter: DateTimeFormatter = DateTimeFormatter.ofPattern("MMM dd")
  val dateFormatter: DateTimeFormatter = DateTimeFormatter.ofPattern("MMM dd, yyyy")
  val dateTimeNew:DateTimeFormatter = DateTimeFormatter.ofPattern("yyyy-MM-dd")
  val dateMonthFormatter = DateTimeFormatter.ofPattern("MMM d")


  val systemDefaultTZ = try {
    ZoneId.systemDefault()
  }catch {
    case _ if TimeZone.getDefault.getID == "Europe/Kyiv" => ZoneId.of("Europe/Kiev")
    case err: Throwable =>
      log.info(s"ZoneId: ${TimeZone.getDefault.getID}")
      throw err
  }

  implicit final class InstantOps(private val instant: Instant) extends AnyVal {
    private def instantInLocalZone = instant.atZone(systemDefaultTZ)

    def toSystemLocal: LocalDateTime = instantInLocalZone.toLocalDateTime

    def toPrettyLocalFormat: String = instantInLocalZone.toLocalDateTime.format(dateTimeFormatter)

    def toShortDateFormat: String = instantInLocalZone.toLocalDateTime.format(shortDateFormatter)
    def toDateFormat: String = instantInLocalZone.toLocalDateTime.format(dateFormatter)
    def toDateFormatNew: String = instantInLocalZone.toLocalDateTime.format(dateTimeNew)
    def toMonthDayFormat: String = instantInLocalZone.toLocalDateTime.format(dateMonthFormatter)
  }

  val asteriskString: String = "*********************************************"

  val byteToTb = 1099511627776.0
  val byteToGb = 1073741824.0
  val byteToMb = 1048576.0
  val byteToKb = 1024.0


}


