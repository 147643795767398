import com.raquo.laminar.api.L._
import com.raquo.waypoint.SplitRender
import org.scalajs.dom
import portal_router.AppStoragePage
import root_pages.aurinko_pages.{AurinkoRootComponent, profile_dialog}
import root_pages.aurinko_pages.app.accounts.{AccountComponent, AccountsComponent}
import root_pages.aurinko_pages.app.addin_setup.AddinSetupComponent
import root_pages.aurinko_pages.app.dashboard.DashboardPageComponent
import root_pages.aurinko_pages.app.organizations.{OrganizationComponent, OrganizationsComponent}
import root_pages.aurinko_pages.app.settings.SettingsComponent
import root_pages.aurinko_pages.app.storage.AppStoragePageComponent
import root_pages.aurinko_pages.app.sync.{SyncOrgComponent, SyncOrgRoot, SyncOrgsComponent, SyncRoot, SyncUserComponent}
import root_pages.aurinko_pages.app.users.{EndUserComponent, EndUsersComponent}
import root_pages.aurinko_pages.app.virtual_api.VirtualApiRoot
import root_pages.aurinko_pages.app.{AppRootComponent, virtual_api}
import root_pages.aurinko_pages.create_app.CreateAppPageComponent
//import service.ui.messages.Messages

import root_pages.aurinko_pages.team.TeamRoot
import root_pages.auth_pages.accept_invitation.AcceptInvitationComponent
import root_pages.auth_pages.new_password.NewPasswordComponent
import root_pages.auth_pages.not_verified.NotVerifiedComponent
import root_pages.auth_pages.reset_password.ResetPasswordComponent
import root_pages.auth_pages.sign_in.LoginComponent
import root_pages.auth_pages.sign_up.SignUpComponent
import root_pages.auth_pages.verify_email.VerifyEmailComponent
import root_pages.loading.BufferPageComponent
import portal_router.{AcceptInvitationPage, AccountPage, AccountsPage, AddinSetupPage, AppPage, AppSettingsPage, AurinkoPage, BillingInvoicePage, BillingPage, CreateAppPage, DashboardPage, BufferPage, LoginPage, TeamAppsPage, NewPasswordPage, NotVerifiedPage, OrganizationPage, OrgsPage, Page, PortalRouter, ResetPasswordPage, SignUpPage, SyncOrgPage, SyncOrgPages, SyncOrgsPage, SyncPage, SyncUserPage, TeamPage, TeamSettingsPage, UserPage, UsersPage, VerifyEmailPage, VirtualAPIsPage, VirtualAPIsPage_ClassOfCustomMetadata, VirtualAPIsPage_ClassOfStandardMetadata, VirtualAPIsPage_CustomMetadata, VirtualAPIsPage_ItemsOfProviderMetadata, VirtualAPIsPage_ItemsOfVirtualMetadata, VirtualAPIsPage_StandardMetadata}
import root_pages.aurinko_pages.main.MainPageComponent
import root_pages.aurinko_pages.team.billing.BillingPageComponent
import root_pages.aurinko_pages.team.billing.invoice.BillingInvoicePageComponent
import root_pages.aurinko_pages.team.team_settings.TeamComponent
import service.UiServiceManager
import service.apis.dynamic_api.DataMapperApi
import service.apis.portal_api.PortalApi
import service.apis.sync_api.SyncApi
import service.aurinko_api.AurinkoApi
import service.portal_state.PortalState
import service.urls.Urls

class Components(document: dom.Document, wl: dom.Location) {
  lazy val portalRouter = new PortalRouter()

  lazy val urls = new Urls(wl)
  lazy val uiServices = new UiServiceManager(document, portalRouter)

  lazy val portalApi = new PortalApi(urls.apiOrigin + "/portal", urls, uiServices.Spinner)
  lazy val syncApi = new SyncApi(urls.apiOrigin + "/portal", uiServices.Spinner)
  lazy val aurinkoApi: AurinkoApi = new AurinkoApi(urls.apiOrigin + "/v1", uiServices.Spinner)
  lazy val dynamicApi: DataMapperApi.API = DataMapperApi(urls.apiOrigin + "/portal", uiServices.Spinner)

  lazy val portalState: PortalState = new PortalState()

  def $components: Signal[HtmlElement] = renderRootComponents.$view

  private val profileDialog = new profile_dialog.ProfileComponent(
    portalApi,
    portalState
  )

  private def renderRootComponents: SplitRender[Page, HtmlElement] = SplitRender[Page, HtmlElement](portalRouter.router.$currentPage)
    .collectSignal[AurinkoPage] { $page => new AurinkoRootComponent($page, portalApi, portalState, auPagesSplitter, uiServices.DocumentScrollOps, portalRouter, profileDialog).node }
    .collectSignal[LoginPage] { $page => new LoginComponent($page.map(_.redirect.flatMap(portalRouter.router.pageForAbsoluteUrl)), portalApi, portalState.Session.PortalUserAuthInfo, portalRouter).node }
    .collectSignal[NewPasswordPage] { $page => new NewPasswordComponent($page, portalApi, portalRouter).node }
    .collectStatic(SignUpPage) {
      new SignUpComponent(portalApi, portalRouter).node
    }
    .collectStatic(ResetPasswordPage) {
      new ResetPasswordComponent(portalApi).node
    }
    .collectStatic(NotVerifiedPage) {
      new NotVerifiedComponent(portalApi, uiServices.Messages, portalRouter.router).node
    }
    .collectSignal[VerifyEmailPage] { $page => new VerifyEmailComponent($page, portalApi, portalRouter).node }
    .collectSignal[AcceptInvitationPage] { $page => new AcceptInvitationComponent($page, portalApi, portalRouter, portalState).node }

  private def auPagesSplitter($page: Signal[AurinkoPage]): SplitRender[AurinkoPage, HtmlElement] =
    SplitRender[AurinkoPage, HtmlElement]($page)
      .collectSignal[AppPage] { $page => new AppRootComponent($page, portalApi, portalState, appPagesSplitter, uiServices.DocumentScrollOps, portalRouter).node }
      .collectSignal[TeamPage] { $page => new TeamRoot($page, portalState, portalRouter, teamPagesSplitter, uiServices.DocumentScrollOps).node }
      .collectSignal[CreateAppPage] { $page => new CreateAppPageComponent($page, portalApi, portalRouter, portalState).node }
      .collectStatic(BufferPage) {
        new BufferPageComponent(portalApi, portalState, portalRouter.router).node
      }

  private def appPagesSplitter($page: Signal[AppPage]): SplitRender[AurinkoPage, HtmlElement] = SplitRender[AurinkoPage, HtmlElement]($page)
    .collectSignal[AddinSetupPage] { $page => AddinSetupComponent($page, portalApi).node }
    .collectSignal[DashboardPage] { $page => new DashboardPageComponent($page, portalApi, aurinkoApi, uiServices.Clipboard, uiServices.LocalStorage, portalRouter, portalState, urls.apiOrigin).node }
    .collectSignal[AccountsPage] { $page => new AccountsComponent($page, portalApi, uiServices.DocumentScrollOps, portalRouter, portalState, urls.apiOrigin).node }
    .collectSignal[AccountPage] { $page => new AccountComponent($page, portalApi, uiServices.Clipboard, uiServices.DocumentScrollOps, portalRouter, portalState, urls.apiOrigin).node }
    .collectSignal[UsersPage] { $page => new EndUsersComponent($page, portalApi, uiServices.DocumentScrollOps, portalRouter).node }
    .collectSignal[UserPage] { $page => new EndUserComponent($page, portalApi, portalRouter, uiServices.DocumentScrollOps, portalState).node }
    .collectSignal[OrgsPage] { $page => new OrganizationsComponent($page, portalApi, uiServices.DocumentScrollOps, portalRouter, portalState).node }
    .collectSignal[OrganizationPage] { $page => new OrganizationComponent($page, portalApi, portalState, portalRouter, uiServices.DocumentScrollOps).node }
    .collectSignal[AppSettingsPage] { $page => new SettingsComponent($page, portalApi, uiServices.Clipboard, urls.apiOrigin, portalRouter, portalState).node }
    .collectSignal[AppStoragePage] { $page => new AppStoragePageComponent($page, portalApi, portalState).node }
    .collectSignal[SyncPage] { $page => SyncRoot(syncPagesSplitter, $page) }
    .collectSignal[VirtualAPIsPage] { $page => VirtualApiRoot($page, dataMapperPagesSplitter) }

  private def teamPagesSplitter($page: Signal[TeamPage]) = SplitRender[TeamPage, HtmlElement]($page)
    .collectSignal[TeamSettingsPage] { $page => new TeamComponent($page, portalApi, portalState, portalRouter).node }
    .collectSignal[TeamAppsPage] { $page => new MainPageComponent($page, portalApi, portalState, portalRouter, uiServices.Clipboard, uiServices.Messages, uiServices.LocalStorage, profileDialog.externalOpenDialog).node }
    .collectSignal[BillingPage] { $page => new BillingPageComponent($page, portalApi, portalRouter, portalState).node }
    .collectSignal[BillingInvoicePage] { $page => new BillingInvoicePageComponent($page, portalApi, portalRouter, portalState).node }

  private def syncPagesSplitter($page: Signal[SyncPage]): SplitRender[SyncPage, HtmlElement] = SplitRender[SyncPage, HtmlElement]($page)
    .collectSignal[SyncOrgPages] { $page => SyncOrgRoot($page, syncApi, syncOrgPagesSplitter) }
    .collectSignal[SyncOrgsPage] { $page => new SyncOrgsComponent($page, syncApi, uiServices.DocumentScrollOps, portalRouter).node }


  private def syncOrgPagesSplitter($page: Signal[SyncOrgPages]): SplitRender[SyncOrgPages, HtmlElement] = SplitRender[SyncOrgPages, HtmlElement]($page)

    .collectSignal[SyncOrgPage] { $page => new SyncOrgComponent($page, syncApi, uiServices.DocumentScrollOps, portalRouter, portalState).node }
    .collectSignal[SyncUserPage] { $page => new SyncUserComponent($page, syncApi, uiServices.DocumentScrollOps, portalRouter, portalState).node }


  private def dataMapperPagesSplitter($page: Signal[VirtualAPIsPage]): SplitRender[VirtualAPIsPage, HtmlElement] = SplitRender[VirtualAPIsPage, HtmlElement]($page)
    .collectSignal[VirtualAPIsPage_ItemsOfVirtualMetadata] { $page => virtual_api.MetadatasPage.byVirtual($page, dynamicApi, uiServices.DocumentScrollOps, portalRouter, portalState) }
    .collectSignal[VirtualAPIsPage_ItemsOfProviderMetadata] { $page => virtual_api.MetadatasPage.byProvider($page, dynamicApi, uiServices.DocumentScrollOps, portalRouter, portalState) }
    .collectSignal[VirtualAPIsPage_CustomMetadata] { $page => virtual_api.ObjectsPage.byId($page, portalApi, dynamicApi, uiServices.DocumentScrollOps, portalRouter, portalState) }
    .collectSignal[VirtualAPIsPage_StandardMetadata] { $page => virtual_api.ObjectsPage.byName($page, portalApi, dynamicApi, uiServices.DocumentScrollOps, portalRouter, portalState) }
    .collectSignal[VirtualAPIsPage_ClassOfCustomMetadata] { $page => virtual_api.FieldsAndRelationsPage.byId($page, portalApi, dynamicApi, uiServices.DocumentScrollOps, portalRouter, portalState) }
    .collectSignal[VirtualAPIsPage_ClassOfStandardMetadata] { $page => virtual_api.FieldsAndRelationsPage.byName($page, portalApi, dynamicApi, uiServices.DocumentScrollOps, portalRouter, portalState) }
}
