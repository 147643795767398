package root_pages.aurinko_pages.app.accounts

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.{Button, Dialog, Icon, Textfield}
import com.raquo.airstream.eventbus.EventBus
import com.raquo.laminar.api.L._
import common.{AccountError, AppKey, AurinkoApiPage, AuthAccountResponse, BookProfile, ClientSubscription, FormModel, InstantOps, PortalAccount, PortalAccountEditModel, TeamMemberRole, Usage, nameFieldPattern}
import common.airstream_ops._
import common.forms.FormsLocalExceptionHandling
import common.ui.breadcrumbs.{BreadcrumbsComponent, BreadcrumbsItem}
import common.ui.buttons_pair.ButtonsPairComponent
import common.forms.TextfieldFormOps
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.paginator.Paginator
import common._
import common.ui.element_binders.DialogModifying
import common.ui.icons.{IconColor, IconComponent, IconSize, IconType, MaterialIcons}
import common.ui.notifications.InfoTextComponent
import org.scalajs.dom
import portal_router.{AccountPage, AccountsPage, OrganizationPage, PortalRouter, UserPage}
import root_pages.aurinko_pages.app.dashboard.AccountUsageComponent
//import root_pages.aurinko_pages.app.dashboard.AccountUsageComponent
import service.apis.portal_api.PortalApi
import service.clipboard_service.ClipboardService
import service.portal_state.{PortalState, PortalUserAccess, TeamMemberAccess}
import service.scroll_ops.ScrollOps
import wvlet.log.Logger

import scala.annotation.tailrec
import scala.scalajs.js.timers.setTimeout

class AccountComponent($route: Signal[AccountPage],
                       portalApi: PortalApi,
                       clipboardService: ClipboardService,
                       documentScrollOps: ScrollOps,
                       portalRouter: PortalRouter,
                       portalState: PortalState,
                       apiOrigin: String
                      ) {
  private val log = Logger.of[AccountComponent]

  val accountUsage: Var[List[Usage]] = Var(Nil)
  private val AccountUsage = new AccountUsageComponent($route, accountUsage.signal, portalApi)

  val webhooks: Var[AurinkoApiPage[ClientSubscription]] = Var(AurinkoApiPage(Nil, 0, done = false, 0))
  private val WebhooksSubscription = new AccountWebhooks($route, portalApi, webhooks.signal)
  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)
  private val userAccess = new PortalUserAccess(portalState.$me)

  val bookProfile: Var[AurinkoApiPage[BookProfile]] = Var(AurinkoApiPage(Nil, 0, done = false, 0))
  private val BookProfile = new AccountBookProfile($route, portalApi, bookProfile.signal)

  def scopeListOptimizer(list: List[String]): List[String] = {
    val tupleList = list.map(x => (x.split('.').toList.head, if (x.split('.').toList.tail.head == "ReadWrite")
      "read, write"
    else
      x.split('.').toList.tail.head.toLowerCase))

    @tailrec
    def filtering(list: List[(String, String)], listRes: List[(String, String)] = List()): List[(String, String)] = {
      list match {
        case ::(head, next) => if (listRes.map(_._1).contains(head._1))
          filtering(next, listRes.map(x => if (x == (head._1, x._2)) (x._1, x._2 + ", " + head._2) else x))
        else
          filtering(next, head :: listRes)
        case Nil => listRes
      }
    }

    filtering(tupleList).map(x => x._1 + ": " + x._2).sorted
  }

  val reloadBusErrors = new EventBus[Int]
  val errorsPageSize: Int = 15

  def accountBadges(account: PortalAccount): Div = div(
    cls := "slds-grid badge-container content-height",
    if (!account.active) small(cls := "slds-col badge orange slds-m-right--xx-small", "Inactive") else None,
    if (account.hasApiErrors.getOrElse(false)) small(cls := "slds-col badge red slds-m-right--xx-small", "Error") else None,
    if (account.`type` == "managed") small(cls := "slds-col badge slds-m-right--xx-small", "Managed") else None,
    if (account.isEndUserAccount.getOrElse(false)) small(cls := "slds-col badge slds-m-right--xx-small", "End user") else None
  )

  def requestErrors(appKey: AppKey, accId: Int, pageNum: Int, limit: Int): EventStream[AurinkoApiPage[AccountError]] = {
    portalApi.accountErrors(
      appKey,
      accId,
      pageNum,
      limit
    )
  }

  val showDeletePopup: Var[Boolean] = Var(false)
  val deletePopup: Dialog.El = {
    Dialog(
      _.open <-- showDeletePopup.signal,
      _.heading := "Delete account",
      _.onClosing.mapTo(false) --> showDeletePopup,
      _.slots.default(p("This action cannot be undone!")),
      _.slots.primaryAction(

        ButtonsPairComponent[Unit, Boolean](

          primaryButtonText = "Delete",
          secondaryEffect = () => EventStream.fromValue(false),
          secondaryObserver = showDeletePopup.writer,

          primaryEffect = () => EventStream.fromValue(())
            .sample(portalState.$teamApp)
            .map(_.appKey)
            .withCurrentValueOf($route.map(_.accId))
            .flatMap(t => portalApi.deleteAccount(t._1, t._2)),

          primaryObserver = Observer[Unit](onNext = _ => dom.window.history.back())

        ).node
      )
    ).withPing(portalApi)
  }
  val showAuthPopup: Var[Boolean] = Var(false)

  val modelBus: EventBus[PortalAccount] = new EventBus[PortalAccount]

  val editModel: Var[Option[PortalAccountEditModel]] = Var(None)

  def editPopup(portalAccount: PortalAccount): Dialog.El = {
    Dialog(
      _.open <-- editModel.signal.map(_.isDefined),
      _.onClosing.mapTo(None) --> editModel,
      _.heading <-- editModel.signal.map(a => if (a.isDefined) s"${a.get.source.serviceType} account" else ""),

      _ => child.maybe <-- editModel.signal.nestedMap { model =>

        div(
          cls := "slds-grid slds-grid--vertical gap--large",

          FormsLocalExceptionHandling.errorView(model.formError.signal),

          Textfield(
            _ => cls := "slds-size--1-of-1",
            _.label := "Name",
            _.outlined := true,
            _.value <-- model.name,
            _ => onInput.mapToValue --> model.name,
            _.pattern := nameFieldPattern
          ).bindToForm(model.formState),

          Textfield(
            _ => cls := "slds-size--1-of-1",
            _.outlined := true,
            _.label := "Client org id",
            _.value <-- model.clientOrgId,
            _ => onInput.mapToValue --> model.clientOrgId
          ).bindToForm(model.formState)
        )
      },


      _.slots.primaryAction(ButtonsPairComponent[PortalAccount, Option[PortalAccountEditModel]](
        primaryDisabled = editModel.signal
          .flatMap(_.$traverse(_.formState.$submitAllowed))
          .map(!_.contains(true)),

        primaryEffect = () => ()
          .streamed
          .sample(editModel.signal)
          .collect { case Some(model) => model }
          .withCurrentValueOf($route)
          .flatMap { case (model, route) => portalApi.updateAccount(route.appKey, route.accId, model) }
          .withErrorHandlingAndCollect(
            FormsLocalExceptionHandling
              .handler(str => editModel.now.foreach(_.formError.set(str.some)))),

        primaryObserver = Observer[PortalAccount](onNext = a => {
          log.info(s"<<<: PORTALAccountID ${a.id}")
          modelBus.emit(a)
          editModel.set(None)
        }),
        secondaryEffect = () => EventStream.fromValue(None),
        secondaryObserver = editModel.writer,
      ).node,

      )

    )
      .withPingOnOpen(portalApi)
      .withFormCaching(
        portalState.FormCache,
        portalState.Session.$sessionExpiredEvents,
        editModel,
        portalAccount.toEditModel.signaled,
        (m: PortalAccountEditModel) => {
          log.info(s"<<<: UPDATE AccountModel ${m.name.now()}")
          editModel.now.foreach(_.update(m))
        },
        (cashedForm: FormModel) => cashedForm match {
          case org: PortalAccountEditModel => org.some
          case _ => None
        },
        () => editModel.now.foreach(_.formState.validate())
      )
  }

  val errors: Var[AurinkoApiPage[AccountError]] = Var(AurinkoApiPage(Nil, 0, done = false, 0))
  val pageNumVar: Var[Int] = Var(1)
  val currentPageNum: Signal[Int] = errors.signal.map(_.offset / errorsPageSize)
  val pageBus: EventBus[(Int, Int)] = new EventBus[(Int, Int)]

  val iconImage: Var[String] = Var("content_copy")
  val $iconImage: Signal[String] = iconImage.signal
  val eventBinders = List(
    $route.map(_.accId)
      .withCurrentValueOf(portalState.$teamApp.map(_.appKey))
      .flatMap(t => portalApi.account(t._2, t._1)) --> modelBus.writer,

    pageBus.events
      .withCurrentValueOf($route)
      .flatMap { case (pageNum, limit, route) => requestErrors(route.appKey, route.accId, pageNum, limit) } --> errors,
    $route --> Observer[AccountPage](_ => pageBus.emit(0, 15)) //todo: save pagesize
  )

  val node: Div = div(

    cls := "x-nested-page",

    div(
      cls := "nav-filters-container",
      BreadcrumbsComponent(
        BreadcrumbsItem("Accounts".signaled,
          $route.map(r => portalRouter.router.absoluteUrlForPage(AccountsPage(r.appKey))).some),

        BreadcrumbsItem(modelBus.events.map(_.name).startWith(None).getOrElse("Account"), None),
      ))
      .amend(cls <-- documentScrollOps.$scrolled
        .map { case true => "shadow" case _ => "" }),

    child <-- modelBus.events.map(account => {
      div(
        cls := "content-padding",
        editPopup(account),
        div(
          cls := "left-section",
          paddingTop <-- documentScrollOps.$scrollTop.changes
            .debounce(0).map(i => s"calc(var(--au-header-height) - ${i}px)"),

          div(
            cls := "left-section-header",
            div(
              cls := "slds-grid slds-grid--align-spread",
              div(
                cls := "slds-m-bottom--medium",
                p(s"${account.serviceType} account", cls := "title--level-1"),
                accountBadges(account)
              ),
              child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
                .andThen(Icon(_ => cls := "light medium clickable slds-m-top--x-small material-icons-outlined",

                  _ => "edit",
                  _ => composeEvents(onClick)(_.mapTo(account.toEditModel).map(Some(_))) --> editModel
                )
                )
            )
          ),
          div(
            cls := "content-container",
            div(
              cls := "content",
              div(
                cls := "border-top--light",

                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Id"),
                  span(account.id),
                ),

                if (account.name.getOrElse("").nonEmpty) div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Name"),
                  span(account.name),
                ) else None,

                account.loginString.map(loginName => div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray",
                    account.serviceType match {
                      case ServiceType.imap if account.loginString2.exists(_ != loginName) => "IMAP Login"
                      case _ => "Login"
                    }
                  ),
                  span(loginName),
                )
                ),

                account.loginString2.flatMap(loginName2 =>
                  if (account.serviceType == ServiceType.imap && !account.loginString.contains(loginName2))
                    Some(div(
                      cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                      small(cls := "gray", "SMTP Login"),
                      span(loginName2)
                    )) else None
                ),

                if (account.email.getOrElse("").nonEmpty) div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Email"),
                  span(account.email),
                ) else None,

                if (account.clientOrgId.getOrElse("").nonEmpty) div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Client org id"),
                  span(account.clientOrgId),
                ) else None,

                if (account.authUserId.getOrElse("").nonEmpty) div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Auth user id"),
                  span(account.authUserId),
                ) else None,

                if (account.userId.getOrElse("").nonEmpty) div(
                  //                  cls := "slds-grid slds-grid_vertical-align-center slds-m-vertical--medium",
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "End user"),
                  span(
                    cls := "blue clickable",
                    account.userId,
                    // TODO: REVIEW: we have the router observer for this
                    composeEvents(onClick)(_.sample($route)) --> Observer[AccountPage](onNext = route => portalRouter.navigate(UserPage(route.appKey, account.userId.get)))
                  ),
                ) else None,

                if (account.authOrgId.getOrElse("").nonEmpty) div(
                  //                  cls := "slds-grid slds-grid_vertical-align-center slds-m-vertical--medium",
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Organization"),
                  span(
                    cls := (if (account.organization.isDefined) "blue clickable" else ""),
                    account.authOrgId,
                    composeEvents(onClick.filter(_ => account.organization.isDefined))
                    // TODO: REVIEW: we have the router observer for this
                    (_.sample(portalState.$teamApp.map(_.appKey))) --> Observer[AppKey](onNext = key => portalRouter.navigate(OrganizationPage(key, account.organization.get.id))),
                  ),
                ) else None,

                if (account.serverUrl.getOrElse("").nonEmpty)
                  div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray",
                      child.text <-- Signal.fromValue(account.serviceType).map {
                        case accountST if accountST == ServiceType.imap => "IMAP Url"
                        case accountST if accountST == ServiceType.smtp => "SMTP Url"
                        case _ => "Server Url"
                      }),
                    span(account.serverUrl),
                  ) else None,

                if (account.serverUrl2.getOrElse("").nonEmpty && account.serviceType == ServiceType.imap)
                  div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "SMTP Url"),
                    span(account.serverUrl2),
                  ) else None,


                if (account.createdAt.isDefined) div(
                  //                  cls := "slds-grid slds-grid_vertical-align-center slds-m-vertical--medium",
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Created at"),
                  span(account.createdAt.get.toPrettyLocalFormat),
                ) else None,

                account.bookingCount.collect {
                  case x if x > 0 => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Booking profiles"),
                    span(x)
                  )
                },

                account.templatesCount.collect {
                  case accountTemplatesCount if accountTemplatesCount > 0 =>
                    div(
                      cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                      small(cls := "gray", "Email templates"),
                      span(accountTemplatesCount),
                    )
                },

                account.trackingActive.collect {
                  case true =>
                    div(
                      cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                      small(cls := "gray", "Email tracking"),
                      span(true),
                    )
                },


                div(
                  cls := "border-top--light slds-p-top--small slds-p-bottom--large",
                  child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen(
                    Button(
                      _.label := "Delete account",
                      _.outlined := true,
                      _ => cls := "red",
                      _ => onClick.mapTo(true) --> showDeletePopup,
                    )
                  )
                )
              )
            ),


          )
        ),

        deletePopup,

        {
          val onAuthorized = new EventBus[Boolean]
          div(
            onAuthorized.events
              .filter(_ == true)
              .sample(portalState.$teamApp.map(_.appKey))
              .withCurrentValueOf($route)
              .flatMap(t => portalApi.account(t._1, t._2.accId)) --> modelBus.writer,
            child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).sAndThen($route
              .map(_.appKey)
              .map {
                appKey =>
                  AccountAuthorization(
                    appKey = appKey,
                    initialAccount = account.some,
                    daemonMode = (account.`type` == "daemon").signaled,
                    onSuccess = onAuthorized.writer.contramap((a: AuthAccountResponse) => a.status == "success"),
                    visible = showAuthPopup,
                    portalApi = portalApi,
                    portalState = portalState,
                    documentScrollOps = documentScrollOps,
                    apiOrigin = apiOrigin
                  ).node
              }
            ))
        },

        div(
          cls := "right-section slds-m-top--large",
          div(
            div(
              cls := "slds-m-top_xx-large slds-m-bottom_medium",
              span(cls := "title--level-3", "Tokens")
            ),
            div(
              cls := "data-table",
              div(
                cls := "table-header",
                span(cls := "slds-col slds-size--1-of-12 gray", "Id"),
                span(cls := "slds-col slds-size--6-of-12 gray", "Scopes"),
                span(cls := "slds-col slds-size--3-of-12 gray", "Last activity")
              ),
              if (account.tokens.getOrElse(Nil).nonEmpty)
                children <-- account
                  .tokens.getOrElse(Nil).sortBy(_.id)
                  .signaled
                  .nestedMap {
                    token =>
                      val copyTokenMenu: Var[Option[String]] = Var(None)
                      div(

                        cls := "table-row panel-like",
                        span(cls := "slds-col slds-size--1-of-12", div(token.id.toString)),
                        span(cls := "slds-col slds-size--6-of-12",
                          token.scopes match {
                            case Some(value) => scopeListOptimizer(value).mkString("; ")
                            case None => ""
                          }),
                        div(cls := "slds-col slds-size--3-of-12",
                          span(token.lastActivity match {
                            case Some(value) => value.toPrettyLocalFormat
                            case None => ""
                          })),

                        child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen(div(
                          cls := "slds-col slds-grid slds-grid_vertical-align-center growing-block slds-is-relative slds-grid_align-end",
                          material.Menu(
                            _ => cls := "info-popup",
                            _.open <-- copyTokenMenu.signal.map(_.isDefined),
                            _.corner := "BOTTOM_LEFT",
                            _.onClosed.mapTo(None) --> copyTokenMenu,
                            _.slots.default(
                              div(
                                cls := "slds-grid slds-grid_vertical-align-center slds-grid_align-spread slds-p-around--medium popup-content break-word",
                                List(
                                  div(
                                    cls := "slds-p-right--x-small",
                                    child.text <-- copyTokenMenu.signal.map(_.getOrElse(""))
                                  ),
                                  div(
                                    child <-- $iconImage.map { i =>
                                      IconComponent(
                                        icon = i,
                                        color = IconColor.`brown`,
                                        size = IconSize.medium,
                                        iconType = IconType.outlined
                                      )
                                    }
                                  ).amend
                                  (
                                    cls := "slds-m-right--x-small clickable",
                                    onClick --> Observer[dom.MouseEvent](_ => {
                                      iconImage.set(MaterialIcons.done)
                                      setTimeout(500) {
                                        iconImage.set(MaterialIcons.copy)
                                        copyTokenMenu.set(None)
                                      }
                                    }
                                    ),
                                    composeEvents(onClick)(_
                                      .sample(copyTokenMenu.signal.map(_.getOrElse(""))))
                                      --> Observer[String](onNext = text => {
                                      clipboardService.copy(text)
                                    })
                                  )
                                )
                              )
                            )
                          ),
                          Option.when(token.obtainedViaPortal)(
                            Icon(
                              _ => "visibility",
                              _ => cls := "slds-col clickable slds-m-left_small light primary small",
                              _ => composeEvents(onClick)(_.sample(portalState.$teamApp)
                                .flatMap(key => portalApi.accountToken(key.appKey, account.id, token.id))
                                .map(Some(_))) --> copyTokenMenu),
                          ),
                          Option.when(token.status != "active")(
                            IconComponent(
                              icon = token.status match {
                                case "invalid" => "warning"
                                case "dead" => "error"
                                case _ => ""
                              },
                              color = IconColor.`brown`,
                              size = IconSize.small,
                              iconType = IconType.outlined
                            ).amend(
                              cls := "slds-col clickable slds-m-left_small light primary small",
                              onClick.mapTo(token.errorDesc) --> copyTokenMenu
                            )
                          )
                        )),
                      )
                  } else span(
                cls := "gray slds-m-top--large slds-align_absolute-center",
                "No tokens")
            )
          ),
          div(
            cls := "slds-grid gap--small",
            child.maybe <-- userAccess.verifiedCheck
              .andThen {
                if (!account.serviceType.isBot)
                  Some(Button(
                    _ => cls := "blue",
                    _.outlined := true,
                    _.label := "Reauth account",
                    _ => onClick.mapTo(true) --> showAuthPopup,

                    _.disabled <-- portalState.$team
                      .map(_.billingInfo.exists(_.hasIssues)),

                  ))
                else None
              }.map(_.flatten),

            child.maybe <-- portalState.$team
              .map {
                case team if !team.role.isAdmin && team.billingInfo.exists(bi => bi.subscriptionRequired || bi.paymentInfo.exists(_.hasDebt)) =>
                  "Action denied. Please contact team administrator.".some
                case team if team.billingInfo.exists(bi => bi.subscriptionRequired) =>
                  "Subscription required. Please add payment method on team billing page.".some
                case team if team.billingInfo.exists(_.paymentInfo.exists(_.hasDebt)) =>
                  "Payment required.".some
                case _ => None
              }
              .nestedMap(text => InfoTextComponent.standard(text.signaled))
          ),

          AccountUsage.node,

          if (account.hasApiErrors.getOrElse(false)) div(
            div(
              cls := "slds-m-bottom_small slds-m-top_medium",
              span(cls := "title--level-3", "Errors")
            ),
            div(
              cls := "data-table",
              p(
                cls := "slds-size--11-of-12 table-header",
                span(cls := "slds-size--6-of-12  gray", "Path"),
                span(cls := "slds-size--2-of-12  gray", "Error"),
                span(cls := "slds-size--3-of-12  gray", "Time"),
                span(cls := "slds-size--1-of-12  gray", "Occurrences")
              ),
              children <-- errors.signal.combineWith(pageNumVar)
                .map(t => t._1.records.map(error =>
                  ExpansionPanelComponent(
                    header = p(
                      cls := "slds-size--11-of-12 slds-grow slds-grid",
                      span(cls := "slds-size--6-of-12 au-truncate", error.apiPath),
                      span(cls := "slds-size--2-of-12", error.errorCode),
                      span(cls := "slds-size--3-of-12", error.requestTime.toPrettyLocalFormat),
                      span(cls := "slds-size--1-of-12", error.occurrencesCount)
                    ),
                    body = Some(div(
                      div(
                        cls := "slds-grid slds-grid_vertical-align-start slds-m-bottom_medium",

                        div(cls := "slds-col slds-size--1-of-8 gray", span("Request Id")),
                        div(cls := "slds-col", span(cls := "break-word-overflow", error.requestId))
                      ),
                      div(
                        cls := "slds-grid slds-grid_vertical-align-start slds-m-bottom_medium",

                        div(cls := "slds-col slds-size--1-of-8  gray", span("Path")),
                        div(cls := "slds-col",
                          if (error.apiQuery.isEmpty)
                            span(cls := "break-word-overflow", error.apiPath)
                          else
                            div(span(cls := "break-word-overflow", error.apiPath), span(cls := "light break-word-overflow", "?" + error.apiQuery))
                        ),
                      ),
                      div(
                        cls := "slds-grid slds-grid_vertical-align-start slds-m-bottom_medium",
                        div(cls := "slds-col slds-size--1-of-8 gray",
                          span("Error message")
                        ),
                        div(
                          cls := "slds-col",
                          span(cls := "break-word-overflow", error.errorMessage)
                        )
                      )
                    )),

                    horizontalPadding = false
                  ).node)
                )
            ),
            div(
              cls <-- errors.signal.map(_.totalSize <= errorsPageSize).map { case true => "hidden" case false => "" },
              Paginator(
                pageNum = currentPageNum,
                totalCount = errors.signal.map(_.totalSize),
                pageSize = Signal.fromValue(errorsPageSize),
                onPageChange = Observer[(Int, Int)](onNext = i => {
                  pageBus.emit(i._1 -> i._2)
                }),
                documentScrollTopAfterPageChange = false,
                None,
                itemsPluralLabel = "Errors",
              ).node,

            )
          ) else None,

          WebhooksSubscription.node,

          account.bookingCount.collect {
            case x if x > 0 => BookProfile.node
          },

        ),
      )
    }),
  )
    .amend(eventBinders)
}
