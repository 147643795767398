package common.ui.app_card

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common._
import common.ui.icons.{IconColor, IconComponent, IconShape, IconSize, IconType, MaterialIcons}
import org.scalajs.dom.html

case class AppCardComponent($app: Signal[PortalApplication]) {


    val node: ReactiveHtmlElement[html.Div] = div(
      cls := "slds-grid slds-grid--vertical-align-center slds-p-vertical--large",
      child <-- $app.map {
        case a if a.logo.isDefined => img(
          src := s"data:${a.logo.get.mimeType};base64, ${a.logo.get.content}",
          cls := "slds-m-right--small",
          width := "4rem",
          height := "4rem")
        case _ => IconComponent(
          icon = MaterialIcons.application,
          color = IconColor.`light-brown`,
          shape = IconShape.square.some,
          size = IconSize.`x-large`)
          .amend(cls := "slds-m-right--small")
      },

      div(
        cls := "slds-m-right--medium",
        p(
          cls := "subtitle--level-1 slds-m-bottom--xx-small break-word-space",
          child.text <-- $app.map(_.name)),

        child.maybe <-- $app.map(_.description.map(descr =>
          p(
            cls := "text-small gray break-word-space",
            descr)
        ))
      ),

      child.maybe <-- $app.map(_.websiteUrl.map(url => a(
        IconComponent(
          icon = MaterialIcons.link,
          color = IconColor.orange,
          iconType = IconType.outlined,
          size = IconSize.small
        ),
        title := url,
        href := url,
        target := "_blank",
        onClick.stopPropagation --> Observer.empty
      )))
    )
  }

