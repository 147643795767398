package common

import com.github.uosis.laminar.webcomponents.material.Textfield.El
import com.github.uosis.laminar.webcomponents.material.{Checkbox, Radio, Select, Textarea, Textfield}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.ValueToObservableOps
import common.ui.AuFormStateExp
import common.ui.notifications.LocalMessagesView
import common.ui.auto_suggest.AutoSuggestComponent
import org.scalajs.dom.html
import root_pages.aurinko_pages.app.settings.{ColorInput, LogoInputComponent}
import service.exception_handler.LocalExceptionHandler

package object forms {
  implicit final class TextfieldFormOps(private val textField: Textfield.El) extends AnyVal {
    def bindToForm(form: AuFormStateExp,
                   customValidation: Option[String => Boolean] = None,
                   $customValidation: String => Signal[Boolean] = (_: String) => true.signaled,
                   initialValidation: Boolean = false
                  ): El = {
      form.addTextField(textField, customValidation, $customValidation, initialValidation)
      textField
    }
  }

  implicit final class TextareaFormOps(private val textarea: Textarea.El) extends AnyVal {
    def bindToForm(form: AuFormStateExp): Textarea.El = {
      form.addTextArea(textarea)
      textarea
    }

  }

  implicit final class CheckboxFormOps(private val checkbox: Checkbox.El) extends AnyVal {
    def bindToForm(form: AuFormStateExp): Checkbox.El = {
      form.addCheckbox(checkbox)
      checkbox
    }

  }

  implicit final class RadioFormOps(private val radio: Radio.El) extends AnyVal {
    def bindToForm(form: AuFormStateExp, $externalChange: EventStream[Unit] = EventStream.empty): Radio.El = {
      form.addRadio(radio, $externalChange = $externalChange)
      radio
    }

  }

  implicit final class SelectFormOps(private val select: Select.El) extends AnyVal {
    def bindToForm(form: AuFormStateExp): Select.El = {
      form.addSelect(select)
      select
    }
  }

  implicit final class AutoSuggestFormOps(private val autoSuggest: AutoSuggestComponent) extends AnyVal {
    def bindToForm(form: AuFormStateExp): AutoSuggestComponent = {
      form.addAutoSuggest(autoSuggest)
      autoSuggest
    }
  }

  implicit final class ImageInputFormOps(private val imageInput: LogoInputComponent) extends AnyVal {
    def bindToForm(form: AuFormStateExp): ReactiveHtmlElement[html.Div] = {
      form.addCustomValidation(imageInput.validatorExp)
      imageInput.node
    }
  }

  implicit final class ColorInputFormOps(private val colorInput: ColorInput) extends AnyVal {
    def bindToForm(form: AuFormStateExp): ReactiveHtmlElement[html.Div] = {
      form.addFormField(colorInput.colorField)
      colorInput.node
    }
  }


  object FormsLocalExceptionHandling {
    def errorView($errorMessage: Signal[Option[String]], scrollTopWhenChanged: Boolean = true): ReactiveHtmlElement[html.Div] = div(
      LocalMessagesView($errorMessage)
        .amendThis(th => $errorMessage.signal.map(_.isDefined) --> Observer[Boolean](er => if (er) th.ref.scrollIntoView(scrollTopWhenChanged)))
    )

    def handler(showError: String => Unit): Throwable => Unit = (error: Throwable) => LocalExceptionHandler.handleAirstreamException(error, showError)
  }
}
