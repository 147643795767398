
import com.raquo.laminar.api.L._
import org.scalajs.dom
import org.scalajs.dom.Document
import org.scalajs.dom.raw.Location
import portal_router.Page
import service.exception_handler.ExceptionHandler

object Root extends App {
  renderOnDomContentLoaded(
    dom.document.getElementById("app-container"),
    new RootComponent(
      dom.document,
      dom.window.location
    ).node
  )
}

class RootComponent(document: Document, wl: Location) {

  private val portalComponents = new Components(document, wl)

  private val exceptionHandler = new ExceptionHandler(
    portalComponents.uiServices.Messages,
    portalComponents.portalRouter,
    portalComponents.portalState.Session.onSessionExpired,
    portalComponents.portalState.resetState
  )

  AirstreamError.registerUnhandledErrorCallback(e =>
    exceptionHandler.handleAirstreamException(e, portalComponents.portalState.Session.PortalUserAuthInfo))

  val node: Div = div(
    cls := "app-element",

    windowEvents.onError --> Observer[dom.ErrorEvent](exceptionHandler.handleDomException),

    portalComponents.portalRouter.router
      .$currentPage
      .mapTo(())
      --> Observer[Unit](_ => portalComponents.uiServices.DocumentScrollOps.scrollToTop()),

    windowEvents.onScroll
      .mapTo(dom.document.documentElement.scrollTop) --> portalComponents.uiServices.DocumentScrollOps.detectScroll,


    portalComponents.uiServices.node,
    child <-- portalComponents.$components

  )
}
