package root_pages.aurinko_pages.app.virtual_api.components

import com.raquo.laminar.api.L._
import portal_router.{PortalRouter, VirtualAPIsPage, VirtualAPIsPage_ItemsOfProviderMetadata, VirtualAPIsPage_ItemsOfVirtualMetadata}

case object Menu {
  trait Item {
    def id: String

    def icon: String

    def label: String

    def createPage(appKey: common.AppKey): portal_router.Page

    def isActive(page: portal_router.Page): Boolean
  }

  case object Constants {
    private case class Item[T <: portal_router.VirtualAPIsPage](
                                                                 id: String,
                                                                 icon: String,
                                                                 label: String,
                                                                 makePage: common.AppKey => T,
                                                                 checkPage: portal_router.Page => Boolean,
                                                               ) extends Menu.Item {
      def createPage(appKey: common.AppKey): portal_router.Page = makePage(appKey)

      def isActive(page: portal_router.Page): Boolean = checkPage(page) // does not work: page.isInstanceOf[T]
    }

    private val Configuration: Menu.Item = Item[VirtualAPIsPage_ItemsOfVirtualMetadata](
      id = "virtual-metadata",
      icon = "settings_input_component",
      label = "Virtual models",
      makePage = VirtualAPIsPage_ItemsOfVirtualMetadata,
      checkPage = (p: portal_router.Page) => p.isInstanceOf[VirtualAPIsPage_ItemsOfVirtualMetadata]
    )
    //private val Mapping: Menu.Item = Item(id = "mapping", icon = "mediation", label = "Mapping", page = (appKey: common.AppKey) => )
    private val Metadata: Menu.Item = Item[VirtualAPIsPage_ItemsOfProviderMetadata](
      id = "provider-metadata",
      icon = "dynamic_form",
      label = "Provider models",
      makePage = VirtualAPIsPage_ItemsOfProviderMetadata,
      checkPage = (p: portal_router.Page) => p.isInstanceOf[VirtualAPIsPage_ItemsOfProviderMetadata]
    )
    //private val Description: Menu.Item = Item(id = "description", icon = "settings_ethernet", label = "Description", page = (appKey: common.AppKey) => )

    val all: List[Menu.Item] =
      Configuration ::
        //Mapping ::
        Metadata ::
        //Description ::
        Nil
  }

  def apply(
             $route: Signal[VirtualAPIsPage],
             items: Signal[Seq[Menu.Item]],
             portalRouter: PortalRouter
           ): HtmlElement = div(
    cls := "virtual-apicontainer",
    ul(
      cls := "vertical-menu",
      children <-- items.split(_.id)((_, _, $item: Signal[Menu.Item]) => {
        li(
          child.text <-- $item.map(_.label),
          cls := "menu-item menu-left-item slds-size--1-of-1",

          cls <-- $item.combineWith($route)
            .map(x => x._1.isActive(x._2))
            .map {
              case true => "active"
              case _ => ""
            },
          composeEvents(onClick)(_
            .sample($item)
            .withCurrentValueOf($route.map(_.appKey))
            .map(x => x._1.createPage(x._2)))
            --> portalRouter.goto,
        )
      }),
    ))
}
