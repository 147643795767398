package root_pages.aurinko_pages.main

import cats.implicits.{catsSyntaxOptionId, none}
import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.{Button, Dialog, Icon, Menu, Textfield}
import com.raquo.airstream.core.Observer
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.{AppKey, InstantOps, PingTools, PortalApplication, PortalTeam, PortalUser, TeamMemberRole, Usage, byteToGb, byteToKb, byteToMb, byteToTb}
import common.airstream_ops.{EventStreamOptionOps, SignalNestedOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.ui.app_card.AppCardComponent
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.grid_configs.{GridConfig4, GridConfig5}
import common.ui.icons.{IconColor, IconComponent, IconShape, IconSize, IconType, MaterialIcons}
import common.ui.mat_components_styles.fixMwcDialogOverflow
import common.ui.notifications.{GeneralTodosComponent, SuperscriptWarningIcon}
import common.ui.search_input.SearchInputComponent
import common.ui.text_data_view
import common.ui.element_binders.DialogModifying
import org.scalajs.dom
import org.scalajs.dom.html
import portal_router.{BillingPage, BufferPage, CreateAppPage, DashboardPage, Page, PortalRouter, TeamAppsPage, TeamSettingsPage}
import service.apis.portal_api.PortalApi
import service.clipboard_service.ClipboardService
import service.exception_handler.{ForbiddenError, UnknownException}
import service.local_storage.LocalStorageService
import service.portal_state.{PortalState, PortalUserAccess, TeamMemberAccess}
import service.ui.messages.Messages
import wvlet.log.Logger

import java.time.Instant
import java.time.temporal.ChronoUnit


class MainPageComponent($page: Signal[TeamAppsPage],
                        portalApi: PortalApi,
                        portalState: PortalState,
                        portalRouter: PortalRouter,
                        clipboardService: ClipboardService,
                        messages: Messages,
                        localStorageService: LocalStorageService,
                        openProfileDialog: () => Unit
                       ) {

  private val $teams: Signal[List[PortalTeam]] = portalState.$me.map(_.teams)

  private val $currentTeam: Signal[PortalTeam] = $page.map(_.teamId)
    .flatMap(tId => $teams.map(_.find(_.id == tId)))
    .getOrFail(ForbiddenError())

  private val showNewTeamPopupBus = new EventBus[Unit]

  private val $apps: Signal[List[PortalApplication]] = $currentTeam
    .map(_.apps)
    .map(_.toList.flatten)

  private val TeamsComponent = new TeamsManagement(
    portalApi,
    portalState,
    portalRouter,
    $currentTeam.map(_.some),
    $teams,
    showNewTeamPopupBus.events
  )

  private val ApplicationsComponent = new AppsManagement(
    portalApi,
    portalState,
    portalRouter,
    $apps,
    $currentTeam,
    clipboardService,
    onShowNewTeamPopup = showNewTeamPopupBus.writer
  )
  val NotificationComponent: Signal[Option[ReactiveHtmlElement[html.Div]]] = {
    val Todos = new GeneralTodosComponent($currentTeam,
      portalState.$me,
      portalRouter,
      portalApi,
      portalState,
      messages,
      openProfileDialog,
      localStorageService,

    )

    Todos.$todoElements
      .combineWith($currentTeam).mapOptWhenTrue { case (todos, t) =>
        todos.nonEmpty || t.billingInfo.exists(_.needsNotification) && t.role.isAdmin
      }(
        div(
          //        p(
          //          cls := "title--level-2 slds-p-bottom--large",
          //          "Notifications"
          //        ),

          child.maybe <-- $currentTeam
            .mapOptWhenTrue(t => t.billingInfo.exists(_.needsNotification) && t.role.isAdmin) {
              div(
                cls := "slds-grid slds-grid--vertical gap--medium",
                child.maybe <-- $currentTeam.map {
                  case t if t.role.isAdmin =>
                    t.billingInfo.flatMap(bi =>

                      Option.when(bi.paymentEnabled && !bi.paymentMethodConfigured && bi.trialDaysLeft <= 7) {
                        div(
                          cls := "block--light-orange slds-p-around--large slds-size--1-of-1",
                          p(
                            s"${
                              Option.when(bi.trialDaysLeft > 0) {
                                s"Your trial period expires in ${bi.trialDaysLeft} days."
                              }.getOrElse("Your trial period ended.")
                            } Please add payment method ",
                            a(
                              "here →",
                              href := portalRouter.link(BillingPage(t.id))
                            ),
                          )
                        )
                      }
                    )
                  case _ => None
                },

                child.maybe <-- $currentTeam.map {
                  case t if t.role.isAdmin =>
                    t.billingInfo.flatMap(bi =>

                      Option.when(bi.paymentInfo.exists(_.hasDebt)) {
                        div(
                          cls := "block--light-red slds-p-around--large slds-size--1-of-1",
                          p(
                            "We can't process your payment. Please review your payment details and check that there is money in your associated account. ",
                            a(
                              "Review →",
                              href := portalRouter.link(BillingPage(t.id))
                            ),
                          )
                        )
                      },

                    )
                  case _ => None
                },

              )
            },

          div(
            cls := "slds-m-top--large",
            Todos.renderElements
          )
        )
      )
  }

  private def LeaveTeamComponent(team: PortalTeam) = Option.when(team.role != TeamMemberRole.owner) {
    val showConfirm = Var(false)
    div(
      cls := "slds-p-top--small",
      Button(
        _.label := "Leave team",
        _.outlined := true,
        _ => cls := "red",
        _ => onClick.mapTo(true) --> showConfirm,
      ),

      Dialog(
        _ => onMountCallback(fixMwcDialogOverflow),
        _.open <-- showConfirm,
        _.heading := "Leave team?",

        _.onClosing.mapTo(false) --> showConfirm,

        _.slots.default(p("This action cannot be undone!")),

        _.slots.primaryAction(

          ButtonsPairComponent[PortalUser, dom.MouseEvent](
            primaryButtonText = "Leave",

            primaryEffect = () => EventStream.fromValue(())
              .sample(portalState.$me)
              .flatMap(me => for {
                _ <- portalApi.leaveTeam(team.id)
                me <- portalApi.getMe
              } yield me),

            primaryObserver = Observer[PortalUser](onNext = userToUpdate => {

              portalState.updateMe(userToUpdate)
              portalRouter.navigate(BufferPage)

            }),

            secondaryObserver = showConfirm.writer.contramap((_: dom.MouseEvent) => false)
          ).node
        ))
        .withPing(portalApi)

    )
  }


  val node: ReactiveHtmlElement[html.Div] = div(
    cls := "content-wrapper",
    //    div(
    //      cls := "main-page-header",
    //      TeamsComponent().amend(cls := "subtitle--level-1"),
    //      ApplicationsComponent.ApplicationSearch,
    //      div(
    //        child.maybe <-- $teams.mapOptWhenTrue(_.exists(_.role.isAdmin)) {
    //          ApplicationsComponent.ApplicationCreation
    //        }
    //      )
    //    ),
    div(
      cls := "slds-p-top--x-large growing-block content-padding nested-page slds-grid slds-grid--vertical gap--xx-large",

      p(
        cls := "title--level-2",
        "Applications"
      ),
      child.maybe <-- NotificationComponent,
      ApplicationsComponent(),
      child.maybe <-- portalState.$maybeTeam.subflatMap(LeaveTeamComponent),
    )
  )
}

class TeamsManagement(portalApi: PortalApi,
                      portalState: PortalState,
                      portalRouter: PortalRouter,
                      $currentTeam: Signal[Option[PortalTeam]],
                      $teams: Signal[List[PortalTeam]],
                      $showNewTeamPopupStream: EventStream[Unit]
                     ) {
  private def navigate(page: Page): Unit = portalRouter.router.pushState(page)

  private val showTeamsPopup: Var[Boolean] = Var(false)
  private val showNewTeamPopup: Var[Boolean] = Var(false)
  private val teamToLeave: Var[Option[PortalTeam]] = Var(None)

  private def TeamTitle(team: Option[PortalTeam]) = {
    val teamName = team.map(_.name).getOrElse("Teams")

    div(
      cls := "clickable slds-grid slds-grid--vertical-align-center slds-grid-align-spread",
      IconComponent(
        icon = MaterialIcons.teams,
        color = IconColor.orange,
        shape = IconShape.circle.some,
        materialIconType = IconType.filled
      ),

      p(
        cls := "slds-m-left--small",
        teamName,
        composeEvents(onClick)(_.sample(portalState
          .$maybeTeam.nestedMap(t => TeamAppsPage(t.id)))) --> portalRouter.gotoSome,
      ),
    )
  }

  private def BillingWarningIcon() = SuperscriptWarningIcon("Payment required.")

  private def CreateTeamButton = div(
    cls := "blue clickable slds-grid slds-grid--vertical-align-center",
    material.Icon(_ => "add", _ => cls := "slds-m-right--x-small"),
    span("Create team"),

    onClick.mapTo(true) --> showNewTeamPopup,
    onClick.mapTo(false) --> showTeamsPopup
  )

  private def CreateTeamWindow(onCreateStart: Observer[Unit]) = div(
    cls := "slds-size--1-of-1 slds-grid slds-grid--vertical slds-grid--vertical-align-center border-around--light slds-m-top--large slds-p-vertical--large",
    div(
      cls := "slds-grid slds-grid--vertical",
      p(
        cls := "title--level-3",
        "Create your first team"),
      Button(
        _.label := "Create new team",
        _.raised := true,
        _ => cls := "secondary slds-m-top--medium",
        _ => onClick.mapTo(true) --> showNewTeamPopup.writer,
        _ => onClick.mapTo(()) --> onCreateStart
      )
    )
  )

  private def NewTeamPopup = {
    val teamName = Var("")
    Dialog(
      _ => onMountCallback(fixMwcDialogOverflow),
      _.heading := "Create team",
      _.open <-- showNewTeamPopup.signal,
      _.onClosed.mapTo(false) --> showNewTeamPopup,
      _.onClosed.mapTo("") --> teamName,
      _ => PingTools.dialogBinders(portalApi),
      _.slots.default(
        Textfield(
          _ => cls := "width-full",
          _.outlined := true,
          _ => onInput.mapToValue --> teamName,
          _.value <-- teamName,
          _.label := "Team name"
        )
      ),
      _.slots.primaryAction(
        ButtonsPairComponent[(PortalUser, Int), dom.MouseEvent](
          primaryButtonText = "Create",
          primaryDisabled = teamName.signal.map(_.trim().isEmpty),
          primaryEffect = () => for {
            team <- portalApi.createTeam(teamName.now)
            me <- portalApi.getMe
          } yield (me, team.id),
          primaryObserver = Observer[(PortalUser, Int)] { case (me, teamId) =>
            showNewTeamPopup.set(false)
            portalState.updateMe(me)
            navigate(TeamAppsPage(teamId))
          },
          secondaryObserver = showNewTeamPopup.writer.contramap((_: dom.MouseEvent) => false)
        ).node
      )
    )
  }


  private def TeamsMenu = Menu(
    _.open <-- showTeamsPopup.signal,
    _.onClosed.mapTo(false) --> showTeamsPopup.writer,
    _ => cls := "dropdown_menu",
    _.slots.default(
      div(
        cls := "slds-p-horizontal--large slds-p-vertical--medium",
        width := "27rem",
        maxHeight := "calc(100vh - 2 * var(--au-header-height))",
        child.maybe <-- $currentTeam.nestedMap(team =>
          TeamTitle(team.some)
            .amend(
              cls := "border-bottom--light slds-p-vertical--medium clickable",
              onClick.mapTo(TeamAppsPage(team.id)) --> portalRouter.goto,
              onClick.mapTo(false) --> showTeamsPopup,
            )),
        div(
          cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread border-bottom--light slds-p-vertical--large",
          span(
            cls := "title--level-3",
            "Teams"
          ),

          child.maybe <-- $teams.map(l => Option.when(l.nonEmpty) {
            CreateTeamButton
          })

        ),
        children <-- $teams
          //          .combineWith($currentTeam)
          //          .map{ case (list, ct) => list.filterNot(t => ct.exists(_.id == t.id))} //filter current team
          .split(_.id) { (_: Int, _: PortalTeam, $team: Signal[PortalTeam]) =>
            div(
              cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread slds-p-vertical--medium",
              div(
                cls := "slds-grid slds-grid--vertical-align-center clickable",
                IconComponent(
                  MaterialIcons.teams,
                  IconColor.`light-brown`,
                  shape = IconShape.circle.some,
                  size = IconSize.medium,
                  materialIconType = IconType.filled
                ),
                span(
                  cls := "subtitle--level-1 slds-m-left--small",
                  child.text <-- $team.map(_.name)
                ),

                child.maybe <-- $team.map {
                  case t if t.role.isAdmin && t.billingInfo.exists(_.hasIssues) => BillingWarningIcon().some
                  case _ => none[Icon.El]
                },

                composeEvents(onClick)(_
                  .sample($team.map(t => TeamAppsPage(t.id)))) --> portalRouter.goto,

                onClick.mapTo(false) --> showTeamsPopup
              ),

              div(
                cls := "slds-grid slds-grid--vertical-align-center",
                //                child.maybe <-- $team.map(t => Option.when(t.role != TeamMemberRole.owner) {

                //                  span(
                //                    cls := "red clickable slds-m-right--x-small",
                //                    "Leave team",
                //                    composeEvents(onClick)(_.sample($team).map(_.some)) --> teamToLeave
                //
                //                  )

                //                }),
                IconComponent(
                  icon = MaterialIcons.settings,
                  color = IconColor.`lighter-brown`,
                  iconType = IconType.outlined
                )
                  .amend(
                    cls := "clickable",
                    composeEvents(onClick)(_.sample($team.map(_.id).map(TeamSettingsPage))) --> portalRouter.goto
                  )
              ),
              onClick.mapTo(false) --> showTeamsPopup,
            )
          },

        child.maybe <-- $teams.map(l => Option.when(l.isEmpty) {
          CreateTeamWindow(showTeamsPopup.writer.contramap((_: Unit) => false))
        })
      )
    )
  )

  private val eventsSubscriptions = $showNewTeamPopupStream.mapTo(true) --> showNewTeamPopup.writer

  def apply(): ReactiveHtmlElement[html.Div] =
    div(
      eventsSubscriptions,

      NewTeamPopup,
      div(
        cls := "slds-is-relative",
        div(
          cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
          child <-- $currentTeam.map(TeamTitle),
          child.maybe <-- $currentTeam.subflatMap {
            case t if t.role.isAdmin && t.billingInfo.exists(_.hasIssues) => BillingWarningIcon().some
            case _ => none[Icon.El]
          },

          IconComponent(
            icon = MaterialIcons.unfold,
            color = IconColor.grey,
            iconType = IconType.outlined,
            size = IconSize.medium
          ).amend(cls := "clickable slds-m-left--small",
            onClick.mapTo(true) --> showTeamsPopup),
        ),
        TeamsMenu
      )
    )

}

class AppsManagement(portalApi: PortalApi,
                     portalState: PortalState,
                     portalRouter: PortalRouter,
                     $apps: Signal[List[PortalApplication]],
                     $team: Signal[PortalTeam],
                     clipboardService: ClipboardService,
                     onShowNewTeamPopup: Observer[Unit]
                    ) {

  private val logger = Logger.of[AppsManagement]

  private val searchText = Var("")
  private val $appsToShow = searchText.signal
    .combineWith($apps)
    .map { case (search, apps) => apps.filter(_.name.toLowerCase.contains(search.toLowerCase)) }
  private val teamMemberAccess = new TeamMemberAccess($team)
  private val portalUserAccess = new PortalUserAccess(portalState.$me)

  def ApplicationCreation: ReactiveHtmlElement[html.Div] = div(
    cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center clickable blue",
    Button(
      _.outlined := true,
      _ => cls := "slds-m-left--large blue",
      _.label := "Create new application",
      _.icon := "add",
      _ => composeEvents(onClick)(_.sample($team.map {
          case team if team.role.isAdmin => team.id.some
          case _ => None
        })
        .map(CreateAppPage)) --> portalRouter.goto
    )
  )

  def ApplicationSearch: Textfield.El =
    SearchInputComponent(onChange = searchText.writer, label = "Search app")
      .node
      .amend(cls := "width-medium")

  private def NoAppsView = {
    val $isAdmin = $team.map(_.role.isAdmin)
    div(
      cls := "slds-size--1-of-1 slds-p-vertical--x-large slds-grid slds-grid--vertical slds-grid--vertical-align-center border-around--light gap--large",
      p(
        cls := "title--level-2",
        "You don't have any apps yet."),

      p(
        "Every app you ",
        child.text <-- $isAdmin.map(Option.when(_) {
          "create or "
        }.getOrElse("")),
        "become a collaborator on will appear here."),

      //      child.maybe <-- $team.map { t =>
      //        Option.when(t.isEmpty) {
      //          Button(
      //            _.raised := true,
      //            _ => cls := "secondary",
      //            _.label := "Create team",
      //            _ => onClick.mapTo(()) --> onShowNewTeamPopup
      //          )
      //        }
      //      },


      child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin).andThen(
        Button(
          _.raised := true,
          _ => cls := "secondary",
          _.label := "Create new app",
          _ => composeEvents(onClick)(_
            .sample($team.map(_.id.some)
              .map(CreateAppPage)
            )
          ) --> portalRouter.goto
        )
      ),

      child.maybe <-- $isAdmin.mapOptWhenTrue(!_) {
        p("Please contact team administrator.")
      }
    )
  }

  def apply(): ReactiveHtmlElement[html.Div] = {
    val tableGridConfigs = $team.map {
      case team if team.role.isAdmin => GridConfig5((13, 5, 6, 5, 3), 32)
      case team if team.role == TeamMemberRole.developer => GridConfig5((16, 5, 6, 5, 0), 32)
      case _ => GridConfig5((16, 16, 0, 0, 0), 32)
    }

    val usage: Var[List[Usage]] = Var(Nil)

    div(
      $team
        .withCurrentValueOf($apps)
        .flatMap {
          case (team, apps) if apps.nonEmpty && team.role.isAdmin => portalApi.appsUsage(team.id)
          case _ => EventStream.empty
        } --> usage.writer,

      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-end slds-m-bottom--xx-large",

        div(
          cls := "slds-grid gap-large",
          ApplicationSearch,
          child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin)
            .andThen {
              ApplicationCreation
            }
        )
      ),

      cls := "data-table",
      AppInfoRowComponent.RegenerateSigningConfirmation(portalApi),

      child <-- $apps.map {
        case Nil => NoAppsView
        case _ =>
          div(
            cls := "table-header",
            span("Name",
              width <-- tableGridConfigs.map(_.width.col_1)),

            child.maybe <-- portalUserAccess.verifiedCheck.andThen(
              span("Client ID",
                width <-- tableGridConfigs.map(_.width.col_2))),

            child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
              .and(portalUserAccess.verifiedCheck)
              .andThen(
                span("Client secret",
                  width <-- tableGridConfigs.map(_.width.col_3))),

            child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
              .and(portalUserAccess.verifiedCheck)
              .andThen(
                span("Signing secret",
                  width <-- tableGridConfigs.map(_.width.col_4))),

            child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin)
              .and(portalUserAccess.verifiedCheck)
              .andThen(
                div(
                  width <-- tableGridConfigs.map(_.width.col_5),
                  span(
                    cls := "slds-m-left--medium",
                    "Usage",
                  ))),
          )
      },

      children <-- $appsToShow.split(_.id) { (_: Int, _: PortalApplication, $app: Signal[PortalApplication]) => {
        val $appKey = $app.map(_.id)
          .combineWith($team.map(_.id))
          .map { case (appId, teamId) => AppKey(teamId, appId) }

        AppInfoRowComponent.AppInfo(
          $appKey,
          $app,
          teamMemberAccess,
          portalUserAccess,
          portalApi,
          clipboardService,
          navigationObserver = portalRouter.goto.some,

          tableGridConfigs = $team.map {
            case team if team.role.isAdmin => GridConfig5((13, 5, 6, 5, 3), 32)
            case team if team.role == TeamMemberRole.developer => GridConfig5((16, 5, 6, 5, 0), 32)
            case _ => GridConfig5((16, 16, 0, 0, 0), 32)
          },
          usage = usage.signal.combineWith($appKey).map {
            case (usages, app) => usages.find(_.id == app.appId)
          }
        )
      }
      },

      child.maybe <-- $apps.combineWith($appsToShow)
        .mapOptWhenTrue { case (allApps, filteredApps) => allApps.nonEmpty && filteredApps.isEmpty } {
          p(
            cls := "slds-grid slds-grid--align-center gray slds-m-top--large",
            "No applications"
          )
        }
    )
  }
}

object AppInfoRowComponent {

  val appToRegenerateSigning: Var[Option[AppKey]] = Var(None)

  def RegenerateSigningConfirmation(portalApi: PortalApi): Dialog.El = {
    val confirmText = Var("")
    Dialog(
      _.open <-- appToRegenerateSigning.signal.map(_.nonEmpty),
      _ => cls := "width--medium",
      _.heading := "Regenerate signing secret",
      _.onClosing.mapTo(None) --> appToRegenerateSigning,
      _ => PingTools.dialogBinders(portalApi),

      _.slots.default(
        p("Updating the app secret will break any existing APIs or integrations using the old secret."),
        p(
          "Enter \"regenerate\" below to confirm you want to regenerate it:",
          cls := "slds-m-vertical--small"
        ),
        Textfield(
          _ => cls := "width-full",
          _.outlined := true,
          _.helper := "Confirm regeneration",
          _.helperPersistent := true,
          _.required := true,
          _ => cls := "slds-col slds-size--9-of-12",
          _.value <-- confirmText,
          _ => onInput.mapToValue --> confirmText
        )
      ),
      _.slots.primaryAction(
        ButtonsPairComponent[Option[String], Option[AppKey]](
          primaryDisabled = confirmText.signal.map(_ != "regenerate"),
          primaryButtonText = "Regenerate",
          secondaryEffect = () => EventStream.fromValue(None),
          secondaryObserver = appToRegenerateSigning.writer,

          primaryEffect = () => appToRegenerateSigning.signal.stream
            .semiflatMap(portalApi.appSigningSecret(_, createNew = true)),

          primaryObserver = appToRegenerateSigning.writer.contramap((_: Option[String]) => None)
        ).node
      )
    )
  }

  def AppInfo($appKey: Signal[AppKey],
              $app: Signal[PortalApplication],
              teamMemberAccess: TeamMemberAccess,
              portalUserAcces: PortalUserAccess,
              portalApi: PortalApi,
              clipboardService: ClipboardService,
              tableGridConfigs: Signal[GridConfig5] = GridConfig5((13, 5, 6, 5, 3), 32).signaled,
              navigationObserver: Option[Observer[Page]] = None,
              withSecretsLabels: Boolean = false,
              usage: Signal[Option[Usage]]
             ): ReactiveHtmlElement[html.Div] = {
    div(
      cls := "table-row border-bottom--light",
      cls.toggle("hoverable")(navigationObserver.nonEmpty),
      div(
        width <-- tableGridConfigs.map(_.width.col_1),

        AppCardComponent($app).node
          .amend(
            cls := "slds-m-right--xxx-large",
            cls.toggle("clickable")(navigationObserver.nonEmpty),

            navigationObserver.map(obs =>

              composeEvents(onClick)(_.sample($appKey).map(DashboardPage(_))) --> obs
            )
          )
      ),

      child.maybe <-- portalUserAcces.verifiedCheck.andThen(
        div(
          width <-- tableGridConfigs.map(_.width.col_2),
          text_data_view.TextView(
              $text = $app.map(_.clientId).getOrFail(UnknownException("Missing app client ID")),
              copyObserver = Observer[String](clipboardService.copy),
              label = Option.when(withSecretsLabels) {
                "Client ID"
              }
            )
            .amend(cls := "slds-m-right--xxx-large")
        )
      ),

      child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
        .and(portalUserAcces.verifiedCheck).andThen(
          div(
            width <-- tableGridConfigs.map(_.width.col_3),
            text_data_view.SecretTextView(
                textF = _ => $appKey.flatMap(portalApi.appClientSecret),
                copyObserver = Observer[String](clipboardService.copy),
                label = Option.when(withSecretsLabels) {
                  "Client secret"
                }
              )
              .amend(cls := "slds-m-right--xxx-large")
          )
        ),

      child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
        .and(portalUserAcces.verifiedCheck).andThen(
          div(
            width <-- tableGridConfigs.map(_.width.col_4),

            text_data_view.SecretTextView(
              textF = _ => {
                $appKey
                  .flatMap(portalApi.appSigningSecret(_))
              },
              copyObserver = Observer[String](clipboardService.copy),
              label = Option.when(withSecretsLabels) {
                "Signing secret"
              }
            ).amend(
              child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin).andThen(IconComponent(
                icon = MaterialIcons.sync,
                color = IconColor.`lighter-brown`,
                iconType = IconType.outlined,
                size = IconSize.small,
              )
                .amend(
                  cls := "clickable",
                  marginBottom := "3px",
                  composeEvents(onClick)(_
                    .sample($appKey.map(_.some))) --> appToRegenerateSigning.writer)

              ))
          )
        ),

      child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin)
        .and(portalUserAcces.verifiedCheck).andThen(
          div(
            width <-- tableGridConfigs.map(_.width.col_5),
            div(
              cls := "slds-m-left--medium",
              Option.when(withSecretsLabels) {
                div(
                  cls := "text-x-small grey slds-m-bottom--small",
                  "Usage"
                )
              },
              child <-- usage
                .map(usages => {
                  val totalBytes = usages.map(_.bytes).sum
                  val appUsageTb = totalBytes / byteToTb
                  val appUsageGb = totalBytes / byteToGb
                  val appUsageMb = totalBytes / byteToMb
                  val appUsageKb = totalBytes / byteToKb

                  if (appUsageTb >= 1) {
                    p(f"$appUsageTb%.1f TB/month")
                  } else if (appUsageGb >= 1) {
                    p(f"$appUsageGb%.1f GB/month")
                  } else if (appUsageMb >= 1) {
                    p(f"$appUsageMb%.1f MB/month")
                  } else if (appUsageKb > 0) {
                    p(f"$appUsageKb%.1f KB/month")
                  } else {
                    p("0 KB/month")
                  }
                }
                )
            )
          )
        ),
    )
  }
}
