package service.captcha

import cats.implicits.catsSyntaxOptionId
import com.raquo.domtypes.generic.codecs.StringAsIsCodec
import com.raquo.laminar.api.L
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.ValueToObservableOps
import common.ui.FormField
import org.scalajs.dom.html
import wvlet.log.Logger

import scala.scalajs.js
import scala.scalajs.js.annotation.{JSExportTopLevel, JSGlobal, JSGlobalScope}

//@js.native
//@JSGlobalScope
//object JsGlobals extends js.Object {
//  var onCaptchaDataSuccess: js.Function = js.native
//  var onCaptchaDataExpired: js.Function = js.native
//}


@js.native
trait HCaptcha extends js.Object {
  def render(id: String, options: js.Object): Unit = js.native
}


class Captcha(siteKey: String = "40dfff0f-d4c6-4d3e-a4a2-f687ba8b5457") {
  private val log = Logger("Captcha")
  private val scriptLoaded = Var(
    js.typeOf(js.Dynamic.global.hcaptcha) != "undefined" && js.Dynamic.global.hcaptcha != null)


  private def onCaptchaDataSuccess(response: String): Unit = token.set(response.some)
  private def onCaptchaDataExpired(): Unit = token.set(None)
  private def onCaptchaScriptLoaded(): Unit = scriptLoaded.set(true)


  val token: Var[Option[String]] = Var(None)

  def element: ReactiveHtmlElement[html.Div] = {


      div(
        onMountUnmountCallback(_ => token.set(None), _ => token.set(None)),
        scriptLoaded.signal --> Observer[Boolean](loaded => if (loaded) {
          js.Dynamic.global.onCaptchaDataSuccess = params => onCaptchaDataSuccess(params)
          js.Dynamic.global.onCaptchaDataExpired = (_: js.Dynamic) => onCaptchaDataExpired()
          js.Dynamic.global.onCaptchaScriptLoaded = (_: js.Dynamic) => onCaptchaScriptLoaded()

          js.Dynamic.global
            .hcaptcha
            .asInstanceOf[HCaptcha]
            .render("h-captcha", js.Dynamic.literal("sitekey" -> siteKey))
        }),

        idAttr := "h-captcha",
        cls := "h-captcha slds-grid slds-grid--align-center",
        customHtmlAttr("data-sitekey", StringAsIsCodec) := siteKey,
        customHtmlAttr("data-callback", StringAsIsCodec) := "onCaptchaDataSuccess",
        customHtmlAttr("data-expired-callback", StringAsIsCodec) := "onCaptchaDataExpired",
      )
    }


}

