package root_pages.aurinko_pages.app.accounts

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Dialog}
import com.raquo.airstream.eventbus.EventBus
import com.raquo.laminar.api.L._
import common.airstream_ops.ValueToObservableOps
import common.ui.element_binders.DialogModifying
import common.ui.paginator.Paginator
import common._
import org.scalajs.dom
import portal_router.AccountPage
import service.apis.portal_api.PortalApi

case class AccountBookProfile(
                               $route: Signal[AccountPage],
                               portalApi: PortalApi,
                               $bookProfiles: Signal[AurinkoApiPage[BookProfile]]
                             ) {
  val bookProfiles: Var[Option[AurinkoApiPage[BookProfile]]] = Var(None)

  val pageBus: EventBus[(Int, Int)] = new EventBus[(Int, Int)]
  val bookProfilesPageSize: Int = 10


  def requestBookProfiles(appKey: AppKey, accId: Int, pageNum: Int, limit: Int): EventStream[AurinkoApiPage[BookProfile]] = {
    portalApi.getBookProfile(
      appKey,
      accId,
      pageNum,
      limit
    )
  }

  val node: Div = div(
    cls := "slds-m-top_xx-large",
    pageBus.events
      .withCurrentValueOf($route)
      .flatMap {
        case (pageNum, limit, route) =>
          requestBookProfiles(route.appKey, route.accId, pageNum, limit)
      }
      .map(_.some) --> bookProfiles.writer,
    span(cls := "title--level-3 slds-m-top_xx-large slds-m-bottom_medium", "Booking profiles"),

    div(
      cls := "data-table",
      div(
        cls := "table-header slds-m-bottom_xx-small",
        span(cls := "slds-col slds-size--1-of-12 gray", "Id"),
        span(cls := "slds-col slds-size--3-of-12 gray", "Name"),
        span(cls := "slds-col slds-size--4-of-12  gray", "Subject"),
        span(cls := "slds-col slds-size--4-of-12 gray", "Location"),
      ),

      child.maybe <-- bookProfiles.signal
        .map {
          case Some(bookProfiles) if bookProfiles.records.nonEmpty => Some(div(
            bookProfiles.records
              .map(bookProfile => {
                val showDetails: Var[Boolean] = Var(false)

                div(
                  cls := "table-row hoverable slds-is-relative",

                  span(cls := "slds-col slds-size--1-of-12", bookProfile.id),
                  span(cls := "slds-col slds-size--3-of-12  text-bolder", bookProfile.name),
                  span(cls := "slds-col slds-size--4-of-12", bookProfile.subject),
                  span(cls := "slds-col slds-size--4-of-12", bookProfile.location),
                  onMouseOver --> Observer[dom.MouseEvent](_ => {
                    showDetails.set(true)
                  }),
                  onMouseLeave --> Observer[dom.MouseEvent](_ => showDetails.set(false)),
                  child.maybe <-- showDetails.signal.map {
                    case true => Some(div(
                      cls := "slds-is-absolute booking-title slds-grid slds-grid_vertical",
                      span(
                        cls := "text-bolder",
                        "Details"),
                      span(
                        small(cls := "slds-m-right--small", "step:"),
                        small(
                          s"${bookProfile.availabilityStep} min",
                        )
                      ),
                      if (bookProfile.description.nonEmpty) span(
                        small(cls := "slds-m-right--small", "description:"),
                        small(bookProfile.description,
                        )
                      ) else None,
                      span(
                        small(cls := "slds-m-right--small", "duration:"),
                        small(s"${bookProfile.durationMinutes} min")
                      ),
                      if (bookProfile.context.nonEmpty) span(
                        small(cls := "slds-m-right--small", "context: "),
                        small(bookProfile.context)
                      ) else None,

                    ))
                    case _ => None
                  }
                )
              }
              )
          )
          )
          case _ => Some(span(
            cls := "gray slds-m-top--large slds-align_absolute-center",
            "No booking profiles"))
        }
    ),
    child.maybe <-- bookProfiles.signal
      .map {
        case Some(bookProfilesPage) if bookProfilesPage.totalSize >= bookProfilesPageSize =>
          Some(Paginator(
            pageNum = (bookProfilesPage.offset / bookProfilesPageSize).signaled,
            totalCount = Signal.fromValue(bookProfilesPage.totalSize),
            pageSize = Signal.fromValue(bookProfilesPageSize),
            onPageChange = pageBus.writer,
            documentScrollTopAfterPageChange = false,
            None,
            itemsPluralLabel = "Subscriptions",
          ).node)

        case _ => Option.empty
      },
    onMountCallback(_ => pageBus.emit((0, bookProfilesPageSize)))
  )
}
