package root_pages.aurinko_pages.app.accounts

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Fab}
import com.raquo.airstream.eventbus.EventBus
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement.Base
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.{SignalNestedOps, ValueToObservableOps}
import common.ui.mat_components_styles._
import common.ui.notifications.InfoTextComponent
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.ui.text_data_view.ColoredStrings
import common.{AccountFilters, AppKey, AurinkoApiPage, AuthAccountResponse, EmptyFieldException, InstantOps, PortalAccount, TeamMemberRole}
import org.scalajs.dom.html
import portal_router.{AccountPage, AccountsPage, PortalRouter}
import service.apis.portal_api.PortalApi
import service.portal_state.{PortalState, PortalUserAccess, TeamMemberAccess}
import service.scroll_ops.ScrollOps
import wvlet.log.Logger

class AccountsComponent(
                         $route: Signal[AccountsPage],
                         portalApi: PortalApi,
                         documentScrollOps: ScrollOps,
                         portalRouter: PortalRouter,
                         portalState: PortalState,
                         apiOrigin: String
                       ) {

  private val log = Logger.of[AccountsComponent]
  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)
  private val userAccess = new PortalUserAccess(portalState.$me)

  val accounts: Var[AurinkoApiPage[PortalAccount]] = Var(AurinkoApiPage[PortalAccount](Nil, 0, done = true, 0))


  val $pageSize: Signal[Int] = $route.map(_.pageSize.getOrElse(portalApi.standardApiPageSize))
  val $showPagination: Signal[Boolean] = accounts.signal.map(_.totalSize)
    .combineWith($pageSize)
    .map(t => t._1 > t._2)

  val userAccountFilters: AccountFilters = AccountFilters()
  val serviceAccountFilters: AccountFilters = AccountFilters()

  val currentPageNum: Signal[Int] = accounts.signal.map(_.offset)
    .combineWith($pageSize)
    .map(t => t._1 / t._2)

  val daemon: Var[Boolean] = Var(false)

  def currentFilters: AccountFilters = if (daemon.now) serviceAccountFilters else userAccountFilters

  val accountsPageData: Var[AurinkoApiPage[PortalAccount]] =
    Var[AurinkoApiPage[PortalAccount]](AurinkoApiPage[PortalAccount](Nil, 0, done = false, 0))

  def handleQueryParams(page: AccountsPage): Unit = {
    val isDaemon = page.accType.getOrElse("") == "service"
    daemon.set(isDaemon)

    val filters = if (isDaemon) serviceAccountFilters else userAccountFilters

    filters.searchText.set(page.search.getOrElse(""))
    filters.onlyWithErrors.set(page.filter.getOrElse("").contains("hasErrors"))
    filters.hasValidToken.set(Option.when(page.filter.getOrElse("").contains("active")) {
      !page.filter.contains("inactive")
    })

    if (page.accType.getOrElse("") == "both") {
      serviceAccountFilters.searchText.set(page.search.getOrElse(""))
      serviceAccountFilters.onlyWithErrors.set(page.filter.getOrElse("").contains("hasErrors"))
      serviceAccountFilters.hasValidToken.set(Option.when(page.filter.getOrElse("").contains("active")) {
        !page.filter.contains("inactive")
      })
    }
    // TODO: make reloadPageBus with type Unit
    reloadPageBus.emit(page.pageNum.getOrElse(0) -> page.pageSize.getOrElse(portalApi.standardApiPageSize))
  }

  def requestAccounts(pageNum: Int, limit: Int, appKey: AppKey): EventStream[AurinkoApiPage[PortalAccount]] = {
    portalApi.accounts(
      appKey = appKey,
      daemon = daemon.now,
      offset = limit * pageNum,
      limit = limit,
      accountFilters = currentFilters
    )
  }

  def accountBadges($account: EventStream[PortalAccount]): Div = div(
    cls := "slds-grid slds-grid--align-end slds-wrap badge-container",
    child.maybe <-- $account.map(_.active).map {
      case false => Some(small(cls := "slds-col badge orange slds-m-left--xx-small", "Inactive"))
      case true => None
    },
    child.maybe <-- $account.map(_.hasApiErrors).map {
      case Some(v) if v => Some(small(cls := "slds-col badge red slds-m-left--xx-small", "Error"))
      case _ => None
    },
    child.maybe <-- $account.map(_.`type` == "managed").map {
      case true => Some(small(cls := "slds-col badge slds-m-left--xx-small", "Managed"))
      case false => None
    },
    child.maybe <-- $account.map(_.isEndUserAccount.getOrElse(false)).map {
      case true => Some(small(cls := "slds-col badge slds-m-left--xx-small", "End user"))
      case false => None
    },
  )

  def renderAccount(id: Int, initialAccount: PortalAccount, $account: EventStream[PortalAccount]): Anchor =
    if (daemon.now) renderServiceAccount(id, initialAccount, $account)
    else renderUserAccount(id, initialAccount, $account)

  val $serviceTypeRenderers: Signal[ColoredStrings.ColoredStringsSet] =
    portalState.$serviceTypes.map(serviceTypes => ColoredStrings(serviceTypes.map(_.label)))

  def renderUserAccount(id: Int, initialAccount: PortalAccount, $account: EventStream[PortalAccount]): ReactiveHtmlElement[html.Anchor] = {

    val $serviceTypeView = $account
      .withCurrentValueOf($serviceTypeRenderers)
      .map(x => x._2.renderFuncForText(x._1.serviceType.label))

    a(
      cls := "slds-grid table-row clickable primary",

      href <-- $route.map(r => portalRouter.router.absoluteUrlForPage(AccountPage(r.appKey, id))),

      span(cls := "slds-size--1-of-12 text-bolder", child.text <-- $account.map(_.id)),
      span(cls := "slds-size--2-of-12", child <-- $serviceTypeView.map(_.apply())),
      span(cls := "slds-size--3-of-12", child.text <-- $account.map(_.email.getOrElse(""))),
      span(cls := "slds-size--2-of-12 text-bolder", child.text <-- $account.map(_.name.getOrElse(""))),
      span(cls := "slds-size--2-of-12", child.text <-- $account.map(_.createdAt
        .getOrElse(throw EmptyFieldException("account.createdAt")
        ).toPrettyLocalFormat)),
      div(cls := "slds-size--2-of-12", accountBadges($account)),

    )
  }

  def renderServiceAccount(id: Int, initialAccount: PortalAccount, $account: EventStream[PortalAccount]): ReactiveHtmlElement[html.Anchor] = {
    val $serviceTypeView = $account
      .withCurrentValueOf($serviceTypeRenderers)
      .map(x => x._2.renderFuncForText(x._1.serviceType.label))

    a(
      cls := "slds-grid clickable table-row primary",

      href <-- $route.map(r => portalRouter.router.absoluteUrlForPage(AccountPage(r.appKey, id))),

      b(cls := "slds-size--1-of-12", child.text <-- $account.map(_.id)),
      span(cls := "slds-size--2-of-12", child <-- $serviceTypeView.map(_.apply)),
      span(cls := "slds-size--2-of-12 text-bolder", child.text <-- $account.map(_.name.getOrElse(""))),
      span(cls := "slds-size--3-of-12", child.text <-- $account.map(_.clientOrgId.getOrElse(""))),
      span(cls := "slds-size--2-of-12", child.text <-- $account.map(_.createdAt
        .getOrElse(throw EmptyFieldException("account.createdAt")).toPrettyLocalFormat)),
      div(cls := "slds-size--2-of-12", accountBadges($account)),

    )
  }

  val reloadPageBus = new EventBus[(Int, Int)]
  val mountBus = new EventBus[Unit]

  val showAuthModel: Var[Boolean] = Var(false)

  private val searchInputComponent = SearchInputComponent(
    onChange = Observer[String](onNext = v => currentFilters.searchText.set(v)),
  )

  val binders: Seq[Binder[Base]] =
    mountBus.events.sample($route) --> Observer[AccountsPage](onNext = handleQueryParams) ::
      daemon.signal
        .combineWith(daemon.signal.flatMap(_ => currentFilters.searchText.signal))
        .combineWith(daemon.signal.flatMap(_ => currentFilters.onlyWithErrors.signal))
        .combineWith(daemon.signal.flatMap(_ => currentFilters.hasValidToken.signal))
        .withCurrentValueOf($pageSize)
        .changes.map(changes => 0 -> changes._5) --> reloadPageBus.writer ::
      reloadPageBus.events.withCurrentValueOf($route)
        .flatMap { case (pageNum, pageSize, p) =>
          portalRouter.router.replaceState(
            AccountsPage(
              p.appKey,
              filter = Option.when(currentFilters.toFiltersString.nonEmpty) {
                currentFilters.toFiltersString
              },
              search = Option.when(currentFilters.searchText.now.trim.nonEmpty) {
                currentFilters.searchText.now
              },
              accType = Some(if (daemon.now) "service" else "user"),
              pageNum = Some(pageNum),
              pageSize = Some(pageSize)
            ))

          requestAccounts(pageNum, pageSize, p.appKey)
        } --> accounts ::
      daemon.signal.flatMap(_ => currentFilters.searchText.signal) --> searchInputComponent.text.writer ::
      Nil

  val node: Div = div(
    binders,

    cls := "content-padding",

    div(
      cls := "slds-grid slds-grid--align-spread slds-p-bottom--medium border-bottom--light",

      p(
        cls := "au-truncate caption title--level-1",
        "Accounts"),


      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread caption",

        div(
          cls := "slds-grid slds-grid--vertical-align-center",
          searchInputComponent.node,
          div(
            cls := "slds-grid gap--small",
            child.maybe <--
              teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
              .and(userAccess.verifiedCheck)
              .andThen(Button(
                    _.outlined := true,
                    _ => cls := "slds-m-left--large blue",
                    _.label <-- daemon.signal.map {
                      case true => "Add service"
                      case false => "Add account"
                    },
                    _.icon := "add",
                    _.disabled <-- portalState.$team
                      .map(_.billingInfo.exists(_.hasIssues)),


                    _ => onClick.mapTo(true) --> showAuthModel
                  )
              ),
            child.maybe <-- portalState.$team
            .map {
              case team if !team.role.isAdmin && team.billingInfo.exists(bi => bi.subscriptionRequired || bi.paymentInfo.exists(_.hasDebt)) =>
                "Action denied. Please contact team administrator.".some
              case team if team.billingInfo.exists(bi => bi.subscriptionRequired) =>
                "Subscription required. Please add payment method on team billing page.".some
              case team if team.billingInfo.exists(_.paymentInfo.exists(_.hasDebt)) =>
                "Payment required.".some
              case _ => None
            }
            .nestedMap(text => InfoTextComponent.standard(text.signaled))
        )
        )
      )
    ),
    div(
      cls := "slds-grid slds-p-top--x-large",
      div(
        cls := "vertical-menu--container slds-size--1-of-6",
        ul(
          cls := "vertical-menu slds-m-top--medium",

          li(
            "User",
            cls := "menu-item menu-left-item",
            cls <-- daemon.signal.map { case false => "active" case _ => "" },
            onClick.mapTo(false) --> daemon,
          ),

          li(
            "Service",
            cls := "menu-item menu-left-item",
            cls <-- daemon.signal.map { case true => "active" case _ => "" },
            onClick.mapTo(true) --> daemon,
          )
        )
      ),
      div(
        cls := "growing-block slds-m-left--x-large",
        div(
          cls := "slds-grid",
          Fab(
            _.label := "Active",
            _.extended := true,
            _.showIconAtEnd := true,
            _.icon <-- daemon.signal.flatMap(_ => currentFilters.hasValidToken.signal).map { case Some(v) if v => "cancel" case _ => "" },
            _ => cls := "slds-m-right--x-small",
            _ => cls <-- daemon.signal.flatMap(_ => currentFilters.hasValidToken.signal).map { case Some(v) if v => "" case _ => "inactive" },
            _ => onClick.mapTo(currentFilters) --> Observer[AccountFilters](onNext = _.hasValidToken.update {
              case Some(v) if v => None
              case _ => Some(true)
            }),
            _ => onMountCallback(fixFabStyle)
          ),
          Fab(
            _.label := "Inactive",
            _.extended := true,
            _.showIconAtEnd := true,
            _.icon <-- daemon.signal.flatMap(_ => currentFilters.hasValidToken.signal).map { case Some(v) if !v => "cancel" case _ => "" },
            _ => cls := "slds-m-right--x-small",
            _ => cls <-- daemon.signal.flatMap(_ => currentFilters.hasValidToken.signal).map { case Some(v) if !v => "" case _ => "inactive" },
            _ => onClick.mapTo(currentFilters) --> Observer[AccountFilters](onNext = _.hasValidToken.update {
              case Some(v) if !v => None
              case _ => Some(false)
            }),
            _ => onMountCallback(fixFabStyle)
          ),
          Fab(
            _.label := "Has issues",
            _.extended := true,
            _.showIconAtEnd := true,
            _.icon <-- daemon.signal.flatMap(_ => currentFilters.onlyWithErrors.signal).map { case true => "cancel" case _ => "" },
            _ => cls := "slds-m-right--x-small",
            _ => cls <-- daemon.signal.flatMap(_ => currentFilters.onlyWithErrors.signal).map { case true => "" case _ => "inactive" },
            _ => onClick.mapTo(currentFilters) --> Observer[AccountFilters](onNext = _.onlyWithErrors.update(v => !v)),
            _ => onMountCallback(fixFabStyle)
          )
        ),

        div(
          cls := "data-table slds-m-top--large",
          cls <-- $showPagination.map { case true => "border-bottom--secondary" case false => "" },

          child <-- daemon.signal.map {
            case false => div(
              cls := "slds-grid table-header",
              //              cls <-- accounts.signal.map(_.totalSize > 0).map { case true => "" case false => "hidden" },

              span(cls := "slds-size--1-of-12", "Id"),
              span(cls := "slds-size--2-of-12", "Service type"),
              span(cls := "slds-size--3-of-12", "Email"),
              span(cls := "slds-size--2-of-12", "Name"),
              span(cls := "slds-size--2-of-12", "Created at"),
            )
            case true => div(
              cls := "slds-grid",
              cls <-- accounts.signal.map(_.totalSize > 0).map { case true => "" case false => "hidden" },

              span(cls := "slds-size--1-of-12", "Id"),
              span(cls := "slds-size--2-of-12", "Service type"),
              span(cls := "slds-size--2-of-12", "Name"),
              span(cls := "slds-size--3-of-12", "Client org id"),
              span(cls := "slds-size--2-of-12", "Created at"),
            )
          },
          children <-- accounts.signal.changes
            .map(_.records)
            .split(_.id)(renderAccount),

          div(
            cls <-- accounts.signal.map(_.totalSize == 0).map { case true => "" case false => "hidden" },
            p(
              cls := "slds-grid slds-grid--align-center gray slds-m-top--large",
              span("No accounts", cls := "gray"),
            )
          )
        ),

        div(
          cls <-- $showPagination.map { case true => "" case false => "hidden" },
          Paginator(
            pageNum = currentPageNum,
            totalCount = accounts.signal.map(_.totalSize),
            pageSize = $pageSize,
            onPageChange = Observer[(Int, Int)](onNext = num => {
              reloadPageBus.emit(num._1 -> num._2)
            }),
            documentScrollTopAfterPageChange = true,
            documentScrollOps.some,
            itemsPluralLabel = "Accounts",
          ).node
        ),


        child.maybe <-- teamMemberAccess
          .minRoleCheck(TeamMemberRole.developer)
          .and(userAccess.verifiedCheck)
          .sAndThen(
            $route.map(route =>

              AccountAuthorization(
                appKey = route.appKey,
                daemonMode = daemon.signal,
                visible = showAuthModel,
                portalApi = portalApi,
                portalState = portalState,
                onSuccess = Observer[AuthAccountResponse](_.accountId
                  .foreach(id => portalRouter.navigate(AccountPage(route.appKey, id)))
                ),
                documentScrollOps = documentScrollOps,
                apiOrigin = apiOrigin
              ).node
            ))
      )
    ),


    onMountCallback(_ => mountBus.emit(()))
  )
}
