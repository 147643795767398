package common

import common.BillingModels.{BillingAddress, BillingInvoice, CountryInfo}
import common.BillingModels.BillingInvoiceStatus.BillingInvoiceStatus
import common.BillingModels.BillingType.BillingType
import common.ui.auto_suggest.SuggestItem

import java.time.Instant

object BillingModels {

  case class BillingInfo(
                          subscriptionRequired: Boolean,
                          trialDaysLeft: Int,
                          paymentMethodConfigured: Boolean,
                          paymentInfo: Option[PaymentInfo],
                          paymentEnabled: Boolean
                        ) {
    val hasIssues: Boolean = subscriptionRequired || paymentInfo.exists(_.hasDebt)
    val needsNotification: Boolean = hasIssues || paymentEnabled && !paymentMethodConfigured && trialDaysLeft  <= 7
  }

  case class PaymentInfo(
                          payDate: Option[String],// todo: local date
                          hasDebt: Boolean
                        )

  case class PaymentMethod(
                            stripePk: String,
                            card: Option[BillingCard] = None,
                            billingDetails: Option[BillingDetails] = None,
                            couponId: Option[String] = None,
                            couponDescription: Option[String] = None
                          )

  case class BillingCard(
                             number: Option[String] = None,
                             expMonth: Int,
                             expYear: Int,
                             cvc: Option[Int] = None,
                             last4: Option[String] = None
                           )

  case class BillingDetails(
                                address: Option[BillingAddress],
                                email: Option[String],
                                name: Option[String],
//                                phone: Option[String]
                              )


  case class PaymentMethodUpdate(
                                  paymentMethodId: String,
                                  couponId: Option[String] = None,
                                  couponDescription: Option[String] = None,
                                )

  case class BillingAddress(
                                city: Option[String],
                                country: Option[String], // Two-letter country code https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
                                line1: Option[String],
                                line2: Option[String],
                                postal_code: Option[String],
                                state: Option[String]
                              )

  case class BillingPlan(
                             id: Long,
                             name: String,
                             billingType: String,
                             description: Option[String],
                             tiers: List[BillingPlanTier]
                           )

  case class BillingPlanTier(
                                 name: String,
                                 description: Option[String],
                                 price: Int,
                                 maxUsage: Option[Long],
                                 maxFeatures: Option[Int],
                                 allowedFeatures: Option[List[String]]
                               ) {
    def priceD: Double = price.toDouble / 100
  }

  case class BillingSubscription(
                                     id: Long = 0,
                                     name: String,
                                     billingType: BillingType,
                                     description: Option[String],
                                     isDefault: Boolean,
                                     subscribedAt: String,
                                     couponId: Option[String],
                                     tiers: List[BillingSubscriptionTier]
                                   )

  case class BillingSubscriptionTier(
                                         name: String,
                                         description: Option[String],
                                         price: Int,
                                         usageLimit: Option[Long]
                                       )

  object BillingType extends Enumeration with JsonEnum {
    type BillingType = Value
    type EnumValue = Value

    val manual, accountBased = Value
  }

  case class BillingInvoice(id: Option[String],
                            amountDue: Long,
                            amountPaid: Long,
                            amountRemaining: Long,
                            attemptCount: Int,
                            discount: Option[Double],
                            attempted: Boolean,
                            created: Instant,
                            periodStart: Instant,
                            periodEnd: Instant,
                            currency: String,
                            status: BillingInvoiceStatus,
                            details: List[BillingInvoiceAppDetail],
                            number: Option[String],
                            hostedUrl: Option[String]
                           ) {
    def getId: String = id match {
      case Some(str) if str.trim.nonEmpty => str
      case _ => "upcoming"
    }

    val units: Long = details.map(_.units).sum

    val amount: Double = amountDue.toDouble / 100
  }

  case class BillingInvoiceAppDetail(
                                      appId: Long,
                                      amount: Long,
                                      tiers: List[BillingInvoiceTierDetail],
                                      units: Long
                                    ) {
    val amountD: Double = amount.toDouble / 100
  }

  object BillingInvoiceStatus extends Enumeration with JsonEnum {

    type BillingInvoiceStatus = Value
    type EnumValue = Value

    val upcoming, paid, unpaid = Value
  }

  case class BillingInvoiceTierDetail(
                                       tierId: Option[Long],
                                       tierName: Option[String],
                                       amount: Long,
                                       units: Long
                                     ) {
    val amountD: Double = amount.toDouble / 100
  }


  case class BillingApiMany[T](nextPageToken: Option[String] = None,
                               nextDeltaToken: Option[String] = None,
                               length: Int,
                               records: Seq[T])

  case class CountryInfo(country: String, code: String) extends SuggestItem {
//    override def toString: String = country

    override val label: String = country
    override val value: String = code
  }

}

object BillingModelsOps {

  def addressStringFromPaymentInfo(address: BillingAddress, countries: List[CountryInfo]): String =
    (address.line1 ::
      address.line2 ::
      address.city ::
      address.state ::
      address.postal_code ::
      address.country.flatMap(c => countries.find(_.code == c)).map(_.country) ::
      Nil)
      .flatten
      .filter(_.nonEmpty)
      .mkString(", ")

  def labelForCurrency(str: String): String = str.toLowerCase match {
    case "usd" => "$"
    case "eur" => "€"
    case other => other
  }

  def invoicePeriodString(invoice: BillingInvoice): String = invoice.periodStart.toShortDateFormat + " - " + invoice.periodEnd.toDateFormat

}

