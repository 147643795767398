package root_pages.aurinko_pages

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Dialog, Icon, Textfield}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ui.notifications.LocalMessagesView
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.{FormModel, InstantOps, PortalUser, PortalUserEditModel, nameFieldPattern}
import common.ui.{AuFormField, AuFormState}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.mat_components_styles.fixMwcDialogOverflow
import common.forms.{FormsLocalExceptionHandling, TextfieldFormOps}
import root_pages.auth_pages.ApiResponseOpps
import common.ui.secret_input.SecretInputComponent
import org.scalajs.dom
import common.ui.element_binders.DialogModifying
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import org.scalajs.dom.html
import wvlet.log.Logger

package object profile_dialog {
  class ProfileComponent(
                          portalApi: PortalApi,
                          portalState: PortalState) {
    private val log = Logger.of[ProfileComponent]

    val errorMessage: Var[Option[String]] = Var(None)
    val errorType: Var[Option[String]] = Var(None)
    val editModel: Var[Option[PortalUserEditModel]] = Var(None)

    val showProfileDialog: Var[Boolean] = Var(false)

    def externalOpenDialog: () => Unit = () => showProfileDialog.set(true)

    def editPopup(portalUser: PortalUser): Dialog.El =
      Dialog(
      _.open <-- editModel.signal.map(_.isDefined),
      _.onClosing.mapTo(None) --> editModel,
      _.heading := "Edit profile",
      _ => child.maybe <-- editModel.signal.nestedMap { model =>
        div(
          div(
            FormsLocalExceptionHandling.errorView(model.formError.signal),
            Textfield(
              _ => cls := "width-medium slds-m-bottom--large",
              _.outlined := true,
              _.label := "Name",
              _.pattern := nameFieldPattern,
              _.value <-- model.name,
              _ => onInput.mapToValue --> model.name,
              _.required := true,
            ).bindToForm(model.formState),
          ),
          div(
            Textfield(
              _.outlined := true,
              _ => cls := "width-medium",
              _.label := "Email",
              _.`type` := "email",
              _.value <-- model.email,
              _ => onInput.mapToValue --> model.email,
              _.required := true
            ).bindToForm(model.formState),
          )
        )
      },
      _.slots.primaryAction(
        ButtonsPairComponent[PortalUser, dom.MouseEvent](
          primaryDisabled = editModel.signal
            .flatMap(_.$traverse(_.formState.$submitAllowed))
            .map(!_.contains(true)),

          primaryEffect = () => ()
            .streamed
            .sample(editModel.signal)
            .collect { case Some(model) => model }
            .flatMap(model => portalApi.updateMe(model.name.now, model.email.now)
            ).flatMap(_ => portalApi.getMe)
            .withErrorHandlingAndCollect(
              FormsLocalExceptionHandling
                .handler(str => editModel.now.foreach(_.formError.set(str.some)))),

          secondaryObserver = editModel.writer.contramap((_: dom.MouseEvent) => None),

          primaryObserver = Observer[PortalUser](onNext = me => {
            portalState.updateMe(me)
            editModel.set(None)
          }),
        ).node,
      ),
      _ => onMountCallback(fixMwcDialogOverflow),
    )
      .withPing(portalApi)
      .withFormCaching(
        portalState.FormCache,
        portalState.Session.$sessionExpiredEvents,
        editModel,
        portalUser.toEditModel.signaled,
        (m: PortalUserEditModel) => {
          editModel.now.foreach(_.update(m))
        },
        (cashedForm: FormModel) => cashedForm match {
          case user: PortalUserEditModel => user.some
          case _ => None
        },
        () => editModel.now.foreach(_.formState.validate())
      )

    private val showResetPasswordPopup: Var[Boolean] = Var(false)

    val resetPasswordPopup = {
      child.maybe <-- showResetPasswordPopup.signal.map {
        case false => None

        case true => val oldPassword = SecretInputComponent(
          label = "Old password"
        )

          val newPassword = SecretInputComponent(
            label = "New password",
            newPassword = true
          )
          val form = AuFormState(AuFormField(oldPassword.inputNode) :: AuFormField(newPassword.inputNode) :: Nil)

          Some(Dialog(
            _ => cls := "reset-password",
            _.open <-- showResetPasswordPopup.signal,
            _ => onMountCallback(fixMwcDialogOverflow),
            _.onOpened --> Observer[dom.Event](onNext = _ => form.validate()),
            _.onClosing.mapTo(false) --> showResetPasswordPopup.writer,
            _.onClosing --> Observer[dom.Event](onNext = _ => {
              form.reset()
            }),

            _.slots.default(div(
              p("Reset password", cls := "title--level-2 slds-m-bottom_small"),
              LocalMessagesView(errorMessage.signal)
            )),

            _.slots.default(
              div(
                cls := "slds-p-bottom--large",
                oldPassword.node
              ),
              div(
                newPassword.node
              )
            ),
            _.slots.primaryAction(

              ButtonsPairComponent[Boolean, dom.MouseEvent](

                secondaryEffect = () => {
                  form.reset()
                  EventStream.empty
                },

                primaryDisabled = form.validSignal
                  .combineWith(form.dirtySignal)
                  .map(t => !t._1 || !t._2),

                primaryEffect = () => {
                  portalApi.updatePassword(newPassword.value.now, oldPassword.value.now)
                    .validateWithLocalError(errorMessage)
                    .mapTo(false)
                },

                primaryObserver = showResetPasswordPopup.writer,

                secondaryObserver = showResetPasswordPopup.writer.contramap((_: dom.MouseEvent) => false),
              ).node,
            )
          ).withPing(portalApi)
            .closeOnSessionExpired(portalState.Session.$sessionExpiredEvents))

      }
    }

    //    val showDeletePopup: Var[Boolean] = Var(false)
    //    val deleteAccountPopup: Dialog.El = {
    //      Dialog(
    //        _ => onMountCallback(fixMwcDialogOverflow),
    //        _.open <-- showDeletePopup.signal,
    //        _.onClosing.mapTo(false) --> showDeletePopup.writer,
    //        _.heading := "Delete account",
    //        // _ => PingTools.dialogBinders(portalApi),
    //        _.slots.default(
    //          div(
    //            p("Please confirm that you would like to delete your Aurinko account by clicking the button below:")
    //          )
    //        ),
    //        _.slots.primaryAction(
    //          ButtonsPairComponent[Unit, dom.MouseEvent](
    //            primaryButtonText = "yes, delete my account",
    //            primaryCls = "red",
    //            primaryEffect = () => portalApi.deleteMe(),
    //            primaryObserver = Observer[Unit](onNext = _ => portalRouter.pushState(SignUpPage)),
    //            secondaryObserver = showDeletePopup.writer.contramap((_: dom.MouseEvent) => false)
    //          ).node
    //        )
    //      )
    //    }

    val profileDialog: ReactiveHtmlElement[html.Div] = {

      div(
        portalState.$me.map { _ =>
          portalState.FormCache.model.exists {
            case _: PortalUserEditModel => true
            case _ => false
          }
        } --> showProfileDialog,

        div(
          Dialog(
            _ => cls := "width-small",
            _.open <-- showProfileDialog.signal,
            _.onClosing.mapTo(false) --> showProfileDialog,
            _.slots.default(
              div(
                cls := "border-bottom--light slds-m-bottom_medium",

                p("Profile", cls := "title--level-1  slds-p-bottom_medium"),

                LocalMessagesView(errorMessage.signal, errorType.signal),

                child.maybe <-- portalState.$me.map(_.verified).map {
                  case false => Some(div(
                    cls := "section-warning slds-grid slds-m-vertical_xx-small",
                    Icon(_ => "feedback", _ => cls := "small slds-m-right--small"),
                    div(
                      p(
                        cls := "slds-m-bottom--xx-small",
                        "Verify your email. We sent an email to ",
                        child.text <-- portalState.$me.map(_.email),
                        "."
                      ),
                      div(
                        cls := "blue clickable",
                        "Resend email",
                        composeEvents(onClick)(_.flatMap(_ => portalApi.startEmailVerification())) -->
                          Observer[Unit](onNext = _ => {
                            errorMessage.set(Some("Please check your mailbox."))
                            errorType.set(Some("message"))
                          }
                          ),
                      )
                    )
                  ))
                  case true => None
                },
              )),
            _.slots.default(
              div(
                div(
                  cls := "slds-grid slds-grid--vertical",
                  marginBottom := "1.5rem",
                  small(cls := "gray", "Name"),
                  span(child.text <-- portalState.$me.map(_.name)),
                ),
                div(
                  cls := "slds-grid slds-grid--vertical",
                  marginBottom := "1.5rem",
                  small(cls := "gray", "Email"),
                  span(child.text <-- portalState.$me.map(_.email)),

                ),

                Button(
                  _ => cls := "slds-size--1-of-1 slds-m-bottom--x-small blue",
                  _ => marginBottom := "1.5rem",
                  _.label := "Edit profile",
                  _.outlined := true,
                  _ => composeEvents(onClick)(_
                    .sample(portalState.$me)
                    .map(_.toEditModel)
                    .map(_.some)) --> editModel
                ),

                div(
                  cls := "slds-grid slds-box",
                  marginBottom := "1.5rem",
                  div(
                    cls := "slds-col slds-grid slds-grid--vertical",
                    small(cls := "gray", "Registered at"),
                    span(child.text <-- portalState.$me.map(_.registeredAt.toPrettyLocalFormat)),
                  ),

                  child.maybe <-- portalState.$me.map(_.lastLoginAt).map {
                    case None => None
                    case Some(date) => Some(div(
                      cls := "slds-col slds-grid slds-grid--vertical",
                      small(cls := "gray", "Last login at"),
                      span(date.toPrettyLocalFormat),
                    ))
                  },
                ),
                Button(
                  _ => cls := "slds-size--1-of-1 blue",
                  _.label := "Reset password",
                  _.outlined := true,
                  _ => onClick.mapTo(true) --> showResetPasswordPopup.writer
                ),
              )
            ),
            _.slots.secondaryAction(
              Button(
                _.label := "Close",
                _.outlined := true,
                _ => cls := "slds-m-left--small secondary",
                _ => onClick.mapTo(false) --> showProfileDialog,
              )
            )
          )
            .withPing(portalApi)
            .closeOnSessionExpired(portalState.Session.$sessionExpiredEvents),

          child <-- portalState.$me.map(editPopup),
          resetPasswordPopup
        )
      )
    }
  }
}





