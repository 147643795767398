package service.exception_handler

import com.raquo.airstream.core.AirstreamError.{CombinedError, ObserverError}
import com.raquo.airstream.core.Observer
import org.scalajs.dom
import portal_router.{BufferPage, PortalRouter, TeamAppsPage}
import service.apis.dynamic_api.DataMapperApi
import service.apis.sync_api.SyncApiException
import service.portal_state.UserAuthInfo
import service.ui.messages.Messages
import wvlet.log.Logger

class ExceptionHandler(messagesService: Messages,
                       portalRouter: PortalRouter,
                       onAuthExpired: () => Unit,
                       resetState: () => Unit
                      ) {

  private val log = Logger.of[ExceptionHandler]

  def handleAirstreamException(err: Throwable, userAuthInfoService: UserAuthInfo): Unit = {

    err match {

      case e: SilentException =>
        log.warn(s"ignore ${e.getMessage}")

      case e: ApiException => messagesService.addMessage(s"${e.message}")

      case e: ApiNotFoundException => messagesService.addMessage(s"${e.message}")

      case e: SyncApiException => messagesService.addMessage(s"Sync api exception: ${e.message}")

      case e: DataMapperApi.ApiException => messagesService.addMessage(s"Dynamic api exception: ${e.message}")

      case e:  NotAuthorizedSilentException =>
        userAuthInfoService.setUsername(e.username)
        portalRouter.navigate(portal_router.LoginPage(redirect = Some(dom.window.location.href)))

      case e: NotAuthorizedException =>
        onAuthExpired()
        messagesService
          .addReauthenticate(Observer[Unit](_ => {
            userAuthInfoService.setUsername(e.username)
            portalRouter.navigate(portal_router.LoginPage(redirect = Some(dom.window.location.href)))
            resetState()
          }))

      case _: ForbiddenError =>

        messagesService.addAccessDeniedMessage(Observer.empty)

      case e: CombinedError => e.causes
        .collect { case Some(v) => v }.foreach(e => handleAirstreamException(e, userAuthInfoService))

      case e: XhrFailedException => messagesService.addMessage(e.toString)

      case e: ObserverError => handleAirstreamException(e.error, userAuthInfoService)

      case _ => messagesService.addMessage(err.getMessage)
    }
  }

  def handleDomException(err: dom.ErrorEvent): Unit = messagesService.addMessage(s"JsException ${err.message}")
}

object LocalExceptionHandler {

  private val log = Logger.of[LocalExceptionHandler.type]

  def handleAirstreamException(err: Throwable, showMessage: String => Unit): Unit = {

    err match {

      case e: SilentException =>
        log.warn(s"ignore ${e.getMessage}")

      case e: ApiException => showMessage(s"${e.message}")

      case e: ApiNotFoundException => showMessage(s"${e.message}")

      case e: SyncApiException => showMessage(s"Sync api exception: ${e.message}")

      case e: DataMapperApi.ApiException => showMessage(s"Dynamic api exception: ${e.message}")


      case _: NotAuthorizedException | _: NotAuthorizedSilentException => throw err

      case _: ForbiddenError => showMessage(s"You do not have the appropriate permissions.")

      case e: CombinedError => e.causes
        .collect { case Some(v) => v }.foreach(e => handleAirstreamException(e, showMessage))

      case e: XhrFailedException => showMessage(e.toString)

      case _ => showMessage(err.getMessage)
    }
  }

  def handleDomException(err: dom.ErrorEvent, showMessage: String => Unit): Unit = showMessage(s"JsException ${err.message}")
}
