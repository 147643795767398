package common.ui.au_storage_view

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Dialog, Icon, Textarea}
import com.raquo.laminar.api.L._
import common.{AppKey, InstantOps, Storage}
import common.StoreType.StoreType
import common.airstream_ops.ValueToObservableOps
import common.ui.{AuFormState, AuFormTextArea}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.ui.element_binders.DialogModifying
import org.scalajs.dom
import service.apis.portal_api.PortalApi
import service.portal_state.AccessCheck

case class StorageComponent(
                             appKey: AppKey,
                             instId: String,
                             storeType: StoreType,
                             portalApi: PortalApi,
                             $sessionExpiredEvents: EventStream[Unit],
                             editAccessCheck: AccessCheck
                           ) {
  private val pageSize: Var[Int] = Var(15)
  private val $pageSize: Signal[Int] = pageSize.signal
  private val searchText = Var("")

  private val expandedPropertyId: Var[Option[String]] = Var(None)
  private val reloadPageBus = new EventBus[(Int, Int)]

  private def requestStorage(pageNum: Int, limit: Int): EventStream[Storage] = portalApi.storage(
    appKey = appKey,
    storeType = storeType,
    instId = Some(instId),
    search = searchText.now,
    limit = limit,
    offset = limit * pageNum,
  )

  private val storageVar: Var[Storage] = Var(Storage(totalSize = 0, data = Map.empty, offset = 0))
  val nonEmpty: Signal[Boolean] = storageVar.signal.map(_.totalSize > 0)
  val storage: Signal[Storage] = storageVar.signal

  private val currentPageNum: Signal[Int] = storageVar.signal.map(_.offset)
    .combineWith($pageSize)
    .map(p => p._1 / p._2)

  private val showNewPropertyPanel = Var(false)

  private def renderProperty(id: String, initial: (String, String), $property: Signal[(String, String)]): Div = {
    val keyInput = AuFormTextArea(Textarea(
      _ => cls := "slds-size--1-of-1",
      _.outlined := true,
      _.label := "Key",
      _.value <-- $property.map(_._1),
      _.rows := 2,
      _.required := true,
    ))

    val valueInput = AuFormTextArea(Textarea(
      _ => cls := "slds-size--1-of-1",
      _.outlined := true,
      _.label := "Value",
      _.value <-- $property.map(_._2),
      _.rows := 2,
      _.required := true,
    ))

    val formState = AuFormState(textAreas = valueInput :: keyInput :: Nil)

    ExpansionPanelComponent(
      header = p(
        cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread growing-block",
        child.text <-- $property.map(v => if (v._1.nonEmpty) v._1 else "New property"),
        child.maybe <-- editAccessCheck.sAndThenOpt( $property.map {
          case v if v._1.nonEmpty => Icon(
            _ => "delete",
            _ => cls := "small clickable light slds-m-right--x-small",
            _ => onClick.stopPropagation.mapTo(v._1.some) --> deletingProperty
          ).some
          case _ => None
        })
      ),
      body = Some(div(
        div(
          cls := "slds-m-bottom--x-large",
          keyInput.node
        ),
        div(valueInput.node),

        if (initial._1.isEmpty) {
          EventStream.fromValue(()).delay().mapTo(Some(id)) --> expandedPropertyId
        } else {
          $property.changes --> Observer[(String, String)](onNext = _ => formState.dirtyUpdate())
        }
      )),
      footer = Some(
        ButtonsPairComponent[(Int, Int), dom.MouseEvent](
          disabled = if (initial._1.nonEmpty) formState.dirtySignal.map(!_) else Signal.fromValue(false),
          primaryDisabled = formState.validSignal.map(!_),

          secondaryObserver = Observer[dom.MouseEvent](onNext = _ => {
            formState.reset()
            expandedPropertyId.set(None)
          }),

          primaryEffect = () => EventStream.fromValue(())
            .withCurrentValueOf(Signal.fromValue(initial._1))
            .flatMap(key =>
              for {
                _ <- if (key != keyInput.getValue) {
                  portalApi.deleteStorageMapping(appKey = appKey,
                    storeType = storeType,
                    instId = Some(instId),
                    key = initial._1
                  )
                } else ().streamed
                _ <- portalApi.updateStorageValue(
                  appKey = appKey,
                  storeType = storeType,
                  instId = Some(instId),
                  key = keyInput.getValue,
                  newValue = valueInput.getValue
                )
              } yield ()
            )
            .sample(currentPageNum)
            .withCurrentValueOf($pageSize),

          primaryObserver = Observer[(Int, Int)](onNext = n => {
            reloadPageBus.emit(n._1 -> n._2)
            expandedPropertyId.set(None)
          }
          )).node
      ),

      expanded = expandedPropertyId.signal.map(t => t.isDefined && t.get == id),
      horizontalPadding = false,

      onExpansionChange = Observer[Boolean](onNext = {
        case true => expandedPropertyId.set(Some(id))
        case false => if (initial._1.isEmpty) showNewPropertyPanel.set(false)
      }),

      expandable = editAccessCheck.allowed
    ).node
  }

  private val deletingProperty: Var[Option[String]] = Var(None)

  private val deletePopup: Dialog.El = Dialog(
    _.open <-- deletingProperty.signal.map(_.isDefined),
    _.onClosed.mapTo(None) --> deletingProperty,
    _.heading := "Delete property",
    _.slots.default(
      p("This action can not be undone!")
    ),

    _.slots.primaryAction(
      ButtonsPairComponent[(Int, Int), Option[String]](
        primaryButtonText = "Delete",
        primaryCls = "red",
        secondaryEffect = () => EventStream.fromValue(None),
        secondaryObserver = deletingProperty.writer,
        primaryObserver = Observer[(Int, Int)](onNext = n => {
          reloadPageBus.emit(n._1 -> n._2)
          deletingProperty.set(None)
        }),
        primaryEffect = () => EventStream.fromValue(())
          .sample(deletingProperty.signal)
          .flatMap(pr => portalApi.deleteStorageMapping(
            appKey,
            storeType,
            Some(instId),
            pr.getOrElse(throw new Exception("no property to delete"))
          )
          )
          .sample(currentPageNum).withCurrentValueOf($pageSize)
      ).node
    )
  )
    .withPing(portalApi)
    .closeOnSessionExpired($sessionExpiredEvents)

  val node: Div = div(
    searchText.signal.changes.mapTo(0).withCurrentValueOf($pageSize) --> reloadPageBus.writer,

    reloadPageBus.events.flatMap(p => requestStorage(p._1, p._2)) --> storageVar,

    div(
      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread border-bottom--light slds-p-bottom--medium ",
        div(
          cls := "slds-grid slds-grid--vertical",
          p(
            cls := "title--level-3",
            "Properties",
          ),
          span(
            cls := "slds-m-top--xx-small grey text-x-small",
            child.text <-- storageVar.signal.map(_.updatedAt).map {
              case None => ""
              case Some(time) => s"Updated at ${time.toPrettyLocalFormat}"
            }
          )
        ),

        div(
          cls := "slds-grid slds-grid--vertical-align-center",
          SearchInputComponent(onChange = searchText.writer).node,

          child.maybe <-- editAccessCheck.andThen(Button(
            _.outlined := true,
            _ => cls := "slds-m-left--small blue",
            _.label := "New property",
            _.disabled <-- showNewPropertyPanel.signal,
            _.icon := "add",
            _ => onClick.mapTo(true) --> showNewPropertyPanel
          )),
        ),
      ),

      div(
        cls := "data-table slds-m-top--medium",
        div(
          //delay equals animation duration: to prevent page jumping
          child.maybe <-- showNewPropertyPanel.signal.flatMap {
            case true => EventStream.fromValue(Some(renderProperty("newProp", ("", ""), Signal.fromValue("", ""))))
            case false => EventStream.fromValue(()).delay(450).mapTo(None)
          }
        ),
        children <-- storageVar.signal.map(_.data.toList).split(_._1)(renderProperty),
        div(
          cls <-- storageVar.signal.map(_.totalSize == 0).map { case true => "" case false => "hidden" },
          p(
            cls := "slds-grid slds-grid--align-center gray slds-m-top--large",
            span("No properties", cls := "gray"),
          )
        ),

        div(
          cls <-- storageVar.signal.map(_.totalSize <= pageSize.now)
            .map {
              case true => "hidden"
              case false => ""
            },
          Paginator(
            pageNum = currentPageNum,
            totalCount = storageVar.signal.map(_.totalSize),
            pageSize = $pageSize,
            onPageChange = Observer.combine[(Int, Int)](reloadPageBus.writer, pageSize.writer.contramap(_._2)),
            documentScrollTopAfterPageChange = false,
            None,
            itemsPluralLabel = "Items",
          ).node
        )
      )
    ),
    deletePopup,

    onMountCallback(_ => reloadPageBus.emit(0 -> pageSize.now))
  )
}
