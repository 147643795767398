package root_pages.aurinko_pages.app.sync.widgets

import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom.html
import com.raquo.laminar.api.L._

object Flags {
  case class Badge(label: String, cls: String = "")

  private def render(
                      label: String,
                      @annotation.unused initial: Badge,
                      $badge: Signal[Badge],
                    ): HtmlElement =
    small(
      cls <-- $badge.map(b => s"badge slds-m-right--x-small ${b.cls}".trim),
      label,
    )

  def Grid(badgesStream: Signal[List[Badge]]): ReactiveHtmlElement[html.Div] =
    div(
      common.ui.Attribute.Selector := "sync.widgets.flags.grid",

      child.maybe <-- badgesStream.map(_.nonEmpty).map {
        case true => Some(div(
          cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
          small(cls := "gray slds-m-bottom--x-small", "Flags"),
          div(
            cls := "slds-grid",
            children <-- badgesStream.split(b => b.label)(render),
          ),
        ))
        case false => None
      }
    )

  def apply(badgesStream: Signal[List[Badge]]): ReactiveHtmlElement[html.Div] =
    div(
      common.ui.Attribute.Selector := "sync.widgets.flags",
      div(
        cls := "slds-grid slds-wrap",
        children <-- badgesStream.split(b => b.label)(render)
      )

    )
}
