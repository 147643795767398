package root_pages.aurinko_pages.app.virtual_api

import com.raquo.laminar.api.L._
import portal_router._
import service.apis.dynamic_api.DataMapperModels._
import common.InstantOps
import service.apis.dynamic_api.DataMapperApi
import service.portal_state.PortalState
import service.scroll_ops.ScrollOps
import wvlet.log.Logger

case object MetadatasPage {
  private val log = Logger.of[MetadatasPage.type]
  val searchText: Var[String] = Var("")

  def byVirtual(
                 $route: Signal[VirtualAPIsPage_ItemsOfVirtualMetadata],
                 dynamicApi: DataMapperApi.API,
                 documentScrollOps: ScrollOps,
                 portalRouter: PortalRouter,
                 portalState: PortalState,
               ): HtmlElement =
    MetadatasPage(
      $route,
      dynamicApi,
      documentScrollOps,
      portalRouter,
      portalState,
      "Virtual models"
    )

  def byProvider(
                  $route: Signal[VirtualAPIsPage_ItemsOfProviderMetadata],
                  dynamicApi: DataMapperApi.API,
                  documentScrollOps: ScrollOps,
                  portalRouter: PortalRouter,
                  portalState: PortalState,
                ): HtmlElement =
    MetadatasPage(
      $route,
      dynamicApi,
      documentScrollOps,
      portalRouter,
      portalState,
      "Provider models"
    )

  def apply(
             $page: Signal[VirtualAPIsPage],
             dynamicApi: DataMapperApi.API,
             documentScrollOps: ScrollOps,
             portalRouter: PortalRouter,
             portalState: PortalState,
             typeMetadata: String
           ): HtmlElement = {
    val $isProviderPage: Signal[Boolean] = $page.map(_.isInstanceOf[VirtualAPIsPage_ItemsOfProviderMetadata])
    val $isVirtualPage: Signal[Boolean] = $page.map(_.isInstanceOf[VirtualAPIsPage_ItemsOfVirtualMetadata])

    val onCreateMetadata: EventBus[MetadataModels.Metadata] = new EventBus[MetadataModels.Metadata]
    val metadataDialog = new components.dialogs.metadata.Metadata(
      portalState,
      onCreateMetadata.writer.contramap((x: (String, MetadataModels.Metadata)) => x._2),
      `type` = $isVirtualPage
        .map {
          case true => MetadataModels.MetadataType.client
          case _ => MetadataModels.MetadataType.provider
        }
        .map(Some(_))
    )

    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_list_page",
      common.ui.Attribute.Description := typeMetadata,

      onCreateMetadata.events
        .flatMap(m => helpers.cache.metadata.SaveModel($page.map(_.appKey), dynamicApi, m).map(m => m.id.getOrElse[Long](0)))
        .withCurrentValueOf($page)
        .map(x => VirtualAPIsPage_CustomMetadata(x._2.appKey, x._1, configurationId = None))
        --> portalRouter.goto,
      metadataDialog.node,
      components.BoardComponent(
        name = Signal.fromValue("Virtual API"),
        leftContext = components.Menu(
          $page,
          Signal.fromValue(components.Menu.Constants.all),
          portalRouter = portalRouter
        ),
        rightContext = components.TableComponent[MetadataModels.Metadata, String](
          searchText = searchText,
          load = (pageOffset, pageSize, searchText) => helpers.cache.metadata.LoadAllEx(
            $page.map(_.appKey),
            dynamicApi,
            if (searchText.trim.nonEmpty) Some(searchText.trim) else None,
            $isVirtualPage
              .map {
                case true => MetadataModels.MetadataType.client
                case _ => MetadataModels.MetadataType.provider
              }
              .map(Some(_)),
            None,
            pageOffset,
            pageSize,
          ),
          key = row => s"${row.id.getOrElse(0)}-${row.name.getOrElse("")}",
          render = (_, $row) => a(
            cls := "table-row brown clickable",
            span(
              cls <-- $isProviderPage.map {
                case true => "slds-size--5-of-12"
                case _ => "slds-size--8-of-12"
              },
              child.maybe <-- $row.map(_.name).map {
                case Some(v) => Some(span(cls := "virtual-name-item brown", v))
                case _ => None
              },
              common.ui.Attribute.Selector <-- $row.map(_.id.getOrElse(0).toString),
            ),
            child.maybe <-- $isProviderPage
              .map {
                case true => Some(span(
                  cls := "slds-size--3-of-12",
                  span(
                    cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
                    span(cls := "slds-col", child.text <-- $row.map(_.serviceType).map {
                      case Some(v) => v.label
                      case _ => ""
                    }),
                  )
                ))
                case _ => None
              },
            span(cls := "slds-size--2-of-12", child.maybe <-- $row.map(_.updatedAt).map {
              case Some(v) => Some(span(v.toPrettyLocalFormat))
              case _ => None
            }),
            span(cls := "slds-size--2-of-12", child.maybe <-- $row.map(_.createdAt).map {
              case Some(v) => Some(span(v.toPrettyLocalFormat))
              case _ => None
            }),
            href <-- $page
              .withCurrentValueOf($row)
              .map(x => x._2.id
                .map(VirtualAPIsPage_CustomMetadata(x._1.appKey, _, configurationId = None))
                .getOrElse(VirtualAPIsPage_StandardMetadata(x._1.appKey, x._2.name.getOrElse(""), configurationId = None)))
              .map(x => portalRouter.link(x)),
          ),
          header = div(
            cls := "table-header",
            borderBottom := "1px solid #ECECEC",
            div(
              cls := "au-truncate",
              cls <-- $isProviderPage.map {
                case true => "slds-size--5-of-12"
                case _ => "slds-size--8-of-12"
              },
              "Name",
            ),
            child.maybe <-- $isProviderPage
              .map {
                case true => Some(div(cls := "slds-size--3-of-12 au-truncate", "Service type"))
                case _ => None
              },
            div(cls := "slds-size--2-of-12 au-truncate", "Updated at"),
            div(cls := "slds-size--2-of-12 au-truncate", "Created at"),
          ),
          noRows = "No models",
          documentScrollOps = documentScrollOps
        ),
        documentScrollOps = documentScrollOps,
        cssLeftContext = "metadata-page",
        hasSearch = true,
        searchText = searchText,
        buttons = $isVirtualPage
          .map {
            case true => "New virtual metadata"
            case _ => "New provider metadata"
          }
          .map(x => {
            log.info(s"create a ${x.toLowerCase}")
            (x, metadataDialog.writer.contramap((_: Unit) => MetadataModels.Metadata()), false)
          })
          .map(_ :: Nil),
        portalRouter = portalRouter,
      ),
    )
  }
}
