package root_pages.auth_pages.accept_invitation

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Textfield}
import com.raquo.laminar.api.L._
import org.scalajs.dom
import common._
import common.ui.notifications.LocalMessagesView
import common.ui.buttons_pair.ButtonsPairComponent
import portal_router.{AcceptInvitationPage, BufferPage, LoginPage, PortalRouter, TeamAppsPage}
import common.ui.secret_input.SecretInputComponent
import common.value_opps.OptionOpps
import root_pages.auth_pages._
import root_pages.auth_pages.sign_up.SignUpModel
import service.apis.portal_api.PortalApi
import service.captcha.Captcha
import service.exception_handler.UnknownException
import service.portal_state.PortalState

import scala.scalajs.js.timers.setTimeout

class AcceptInvitationComponent($page: Signal[AcceptInvitationPage],
                                portalApi: PortalApi,
                                portalRouter: PortalRouter,
                                portalState: PortalState
                               ) {

  val formValid: Var[Boolean] = Var(false)
  val captcha = new Captcha()

  def validate(): Unit = formValid.set(nameInput.isValid && emailInput.isValid && passwordInput.valid.now && captcha.token.now.nonEmpty)

  val acceptBus = new EventBus[dom.MouseEvent]

  val errorMessage: Var[Option[String]] = Var(None)

  val userData: Var[Option[PortalUser]] = Var(None)

  val completeObserver: Observer[Option[Int]] = Observer[Option[Int]]({
    case Some(team) => portalRouter.navigate(TeamAppsPage(team))
    case _ => portalRouter.navigate(BufferPage)
  })

  def invitationState(invitationKey: String, invitationId: Int): EventStream[(Option[PortalUser], PortalInvitationState)] =
    for {
      state <- portalApi.checkUserState(invitationKey, s"$invitationId")
      result <- if (state.userExists && !state.authorized) {
        for {
          us <- portalApi.getMe
          innerResult <- us match {
            case user if user.email != state.email =>
              userData.set(Some(user))
              EventStream.fromValue((Some(user), state))
            case _ => throw new Exception("Unknown exception")

          }
        } yield innerResult
      } else {
        EventStream.fromValue((None, state))
      }
    } yield result


  val nameInput: Textfield.El = nameTextfield
  val emailInput: Textfield.El = emailTextfield()
  val passwordInput: SecretInputComponent = SecretInputComponent("Password", newPassword = true)

  val node: Div = div(
    child <-- $page.
      flatMap(p => invitationState(p.invKey, p.invId)
        .map((p, _))).map {
        case (route, (user, state)) =>
          div(
            div(
              cls := "slds-align--absolute-center auth-page",
              div(
                cls := "form sign-up",
                AuthPagesLogo,
                div(cls := "slds-p-vertical--medium slds-text-align--center",
                  if (state.userExists && !state.authorized && !user.map(_.email).contains(state.email))
                    cls := "hidden" else "",
                  h2(
                    "Join the team ", state.teamName, cls := ""),

                  LocalMessagesView(errorMessage.signal),
                  p(
                    "Start collaborating with your team today",
                    cls := "slds-p-vertical--xx-small",
                  ),
                ),
                if (state.userExists && !state.authorized && !user.map(_.email).contains(state.email)) {
                  child.maybe <-- userData.signal.map {
                    case Some(user) if user.email != state.email => Some(div(
                      div(
                        cls := "slds-p-vertical--medium slds-m-vertical--xx-large slds-text-align--center",
                        s"Please log into your account associated with the email ${state.email}. You currently logged in as ${user.email}."
                      ),
                      div(
                        cls := "slds-p-top--x-large slds-align--absolute-center",
                        Button(
                          _.raised := true,
                          _ => cls := "secondary",
                          _.label := "Log in",
                          _ => onClick --> Observer[dom.Event](onNext = _ => {
                            portalState.resetState()
                            portalRouter.navigate(LoginPage(
                              redirect = Some(portalRouter.router.absoluteUrlForPage(AcceptInvitationPage(route.invId, s"${route.invKey}")))))
                          })
                        )
                      )
                    ))
                    case _ => None
                  }
                }
                else if (!state.userExists) {
                  div(
                    cls := "slds-grid slds-grid--vertical slds-grid--vertical-align-center",
                    nameInput.amend(value := state.username),
                    emailInput.amend(value := state.email),
                    passwordInput.node,
                    captcha.element.amend(cls := "slds-m-bottom--medium"),

                    div(
                      cls := "slds-p-top--x-large slds-align--absolute-center",
                      Button(
                        _.disabled <-- formValid.signal.map(!_),
                        _.raised := true,
                        _ => cls := "secondary",
                        _.label := "Sign up with email",
                        _ => onClick --> acceptBus.writer,
                        _ => acceptBus.events.flatMap(_ => portalApi.acceptInviteWithSignUp(
                            route.invKey,
                            s"${route.invId}",
                            SignUpModel(
                              emailInput.ref.value,
                              nameInput.ref.value,
                              passwordInput.value.now,
                              captcha.token.now.getOrFail(UnknownException("failed to get hcaptcha token"))
                            )
                          ))
                          .validateWithLocalError(errorMessage)
                          .mapTo(Some(state.teamId)) --> completeObserver
                      )
                    ),
                    p(
                      cls := "slds-p-top--x-large slds-text-align--center mdc-theme--text-secondary-on-background",
                      "By clicking the SIGN UP WITH EMAIL button, you acknowledge that you have read and agree to our ",
                      a("Terms of Use", href := "https://www.aurinko.io/terms-of-services", target := "_blank"),
                      " and have read and acknowledge our ",
                      a("Privacy Policy", href := "https://www.aurinko.io/privacy-policy", target := "_blank"),
                      "."
                    )
                  )
                }
                else {
                  div(
                    cls := "slds-grid slds-grid--align-center",

                    ButtonsPairComponent[Option[Int], Option[Int]](
                      primaryButtonText = "Accept",
                      secondaryButtonText = "Decline",
                      cssClass = "slds-grid--align-center",

                      primaryEffect = () => portalApi.acceptInvite(route.invKey, s"${route.invId}")
                        .mapTo(state.teamId.some)
                        .validateWithLocalError(errorMessage),

                      primaryObserver = completeObserver,

                      secondaryEffect = () => portalApi.declineInvite(route.invKey, s"${route.invId}")
                        .validateWithLocalError(errorMessage)
                        .mapTo(None),

                      secondaryObserver = completeObserver
                    ).node
                  )

                },
                onInput --> Observer[dom.Event](onNext = _ => validate()),
                captcha.token.signal.changes --> Observer[Option[String]](onNext = _ => validate()),
                passwordInput.node.events(onInput) --> Observer[dom.Event](onNext = _ => validate())
              )
            )
          )
      }
  )
}
