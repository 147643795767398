package root_pages.aurinko_pages.app.sync.widgets

import common.{InstantOps, MergeInfo}
import com.raquo.laminar.api.L._

object MergeHistoryGrid {
  def apply(mergeInfoStream: Signal[List[MergeInfo]]): Div =
    div(
      common.ui.Attribute.Selector := "sync.widgets.merge_history_grid",

      child.maybe <-- mergeInfoStream.map(_.nonEmpty).map {
        case true => Some(div(
          cls := "slds-grid slds-grid--vertical",
          p(cls := "gray slds-size--1-of-8 au-truncate slds-m-top--x-large", "Merge history"),
          div(
            cls := "data-table slds-size--7-of-8 slds-m-bottom--medium",
            div(
              cls := "table-header slds-m-top--xx-small",
              span(cls := "slds-size--1-of-3", "Logged at"),
              span(cls := "slds-size--2-of-3", "Summary")
            ),
            children <-- mergeInfoStream.map(
              _.map(mi => div(
                cls := "table-row border-top--light",
                div(cls := "slds-size--1-of-3", mi.mergeTime.toPrettyLocalFormat),
                div(cls := "slds-size--2-of-3", mi.summary),
              ))
            )
          )
        ))
        case false => None
      }
    )
}
