package service.urls

import org.scalajs.dom.Location

class Urls(wl: Location) {
  def wlo: String = wl.origin.getOrElse(throw new Exception("Failed to get window location"))
  def wlHref: String = wl.href
  def wlPathName: String = wl.pathname

  val apiOrigin: String = wlo match {
    case str if str.startsWith("file://") && wlPathName.startsWith("/tmp/") => "http://localhost:9000"
    case str if str.startsWith("file://") || str == "https://spp.aurinko.io" => "https://asti.aurinko.io"
    case _ => "https://api.aurinko.io"
  }
}
