package root_pages.auth_pages.sign_in

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Checkbox, Formfield, Textfield}
import com.raquo.laminar.api.L._
import common._
import common.ui.secret_input.SecretInputComponent
import common.ui.notifications.LocalMessagesView
import org.scalajs.dom
import root_pages.auth_pages._
import portal_router.{BufferPage, Page, PortalRouter, ResetPasswordPage, SignUpPage}
import service.apis.portal_api.PortalApi
import service.exception_handler.{NotAuthorizedException, NotAuthorizedSilentException}
import service.portal_state.UserAuthInfo
import wvlet.log.Logger

import scala.util.{Failure, Success}

class LoginComponent($redirect: Signal[Option[Page]],
                     portalApi: PortalApi,
                     portalUserAuthService: UserAuthInfo,
                     portalRouter: PortalRouter
                    ) {

  private val log = Logger.of[LoginComponent]

  val formValid: Var[Boolean] = Var(false)

  def validate(): Unit = formValid.set(emailInput.isValid && passwordInput.valid.now)

  val signInBus = new EventBus[dom.MouseEvent]
  val signInBusWithEnter = new EventBus[dom.KeyboardEvent]
  val emailInput: Textfield.El = emailTextfield(portalUserAuthService.usernameHint)

  val passwordInput: SecretInputComponent = SecretInputComponent("Password")

  val rememberCheckbox: Checkbox.El = Checkbox(
    _.checked <-- portalUserAuthService.usernameHint.map(_.nonEmpty)
  )

  val errorMessage: Var[Option[String]] = Var(None)

  val node: Div = div(

    //check authorization
    $redirect.map(_.isEmpty).flatMap {
      case true => portalApi.getMe.recoverToTry.collect {
        case Success(_) => true
        case Failure(exception: NotAuthorizedException) =>
          portalUserAuthService.setUsername(exception.username)
          false
        case Failure(exception: NotAuthorizedSilentException) =>
          portalUserAuthService.setUsername(exception.username)
          false

      }
      case false => EventStream.empty
    } --> Observer[Boolean](onNext = navigate => {
      if (navigate) {
        portalUserAuthService.resetUsername()
        portalRouter.navigate(BufferPage)
      }
    }),

    div(
      cls := "slds-align--absolute-center auth-page",


      div(
        cls := "form",
        AuthPagesLogo,
        div(cls := "slds-p-top--medium slds-p-bottom--large",
          p(
            "Log in to your account",
            cls := "slds-text-align--center title--level-1"
          )),


//        LocalErrorMessageView(errorMessage.signal.map {
//          case Some(_) => Some("We do not recognize your username and/or password.")
//          case None => None
//        }),


        LocalMessagesView(errorMessage.signal.map {
          case Some(_) => Some("We do not recognize your username and/or password.")
          case None => None
        }),

        child <-- $redirect.map { redirectPage =>
          form(
            emailInput,
            passwordInput.node,
            div(
              cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread slds-p-bottom--medium",
              Formfield(
                _.label := "Remember me",

                _.slots.default(rememberCheckbox)
              ),
              a("Forgot your password?", cls := "secondary", onClick.mapTo(ResetPasswordPage) --> portalRouter.goto),
              //          span("Remember me for 30 days", cls := "mdc-theme--text-secondary-on-background")
            ),
            onKeyPress.filter(key => key.keyCode == 13) --> signInBusWithEnter.writer,
            signInBusWithEnter.events.flatMap(_ =>
              portalApi.signIn(
                SignInModel(
                  emailInput.ref.value,
                  passwordInput.value.now,
                  rememberCheckbox.ref.checked
                )
              )
            )
              .validateWithLocalError(errorMessage)
              --> Observer[String](onNext = _ => {
              portalUserAuthService.resetUsername()
              portalRouter.navigate(
                redirectPage match {
                  case None => BufferPage
                  case Some(p) => p
                })
            }),

          )
        },

//        div(
//          cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread slds-p-top--x-small",
//          Formfield(
//            _.label := "Remember me",
//
//            _.slots.default(rememberCheckbox)
//          ),
//          a("Forgot your password?", onClick.mapTo(ResetPasswordPage) --> portalRouter.goto),
//          //          span("Remember me for 30 days", cls := "mdc-theme--text-secondary-on-background")
//        ),
        div(
          cls := "slds-p-bottom--small slds-align--absolute-center",
          child <-- $redirect.map { redirectPage =>
            Button(
              _.disabled <-- formValid.signal.map(!_),
              _.raised := true,
              _ => cls := "secondary slds-size--1-of-1",
              //              _.label := "Sign in with email",
              _.label := "Sign in",
              _ => onClick --> signInBus.writer,
              _ => signInBus.events.flatMap(_ =>
                portalApi.signIn(
                  SignInModel(
                    emailInput.ref.value,
                    passwordInput.value.now,
                    rememberCheckbox.ref.checked
                  )
                )
              )
                .validateWithLocalError(errorMessage)
                --> Observer[String](onNext = _ => {
                portalUserAuthService.resetUsername()
                portalRouter.navigate(
                  redirectPage match {
                    case None => BufferPage
                    case Some(p) => p
                  })
              })

            )
          }
        ),
        Button(
          _ => cls := "slds-size--1-of-1 secondary",
          _.outlined := true,
          _.label := "Don't have an account?",
          _ => onClick.mapTo(SignUpPage) --> portalRouter.goto
        ),
        //        p(
        //          cls := "slds-p-vertical--large slds-text-align--center mdc-theme--text-secondary-on-background",
        //          "By clicking Sign in with email or Sign in with your Google or Outlook account, you agree to our Terms of Service ",
        //          a("Terms of Use", href := "https://www.aurinko.io/terms-of-services", target := "_blank"),
        //          " and ",
        //          a("Privacy Policy", href := "https://www.aurinko.io/privacy-policy", target := "_blank"),
        //          "."
        //        ),
        onInput --> Observer[dom.Event](onNext = _ => validate()),
        passwordInput.node.events(onInput) --> Observer[dom.Event](onNext = _ => validate())
      )
    ),

  )

}

case class SignInModel(email: String = "", password: String = "", rememberMe: Boolean = false)
