package root_pages.aurinko_pages.app.settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Dialog, Icon, Textarea, Textfield}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ServiceType
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalNestedOps, SignalOps, SignalOptionOps}
import common.{AppRegistrationModel, PingTools, PortalAppRegistration, asteriskString}
import common.ui.confirmDeletionPopup
import common.forms.{FormsLocalExceptionHandling, TextareaFormOps, TextfieldFormOps}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.icons.{MaterialIcons, TrashIcon}
import common.ui.mat_components_styles.styleTextfieldWithPlaceholder
import org.scalajs.dom
import org.scalajs.dom.html
import service.apis.portal_api.PortalApi
import service.clipboard_service.ClipboardService
import service.portal_state.PortalState
import wvlet.log.Logger

case class OAuthBasic(
                       $appReg: Signal[Option[PortalAppRegistration]],
                       componentHeader: String,
                       bus: EventBus[Unit],
                       serviceType: ServiceType,
                       portalApi: PortalApi,
                       portalState: PortalState,
                       $initialExpand: Signal[Boolean],
                       showSecretFields: Boolean = false,
                       showNativeScopesField: Boolean = false,
                       isDaemon: Boolean = false,
                       apiOrigin: String,
                       clipboardService: ClipboardService
                     ) extends AppRegistrationComponent {

  private val log = Logger.of[OAuthBasic]

  val showDeletePopup: Var[Boolean] = Var(false)

  val deletePopup: Dialog.El = confirmDeletionPopup(
    onConfirm = Observer[Unit](onNext = _ => {
      bus.emit()
      showDeletePopup.set(false)
    }),
    onCancel = showDeletePopup.writer.contramap((_: Unit) => false),
    heading = "Delete registration",
    $visible = showDeletePopup.signal,
    onClose = showDeletePopup.writer.contramap((_: Unit) => false),
    onConfirmEventTransfer = () => EventStream.fromValue(())
      .sample($appReg)
      .map(_.get)
      .withCurrentValueOf(portalState.$teamApp.map(_.appKey))
      .flatMap(t => portalApi.deleteAppReg(t._2, t._1))
  ).amend(PingTools.dialogBinders(portalApi))

  val node: Div = ExpansionPanelComponent(
    header = p(
      componentHeader,
      cls := "title--level-2",
      cls <-- $appReg.map(x => if (x.isEmpty) "light" else "")
    ),

    body = Some(
      div(
        cls := "slds-p-bottom_medium",
        child.maybe <-- errorView,

        $appReg.map {
          case Some(ar) => ar.toEditModel.some
          case None => AppRegistrationModel(serviceType = serviceType).some
        } --> editModel.writer,

        editModel.signal.semiflatMap(m => m.clientId.signal.map(_ -> m.serviceType.toString())) --> Observer[Option[(String, String)]](c => log.info(s" editModel clientId: $c")),

        child.maybe <-- editModel.signal.nestedMap(model => {
          div(
            deletePopup,
            div(
              cls := "slds-m-bottom_medium",
              model.serviceType match {
                case ServiceType.office365 => p(
                  cls := "slds-m-top_xx-small",
                  "OAuth keys used to authenticate users to your app. See our docs: ",
                  a("Office 365 OAuth setup", href := "https://docs.aurinko.io/article/16-office-365-oauth-setup"),
                  "."
                )
                case _ => p(
                  cls := "slds-m-top_xx-small",
                  "OAuth keys used to authenticate users to your app."
                )
              }
            ),
            div(
              cls := "slds-grid slds-m-bottom--medium",
              Textfield(
                _ => cls := "width-medium",
                _.outlined := true,
                _.label := "Redirect URI",
                _.`type` := "url",
                _.placeholder <-- portalState.$app.map(_.defaultRedirect(apiOrigin)),
                _ => onMountCallback(styleTextfieldWithPlaceholder),
                _.helper := (model.serviceType match {
                  case ServiceType.hubspot => "Note: Please enter the url above as one of authorized redirect URIs in your HubSpot developer console (App / Auth)."
                  case ServiceType.office365 => "Note: Please enter the url above as one of return URIs for your Azure AD app registration."
                  case _ => "Note: Please enter the url above as one of return URIs for your app registration."
                }),
                _.helperPersistent := true,
                _ => controlled(
                  value <-- model.intermediateCallbackUrl,
                  onInput.mapToValue --> model.intermediateCallbackUrl
                )
              ).bindToForm(model.formState),
              div(cls := "slds-p-left_medium slds-p-top--large", iconCopy($redirectUri(model, portalState, apiOrigin), clipboardService)
              )
            ),
            div(cls := "slds-grid slds-grid_vertical-align-end",
              Textfield(
                _ => cls := "width-medium slds-m-bottom_medium",
                _.outlined := true,
                _.label := "Client Id",

                _ => controlled(
                  value <-- model.clientId.signal,
                  onInput.mapToValue --> model.clientId
                )
              ).bindToForm(model.formState)),

            div(cls := "slds-grid slds-grid_vertical-align-end",
              Textfield(
                _ => cls := "width-medium slds-m-bottom_medium",
                _.outlined := true,
                _.label := "Client secret",
                _.required <-- $appReg.map {
                  case Some(_) => false
                  case None => true
                },
                _ => onMountCallback(ctx => if (model.hasSecret) styleTextfieldWithPlaceholder(ctx)),
                _.placeholder <-- $appReg.map {
                  case Some(_) => asteriskString
                  case None => ""
                },
                _ => controlled(
                  value <-- model.clientSecret,
                  onInput.mapToValue --> model.clientSecret,
                )
              ).bindToForm(model.formState)
            ),

            if (showNativeScopesField) Some(Textarea(
              _ => cls := "width-large",
              _.label := "Default scopes",
              _.outlined := true,
              _.value <-- model.defaultScopes,
              _.rows <-- model.defaultScopes.signal.map(_.count(_ == '\n')),
              _ => onInput.mapToValue --> model.defaultScopes,
              _.helper := s"As you see them in your ${serviceType.label} app registration.",
              _.helperPersistent := true
            ).bindToForm(model.formState)) else None,

            if (showSecretFields) {
              KeyInputComponent(
                model.clientSecret2,
                "Private key",
                Signal.fromValue(model.hasPk)
              ).node ::
                KeyInputComponent(
                  model.clientSecret3,
                  "Certificate",
                  Signal.fromValue(model.hasPk)
                ).node ::
                Nil
            } else None,

            div(
              cls := "slds-p-top_xx-large slds-grid footer-buttons",
              cls <-- $appReg.map { case None => "slds-grid_align-end" case _ => "slds-grid_align-spread" },
              div(
                cls := "slds-col",

                cls <-- $appReg.map { case None => "hidden" case _ => "" },
                TrashIcon(showDeletePopup.writer.contramap((_: dom.MouseEvent) => true))
              ),
              div(
                cls := "dialog-submit-buttons",
                ButtonsPairComponent[Unit, Option[PortalAppRegistration]](
                  disabled =
                    if (!showSecretFields) editModel.signal.flatMap(_.$traverse(_.formState.$dirty)).$contains(false)
                    else editModel.signal.flatMap(_.$traverse(_.formState.$dirty.map(!_)))
                      .combineWith(model.clientSecret2.signal.map(_ == ""))
                      .combineWith(model.clientSecret3.signal.map(_ == ""))
                      .map(t => t._1.contains(true) && t._2 && t._3),

                  primaryDisabled =
                    if (!showSecretFields) editModel.signal.flatMap(_.$traverse(_.formState.$submitAllowed))
                      .combineWith(model.clientSecret)
                      .map { case (valid, secret) => !valid.contains(true) || (secret.isEmpty && !model.hasSecret) }

                    else editModel.signal.flatMap(_.$traverse(_.formState.$submitAllowed))
                      .combineWith(model.clientSecret2)
                      .combineWith(model.clientSecret3)
                      .combineWith(model.clientSecret)
                      .map { case (valid, secret2, secret3, secret) =>
                        !valid.contains(true) || (secret2 :: secret3 :: Nil).count(_.isEmpty) == 1 || (secret.isEmpty && !model.hasSecret)
                      },

                  primaryEffect = () => editModel.signal.stream
                    .collect { case Some(model) => model }
                    .withCurrentValueOf(portalState.$teamApp)
                    .flatMap {
                      case (model, teamApp) => portalApi.createOrUpdateAppReg(teamApp.appKey, model.toImmutableModel)
                    }
                    .withErrorHandlingAndCollect(
                      FormsLocalExceptionHandling
                        .handler(str => editModel.now.foreach(_.formError.set(str.some)))),

                  primaryObserver = Observer[Unit](onNext = _ => {
                    bus.emit(())
                    model.clientSecret2.set("")
                    model.clientSecret3.set("")
                  }),

                  secondaryEffect = () => EventStream.fromValue(()).sample($appReg),

                  secondaryObserver = Observer[Option[PortalAppRegistration]](onNext = {

                    case Some(value) =>
                      model.updateModelFromImmutable(value)
                      model.formState.validate()
                    case None =>
                      model.resetModel()
                      model.formState.validate()
                  })
                ).node,
              )
            )
          )
        })
      )
    ),
    bordered = Signal.fromValue(true),
    expanded = $initialExpand.combineWith(isExpanded.signal).map(t => t._1 || t._2),
    onExpansionChange = isExpanded.writer
  ).node


}
