package common

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.{Checkbox, Dialog, Formfield, Icon, Radio, Select, Textarea, Textfield}
import com.github.uosis.laminar.webcomponents.material.Textfield.{value, _}
import com.raquo.airstream.core.Observer
import com.raquo.domtypes.generic.codecs.StringAsIsCodec
import com.raquo.laminar.api.L._
import com.raquo.laminar.keys.ReactiveHtmlAttr
import com.raquo.laminar.nodes.ReactiveElement.Base
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.{EventStreamOps, SignalOps, SignalSeqOps, ValueToObservableOps}
import common.ui.auto_suggest.AutoSuggestComponent
import common.ui.buttons_pair.ButtonsPairComponent
import org.scalajs.dom
import org.scalajs.dom.html
import service.apis.API
import service.portal_state.FormInputDataCaching
import wvlet.log.Logger

import scala.scalajs.js
import scala.scalajs.js.timers.setTimeout

package object ui {
  def appFooter: Div = div(
    cls := "app-footer",
    small("© Aurinko 2024", cls := "primary"),
    div(
      cls := "slds-grid",
      small(
        cls := "slds-m-right--x-large",
        a("Docs", href := "https://docs.aurinko.io", target := "_blank")),
      small(
        cls := "slds-m-right--x-large",
        a("API refs", href := "https://apirefs.aurinko.io", target := "_blank")),
      small(
        cls := "slds-m-right--x-large",
        a("Privacy policy", href := "https://www.aurinko.io/privacy-policy", target := "_blank")),
      small(
        a("Terms of service", href := "https://www.aurinko.io/terms-of-services", target := "_blank"))
    ),
  )

  def confirmDeletionPopup(
                            onConfirm: Observer[Unit],
                            onCancel: Observer[Unit],
                            heading: String,
                            $visible: Signal[Boolean],
                            onClose: Observer[Unit],
                            onConfirmEventTransfer: () => EventStream[Unit]
                          ): Dialog.El = {


    Dialog(
      _.open <-- $visible,
      _.heading := heading,
      _.onClosing.mapTo(()) --> onClose,
      _.slots.default(p("This action cannot be undone!")),
      _.slots.primaryAction(
        ButtonsPairComponent[Unit, Unit](
          primaryButtonText = "Delete",
          primaryEffect = onConfirmEventTransfer,
          primaryObserver = onConfirm,
          secondaryEffect = () => ().streamed,
          secondaryObserver = onCancel

        ).node
      )
    )
  }

  trait FormField {
    def valid: Signal[Boolean]
    def dirty: Signal[Boolean]


  }
  case class AuFormField(node: Textfield.El,
                         initialValidation: Boolean = false,
                         customValidation: Option[String => Boolean] = None,
                         $customValidation: String => Signal[Boolean] = (_: String) => true.signaled
                        ) {
    private val log = Logger.of[AuFormField]

    val dirty = Var(false)
    val valid = Var(true)
    val vasInFocus = Var(false)
    private val callValidationBus = new EventBus[Unit]

    val updateValueBus = new EventBus[String]
    private var initValue = ""

    node.amendThis(thisNode => {
      List(
        value <-- updateValueBus.events,

        callValidationBus.events
          .mapTo(thisNode.ref.value)
          .flatMap(str => for {
            customValid <- $customValidation(str).map(_ && customValidation.forall(_.apply(str))).stream

            jsValid = thisNode.isValid
            dirty = str != initValue

          } yield (customValid && jsValid) -> dirty) --> Observer.combine[(Boolean, Boolean)](
          valid.writer.contramap { case (v, _) => v },
          dirty.writer.contramap { case (_, d) => d },
        ),

        onInput.mapTo(()) --> callValidationBus.writer,

        onBlur.mapTo(true) --> Observer.combine[Boolean](vasInFocus.writer, callValidationBus.writer.contramap(_ => ())),

        validateOnInitialRender := initialValidation,

        updateValueBus.events.mapTo(()).delay() --> callValidationBus.writer,
        cls <-- valid.signal
          .combineWith(vasInFocus.signal)
          .map { case (false, true) => "with-error" case _ => "" }
      ) ::: {
        initValue = thisNode.ref.value
        //needed to avoid the error: Cannot read property 'validity' of null
        val validated = Var(false)
        List(
          validated.signal.flatMap {
              case false => EventStream.periodic(50, resetOnStop = true, emitInitial = false).mapTo(
                try {
                  Some(thisNode.ref.validity.asInstanceOf[dom.ValidityState])
                }
                catch {
                  case _: Throwable => None
                }
              )
              case true => EventStream.empty
            }
            .filter(_.isDefined).map(_.get) --> Observer[dom.ValidityState](state => {
            valid.set(state.valid)

            validated.set(true)
          }), true.streamed.delay(3000) --> validated)

      }

    }
    )

    def getValue: String = node.ref.value

    def reset(): Unit = {
      updateValueBus.emit(initValue)
      dirty.set(false)
      callValidationBus.emit(())
    }

    def dirtyUpdate(): Unit = {
      callValidationBus.emit(())
    }

    def setInitial(newInitial: String): Unit = initValue = newInitial

    def validate(): Unit = {
      callValidationBus.emit(())
    }
  }

  case class AuFormTextArea(node: Textarea.El, initialValidation: Boolean = false, customValidation: Option[String => Boolean] = None) {
    val updateValueBus = new EventBus[String]
    private var initValue = ""

    node.amend(
      value <-- updateValueBus.events,
      onInput --> Observer[dom.Event](onNext = _ => validate()),
      validateOnInitialRender := initialValidation,
      updateValueBus.events.delay() --> Observer[String](onNext = _ => validate()),
      onMountCallback(th => {
        initValue = th.thisNode.ref.value
        val validated = Var(false)
        th.thisNode.amend(
          validated.signal.flatMap {
            case false => EventStream.periodic(50, resetOnStop = true, emitInitial = false).mapTo(
              try {
                Some(th.thisNode.ref.validity.asInstanceOf[dom.ValidityState])
              }
              catch {
                case _: Throwable => None
              }
            )
            case true => EventStream.empty
          }.filter(_.isDefined).map(_.get) --> Observer[dom.ValidityState](onNext = state => {
            valid.set(state.valid)
            validated.set(true)
          }),

          EventStream.fromValue(()).delay(3000).filterNot(_ => validated.now) --> Observer[Unit](onNext = _ => {
            validated.set(true)
          })
        )
      })
    )

    def getValue: String = node.ref.value

    def reset(): Unit = {
      updateValueBus.emit(initValue)
      dirty.set(false)
      validate()
    }

    def dirtyUpdate(): Unit = {
      initValue = node.ref.value
      validate()
    }

    def setInitial(newInitial: String): Unit = initValue = newInitial

    //    validate()

    def validate(): Unit = {
      dirty.set(node.ref.value != initValue)
      valid.set(if (customValidation.isDefined) customValidation.get(node.ref.value) else node.isValid)
    }

    val dirty = Var(false)
    val valid = Var(true)
  }

  //needed for clickable labels
  case class AuCheckboxComponent(checkbox: Checkbox.El, label: String, cssClass: Signal[String] = Signal.fromValue("")) {
    val node: Formfield.El = Formfield(
      _.label := label,
      _.slots.default(checkbox),
      _ => cls <-- cssClass
    )
  }

  case class AuFormCheckbox(node: Checkbox.El, initialValidation: Boolean = false, customValidation: Option[Boolean => Boolean] = None) {
    private val log = Logger("AuFormCheckbox")
    private val updateValueBus = new EventBus[Boolean]
    private var initValue = false

    node.amendThis(th => {
      initValue = th.ref.checked
      List(
        checked <-- updateValueBus.events,
        composeEvents(onChange)(_.delay(0)) --> Observer[dom.Event](onNext = _ => {

          validate()
        }),
        validateOnInitialRender := initialValidation,
        updateValueBus.events.delay() --> Observer[Boolean](onNext = _ => validate())
      )
    }
    )

    def getValue: Boolean = node.ref.checked

    def reset(): Unit = {
      updateValueBus.emit(initValue)
      dirty.set(false)
      validate()
    }

    def dirtyUpdate(): Unit = {
      initValue = node.ref.checked
      validate()
    }

    def setInitial(newInitial: Boolean): Unit = initValue = newInitial

    def validate(): Unit = {
      dirty.set(node.ref.checked != initValue)
      valid.set(if (customValidation.isDefined) customValidation.get(node.ref.checked) else true)

    }

    val dirty = Var(false)
    val valid = Var(true)
  }

  case class AuFormRadio(node: Radio.El, initialValidation: Boolean = false, customValidation: Option[Boolean => Boolean] = None,
                         $externalChange: EventStream[Unit] = EventStream.empty) {
    private val log = Logger("AuFormRadio")
    private val updateValueBus = new EventBus[Boolean]
    private var initValue = false

    node.amendThis(th => {
      initValue = th.ref.checked
      List(
        checked <-- updateValueBus.events,
        onChange --> Observer[dom.Event](onNext = _ => validate()),
        validateOnInitialRender := initialValidation,
        updateValueBus.events.delay() --> Observer[Boolean](onNext = _ => validate()),
        $externalChange.delay() --> Observer[Unit](onNext = _ => validate())
      )
    })

    def getValue: Boolean = node.ref.checked

    def reset(): Unit = {
      updateValueBus.emit(initValue)
      dirty.set(false)
      validate()
    }

    def dirtyUpdate(): Unit = {
      initValue = node.ref.checked
      validate()
    }

    def setInitial(newInitial: Boolean): Unit = initValue = newInitial

    def validate(): Unit = {
      dirty.set(node.ref.checked != initValue)
      valid.set(if (customValidation.isDefined) customValidation.get(node.ref.checked) else true)
    }

    val dirty = Var(false)
    val valid = Var(true)
  }

  case class AuFormSelect(node: Select.El, initialValidation: Boolean = false, customValidation: Option[String => Boolean] = None) {
    val updateValueBus = new EventBus[String]
    private var initValue = ""

    node.amend(
      value <-- updateValueBus.events,
      onChange --> Observer[dom.Event](onNext = _ => validate()),
      validateOnInitialRender := initialValidation,
      updateValueBus.events.delay() --> Observer[String](onNext = _ => validate()),
      onMountCallback(th => initValue = th.thisNode.ref.value)
    )

    def getValue: String = node.ref.value

    def reset(): Unit = {
      updateValueBus.emit(initValue)
      dirty.set(false)
      validate()
    }

    def dirtyUpdate(): Unit = {
      initValue = node.ref.value
      validate()
    }

    def setInitial(newInitial: String): Unit = initValue = newInitial

    //    validate()

    def validate(): Unit = {
      dirty.set(node.ref.value != initValue)
      valid.set(if (customValidation.isDefined) customValidation.get(node.ref.value) else true)
    }

    val dirty = Var(false)
    val valid = Var(true)
  }

  case class AurinkoFormAutoSuggest(element: AutoSuggestComponent) {
    private val log = Logger.of[AurinkoFormAutoSuggest]
    private val initialValue = Var("")

    val $valid: Signal[Boolean] = element.$valid
    val $dirty: Signal[Boolean] = element.value.signal
      .combineWith(initialValue.signal)
      .map {
        case (Some(v), i) => i != v
        case (_, i) => i.nonEmpty
      }

    element.node.amend(
      element.value.stream.map(_.getOrElse("")) --> initialValue
    )


  }

  // TODO: rethink this class
  case class AuFormCustomValidator(valid: Var[Boolean] = Var(true), dirty: Var[Boolean] = Var(false))

  case class AuFormCustomValidatorExp($valid: Signal[Boolean] = true.signaled, $dirty: Signal[Boolean] = false.signaled)

  case class AuFormState(
                          fields: List[AuFormField] = Nil,
                          textAreas: List[AuFormTextArea] = Nil,
                          checkBoxes: List[AuFormCheckbox] = Nil,
                          selects: List[AuFormSelect] = Nil,
                          customValidations: List[AuFormCustomValidator] = Nil,
                          radios: List[AuFormRadio] = Nil,
                        ) {
    private val log = Logger("AuFormState")

    val validSignal: Signal[Boolean] = Signal.combineSeq(
      fields.map(_.valid.signal) ++
        textAreas.map(_.valid.signal) ++
        checkBoxes.map(_.valid.signal) ++
        selects.map(_.valid.signal) ++
        radios.map(_.valid.signal) ++
        customValidations.map(_.valid.signal)
    ).map(x => {
      !x.contains(false)
    })

    val dirtySignal: Signal[Boolean] = Signal.combineSeq(
      fields.map(_.dirty.signal) ++
        textAreas.map(_.dirty.signal) ++
        checkBoxes.map(_.dirty.signal) ++
        radios.map(_.dirty.signal) ++
        selects.map(_.dirty.signal) ++
        customValidations.map(_.dirty.signal)
    ).map(x => {
      x.contains(true)
    })

    def reset(): Unit = {
      selects.foreach(_.reset())
      checkBoxes.foreach(_.reset())
      radios.foreach(_.reset())
      textAreas.foreach(_.reset())
      fields.foreach(_.reset())
    }

    def validate(): Unit = {
      fields.foreach(_.validate())
      textAreas.foreach(_.validate())
      checkBoxes.foreach(_.validate())
      radios.foreach(_.validate())
      selects.foreach(_.validate())
    }

    def dirtyUpdate(): Unit = {
      fields.foreach(_.dirtyUpdate())
      textAreas.foreach(_.dirtyUpdate())
      checkBoxes.foreach(_.dirtyUpdate())
      radios.foreach(_.dirtyUpdate())
      selects.foreach(_.dirtyUpdate())
    }
  }

  //todo: think [try to make the usage more compact]
  class AuFormStateExp(
                        fields: Var[List[AuFormField]] = Var(Nil),
                        textAreas: Var[List[AuFormTextArea]] = Var(Nil),
                        checkBoxes: Var[List[AuFormCheckbox]] = Var(Nil),
                        radios: Var[List[AuFormRadio]] = Var(Nil),
                        selects: Var[List[AuFormSelect]] = Var(Nil),
                        autoSuggests: Var[List[AurinkoFormAutoSuggest]] = Var(Nil),
                        customValidations: Var[List[AuFormCustomValidatorExp]] = Var(Nil)
                      ) {
    private val log = Logger("AuFormStateExp")
    //    private val radioGroups: Var[Map[String, List[AuFormRadio]]] = Var(Map.empty)
    //    private val textFieldsGroups: Var[Map[String, List[AuFormRadio]]] = Var(Map.empty)

    def addFormField(ff: AuFormField): Unit = fields.update(f => ff :: f)

    def addTextField(tf: Textfield.El,
                     customValidation: Option[String => Boolean] = None,
                     $customValidation: String => Signal[Boolean] = (_: String) => true.signaled,
                     initialValidation: Boolean = false
                    ): Unit =
      fields.update(f => AuFormField(tf, initialValidation = initialValidation, customValidation = customValidation, $customValidation = $customValidation) :: f)

    //todo: custom validation
    def addTextArea(ta: Textarea.El, initialValidation: Boolean = true): Unit = textAreas.update(f => AuFormTextArea(ta, initialValidation) :: f)

    def addCheckbox(ch: Checkbox.El, initialValidation: Boolean = true): Unit = checkBoxes.update(f => AuFormCheckbox(ch, initialValidation) :: f)

    def addRadio(r: Radio.El, initialValidation: Boolean = true, $externalChange: EventStream[Unit] = EventStream.empty): Unit = radios.update(f => AuFormRadio(r, initialValidation, $externalChange = $externalChange) :: f)

    def addSelect(s: Select.El, initialValidation: Boolean = true): Unit = selects.update(f => AuFormSelect(s, initialValidation) :: f)

    def addAutoSuggest(s: AutoSuggestComponent): Unit = autoSuggests.update(f => AurinkoFormAutoSuggest(s) :: f)

    def addCustomValidation(cv: AuFormCustomValidatorExp): Unit = customValidations.update(f => cv :: f)

    val $valid: Signal[Boolean] = Signal.combineSeq(
      fields.signal.semiflatMap(_.valid.signal) ::
        textAreas.signal.semiflatMap(_.valid.signal) ::
        checkBoxes.signal.semiflatMap(_.valid.signal) ::
        radios.signal.semiflatMap(_.valid.signal) ::
        selects.signal.semiflatMap(_.valid.signal) ::
        autoSuggests.signal.semiflatMap(_.$valid) ::
        customValidations.signal.semiflatMap(_.$valid) :: Nil
    ).map(!_.flatten.contains(false))

    val $dirty: Signal[Boolean] = Signal.combineSeq(
      fields.signal.semiflatMap(_.dirty.signal) ::
        textAreas.signal.semiflatMap(_.dirty.signal) ::
        checkBoxes.signal.semiflatMap(_.dirty.signal) ::
        radios.signal.semiflatMap(_.dirty.signal) ::
        selects.signal.semiflatMap(_.dirty.signal) ::
        autoSuggests.signal.semiflatMap(_.$dirty) ::
        customValidations.signal.semiflatMap(_.$dirty) :: Nil
    ).map(_.flatten.contains(true))

    val $submitAllowed: Signal[Boolean] = $valid.combineWith($dirty).map(t => t._1 && t._2)

    //    def reset(): Unit = {
    //      selects.now.foreach(_.reset())
    //      checkBoxes.now.foreach(_.reset())
    //      textAreas.now.foreach(_.reset())
    //      fields.now.foreach(_.reset())
    //    }
    //
    def validate(): Unit = {
      fields.now.foreach(_.validate())
      textAreas.now.foreach(_.validate())
      checkBoxes.now.foreach(_.validate())
      radios.now.foreach(_.validate())
      selects.now.foreach(_.validate())
    }
    //
    //    def dirtyUpdate(): Unit = {
    //      fields.now.foreach(_.dirtyUpdate())
    //      textAreas.now.foreach(_.dirtyUpdate())
    //      checkBoxes.now.foreach(_.dirtyUpdate())
    //      selects.now.foreach(_.dirtyUpdate())
    //    }
  }


  def ApplicationLogoComponent(encodedSignal: Signal[Option[ApplicationLogo]]): Signal[HtmlElement] = encodedSignal.map {
    case Some(encodedLogo) => img(
      src := s"data:${encodedLogo.mimeType};base64, ${encodedLogo.content}",
      cls := "slds-m-right--medium",
      width := "4rem",
      height := "4rem",
      //      marginLeft := "-6px"
    )
    case None => Icon(
      _ => "wallpaper",
      _ => cls := "x-large slds-m-right--medium light primary",
      _ => marginLeft := "-6px"
    )
  }

  def animateHeightModifier[T]($heightChanger: EventStream[T])(node: ReactiveHtmlElement[dom.html.Element]) = {

    List(
      cls := "animated-height",

      $heightChanger --> Observer[T](_ => {
        node.ref.style.height = node.ref.scrollHeight + "px"
      }),

      $heightChanger.delay() --> Observer[T](_ => {

        node.ref.style.height = node.ref.scrollHeight + "px"
      }),

      $heightChanger.delay(500) --> Observer[T](_ => {
        node.ref.style.height = "auto"
      })
    )
  }

  //css requires fixed height (in px/rem/em) to animate height changes
  //we use node.ref.scrollHeight to make a change with animation and then set "auto" to avoid  the content cutting in some scenarios
  def animateVisibilityModifier($visibility: Signal[Boolean])(node: ReactiveHtmlElement[dom.html.Element]): List[Mod[ReactiveHtmlElement.Base]] = {
    val autoHeightBus = new EventBus[Unit]
    val heightBus = new EventBus[String]

    def showElement(): Unit = {

      node.ref.style.height = node.ref.scrollHeight + "px"
      autoHeightBus.emit(())
    }

    def hideElement(): Unit = {
      node.ref.style.overflow = "hidden"
      node.ref.style.height = node.ref.clientHeight + "px"
      node.ref.style.overflow = "hidden"
      heightBus.emit("0px")
    }

    List(
      cls := "animated-height",
      height := "0px",

      autoHeightBus.events.delay(300) --> Observer[Unit](onNext = _ => node.ref.style.height = "auto"),

      //zero delay needed to let the code set fixed height to element before
      heightBus.events.delay() --> Observer[String](onNext = str => node.ref.style.height = str),

      EventStream.fromValue(()).sample($visibility) --> Observer[Boolean](onNext = {
        case true => showElement()
        case false => hideElement()
      }),

      $visibility.changes.filter(_ == true).delay() --> Observer[Boolean](onNext = _ => showElement()),
      $visibility.changes.filter(!_) --> Observer[Boolean](onNext = _ => hideElement()),

      //      $visibility --> Observer[Boolean](onNext = {
      //        case true =>
      //          println(s"node.ref.scrollHeight ${node.ref.scrollHeight} node.ref.clientHeight ${node.ref.clientHeight}")
      //
      //
      //        case false =>
      //      }),


      $visibility.changes.delay(400) --> Observer[Boolean](onNext = {
        case true => node.ref.style.overflow = "visible"
        case false => node.ref.style.overflow = "hidden"
      }),


    )
  }

  case object Attribute {
    val Selector: ReactiveHtmlAttr[String] = Attribute("selector")
    val Description: ReactiveHtmlAttr[String] = Attribute("description")

    def apply(name: String): ReactiveHtmlAttr[String] =
      customHtmlAttr(name, StringAsIsCodec)
  }

}

object PingTools {
  def dialogBinders(api: API): List[Binder[Base]] = List(
    composeEvents(material.Dialog.onOpening)(_.flatMap(_ => api.ping)) --> Observer.empty,
    composeEvents(material.Dialog.onClosing)(_.flatMap(_ => api.ping)) --> Observer.empty,
  )

  def dialogOnOpenBinder(api: API): Binder[Base] =
    composeEvents(material.Dialog.onOpening)(_.flatMap(_ => api.ping)) --> Observer.empty

}

object SessionExpiredActions {
  def dialogBinder($sessionExpiredEvents: EventStream[Unit])(t: material.Dialog.El): Binder[Base] =
    $sessionExpiredEvents --> Observer[Unit](_ => t.ref.close())
}

object FormCachingTools {
  def dialogBinders[T <: FormModel](formCaching: FormInputDataCaching,
                                    $sessionExpiredEvents: EventStream[Unit],
                                    inputModel: Var[Option[T]],
                                    $initialModel: Signal[T],
                                    updateModelFunc: T => Unit,
                                    typeCheck: FormModel => Option[T],
                                    onFormFilledCallback: () => Unit //optional 9for validation): if formState is property of FormModel valid
                                   )(t: material.Dialog.El) = List(
    ().streamed.sample($initialModel) --> Observer[T](init => formCaching.model.flatMap(typeCheck).foreach { _ =>
      inputModel.set(init.some)
    }
    ), //fill form with initial data from api (before user input) and initiate dialog open (if dialog 'open' observer is subscribed to inputModel.map(_.nonEmpty))
    t.events(material.Dialog.onOpened).mapTo(formCaching.model)
      .map(_.flatMap(m => typeCheck(m))).collect {
        case Some(value) => value
      } --> Observer[T](m => {
      updateModelFunc(m)
      formCaching.resetCache()
      setTimeout(0) {
        inputModel.now.foreach(_.formState.validate())
        onFormFilledCallback()
      }
    }),
    $sessionExpiredEvents
      .withCurrentValueOf(inputModel.signal)
      .collect { case Some(m) => m } --> Observer[T](editModel => {

      formCaching.cacheFormModel(editModel)
      inputModel.set(None)
    })
  )

}

