package portal_router

import common.AppKey
import root_pages.aurinko_pages.app.settings.SettingsSection

sealed trait Page {
  def title: String
}

sealed trait AurinkoPage extends Page

sealed trait AppPage extends AurinkoPage {
  val appKey: AppKey
}

case class TeamAppsPage(teamId: Int) extends TeamPage {
  override val title: String = "Main"
}

case class LoginPage(redirect: Option[String] = None) extends Page {
  override val title: String = "Log in"
}

case object SignUpPage extends Page {
  override val title: String = "Sign up"
}

case class NewPasswordPage(timestamp: Int, key: String) extends Page {
  override val title: String = "New password"
}

case object ResetPasswordPage extends Page {
  override val title: String = "Reset password"
}

case class AcceptInvitationPage(invId: Int, invKey: String) extends Page {
  override val title: String = "Team invitation"
}

case class VerifyEmailPage(key: String, timestamp: Option[Int]) extends Page {
  override val title: String = "Verify email"
}

case object NotVerifiedPage extends Page {
  override val title: String = "Verify email"
}

case object BufferPage extends AurinkoPage {
  override val title: String = "Loading"
}

sealed trait TeamPage extends AurinkoPage {
  val teamId:Int
}

case class TeamSettingsPage(teamId: Int) extends TeamPage {
  override val title: String = "Team"
}


case class BillingPage(teamId: Int) extends TeamPage {
  override val title: String = "Team"
}

case class BillingInvoicePage(teamId: Int, invoiceId: String) extends TeamPage {
  override val title: String = "Invoice"
}

case class CreateAppPage(teamId: Option[Int] = None) extends AurinkoPage {
  override val title: String = "Create page"
}

case class DashboardPage(appKey: AppKey, assignUsers: Option[Boolean]= None) extends AppPage {
  override val title: String = "Dashboard"
}

case class AccountsPage(
                         appKey: AppKey,
                         filter: Option[String] = None,
                         auth: Option[String] = None,
                         accType: Option[String] = None,
                         search: Option[String] = None,
                         pageNum: Option[Int] = None,
                        pageSize: Option[Int] = None
                       ) extends AppPage {
  override val title: String = "Accounts"
}

case class AppSettingsPage(appKey: AppKey, expand: Option[SettingsSection] = None) extends AppPage {
  override val title: String = "Settings"
}

case class AppStoragePage(appKey: AppKey,
                          search: Option[String] = None,
                          pageNum: Option[Int] = None) extends AppPage {
  override val title: String = "Storage"
}

case class AddinSetupPage(appKey: AppKey) extends AppPage {
  override val title: String = "Addin setup"
}

case class OrgsPage(appKey: AppKey, search: Option[String] = None, serviceProvider: Option[String] = None, pageNum: Option[Int] = None, pageSize: Option[Int] = None) extends AppPage {
  override val title: String = "Organizations"
}

case class UsersPage(appKey: AppKey,
                     search: Option[String] = None,
                     pageNum: Option[Int] = None,
                     pageSize: Option[Int] = None
                    ) extends AppPage {
  override val title: String = "App users"
}

case class AccountPage(appKey: AppKey, accId: Int) extends AppPage {
  override val title: String = "Accounts"
}

case class UserPage(appKey: AppKey, userId: String) extends AppPage {
  override val title: String = "App user"
}

case class OrganizationPage(
                             appKey: AppKey,
                             orgId: Int,
                             pageSize: Option[Int] = None
                           ) extends AppPage {
  override val title: String = "Organization"
}

sealed trait SyncPage extends AppPage {
  val appKey: AppKey
}

case class SyncOrgsPage(appKey: AppKey, search: Option[String] = None, pageNum: Option[Int] = None,  pageSize: Option[Int] = None) extends SyncPage {
  override val title: String = "Sync organizations"
}

sealed trait SyncOrgPages extends SyncPage {
  val appKey: AppKey
  val orgId: String
}


case class SyncOrgPage(appKey: AppKey, orgId: String, search: Option[String] = None, pageNum: Option[Int] = None) extends SyncOrgPages {
  override val title: String = "Sync organization"
}

case class SyncUserPage(appKey: AppKey, orgId: String, userId: Int) extends SyncOrgPages {
  override val title: String = "Sync user"
}


// API Configurations pages
sealed trait VirtualAPIsPage extends AppPage {
  val appKey: AppKey
}

case class VirtualAPIsPage_ItemsOfVirtualMetadata(appKey: AppKey) extends VirtualAPIsPage {
  override val title: String = "Virtual metadata"
}

case class VirtualAPIsPage_ItemsOfProviderMetadata(appKey: AppKey) extends VirtualAPIsPage {
  override val title: String = "Provider metadata"
}

case class VirtualAPIsPage_CustomMetadata(
                                          appKey: AppKey,
                                          metadataId: Long,
                                          configurationId: Option[Long] = None,
                                        ) extends VirtualAPIsPage {
  override val title: String = "Custom metadata"
}

case class VirtualAPIsPage_ClassOfCustomMetadata(
                                                 appKey: AppKey,
                                                 metadataId: Long,
                                                 className: String,
                                                 configurationId: Option[Long] = None,
                                               ) extends VirtualAPIsPage {
  override val title: String = "Custom metadata class"
}

case class VirtualAPIsPage_StandardMetadata(
                                            appKey: AppKey,
                                            metadataName: String,
                                            configurationId: Option[Long] = None,
                                          ) extends VirtualAPIsPage {
  override val title: String = "Standard metadata"
}

case class VirtualAPIsPage_ClassOfStandardMetadata(
                                                   appKey: AppKey,
                                                   metadataName: String,
                                                   className: String,
                                                   configurationId: Option[Long] = None,
                                                 ) extends VirtualAPIsPage {
  override val title: String = "Standard metadata class"
}
