package common.ui

import com.github.uosis.laminar.webcomponents.material.Dialog
import com.raquo.laminar.api.L._
import common.{FormCachingTools, FormModel, PingTools, SessionExpiredActions}
import service.apis.API
import service.portal_state.FormInputDataCaching

package object element_binders {

  implicit final class DialogModifying(private val dialog: Dialog.El) extends AnyVal {

    def withPing(api: API): Dialog.El = dialog.amend(PingTools.dialogBinders(api))

    def withPingOnOpen(api: API): Dialog.El = dialog.amend(PingTools.dialogOnOpenBinder(api))

    def closeOnSessionExpired($expired: EventStream[Unit]): Dialog.El = dialog
      .amendThis(th => SessionExpiredActions.dialogBinder($expired)(th))

    def withFormCaching[T <: FormModel](formCaching: FormInputDataCaching,
                                        $sessionExpiredEvents: EventStream[Unit],
                                        inputModel: Var[Option[T]],
                                        $initialModel: Signal[T],
                                        updateModelFunc: T => Unit,
                                        typeCheck: FormModel => Option[T],
                                        onFormFilledCallback: () => Unit = () => ()): Dialog.El =
      dialog
        .amendThis(th =>
          FormCachingTools.dialogBinders[T](formCaching, $sessionExpiredEvents, inputModel,
            $initialModel, updateModelFunc, typeCheck, onFormFilledCallback)(th)
        )
  }
}

