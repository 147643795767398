package root_pages.aurinko_pages.app.virtual_api.components

import com.github.uosis.laminar.webcomponents.material
import com.raquo.laminar.nodes.ReactiveHtmlElement
import com.raquo.laminar.api.L._
import org.scalajs.dom
import wvlet.log.Logger
import common.ui.animateVisibilityModifier
import cats.implicits.catsSyntaxOptionId
import common.airstream_ops.EventStreamOps

case class VirtualExpansionPanelComponent(
                                           header: ReactiveHtmlElement[dom.html.Element],
                                           body: Signal[Option[ReactiveHtmlElement[dom.html.Element]]] = Signal.fromValue(None),
                                         ) {
  private val log = Logger.of[VirtualExpansionPanelComponent]
  private val isExpanded: Var[Boolean] = Var(false)
  private val expandable: Signal[Boolean] = body.map(_.isDefined)

  val $isExpanded: Signal[Boolean] = isExpanded.signal

  val node: Div = div(
    common.ui.Attribute.Selector := log.getName,

    cls := "expansion-panel virtual-expansion-panel slds-m-bottom_medium",
    cls <-- expandable.map { case true => "expandable" case _ => "" },
    cls <-- isExpanded.signal.map { case true => "expanded" case _ => "" },

    div(
      cls := "header border-bottom--light",
      padding := "0rem 0.5rem",
      p(
        cls := "slds-grow",
        header,
        child.maybe <-- expandable.map {
          case true => material.Icon(
            _ => "expand_more",
            _ => cls := "lighter expand-icon  clickable mdc-theme--text-secondary-on-background no-right-margin",
            _ => display := "block",
            _ => textAlign := "center",
            _ => composeEvents(onClick.stopPropagation)(_
              .sample(isExpanded.signal.map[Boolean](!_))
              .logINFO(log)(isExpanded => s"expanded: $isExpanded"))
              --> isExpanded.writer,
          ).some
          case _ => None
        }
      )
    ),
    child.maybe <-- body.map {
      case Some(context) => div(
        cls := "body",
        cls <-- isExpanded.signal.map {
          case true => " border-bottom--light"
          case _ => ""
        },
        padding := "0rem 0.5rem",
        div(
          context,
          inContext(animateVisibilityModifier(isExpanded.signal)),
        )
      ).some
      case _ => None
    },
  )
}
