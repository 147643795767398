package service.http

import com.raquo.airstream.core.EventStream
import com.raquo.airstream.custom.{CustomSource, CustomStreamSource}
import com.raquo.airstream.web.AjaxEventStream
import io.circe.Json
import org.scalajs.dom
import org.scalajs.dom.ext.Ajax
import service.ui.spinner.{Spinner, SpinnerCaller}
import wvlet.log.Logger

import scala.scalajs.js.URIUtils

case class ApiRequester(apiUrl: String, validation: dom.XMLHttpRequest => String, spinnerService: Spinner) {

  val standardHeaders: Map[String, String] = Map("Content-Type" -> "application/json")

  def customStream(spinnerCaller: Option[SpinnerCaller]): EventStream[Unit] = CustomStreamSource[Unit]((fireValue, _, _, _) => {

    CustomSource.Config(
      onStart = () => {

        if (spinnerCaller.isDefined) {
          spinnerService.show(spinnerCaller.get)
        }

        fireValue(())
      },
      onStop = () => {
        if (spinnerCaller.isDefined) {
          spinnerService.hide(spinnerCaller.get)
        }

      }
    )
  })

  private val log = Logger.of[ApiRequester.type]

  def get(
           path: String,
           queryParams: Map[String, Option[String]] = Map.empty,
           headers: Map[String, String] = Map.empty,
           showSpinner: Boolean = true,
           withCredentials: Boolean = true
         ): EventStream[String] = {

    val spinnerCaller = if (showSpinner) Some(new SpinnerCaller) else None

    customStream(spinnerCaller)
      .flatMap(_ => AjaxEventStream.get(
        url = s"$apiUrl$path${toQueryString(queryParams)}",
        headers = headers ++ standardHeaders,
        withCredentials = withCredentials
      )
        .completeEvents
        .map { resp =>
          if (spinnerCaller.isDefined) {
            spinnerService.hide(spinnerCaller.get)
          }
          validation(resp)
        })

  }

  def post(
            path: String,
            body: Option[Json] = None,
            headers: Map[String, String] = Map.empty,
            showSpinner: Boolean = true,
            withCredentials: Boolean = true,
            queryParams: Map[String, Option[String]] = Map.empty,
          ): EventStream[String] = {

    val spinnerCaller = if (showSpinner) Some(new SpinnerCaller) else None

    customStream(spinnerCaller)
      .flatMap(_ => AjaxEventStream.post(
        url = s"$apiUrl$path${toQueryString(queryParams)}",
        data = body match { case None => "" case Some(b) => Ajax.InputData.str2ajax(b.toString) },
        headers = headers ++ standardHeaders,
        withCredentials = withCredentials
      )
        .completeEvents
        .map { resp =>
          if (spinnerCaller.isDefined) {
            spinnerService.hide(spinnerCaller.get)
          }
          validation(resp)
        })
  }

  def put(
           path: String,
           body: Option[Json] = None,
           headers: Map[String, String] = Map.empty,
           showSpinner: Boolean = true,
           withCredentials: Boolean = true
         ): EventStream[String] = {

    val spinnerCaller = if (showSpinner) Some(new SpinnerCaller) else None

    customStream(spinnerCaller)
      .flatMap(_ => AjaxEventStream.put(
        url = s"$apiUrl$path",
        data = body match { case None => "" case Some(b) => Ajax.InputData.str2ajax(b.toString) },
        headers = headers ++ standardHeaders,
        withCredentials = withCredentials
      )
        .completeEvents
        .map { resp =>
          if (spinnerCaller.isDefined) {
            spinnerService.hide(spinnerCaller.get)
          }
          validation(resp)
        })
  }

  def delete(
              path: String,
              headers: Map[String, String] = Map.empty,
              showSpinner: Boolean = true,
              withCredentials: Boolean = true
            ): EventStream[String] = {

    val spinnerCaller = if (showSpinner) Some(new SpinnerCaller) else None

    customStream(spinnerCaller)
      .flatMap(_ => AjaxEventStream.delete(
        url = s"$apiUrl$path",
        headers = headers ++ standardHeaders,
        withCredentials = withCredentials
      )
        .completeEvents
        .map { resp =>
          if (spinnerCaller.isDefined) {
            spinnerService.hide(spinnerCaller.get)
          }
          validation(resp)
        })
  }

  //
  def patch(
             path: String,
             body: Option[Json] = None,
             headers: Map[String, String] = Map.empty,
             showSpinner: Boolean = true,
             withCredentials: Boolean = true
           ): EventStream[String] = {

    val spinnerCaller = if (showSpinner) Some(new SpinnerCaller) else None

    customStream(spinnerCaller)
      .flatMap(_ => AjaxEventStream.patch(
        url = s"$apiUrl$path",
        data = body match { case None => "" case Some(b) => Ajax.InputData.str2ajax(b.toString) },
        headers = headers ++ standardHeaders,
        withCredentials = withCredentials
      )
        .completeEvents
        .map { resp =>
          if (spinnerCaller.isDefined) {
            spinnerService.hide(spinnerCaller.get)
          }
          validation(resp)
        })
  }

  def getFromCustomUrl[T](
                        path: String,
                        queryParams: Map[String, Option[String]] = Map.empty,
                        headers: Map[String, String] = Map.empty,
                        showSpinner: Boolean = false,
                        responseType: String = "",
                      ): EventStream[T] = {
    log.info(s"Get custom: $path ")
    val spinnerCaller = if (showSpinner) Some(new SpinnerCaller) else None

    def validate: dom.XMLHttpRequest => T = (resp: dom.XMLHttpRequest) => resp.status match {
      case c if c >= 200 && c < 300 => if (responseType == "") resp.responseText.asInstanceOf[T] else resp.response.asInstanceOf[T]
      case _ =>
        log.info(resp.status)
        throw new Exception(resp.responseText)
    }

    customStream(spinnerCaller)
      .flatMap(_ => AjaxEventStream.get(
        s"$path${toQueryString(queryParams)}",
        headers = headers ++ standardHeaders,
        withCredentials = true,
        responseType = responseType
      )
        .completeEvents
        .map { resp =>
          if (spinnerCaller.isDefined) {
            spinnerService.hide(spinnerCaller.get)
          }
          validate(resp)
        })

  }

  def toQueryString(params: Map[String, Option[String]]): String = {
    val str = (for {
      (k, v) <- params
      if v.getOrElse("").nonEmpty
    }
    yield {
      s"$k=${URIUtils.encodeURIComponent(v.get)}"
    }).mkString("&")

    str match {
      case s if s.nonEmpty => s"?$s"
      case _ => ""
    }
  }

  /*
  def withRetries(s: => EventStream[SyncStatus], count: Int, delayMs: Int): EventStream[SyncStatus] = {
    s.flatMap {
      case v if v.syncState == "IDLE" => EventStream.fromValue(v)
      case v =>
        if (count > 0)
          withRetries(s.delay(delayMs), count - 1, delayMs)
        else EventStream.fromValue(
          SyncStatus(
            v.userSyncEnabled,
            v.pkgCheckFailed,
            v.userLastSynced,
            "IDLE",
            v.lastRequest,
            Some(
              SyncError(
                "",
                None,
                Some("The sync seems to be taking longer than expected to start. Please try again later."),
                runtime = false,
                None,
                None,
                None,
                hide = false
              ) :: v.errors.getOrElse(List())
            )
          )
        )
    }
  }*/

}


