package common.ui.text_data_view

import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom.html.Paragraph
import wvlet.log.Logger

object ColoredStrings {

  private val log = Logger.of[ColoredStrings.type ]

  private val RandomColorPairs = //fontColor -> backgroundColor
    "#4285F4" -> "#E0EBFF" ::
      "#FF891C" -> "#FFDCBB" ::
      "#273E63" -> "#BDC8DB" ::
      "#3867B4" -> "#D8E7FF" ::
      "#FFFFFF" -> "#273E63" ::
      "#FFFFFF" -> "#0066CC" ::
      "#FFFFFF" -> "#3399ff" ::
      "#001A4D" -> "#E6EEFF" ::
      "#004D00" -> "#CCFFCC" ::
      "#FFFFFF" -> "#006600" ::
      "#005580" -> "#B3E6FF" ::
      "#800000" -> "#F0F0F5" ::
      "#FFFFFF" -> "#99B3E6" ::
      "#000000" -> "#E0EBEB" ::
      "#5A237B" -> "#F3D7FA" ::
      "#5A7B23" -> "#EFFAD7" ::
      "#237B4B" -> "#D7FAE5" ::
      "#8533FF" -> "#F0E6FF" ::
      "#FFFFFF" -> "#9FDF9F" ::
      "#3399FF" -> "#E6F2FF" ::
      "#004080" -> "#CCE6FF" ::
      "#0073E6" -> "#E6F2FF" ::
      "#E6B800" -> "#FFF5CC" ::
      "#FFFFFF" -> "#C6C6EC" ::
      "#333399" -> "#ECECF9" ::
      "#FFFFFF" -> "#FFCC99" ::
      "#00AAFF" -> "#E6F7FF" ::
      "#003399" -> "#CCDDFF" ::
      "#602040" -> "#F9ECF2" ::
      "#4D0000" -> "#FFE6E6" ::
      "#4D0026" -> "#FFE6F2" ::
      Nil

  object SemanticAccentColors {
    val error = "#A93828" -> "#FAD7DE"
    val success = "#237B4B" -> "#D7FAE5"
    val warning = "#A93828" -> "#FFA34D"
    val info = "#B380FF" -> "#F0E6FF"
    val info2 = "#4D79FF" -> "#E6ECFF"
    val disabled = "#808080" -> "#D9D9D9"
    val inactive = "#575B57" -> "#F2F2F2"
  }

  private def colorsForString(str: String, strIndex: Int): (String, String) = {
    if (strIndex <= RandomColorPairs.length - 1) {
      RandomColorPairs(strIndex)
    } else {
      RandomColorPairs(strIndex % (RandomColorPairs.length - 1))
    }
  }

  private def distributeColors(strings: List[String]): List[(String, (String, String))] =
    strings.zipWithIndex.map{case (str, index) => str -> colorsForString(str, index)}

  private def generateMap(strings: List[String]): collection.mutable.Map[String, () => ReactiveHtmlElement[Paragraph]] =
    collection.mutable.Map.from(distributeColors(strings)
      .map{ case (str, colors) => str -> (() => ColoredTextView(str, colors._1, colors._2))}
      .toMap
    )

  class ColoredStringsSet(elementsRenderers: collection.mutable.Map[String, () => ReactiveHtmlElement[Paragraph]]) {

    def renderFuncForText(text: String): () => ReactiveHtmlElement[Paragraph] = elementsRenderers.getOrElse(text, {
      val colors = colorsForString(text, elementsRenderers.length)
      val result = text -> (() => ColoredTextView(text, colors._1, colors._2))
      elementsRenderers.addOne(result)
      result._2
    })
  }

  def apply(strings: List[String]) = new ColoredStringsSet(generateMap(strings: List[String]))

  def bySemanticAccents(error: List[String] = Nil,
                        success: List[String] = Nil,
                        warning: List[String] = Nil,
                        info: List[String] = Nil,
                        info2: List[String] = Nil,
                        disabled: List[String] = Nil,
                        inactive: List[String] = Nil): ColoredStringsSet = {
    val fmap = collection.mutable.Map.from((
      error.map(str =>
        str -> (() => ColoredTextView(str, SemanticAccentColors.error._1, SemanticAccentColors.error._2))) ::
        success.map(str =>
          str -> (() => ColoredTextView(str, SemanticAccentColors.success._1, SemanticAccentColors.success._2))) ::
        warning.map(str =>
          str -> (() => ColoredTextView(str, SemanticAccentColors.warning._1, SemanticAccentColors.warning._2))) ::
        info.map(str =>
          str -> (() => ColoredTextView(str, SemanticAccentColors.info._1, SemanticAccentColors.info._2))) ::
        info2.map(str =>
          str -> (() => ColoredTextView(str, SemanticAccentColors.info2._1, SemanticAccentColors.info2._2))) ::
        disabled.map(str =>
          str -> (() => ColoredTextView(str, SemanticAccentColors.disabled._1, SemanticAccentColors.disabled._2))) ::
        inactive.map(str =>
          str -> (() => ColoredTextView(str, SemanticAccentColors.inactive._1, SemanticAccentColors.inactive._2))) ::
        Nil)
      .flatten
      .toMap)

    new ColoredStringsSet(fmap)

  }

}


