package root_pages.aurinko_pages.team.billing

import cats.implicits.{catsSyntaxOptionId, none}
import com.github.uosis.laminar.webcomponents.material.Icon
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.{BillingModelsOps, PortalTeam}
import common.BillingModels._
import common.airstream_ops.{EventStreamOps, SignalNestedOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.grid_configs.{GridConfig4, GridConfig5}
import common.ui.icons.{IconComponent, MaterialIcons}
import common.ui.notifications.InfoTextComponent
import common.ui.text_data_view
import common.ui.text_data_view.ColoredStrings
import org.scalajs.dom.html
import portal_router.{BillingInvoicePage, BillingPage, PortalRouter}
import service.apis.portal_api.PortalApi
import service.exception_handler.ApiNotFoundException
import service.portal_state.PortalState
import wvlet.log.Logger

import scala.util.{Failure, Success}

class BillingPageComponent($route: Signal[BillingPage],
                           portalApi: PortalApi,
                           portalRouter: PortalRouter,
                           portalState: PortalState) {

  private val log = Logger.of[BillingPageComponent]

  private val $defaultPlan: EventStream[Option[BillingPlan]] = portalState
    .$team.map(_.id).flatMap(id => portalApi.Billing.defaultPlan(id))
    .withErrorHandling{
      case _: ApiNotFoundException => log.warn("default plan is unavailable")
      case other => throw other
    }

  private val paymentMethod: Var[Option[PaymentMethod]] = Var(None)

  val UpComingInvoiceView = new UpComingInvoiceCard(portalState.$team, portalRouter, portalApi)
  val PaymentMethodView = new PaymentMethodComponent(portalApi, portalState, paymentMethod.signal, paymentMethod.writer)
  val BillingInvoicesView = new BillingInvoicesComponent(portalApi, portalState.$team.map(_.id), portalRouter)
  val DefaultPlanView = new DefaultTeamPlanSection(portalState.$team, portalApi, $defaultPlan.collect{case Some(plan) => plan}, couponDescription = paymentMethod.signal.subflatMap(_.couponDescription))

  val node: ReactiveHtmlElement[html.Div] = div(
    cls := "content-wrapper",
    div(
      cls := "nested-page content-padding",

      div(
        cls := "slds-grid slds-m-bottom--xx-large",
        p(
          cls := "title--level-1",
        "Billing"),
        cls := "",
          InfoTextComponent(p(
            cls := "slds-p-horizontal--large slds-p-vertical--medium slds-grid--vertical-align-center",
            "For any billing questions feel free to ",
            a("contact us",
              target := "_blank",
              href := "mailto:support@aurinko.io")
          ))
            .amend(cls := "slds-m-left--small")
      ),
      div(
        cls := "slds-grid slds-grid--vertical gap--xx-large",
        div(
          cls := "slds-grid",
          div(
            cls := "slds-size--1-of-3",
            UpComingInvoiceView()
              .amend(cls := "slds-m-right--x-large")
          ),
          div(
            cls := "slds-size--2-of-3",
            PaymentMethodView()
          )
        ),
        child.maybe <-- $defaultPlan.mapOptWhenTrue(_.nonEmpty)(DefaultPlanView()),
        BillingInvoicesView()
      )
    )
  )
}

class DefaultTeamPlanSection($team: Signal[PortalTeam],
                             portalApi: PortalApi,
                             $defaultPlan: EventStream[BillingPlan],
                             couponDescription: Signal[Option[String]]
                         ) {

  private val tierGridConfig: GridConfig4 = GridConfig4((3, 6, 1, 2))

  def apply(): ReactiveHtmlElement[html.Div] = div(

      div(
        cls := "slds-grid slds-m-top--medium gap--small slds-grid--vertical-align-center",
        p(
          cls := "title--level-2",
          child.text <-- $team.map(t => s"Billing for ${t.name}")
        ),

        p(
          child <-- $defaultPlan.map(p => text_data_view.ColoredTextView(
            p.name,
            text_data_view.ColoredStrings.SemanticAccentColors.inactive._1,
            text_data_view.ColoredStrings.SemanticAccentColors.inactive._2,
            circle = false
          )),
        ),

        child.maybe <-- couponDescription.signal.nestedMap(p(_)),

      ),
    div(
      cls := "slds-m-top--large slds-grid gap--large",
//      p(
//        cls := "table-header with-expansion-panels",
//        p(cls := tierGridConfig.classNames.col_1, "Tier"),
//        p(cls := tierGridConfig.classNames.col_2, "Features"),
//        p(cls := tierGridConfig.classNames.col_3, "Max features"),
//        p(cls := tierGridConfig.classNames.col_4 + " flex-right-block content-width", "Price")
//      ),
      children <-- $defaultPlan.map(_.tiers.map { tier =>

        div(
          cls := "slds-grid slds-grid--vertical slds-grid--vertical-align-center gap--medium block-border--grey slds-p-around--large growing-block",
          span(
            cls := "title--level-2",
            tier.priceD + "$"),
          tier.description.map(_.split("\n").map(span(_)).toList)

        )
        //        p(
        //            cls := "slds-grid slds-grid--vertical-align-center growing-block ",
//            p(cls := tierGridConfig.classNames.col_1, tier.name),
//            p(cls := tierGridConfig.classNames.col_2 + " slds-grid badge-container slds-wrap content-height",
//              tier.allowedFeatures.map(_.map(f => small(cls := "slds-col badge slds-m-left--xx-small", f)))),
//            p(cls := tierGridConfig.classNames.col_3, tier.maxFeatures.map(_.toString).getOrElse("").toString),
//            p(cls := tierGridConfig.classNames.col_4 + " flex-right-block content-width", tier.priceD + "$")
//          )
      })
    )
  )
}

class UpComingInvoiceCard($team: Signal[PortalTeam],
                          portalRouter: PortalRouter,
                          portalApi: PortalApi
                         ) {

  private val log = Logger.of[UpComingInvoiceCard]

  private val $invoice =
    $team
      .flatMap{
        case t if t.billingInfo.exists(_.paymentMethodConfigured) =>
        portalApi.Billing.getInvoice(t.id, "upcoming")
          .recoverToTry.map {
          case Success(inv) => inv.some
          case Failure(_: ApiNotFoundException) =>
            log.warn("Upcoming invoice is unavailable")
            None
          case Failure(exception) =>
            throw exception
        }
        case _ => none[BillingInvoice].streamed
      }
      .startWith(None)

  def apply(): ReactiveHtmlElement[html.Div] = div(
    cls := "block-gradient--light-orange slds-grid height-full",

    div(
      cls := "slds-size--1-of-2 slds-m-vertical--medium border-right--light",

      div(
        cls := "slds-m-horizontal--medium",
        p(
          cls := "text-small",
          "Amount"),
        p(
          cls := " slds-m-top--small",
          span(
            child.text <-- $invoice.nestedMap(i => BillingModelsOps.labelForCurrency(i.currency)).getOrElse(""),
            " "
          ),

          span(
              cls := "title--level-1",
              child.text <-- $invoice.nestedMap(_.amount.toString).getOrElse("-"))


        ))),

    div(
      cls := "slds-size--1-of-2 slds-m-vertical--medium slds-grid slds-grid--vertical-align-end",

      div(
        cls := "slds-size--2-of-3",
        div(
          cls := "slds-m-left--medium",
          p(
            cls := "text-small",
            "Active accounts"),
          p(
            cls := " slds-m-top--small title--level-1",
            span(
              child.text <-- $invoice.nestedMap(_.units).map {
                case Some(num) => num.toString
                case _ => "-"
              })))),

      child.maybe <-- $invoice.nestedMap(invoice => div(
        cls := "slds-size--1-of-3 slds-text-align--right content-height slds-p-right--medium border-box-sizing",
        a(
          cls := "text-underlined text-small",
          "Details",
          href <-- $team.map { t =>
            portalRouter.router.absoluteUrlForPage(BillingInvoicePage(t.id, invoice.getId))
          })
      )))
  )
}

class BillingInvoicesComponent(portalApi: PortalApi, $teamId: Signal[Int], portalRouter: PortalRouter) {

  val invoices: Var[List[BillingInvoice]] = Var(Nil)
  val firstRequestMany: Var[BillingApiMany[BillingInvoice]] = Var(BillingApiMany[BillingInvoice](None, None, 0 , Nil))
  val nextPageToken: Var[Option[String]] = Var(None)

  private def NoInvoicesView = div(
    cls := "block--light-orange slds-p-around--large slds-grid slds-grid--vertical gap--x-small slds-grid--vertical-align-center",
    p(
      cls := "title--level-2",
      "There is no historical usage data to show yet"),

    p("Once you've been using Aurinko for more than a month we'll be able to show you your monthly usage and invoice information.")

  )

  private def ShowMoreLessButtons = div(
    cls := "slds-grid slds-m-top--medium",

    div(visibility := "hidden", marginLeft := "auto"),

    child.maybe <-- nextPageToken.signal.nestedMap(pageToken => div(
      cls := "slds-grid blue clickable slds-grid--vertical-align-center",

      span("Show more"),
      Icon(
        _ => MaterialIcons.arrowBottom,
        _ => cls := "small blue"
      ),

      composeEvents(onClick)(_
        .sample($teamId)
        .flatMap {
          portalApi.Billing.getInvoices(_, pageToken.some)
        }) --> Observer.combine(
        nextPageToken.writer.contramap((many: BillingApiMany[BillingInvoice]) => many.nextPageToken),
        invoices.writer.contramap((many: BillingApiMany[BillingInvoice]) => invoices.now ::: many.records.toList)
      )
    )),

    div(
      cls := "flex-right-block",
      child.maybe <-- firstRequestMany.signal.map(_.nextPageToken)
      .combineWith(nextPageToken.signal)
      .mapOptWhenTrue{ case (first, current) => first != current }
      (Icon(
        _ => MaterialIcons.doubleArrowTop,
        _ => cls := "blue clickable",
        _ => composeEvents(onClick)(_
        .sample(firstRequestMany)) --> Observer.combine(
          nextPageToken.writer.contramap((many: BillingApiMany[BillingInvoice]) => many.nextPageToken),
          invoices.writer.contramap((many: BillingApiMany[BillingInvoice]) => many.records.toList)
        )
      ))
    )
  )

  private val gridSizing = GridConfig5((2, 1, 2, 2, 1))
  private val invoiceStatusesView = ColoredStrings.bySemanticAccents(
    error = BillingInvoiceStatus.unpaid.toString :: Nil,
    success = BillingInvoiceStatus.paid.toString :: Nil,
    info = BillingInvoiceStatus.upcoming.toString :: Nil
  )

  def apply(): ReactiveHtmlElement[html.Div] = div(

    p(
      cls := "title--level-2 border-bottom--light",
      "Invoices"),

    div(
      cls := "slds-m-top--large",

      child <-- invoices.signal.map(_.nonEmpty).map {
        case true =>

          div(
            cls := "data-table",
//            inContext(animateHeightModifier[List[BillingInvoice]](invoices.signal.changes)),

            div(
              cls := "table-header border-bottom--light",

              p(cls := gridSizing.classNames.col_1, "Number"),
              p(cls := gridSizing.classNames.col_2, "Due"),
              p(cls := gridSizing.classNames.col_3, "Status"),
              p(cls := gridSizing.classNames.col_4, "Period")

            ),
            children <-- invoices.signal.split(_.getId)((_: String, _: BillingInvoice, $invoice: Signal[BillingInvoice]) => {

              div(
                cls := "table-row border-bottom--light",
                p(
                  cls := gridSizing.classNames.col_1,
                  child.text <-- $invoice.map(_.number.getOrElse(""))),
                b(
                  cls := gridSizing.classNames.col_2,
                  child.text <-- $invoice.map(i => BillingModelsOps.labelForCurrency(i.currency) + i.amount)),

                p(
                  cls := gridSizing.classNames.col_3,
                  child <-- $invoice.map(_.status.toString).map(str => invoiceStatusesView.renderFuncForText(str)())
                ),

                p(
                  cls := gridSizing.classNames.col_4,
                  cls := "",
                  child.text <-- $invoice.map(BillingModelsOps.invoicePeriodString),

                ),
                p(
                  cls := "flex-right-block slds-text-align--right slds-grid" + gridSizing.classNames.col_5,
//                  child.maybe <-- $invoice.map(_.hostedUrl).nestedMap(url =>
//                    a(
//                      cls := "slds-m-right--small",
//                      Icon(_ => MaterialIcons.link, _ => cls := "blue small"),
//                      href := url,
//                      target := "_blank"
//                    )),
                  a(
                    cls := "text-underlined",
                    "Details",
                    href <-- $teamId
                    .combineWith($invoice)
                    .map { case (teamId, invoice) => portalRouter.router.absoluteUrlForPage(BillingInvoicePage(teamId, invoice.getId)) }
                )
                )
              )
            }),
            ShowMoreLessButtons
          )

        case false => NoInvoicesView

      }
    )
  ).amend(
    $teamId.flatMap(portalApi.Billing.getInvoices(_, None)) --> Observer.combine(
      nextPageToken.writer.contramap((many: BillingApiMany[BillingInvoice]) => many.nextPageToken),
      invoices.writer.contramap((many: BillingApiMany[BillingInvoice]) => many.records.toList),
      firstRequestMany.writer
    )
  )
}


