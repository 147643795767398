package root_pages.auth_pages.reset_password

import com.github.uosis.laminar.webcomponents.material.Button
import com.github.uosis.laminar.webcomponents.material.Textfield.El
import com.raquo.laminar.api.L._
import org.scalajs.dom
import root_pages.auth_pages.{ApiResponseOpps, AuthPagesLogo, emailTextfield}
import common.TextFieldOps
import common.ui.notifications.LocalMessagesView
import service.apis.portal_api.PortalApi
import wvlet.log.Logger

class ResetPasswordComponent(portalApi: PortalApi) {

  private val log = Logger.of[ResetPasswordComponent]

  val clickBus = new EventBus[dom.MouseEvent]

  val enterBus = new EventBus[dom.KeyboardEvent]

  val showSuccess: Var[Boolean] = Var(false)

  val input: El = emailTextfield()
  val valid = Var(false)

  val errorMessage: Var[Option[String]] = Var(None)

  def node: Div = {
    div(
      cls := "slds-align--absolute-center auth-page",
      div(
        cls := "form",
        AuthPagesLogo,
        child <-- showSuccess.signal.map {
          case false =>

            div(
              cls := "slds-p-vertical--medium slds-text-align--center",
              h2("Forgot password?"),
              p(
                "We will send you a link to reset your password.",
                cls := "slds-m-vertical--medium",
              ),

              LocalMessagesView(errorMessage.signal),
              //
              div(
                onKeyPress.filter(key => key.keyCode == 13) --> enterBus.writer,
                enterBus.events
                  .flatMap(_ => portalApi.resetPassword(input.ref.value))
                  .validateWithLocalError(errorMessage)
                  .mapTo(true) --> showSuccess.writer,
                input,
                onInput.mapTo(input.isValid) --> valid.writer,
              ),

                Button(
                  _.label := "Reset",
                  _.raised := true,
                  _ => cls := "secondary slds-size--1-of-1 slds-m-top--medium",
                  _ => onClick --> clickBus.writer,
                  _.disabled <-- valid.signal.map(!_),
                  _ => clickBus.events
                    .flatMap(_ => portalApi.resetPassword(input.ref.value))
                    .validateWithLocalError(errorMessage)
                    .mapTo(true) --> showSuccess.writer
                ),
              Button(
                _.label := "Cancel",
                _.outlined := true,
                _ => cls := "secondary slds-size--1-of-1 slds-m-top--small",
                _ => onClick --> Observer[dom.MouseEvent](_ => dom.window.history.back())
              ),

            )
          case true => div(
            cls := "slds-p-vertical--medium slds-text-align--center",
            p(
              "Please check your email and follow the instructions."
            )
          )
        }
      )

    )
  }
}
