package root_pages.aurinko_pages.app.virtual_api.helpers

import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import service.apis.dynamic_api.DataMapperModels.{ConfigurationModels, DescriptionModels, MappingModels, MetadataModels}
import service.apis.dynamic_api.DataMapperApi
import wvlet.log.Logger

import scala.util.{Failure, Success}

// TODO: rethink, reduce cache
case object cache {
  case object configuration {
    private val log = Logger.of[configuration.type]

    // This cache necessary to redirect between client (virtual) classes of the configuration, without reload the configuration
    private val $cache = Var[ConfigurationModels.Configuration](ConfigurationModels.Configuration())
    private val $cache_providerMetadata = Var[MetadataModels.Metadata](MetadataModels.Metadata())
    private val $cache_clientMetadata = Var[MetadataModels.Metadata](MetadataModels.Metadata())

    def LoadModel(
                   $appKey: Signal[common.AppKey],
                   $objectId: Signal[Long],
                   dynamicApi: DataMapperApi.API,
                 ): EventStream[ConfigurationModels.Configuration] =
      $appKey.combineWith($objectId).flatMap {
        case (_, configurationId) if configurationId > 0 && configurationId == $cache.now().id.getOrElse(0) =>
          EventStream.fromValue($cache.now()).map(m => {
            log.info(s"load model ${m.name.getOrElse("")} (#${m.id.get}) from cache")
            m
          })
        case (appKey, configurationId) if configurationId > 0 =>
          dynamicApi.configuration(appKey).item(configurationId).map(m => {
            log.info(s"load model ${m.name.getOrElse("")} (#${m.id.get})")
            $cache.set(m)
            m
          })
        case _ =>
          log.warn("can't load model")
          EventStream.fromValue[ConfigurationModels.Configuration](ConfigurationModels.Configuration())
      }

    def modelExtension(
                        $appKey: Signal[common.AppKey],
                        dynamicApi: DataMapperApi.API,
                      )(m: ConfigurationModels.Configuration):
    EventStream[(
      ConfigurationModels.Configuration,
        MetadataModels.Metadata,
        DescriptionModels.Description,
        MetadataModels.Metadata,
        MappingModels.Mapping,
        Seq[String], // wrong fields of provider and virtual metadata
        Seq[String], // wrong fields of mapping
      )] =
      $appKey.flatMap(appKey =>
        for {
          clientMetadata <- if (m.clientMetadataId.getOrElse[Long](0) > 0 && m.clientMetadataId.get == $cache_clientMetadata.now().id.getOrElse[Long](0))
            EventStream.fromValue(($cache_clientMetadata.now(), Nil))
          else if (m.clientMetadataId.getOrElse[Long](0) > 0)
            dynamicApi.metadata(appKey).item(m.clientMetadataId.get)
              .map(x => {
                $cache_clientMetadata.set(x)
                x
              })
              .recoverToTry
              .map {
                case Success(model) =>
                  (model, Nil)
                case Failure(exception) =>
                  exception match {
                    case DataMapperApi.NotImplementedFields(fields, message, `object`: MetadataModels.Metadata) =>
                      log.warn(s"incorrect fields, $message")
                      (`object`, fields)
                    case e => throw e
                  }
              }
          else
            EventStream.fromValue((MetadataModels.Metadata(name = m.clientMetadata), Nil))

          providerMetadata <- if (m.providerMetadataId.getOrElse[Long](0) > 0 && m.providerMetadataId.get == $cache_providerMetadata.now().id.getOrElse[Long](0))
            EventStream.fromValue(($cache_providerMetadata.now(), Nil))
          else if (m.providerMetadataId.getOrElse[Long](0) > 0)
            dynamicApi.metadata(appKey).item(m.providerMetadataId.get)
              .map(x => {
                $cache_providerMetadata.set(x)
                x
              })
              .recoverToTry
              .map {
                case Success(model) =>
                  (model, Nil)
                case Failure(exception) =>
                  exception match {
                    case DataMapperApi.NotImplementedFields(fields, message, `object`: MetadataModels.Metadata) =>
                      log.warn(s"incorrect fields, $message")
                      (`object`, fields)
                    case e => throw e
                  }
              }
          else
            EventStream.fromValue((MetadataModels.Metadata(name = m.providerMetadata), Nil))

          providerDescription <- if (m.providerDescription.getOrElse("").nonEmpty)
            EventStream.fromValue(DescriptionModels.Description(name = m.providerDescription))
          else
            description.LoadModel($appKey, Signal.fromValue(m.providerDescriptionId.getOrElse[Long](0)), dynamicApi)

          mapping <- mapping.LoadModel($appKey, m.mappingsId.getOrElse[Long](0), dynamicApi)
            .recoverToTry
            .map {
              case Success(model) =>
                (model, Nil)
              case Failure(exception) =>
                exception match {
                  case DataMapperApi.NotImplementedFields(fields, message, `object`: MappingModels.Mapping) =>
                    log.warn(s"incorrect fields, $message")
                    (`object`, fields)
                  case e =>
                    throw e
                }
            }
        } yield (m, providerMetadata._1, providerDescription, clientMetadata._1, mapping._1, (providerMetadata._2 ++ clientMetadata._2).distinct, mapping._2))

    def SaveModel(
                   $appKey: Signal[common.AppKey],
                   dynamicApi: DataMapperApi.API,
                   model: ConfigurationModels.Configuration,
                 ): EventStream[ConfigurationModels.Configuration] = {
      $appKey.flatMap(appKey => {
        val api = dynamicApi.configuration(appKey)
        if (model.id.getOrElse[Long](0) > 0) {
          log.info(s"update model ${model.name.getOrElse("")} (#${model.id.get})")
          api.update(model.id.get, model)
        } else {
          log.info(s"create model ${model.name.getOrElse("")}")
          api.create(model)
        }
      }).map(m => {
        log.info(s"update cache ${model.name.getOrElse("")} (#${model.id.getOrElse[Long](0)})")
        $cache.set(m)
        m
      })
    }
  }

  case object mapping {
    private val log = Logger.of[mapping.type]

    private val $cache = Var[MappingModels.Mapping](MappingModels.Mapping())

    def LoadModel(
                   $appKey: Signal[common.AppKey],
                   mappingId: Long,
                   dynamicApi: DataMapperApi.API,
                 ): EventStream[MappingModels.Mapping] =
      $appKey.flatMap {
        case _ if mappingId > 0 && mappingId == $cache.now().id.getOrElse(0) =>
          EventStream.fromValue($cache.now()).map(m => {
            log.info(s"load model ${m.name.getOrElse("")} (#${m.id.get}) from cache")
            m
          })
        case appKey if mappingId > 0 =>
          dynamicApi.mapping(appKey).item(mappingId).map(m => {
            log.info(s"load model ${m.name.getOrElse("")} (#${m.id.get})")
            $cache.set(m)
            m
          })
        case _ =>
          log.warn("can't load model")
          EventStream.fromValue[MappingModels.Mapping](MappingModels.Mapping())
      }

    def SaveModel(
                   $appKey: Signal[common.AppKey],
                   dynamicApi: DataMapperApi.API,
                   model: MappingModels.Mapping,
                 ): EventStream[MappingModels.Mapping] =
      $appKey.flatMap(appKey => {
        val api = dynamicApi.mapping(appKey)
        if (model.id.getOrElse[Long](0) > 0) {
          log.info(s"update model ${model.name.getOrElse("")} (#${model.id.get})")
          api.update(model.id.get, model)
        } else {
          log.info(s"create model ${model.name.getOrElse("")}")
          api.create(model)
        }
      }).map(m => {
        log.info(s"update cache ${model.name.getOrElse("")} (#${model.id.getOrElse[Long](0)})")
        $cache.set(m)
        m
      })
  }

  case object metadata {
    private val log = Logger.of[metadata.type]

    // This cache necessary to redirect between classes of  the metadata, without reload the metadata
    private val $cache = Var[MetadataModels.Metadata](MetadataModels.Metadata())

    def LoadModel(
                   $appKey: Signal[common.AppKey],
                   $objectId: Signal[Long],
                   dynamicApi: DataMapperApi.API,
                 ): EventStream[MetadataModels.Metadata] =
      $appKey.combineWith($objectId).flatMap {
        case (_, metadataId) if metadataId > 0 && metadataId == $cache.now().id.getOrElse(0) =>
          EventStream.fromValue($cache.now()).map(m => {
            log.info(s"load model ${m.name.getOrElse("")} (#${m.id.get}) from cache")
            m
          })
        case (appKey, metadataId) if metadataId > 0 =>
          dynamicApi.metadata(appKey).item(metadataId).map(m => {
            log.info(s"load model ${m.name.getOrElse("")} (#${m.id.get})")
            $cache.set(m)
            m
          })
        case _ =>
          log.warn("can't load model")
          EventStream.fromValue[MetadataModels.Metadata](MetadataModels.Metadata())
      }

    def SaveModel(
                   $appKey: Signal[common.AppKey],
                   dynamicApi: DataMapperApi.API,
                   model: MetadataModels.Metadata,
                 ): EventStream[MetadataModels.Metadata] =
      $appKey.flatMap(appKey => {
        val api = dynamicApi.metadata(appKey)
        if (model.id.getOrElse[Long](0) > 0) {
          log.info(s"update model ${model.name.getOrElse("")} (#${model.id.get})")
          api.update(model.id.get, model)
        } else {
          log.info(s"create model ${model.name.getOrElse("")}")
          api.create(model)
        }
      }).map(m => {
        log.info(s"update cache ${model.name.getOrElse("")} (#${model.id.getOrElse[Long](0)})")
        $cache.set(m)
        m
      })

    /**
     * LoadAllEx - Download standard and custom metadata and join it.
     * supported pagination on the custom metadata request
     */
    def LoadAllEx(
                   $appKey: Signal[common.AppKey],
                   dynamicApi: DataMapperApi.API,
                   search: Option[String] = None,
                   `type`: Signal[Option[MetadataModels.MetadataType.MetadataType]] = Signal.fromValue(None),
                   serviceType: Option[common.ServiceType] = None,
                   pageOffset: Int = 0,
                   pageSize: Int = DataMapperApi.standardApiPageSize,
                 ): EventStream[DataMapperApi.PageOf[MetadataModels.Metadata]] =
      $appKey
        .withCurrentValueOf(`type`)
        .flatMap(x => dynamicApi.metadata(x._1).standard.items(
          search = search,
          x._2,
          pageLimit = pageSize,
          serviceType = serviceType,
        ).map(p => (x._1, p, x._2)))
        .map {
          case (appKey, p, t) if pageOffset > 0 => (appKey, p, (pageOffset - p.records.length).max(0), pageSize, t)
          case (appKey, p, t) => (appKey, p, pageOffset, (pageSize - p.records.length).max(0), t)
        }
        .flatMap {
          case (appKey, p, offset, limit, t) =>
            dynamicApi.metadata(appKey).items(
              search = search,
              `type` = t,
              serviceType = serviceType,
              pageOffset = offset,
              pageLimit = limit,
            )
              .map(c => (p, c))
        }
        .map[DataMapperApi.PageOf[MetadataModels.Metadata]] {
          case (p, c) => // pagination fix
            DataMapperApi.PageOf[MetadataModels.Metadata](
              totalSize = c.totalSize + p.totalSize.max(p.records.length),
              offset = c.offset + p.offset.max(p.records.length),
              records = if (pageOffset == 0)
                p.records.map(x => MetadataModels.Metadata(
                  name = Some(x.name),
                  serviceType = x.serviceType,
                )) ++ c.records.take(pageSize - p.records.length)
              else
                c.records,
            )
          case _ =>
            DataMapperApi.PageOf[MetadataModels.Metadata](records = Nil)
        }
  }

  case object description {
    private val log = Logger.of[description.type]

    private val $cache = Var[DescriptionModels.Description](DescriptionModels.Description())

    def LoadModel(
                   $appKey: Signal[common.AppKey],
                   $objectId: Signal[Long],
                   dynamicApi: DataMapperApi.API,
                 ): EventStream[DescriptionModels.Description] =
      $appKey.combineWith($objectId).flatMap {
        case (_, descriptionId) if descriptionId > 0 && descriptionId == $cache.now().id.getOrElse(0) =>
          EventStream.fromValue($cache.now()).map(m => {
            log.info(s"load model ${m.name.getOrElse("")} (#${m.id.get}) from cache")
            m
          })
        case (appKey, descriptionId) if descriptionId > 0 =>
          dynamicApi.description(appKey).item(descriptionId).map(m => {
            log.info(s"load model ${m.name.getOrElse("")} (#${m.id.get})")
            $cache.set(m)
            m
          })
        case _ =>
          log.warn("can't load model")
          EventStream.fromValue[DescriptionModels.Description](DescriptionModels.Description())
      }

    def SaveModel(
                   $appKey: Signal[common.AppKey],
                   dynamicApi: DataMapperApi.API,
                   model: DescriptionModels.Description,
                 ): EventStream[DescriptionModels.Description] =
      $appKey.flatMap(appKey => {
        val api = dynamicApi.description(appKey)
        if (model.id.getOrElse[Long](0) > 0) {
          log.info(s"update model ${model.name.getOrElse("")} (#${model.id.get})")
          api.update(model.id.get, model)
        } else {
          log.info(s"create model ${model.name.getOrElse("")}")
          api.create(model)
        }
      }).map(m => {
        log.info(s"update cache ${model.name.getOrElse("")} (#${model.id.getOrElse[Long](0)})")
        $cache.set(m)
        m
      }
      )
  }
}
