package service.apis.sync_api

import com.raquo.laminar.api.L._
import common.SyncRuleType.SyncRuleType
import common.{AppKey, CalendarQuery, CirceStringOps, ContactsQuery, EmailsQuery, FullSyncApiBody, SyncApiPage, SyncObjFilters, SyncOrganization, SyncRuleType, SyncService, SyncStatus, SyncUser, SyncUserRule, SyncUserSettings, TasksQuery, TemplateGroup, TemplateSyncSettings}
import io.circe.syntax.EncoderOps
import io.circe.generic.auto.{exportDecoder, exportEncoder}
import io.circe.syntax.EncoderOps
import io.circe.{Decoder, Encoder, Json, KeyDecoder, KeyEncoder}
import io.circe.Encoder.encodeMap
import org.scalajs.dom
import service.apis.API
import service.http.ApiRequester
import service.ui.spinner.Spinner
import wvlet.log.Logger

import java.time.Instant
import scala.util.Try

class SyncApi(apiUrl: String, spinnerService: Spinner) extends API {

  private val apiRequester: ApiRequester = ApiRequester(apiUrl, validateResponse, spinnerService)
  private val log = Logger.of[ApiRequester.type]

//  val previousRoute

  private val organization: Var[Option[SyncOrganization]] = Var(None)
  val $maybeOrg: StrictSignal[Option[SyncOrganization]] = organization.signal
  val $organization: Signal[SyncOrganization] = $maybeOrg.signal.map{
    case None => throw new Exception("No sync organization")
    case Some(value) => value
  }
  def setOrg(org: SyncOrganization): Unit = organization.set(Some(org))
  def resetOrg(): Unit = organization.set(None)

//  val standardApiPageSize = 30
//  val standardPageSize = 15

  val standardApiPageSize = 50
  val standardPageSize = 30

  def validateResponse(resp: dom.XMLHttpRequest): String = resp.status match {
    case c if c >= 200 && c < 300 => resp.responseText
    case 401 =>
      log.info(s"${resp.status}: sync-srv not authorized")
      if (
        Try(resp.getResponseHeader("X-Aurinko-UpstreamReached").toLowerCase).getOrElse(false) != "true" &&
          Try(resp.getResponseHeader("X-Yoxel-Upstream-Reached").toLowerCase).getOrElse(false) != "true") {

        throw service.exception_handler.NotAuthorizedException(
          "Sync not authorized",
          username = Option(resp.getResponseHeader("X-Aurinko-UsernameHint"))
        )

      } else {
        throw NotAuthorizedSyncException()
      }
    case _ =>
      log.warn(s"${resp.status}: ${resp.responseText}")
      throw SyncApiException(code = resp.status, message = resp.responseText)
  }

  def organizations(appKey: AppKey,
                    search: String,
                    limit: Int = standardApiPageSize,
                    offset: Int = 0,
                    sort: Option[String] = None): EventStream[SyncApiPage[SyncOrganization]] = apiRequester
    .get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs", queryParams = Map(
      "limit" -> Some(s"$limit"),
      "offset" -> Some(s"$offset"),
      "search" -> Option.when(search.nonEmpty) {
        search
      },
      "sort" -> Option.when(sort.getOrElse("").trim.nonEmpty) {
        sort.get
      }
    )).map(_.decodeAs[SyncApiPage[SyncOrganization]])

  //В дарте boolean параметр добавлен но он во всем проекте всегда true и его наличие не совсем понятно зачем
  def syncOrg(appKey: AppKey, orgId: String): EventStream[SyncOrganization] = apiRequester
    .get(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}",
      headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
    )
    .map(_.decodeAs[SyncOrganization])

  def syncOrgFromHeroku(appKey: AppKey, orgExtId: String): EventStream[SyncOrganization] = apiRequester.get(s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs",
      headers = Map("X-Aurinko-ApiType" -> "v2h"),
      queryParams = Map("extId" -> Some(orgExtId))
    ).map(_.decodeAs[SyncApiPage[SyncOrganization]].summary.headOption.getOrElse(throw new Exception(s"Org with extId $orgExtId is not found")))

  def updateOrgInfo(appKey: AppKey, orgId: String, org: SyncOrganization): EventStream[SyncOrganization] = apiRequester.put(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}",
      body = Some(org.asJson.deepDropNullValues),
      headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
    )
    .map(_.decodeAs[SyncOrganization])

  def syncUsers(appKey: AppKey, orgId: String, filters: SyncObjFilters): EventStream[SyncApiPage[SyncUser]] = apiRequester.get(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users",
      queryParams = filters.toMap,
      headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
    )
    .map(_.decodeAs[SyncApiPage[SyncUser]])

  def syncUser(appKey: AppKey, orgId: String, userId: Int): EventStream[SyncUser] = apiRequester.get(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/$userId",
        headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
    )
    .map(_.decodeAs[SyncUser])

  def updateUser(appKey: AppKey, orgId: String, su: SyncUser): EventStream[SyncUser] = apiRequester.put(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/${su.id}",
    body = Some(su.asJson.deepDropNullValues),
    headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
  )
    .map(_.decodeAs[SyncUser])

  def syncUserSettings(appKey: AppKey, orgId: String, userId: Int): EventStream[Option[SyncUserSettings]] = apiRequester.get(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/$userId/syncSettings",
    headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
  ).map(_.decodeAs[Option[SyncUserSettings]])


  def postStatus(appKey: AppKey, orgId: String, userId: Int, fullResync: Option[FullSyncApiBody]): EventStream[Unit] = apiRequester.post(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/$userId/sync",
    body = if(fullResync.isDefined) Some(fullResync.get.asJson) else Some(Map("initial" -> false).asJson),
    headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
  )
    .map(_ => ())

  def syncStatus(appKey: AppKey, orgId: String, userId: Int): EventStream[SyncStatus] = apiRequester.get(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/$userId/sync",
      showSpinner = false,
      headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
    ).map(_.decodeAs[SyncStatus])

  def services(appKey: AppKey, orgId: String, userId: Int): EventStream[List[SyncService]] = apiRequester.get(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/$userId/services",
    headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
  )
    .map(_.decodeAs[List[SyncService]])

  def updateService(appKey: AppKey, orgId: String, userId: Int, service: SyncService): EventStream[SyncService] = apiRequester.put(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/$userId/services/${service.id}",
      body = Some(service.asJson),
      headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
    )
    .map(_.decodeAs[SyncService])

  def ruleQuery(appKey: AppKey, orgId: String, userId: Int, ruleType: SyncRuleType, filters: SyncObjFilters): EventStream[SyncApiPage[_ >: CalendarQuery with ContactsQuery with EmailsQuery with TasksQuery <: Product]] = apiRequester
    .get(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/$userId/rules/$ruleType/query",
      queryParams = filters.toMap,
      headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
    )
    .map(data => ruleType match {
      case SyncRuleType.Calendar => data.decodeAs[SyncApiPage[CalendarQuery]]
      case SyncRuleType.Contacts => data.decodeAs[SyncApiPage[ContactsQuery]]
      case SyncRuleType.Emails => data.decodeAs[SyncApiPage[EmailsQuery]]
      case _ => data.decodeAs[SyncApiPage[TasksQuery]]
    })

  def ruleObject(appKey: AppKey, orgId: String, userId: Int, ruleType: SyncRuleType, objId: Int): EventStream[Product] = apiRequester
    .get(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/$userId/rules/$ruleType/objects/$objId",
      headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
    )
    .map(data => ruleType match {
      case SyncRuleType.Calendar => data.decodeAs[CalendarQuery]
      case SyncRuleType.Contacts => data.decodeAs[ContactsQuery]
      case SyncRuleType.Emails => data.decodeAs[EmailsQuery]
      case _ => data.decodeAs[TasksQuery]
    })

  def userRules(appKey: AppKey, orgId: String, userId: Int): EventStream[List[SyncUserRule]] = apiRequester
    .get(
      s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/users/$userId/rules",
      headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
    )
    .map(data => data.decodeAs[List[SyncUserRule]])

  def templateGroups(appKey: AppKey, orgId: String): EventStream[List[TemplateGroup]] = apiRequester.get(
    s"/teams/${appKey.teamId}/apps/${appKey.appId}/sync_proxy/partner/orgs/${orgId.split("_h").head}/templateGroups",
    headers = if (orgId.endsWith("_h")) Map("X-Aurinko-ApiType" -> "v2h") else Map.empty
  )
    .map(_.decodeAs[List[TemplateGroup]])

  /*
  def repeatGet(appKey: AppKey, orgId: Int, userId: Int, repeat: Int, delayMs: Int, withSpinner: Boolean = true, f: String => ()): EventStream[SyncStatus] =
    apiRequester.withRetries(syncStatus(appKey, orgId, userId), repeat, delayMs)
*/

  /*
  def updateUser(appKey: AppKey, orgId: Int, userId: Int, body: EditableUser): EventStream[SyncUser] = apiRequester
    .put(s"teams/${appKey.teamId}/apps/${appKey.teamId}/sync_proxy/partner/orgs/$orgId/users/$userId", body = Some(body.asJson)).map(_.decodeAs[SyncUser])
*/
  implicit val ldtDecoder: Decoder[Instant] = Decoder[Long].map(Instant.ofEpochMilli)
  implicit val ldtEncoder: Encoder[Instant] = Encoder[Long].contramap(_.toEpochMilli)

  override def ping: EventStream[Unit] = apiRequester
    .post(s"/user/ping")
    .mapTo(())
}


case class SyncApiException(code: Int, message: String, content: Option[String] = None) extends Exception("Sync api exception") {
  override
  def toString = s"$message"
}

case class NotAuthorizedSyncException() extends Exception("Sync not authorized")


//{appId}/orgs/{oid}/users/{actionUid}/syncSettings
