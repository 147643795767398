package service.clipboard_service

import com.raquo.laminar.api.L._
import org.scalajs.dom.document

import scala.scalajs.js

trait ClipboardService {
  def copy(text: String): Unit
}

case class ClipboardServiceImp() extends ClipboardService {
  override def copy(text: String): Unit = {
    area.ref.value = text
    area.ref.select
    document.execCommand("copy")
    area.ref.value = ""
  }

  val area: TextArea = textArea(
    height := "0",
    width := "0",
    opacity := "0",
    padding := "0",
    margin := "0",
    position := "fixed"
  )
}
