package root_pages.auth_pages.new_password

import com.github.uosis.laminar.webcomponents.material.Button
import com.raquo.laminar.api.L._
import common.ui.notifications.LocalMessagesView
import common.ui.secret_input.SecretInputComponent
import org.scalajs.dom
import root_pages.auth_pages.{ApiResponseOpps, AuthPagesLogo}
import portal_router.{LoginPage, NewPasswordPage, PortalRouter}
import service.apis.portal_api.PortalApi
import wvlet.log.Logger

import java.time.Instant

class NewPasswordComponent($page: Signal[NewPasswordPage], portalApi: PortalApi, portalRouter: PortalRouter) {

  private val log = Logger.of[NewPasswordComponent]

  val message: Var[Option[String]] = Var(None)

  def validateTimestamp(p: NewPasswordPage): Unit = {
    val t = p.timestamp.toLong * 1000
    log.info(t)
    val tmstmp = Instant.ofEpochMilli(t)

    val now = Instant.now
    log.info(s"timestamp ${p.timestamp} $tmstmp now $now")
    if(now.isAfter(tmstmp)) {
      message.set(Some("The reset link is expired."))
    }
  }

  val passwordInput: SecretInputComponent = SecretInputComponent(label = "New password", newPassword = true)

  val submitBus = new EventBus[dom.MouseEvent]
  val submitObserver: Observer[Unit] = Observer[Unit](onNext = _ => {
    log.info("Submit")
    portalRouter.navigate(LoginPage())
  })

  val errorMessage: Var[Option[String]] = Var(None)

  val node: Div = div(
    child <-- $page.map{page => {
      validateTimestamp(page)
      div(
        cls := "slds-align--absolute-center auth-page",
        submitBus.events
          .flatMap(_ => portalApi.newPassword(page.key, passwordInput.value.now))
          .validateWithLocalError(errorMessage)
          .map(_ =>  message.set(Some("Success! We will redirect you to Sign In page in 5 seconds.")))
          .compose(c => c.delay(5000))
          --> submitObserver,
        div(
          cls := "form",
          AuthPagesLogo,
          div(
            cls := "slds-p-vertical--medium slds-text-align--center",
            h2("Set new password", cls := "slds-m-bottom--large"),

            LocalMessagesView(errorMessage.signal),

            child <-- message.signal.map {
              case Some(mes) => p(mes)
              case None =>
                div(

                  cls := "slds-p-vertical--medium",
                  passwordInput.node,
                  Button(
                    _.label := "Submit",
                    _.raised := true,
                    _ => cls := "secondary slds-m-top--x-large",
                    _ => onClick --> submitBus.writer,
                    _.disabled <-- passwordInput.valid.signal.map(!_)
                  )

                )
            }
          )
        )
      )
    }}
  )
}
