package root_pages.aurinko_pages.app.sync

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Checkbox, Dialog, Fab, Formfield, Icon, Radio, Select, Textfield}
import portal_router.{Page, PortalRouter, SyncOrgPage, SyncOrgsPage, SyncUserPage}
import com.raquo.laminar.api.L._
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.forms.FormsLocalExceptionHandling
import common.ui.breadcrumbs.{BreadcrumbsComponent, BreadcrumbsItem}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.mat_components_styles.{fixFabStyle, fixMwcDialogOverflow}
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.ui.element_binders.DialogModifying
import common.forms.{CheckboxFormOps, RadioFormOps, SelectFormOps, TextfieldFormOps}
import common.{AppKey, FormModel, InstantOps, JsonEnum, PingTools, SyncApiPage, SyncDirection, SyncObjFilters, SyncOrgEditModel, SyncOrganization, SyncServiceType, SyncUser, TeamMemberRole, nameFieldPattern}
import org.scalajs.dom
import service.apis.sync_api.SyncApi
import service.portal_state.{PortalState, TeamMemberAccess}
import service.scroll_ops.ScrollOps

import scala.util.Try

class SyncOrgComponent($route: Signal[SyncOrgPage],
                       syncApi: SyncApi,
                       documentScrollOps: ScrollOps,
                       portalRouter: PortalRouter,
                       portalState: PortalState
                      ) {
  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)

  val searchVar: Var[String] = Var("")

  val reloadUsersBus = new EventBus[Unit]

  val editModel: Var[Option[SyncOrgEditModel]] = Var(None)
  val modelBus: EventBus[SyncOrganization] = new EventBus[SyncOrganization]
  val $users: EventStream[SyncApiPage[SyncUser]] = reloadUsersBus.events.sample($route).flatMap {
    route => {
      portalRouter.router.replaceState(
        SyncOrgPage(
          route.appKey,
          route.orgId,
          search = Option.when(filters.searchText.now.trim.nonEmpty) {
            filters.searchText.now
          },
          pageNum = Some(filters.pageNum.now())
        )
      )
      syncApi.syncUsers(
        route.appKey,
        route.orgId,
        filters,
      )
    }
  }

  def userBadges(user: SyncUser): Div = div(
    cls := "slds-grid slds-wrap badge-container",
    if (user.disabled) small(cls := "badge orange slds-m-left--xx-small", "disabled") else None,
    if (user.syncError) small(cls := "badge red slds-m-left--xx-small", "Error") else None,
    if (user.admin) small(cls := "badge slds-m-left--xx-small", "Admin") else None,
    if (user.syncEnabled) small(cls := "badge slds-m-left--xx-small", "Sync enabled") else None,
    if (user.pkgCheckFailed.getOrElse(false)) small(cls := "badge slds-m-left--xx-small", "Package issues") else None,
  )

  val navigateToUser: Observer[(AppKey, String, Int)] = Observer[(AppKey, String, Int)](onNext = tuple =>
    portalRouter.navigate(SyncUserPage(tuple._1, tuple._2, tuple._3))
  )

  val navigationObserver: Observer[Page] = Observer[Page](onNext = route => portalRouter.navigate(route))

  val filters: SyncObjFilters = SyncObjFilters(limit = syncApi.standardPageSize)

  def editPopup(org: SyncOrganization): Dialog.El = Dialog(
    _.open <-- editModel.signal.map(_.isDefined),
    _.onClosing.mapTo(None) --> editModel,
    _.heading <-- syncApi.$organization.map(org => s"${org.name} ${org.domain}"),
    _ => child.maybe <-- editModel.signal.nestedMap { model =>

      div(
        div(
          FormsLocalExceptionHandling.errorView(model.formError.signal),
          Textfield(
            _ => cls := "slds-size--1-of-1 slds-m-vertical--medium",
            _.outlined := true,
            _.label := "Name",
            _.pattern := nameFieldPattern,
            _.value <-- model.name,
            _ => onInput.mapToValue --> model.name
          ).bindToForm(model.formState),

          Textfield(
            _.label := "Support email",
            _ => cls := "slds-size--1-of-1 slds-m-vertical--medium",
            _.outlined := true,
            _.value <-- model.supportEmail,
            _ => onInput.mapToValue --> model.supportEmail,
            _.`type` := "email",
            _.required := true
          ).bindToForm(model.formState),

          model.dfltTemplate match {
            case Some(template)
              if template.source.`type` == SyncServiceType.repfabric || template.source.`type` == SyncServiceType.teamwork =>
              Textfield(
                _.outlined := true,
                _.label := "Discount %",
                _.pattern := """^\d+(?:[.,]\d+)?$""",
                _ => controlled(
                  value <-- model.percent.signal.map(_.toString),
                  onInput.mapToValue.map(_.filter(f => Character.isDigit(f) || f == '.' || f == ',')) --> model.percent
                ),
              ).bindToForm(model.formState)
            case _ => None
          }
        ),

        div(
          cls := "slds-p-top--small",
          small(cls := "gray", "Status")
        ),
        div(
          cls := "slds-grid slds-grid--vertical-align-center",

          Formfield(
            _ => cls := "slds-m-right--x-large",
            _.label := "Active",

            _.slots.default(Radio(
              _.checked <-- model.options.$boolean,
              _.onChange.mapToChecked --> Observer[Boolean](onNext = model.options.update),
            ).bindToForm(model.formState, $externalChange = model.options.$boolean.changes.mapTo(())))
          ),

          Formfield(
            _.label := "Inactive",

            _.slots.default(Radio(
              _.checked <-- model.options.$boolean.map(!_),
              _.onChange.mapToChecked.map(!_) --> Observer[Boolean](onNext = model.options.update),
            ).bindToForm(model.formState, $externalChange = model.options.$boolean.changes.mapTo(())))
          )
        ),

        children <-- editModel.signal
          .map(_.map(x => x.defaultSyncSettings -> x.dfltTemplate).map {
            case (Some(defaultSyncSettings), Some(_)) => (defaultSyncSettings.syncCalendar, "Sync calendar", defaultSyncSettings.calSyncDirection) ::
              (defaultSyncSettings.syncContacts, "Sync contacts", defaultSyncSettings.contSyncDirection) ::
              (defaultSyncSettings.syncTasks, "Sync tasks", defaultSyncSettings.taskSyncDirection) ::
              Nil
            case _ => Nil
          })
          .nestedMap(_.map {
            case (sync, label, direction) =>
              div(
                cls := "slds-grid slds-grid--vertical-align-center slds-m-top--x-small",
                div(cls := "slds-size--1-of-3 slds-grid slds-grid--vertical-align-center slds-m-right--large",
                  Checkbox(
                    _.checked <-- sync.signal,
                    _ => onChange.mapToChecked --> sync,
                  ).bindToForm(model.formState),
                  label

                ),
                div(cls := "slds-grow",
                  Select(
                    _ => cls := "slds-size--1-of-1",
                    _.outlined := true,
                    _.label := "Sync direction",
                    _.disabled <-- sync.signal.map(!_),
                    _.value <-- direction.signal.map(_.generateLabel(model.dfltTemplate.get.source.`type`.label)),

                    _ => SyncDirection.selectableValues.map(dir => ListItem(
                      _.value := dir.generateLabel(model.dfltTemplate.get.source.`type`.label),
                      _ => dir.generateLabel(model.dfltTemplate.get.source.`type`.label),
                      _.selected <-- direction.signal.map(_ == dir),
                      _ => onClick.mapTo(dir) --> direction
                    ))
                  ).bindToForm(model.formState)),
              )
          }).getOrElse(Nil)
      )
    },
    _.slots.primaryAction(ButtonsPairComponent[SyncOrganization, dom.MouseEvent](
      primaryDisabled = editModel.signal
        .flatMap(_.$traverse(_.formState.$submitAllowed))
        .map(!_.contains(true)),

      secondaryObserver = editModel.writer.contramap((_: dom.MouseEvent) => None),

      primaryEffect = () => ()
        .streamed
        .sample(editModel.signal)
        .collect {
          case Some(model) => model
        }
        .withCurrentValueOf($route)
        .flatMap { case (model, route) => syncApi.updateOrgInfo(route.appKey, route.orgId, model.toApiModel) }
        .withErrorHandlingAndCollect(
          FormsLocalExceptionHandling
            .handler(str => editModel.now.foreach(_.formError.set(str.some)))),

      primaryObserver = Observer[SyncOrganization](onNext = org => {
        syncApi.setOrg(org)
        editModel.set(None)
      }),
    ).node
    ),
  )
    .withPing(syncApi)
    .withFormCaching(
      portalState.FormCache,
      portalState.Session.$sessionExpiredEvents,
      editModel,
      org.toEditableModel.signaled,
      (m: SyncOrgEditModel) => {
        editModel.now.foreach(_.update(m))
      },
      (cashedForm: FormModel) => cashedForm match {
        case org: SyncOrgEditModel => org.some
        case _ => None
      },
      () => editModel.now.foreach(_.formState.validate())
    )

  val node: Div = div(
    cls := "x-nested-page",
    div(
      cls := "nav-filters-container",
      BreadcrumbsComponent(
        BreadcrumbsItem("Sync organizations".signaled,
          $route.map(r => portalRouter.router.absoluteUrlForPage(SyncOrgsPage(r.appKey))).some),

        BreadcrumbsItem(syncApi.$organization.map(_.name), None)
      )).amend(cls <-- documentScrollOps.$scrolled
      .map { case true => "shadow" case _ => "" }),
    child <-- syncApi.$organization.map(org =>
      div(
        cls := "content-padding",
        editPopup(org),
        div(
          cls := "left-section",
          paddingTop <-- documentScrollOps.$scrollTop.changes
            .debounce(0).map(i => s"calc(var(--au-header-height) - ${i}px)"),

          children <-- syncApi.$organization.map(org => {

            div(
              cls := "left-section-header",
              div(
                cls := "slds-grid slds-grid--align-spread ",
                div(
                  cls := "slds-m-bottom--medium",
                  div(
                    cls := "slds-grid slds-grid--vertical",
                    p(s"${org.name}", cls := "title--level-1"),
                    p(s"${org.domain}", cls := "subtitle--level-3")
                  ),
                  if (org.useAurinko) small(cls := "badge", "use aurinko") else None
                ),
                child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
                  .andThen(Icon(
                  _ => cls := "light medium clickable slds-m-top--x-small mat-outlined",
                  _ => "edit",
                  _ => composeEvents(onClick)(_.sample(syncApi.$organization).map(_.toEditableModel).map(Some(_))) --> editModel
                ))
              )
            ) ::
              div(
                cls := "content-container",
                div(
                  cls := "content",
                  div(
                    cls := "border-top--light border-bottom--light",

                    div(
                      cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                      small(cls := "gray", "Id"),
                      span(org.id),
                    ),

                    div(
                      cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                      small(cls := "gray", "Support email"),
                      span(org.supportEmail),
                    ),

                    div(
                      cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                      small(cls := "slds-col slds-size--1-of-3  gray", "External id"),
                      span(org.extId)
                    ),


                    div(
                      cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                      small(cls := "gray", "Status"),
                      span(if (org.isActive) "Active" else "Inactive")
                    ),


                    if (org.defaultSyncSettings.isDefined && org.dfltTemplate.isDefined && org.defaultSyncSettings.get.syncCalendar)
                      div(
                        cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                        small(cls := "gray", "Calendar sync direction"),
                        span(org.defaultSyncSettings.get.calSyncDirection.getOrElse(SyncDirection.BOTH).generateLabel(org.dfltTemplate.get.`type`.label)),

                      ) else None,

                    if (org.defaultSyncSettings.isDefined && org.dfltTemplate.isDefined && org.defaultSyncSettings.get.syncContacts)
                      div(
                        cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                        small(cls := "gray", "Contacts sync direction"),
                        span(org.defaultSyncSettings.get.contSyncDirection.getOrElse(SyncDirection.BOTH).generateLabel(org.dfltTemplate.get.`type`.label)),

                      ) else None,

                    if (org.defaultSyncSettings.isDefined && org.dfltTemplate.isDefined && org.defaultSyncSettings.get.syncTasks)
                      div(
                        cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                        small(cls := "gray", "Tasks sync direction"),
                        span(org.defaultSyncSettings.get.taskSyncDirection.getOrElse(SyncDirection.BOTH).generateLabel(org.dfltTemplate.get.`type`.label)),

                      ) else None,

                    div(
                      cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                      small(cls := "gray", "Registered at"),
                      span(org.regDate.toPrettyLocalFormat)
                    )
                  )
                )
              ) :: Nil

          }
          )
        ),
        div(
          cls := "right-section slds-m-top--large",
          div(
            cls := "",
            div(
              span(cls := "title--level-2", "Users"),
            ),
            div(
              cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread slds-m-top--medium",

              div(
                cls := "slds-grid slds-grid--vertical-align-center",
                SyncOrgUsersFilters.All.map(filter =>
                  Fab(
                    _.label := filter.label,
                    _.extended := true,
                    _.showIconAtEnd := true,
                    _.icon <-- filters.$filters.map(_.contains(filter.name)).map { case true => "cancel" case false => "" },
                    _ => cls := "slds-m-right--x-small",
                    _ => cls <-- filters.$filters.map(_.contains(filter.name)).map { case true => "" case _ => "inactive" },
                    _ => onClick --> Observer[dom.MouseEvent](onNext = _ => filters.toggleFilter(filter.name)),
                    _ => onMountCallback(fixFabStyle)
                  )
                )
              ),
              SearchInputComponent(onChange = filters.searchText.writer).node
            ),

            div(
              cls := "data-table slds-m-top--medium",

              p(
                cls := "table-header",
                span(cls := "slds-size--1-of-12  gray", "Id"),
                span(cls := "slds-size--3-of-12  gray", "Name"),
                span(cls := "slds-size--3-of-12  gray", "Email"),
                span(cls := "slds-size--2-of-12  gray", "Template group"),
                span(cls := "slds-size--3-of-12  gray", "Flags")
              ),

              children <-- $users.map(_.summary).split(_.id)(
                (_: Int, _: SyncUser, $user: EventStream[SyncUser]) =>
                  div(
                    cls := "table-row panel-like clickable",
                    span(cls := "slds-size--1-of-12", child.text <-- $user.map(_.id)),
                    span(cls := "slds-size--3-of-12 text-bolder", child.text <-- $user.map(_.name.getOrElse(""))),
                    span(cls := "slds-size--3-of-12", child.text <-- $user.map(_.email.getOrElse(""))),
                    i(cls := "slds-size--2-of-12 text-light", child.text <-- $user.map(_.templGroupName.getOrElse(""))),
                    span(cls := "slds-size--3-of-12", child <-- $user.map(userBadges)),
                    composeEvents(onClick)(_.combineWith($user).withCurrentValueOf($route).map { case (_, user, route) => (route.appKey, route.orgId, user.id) }) --> navigateToUser
                  )
              ),

              div(
                cls <-- $users.map(_.total > syncApi.standardPageSize).map { case true => "" case _ => "hidden" },
                Paginator(
                  pageNum = filters.pageNum.signal,
                  totalCount = $users.map(_.total).startWith(0),
                  pageSize = filters.pagesLimit.signal,
                  onPageChange = Observer[(Int, Int)](onNext = i => {
                    filters.pageNum.set(i._1)
                    filters.pagesLimit.set(i._2)
                    reloadUsersBus.emit(())
                  }),
                  documentScrollTopAfterPageChange = true,
                  documentScrollOps.some,
                  itemsPluralLabel = "Sync Users"
                ).node
              ),

              div(
                span("No users", cls := "gray"),
                cls := "slds-grid slds-grid--align-center slds-m-top--large",
                cls <-- $users.map(_.summary.isEmpty).map { case true => "" case _ => "hidden" },
              )
            ),
            filters.$filters
              .combineWith(filters.searchText.signal) --> Observer[(Set[String], String)](onNext = _ => {
              filters.pageNum.set(0)
              reloadUsersBus.emit(())
            }),
          )
        )
      ),
    ),
  )
}

object SyncOrgUsersFilters extends Enumeration with JsonEnum {
  case class Val(name: String, label: String) extends super.Val(name)

  type SyncUsersFilters = Val
  type EnumValue = Val

  val syncError: SyncUsersFilters = Val("syncError", "Error")
  val admin: SyncUsersFilters = Val("admin", "Admin")
  val syncEnabled: SyncUsersFilters = Val("syncEnabled", "Sync enabled")
  val active: SyncUsersFilters = Val("active", "Active")
  val disabled: SyncUsersFilters = Val("disabled", "Disabled")

  val All: List[SyncUsersFilters] = syncError :: admin :: syncEnabled :: active :: Nil
}
