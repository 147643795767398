package root_pages.aurinko_pages.app.settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Dialog, Icon, Textfield}
import com.raquo.laminar.api.L._
import common.ServiceType
import common.airstream_ops.{OptionSignalOps, SignalOptionOps}
import common.{AppRegistrationModel, PingTools, PortalAppRegistration, asteriskString}
import common.ui.confirmDeletionPopup
import common.forms.{FormsLocalExceptionHandling, TextfieldFormOps}
import common.airstream_ops.{EventStreamOps,SignalOps}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.icons.TrashIcon
import common.ui.mat_components_styles.styleTextfieldWithPlaceholder
import org.scalajs.dom
import service.apis.portal_api.PortalApi
import service.clipboard_service.ClipboardService
import service.portal_state.PortalState
import wvlet.log.Logger

case class GoogleOAuth(
                        $appReg: Signal[Option[PortalAppRegistration]],
                        componentHeader: String,
                        bus: EventBus[Unit],
                        serviceType: ServiceType,
                        portalApi: PortalApi,
                        portalState: PortalState,
                        $initialExpand: Signal[Boolean],
                        isDaemon: Boolean = false,
                        apiOrigin: String,
                        clipboardService: ClipboardService
                      ) extends AppRegistrationComponent {

  private val log = Logger.of[OAuthBasic]

  val showDeletePopup: Var[Boolean] = Var(false)

  val deletePopup: Dialog.El = confirmDeletionPopup(
    onConfirm = Observer[Unit](onNext = _ => {
      bus.emit()
      showDeletePopup.set(false)
    }),
    onCancel = showDeletePopup.writer.contramap((_: Unit) => false),
    heading = "Delete registration",
    $visible = showDeletePopup.signal,
    onClose = showDeletePopup.writer.contramap((_: Unit) => false),
    onConfirmEventTransfer = () => EventStream.fromValue(())
      .sample($appReg)
      .map(_.get)
      .withCurrentValueOf(portalState.$teamApp.map(_.appKey))
      .flatMap(t => portalApi.deleteAppReg(t._2, t._1))
  ).amend(PingTools.dialogBinders(portalApi))

  val node: Div = div(
    ExpansionPanelComponent(
      header =
        p(
          componentHeader,
          cls := "title--level-2",
          cls <-- $appReg.map(x => if (x.isEmpty) "light" else "")
        ),
      body = Some(div(
        cls := "slds-p-bottom_medium",
        child.maybe <-- errorView,
        $appReg.map {
          case Some(v) => v.toEditModel.some
          case None => AppRegistrationModel(serviceType = serviceType).some
        } --> editModel,

        children <-- editModel.signal.nestedMap(model => {
          deletePopup ::
            div(
              cls := "slds-m-bottom_medium",
              p("OAuth keys used to authenticate users to your app. See our docs: ",
                a("Google OAuth setup", href := "https://docs.aurinko.io/article/15-google-oauth-setup"), "."
              )
            ) ::
            div(
              cls := "slds-grid slds-m-bottom--medium",
              Textfield(
                _ => cls := "width-medium",
                _.outlined := true,
                _.label := "Redirect URI",
                _.`type` := "url",
                _.placeholder <-- portalState.$app.map(_.defaultRedirect(apiOrigin)),
                _.value <-- model.intermediateCallbackUrl,
                _ => onInput.mapToValue --> model.intermediateCallbackUrl,
                _.helper := "Note: Please enter the url above as one of authorized redirect URIs in your Google developer console (Credentials).",
                _.helperPersistent := true,
                _ => onMountCallback(styleTextfieldWithPlaceholder)
              ).bindToForm(model.formState),
              div(
                cls := "slds-p-left_medium slds-p-top--large",
                iconCopy($redirectUri(model, portalState, apiOrigin), clipboardService)
              )
            ) ::
            div(cls := "slds-grid slds-grid_vertical-align-end",
              Textfield(
                _ => cls := "width-medium slds-m-bottom_medium",
                _.outlined := true,
                _.label := "Client Id",
                _.value <-- model.clientId.signal,
                _ => onInput.mapToValue --> model.clientId
              ).bindToForm(model.formState)
            ) ::
            div(cls := "slds-grid slds-grid_vertical-align-end",
              Textfield(
                _ => cls := "width-medium slds-m-bottom_medium",
                _.outlined := true,
                _.label := "Client secret",
                _.required <-- $appReg.map {
                  case Some(_) => false
                  case None => true
                },
                _.placeholder <-- $appReg.map {
                  case Some(_) => asteriskString
                  case None => ""
                },
                _.value <-- model.clientSecret,
                _ => onInput.mapToValue --> model.clientSecret,
                _ => onMountCallback(ctx => if (model.hasSecret) styleTextfieldWithPlaceholder(ctx))
              ).bindToForm(model.formState),
            ) ::
            div(cls := "slds-grid slds-grid_vertical",
              Textfield(
                _ => cls := "width-medium",
                _.outlined := true,
                _.label := "Topic Name",
                _.value <-- model.topicName.signal,
                _ => onInput.mapToValue --> model.topicName,
                _.pattern := "^projects/.+/topics/.+$"
              ).bindToForm(model.formState),
              p(
                width:= "27rem",
                boxSizing:="border-box",
                cls := "slds-text-body_small grey slds-p-horizontal_medium slds-m-top--small",
                "(Optional) GCP Pub/Sub API topic name is used to create a watch for real-time activity notifications. If it is not set, Aurinko falls back to collecting activity data via polling. Expected format: 'projects/my-project-identifier/topics/my-topic-name'. See our docs: ",
                a(href := "https://docs.aurinko.io/unified-mailbox-api/configuring-pub-sub-for-gmail-api-webhooks", "Pub/Sub setup")
              )
            ) ::
            div(
              cls := "slds-p-top_xx-large slds-grid footer-buttons",
              cls <-- $appReg.map { case None => "slds-grid_align-end" case _ => "slds-grid_align-spread" },
              div(
                cls := "slds-col",
                cls <-- $appReg.map { case None => "hidden" case _ => "" },
                TrashIcon(showDeletePopup.writer.contramap((_: dom.MouseEvent) => true))
              ),
              div(
                cls := "dialog-submit-buttons",
                ButtonsPairComponent[Unit, Option[PortalAppRegistration]](

                  disabled = editModel.signal.flatMap(_.$traverse(_.formState.$dirty)).$contains(false),
                  primaryDisabled = editModel.signal
                    .flatMap(_.$traverse(_.formState.$submitAllowed))
                    .combineWith(model.clientSecret)
                    .combineWith($appReg)
                   .map(t => !t._1.contains(true) || (t._2.isEmpty && t._3.isEmpty)),

                  primaryEffect = () => editModel.signal.stream
                    .collect { case Some(model) => model }
                    .withCurrentValueOf(portalState.$teamApp)
                    .flatMap {
                      case (model, teamApp) => portalApi.createOrUpdateAppReg(teamApp.appKey, model.toImmutableModel)
                    }.withErrorHandlingAndCollect(
                      FormsLocalExceptionHandling
                        .handler(str => editModel.now.foreach(_.formError.set(str.some)))),

                  primaryObserver = Observer[Unit](onNext = _ => {
                    bus.emit(())
                  }),

                  secondaryEffect = () => EventStream.fromValue(()).sample($appReg),
                  secondaryObserver = Observer[Option[PortalAppRegistration]](onNext = {
                    case Some(value) =>
                      model.updateModelFromImmutable(value)
                      model.formState.validate()
                    case None =>
                      model.resetModel()
                      model.formState.validate()
                  }
                  )
                ).node,
              )
            ) :: Nil
        }).map(_.toList.flatten)
      )
      ),
      bordered = Signal.fromValue(true),
      expanded = $initialExpand.combineWith(isExpanded.signal).map(t => t._1 || t._2),
      onExpansionChange = isExpanded.writer
    ).node
  )


}
