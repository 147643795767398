package root_pages.auth_pages.sign_up

import com.github.uosis.laminar.webcomponents.material.{Button, Textfield}
import com.raquo.laminar.api.L._
import org.scalajs.dom
import common._
import common.ui.notifications.LocalMessagesView
import portal_router.{LoginPage, NotVerifiedPage, PortalRouter}
import common.ui.secret_input.SecretInputComponent
import common.value_opps.OptionOpps
import root_pages.auth_pages._
import service.apis.portal_api.PortalApi
import service.exception_handler.UnknownException
import service.captcha.Captcha
import wvlet.log.Logger


class SignUpComponent(portalApi: PortalApi, portalRouter: PortalRouter) {
  private val  log = Logger.of[SignUpComponent]
  val formValid: Var[Boolean] = Var(false)

  def validate(): Unit = formValid.set(nameInput.isValid && emailInput.isValid && passwordInput.valid.now && captcha.token.now.nonEmpty)

  val signInBus = new EventBus[Unit]

  val nameInput: Textfield.El = nameTextfield
  val emailInput: Textfield.El = emailTextfield()

  val passwordInput: SecretInputComponent = SecretInputComponent("Password", newPassword = true)
  val captcha = new Captcha()

  val errorMessage: Var[Option[String]] = Var(None)


  val node: Div = div(
    signInBus.events
      .flatMap(_ => {
        val signUpModel = SignUpModel(
          emailInput.ref.value,
          nameInput.ref.value,
          passwordInput.value.now,
          captcha.token.now.getOrFail(UnknownException("failed to get hcaptcha token"))
        )
        log.info(s"model ${signUpModel}")
        portalApi.signUp(signUpModel)
      })
      .validateWithLocalError(errorMessage)
      --> Observer[String](onNext = _ => portalRouter.navigate(LoginPage(redirect = Some(portalRouter.router.absoluteUrlForPage(NotVerifiedPage))))),

//    signInBusWithEnter.events
//      .flatMap(_ => portalApi.signUp(SignUpModel(emailInput.ref.value, nameInput.ref.value, passwordInput.value.now)))
//      .validateWithLocalError(errorMessage)
//      --> Observer[String](onNext = _ => portalRouter.navigate(LoginPage(redirect = Some(portalRouter.router.absoluteUrlForPage(NotVerifiedPage))))),

    passwordInput.node.events(onInput) --> Observer[dom.Event](onNext = _ => validate()),

    div(
      cls := "slds-align--absolute-center auth-page",

      form(
        cls := "form sign-up slds-grid slds-grid--vertical slds-sgrid--vertical-align-center",
        onKeyPress
          .filter(key => key.keyCode == 13)
          .filter(_ => formValid.now)
          .mapTo(()) --> signInBus.writer,

        AuthPagesLogo,
        div(cls := "slds-p-top--x-small slds-p-bottom--large", h2("Sign up", cls := "slds-text-align--center")),

        LocalMessagesView(errorMessage.signal),

        nameInput,
        emailInput,
        passwordInput.node,
        captcha.element.amend(cls := "slds-m-bottom--medium"),


        Button(
          _.disabled <-- formValid.signal.map(!_),
          _.raised := true,
          _ => cls := "secondary slds-m-bottom--small slds-size--1-of-1",
          //            _.label := "Sign up with email",
          _.label := "Sign up",
          _ => onClick.mapTo(()) --> signInBus.writer
        ),
        Button(
          _ => cls := "slds-size--1-of-1 secondary",
          _.outlined := true,
          _.label := "Already have an account?",
          _ => onClick.mapTo(LoginPage()) --> portalRouter.goto
        ),
        p(
          cls := "slds-p-vertical--large slds-text-align--center",
          "By clicking the SIGN UP button, you acknowledge that you have read and agree to our ",
          a("Terms of Use", href := "https://www.aurinko.io/terms-of-services", target := "_blank"),
          " and have read and acknowledge our ",
          a("Privacy Policy", href := "https://www.aurinko.io/privacy-policy", target := "_blank"),
          "."
        ),


        onInput --> Observer[dom.Event](onNext = _ => validate()),
        captcha.token.signal.changes --> Observer[Option[String]](onNext = _ => validate())

      )
    ),

  )

}

case class SignUpModel(email: String,
                       name: String,
                       password: String,
                       captchaResponse: String,
                       createApp: Boolean = false)
