package root_pages.aurinko_pages.app.virtual_api.components

import cats.implicits.catsSyntaxOptionId
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ui.paginator.Paginator
import org.scalajs.dom.html
import service.apis.dynamic_api.DataMapperApi
import service.scroll_ops.ScrollOps

case object TableComponent {
  def apply[T, KEY](
                     load: (Int, Int, String) => EventStream[DataMapperApi.PageOf[T]],
                     key: T => KEY,
                     render: (KEY, Signal[T]) => HtmlElement,
                     header: HtmlElement = div(),
                     noRows: String = "No entity",
                     caption: Signal[Option[HtmlElement]] = Signal.fromValue(None),
                     pageSize: Var[Int] = Var(DataMapperApi.standardApiPageSize),
                     searchText: Var[String] = Var[String](""),
                     documentScrollOps: ScrollOps
                   ): ReactiveHtmlElement[html.Div] = {
    val items: Var[Option[DataMapperApi.PageOf[T]]] = Var(None)
    val pageNumber: Var[Int] = Var(0)

    div(
      common.ui.Attribute.Selector := "data_mapper.components.table",

      pageNumber.signal
        .combineWith(pageSize.signal)
        .combineWith(searchText.signal)
        // TODO REVIEW: you have pageSize value here from withCurrentValueOf(pageSize.signal), could you use it
        .flatMap(x => load(x._1 * pageSize.now, pageSize.now, x._3)
          .map(Some(_)))
        --> items.writer,

      // TODO REVIEW could you optimize child.maybe ans uses without case true
      child.maybe <-- caption.map(_.isDefined)
        .map {
          case true => Some(div(
            cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread slds-m-bottom--large",
            child.maybe <-- caption.map {
              case Some(c) => Some(div(cls := "title--level-2", c))
              case _ => None
            },
          ))
          case _ => None
        },

      div(
        div(
          cls := "data-table",

          children <-- items.signal
            .map {
              case Some(p) => p.records
              case _ => Nil
            }
            .split(key)((rowId, _, $row: Signal[T]) => render(rowId, $row))
            .map {
              case rows if rows.nonEmpty => header :: rows
              case _ => List(p(
                cls := "gray slds-grid slds-grid--align-center slds-m-around--large",
                noRows,
              ))
            },
        ),

        child.maybe <-- items.signal.map {
          case Some(entities) if entities.totalSize > pageSize.now() => Some(Paginator(
            pageNum = Signal.fromValue(entities.offset / pageSize.now), // TODO REVIEW: need to observe the changes from pageSize
            totalCount = Signal.fromValue(entities.totalSize),
            pageSize = pageSize.signal,
            onPageChange = Observer.combine(
              pageNumber.writer.contramap(x => x._1),
              pageSize.writer.contramap(x => x._2),
            ),
            documentScrollTopAfterPageChange = true,
            documentScrollOps.some,
            itemsPluralLabel = "Items", // TODO REVIEW: what it is? could use title ENTITIES or noRows value?
          ).node)
          case _ => None
        },
      ),
    )
  }
}
