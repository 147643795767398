package common.ui

import cats.implicits.{catsSyntaxOptionId, catsSyntaxWriterId}
import com.github.uosis.laminar.webcomponents.material.Menu
import com.raquo.domtypes.jsdom.defs.events.TypedTargetMouseEvent
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.{EventStreamOps, SignalNestedOps, ValueToObservableOps}
import common.ui.icons.{IconColor, IconComponent, IconSize, IconType, MaterialIcons}
import org.scalajs.dom
import org.scalajs.dom.html
import scala.scalajs.js.timers.setTimeout

package object text_data_view {
  def SecretTextView(textF: TypedTargetMouseEvent[dom.Element] => EventStream[String],
                     copyObserver: Observer[String],
                     label: Option[String] = None
                    ): ReactiveHtmlElement[html.Div] = {

    val text: Var[Option[String]] = Var(None)

    val iconImage: Var[String] = Var(MaterialIcons.copy)
    val $iconImage = iconImage.signal

    div(
      cls := "slds-grid slds-grid--vertical-align-end slds-is-relative width-full",
      div(
        cls := "slds-grid slds-grid--vertical slds-grid--align-end flex-item-prevent-container-overflow",
        label.map(l => div(
          cls := "text-x-small grey slds-m-bottom--small",
          l
        )),
        div(
          cls := "slds-grid slds-grid--vertical-align-center",
          p(
            cls := "slds-m-right--x-small text-x-small",
            letterSpacing := ".07rem",
            textOverflow := "clip",
            "••••••••••••••••••••••••••••••"),
          div(child <-- $iconImage.map { i =>
            IconComponent(
              icon = i,
              color = IconColor.`lighter-brown`,
              size = IconSize.small,
              iconType = IconType.outlined
            )
          }
          ).amend(
            cls := "slds-m-right--x-small clickable",
            composeEvents(onClick)(_.flatMap(textF)) --> copyObserver,

            onClick --> Observer[dom.MouseEvent](_ => {
              iconImage.set(MaterialIcons.done)
              setTimeout(2500) {
                iconImage.set(MaterialIcons.copy)
              }
            }
            ),
          ),

          IconComponent(
            icon = MaterialIcons.eye,
            color = IconColor.`lighter-brown`,
            size = IconSize.small,
            iconType = IconType.outlined,
            materialIconType = IconType.filled
          )
            .amend(
              cls := "slds-m-right--x-small clickable",
              composeEvents(onClick)(_.flatMap(textF)) --> text.writer.contramap((e: String) => e.some)),

          Menu(
            _.open <-- text.signal.map(_.nonEmpty),
            _.onClosed.mapTo(None) --> text,

            _ => cls := "info-popup",

            _ => div(
              cls := "slds-grid slds-p-around--medium popup-content break-word",
              child.maybe <-- text.signal.nestedMap(text => div(text))
            )
          )
        )
      )
    )
  }

  def TextView($text: Signal[String],
               copyObserver: Observer[String],
               label: Option[String] = None): ReactiveHtmlElement[html.Div] = {

    val text: Var[Option[String]] = Var(None)
    val iconImage: Var[String] = Var(MaterialIcons.copy)
    val $iconImage = iconImage.signal

    div(
      cls := "slds-grid slds-grid--vertical-align-center slds-is-relative",
      div(
        cls := "slds-grid slds-grid--vertical slds-grid--align-end  width-full",
        label.map(l => div(
          cls := "text-x-small grey slds-m-bottom--small",
          l
        )),
        div(
          cls := "slds-grid slds-grid--vertical-align-center",
          p(
            cls := "slds-m-right--x-small",
            child.text <-- $text
          ),
          div(
            child <-- $iconImage.map(i => IconComponent(
              icon = i,
              color = IconColor.`lighter-brown`,
              size = IconSize.small,
              iconType = IconType.outlined))
          ).amend(
            cls := "slds-m-right--x-small clickable",
            composeEvents(onClick)(_.sample($text)) --> copyObserver),
          onClick --> Observer[dom.MouseEvent](onNext = _ => {
            iconImage.set(MaterialIcons.done)
            setTimeout(2500) {
              iconImage.set(MaterialIcons.copy)
            }
          }),

          IconComponent(
            icon = MaterialIcons.eye,
            color = IconColor.`lighter-brown`,
            size = IconSize.small,
            iconType = IconType.outlined,
            materialIconType = IconType.filled)
            .amend(
              cls := "slds-m-right--x-small clickable",
              composeEvents(onClick.stopPropagation)(_.sample($text)) --> text.writer.contramap((e: String) => e.some)),

          Menu(
            _.open <-- text.signal.map(_.nonEmpty),
            _.onClosed.mapTo(None) --> text,

            _ => cls := "info-popup",

            _ => div(
              cls := "slds-grid slds-p-around--medium popup-content break-word",
              child.maybe <-- text.signal.nestedMap(text => div(text))
            )
          )
        )
      )
    )
  }

  def ColoredTextView(text: String, textColor: String, fillingColor: String, circle: Boolean = true): ReactiveHtmlElement[html.Paragraph] =

    p(
      padding := (if (circle) "0 11px" else "2px 6px"),
      backgroundColor := fillingColor,
      borderRadius := (if (circle) "8px" else "4px"),
      display := "inline-block",

      span(

        fontWeight := "300",
        color := textColor,
        text
      )
    )
}
