package root_pages.aurinko_pages.app.settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Dialog, Icon, Textfield}
import com.raquo.laminar.api.L._
import common.ServiceType
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.{AppRegistrationModel, PingTools, PortalAppRegistration}
import common.ui.confirmDeletionPopup
import common.forms.{FormsLocalExceptionHandling, TextfieldFormOps}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.icons.TrashIcon
import common.ui.mat_components_styles.styleTextfieldWithPlaceholder
import org.scalajs.dom
import service.apis.portal_api.PortalApi
import service.clipboard_service.ClipboardService
import service.portal_state.PortalState
import wvlet.log.Logger

case class DaemonBasic(
                        $appReg: Signal[Option[PortalAppRegistration]],
                        componentHeader: String,
                        bus: EventBus[Unit],
                        serviceType: ServiceType,
                        portalApi: PortalApi,
                        portalState: PortalState,
                        isDaemon: Boolean = true,
                        apiOrigin: String,
                        clipboardService: ClipboardService,
                      ) extends AppRegistrationComponent {

  private val log = Logger.of[DaemonBasic]

  override val $initialExpand: Signal[Boolean] = false.signaled

  val showDeletePopup: Var[Boolean] = Var(false)

  val deletePopup: Dialog.El = confirmDeletionPopup(
    onConfirm = Observer[Unit](onNext = _ => {
      bus.emit()
      showDeletePopup.set(false)
    }),
    onCancel = showDeletePopup.writer.contramap((_: Unit) => false),
    heading = "Delete registration",
    $visible = showDeletePopup.signal,
    onClose = showDeletePopup.writer.contramap((_: Unit) => false),
    onConfirmEventTransfer = () => EventStream.fromValue(())
      .sample($appReg)
      .map(_.get)
      .withCurrentValueOf(portalState.$teamApp.map(_.appKey))
      .flatMap(t => portalApi.deleteAppReg(t._2, t._1))
  ).amend(PingTools.dialogBinders(portalApi))

  val node: Div =
    ExpansionPanelComponent(
      header =
        p(
          componentHeader,
          cls := "title--level-2",
          cls <-- $appReg.map(x => if (x.isEmpty) "light" else "")
        ),
      body = Some(
        div(
          cls := "slds-p-bottom_medium",
          child.maybe <-- errorView,
          $appReg.map {
            case Some(v) => v.toEditModel.some
            case None => AppRegistrationModel(serviceType = serviceType, daemon = true).some
          } --> editModel,

          children <-- editModel.signal.nestedMap(model => {
            deletePopup ::
              div(cls := "slds-m-bottom_medium", model.serviceType match {
                case ServiceType.office365 => p(
                  "OAuth keys used to authenticate users to your app. See our docs: ",
                  a("Office 365 OAuth setup", href := "https://docs.aurinko.io/article/16-office-365-oauth-setup"),
                  "."
                )
                case _ => span("OAuth keys used to authenticate users to your app.")
              }) ::
              div(
                cls := "slds-grid slds-m-bottom--medium",
                Textfield(
                  _ => cls := "width-medium",
                  _.outlined := true,
                  _.label := "Redirect URI",
                  _.`type` := "url",
                  _.placeholder <-- portalState.$app.map(_.defaultRedirect(apiOrigin)),
                  _.value <-- model.intermediateCallbackUrl,
                  _ => onInput.mapToValue --> model.intermediateCallbackUrl,
                  _ => onMountCallback(styleTextfieldWithPlaceholder),
                  _.helper := (model.serviceType match {
                    case common.ServiceType.office365 => "Note: Please enter the url above as one of return URIs for your Azure AD app registration."
                    case _ => "Note: Please enter the url above as one of return URIs for your app registration."
                  }),
                  _.helperPersistent := true

                ).bindToForm(model.formState),
                div(
                  cls := "slds-p-left_medium slds-p-top--large",
                  iconCopy($redirectUri(model, portalState, apiOrigin), clipboardService)
                )
              ) ::
              div(cls := "slds-grid slds-grid_vertical-align-end",
                Textfield(
                  _ => cls := "width-medium slds-m-bottom_medium",
                  _.outlined := true,
                  _.label := "Client Id",
                  _.value <-- model.clientId.signal,
                  _ => onInput.mapToValue --> model.clientId
                ).bindToForm(model.formState)) ::
              KeyInputComponent(
                model.clientSecret2,
                "Private key",
                Signal.fromValue(model.hasPk)
              ).node ::
              KeyInputComponent(
                model.clientSecret3,
                "Certificate",
                Signal.fromValue(model.hasPk)
              ).node ::
              div(
                cls := "slds-p-top_medium slds-grid footer-buttons",
                cls <-- $appReg.map { case None => "slds-grid_align-end" case _ => "slds-grid_align-spread" },
                div(
                  cls := "slds-col",
                  cls <-- $appReg.map { case None => "hidden" case _ => "" },
                  TrashIcon(showDeletePopup.writer.contramap((_: dom.MouseEvent) => true)),
                ),
                div(
                  cls := "dialog-submit-buttons",
                  ButtonsPairComponent[Unit, Option[PortalAppRegistration]](

                    disabled = editModel.signal.flatMap(_.$traverse(_.formState.$dirty)).$contains(false)
                      .combineWith(model.clientSecret2.signal.map(_.isEmpty))
                      .combineWith(model.clientSecret3.signal.map(_.isEmpty))
                      .map(t => t._1 && t._2 && t._3),

                    primaryDisabled = editModel.signal.flatMap(_.$traverse(_.formState.$valid)).$contains(false)
                      .combineWith(model.clientSecret2)
                      .combineWith(model.clientSecret3)
                      .map(t => t._1 || (t._2.isEmpty && t._3.nonEmpty) || (t._3.isEmpty && t._2.nonEmpty)),

                    primaryEffect = () => editModel.signal.stream
                      .collect { case Some(model) => model }
                      .withCurrentValueOf(portalState.$teamApp)
                      .flatMap { case (model, teamApp) => portalApi.createOrUpdateAppReg(teamApp.appKey, model.toImmutableModel) }
                      .withErrorHandlingAndCollect(
                        FormsLocalExceptionHandling
                          .handler(str => editModel.now.foreach(_.formError.set(str.some)))),

                    primaryObserver = Observer[Unit](onNext = _ => {
                      bus.emit(())
                      model.clientSecret2.set("")
                      model.clientSecret3.set("")
                    }),

                    secondaryEffect = () => EventStream.fromValue(()).sample($appReg),

                    secondaryObserver = Observer[Option[PortalAppRegistration]](onNext = {
                      case Some(value) =>
                        model.updateModelFromImmutable(value)
                        model.formState.validate()
                      case None =>
                        model.resetModel()
                        model.formState.validate()
                    })).node,
                )
              ) :: Nil
          }).map(_.toList.flatten)
        )
      ),
      bordered = Signal.fromValue(true)
    ).node
}
