package common.ui.search_input

import com.github.uosis.laminar.webcomponents.material.Textfield
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import wvlet.log.Logger

case class SearchInputComponent(
                                 label: String = "Search",
                                 icon: String = "search",
                                 onChange: Observer[String],
                                 delay: Int = 1000, // MS
                                 className: Option[String] = None,
                                 $value: Signal[String] = Signal.fromValue("")
                               ) {
  private val onChangeBus = new EventBus[String]
  private val log = Logger.of[SearchInputComponent]
  log.info(s"initial: ")
  val text: Var[String] = Var[String]("")

  val node: Textfield.El = Textfield(
    _ => cls := "search-input",

    _.placeholder := label,
    _.icon := icon,
    _.outlined := true,
    _ => className.map(cls := _),
    _.value <-- text.signal,

    _ => onInput.mapToValue --> onChangeBus,
    _ => onBlur.mapToValue --> text,

    _ => text.signal.changes --> onChange,
    _ => onChangeBus.events.debounce(delay) --> text,
    _ => $value --> text.writer
  )

}
