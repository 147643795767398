package service.apis.dynamic_api

import common.JsonEnum
import common.value_opps._
import cats.implicits.catsSyntaxOptionId
import wvlet.log.Logger

import java.time.Instant

case object DataMapperModels {
  object SupportedType extends Enumeration {
    trait Type {
      def value: String

      def label: String

      def color: String
    }

    case class Val(value: String, label: String, color: String) extends super.Val(value) with Type

    case class ValOf(subtype: Type, value: String, label: String, color: String) extends super.Val(s"$value[${subtype.value}]") with Type

    val string: Type = Val("String", "String", "#D7FAE5")
    val long: Type = Val("Long", "Long", "#F3D7FA")
    val decimal: Type = Val("BigDecimal", "Decimal", "#F3D7FA")
    val double: Type = Val("Double", "Double", "#F3D7FA")
    val boolean: Type = Val("Boolean", "Boolean", "#FFE7D2")
    val dateTime: Type = Val("OffsetDateTime", "DateTime", "#D8E7FF")
    val localDate: Type = Val("LocalDate", "LocalDate", "#D8E7FF")
    val special: Type = Val("special", "Special", "#f4424240")
    val stringDecimal: Type = Val("stringDecimal", "StringDecimal", "#D7FAE5")
    val JsValue: Type = Val("jsValue", "JsValue", "#ffc10759")

    val none: Type = Val("none", "None", "None")

    def listOf(t: Type): Type = ValOf(t, "List", "List", "#EFFAD7")

    def mapOf(t: Type): Type = ValOf(t, "Map", "Map", "#EFFAD7")

    def setOf(t: Type): Type = ValOf(t, "Set", "Set", "#EFFAD7")

    private val basicTypes: Seq[Type] = Seq(
      string,
      long,
      decimal,
      boolean,
      dateTime,
      localDate,
    )

    def all: Seq[Type] = Seq(special) ++
      basicTypes ++
      basicTypes.map(t => listOf(t)) ++
      basicTypes.map(t => mapOf(t)) ++
      basicTypes.map(t => setOf(t))
  }

  case object ConfigurationModels {
    //private val log = Logger.of[ConfigurationModels.type]

    /**
     * Configuration groups provider and client metadata with unique mapping rules.
     */
    case class Configuration(
                              id: Option[Long] = None, // readonly
                              name: Option[String] = None,

                              providerDescriptionId: Option[Long] = None,
                              providerDescription: Option[String] = None,
                              providerDescriptionName: Option[String] = None,
                              providerMetadataServiceType: Option[common.ServiceType] = None,

                              providerMetadataId: Option[Long] = None,
                              providerMetadata: Option[String] = None,
                              providerMetadataName: Option[String] = None,

                              clientMetadataId: Option[Long] = None,
                              clientMetadata: Option[String] = None,
                              clientMetadataName: Option[String] = None,

                              mappingsId: Option[Long] = None,

                              configAccountId: Option[Int] = None,
                              configAccountDescription: Option[String] = None,

                              createdAt: Option[Instant] = None, // readonly
                              updatedAt: Option[Instant] = None, // readonly
                            ) {
      // rethink
      def provider_descriptionName: String =
        providerDescriptionName.getOrElse("")

      def providerMetadataServiceTypeLabel: String = providerMetadataServiceType match {
        case Some(t) => t.label
        case None => ""
      }

      override def toString: String = this.name.getOrElse(s"#${this.id.getOrElse[Long](0)}")
    }
  }

  case object MetadataModels {
    private val log = Logger.of[MetadataModels.type]

    /**
     * Structure control of Metadata model
     * The backend can not check all structure, so client has structure control too.
     */
    case object Metadata {
      /**
       * UsedFields -
       *
       * @return Seq[String]
       */
      private def UsedFields: Seq[String] =
        "id" ::
          "name" ::
          "data" ::
          "serviceType" ::
          "types" ::
          "createdAt" ::
          "updatedAt" ::
          Nil

      /**
       * UsedFields - as
       *
       * @param rawData : io.circe.Json
       * @return Seq[String]
       *         if isDefined && get.nonEmpty this fields is not not implemented
       *         if isDefined && get.isEmpty can not check
       *         if isEmpty json is correct
       */
      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the metadata")
                Some(diff)
              } else {
                val jData = obj.apply("data")
                if (jData.isDefined)
                  ClassList.CheckFieldNames(jData.get)
                else
                  Some(Nil)
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * Metadata is the base model for all metadata requests.
     */
    case class Metadata(
                         id: Option[Long] = None, // readOnly
                         name: Option[String] = None,
                         data: Option[MetadataModels.ClassList] = None,
                         data1: Option[MetadataModels.SchemaList] = None,
                         serviceType: Option[common.ServiceType] = None,
                         createdAt: Option[Instant] = None, // readOnly
                         updatedAt: Option[Instant] = None, // readOnly
                       ) {
      def classes: List[MetadataModels.Class] =
        data.getOrElse(MetadataModels.ClassList()).classes

      def schemas: List[MetadataModels.Schema] =
        data1.getOrElse(MetadataModels.SchemaList()).schemas

      def findClass(className: String): Option[MetadataModels.Class] =
        classes.find(c => c.name == className)

      def findField(fieldName: String, className: String): Option[MetadataModels.Field] = {
        val c = findClass(className)
        if (c.isDefined)
          c.get.findField(fieldName)
        else
          None
      }

      override def toString: String = this.name.getOrElse(s"#${this.id.getOrElse[Long](0)}")
    }

    /**
     * Structure control of ClassList model
     * The backend can not check all structure, so client has structure control too.
     */
    case object ClassList {
      private def UsedFields: Seq[String] =
        "classes" ::
          "recordSchemas" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the class model")
                Some(diff)
              } else {
                obj.apply("classes")
                  .condition(
                    _.exists(_.isArray),
                    _.get.asArray.getOrElse(Nil).toList
                      .map(Class.CheckFieldNames)
                      .fold(None)((a, b) =>
                        if (a.isEmpty && b.isEmpty) None
                        else if (a.isDefined && b.isEmpty) a
                        else if (a.isEmpty && b.isDefined) b
                        else Some((a.get ++ b.get).distinct)),
                    _ => Nil.some
                  )
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * ClassList metadata model for group all classes
     */
    case class ClassList(
                          classes: List[MetadataModels.Class] = Nil,

                          recordSchemas: Option[List[io.circe.Json]] = Some(Nil), // TODO: ues RecordSchema type some like so List[RecordSchema]
                        ) {
      override def toString: String = this.classes.map(_.toString).mkString(", ")
    }

    case class SchemaList(
                           schemas: List[MetadataModels.Schema] = Nil,
                           //  recordSchemas: Option[List[io.circe.Json]] = Some(Nil),
                         ) {
      override def toString: String = this.schemas.map(_.toString).mkString(", ")
    }

    /**
     * Structure control of Class model
     * The backend can not check all structure, so client has structure control too.
     */
    case object Class {
      private def UsedFields: Seq[String] =
        "name" ::
          "pluralName" ::
          "loadable" ::
          "embedded" ::
          "fields" ::
          "relations" ::
          "subSets" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the class model")
                Some(diff)
              } else {
                // checking fields
                val jFields = obj.apply("fields")
                val fields: Option[Seq[String]] = if (jFields.isDefined && jFields.get.isArray)
                  jFields.get.asArray.getOrElse(Nil)
                    .toList
                    .map(Field.CheckFieldNames)
                    .fold(None)((a, b) =>
                      if (a.isEmpty && b.isEmpty) None
                      else if (a.isDefined && b.isEmpty) a
                      else if (a.isEmpty && b.isDefined) b
                      else Some((a.get ++ b.get).distinct))
                else
                  Some(Nil)
                // checking relations
                val jRelations = obj.apply("relations")
                val relations: Option[Seq[String]] = if (jRelations.isDefined && jRelations.get.isArray)
                  jRelations.get.asArray.getOrElse(Nil)
                    .toList
                    .map(Relation.CheckFieldNames)
                    .fold(None)((a, b) =>
                      if (a.isEmpty && b.isEmpty) None
                      else if (a.isDefined && b.isEmpty) a
                      else if (a.isEmpty && b.isDefined) b
                      else Some((a.get ++ b.get).distinct))
                else
                  Some(Nil)
                // merging the fields and relation wrong columns
                if (fields.isEmpty && relations.isEmpty) None
                else if (fields.isDefined && relations.isEmpty) fields
                else if (fields.isEmpty && relations.isDefined) relations
                else Some((fields.get ++ relations.get).distinct)
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * Class metadata model
     */
    case class Class(
                      name: String = "",
                      pluralName: String = "",
                      loadable: Boolean = true, // этот флаг не нужен на клиенте
                      embedded: Boolean = false, // означает, что этот класс может выступать частью другого класса.
                      fields: Option[List[MetadataModels.Field]] = None,
                      relations: Option[List[MetadataModels.Relation]] = None,
                      subSets: Option[Map[String, MetadataModels.ClassSubsetsDefinition]] = None,
                    ) {
      def findField(name: String): Option[MetadataModels.Field] =
        fields.getOrElse(Nil).find(f => f.name == name)

      override def toString: String = this.name
    }

    case object Schema {
      private def UsedFields: Seq[String] =
        "name" ::
          "fields" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the class model")
                Some(diff)
              } else {
                // checking fields
                val jFields = obj.apply("fields")
                val fields: Option[Seq[String]] = if (jFields.isDefined && jFields.get.isArray)
                  jFields.get.asArray.getOrElse(Nil)
                    .toList
                    .map(Field.CheckFieldNames)
                    .fold(None)((a, b) =>
                      if (a.isEmpty && b.isEmpty) None
                      else if (a.isDefined && b.isEmpty) a
                      else if (a.isEmpty && b.isDefined) b
                      else Some((a.get ++ b.get).distinct))
                else
                  Some(Nil)
                // checking relations
                val jRelations = obj.apply("relations")
                val relations: Option[Seq[String]] = if (jRelations.isDefined && jRelations.get.isArray)
                  jRelations.get.asArray.getOrElse(Nil)
                    .toList
                    .map(Relation.CheckFieldNames)
                    .fold(None)((a, b) =>
                      if (a.isEmpty && b.isEmpty) None
                      else if (a.isDefined && b.isEmpty) a
                      else if (a.isEmpty && b.isDefined) b
                      else Some((a.get ++ b.get).distinct))
                else
                  Some(Nil)
                // merging the fields and relation wrong columns
                if (fields.isEmpty && relations.isEmpty) None
                else if (fields.isDefined && relations.isEmpty) fields
                else if (fields.isEmpty && relations.isDefined) relations
                else Some((fields.get ++ relations.get).distinct)
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    case class Schema(
                       name: String = "",
                       fields: Option[List[MetadataModels.Field]] = None,

                     ) {
      def findField(name: String): Option[MetadataModels.Field] =
        fields.getOrElse(Nil).find(f => f.name == name)

      override def toString: String = this.name
    }

    /**
     * Structure control of Field model
     * The backend can not check all structure, so client has structure control too.
     */
    case object Field {
      private def UsedFields: Seq[String] =
        "name" ::
          "label" ::
          "type" ::
          "nativeType" ::
          "structure" ::
          "role" ::
          "required" ::
          "readOnly" ::
          "referenceTo" ::
          "relationshipName" ::
          "primaryReference" ::
          "recordSchema" ::
          "enum" ::
          "group" ::
          "groupLabel" ::
          "referenceToField" ::
          "mappingDetails" ::
          "creatable" ::
          "compound" ::
          "updatable" ::
          "custom" ::
          "enumerated" ::
          "referenceName" ::
          "writeType" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the field model")
                Some(diff)
              } else {
                None
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * Field metadata model
     */
    case class Field(
                      name: String = "",
                      label: String = "",
                      `type`: MetadataModels.DataType.Type = DataType.unknown, // строка
                      nativeType: Option[String] = None, // не нужно на клиенте.
                      structure: MetadataModels.FieldStructure.FieldStructure = MetadataModels.FieldStructure.singular, // два возможных значения: singular и array.
                      role: Option[MetadataModels.FieldRole.FieldRole] = None, // значения: id, name, email. в не embedded классе должно быть ровно одно поле с ролью id.
                      required: Boolean = false,
                      readOnly: Boolean = false,
                      primaryReference: Option[Boolean] = Some(false),
                      referenceTo: Option[String] = None, // имя класса, на который ссылается данное поле
                      relationshipName: Option[String] = None, // можно пока пропустить. название parent relationship а
                      recordSchema: Option[RecordSchema] = None, //ApiRecordSchema
                      `enum`: Option[Enum] = None, // не используются на портале, нужны для работы с ауринко-апи
                      group: Option[String] = None, // не используются на портале, нужны для работы с ауринко-апи
                      groupLabel: Option[String] = None, // не используются на портале, нужны для работы с ауринко-апи
                      referenceToField: Option[String] = None, // не используются на портале, нужны для работы с ауринко-апи
                      formula: Option[FieldFormula] = None, // не используются на портале, нужны для работы с ауринко-апи
                      creatable: Boolean = true,
                      compound: Boolean = false,
                      updatable: Boolean = true,
                      enumerated: Boolean = false,
                      custom: Boolean = false,
                      referenceName: Option[String] = None,
                      writeType: Option[MetadataModels.DataType.Type] = None

                    ) {
      def getDataType: String =
        structure match {
          case MetadataModels.FieldStructure.array =>
            s"Array of ${`type`.label}"
          case _ =>
            `type`.label
        }

      override def toString: String = this.name
    }

    /**
     * Structure control of Relation model
     * The backend can not check all structure, so client has structure control too.
     */
    case object Relation {
      private def UsedFields: Seq[String] =
        "name" ::
          "relatesToClass" ::
          "relatesToField" ::
          "quickAccessName" ::
          "postSubPath" ::
          "listSubPath" ::
          "childCountField" ::
          "parentTypeColumn" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the relation model")
                Some(diff)
              } else {
                None
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * Relation metadata model
     */
    case class Relation(
                         name: String = "",
                         relatesToClass: String = "", // имя класса, на который ссылается этот relation.
                         relatesToField: Option[String] = None, // имя поля в related классе, которое ссылается на этот класс.

                         quickAccessName: Option[String] = None, // use only in the aurinko-api
                         postSubPath: Option[String] = None, // use only in the aurinko-api
                         listSubPath: Option[String] = None, // use only in the aurinko-api

                         childCountField: Option[String] = None, // use only in the aurinko-api
                         parentTypeColumn: Option[String] = None, // use only in the aurinko-api
                       ) {
      override def toString: String = this.name
    }

    case class ClassSubsetsDefinition(
                                       queryParams: Map[String, String],
                                       validateData: io.circe.Json,
                                     )

    // TODO: implement
    //    case object RecordSchema {
    //      private def UsedFields: Seq[String] = "name" :: "fields" :: Nil
    //
    //      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
    //        if (rawData.isObject)
    //          rawData.asObject match {
    //            case Some(obj) =>
    //              val diff = obj.keys.toSeq.diff(UsedFields)
    //              if (diff.nonEmpty) {
    //                log.warn(s"not found a columns ${diff.mkString(", ")} in the field model")
    //                diff.some
    //              } else {
    //                None
    //              }
    //            case None =>
    //              log.warn(s"json data is not an object")
    //              Nil.some
    //          }
    //        else {
    //          log.warn(s"incorrect json type")
    //          Nil.some
    //        }
    //      }
    //    }

    case class RecordSchema(
                             name: String, // equivalent value of the field name, but will reserved for the future
                             fields: Seq[Field], // fields of substructure
                           )

    case class Enum(
                     name: String,
                     dependsOn: Option[String] = None,
                     options: List[EnumOption]
                   )

    case class EnumOption(
                           value: String,
                           label: String,
                           validFor: Set[String] = Set.empty
                         )

    case class FieldFormula(
                             fields: List[String]
                           )

    object FieldRole extends Enumeration with JsonEnum {
      case class Val(value: String, label: String, weight: Int) extends super.Val(value)

      type FieldRole = Val
      type EnumValue = Val

      val id: FieldRole = Val("id", "Id", weight = 3)
      val name: FieldRole = Val("name", "Name", weight = 2)
      val email: FieldRole = Val("email", "Email", weight = 1)

      val none: FieldRole = Val("none", "-", weight = 0) // custom FieldRole
      val unknown: FieldRole = Val("unknown", "Unknown", weight = 0) // custom FieldRole

      val all: List[FieldRole] = id :: // class has only one id
        name ::
        email ::
        Nil

      override def fromString(jsonRawValue: String): EnumValue = {
        try {
          withName(jsonRawValue)
        } catch {
          case _: Throwable =>
            log.warn(s"Undefined field role: $jsonRawValue")
            unknown
        }
      }
    }

    object FieldStructure extends Enumeration with JsonEnum {
      case class Val(name: String, label: String) extends super.Val(name)

      type FieldStructure = Val
      type EnumValue = Val

      val singular: FieldStructure = Val("singular", "Singular")
      val array: FieldStructure = Val("array", "Array")

      val unknown: FieldStructure = Val("unknown", "Unknown") // custom FieldStructure

      val all: List[FieldStructure] = singular ::
        array ::
        Nil

      override def fromString(jsonRawValue: String): EnumValue = {
        try {
          withName(jsonRawValue)
        } catch {
          case _: Throwable =>
            log.warn(s"Undefined field structure: $jsonRawValue")
            unknown
        }
      }
    }

    object DataType extends Enumeration with JsonEnum {

      abstract class Val(
                          n: String,
                          l: String,
                          t: DataMapperModels.SupportedType.Type,
                        ) extends super.Val(n) {
        def name: String = n

        def label: String = l

        def basisType: DataMapperModels.SupportedType.Type = t

        override def toString: String = n
      }

      case class DefaultVal(
                             override val name: String,
                             override val label: String,
                             override val basisType: DataMapperModels.SupportedType.Type,
                           ) extends Val(name, label, basisType)

      case class EmbeddedVal(className: Option[String]) extends Val(
        "embedded",
        "Embedded",
        SupportedType.special,
      ) {
        override def toString: String = className.map(c => s"embedded:$c").getOrFail(new Exception("???"))
      }

      case class RecordVal(schemaName: Option[String]) extends Val(
        "record",
        "Record",
        SupportedType.special,
      ) {
        override def toString: String = schemaName.map(c => s"record:$c").getOrElse("record")
      }

      type Type = Val
      type EnumValue = Val

      val string: Type = DefaultVal("string", "String", basisType = SupportedType.string)
      val stringNotNull: Type = DefaultVal("stringNotNull", "StringNotNull", basisType = SupportedType.string)
      val integer: Type = DefaultVal("integer", "Integer", basisType = SupportedType.long)
      val decimal: Type = DefaultVal("decimal", "Decimal", basisType = SupportedType.decimal)
      val boolean: Type = DefaultVal("boolean", "Boolean", basisType = SupportedType.boolean)
      val dateTime: Type = DefaultVal("dateTime", "DateTime", basisType = SupportedType.dateTime)
      val dateTimeNotNull: Type = DefaultVal("dateTimeNotNull", "DateTimeNotNull", basisType = SupportedType.dateTime)
      val date: Type = DefaultVal("date", "Date", basisType = SupportedType.localDate)
      val dateNotNull: Type = DefaultVal("dateNotNull", "DateNotNull", basisType = SupportedType.localDate)
      val stringInteger: Type = DefaultVal("stringInteger", "StringInteger", basisType = SupportedType.long)
      val stringDecimal: Type = DefaultVal("stringDecimal", "StringDecimal", basisType = SupportedType.decimal)
      val stringList: Type = DefaultVal("stringList", "StringList", basisType = SupportedType.listOf(SupportedType.string))
      val dateTimeCompactOffset: Type = DefaultVal("dateTimeCompactOffset", "DateTime (CompactOffset)", basisType = SupportedType.dateTime)
      val emailField: Type = DefaultVal("emailField", "EmailField", basisType = SupportedType.special)
      val embedded: Type = EmbeddedVal(None) // <имя embedded класса> есть связь с флагом Class.embedded
      val record: Type = RecordVal(None) // аналог embedded типа но с описанием в филде
      val enum: Type = DefaultVal("enum", "Enum", basisType = SupportedType.string)
      val integerNotZero: Type = DefaultVal("integerNotZero", "IntegerNotZero", basisType = SupportedType.long)
      val double: Type = DefaultVal("double", "Double", basisType = SupportedType.decimal) // deprecated
      val stringDouble: Type = DefaultVal("stringDouble", "StringDouble", basisType = SupportedType.decimal)
      val relaxedBoolean: Type = DefaultVal("relaxedBoolean", "RelaxedBoolean", basisType = SupportedType.boolean)
      val stringBoolean: Type = DefaultVal("stringBoolean", "StringBoolean", basisType = SupportedType.boolean)
      val jsObject: Type = DefaultVal("jsObject", "JsonObject", basisType = SupportedType.JsValue)


      val unknown: Type = DefaultVal("unknown", "Unknown", SupportedType.none) // custom DataType

      val all: List[Type] = string ::
        stringNotNull ::
        integer ::
        decimal ::
        boolean ::
        dateTime ::
        dateTimeNotNull ::
        date ::
        dateNotNull ::
        stringInteger ::
        stringDecimal ::
        stringList ::
        dateTimeCompactOffset ::
        emailField ::
        embedded ::
        // record ::
        // enum ::
        integerNotZero ::
        stringDouble ::
        relaxedBoolean ::
        stringBoolean ::
        jsObject ::
        Nil

      override def fromString(jsonRawValue: String): EnumValue = {
        try {
          jsonRawValue match {
            case s"embedded:$className" => EmbeddedVal(Option.when(className.nonEmpty)(className))
            case "embedded" => EmbeddedVal(None)
            case s"record:$className" => RecordVal(Some(className))
            case "record" => RecordVal(None)
            // TODO: rethink
            case "string" => string
            case "stringNotNull" => stringNotNull
            case "integer" => integer
            case "decimal" => decimal
            case "boolean" => boolean
            case "dateTime" => dateTime
            case "dateTimeNotNull" => dateTimeNotNull
            case "date" => date
            case "dateNotNull" => dateNotNull
            case "stringInteger" => stringInteger
            case "stringDecimal" => stringDecimal
            case "stringList" => stringList
            case "sforceDateTime" => dateTimeCompactOffset
            case "dateTimeCompactOffset" => dateTimeCompactOffset
            case "emailField" => emailField
            case "enum" => enum
            case "integerNotZero" => integerNotZero
            case "double" => double
            case "stringDouble" => stringDouble
            case "relaxedBoolean" => relaxedBoolean
            case "stringBoolean" => stringBoolean
            case "jsObject" => jsObject
            case _ => withName(jsonRawValue)
          }
        } catch {
          case _: Throwable =>
            log.warn(s"Undefined data type: $jsonRawValue")
            unknown
        }
      }
    }

    case class Standard(
                         name: String = "",
                         requiresAuth: Option[Boolean] = None,
                         classNames: Option[List[String]] = None,
                         classes: Option[List[MetadataModels.Class]] = None,
                         serviceType: Option[common.ServiceType] = None,
                       ) {
      def convertToMetadata: MetadataModels.Metadata = MetadataModels.Metadata(
        name = Some(this.name),
        data = Some(MetadataModels.ClassList(
          classes = this.classes
            .getOrElse(this.classNames
              .map(_.map(n => MetadataModels.Class(name = n)))
              .getOrElse(Nil))
        )),
        serviceType = this.serviceType,
      )

      override def toString: String = this.name
    }

    object MetadataType extends Enumeration with JsonEnum {
      case class Val(value: String, label: String, isProvider: Boolean = false) extends super.Val(value)

      type MetadataType = Val
      type EnumValue = Val

      val client: MetadataType = Val("client", "Virtual")
      val provider: MetadataType = Val("provider", "Provider", isProvider = true)

      val unknown: MetadataType = Val("unknown", "Unknown") // custom MetadataType

      val all: List[MetadataType] = client :: provider :: Nil

      override def fromString(jsonRawValue: String): EnumValue = {
        try {
          withName(jsonRawValue)
        } catch {
          case _: Throwable =>
            log.warn(s"Undefined metadata type: $jsonRawValue")
            unknown
        }
      }
    }
  }

  case object MappingModels {
    private val log = Logger.of[MappingModels.type]

    /**
     * Structure control of Mapping model
     * The backend can not check all structure, so client has structure control too.
     */
    case object Mapping {
      private def UsedFields: Seq[String] =
        "id" ::
          "name" ::
          "data" ::
          "createdAt" ::
          "updatedAt" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the metadata")
                Some(diff)
              } else {
                val jData = obj.apply("data")
                if (jData.isDefined)
                  ClassList.CheckFieldNames(jData.get)
                else
                  Some(Nil)
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * Mapping is the base model for all mapping requests.
     */
    case class Mapping(
                        id: Option[Long] = None, // readOnly
                        name: Option[String] = None,
                        data: Option[ClassList] = None,
                        createdAt: Option[Instant] = None, // readOnly
                        updatedAt: Option[Instant] = None, // readOnly
                      ) {
      def classes: List[MappingModels.Class] = data.getOrElse(MappingModels.ClassList()).classMappings

      override def toString: String = this.name.getOrElse(s"#${this.id.getOrElse[Long](0)}")
    }

    /**
     * Structure control of ClassList model
     * The backend can not check all structure, so client has structure control too.
     */
    case object ClassList {
      private def UsedFields: Seq[String] =
        "classMappings" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the class model")
                Some(diff)
              } else {
                val jClasses = obj.apply("classMappings")
                if (jClasses.isDefined && jClasses.get.isArray)
                  jClasses.get.asArray.getOrElse(Nil)
                    .toList
                    .map(Class.CheckFieldNames)
                    .fold(None)((a, b) =>
                      if (a.isEmpty && b.isEmpty) None
                      else if (a.isDefined && b.isEmpty) a
                      else if (a.isEmpty && b.isDefined) b
                      else Some((a.get ++ b.get).distinct))
                else
                  Some(Nil)
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * ClassList mapping model for group all classes
     */
    case class ClassList(
                          classMappings: List[MappingModels.Class] = Nil,
                        ) {
      override def toString: String = this.classMappings.map(_.toString).mkString(", ")
    }

    /**
     * Structure control of Class mapping model
     * The backend can not check all structure, so client has structure control too.
     */
    case object Class {
      private def UsedFields: Seq[String] =
        "providerClass" ::
          "clientClass" ::
          "fieldMappings" ::
          "virtual" ::
          "relationMappings" ::
          "comment" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the class model")
                Some(diff)
              } else {
                // checking fields
                val jFields = obj.apply("fieldMappings")
                val fields: Option[Seq[String]] = if (jFields.isDefined && jFields.get.isArray)
                  jFields.get.asArray.getOrElse(Nil)
                    .toList
                    .map(Field.CheckFieldNames)
                    .fold(None)((a, b) =>
                      if (a.isEmpty && b.isEmpty) None
                      else if (a.isDefined && b.isEmpty) a
                      else if (a.isEmpty && b.isDefined) b
                      else Some((a.get ++ b.get).distinct))
                else
                  Some(Nil)
                // checking relations
                val jRelations = obj.apply("relationMappings")
                val relations: Option[Seq[String]] = if (jRelations.isDefined && jRelations.get.isArray)
                  jRelations.get.asArray.getOrElse(Nil)
                    .toList
                    .map(Relation.CheckFieldNames)
                    .fold(None)((a, b) =>
                      if (a.isEmpty && b.isEmpty) None
                      else if (a.isDefined && b.isEmpty) a
                      else if (a.isEmpty && b.isDefined) b
                      else Some((a.get ++ b.get).distinct))
                else
                  Some(Nil)
                // merging the fields and relation wrong columns
                if (fields.isEmpty && relations.isEmpty) None
                else if (fields.isDefined && relations.isEmpty) fields
                else if (fields.isEmpty && relations.isDefined) relations
                else Some((fields.get ++ relations.get).distinct)
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * Class mapping model
     */
    case class Class(
                      providerClass: Option[String] = None,
                      clientClass: String = "",
                      fieldMappings: List[MappingModels.Field] = Nil,
                      virtual: Option[Boolean] = None,
                      relationMappings: List[MappingModels.Relation] = Nil,
                      comment: Option[String] = None,
                    ) {
      def findField(name: String): Option[MappingModels.Field] =
        fieldMappings.find(f => f.clientFields.contains(name))

      override def toString: String = s"${this.clientClass}<->${this.providerClass.getOrElse[String]("#virtual")}"
    }

    /**
     * Structure control of Field mapping model
     * The backend can not check all structure, so client has structure control too.
     */
    case object Field {
      private def UsedFields: Seq[String] =
        "providerFields" ::
          "clientFields" ::
          "direction" ::
          "rawValue" ::
          "mapper" ::
          "mappingDetails" ::
          "readMapper" ::
          "writeMapper" ::
          "structureMapper" ::
          "readStructureMapper" ::
          "writeStructureMapper" ::
          "providerFieldSelector" ::
          "tag" ::
          "description" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the field model")
                Some(diff)
              } else {
                None
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * Field mapping model
     */
    case class Field(
                      providerFields: Seq[String] = Nil,
                      clientFields: Seq[String] = Nil,
                      direction: Option[MappingModels.Direction.Direction] = None,
                      rawValue: Option[String] = None, // only json string
                      mapper: Option[MappingModels.MappingFunction.Mapper] = None,
                      mappingDetails: Option[io.circe.Json] = None,
                      readMapper: Option[MappingModels.MappingFunction.Mapper] = None,
                      writeMapper: Option[MappingModels.MappingFunction.Mapper] = None,
                      structureMapper: Option[MappingModels.StructureMappingFunction.Mapper] = None,
                      readStructureMapper: Option[MappingModels.StructureMappingFunction.Mapper] = None,
                      writeStructureMapper: Option[MappingModels.StructureMappingFunction.Mapper] = None,
                      providerFieldSelector: Option[MappingModels.ProviderFieldSelector.ProviderFieldSelector] = None,
                      tag: Option[String] = None,
                      description: Option[String] = None,
                    ) {
      def name: String = clientFields.mkString(",")

      def isEmpty: Boolean = clientFields.isEmpty || providerFields.isEmpty || rawValue.getOrElse("").isEmpty

      def isVirtual: Boolean = clientFields.nonEmpty && providerFields.isEmpty && rawValue.getOrElse("").nonEmpty

      override def toString: String = {
        val c = this.clientFields.sorted.mkString(",")
        val p = this.providerFields.sorted.mkString(",")
        this.direction match {
          case Some(MappingModels.Direction.readOnly) => s"$c<--$p"
          case Some(MappingModels.Direction.writeOnly) => s"$c-->$p"
          case Some(MappingModels.Direction.bidirectional) => s"$c}<->$p"
          case _ => s"$c--$p"
        }
      }
    }

    /**
     * Structure control of Relation mapping model
     * The backend can not check all structure, so client has structure control too.
     */
    case object Relation {
      private def UsedFields: Seq[String] =
        "providerRelation" ::
          "clientRelation" ::
          Nil

      def CheckFieldNames(rawData: io.circe.Json): Option[Seq[String]] = {
        if (rawData.isObject)
          rawData.asObject match {
            case Some(obj) =>
              val diff = obj.keys.toSeq.diff(UsedFields)
              if (diff.nonEmpty) {
                log.warn(s"not found a columns ${diff.mkString(", ")} in the relation model")
                Some(diff)
              } else {
                None
              }
            case None =>
              log.warn(s"json data is not an object")
              Some(Nil)
          }
        else {
          log.warn(s"incorrect json type")
          Some(Nil)
        }
      }
    }

    /**
     * Relation mapping model
     */
    case class Relation(
                         providerRelation: String = "",
                         clientRelation: String = "",
                       ) {
      def isEmpty: Boolean = clientRelation.isEmpty || providerRelation.isEmpty

      override def toString: String = s"${this.clientRelation}<->${this.providerRelation}"
    }

    object ProviderFieldSelector extends Enumeration with JsonEnum {
      case class Val(value: String, label: String) extends super.Val(value)

      type ProviderFieldSelector = Val
      type EnumValue = Val

      val email: ProviderFieldSelector = Val("email", "email")

      val none: ProviderFieldSelector = Val("none", "-")
      val unknown: ProviderFieldSelector = Val("unknown", "unknown")

      val all: List[ProviderFieldSelector] = email ::
        Nil

      override def fromString(jsonRawValue: String): EnumValue = {
        try {
          withName(jsonRawValue)
        } catch {
          case _: Throwable =>
            log.warn(s"Undefined provider field selector: $jsonRawValue")
            unknown
        }
      }
    }

    object Direction extends Enumeration with JsonEnum {
      case class Val(
                      value: String,
                      label: String,
                      separator: String,
                      icon: String,
                      description: String,
                      read: Boolean = false,
                      write: Boolean = false,
                      weight: Int = 0,
                    ) extends super.Val(value)

      type Direction = Val
      type EnumValue = Val

      val readOnly: Direction = Val("readOnly", "Read only", "<--", "<--", "Read from provider only", read = true, weight = 1)
      val writeOnly: Direction = Val("writeOnly", "Write only", "-->", "-->", "Write to provider only", write = true, weight = 2)
      val bidirectional: Direction = Val("bidirectional", "Bidirectional", "}<->", "<->", "Read or write operation", read = true, write = true, weight = 3)
      val unknown: Direction = Val("unknown", "unknown", "", "", "")

      val all: List[Direction] = readOnly ::
        writeOnly ::
        bidirectional ::
        Nil

      override def fromString(jsonRawValue: String): EnumValue = {
        try {
          withName(jsonRawValue)
        } catch {
          case _: Throwable =>
            log.warn(s"Undefined direction: $jsonRawValue")
            unknown
        }
      }
    }

    object MappingFunction extends Enumeration with JsonEnum {
      abstract class Val(n: String, l: String) extends super.Val(n) {
        def name: String = n

        def label: String = l

        override def toString: String = n
      }

      case class DefaultVal(
                             override val name: String,
                             override val label: String,
                             private val providerType: SupportedType.Type = SupportedType.none,
                             private val clientType: SupportedType.Type = SupportedType.none,
                             private val providerStructure: Option[MetadataModels.FieldStructure.FieldStructure] = Some(MetadataModels.FieldStructure.singular),
                             private val clientStructure: Option[MetadataModels.FieldStructure.FieldStructure] = Some(MetadataModels.FieldStructure.singular),
                           ) extends Val(name, label)

      case class RegexExtractVal(
                                  pattern: Option[String],
                                ) extends Val(
        s"regexExtract${pattern.map(s => s"($s)").getOrElse[String]("")}",
        s"Extract with RegEx${pattern.map(s => s"($s)").getOrElse[String]("")}"
      ) {
        override def toString: String = s"regexExtract${pattern.map(s => s"($s)").getOrElse[String]("")}"
      }

      type Mapper = Val
      type EnumValue = Val

      val identity: Mapper = DefaultVal("identity", "no mapper", providerStructure = None, clientStructure = None) // ничего не делает
      val integerToString: Mapper = DefaultVal("integerToString", "Integer to string", providerType = SupportedType.long, clientType = SupportedType.string)
      val stringToInteger: Mapper = DefaultVal("stringToInteger", "String to integer", providerType = SupportedType.string, clientType = SupportedType.long)
      val decimalToString: Mapper = DefaultVal("decimalToString", "Decimal to string", providerType = SupportedType.decimal, clientType = SupportedType.string)
      val stringToDecimal: Mapper = DefaultVal("stringToDecimal", "String to decimal", providerType = SupportedType.string, clientType = SupportedType.decimal)
      val integerToDecimal: Mapper = DefaultVal("integerToDecimal", "Integer to decimal", providerType = SupportedType.long, clientType = SupportedType.decimal)
      val decimalToInteger: Mapper = DefaultVal("decimalToInteger", "Decimal to integer", providerType = SupportedType.decimal, clientType = SupportedType.long)
      val integerToDouble: Mapper = DefaultVal("integerToDouble", "Integer to double", providerType = SupportedType.long, clientType = SupportedType.double) // deplicated: analog the integerToDecimal
      val doubleToInteger: Mapper = DefaultVal("doubleToInteger", "Double to integer", providerType = SupportedType.double, clientType = SupportedType.long) // duplicated: analog the decimalToInteger
      val selectFirst: Mapper = DefaultVal("selectFirst", "Select first of array", providerStructure = Some(MetadataModels.FieldStructure.array)) // выбрать первое значение из списка полей
      val populateMany: Mapper = DefaultVal("populateMany", "Fill all", clientStructure = Some(MetadataModels.FieldStructure.array)) // заполнить список полей одним значением
      val dateOnlyUserTimezone: Mapper = DefaultVal("dateOnlyUserTimezone", "Date by user timezone", providerType = SupportedType.dateTime, clientType = SupportedType.localDate) // date/time -> date only в таймзоне юзера
      val stringAsDateNormalize: Mapper = DefaultVal("stringAsDateNormalize", "String as date normalize", providerType = SupportedType.string, clientType = SupportedType.string) // затрудняюсь сказать, что это. надо у Толи спросить. судя по типам, он конвертирует String -> String
      val atMidnightUserTimezone: Mapper = DefaultVal("atMidnightUserTimezone", "Date time at midnight user timezone", providerType = SupportedType.localDate, clientType = SupportedType.dateTime) // date only -> date/time в полночь по пользовательской таймзоне
      val stringToEmailField: Mapper = DefaultVal("stringToEmailField", "String to email field", providerType = SupportedType.string, clientType = SupportedType.special) // что-то старое, надо тоже уточнить у Толи
      val emailFieldToString: Mapper = DefaultVal("emailFieldToString", "Email field to string", providerType = SupportedType.special, clientType = SupportedType.string) // что-то старое, надо тоже уточнить у Толи
      val sugarEmailToEmailField: Mapper = DefaultVal("sugarEmailToEmailField", "Sugar email to email field", providerType = SupportedType.special, clientType = SupportedType.special) // что-то старое, надо тоже уточнить у Толи
      val emailFieldToSugarEmail: Mapper = DefaultVal("emailFieldToSugarEmail", "Email field to sugar email", providerType = SupportedType.special, clientType = SupportedType.special) // что-то старое, надо тоже уточнить у Толи
      val htmlToString: Mapper = DefaultVal("htmlToString", "HTML to string", providerType = SupportedType.string, clientType = SupportedType.string) // html -> text
      val splitAtSemicolon: Mapper = DefaultVal("splitAtSemicolon", "Split at semicolon", providerType = SupportedType.string, clientType = SupportedType.listOf(SupportedType.string)) // string -> stringList, разделяя по ;. тоже подзабыл, используется ли это где-то сейчас
      val gatherBySemicolon: Mapper = DefaultVal("gatherBySemicolon", "Gather by semicolon", providerType = SupportedType.listOf(SupportedType.string), clientType = SupportedType.string) // stringList -> string, join по точке с запятой.
      val stringToDateTime: Mapper = DefaultVal("stringToDateTime", "String to date time", providerType = SupportedType.string, clientType = SupportedType.dateTime)
      val dateTimeToString: Mapper = DefaultVal("dateTimeToString", "Date time to string", providerType = SupportedType.dateTime, clientType = SupportedType.string)
      val enumerated: Mapper = DefaultVal("enumarated", "Enumarated", providerType = SupportedType.special, clientType = SupportedType.special)
      val regexExtract: Mapper = RegexExtractVal(None)
      val unknown: Mapper = DefaultVal("unknown", "unknown", providerStructure = None, clientStructure = None)

      val all: Seq[Mapper] = Seq(
        identity,
        integerToString,
        stringToInteger,
        decimalToString,
        stringToDecimal,
        integerToDecimal,
        decimalToInteger,
        //integerToDouble,
        //doubleToInteger,
        selectFirst,
        populateMany,
        dateOnlyUserTimezone,
        stringAsDateNormalize,
        atMidnightUserTimezone,
        //stringToEmailField,
        //emailFieldToString,
        //sugarEmailToEmailField,
        //emailFieldToSugarEmail,
        htmlToString,
        splitAtSemicolon,
        gatherBySemicolon,
        stringToDateTime,
        dateTimeToString,
        // enumerated,
        //regexExtract,
      )

      def activeFor(providerFields: Option[Seq[MetadataModels.Field]], clientFields: Option[Seq[MetadataModels.Field]]): Seq[Mapper] =
        all
          .filter {
            case DefaultVal(_, _, pType, cType, Some(MetadataModels.FieldStructure.array), Some(MetadataModels.FieldStructure.array)) =>
              val pTypeCondition = pType == SupportedType.special || providerFields.forall(_.exists(_.`type`.basisType == pType))
              val cTypeCondition = cType == SupportedType.special || clientFields.forall(_.exists(_.`type`.basisType == cType))
              pTypeCondition && cTypeCondition
            case DefaultVal(_, _, pType, cType, Some(MetadataModels.FieldStructure.singular), Some(MetadataModels.FieldStructure.singular)) =>
              val pTypeCondition = pType == SupportedType.special || providerFields.forall(_.exists(_.`type`.basisType == pType))
              val cTypeCondition = cType == SupportedType.special || clientFields.forall(_.exists(_.`type`.basisType == cType))
              pTypeCondition && cTypeCondition
            case DefaultVal(_, _, pType, cType, pStructure, cStructure) =>
              val pTypeCondition = pType == SupportedType.special || providerFields.forall(_.exists(_.`type`.basisType == pType))
              val cTypeCondition = cType == SupportedType.special || clientFields.forall(_.exists(_.`type`.basisType == cType))
              val pStructureCondition = pStructure.forall(s => providerFields.forall(_.exists(_.structure == s)))
              val cStructureCondition = cStructure.forall(s => clientFields.forall(_.exists(_.structure == s)))
              pTypeCondition && cTypeCondition && pStructureCondition && cStructureCondition
            case RegexExtractVal(pattern) if pattern.nonEmpty =>
              providerFields.forall(_.exists(_.`type`.basisType == SupportedType.string)) &&
                clientFields.forall(_.exists(_.`type`.basisType == SupportedType.string)) &&
                providerFields.forall(_.exists(_.structure == MetadataModels.FieldStructure.singular)) &&
                clientFields.forall(_.exists(_.structure == MetadataModels.FieldStructure.singular))
            case _ => false
          }

      override def fromString(jsonRawValue: String): EnumValue = {
        try {
          jsonRawValue match {
            case s"regexExtract($p)" if p.nonEmpty => RegexExtractVal(Some(p))
            case s"regexExtract" => RegexExtractVal(None)
            case v => withName(v)
          }
        } catch {
          case _: Throwable =>
            log.warn(s"Undefined mapper: $jsonRawValue")
            unknown
        }
      }
    }

    object StructureMappingFunction extends Enumeration with JsonEnum {
      case class Val(value: String,
                     label: String,
                     description: String = "") extends super.Val(value)

      type Mapper = Val
      type EnumValue = Val

      val direct: Mapper = Val("direct", "Direct")
      val expand: Mapper = Val("expand", "Expand")
      val shrink: Mapper = Val("shrink", "Shrink")
      val unknown: Mapper = Val("unknown", "unknown")

      val all: List[Mapper] = direct ::
        expand ::
        shrink ::
        Nil

      override def fromString(jsonRawValue: String): EnumValue = {
        try {
          withName(jsonRawValue)
        } catch {
          case _: Throwable =>
            log.warn(s"Undefined structure mapper: $jsonRawValue")
            unknown
        }
      }
    }
  }

  /**
   * DescriptionModels don't use in the frontend.
   */
  case object DescriptionModels {
    //private val log = Logger.of[DescriptionModels.type]

    case class Description(
                            id: Option[Long] = None,
                            name: Option[String] = None,
                            serviceType: Option[common.ServiceType] = None,
                          )

    case class Standard(
                         name: String = "",
                         serviceType: Option[common.ServiceType] = None,
                       )
  }
}

