package root_pages.aurinko_pages.app.sync

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Fab
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.mat_components_styles.fixFabStyle
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.{InstantOps, JsonEnum, SyncApiPage, SyncObjFilters, SyncRuleType, TasksQuery}
import org.scalajs.dom
import root_pages.aurinko_pages.app.sync.widgets.{Flags, MergeHistoryGrid, ServicesGrid, SortableTableColumnHeader}
import portal_router.SyncUserPage
import service.apis.sync_api.SyncApi
import service.scroll_ops.ScrollOps

object UserTasksSyncComponent {
  case class Handler($route: Signal[SyncUserPage], syncApi: SyncApi, filters: SyncObjFilters) {
    val reloadBus = new EventBus[Unit]
    val $tasks: EventStream[SyncApiPage[TasksQuery]] = reloadBus.events.sample($route)
      .flatMap { route =>
        syncApi.ruleQuery(
          route.appKey,
          route.orgId,
          route.userId,
          SyncRuleType.Tasks,
          filters,
        )
      }
      .map(_.asInstanceOf[SyncApiPage[TasksQuery]])

    def renderRow(routeInfo: SyncUserPage)(
      @annotation.unused id: Int,
      initialTask: TasksQuery,
      $shortTask: EventStream[TasksQuery],
    ): Div = {

      val task: Var[(TasksQuery, Boolean)] = Var[(TasksQuery, Boolean)]((TasksQuery(id = 0), false))
      val $task: Signal[TasksQuery] = task.signal.map(_._1)
      val expanded = Var(false)

      val panel = ExpansionPanelComponent(
        header = div(
          cls := "slds-grid slds-grid--vertical-align-center growing-block au-truncate",
          div(cls := "slds-size--1-of-12 au-truncate", child.text <-- $task.map(_.id.toString)),
          div(cls := "slds-size--3-of-12 au-truncate", child.text <-- $task.map(_.subject).map { case None => "" case Some(v) => v }),
          div(cls := "slds-size--1-of-12 au-truncate", child.text <-- $task.map(_.source).map { case None => "" case Some(v) => v }),
          div(cls := "slds-size--2-of-12 au-truncate", child.text <-- $task.map(_.dueDate).map { case None => "" case Some(v) => v.toPrettyLocalFormat }),
          div(cls := "slds-size--2-of-12 au-truncate", child.text <-- $task.map(_.updateTime).map { case None => "" case Some(v) => v.toPrettyLocalFormat }),
          div(cls := "slds-size--3-of-12 au-truncate",
            Flags(
              $task.map(e => (
                (e.autoMerged, Flags.Badge(label = "auto merged")) ::
                  (e.syncEnabled, Flags.Badge(label = "sync enabled")) ::
                  (e.sameAsId > 0 && e.skipMerge, Flags.Badge(label = "target to merge")) ::
                  (e.sameAsId > 0 && e.sureMatch, Flags.Badge(label = "duplicate")) ::
                  (e.errorsDuringSync, Flags.Badge(label = "error", cls = "orange")) ::
                  (e.`private`, Flags.Badge(label = "private")) ::
                  Nil
                ).filter(v => v._1).map(_._2))
            ),
          ),
        ),
        body = Some(div(
          child.maybe <-- $task.map(_.subject).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Subject"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $task.map(_.ctgName).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Category"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $task.map(_.startDate).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Start date"),
              span(cls := "au-truncate", v.toPrettyLocalFormat),
            ))
          },

          child.maybe <-- $task.map(_.dueDate).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Due date"),
              span(cls := "au-truncate", v.toPrettyLocalFormat),
            ))
          },

          child <-- $task.map(e => (
            ("Private", e.`private`) ::
              ("Complete", e.`complete`) ::
              Nil).filter(e => e._1.nonEmpty && e._2).map(v => v._1)).map { l =>
            div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Task type"),
              div(cls := "au-truncate", l.map(v => span(cls := s"badge slds-m-right--x-small", v))),
            )
          },

          child <-- $task.map(e => if (e.remind && e.remindAt.isDefined) e.remindAt.get.toPrettyLocalFormat else "None").map(v => div(
            cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
            small(cls := "au-truncate gray", "Reminder"),
            div(cls := "au-truncate",
              span(cls := "mdc-layout-grid__cell mdc-layout-grid__cell--span-9", v),
            ),
          )),

          child.maybe <-- $task.map(_.notes).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Notes"),
              pre(cls := "slds-m-around--none", v),
            ))
          },

          Flags.Grid(
            $task.map(e => (
              (e.autoMerged, Flags.Badge(label = "Auto merged")) ::
                (e.syncEnabled, Flags.Badge(label = "Sync enabled")) ::
                (e.sameAsId > 0 && e.skipMerge, Flags.Badge(label = "Target to merge")) ::
                (e.sameAsId > 0 && e.sureMatch, Flags.Badge(label = "Duplicate")) ::
                (e.errorsDuringSync, Flags.Badge(label = "Error", cls = "orange")) ::
                (e.`private`, Flags.Badge(label = "Private")) ::
                Nil
              ).filter(v => v._1).map(_._2))
          ),

          ServicesGrid($task.map(_.accountsSummary.getOrElse(Nil))),

          MergeHistoryGrid($task.map(_.mergeHistory.getOrElse(Nil))),
        )),
        horizontalPadding = false,
        expanded = expanded.signal,
        onExpansionChange = expanded.writer
      )

      div(
        $shortTask --> Observer.combine(
          task.writer.contramap((x: TasksQuery) => (x, true)),
          expanded.writer.contramap((_: TasksQuery) => false),
        ),

        panel.$isExpanded.withCurrentValueOf(task.signal.map(_._2)).changes
          .filter(x => x._1 && x._2)
          .flatMap(_ => syncApi.ruleObject(routeInfo.appKey, routeInfo.orgId, routeInfo.userId, SyncRuleType.Tasks, initialTask.id))
          .map(_.asInstanceOf[TasksQuery])
          --> task.writer.contramap((x: TasksQuery) => (x, false)),

        panel.node
      )
    }
  }

  object Filters extends Enumeration with JsonEnum {
    case class Val(name: String, label: String) extends super.Val(name)

    type Filters = Val
    type EnumValue = Val

    val autoMerged: Filters = Val("autoMerged", "Auto merged")
    val syncEnabled: Filters = Val("syncEnabled", "Sync enabled")
    val showDupes: Filters = Val("showDupes", "Duplicate")
    val errors: Filters = Val("errors", "Errors")
    val `private`: Filters = Val("private", "Private")
    val incomplete: Filters = Val("incomplete", "Incomplete")

    val All: List[Filters] = autoMerged :: syncEnabled :: showDupes :: errors :: `private` :: incomplete :: Nil
  }

  object Columns extends Enumeration {
    case class Val(label: String, sizeCss: String, sortValue: Option[String] = None)

    type Columns = Val

    val id = Val("Id", "slds-size--1-of-12")
    val subject = Val("Subject", "slds-size--3-of-12", Some("Subject"))
    val source = Val("Source", "slds-size--1-of-12")
    val dueDate = Val("Due date", "slds-size--2-of-12", Some("dueDate"))
    val updateTime = Val("Update time", "slds-size--2-of-12", Some("updateTime"))
    val badges = Val("", "slds-size--3-of-12", None)

    val all = id :: subject :: source :: dueDate :: updateTime :: badges :: Nil
  }

  // UI\HTML structures
  def apply(
             $route: Signal[SyncUserPage],
             syncApi: SyncApi,
             defaultFilters: Var[Set[String]] = Var(Set.empty),
             $reload: EventStream[Unit],
             documentScrollOps: ScrollOps
           ): Div = {
    val handler = Handler($route, syncApi, SyncObjFilters(defaultFilters))

    div(
      common.ui.Attribute.Selector := "sync.user_tasks_sync",

      $reload --> handler.reloadBus.writer,

      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread slds-m-top--medium",
        div(
          cls := "slds-grid slds-grid--vertical-align-center",
          Filters.All.map(filter =>
            Fab(
              _.label := filter.label,
              _.extended := true,
              _.showIconAtEnd := true,
              _.icon <-- handler.filters.$filters.map(_.contains(filter.name)).map { case true => "cancel" case false => "" },
              _ => cls := "slds-m-right--x-small",
              _ => cls <-- handler.filters.$filters.map(_.contains(filter.name)).map { case true => "" case _ => "inactive" },
              _ => onClick --> Observer[dom.MouseEvent](onNext = _ => handler.filters.toggleFilter(filter.name)),
              _ => onMountCallback(fixFabStyle)
            )
          )
        ),
        SearchInputComponent(onChange = handler.filters.searchText.writer).node
      ),

      child <-- $route.map { route =>

        div(
          child <-- handler.$tasks.map(_.summary.nonEmpty).toSignal().map {
            case true => div(
              cls := "data-table slds-m-top--medium",
              div(
                cls := "table-header with-expansion-panels",
                Columns.all.map{
                  case c if c.sortValue.isEmpty => span(c.label, cls := c.sizeCss)
                  case column => SortableTableColumnHeader(
                    label = column.label,
                    onSorted = Observer[dom.MouseEvent](onNext = _ => handler.filters.toggleSortParam(column.sortValue.get)),
                    state = handler.filters.sortParamState(column.sortValue.get),
                    cssClass = column.sizeCss
                  )
                }
              ),

              div(children <-- handler.$tasks.map(_.summary).split(_.id)(handler.renderRow(route))),

              div(
                cls <-- handler.$tasks.map(_.total < handler.filters.pagesLimit.now).map { case true => "hidden" case false => "" },
                Paginator(
                  pageNum = handler.filters.pageNum.signal,
                  totalCount = handler.$tasks.map(_.total).startWith(0),
                  pageSize = handler.filters.pagesLimit.signal,
                  onPageChange = Observer[(Int, Int)](onNext = i => {
                    handler.filters.pageNum.set(i._1)
                    handler.filters.pagesLimit.set(i._2)
                    handler.reloadBus.emit(())
                  }),
                  documentScrollTopAfterPageChange = true,
                  documentScrollOps.some,
                  itemsPluralLabel = "Tasks"
                ).node
              )
            )
            case _ => p(cls := "gray slds-grid slds-grid--align-center slds-m-around--large", "No tasks")
          }
        )
      },

      handler.filters.$filters
        .combineWith(handler.filters.searchText.signal)
        .combineWith(handler.filters.$sorting)
         --> Observer[(Set[String], String, Set[String])](onNext = _ => {
          handler.filters.pageNum.set(0)
          handler.reloadBus.emit(())
        }),
    )
  }
}
