package service.stripe

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.BillingModels.BillingType.Value
import common.JsonEnum
import io.circe.generic.auto.exportEncoder
import io.circe.syntax.EncoderOps
import org.scalajs.dom
import org.scalajs.dom.html
import service.stripe.StripeElementName.StripeElementName
import service.stripe.StripeUiTools.createElement
import wvlet.log.Logger

import scala.scalajs.js
import scala.scalajs.js.annotation.{JSExportAll, JSGlobal}

@js.native
@JSGlobal
class Stripe(key: String) extends js.Object {

  def elements(): Elements = js.native

  def createPaymentMethod(options: js.Object): js.Promise[StripeCreatePaymentMethodResponse] = js.native

}

//@JSExportAll
//class StripeBillingAddress(city: String,
//                           country: String,
//                           line1: String,
//                           line2: String,
//                           postal_code: String,
//                           state: String)
//
//@JSExportAll
//class StripeBillingDetails(email: String, phone: String, name: String, address: StripeBillingAddress)

@js.native
trait Elements extends js.Object {
  def create(element: String, options: js.Object): StripeElement = js.native
}

@js.native
trait StripeElement extends js.Object {
  def mount(containerSelector: String): Unit = js.native
  def destroy(): Unit = js.native
  def clear(): Unit = js.native

  def on(eventName: String, handler: js.Function1[StripeElementEvent, Unit]): Unit = js.native
}

@js.native
trait StripeElementEvent extends js.Object {

  val complete: Boolean = js.native
  val brand: String
  val elementType: String
  val empty: Boolean

}

@js.native
trait StripeCreatePaymentMethodResponse extends js.Object {

  val error: js.UndefOr[CreatePaymentMethodError] = js.native
  val paymentMethod: js.UndefOr[PaymentMethodResponseBody] = js.native

}

@js.native
trait PaymentMethodResponseBody extends js.Object {
  val id: String = js.native
}

@js.native
trait CreatePaymentMethodError extends js.Object {
  val `type`: String = js.native
  val code: String = js.native
  val message: String = js.native
}

object StripeUiTools {

  private val log = Logger("StripeUiTools")

  def createCardElement(elements: Elements): StripeElement = {
    createElement(StripeElementName.card.toString, elements, js.Dynamic.literal(
      "style" -> AuStripeStyleTools.AuStripeCardElementStyle,
    "hidePostalCode" -> true).some)
  }

//    def createAddressElement(elements: Elements): StripeElement = {
//      createElement(StripeElementName.address.toString, elements, js.Dynamic.literal(
//        "style" -> AuStripeStyleTools.AuStripeCardElementStyle,
//        "mode" -> "billing").some
//      )
//
//  }
//
//  def createPaymentElement(elements: Elements): StripeElement = {
//    createElement(StripeElementName.payment.toString, elements, js.Object().some)
//  }

  private def createElement(elemName: String, elements: Elements, options: Option[js.Object]): StripeElement = {
    elements.create(elemName, options.getOrElse(js.Object()))
  }

  private def renderElement(element: StripeElement, elementName: String)(container: ReactiveHtmlElement[dom.html.Element]) = {
    container.amend(
      idAttr := "stripe-" + elementName,
      onMountCallback(_ => element.mount("#" + "stripe-" + elementName))
    )
    container
  }

  implicit final class StripeElementsOps(private val container: ReactiveHtmlElement[dom.html.Element]) extends AnyVal {

    def addElement(element: StripeElement, name: StripeElementName): ReactiveHtmlElement[html.Element] = renderElement(element, name.toString)(container)

    def addCardElement(element: StripeElement): ReactiveHtmlElement[html.Element] = renderElement(element, "card")(container)
//    def addAddressElement(element: StripeElement): ReactiveHtmlElement[html.Element] = renderElement(element, "address")(container)

  }
}

object AuStripeStyleTools {

  private val AuStripeCardElementStyleBase = js.Dynamic.literal(
    "fontFamily" -> "'Nunito', 'Noto', sans-serif",
    "fontSize" -> "14px",
    "iconColor" -> "#F7931E",
    "color" -> "#63340a"
  )

  val AuStripeCardElementStyle = js.Dynamic.literal(
    "base" -> AuStripeCardElementStyleBase)
}

object StripeElementName extends Enumeration {
  type StripeElementName = Value

  val card, payment, address, cardNumber, cardExpiry, cardCvc = Value
}
