package root_pages.aurinko_pages.app.virtual_api.components.dialogs

import com.github.uosis.laminar.webcomponents.material
import com.raquo.airstream.core.Observer
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import common.ui.{AuFormCheckbox, AuFormField, AuFormSelect, AuFormState}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.mat_components_styles.{fixMwcDialogOverflow, fixMwcDialogOverflowEx}
import org.scalajs.dom
import service.apis.dynamic_api.DataMapperModels.MetadataModels
import service.apis.dynamic_api.DataMapperModels
import service.portal_state.PortalState
import wvlet.log.Logger

import scala.util.matching.Regex

case object metadata {
  private val log = Logger.of[metadata.type]

  private def shrinkValidator(signals: Seq[Signal[Boolean]]): Signal[Boolean] =
    Signal.combineSeq[Boolean](signals)
      .map(x => {
        log.info(s"custom validation: ${x.map(_.toString).mkString(", ")}")
        !x.contains(false)
      })

  /**
   * Metadata creating new dialog controller, if you want to show the dialog, need to emit non null value to model.
   * Arguments:
   *  - onChange - observer for the success result, accepting pair parameters:
   *    `String` is the old metadata name
   *    `MetadataModels.Metadata` is the new metadata model
   */
  class Metadata(
                  private val portalState: PortalState,
                  private val onChange: Observer[(String, MetadataModels.Metadata)],
                  private val `type`: Signal[Option[MetadataModels.MetadataType.MetadataType]] = Signal.fromValue(None),
                ) {
    private val model: Var[Option[MetadataModels.Metadata]] = Var(None)

    private case class MutableModel(src: MetadataModels.Metadata) {
      val name: Var[String] = Var(src.name.getOrElse[String](""))
      val data: Var[MetadataModels.ClassList] = Var(src.data.getOrElse[MetadataModels.ClassList](MetadataModels.ClassList()))
      val serviceType: Var[Option[common.ServiceType]] = Var(src.serviceType)

      def toImmutableModel: MetadataModels.Metadata = src.copy(
        id = src.id,
        name = Some(this.name.now()),
        data = Some(this.data.now()),
        serviceType = this.serviceType.now(),
        createdAt = src.createdAt,
        updatedAt = src.updatedAt,
      )
    }

    private case class Controller(m: MutableModel) {
      private val nameAuFormEl: AuFormField = AuFormField(material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.outlined := true,
        _.label := "Name",
        _.required := true,
        _.value <-- m.name,
        _ => onInput.mapToValue --> m.name
      ))
      private val serviceTypeAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Service type",
        _.value <-- m.serviceType.signal.map(_.getOrElse(common.ServiceType.unknown).value),
        _.required := true,
        _ => children <-- `type`
          .map {
            case Some(MetadataModels.MetadataType.provider) => Nil
            case _ => material.List.ListItem(
              _.value := common.ServiceType.unknown.value,
              _.selected <-- m.serviceType.signal.map(_.getOrElse(common.ServiceType.unknown).value == common.ServiceType.unknown.value),
              _ => "Virtual",
              _ => onClick.mapTo(None) --> m.serviceType.writer,
            ) :: li(cls := "mdc-list-divider") :: Nil
          },
        _ => children <-- portalState.$serviceTypes
          .map {
            _
              .filter(_.capabilities.contains(common.PortalAppCapability.apiCrm))
              .map {
                serviceType =>
                  material.List.ListItem(
                    _.value := serviceType.value,
                    _.selected <-- m.serviceType.signal.map(_.contains(serviceType)),
                    _ => serviceType.label,
                    _ => onClick.mapTo(Some(serviceType)) --> m.serviceType.writer,
                  )
              }
          }
          .map {
            case items if items.isEmpty => Seq[material.List.ListItem.El](material.List.ListItem(
              _ => "no types",
              _.selected := false,
              _.value := "no-types",
              _.disabled := true,
            ))
            case items => items.toSeq
          },
      ))

      private def customValidation: Seq[Signal[Boolean]] = Seq(
        m.name.signal
          .map(_.trim.nonEmpty),
        m.serviceType.signal
          .map(_.getOrElse(common.ServiceType.unknown))
          .combineWith(`type`)
          .map(x => !x._2.exists(_.value == MetadataModels.MetadataType.provider.value) || x._1.value != common.ServiceType.unknown.value),
      )

      def buttons: HtmlElement = {
        val state: AuFormState = AuFormState(
          nameAuFormEl :: Nil,
          Nil,
          Nil,
          serviceTypeAuFormEl :: Nil,
        )

        div(
          cls := "slds-col slds-size--1-of-1 slds-m-top--large",
          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
            div(cls := "slds-col"),
            div(
              cls := "slds-col",
              ButtonsPairComponent[(String, DataMapperModels.MetadataModels.Metadata), dom.MouseEvent](
                primaryDisabled = state.dirtySignal.combineWith(shrinkValidator(customValidation)).map(v => !v._1 || !v._2),
                primaryEffect = () => EventStream.fromValue((model.now().flatMap(_.name).getOrElse[String](""), m.toImmutableModel)),
                primaryObserver = Observer.combine(onChange, model.writer.contramap((_: (String, MetadataModels.Metadata)) => None)),
                secondaryObserver = model.writer.contramap((_: dom.MouseEvent) => None),
              ).node
            )
          ),
        )
      }

      def name: material.Textfield.El = nameAuFormEl.node

      def serviceType: material.Select.El = serviceTypeAuFormEl.node
    }

    def node: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.metadata",

      material.Dialog(
        _ => cls := "width--medium",
        _.open <-- model.signal
          .map(_.isDefined)
          .map(x => {
            log.info(s"metadata dialog ${if (x) "open" else "close"}")
            x
          }),
        _.onClosing.mapTo(None) --> model.writer,
        _.heading <-- model.signal.map(_
          .flatMap {
            case v if v.id.getOrElse[Long](0) > 0 => Some(v.name.getOrElse[String](s"#${v.id.get}"))
            case _ => None
          }
          .fold[String]
            ("Create new metadata")
            (name => s"Edit $name metadata")),
        _.hideActions := true,

        _ => child.maybe <-- model.signal.map {
          case Some(m) =>
            val model = MutableModel(m)
            val controller = Controller(model)
            Some(div(
              controller.name,
              child.maybe <-- `type`.map {
                case Some(MetadataModels.MetadataType.provider) =>
                  Some(controller.serviceType)
                case _ =>
                  None
              },
              controller.buttons,
            ))
          case _ => None
        },

        _ => onMountCallback(fixMwcDialogOverflow)
      ),
    )

    def writer: Observer[MetadataModels.Metadata] =
      model.writer.contramap((x: MetadataModels.Metadata) => Some(x))
  }

  /**
   * TODO: change
   * Class creating new dialog controller, if you want to show the dialog, need to emit non null value to model.
   * Arguments:
   *  - onChange - observer for the success result, accepting pair parameters:
   *    `String` is the old class name
   *    `MetadataModels.Class` is the new class model
   */
  class Class(nameImmutable: Boolean = true) {
    private val model: Var[Option[MetadataModels.Class]] = Var(None)
    private val classNames: Var[List[String]] = Var(Nil) // for the validation class name
    private val classPluralNames: Var[List[String]] = Var(Nil) // for the validation class name

    private val changing: EventBus[(String, MetadataModels.Class)] = new EventBus()

    private case class MutableModel(src: MetadataModels.Class) {
      val name: Var[String] = Var(src.name)
      val pluralName: Var[String] = Var(src.pluralName)
      val embedded: Var[Boolean] = Var(src.embedded)

      def toImmutableModel: MetadataModels.Class = src.copy(
        name = this.name.now(),
        pluralName = this.pluralName.now(),
        embedded = this.embedded.now(),
        fields = Some(src.fields.getOrElse(Nil)),
        relations = Some(src.relations.getOrElse(Nil)),
      )
    }

    private case class Controller(m: MutableModel) {
      private val nameError = Var[String]("")
      private val nameField: AuFormField = AuFormField(material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Name",
        _.required := true,
        _.value <-- m.name,
        _ => cls <-- nameError.signal
          .map(_.nonEmpty)
          .map {
            case true => "with_error"
            case _ => ""
          },
        _.helper <-- nameError.signal,
        _.helperPersistent := true,
        _ => onInput.mapToValue --> m.name
      ))
      private val pluralNameError = Var[String]("")
      private val pluralNameField: AuFormField = AuFormField(material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Plural name",
        _.required := false,
        _.value <-- m.pluralName,
        _ => cls <-- pluralNameError.signal
          .map(_.nonEmpty)
          .map {
            case true => "with_error"
            case _ => ""
          },
        _.helper <-- pluralNameError.signal,
        _.helperPersistent := true,
        _ => onInput.mapToValue --> m.pluralName
      ))
      private val embeddedAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Type",
        _.value <-- m.embedded.signal.map {
          case true => "embedded"
          case _ => "default"
        },
        _.required := true,
        _.helperPersistent := true,
        _.helper <-- m.embedded.signal.map {
          case true => "can be part of other class"
          case _ => "can be related from other class."
        },
        _ => material.List.ListItem(
          _.value := "default",
          _.selected <-- m.embedded.signal.map(_ != true),
          _.slots.default(span(cls := "slds-r-bottom_medium", "Default")),
          _ => onClick.mapTo(false) --> m.embedded.writer,
        ),
        _ => child.maybe <-- m.name.signal.map(_.nonEmpty)
          .combineWith(m.embedded.signal).map{case (true, true) => Some(material.List.ListItem(
            _.value := "embedded",
            _.selected <-- m.embedded.signal.map(_ == true),
            _.slots.default(span(cls := "slds-r-bottom_medium", "Embedded")),
            _ => onClick.mapTo(true) --> m.embedded.writer,
          ))
          case _ => None
          },
      ))

      private val rxName: Regex = "^[A-Za-z][A-Za-z0-9_-]+$".r

      private def customValidation: Seq[Signal[Boolean]] = Seq(
        m.name.signal
          .map[Boolean](_.trim.nonEmpty),
        m.name.signal
          .combineWith(classNames.signal.combineWith(model).map(x => x._1.filter(n => x._2.forall(_.name != n))))
          .map[String] {
            case (newName, names) if newName.nonEmpty && names.contains(newName) => "already exists"
            case (newName, _) if newName.nonEmpty && !rxName.matches(newName) => "incorrect value"
            case _ => ""
          }
          .map[Boolean](x => {
            nameError.set(x)
            x.isEmpty
          }),
        //m.pluralName.signal.map(_.nonEmpty) ,
        m.pluralName.signal
          .combineWith(classPluralNames.signal.combineWith(model).map(x => x._1.filter(n => x._2.forall(_.pluralName != n))))
          .map[String] {
            case (newName, names) if newName.nonEmpty && names.contains(newName) => "already exists"
            case (newName, _) if newName.nonEmpty && !rxName.matches(newName) => "incorrect value"
            case _ => ""
          }
          .map[Boolean](x => {
            pluralNameError.set(x)
            x.isEmpty
          }),
      )

      def buttons: HtmlElement = {
        val state: AuFormState = AuFormState(
          nameField :: pluralNameField :: Nil,
          Nil,
          Nil,
          embeddedAuFormEl :: Nil
        )

        div(
          cls := "slds-col slds-size--1-of-1 slds-m-top--large",
          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
            div(cls := "slds-col"),
            div(
              cls := "slds-col",

              ButtonsPairComponent[(String, MetadataModels.Class), dom.MouseEvent](
                primaryDisabled = state.dirtySignal.combineWith(shrinkValidator(customValidation)).map(v => !v._1 || !v._2),
                primaryEffect = () => EventStream.fromValue(m.toImmutableModel)
                  .withCurrentValueOf(model.signal.map(_.map(_.name)))
                  .map(x => (x._2.getOrElse(x._1.name), x._1)),
                primaryObserver = Observer.combine(
                  changing.writer,
                  model.writer.contramap((_: (String, MetadataModels.Class)) => None),
                ),
                secondaryObserver = model.writer.contramap((_: dom.MouseEvent) => None),
              ).node
            ),
          ),
        )
      }

      def name: material.Textfield.El = nameField.node

      def pluralName: material.Textfield.El = pluralNameField.node

      def embedded: material.Select.El = embeddedAuFormEl.node
    }

    def node: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.metadata.class",

      material.Dialog(
        _ => cls := "width--medium",
        _.open <-- model.signal
          .map(_.isDefined)
          .map(x => {
            log.info(s"metadata.class dialog ${if (x) "open" else "close"}")
            x
          }),
        _.onClosing.mapTo(None) --> model.writer,
        _.heading <-- model.signal.map(_
          .flatMap {
            case v if v.name.nonEmpty => Some(v.name)
            case _ => None
          }
          .fold[String]
            ("Create new metadata object")
            (name => s"Edit $name metadata object")),
        _.hideActions := true,

        _ => child.maybe <-- model.signal.map {
          case Some(m) =>
            val model = MutableModel(m)
            val controller = Controller(model)

            Some(div(
              if (nameImmutable && m.name.nonEmpty)
                None
              else
                controller.name,
              controller.pluralName,
              controller.embedded,

              controller.buttons,
            ))
          case _ => None
        },

        _ => onMountCallback(fixMwcDialogOverflow)
      ),
    )

    def classes: Observer[List[MetadataModels.Class]] = Observer.combine(
      classNames.writer.contramap((x: List[MetadataModels.Class]) => x.map(_.name)),
      classPluralNames.writer.contramap((x: List[MetadataModels.Class]) => x.map(_.pluralName)),
    )

    def events: EventStream[(String, MetadataModels.Class)] = changing.events

    def writer: Observer[MetadataModels.Class] = model.writer.contramap((x: MetadataModels.Class) => Some(x))
  }

  /**
   * TODO: change
   * Field creating new dialog controller, if you want to show the dialog, need to emit non null value to model.
   * Arguments:
   *  - fields - all fields from class
   *  - classes - all classes from metadata
   *  - onChange - observer for the success result, accepting pair parameters:
   *    `String` is the old field name
   *    `MetadataModels.Field` is the new field model
   */
  class Field(
               private val fields: Signal[List[MetadataModels.Field]],
               private val classes: Signal[List[MetadataModels.Class]], // for the embedded mode and RelationTo field
             ) {
    private val model: Var[Option[MetadataModels.Field]] = Var(None)
    private val changing: EventBus[(String, MetadataModels.Field)] = new EventBus()

    private def key: Signal[String] = model.signal.map(_.map(_.name).getOrElse[String](""))

    private case class MutableModel(src: MetadataModels.Field) {
      val name: Var[String] = Var(src.name)
      val label: Var[String] = Var(src.label)
      val `type`: Var[MetadataModels.DataType.Type] = Var(src.`type`)
      val typeEmbeddedClass: Var[String] = Var(src.`type` match {
        case MetadataModels.DataType.EmbeddedVal(Some(className)) => className
        case _ => ""
      })
      val structure: Var[MetadataModels.FieldStructure.FieldStructure] = Var(src.structure)
      val role: Var[MetadataModels.FieldRole.FieldRole] = Var(src.role.getOrElse(MetadataModels.FieldRole.none)) // значения: id, name, email. в не embedded классе должно быть ровно одно поле с ролью id
      val required: Var[Boolean] = Var(src.required)
      val readOnly: Var[Boolean] = Var(src.readOnly)
      val referenceTo: Var[String] = Var(src.referenceTo.getOrElse[String]("")) // имя класса, на который ссылается данное поле

      private def mkType: MetadataModels.DataType.Type = {
        val r = this.`type`.now()
        val c = this.typeEmbeddedClass.now()
        if (r.name == MetadataModels.DataType.embedded.name && c.nonEmpty)
          MetadataModels.DataType.EmbeddedVal(Some(c)) // TODO get fields
        else
          r
      }

      private def mkRole: Option[MetadataModels.FieldRole.FieldRole] = {
        val role = this.role.now()
        Option.when(role != MetadataModels.FieldRole.none && role != MetadataModels.FieldRole.unknown)(role)
      }

      private def mkReferenceTo: Option[String] = {
        val referenceTo = this.referenceTo.now()
        Option.when(referenceTo.nonEmpty)(referenceTo)
      }

      def toImmutableModel: MetadataModels.Field = src.copy(
        name = this.name.now(),
        label = this.label.now(),
        `type` = this.mkType,
        structure = this.structure.now(),
        role = this.mkRole,
        required = this.required.now(),
        readOnly = this.readOnly.now(),
        referenceTo = this.mkReferenceTo,
      )

      def toHtml: HtmlElement = div(
        cls := "slds-grid slds-grid--vertical",
        div(cls := "slds-col", s"Name: ", child.text <-- name.signal),
        div(cls := "slds-col", s"Label: ", child.text <-- label.signal),
        div(cls := "slds-col", s"Type: ", child.text <-- `type`.signal.map(_.label)),
      //  div(cls := "slds-col", s"TypeEmbeddedClass: ", child.text <-- typeEmbeddedClass.signal),
        div(cls := "slds-col", s"Structure: ", child.text <-- structure.signal.map[String](_.label)),
        div(cls := "slds-col", s"Role: ", child.text <-- role.signal.map[String](_.label)),
        div(cls := "slds-col", s"Required: ", child.text <-- required.signal.map[String](_.toString)),
        div(cls := "slds-col", s"ReadOnly: ", child.text <-- readOnly.signal.map[String](_.toString)),
        div(cls := "slds-col", s"ReferenceTo: ", child.text <-- referenceTo.signal),
      )
    }

    private case class Controller(m: MutableModel) {
      private val nameError = Var[String]("")
      private val nameAuFormEl = AuFormField(material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Name",
        _.required := true,
        _.value <-- m.name,
        _ => cls <-- nameError.signal
          .map(_.trim.nonEmpty)
          .map {
            case true => "with_error"
            case _ => ""
          },
        _.helper <-- nameError.signal,
        _.helperPersistent := true,
        _ => onInput.mapToValue --> m.name,
      ))
      private val labelAuFormEl = AuFormField(material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Label",
        _.required := false,
        _.value <-- m.label,
        _ => onInput.mapToValue --> m.label
      ))
      private val typeAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Type",
        _.value <-- m.`type`.signal.map(_.name),
        _.required := true,
        _ => children <-- m.role.signal
          .map {
            case MetadataModels.FieldRole.id =>
              (t: MetadataModels.DataType.Type) =>
                t.basisType == DataMapperModels.SupportedType.string ||
                  t.basisType == DataMapperModels.SupportedType.long
            case MetadataModels.FieldRole.name =>
              (t: MetadataModels.DataType.Type) =>
                t.basisType == DataMapperModels.SupportedType.string
            case MetadataModels.FieldRole.email =>
              (t: MetadataModels.DataType.Type) =>
                t.name.contains("email") ||
                  t.basisType == DataMapperModels.SupportedType.string ||
                  t.basisType == DataMapperModels.SupportedType.listOf(DataMapperModels.SupportedType.string)
            case _ =>
              (_: MetadataModels.DataType.Type) => true
          }
          .map(MetadataModels.DataType.all.filter)
          .map(_.map(c => material.List.ListItem(
            _.value := c.name,
            _.selected <-- m.`type`.signal.map(_.name == c.name),
            _ => c.label,
            _ => onClick.mapTo(c) --> m.`type`.writer,
          )))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no types",
              _.selected := false,
              _.value := "no-types",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
      ))
      private val typeEmbeddedToClassAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Embedded object",
        _.value <-- m.typeEmbeddedClass.signal,
        _.required := true,
        _ => children <-- classes
          .map(_.filter(_.embedded).sortBy(_.name))
          .map(_.map(c => material.List.ListItem(
            _.value := c.name,
            _.selected <-- m.typeEmbeddedClass.signal.map(_ == c.name),
            _ => c.name,
            _ => composeEvents(onClick.stopPropagation)(_
              .mapTo(c.name)
              .withCurrentValueOf(m.typeEmbeddedClass.signal)
              .map(x => Option.when(x._1 != x._2)(x._1).getOrElse[String]("")))
              --> m.typeEmbeddedClass.writer,
          )))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no embedded objects",
              _.selected := false,
              _.value := "no-embedded-classes",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
      ))
      private val structureAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Structure",
        _.value <-- m.structure.signal.map(_.name),
        _.required := true,
        _ => children <-- Signal.fromValue(MetadataModels.FieldStructure.all
          .map(e => material.List.ListItem(
            _.value := e.name,
            _.selected <-- m.structure.signal.map(_.name == e.name),
            _ => e.label,
            _ => onClick.mapTo(e) --> m.structure.writer,
          )))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no structures",
              _.selected := false,
              _.value := "no-structures",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
      ))
      private val roleError = Var[String]("")
      private val roleAuFormEl = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Role",
        _.value <-- m.role.signal.map(_.value),
        _.required := false,
        _ => children <-- Signal.fromValue(MetadataModels.FieldRole.all)
          .combineWith(fields.map(_.map(_.role).exists(_.exists(_.value == MetadataModels.FieldRole.id.value))))
          .combineWith(model.signal.map(_.exists(_.role.exists(_.value == MetadataModels.FieldRole.id.value))))
          .map {
            case (roles, true, false) => roles.filter(_.value != MetadataModels.FieldRole.id.value)
            case (roles, _, _) => roles
          }
          .map(roles => (MetadataModels.FieldRole.none :: Nil) ++ roles)
          .map(_.map(r => material.List.ListItem(
            _.value := r.value,
            _.selected <-- m.role.signal.map(_ == r),
            _ => r.label,
            _ => composeEvents(onClick.stopPropagation)(_
              .mapTo(r)
              .withCurrentValueOf(m.role.signal)
              .map(x => Option.when(x._1 != x._2)(x._1).getOrElse(MetadataModels.FieldRole.none)))
              --> m.role.writer,
          )))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no roles",
              _.selected := false,
              _.value := "no-roles",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
        _ => cls <-- roleError.signal
          .map(_.nonEmpty)
          .map {
            case true => "with_error"
            case _ => ""
          },
        _.helper <-- roleError.signal,
        _.helperPersistent := true,
      ))
      private val requiredAuFormEl = AuFormCheckbox(material.Checkbox(
        _.checked <-- m.required,
        _.onChange.mapToChecked --> m.required.writer,
      ))
      private val readOnlyAuFormEl = AuFormCheckbox(material.Checkbox(
        _.checked <-- m.readOnly,
        _.onChange.mapToChecked --> m.readOnly.writer
      ))
      private val referenceToAuFormEl = {
        val unselect = "-"

        AuFormSelect(material.Select(
          _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
          _.label := "Reference to",
          _.value <-- m.referenceTo.signal
            .map {
              case x if x.isEmpty => unselect
              case x => x
            },
          _.required := true,
          _ => children <-- classes
            .map(_.sortBy(_.name))
            .map(c => (unselect :: Nil) ++ c.map[String](_.name))
            .map(_.map(c => material.List.ListItem(
              _.value := c,
              _.selected <-- m.referenceTo.signal.map(_ == c),
              _ => c,
              _ => composeEvents(onClick.stopPropagation)(_
                .mapTo(c)
                .withCurrentValueOf(m.referenceTo.signal)
                .map(x => Option.when(x._1 != x._2 && x._1 != unselect)(x._1).getOrElse[String]("")))
                --> m.referenceTo.writer,
            )))
            .map {
              case items if items.isEmpty => material.List.ListItem(
                _ => "no objects",
                _.selected := false,
                _.value := "no-classes",
                _.disabled := true,
              ) :: Nil
              case items => items
            },
        ))
      }

      private val rxName: Regex = "^[A-Za-z][A-Za-z0-9_-]+$".r

      private def customValidation: Seq[Signal[Boolean]] = Seq(
        m.name.signal
          .map(_.trim.nonEmpty),
        m.name.signal
          .combineWith(fields.combineWith(key).map(x => x._1.filter(_.name != x._2)))
          .map[String] {
            case (name, fields) if name.nonEmpty && fields.exists(_.name == name) => "already exists"
            case (name, _) if name.nonEmpty && !rxName.matches(name) => "incorrect value"
            case _ => ""
          }
          .map(v => {
            nameError.set(v)
            v.isEmpty
          }),
        m.`type`.signal
          .combineWith(m.typeEmbeddedClass.signal)
          .map(x => x._1.name.nonEmpty &&
            (x._1.name != MetadataModels.DataType.embedded.name && x._1.name != MetadataModels.DataType.unknown.name ||
              x._1.name == MetadataModels.DataType.embedded.name && x._2.nonEmpty)
          ),
        m.structure.signal
          .map(v => v.name.nonEmpty && v.name != MetadataModels.FieldStructure.unknown.name),
        m.role.signal
          .combineWith(fields.combineWith(key).map(x => x._1.filter(_.name != x._2)))
          .map[String] {
            case (MetadataModels.FieldRole.unknown, _) =>
              "wrong value"
            case (r, fields) if r == MetadataModels.FieldRole.id && fields.exists(_.role.exists(_ == r)) =>
              "already exists"
            case (r, fields) if r == MetadataModels.FieldRole.name && fields.exists(_.role.exists(_ == r)) =>
              "already exists"
            case _ => ""
          }
          .map(v => {
            roleError.set(v)
            v.isEmpty
          }),
        //m.referenceTo.signal.map(v => v.isEmpty || v.get.nonEmpty),
      )

      def buttons: HtmlElement = {
        val state: AuFormState = AuFormState(
          nameAuFormEl :: labelAuFormEl :: Nil,
          Nil,
          requiredAuFormEl :: readOnlyAuFormEl :: Nil,
          typeAuFormEl :: structureAuFormEl :: roleAuFormEl :: referenceToAuFormEl :: typeEmbeddedToClassAuFormEl :: Nil,
        )

        div(
          cls := "slds-col slds-size--1-of-1 slds-m-top--large",
          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
            div(cls := "slds-col"),
            div(
              cls := "slds-col",
              ButtonsPairComponent[(String, DataMapperModels.MetadataModels.Field), dom.MouseEvent](
                primaryButtonText = "Apply",
                primaryDisabled = state.dirtySignal.combineWith(shrinkValidator(customValidation)).map(v => !v._1 || !v._2),
                primaryEffect = () => EventStream.fromValue((model.now().map(_.name).getOrElse[String](""), m.toImmutableModel)),
                primaryObserver = Observer.combine(changing.writer, model.writer.contramap((_: (String, MetadataModels.Field)) => None)),
                secondaryObserver = model.writer.contramap((_: dom.MouseEvent) => None),
              ).node
            ),
          ),
        )
      }

      def name: material.Textfield.El = nameAuFormEl.node

      def label: material.Textfield.El = labelAuFormEl.node

      def `type`: material.Select.El = typeAuFormEl.node

      def typeEmbeddedToClass: HtmlElement = div(
        cls <-- m.`type`.signal
          .map {
            case t if t.name == MetadataModels.DataType.embedded.name => ""
            case _ =>
              m.typeEmbeddedClass.set("")
              log.info(s"type embedded class reset")
              "hidden"
          },
        typeEmbeddedToClassAuFormEl.node,
      )

      def structure: HtmlElement = div(
        cls := m.src.role
          .map {
            case MetadataModels.FieldRole.id => "hidden"
            case _ => ""
          }
          .getOrElse(""),
        structureAuFormEl.node,
      )

      def role: HtmlElement = div(
        cls := m.src.role
          .map {
            case MetadataModels.FieldRole.id => "hidden"
            case _ => ""
          }
          .getOrElse(""),
        roleAuFormEl.node,
      )

      def required: HtmlElement = div(
        cls := "slds-col slds-size--1-of-1 slds-grid slds-grid--vertical-align-center",
        cls := m.src.role
          .map {
            case MetadataModels.FieldRole.id => "hidden"
            case _ => ""
          }
          .getOrElse(""),
        requiredAuFormEl.node,
        span("Required"),
      )

      def readOnly: HtmlElement = div(
        cls := "slds-col slds-size--1-of-1 slds-grid slds-grid--vertical-align-center",
        cls := m.src.role
          .map {
            case MetadataModels.FieldRole.id => "hidden"
            case _ => ""
          }
          .getOrElse(""),
        readOnlyAuFormEl.node,
        span("ReadOnly"),
      )

      def referenceTo: HtmlElement = div(
        cls <-- classes
          .map(_.nonEmpty)
          .map {
            case true if !m.src.role.contains(MetadataModels.FieldRole.id) => ""
            case _ => "hidden"
          },
        referenceToAuFormEl.node,
      )
    }

    //overflow-y: auto;
    def node: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.metadata.field",

      material.Dialog(
        _ => cls := "width--medium",
        _.open <-- model.signal
          .map(_.isDefined)
          .map(x => {
            log.info(s"metadata.field dialog ${if (x) "open" else "close"}")
            x
          }),
        _.onClosing.mapTo(None) --> model.writer,
        _.heading <-- model.signal.map(_
          .flatMap {
            case v if v.name.nonEmpty => Some(v.name)
            case _ => None
          }
          .fold[String]
            ("Create new field of metadata object")
            (name => s"Edit $name field of metadata object")),
        _.hideActions := true,

        _ => child.maybe <-- model.signal
          .map {
            case Some(m) =>
              val model = MutableModel(m)
              val controller = Controller(model)
              Some(div(
                //styleAttr("overflow-y: auto"),

                controller.name,
                controller.label,
                controller.`type`,
                controller.typeEmbeddedToClass,
                controller.structure,
                controller.role,
                controller.required,
                controller.readOnly,
                controller.referenceTo,
                controller.buttons,

                // debug information
                div(
                  cls := "slds-col slds-m-top--small slds-grid hidden",
                  div(cls := "slds-col slds-size--6-of-12", model.toHtml),
                  div(
                    cls := "slds-col slds-size--6-of-12",
                    div(
                      cls := "slds-grid slds-grid--vertical",
                      div(cls := "slds-col", s"Name: ", m.name),
                      div(cls := "slds-col", s"Label: ", m.label),
                      div(cls := "slds-col", s"Type: ", m.`type`.name),
                      div(cls := "slds-col", s"Structure: ", m.structure.label),
                      div(cls := "slds-col", s"Role: ", m.role.map(_.label).getOrElse[String]("")),
                      div(cls := "slds-col", s"Required: ", m.required.toString),
                      div(cls := "slds-col", s"ReadOnly: ", m.readOnly.toString),
                      div(cls := "slds-col", s"ReferenceTo: ", m.referenceTo.getOrElse[String]("")),
                    ),
                  )
                )
              ))
            case _ => None
          },

        _ => onMountCallback(fixMwcDialogOverflow)
      ),
    )

    def events: EventStream[(String, MetadataModels.Field)] = changing.events

    def writer: Observer[MetadataModels.Field] =
      model.writer
        .contramap((x: MetadataModels.Field) => Some(x))
  }

  /**
   * TODO: change
   * Relation creating new dialog controller, if you want to show the dialog, need to emit non null value to model.
   * Arguments:
   *  - relations - all relations from class
   *  - classes - all classes from metadata
   *  - onChange - observer for the success result, accepting pair parameters:
   *    `String` is the old relation name
   *    `MetadataModels.Relation` is the new relation model
   */
  class Relation(
                  private val fields: Signal[List[MetadataModels.Field]],
                  private val relations: Signal[List[MetadataModels.Relation]],
                  private val classes: Signal[List[MetadataModels.Class]], // for the RelatesToClassField field
                ) {
    private val model: Var[Option[MetadataModels.Relation]] = Var(None)
    private val changing: EventBus[(String, MetadataModels.Relation)] = new EventBus()

    private def key: Signal[String] = model.signal.map(_.map(_.name).getOrElse[String](""))

    private case class MutableModel(src: MetadataModels.Relation) {
      val name: Var[String] = Var(src.name)
      val relatesToClass: Var[String] = Var(src.relatesToClass)
      val relatesToField: Var[Option[String]] = Var(src.relatesToField)

      def toImmutableModel: MetadataModels.Relation = src.copy(
        name = this.name.now(),
        relatesToClass = this.relatesToClass.now(),
        relatesToField = this.relatesToField.now(),
      )
    }

    private case class Controller(m: MutableModel) {
      private val nameError = Var[String]("")
      private val nameAuFormEl: AuFormField = AuFormField(material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Name",
        _.required := true,
        _.value <-- m.name,
        _ => cls <-- nameError.signal
          .map(_.nonEmpty)
          .map {
            case true => "with_error"
            case _ => ""
          },
        _.helper <-- nameError.signal,
        _.helperPersistent := true,
        _ => onInput.mapToValue --> m.name,
      ))
      private val relatesToClassAuFormEl: AuFormSelect = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Relates to object",
        _.value <-- m.relatesToClass.signal,
        _.required := true,
        _ => children <-- classes
          .map(_.filter(!_.embedded).sortBy(_.name))
          .map(_
            .filter(_.fields.exists(_.exists(_.role.exists(_.value == MetadataModels.FieldRole.id.value))))
            .map(c => material.List.ListItem(
              _.value := c.name,
              _.selected <-- m.relatesToClass.signal.map(_ == c.name),
              _.slots.default(span(c.name)),
              _ => onClick.mapTo(c.name) --> m.relatesToClass.writer,
            )).sortBy(f => s"${f.ref.disabled}-${f.ref.value}"))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no objects",
              _.selected := false,
              _.value := "no-classes",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
      ))
      private val relatesToFieldAuFormEl: AuFormSelect = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Field associated to object",
        _.value <-- m.relatesToField.signal.map(_.getOrElse[String]("")),
        _.required := false,
        _ => children <-- fields
          .map(_.find(_.role.exists(_.value == MetadataModels.FieldRole.id.value)).map(_.`type`))
          .combineWith(m.relatesToClass.signal
            .combineWith(classes)
            .map(x => x._2.find(_.name == x._1).flatMap(_.fields).getOrElse(Nil)))
          .map(x => x._1
            .map(t => x._2.filter(f => f.structure == MetadataModels.FieldStructure.singular && f.`type`.basisType == t.basisType))
            .getOrElse(x._2))
          .map(_.map(f => material.List.ListItem(
            _.value := f.name,
            _.selected <-- m.relatesToField.signal.map(_.getOrElse[String]("") == f.name),
            _ => f.name,
            _ => composeEvents(onClick.stopPropagation)(_
              .mapTo(f.name)
              .withCurrentValueOf(m.relatesToField.signal)
              .map(x => Option.when(!x._2.contains(x._1))(x._1)))
              --> m.relatesToField.writer,
          )).sortBy(_.ref.value))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no fields",
              _.selected := false,
              _.value := "no-fields",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
      ))

      private val rxName: Regex = "^[A-Za-z][A-Za-z0-9_-]+$".r

      private def customValidation: Seq[Signal[Boolean]] = Seq(
        m.name.signal
          .map(_.trim.nonEmpty),
        m.name.signal
          .withCurrentValueOf(key)
          .combineWith(relations)
          .map(x => (x._1, x._3.filter(_.name != x._2)))
          .map[String] {
            case (name, relations) if name.nonEmpty && relations.exists(_.name == name) => "already exists"
            case (name, _) if name.nonEmpty && !rxName.matches(name) => "incorrect value"
            case _ => ""
          }
          .map(v => {
            nameError.set(v)
            v.isEmpty
          }),
        m.relatesToClass.signal
          .map(_.nonEmpty),
      )

      def buttons: HtmlElement = {
        val state: AuFormState = AuFormState(
          nameAuFormEl :: Nil,
          Nil,
          Nil,
          relatesToClassAuFormEl :: relatesToFieldAuFormEl :: Nil,
        )

        div(
          cls := "slds-col slds-size--1-of-1 slds-m-top--large",
          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
            div(cls := "slds-col"),
            div(
              cls := "slds-col",
              ButtonsPairComponent[(String, DataMapperModels.MetadataModels.Relation), dom.MouseEvent](
                primaryButtonText = "Apply",
                primaryDisabled = state.dirtySignal.combineWith(shrinkValidator(customValidation)).map(v => !v._1 || !v._2),
                primaryEffect = () => EventStream.fromValue((model.now().getOrElse(MetadataModels.Relation()).name, m.toImmutableModel)),
                primaryObserver = Observer.combine(changing.writer, model.writer.contramap((_: (String, MetadataModels.Relation)) => None)),
                secondaryObserver = model.writer.contramap((_: dom.MouseEvent) => None),
              ).node
            ),
          ),
        )
      }

      def name: material.Textfield.El = nameAuFormEl.node

      def relatesToClass: material.Select.El = relatesToClassAuFormEl.node

      def relatesToField: material.Select.El = relatesToFieldAuFormEl.node
    }

    def node: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.metadata.relation",

      material.Dialog(
        _ => cls := "width--medium",
        _.open <-- model.signal
          .map(_.isDefined)
          .map(x => {
            log.info(s"metadata.relation dialog ${if (x) "open" else "close"}")
            x
          }),
        _.onClosing.mapTo(None) --> model.writer,
        _.heading <-- model.signal.map(_
          .flatMap {
            case v if v.name.nonEmpty => Some(v.name)
            case _ => None
          }
          .fold[String]
            ("Create new relation of metadata object")
            (name => s"Edit $name relation of metadata object")),
        _.hideActions := true,

        _ => child.maybe <-- model.signal.map {
          case Some(m) =>
            val model = MutableModel(m)
            val controller = Controller(model)
            Some(div(
              controller.name,
              controller.relatesToClass,
              controller.relatesToField,
              controller.buttons,
            ))
          case _ => None
        },

        _ => onMountCallback(fixMwcDialogOverflow)
      ),
    )

    def events: EventStream[(String, MetadataModels.Relation)] = changing.events

    def writer: Observer[MetadataModels.Relation] =
      model.writer
        .contramap((x: MetadataModels.Relation) => Some(x))
  }
}
