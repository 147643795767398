package root_pages.aurinko_pages.app.virtual_api.components

import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.Button
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import common.ui.breadcrumbs.{BreadcrumbsComponent, BreadcrumbsItem}
import common.ui.search_input.SearchInputComponent
import portal_router.PortalRouter
import service.scroll_ops.ScrollOps


case object BoardComponent {
  val expanded: Var[Boolean] = Var(false)

  def apply[T](
                name: Signal[String],
                leftContext: HtmlElement,
                rightContext: HtmlElement,
                navigation: Signal[List[BreadcrumbsItem]] = Signal.fromValue(Nil),
                cssLeftContext: String = (""),
                buttons: Signal[Seq[(String, Observer[Unit], Boolean)]] = Signal.fromValue(Nil),
                hasSearch: Boolean = false,
                searchDelay: Int = 1000,
                searchText: Var[String] = Var(""),
                reload: EventBus[Unit] = new EventBus[Unit],
                documentScrollOps: ScrollOps,
                portalRouter: PortalRouter,
                //disabled: Signal[Boolean] = Signal.fromValue(false),
              ): HtmlElement = {
    div(
      common.ui.Attribute.Selector := "data_mapper.components.horizontal_board",
      div(
        cls := "x-nested-page",
        div(
          cls := "nav-filters-container",
          div(
            cls := "slds-m-right--small",
            child <-- navigation
              .map(l => BreadcrumbsComponent(l: _*)),
          ),

          div(
            child.maybe <-- buttons
              .map(x => x.nonEmpty || hasSearch)
              .map {
                case true => Some(div(
                  div(
                    cls := "slds-grid slds-grid--vertical-align-center",
                    if (hasSearch) SearchInputComponent(
                      onChange = Observer.combine(searchText.writer, reload.writer.contramap((_: String) => ())),
                      delay = searchDelay,
                      $value = searchText.signal
                    ).node else None,
                    children <-- buttons
                      .map(_.map(btn => Button(
                        _ => cls := "slds-m-left--small blue nav-section_button slds-grid slds-grid--vertical-align-center",
                        _.icon := "add", // TODO: need to support more icons
                        _.label := btn._1,
                        _.disabled := btn._3,
                        _ => onClick.mapTo(()) --> btn._2,
                      )))
                      .map {
                        case x if x.nonEmpty =>
                          x.head.ref.classList.add("slds-m-left--large")
                          x
                        case _ => Nil
                      },
                  )
                ))
                case _ => None
              },
          ),
        )
          .amend(cls <-- documentScrollOps.$scrolled
            .map { case true => "shadow" case _ => "" }),

        div(
          cls := "content-padding",
          div(
            cls := "left-section",
            paddingTop <-- documentScrollOps.$scrollTop.changes
              .debounce(0).map(i => s"calc(var(--au-header-height) - ${i}px)"),
            div(
              cls := "content-container virtual-content-container",
              div(
                cls := "content virtual-content",
                child.maybe <-- name.map {
                  case n if n.nonEmpty => Some(div(
                    cls := "slds-grid slds-grid--align-spread",
                    div(
                      cls := "slds-m-bottom--medium",
                      p(
                        cls := "title--level-1 text-capitalize",
                        child.text <-- name,
                      ),
                    ),
                  ))
                  case _ => None
                },
                leftContext
              )
            ),
          ),

          div(
            cls := s"right-section $cssLeftContext",
            paddingTop := "4rem",
            rightContext,
          )
        )
      )
    )
  }
}




