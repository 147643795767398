package root_pages.aurinko_pages.app.virtual_api.components.dialogs

import com.github.uosis.laminar.webcomponents.material
import com.raquo.airstream.core.Observer
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import common.ui.{AuFormCustomValidator, AuFormField, AuFormSelect, AuFormState}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.mat_components_styles.fixMwcDialogOverflow
import common.ui.element_binders.DialogModifying
import org.scalajs.dom
import root_pages.aurinko_pages.app.virtual_api.components
import service.apis.dynamic_api.DataMapperModels.{ConfigurationModels, DescriptionModels, MetadataModels}
import service.apis.dynamic_api.DataMapperApi
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import wvlet.log.Logger

import scala.util.{Failure, Success}

case object configuration {
  private val log = Logger.of[configuration.type]

  private val SuggestBox_DefaultRowsLimit: Int = 50

  private def shrinkValidator(signals: Seq[Signal[Boolean]]): Signal[Boolean] =
    Signal.combineSeq[Boolean](signals)
      .map(x => {
        log.info(s"custom validation: ${x.map(_.toString).mkString(", ")}")
        !x.contains(false)
      })

  class Configuration(
                       private val $appKey: Signal[common.AppKey],
                       private val dynamicApi: DataMapperApi.API,
                       private val portalApi: PortalApi,
                       private val onChange: Observer[(String, ConfigurationModels.Configuration)],
                       portalState: PortalState
                     ) {
    private val model: Var[Option[ConfigurationModels.Configuration]] = Var(None)

    private case class MutableModel(private val src: ConfigurationModels.Configuration) {
      val name: Var[String] = Var(src.name.getOrElse[String](""))
      val providerDescriptionId: Var[Option[Long]] = Var(src.providerDescriptionId)
      val providerDescription: Var[Option[String]] = Var(src.providerDescription)
      val providerMetadataId: Var[Option[Long]] = Var(src.providerMetadataId)
      val providerMetadata: Var[Option[String]] = Var(src.providerMetadata)
      val clientMetadataId: Var[Option[Long]] = Var(src.clientMetadataId)
      val clientMetadata: Var[Option[String]] = Var(src.clientMetadata)
      val mappingsId: Var[Option[Long]] = Var(src.mappingsId)
      val configAccountId: Var[Option[Int]] = Var(src.configAccountId)
      val configAccountDescription: Var[String] = Var(src.configAccountDescription.getOrElse[String](""))

      def id: Option[Long] = src.id

      def toImmutableModel: ConfigurationModels.Configuration = src.copy(
        name = Some(this.name.now()),
        providerDescriptionId = this.providerDescriptionId.now(),
        providerDescription = this.providerDescription.now(),
        providerMetadataId = this.providerMetadataId.now(),
        providerMetadata = this.providerMetadata.now(),
        clientMetadataId = this.clientMetadataId.now(),
        clientMetadata = this.clientMetadata.now(),
        mappingsId = this.mappingsId.now(),
        configAccountId = this.configAccountId.now().map {
          case x if x > 0 => x
          case _ => 0
        },
        configAccountDescription = this.configAccountId.now().map {
          case x if x > 0 => this.configAccountDescription.now()
          case _ => ""
        },
      )
    }

    private case class CombineBoxOption(
                                         id: String = "",
                                         text: String = "",
                                         src: Option[Long] = None,
                                         serviceType: Option[common.ServiceType] = None,
                                       ) {
      override def hashCode(): Int = id.hashCode

      override def toString: String = text
    }

    private class CombineBoxComponent(
                                       label: String,
                                       currentStandardValue: EventStream[Option[CombineBoxOption]],
                                       currentCustomValue: EventStream[Option[CombineBoxOption]],
                                       searchStandardItems: (common.AppKey, String) => EventStream[List[CombineBoxOption]],
                                       searchCustomItems: (common.AppKey, String) => EventStream[List[CombineBoxOption]],
                                       onChangeStandard: Observer[Option[String]],
                                       onChangeCustom: Observer[Option[Long]],
                                       validation: Signal[(Boolean, Boolean)],
                                     ) {
      private val serviceTypeBus: EventBus[Option[common.ServiceType]] = new EventBus()

      val validator: AuFormCustomValidator = AuFormCustomValidator()

      def onChangeServiceType: EventStream[Option[common.ServiceType]] = serviceTypeBus.events

      def node: HtmlElement = div(
        common.ui.Attribute.Selector := "data_mapper.components.dialogs.configuration.combine-box-component",

        child.maybe <-- currentStandardValue.combineWith(currentCustomValue).map {
          case (currentStandardValue, currentCustomValue) => Some(components.SuggestBox[CombineBoxOption](
            (txt: String) => $appKey // update suggest box options or search by text
              .flatMap(appKey => for {
                s <- searchStandardItems(appKey, txt)
                c <- searchCustomItems(appKey, txt)
              } yield (s, c))
              .map[List[CombineBoxOption]] {
                case (standardItems, customItems) => standardItems ++ customItems
                case _ => Nil
              }
              .toSignal(Nil),
            onChange = Observer.combine( // intercepting the user choice
              serviceTypeBus.writer.contramap((x: Option[CombineBoxOption]) => x.flatMap(_.serviceType)),
              onChangeStandard.contramap((x: Option[CombineBoxOption]) => x.filter(_.src.isEmpty).map(_.text)),
              onChangeCustom.contramap((x: Option[CombineBoxOption]) => x.flatMap(_.src)),
            ),
            limit = SuggestBox_DefaultRowsLimit, // rows on display
            required = Signal.fromValue(true), // mandatory field
            value = currentStandardValue.fold(currentCustomValue)(Some(_)), // current value
          )(
            _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
            _.label := label,
          ))
          case _ => None
        },

        validation --> Observer.combine(
          validator.dirty.writer.contramap((x: (Boolean, Boolean)) => x._1),
          validator.valid.writer.contramap((x: (Boolean, Boolean)) => x._1 && x._2),
        )
      )
    }

    private case class Controller(m: MutableModel) {
      val providerServiceType: Var[Option[common.ServiceType]] = Var(None)
      val providerRequiresAuth: Var[Boolean] = Var(false)

      private val nameField: AuFormField = AuFormField(material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium",
        _.label := "Name",
        _.required := true,
        _.value <-- m.name.signal,
        _ => onInput.mapToValue --> m.name.writer
      ))
      private val providerDescriptionCmp: CombineBoxComponent = new CombineBoxComponent(
        label = "Provider API description",
        currentStandardValue = model.signal
          .map(_.flatMap(_.providerDescription))
          .flatMap(x => EventStream.fromValue(x.map(v => CombineBoxOption(id = v, text = v, src = None)))),
        currentCustomValue = model.signal
          .map(_.flatMap(_.providerDescriptionId))
          .withCurrentValueOf($appKey)
          .flatMap(x => x._1.fold[EventStream[Option[CombineBoxOption]]]
            (EventStream.fromValue(None))
            (mid => dynamicApi.description(x._2).item(mid).map(c => Some(CombineBoxOption(id = c.id.getOrElse[Long](0).toString, text = c.name.getOrElse[String](""), src = c.id))))),
        searchStandardItems = (appKey: common.AppKey, text: String) => dynamicApi.description(appKey).standard.items(search = Some(text))
          .map(_.records.map(x => CombineBoxOption(id = x.name, text = x.name, None, serviceType = x.serviceType))),
        searchCustomItems = (appKey: common.AppKey, text: String) => dynamicApi.description(appKey).items(search = Some(text))
          .map(_.records.map(x => CombineBoxOption(id = x.id.getOrElse(-1).toString, text = x.name.getOrElse[String](""), src = x.id, serviceType = x.serviceType))),
        onChangeStandard = m.providerDescription.writer,
        onChangeCustom = m.providerDescriptionId.writer,
        validation = Signal.combineSeq[(Boolean, Boolean)](Seq(
          m.providerDescription.signal
            .map(_.getOrElse[String](""))
            .withCurrentValueOf[String](model.signal.map(_.flatMap(_.providerDescription).getOrElse[String]("")))
            .map[(Boolean, Boolean)](x => (x._1 != x._2, x._1.nonEmpty)),
          m.providerDescriptionId.signal
            .map(_.getOrElse[Long](0))
            .withCurrentValueOf[Long](model.signal.map(_.flatMap(_.providerDescriptionId).getOrElse[Long](0)))
            .map[(Boolean, Boolean)](x => (x._1 != x._2, x._1 > 0))
        ))
          .map(x => {
            log.info(s"au-form provider description: ${x.map(_._1.toString).mkString(" or ")}")
            x.find(_._1 == true).getOrElse((false, false))
          }),
      )
      private val providerMetadataCmp: CombineBoxComponent = new CombineBoxComponent(
        label = "Provider metadata",
        currentStandardValue = model.signal
          .map(_.flatMap(_.providerMetadata))
          .flatMap(x => EventStream.fromValue(x.map(v => CombineBoxOption(id = v, text = v, src = None)))),
        currentCustomValue = model.signal
          .map(_.flatMap(_.providerMetadataId))
          .withCurrentValueOf($appKey)
          .flatMap(x => x._1.fold[EventStream[Option[CombineBoxOption]]]
            (EventStream.fromValue(None))
            (mid => dynamicApi.metadata(x._2).item(mid).map(c => Some(CombineBoxOption(id = c.id.getOrElse[Long](0).toString, text = c.name.getOrElse(""), src = c.id))))),
        searchStandardItems = (appKey: common.AppKey, text: String) => dynamicApi.metadata(appKey).standard.items(search = Some(text), `type` = Some(MetadataModels.MetadataType.provider))
          .map(_.records.map(x => CombineBoxOption(id = x.name, text = x.name, None, serviceType = x.serviceType))),
        searchCustomItems = (appKey: common.AppKey, text: String) => dynamicApi.metadata(appKey).items(search = Some(text), `type` = Some(MetadataModels.MetadataType.provider))
          .map(_.records.map(x => CombineBoxOption(id = x.id.getOrElse(-1).toString, text = x.name.getOrElse[String](""), src = x.id, serviceType = x.serviceType))),
        onChangeStandard = m.providerMetadata.writer,
        onChangeCustom = m.providerMetadataId.writer,
        validation = Signal.combineSeq[(Boolean, Boolean)](Seq(
          m.providerMetadata.signal
            .map(_.getOrElse[String](""))
            .withCurrentValueOf[String](model.signal.map(_.flatMap(_.providerMetadata).getOrElse[String]("")))
            .map[(Boolean, Boolean)](x => (x._1 != x._2, x._1.nonEmpty)),
          m.providerMetadataId.signal
            .map(_.getOrElse[Long](0))
            .withCurrentValueOf[Long](model.signal.map(_.flatMap(_.providerMetadataId).getOrElse[Long](0)))
            .map[(Boolean, Boolean)](x => (x._1 != x._2, x._1 > 0))
        ))
          .map(x => {
            log.info(s"au-form provider metadata: ${x.map(_._1.toString).mkString(" or ")}")
            x.find(_._1 == true).getOrElse((false, false))
          }),
      )
      private val clientMetadataCmp: CombineBoxComponent = new CombineBoxComponent(
        label = "Virtual metadata",
        currentStandardValue = model.signal
          .map(_.flatMap(_.clientMetadata))
          .flatMap(s => EventStream.fromValue(s.map(v => CombineBoxOption(id = v, text = v, src = None)))),
        currentCustomValue = model.signal
          .map(_.flatMap(_.clientMetadataId))
          .withCurrentValueOf($appKey)
          .flatMap(x => x._1.fold[EventStream[Option[CombineBoxOption]]]
            (EventStream.fromValue(None))
            (mid => dynamicApi.metadata(x._2).item(mid).map(c => Some(CombineBoxOption(id = c.id.getOrElse[Long](0).toString, text = c.name.getOrElse(""), src = c.id))))),
        searchStandardItems = (appKey: common.AppKey, text: String) => dynamicApi.metadata(appKey).standard.items(search = Some(text), `type` = Some(MetadataModels.MetadataType.client))
          .map(_.records.map(x => CombineBoxOption(id = x.name, text = x.name, None, serviceType = x.serviceType))),
        searchCustomItems = (appKey: common.AppKey, text: String) => dynamicApi.metadata(appKey).items(search = Some(text), `type` = Some(MetadataModels.MetadataType.client))
          .map(_.records.map(x => CombineBoxOption(id = x.id.getOrElse(-1).toString, text = x.name.getOrElse[String](""), src = x.id, serviceType = x.serviceType))),
        onChangeStandard = m.clientMetadata.writer,
        onChangeCustom = m.clientMetadataId.writer,
        validation = Signal.combineSeq[(Boolean, Boolean)](Seq(
          m.clientMetadata.signal
            .map(_.getOrElse[String](""))
            .withCurrentValueOf[String](model.signal.map(_.flatMap(_.clientMetadata).getOrElse[String]("")))
            .map[(Boolean, Boolean)](x => (x._1 != x._2, x._1.nonEmpty)),
          m.clientMetadataId.signal
            .map(_.getOrElse[Long](0))
            .withCurrentValueOf[Long](model.signal.map(_.flatMap(_.clientMetadataId).getOrElse[Long](0)))
            .map[(Boolean, Boolean)](x => (x._1 != x._2, x._1 > 0))
        ))
          .map(x => {
            log.info(s"au-form client metadata: ${x.map(_._1.toString).mkString(" or ")}")
            x.find(_._1 == true).getOrElse((false, false))
          }),
      )
      private val mappingField = AuFormSelect(material.Select(
        _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
        _.label := "Mapping",
        _.value <-- m.mappingsId.signal.map(_.getOrElse[Long](0).toString),
        _.required := true,
        _ => children <-- model.signal
          .map[Option[Long]](_.flatMap(_.id))
          .map[Boolean](x => x.getOrElse[Long](0) > 0)
          .map {
            case false => material.List.ListItem(
              _.value := "0",
              _.selected <-- m.mappingsId.signal.map(_.getOrElse[Long](0).toString == "0"),
              _ => "New mapping",
              _ => onClick.mapTo(None) --> m.mappingsId.writer,
            ) ::
              li(cls := "mdc-list-divider") ::
              Nil
            case _ => Nil
          },
        _ => children <-- $appKey
          .flatMap(dynamicApi.mapping(_).items().map(_.records))
          .map(_.map(x => material.List.ListItem(
            _.value := x.id.getOrElse[Long](-1).toString,
            _.selected <-- m.mappingsId.signal.map(_.getOrElse[Long](0) == x.id.getOrElse[Long](-1)),
            _ => x.name.getOrElse[String](x.id.getOrElse[Long](-1).toString),
            _ => onClick.mapTo(x.id) --> m.mappingsId.writer,
          )))
          .map {
            case items if items.isEmpty => material.List.ListItem(
              _ => "no mapping",
              _.selected := false,
              _.value := "no-mapping",
              _.disabled := true,
            ) :: Nil
            case items => items
          },
      ))
      private val accountCmp: HtmlElement = div(
        child.maybe <-- providerServiceType.signal
          .combineWith(providerRequiresAuth.signal)
          .combineWith($appKey)
          .withCurrentValueOf(m.configAccountId.signal)
          .flatMap {
            case (Some(serviceType), true, appKey, Some(accountId)) =>
              // get account by id and check it service type with currently
              portalApi.account(appKey, accountId)
                .recoverToTry
                .map {
                  case Success(c) =>
                    Some(c)
                  case Failure(exception) =>
                    log.warn(s"not found account by id $accountId, ${exception.getMessage}")
                    None
                }
                .map(_.flatMap[common.PortalAccount] {
                  case account if account.serviceType.value == serviceType.value => // check service type
                    Some(account)
                  case _ =>
                    None
                })
                .map((serviceType, appKey, _))
                .map[Option[(common.ServiceType, common.AppKey, Option[common.PortalAccount])]](Some(_))
            case (Some(serviceType), true, appKey, None) =>
              EventStream.fromValue((serviceType, appKey, None))
                .map[Option[(common.ServiceType, common.AppKey, Option[common.PortalAccount])]](Some(_))
            case _ =>
              EventStream.fromValue[Option[(common.ServiceType, common.AppKey, Option[common.PortalAccount])]](None)
          }
          .map(_.map(x => (
            x._3,
            (txt: String, limit: Int) => portalApi.accounts(
              x._2,
              limit = limit,
              serviceType = Some(x._1),
              daemon = x._1.isDaemon,
              accountFilters = common.AccountFilters(
                hasValidToken = Var(Some(true)),
                searchText = Var(txt),
              )).map(_.records),
          )))
          .flatMap {
            case Some((None, getAccountStream)) => // load account when it need
              EventStream.fromValue(Some((None, getAccountStream)))
            case Some(x) =>
              EventStream.fromValue[Option[(Option[common.PortalAccount], (String, Int) => EventStream[List[common.PortalAccount]])]](Some(x))
            case _ =>
              EventStream.fromValue[Option[(Option[common.PortalAccount], (String, Int) => EventStream[List[common.PortalAccount]])]](None)
          }
          .map {
            case Some((account, stream)) => Some(components.SuggestBox[CombineBoxOption](
              (txt: String) => stream(txt, DataMapperApi.standardApiPageSize)
                .map(_.map(a => CombineBoxOption(
                  id = a.id.toString,
                  text = a.name
                    .map(n => s"$n${a.loginString.orElse(a.email).map(l => s" ($l)").getOrElse[String]("")}")
                    .orElse(a.loginString)
                    .orElse(a.email)
                    .getOrElse[String](""),
                  src = Some(a.id.toLong))
                ))
                //(if (txt.isEmpty) Signal.fromValue(defaultValue) else loadAccounts(txt).toSignal(Nil))
                //.map {
                //  case items if m.id.isEmpty =>
                //    CombineBoxOption(id = "0", text = "New account authorization", src = Some(0)) :: Nil ++ items
                //  case items =>
                //    items
                //}
                .toSignal(Nil),
              onChange = Observer.combine(
                m.configAccountId.writer.contramap((x: CombineBoxOption) => x.src.map(_.toInt)),
                m.configAccountDescription.writer.contramap((x: CombineBoxOption) => x.text),
              ).contramap((x: Option[CombineBoxOption]) => x.getOrElse(CombineBoxOption())),
              limit = SuggestBox_DefaultRowsLimit,
              value = account.map(a => CombineBoxOption(
                id = a.id.toString,
                text = a.name
                  .map(n => s"$n${a.loginString.orElse(a.email).map(l => s" ($l)").getOrElse[String]("")}")
                  .orElse(a.loginString)
                  .orElse(a.email)
                  .getOrElse[String](""),
                src = Some(a.id.toLong),
              )),
              required = Signal.fromValue(true),
              minCharactersForSearch = 0,
            )(
              _ => cls := "slds-col slds-size--1-of-1 slds-m-bottom_medium required",
              _.label := "Account",
            ))
            case _ => None
          }
      )

      private def customValidation: Seq[Signal[Boolean]] = Seq(
        m.name.signal.map(_.trim.nonEmpty),
        m.providerDescription.signal
          .combineWith(m.providerDescriptionId)
          .map(x => x._1.getOrElse[String]("").nonEmpty || x._2.getOrElse[Long](0) > 0),
        m.providerMetadata.signal
          .combineWith(m.providerMetadataId)
          .map(x => x._1.getOrElse[String]("").nonEmpty || x._2.getOrElse[Long](0) > 0),
        m.clientMetadata.signal
          .combineWith(m.clientMetadataId)
          .map(x => x._1.getOrElse[String]("").nonEmpty || x._2.getOrElse[Long](0) > 0),
        m.configAccountId.signal
          .combineWith(providerRequiresAuth.signal)
          .map(x => x._1.isDefined || !x._2),
      )

      def buttons: HtmlElement = {
        val configAccountAuFormEl: AuFormCustomValidator = AuFormCustomValidator()
        val state: AuFormState = AuFormState(
          nameField :: Nil,
          Nil,
          Nil,
          mappingField :: Nil,
          clientMetadataCmp.validator :: providerMetadataCmp.validator :: providerDescriptionCmp.validator :: configAccountAuFormEl :: Nil,
        )

        div(
          cls := "slds-col slds-size--1-of-1 slds-m-top--large",

          m.providerMetadata.signal
            .withCurrentValueOf($appKey)
            .flatMap {
              case (Some(providerMetadataName), appKey) if providerMetadataName.nonEmpty =>
                dynamicApi.metadata(appKey).standard.search(providerMetadataName)
                  .map[(Boolean, Option[common.ServiceType])](x => (
                    x.flatMap(_.requiresAuth).getOrElse(false),
                    x.flatMap(_.serviceType),
                  ))
              case _ =>
                EventStream.fromValue[(Boolean, Option[common.ServiceType])]((false, None))
            }
            --> Observer.combine(
            providerServiceType.writer
              .contramap((x: (Boolean, Option[common.ServiceType])) => x._2),
            providerRequiresAuth.writer
              .contramap((x: (Boolean, Option[common.ServiceType])) => x._1),
          ),

          m.configAccountId.signal
            .combineWith(model.signal.map(_.flatMap(_.configAccountId)))
            .map(x => (x._1.getOrElse(-1) != x._2.getOrElse(-1), x._1))
            --> Observer.combine(
            configAccountAuFormEl.dirty.writer.contramap((x: (Boolean, Option[Int])) => x._1),
            configAccountAuFormEl.valid.writer.contramap((x: (Boolean, Option[Int])) => x._1 && x._2.getOrElse(-1) >= 0),
          ),

          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
            div(cls := "slds-col"),
            div(
              cls := "slds-col",
              ButtonsPairComponent[(String, ConfigurationModels.Configuration), dom.MouseEvent](
                primaryDisabled = state.dirtySignal.combineWith(shrinkValidator(customValidation)).map(v => !v._1 || !v._2),
                primaryEffect = () => EventStream.fromValue((model.now().getOrElse(ConfigurationModels.Configuration()).name.getOrElse[String](""), m.toImmutableModel)),
                primaryObserver = Observer.combine(onChange, model.writer.contramap((_: (String, ConfigurationModels.Configuration)) => None)),
                secondaryObserver = model.writer.contramap((_: dom.MouseEvent) => None),
              ).node
            )
          )
        )
      }

      def name: material.Textfield.El = nameField.node

      def providerDescription: HtmlElement = providerDescriptionCmp.node

      def providerMetadata: HtmlElement = div(
        providerMetadataCmp.onChangeServiceType --> providerServiceType.writer,
        providerMetadataCmp.node
      )

      def clientMetadata: HtmlElement = clientMetadataCmp.node

      def mapping: material.Select.El = mappingField.node

      def account: HtmlElement = accountCmp
    }

    def node: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.configuration",

      material.Dialog(
        _ => cls := "width--medium",
        _.open <-- model.signal
          .map(_.isDefined)
          .map(x => {
            log.info(s"configuration dialog ${if (x) "open" else "close"}")
            x
          }),
        _.onClosing.mapTo(None) --> model.writer,
        _.heading <-- model.signal.map(_
          .flatMap {
            case v if v.id.getOrElse[Long](0) > 0 => Some(v.name.getOrElse[String](s"#${v.id.get}"))
            case _ => None
          }
          .fold[String]
            ("Create new configuration")
            (name => s"Edit $name configuration")),
        _.hideActions := true,

        _ => child.maybe <-- model.signal.map {
          case Some(m) =>
            val model = MutableModel(m)
            val controller = Controller(model)

            Some(div(
              controller.name,
              if (m.id.getOrElse[Long](0) > 0 && (m.clientMetadata.isDefined || m.clientMetadataId.isDefined))
                controller.clientMetadata
              else
                None,
              controller.providerMetadata,

              if (m.id.getOrElse[Long](0) > 0)
                controller.providerDescription
              else
                div(child.maybe <-- controller.providerServiceType.signal
                  .combineWith($appKey)
                  .combineWith(model.providerMetadata.signal
                    .combineWith(model.providerMetadataId)
                    .map(x => x._1.getOrElse[String]("").nonEmpty || x._2.getOrElse[Long](0) > 0))
                  .flatMap(x => for {
                    s <- if (x._3) dynamicApi.description(x._2).standard.items(serviceType = x._1, pageLimit = 2).map(_.records) else EventStream.fromValue[List[DescriptionModels.Standard]](Nil)
                    d <- if (x._3) dynamicApi.description(x._2).items(serviceType = x._1, pageLimit = 2).map(_.records) else EventStream.fromValue[List[DescriptionModels.Description]](Nil)
                  } yield (s, d))
                  .map[List[CombineBoxOption]] {
                    case (standards, items) => standards
                      .map[CombineBoxOption](x => CombineBoxOption(id = x.name, text = x.name, src = None, serviceType = x.serviceType)) ++
                      items.map[CombineBoxOption](x => CombineBoxOption(id = x.id.getOrElse(-1).toString, text = x.name.getOrElse[String](""), src = x.id, serviceType = x.serviceType))
                    case _ => Nil
                  }
                  .map {
                    case options if options.length == 1 =>
                      val option = options.head
                      if (option.src.isDefined) {
                        log.info(s"auto set providerDescriptionId: ${option.src.get}")
                        model.providerDescriptionId.set(option.src)
                        model.providerDescription.set(None)
                      } else {
                        log.info(s"auto set providerDescription: ${option.id}")
                        model.providerDescription.set(Some(option.id))
                        model.providerDescriptionId.set(None)
                      }
                      None
                    case options if options.length > 1 =>
                      Some(controller.providerDescription)
                    case _ =>
                      None
                  }),

              if (m.id.getOrElse[Long](0) > 0)
                controller.mapping
              else
                None,

              controller.account, // has internal condition

              controller.buttons,
            ))
          case _ => None
        },

        _ => onMountCallback(fixMwcDialogOverflow)
      )
        .withPing(portalApi)
        .closeOnSessionExpired(portalState.Session.$sessionExpiredEvents),
    )

    def writer: Observer[ConfigurationModels.Configuration] =
      model.writer.contramap((x: ConfigurationModels.Configuration) => Some(x))
  }
}
