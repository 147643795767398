package root_pages.auth_pages.verify_email

import com.github.uosis.laminar.webcomponents.material.CircularProgress
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ui.notifications.LocalMessagesView
import org.scalajs.dom.html
import root_pages.auth_pages.AuthPagesLogo
import portal_router.{BufferPage, PortalRouter, VerifyEmailPage}
import service.apis.portal_api.PortalApi
import service.exception_handler.ApiException

import scala.util.{Failure, Success}

class VerifyEmailComponent($route: Signal[VerifyEmailPage],
                           portalApi: PortalApi,
                           portalRouter: PortalRouter
                          ) {

  val errorMessage: Var[Option[String]] = Var(None)
  val success: Var[Boolean] = Var(false)

  val seconds = Var(6)

  val node: ReactiveHtmlElement[html.Div] = div(

    EventStream.fromValue(()).sample($route)
      .flatMap(route => portalApi.applyEmailVerification(route.key))
      .recoverToTry
      .map {
        case Success(_) =>
          success.set(true)
          true

        case Failure(exception) =>
        exception match {
          case exception: ApiException => errorMessage.set(Some(exception.message))
          case exception => errorMessage.set(Some(exception.getMessage))
        }
          false
      }
      .filter(_ == true)
      .flatMap(_ => EventStream.periodic(1000))
      .mapTo(seconds.now() - 1) --> seconds,

    seconds.signal.changes.filter(_ == 0) --> Observer[Int](onNext = _ => portalRouter.navigate(BufferPage)),

    cls := "slds-align--absolute-center auth-page",
    div(
      cls := "form",
      AuthPagesLogo,

      p(cls := "title--level-1 slds-m-top--xx-large slds-m-bottom--small", "Thank you!"),

      //LocalErrorMessageView(errorMessage.signal),
      LocalMessagesView(errorMessage.signal),

      child <-- success.signal.map{
        case false => div(

          cls := "slds-grid slds-grid--vertical-align-center",
          cls <-- errorMessage.signal.map{case None => "" case _ => "hidden"},

          p("Applying verification", cls := "slds-m-right--medium"),

          CircularProgress(
            _ => cls := "",
            _.closed := false,
            _.indeterminate := true,
            _.density := -8
          )
        )

        case true => div(
          p("Your email has been successfully verified.", cls := "slds-m-bottom--medium"),
          p(
            "You will be redirected to dashboard page in ",
            child.text <-- seconds.signal,
            " seconds."
          )
        )
      }
    )
  )

}
