package root_pages.aurinko_pages.app.settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Icon, Textfield}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement.Base
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.FormModel
import common.airstream_ops.{SignalNestedOps, SignalOps}
import common.ui.{AuFormField, AuFormState}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.notifications.LocalMessagesView
import org.scalajs.dom
import org.scalajs.dom.html
import root_pages.aurinko_pages.app.settings.DynamicFormType.DynamicFormType
import service.apis.portal_api.PortalApi
import service.portal_state.FormInputDataCaching
import wvlet.log.Logger

import scala.annotation.tailrec
import scala.scalajs.js

case class DynamicFormComponent[T](config: DynamicFormConfig,
                                   portalApi: PortalApi,
                                   formCaching: FormInputDataCaching,
                                   $initialExpand: Signal[Boolean],
                                   $startingList: Signal[List[String]],
                                   updateFunc: List[String] => EventStream[T],
                                   onUpdate: Observer[T],
                                   $sessionExpiredEvents: EventStream[Unit]
                                  ) {
  var fakeId: Int = 0

  val showInfoMessage: Var[Boolean] = Var(false)
  val editModel: DynamicFormModel = DynamicFormModel(formType = config.formType)
  val $textFields: Signal[List[(Int, AuFormField)]] = editModel.editingStrings.signal.split(_.now.id)(textField)

  val $formState: Signal[AuFormState] = $textFields.nestedMap(_._2).map(AuFormState(_))

  val isExpanded: Var[Boolean] = Var(false)


  val $isChanged: Signal[Boolean] = $startingList
    .combineWith(editModel.editingStrings.signal.flatMap(list => Signal.combineSeq(list.map(_.signal.map(_.content)))))
    .map { case (initial, current) => initial.length != current.length || initial.sorted != current.sorted }


  @tailrec
  final def formAList(list: List[String], listToFill: List[Var[IdItem]] = List()): List[Var[IdItem]] = list match {
    case ::(head, next) => fakeId += 1; formAList(next, Var(IdItem(fakeId - 1, head)) :: listToFill)
    case Nil => listToFill.reverse
  }

  val focusBus: EventBus[Unit] = new EventBus[Unit]

  val focusTextFieldModifier: Binder[Base] = focusBus.events
    .sample($textFields.map(_.lastOption.map(_._2)))
    .collect { case Some(f) => f }
    .map { field => {

      val done: Var[Boolean] = Var(false)
      val elemBus = new EventBus[Option[dom.html.Element]]
      val cssSelector = ".mdc-text-field__input"

      field.node.amend(
        done.signal.flatMap(s =>
          if (!s) {
            log.info(s"periodic ${config.formType}")
            EventStream.periodic(10, resetOnStop = true, emitInitial = true).mapTo(

              field.node.ref.asInstanceOf[js.Dynamic].shadowRoot.querySelector(cssSelector)
            )
          } else {
            EventStream.empty
          }
        ) --> Observer[js.Dynamic](onNext = elem => {
          if (elem != null) {
            done.set(true)
            field.node.ref.focus()
          }
        })
      )

      field.node.amend(
        EventStream.fromValue(()).delay(3000).filter(_ => !done.now) --> Observer[Unit](onNext = _ => {
          log.warn(s"Elem $cssSelector not found in shadow root")
          done.set(true)
          elemBus.emit(None)
        })

      )
    }
    } --> Observer.empty


  def textField(itemId: Int, initialItem: Var[IdItem], $item: Signal[Var[IdItem]]): (Int, AuFormField) =
    itemId -> AuFormField(Textfield(

      _ => cls := "width-medium",
      _.outlined := true,
      _.label := config.textFieldsLabel,
      _.required := true,
      _ => config.inputPattern.map(p => pattern := p),

      _.value <-- $item.flatMap(_.signal.map(_.content)),
      _ => composeEvents(onInput.mapToValue)(_.withCurrentValueOf($item.signal)) --> Observer[(String, Var[IdItem])] {
        case (str, item) =>
          item.set(IdItem(itemId, str))
      }

    ), initialValidation = true)

  def renderInputElement(idTextfiled: (Int, AuFormField)): ReactiveHtmlElement[html.Div] = div(
    cls := "slds-grid slds-grid_vertical-align-center slds-m-bottom_medium",

    idTextfiled._2.node,
    div(
      cls := "slds-col",
      Icon(
        _ => "close",
        _ => cls := "clickable slds-m-left_small small light",
        _ => onClick.mapTo(()) -->
          Observer[Unit](onNext = _ => {
            editModel.editingStrings.update(_.filter(_.now().id != idTextfiled._1))
          })
      )
    )
  )

  val emptyField: ReactiveHtmlElement[html.Div] = {
    val text: Var[String] = Var("")

    val textField =
      AuFormField(Textfield(
        _ => cls := "width-medium",
        _.outlined := true,
        _.label := config.emptyFieldLabel,
        _.value <-- text,
        _ => onInput.mapToValue --> text,
        _ => onInput.mapToValue --> Observer[String](onNext = x => {
          editModel.editingStrings.update(currentList => currentList :+ Var(IdItem(fakeId, x)))
          fakeId += 1
          text.set("")
          focusBus.emit(())
        })
      ))

    div(cls := "slds-grid slds-grid_vertical-align-center", textField.node,
      div(
        cls := "slds-col", Icon(
          _ => "add",
          _ => cls := "clickable slds-m-left_small small light",
          _ => onClick.mapTo(()) --> Observer[Unit](onNext = _ => {
            editModel.editingStrings.update(currentList => currentList :+ Var(IdItem(fakeId, "")))
            fakeId += 1
            focusBus.emit(())
          })
        ))
    )
  }

  val eventBinders = List(
    focusTextFieldModifier,

    $startingList.map(l => {
      formCaching.model match {

        case Some(f: DynamicFormModel) if f.formType == config.formType =>

          val strings = f.editingStrings.now
          fakeId = strings.map(_.now.id).max + 1
          formCaching.resetCache()
          isExpanded.set(true)
          strings

        case _ =>

          fakeId = 0
          formAList(l)
      }
    }) --> editModel.editingStrings,

    $sessionExpiredEvents.sample($isChanged)
      .filter(_ == true)
      .mapTo(editModel) --> Observer[DynamicFormModel](formCaching.cacheFormModel),

  )

  private val log = Logger.of[DynamicFormComponent[T]]


  val node: Div = div(

    eventBinders,

    ExpansionPanelComponent(

      header = p(
        config.heading,
        cls := "title--level-2",
        cls <-- $startingList
          .map { case Nil => "light" case _ => "" }
      ),

      body = Some(
        div(
          child.maybe <-- showInfoMessage.signal.map { show =>
            if (show) Some(config.infoMessage.getOrElse(div()))
            else None
          },
          config.descriptionElement,

          children <-- $textFields.nestedMap(renderInputElement),
          emptyField

        )
      ),
      footer = Some(ButtonsPairComponent(
        primaryDisabled = $isChanged
          .combineWith($formState.flatMap(_.validSignal))
          .map(t => !t._1 || !t._2),

        disabled = $isChanged.map(!_),
        primaryEffect = () => EventStream.fromValue(())
          .flatMap(_ => {
            showInfoMessage.set(true)
            updateFunc(editModel.toApiModel)
          }),

        primaryObserver = onUpdate,

        secondaryObserver = editModel.editingStrings.writer,

        secondaryEffect = () => $startingList.map(l => formAList(l)).stream

      ).node),
      bordered = Signal.fromValue(true),
      expanded = $initialExpand.combineWith(isExpanded.signal).map(e => e._1 || e._2),
      onExpansionChange = isExpanded.writer
    ).node,
  )


}

case class IdItem(id: Int, content: String)

case class DynamicFormModel(formType: DynamicFormType,
                            editingStrings: Var[List[Var[IdItem]]] = Var(Nil)
                           ) extends FormModel {
  def toApiModel: List[String] = editingStrings.now.map(_.now.content)
}

object DynamicFormType extends Enumeration {
  type DynamicFormType = Value

  val callbacks, domains = Value
}

case class DynamicFormConfig(formType: DynamicFormType,
                             heading: String,
                             textFieldsLabel: String,
                             emptyFieldLabel: String,
                             descriptionElement: Option[ReactiveHtmlElement[dom.html.Element]] = None,
                             inputPattern: Option[String] = None,
                             infoMessage: Option[ReactiveHtmlElement[dom.html.Element]] = None,
                             errorMessage: Option[String] = None,
                            )

object CallbacksFormConfig {
  val instance: DynamicFormConfig = DynamicFormConfig(
    DynamicFormType.callbacks,
    heading = "Callbacks",
    textFieldsLabel = "URI",
    emptyFieldLabel = "Add URI",
    descriptionElement = div(
      cls := "slds-p-bottom_large",
      span("Both the Authorization Code grant and the Implicit grant OAuth flows will only redirect to the exact URIs listed here. "),
      span("See our docs: ",
        a("Authentication flow", href := "https://docs.aurinko.io/article/13-authentication-flow"),
        "."
      )
    ).some,
    inputPattern = "(https?:\\/\\/).*".some
  )
}

object DomainsFormConfig {
  val instance: DynamicFormConfig = DynamicFormConfig(
    DynamicFormType.domains,
    heading = "Trusted domains",
    textFieldsLabel = "URI",
    emptyFieldLabel = "Add URI",
    descriptionElement = div(
      cls := "slds-p-bottom_large",
      span("The HTTP origins that host your web application. This allows API requests to be made from the client-side.")
    ).some,
      inputPattern = "^(https:\\/\\/(?:[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,}|chrome-extension:\\/\\/[a-zA-Z0-9\\-]+|https?:\\/\\/localhost(:\\d+)?)$".some,
    infoMessage = div(
      cls := "trusted-message",
      LocalMessagesView(
      Signal.fromValue(Some("Changes can take up to 10 minutes to take effect.")),
      Signal.fromValue(Some("message")),
      shouldScroll = false
    )).some
  )
}
