package common.ui.expansion_panel

import com.github.uosis.laminar.webcomponents.material
import com.raquo.laminar.nodes.ReactiveHtmlElement
import com.raquo.laminar.api.L._
import org.scalajs.dom
import org.scalajs.dom.html
import wvlet.log.Logger
import common.ui.animateVisibilityModifier

case class ExpansionPanelComponent(
                                    header: ReactiveHtmlElement[dom.html.Element],
                                    body: Option[ReactiveHtmlElement[dom.html.Element]] = None,
                                    footer: Option[ReactiveHtmlElement[dom.html.Element]] = None,
                                    bordered: Signal[Boolean] = Signal.fromValue(true),
                                    expanded: Signal[Boolean] = Signal.fromValue(false),
                                    expandable: Signal[Boolean] = Signal.fromValue(true),
                                    cssClass: Signal[String] = Signal.fromValue(""),
                                    reverse: Signal[Boolean] = Signal.fromValue(false),
                                    canHide: Signal[Boolean] = Signal.fromValue(false),
                                    onHide: Unit => Unit = _ => (),
                                    horizontalPadding: Boolean = true,
                                    onExpansionChange: Observer[Boolean] = Observer.empty,
                                    onMouseClick: Observer[dom.MouseEvent] = Observer.empty,

                                    id: Option[String] = None, // for auto dismiss logic

                                  ) {

  private val log = Logger.of[ExpansionPanelComponent]
  private val clickBus = new EventBus[dom.MouseEvent]
  private def toggleExpandOnClick: Observer[Boolean] = Observer[Boolean](onNext = exp => {
//    log.info(s"exp $exp is")
    if (exp) isExpanded.set(!isExpanded.now)
  })

  private val canExpand: Var[Boolean] = Var(false)
  private val isExpanded: Var[Boolean] = Var(false) // maybe you need to open the property and remove its duplicates
  val $isExpanded: Signal[Boolean] = isExpanded.signal

  val node: Div = div(
    isExpanded.signal.changes --> onExpansionChange,
    expandable --> canExpand.writer,
    expanded --> isExpanded.writer,

    cls := "expansion-panel",
    cls <-- bordered.map{case true => "border" case _ => ""},
    cls <-- expandable.map{case true => "expandable" case _ => ""},
    cls <-- isExpanded.signal.map{case true => "expanded" case _ => ""},
    cls <-- cssClass,

    div(
      cls := "header" + (if (horizontalPadding) "" else " no-horizontal-padding"),
//      cls <-- isExpanded.signal.map{case true => "primary" case false => "unset"},
      onClick --> Observer.combine(onMouseClick, clickBus.writer),
      clickBus.events.mapTo(canExpand.now) --> toggleExpandOnClick,
      p(
        cls := "slds-grid slds-grid--vertical-align-center",
        cls <-- reverse.combineWith(canHide).map{
          case (r, s) if r || s => "slds-grid--reverse"
          case _ => "slds-grid--align-spread slds-grow"
        },
        header,
        child.maybe <-- expandable.map{case false => None case _ =>
          Some(material.Icon(
            _ => "expand_more",
            _ => cls := "lighter expand-icon mdc-theme--text-secondary-on-background",
            _ => cls <-- reverse.combineWith(canHide).map{
              case (r, s) if r || s => "no-left-margin slds-m-right--large"
              case _ => "no-right-margin"
            }
          ))}
        ),
      child.maybe <-- canHide.map{
        case true => Some(
          material.Icon(
            _ => "close",
            _ => cls := "light-grey clickable no-right-margin",
            _ => onClick --> Observer[dom.MouseEvent](onNext = e => {
              onHide()
              e.stopPropagation
            })))
        case _ => None
      }
    ),
    body match {
      case None => None
      case Some(el) => div(
        cls := "body" + (if (horizontalPadding) "" else " no-horizontal-padding"),
        div(
          el,
          inContext(animateVisibilityModifier(isExpanded.signal))
        )
      )
    },
    footer match {
      case None => None
      case Some(el) => div(
        cls := "footer",
        el,
        inContext(animateVisibilityModifier(isExpanded.signal))
      )
    }
  )
}

case class AutoDismissiblePanelsGroup[T](
                                          $items: Signal[List[T]],
                                          renderFunction: (String, T, Signal[T]) => ExpansionPanelComponent,
                                          extractIdFunc: T => String
                                        ) {

  val expandedPanel: Var[Option[String]] = Var(None)
  val node: ReactiveHtmlElement[html.Div] = div(
    children <-- $items.split(extractIdFunc)(renderFunction).map(_.map(p =>
      {
        val panel = p.copy(
          expanded = expandedPanel.signal.map(expanded => expanded.isDefined
            && expanded.get == p.id.getOrElse(throw new Exception("Expansion panel need to have id for auto-dismiss logic "))),
       )

        div(
          panel.node,
          panel.$isExpanded.changes.filter(_ == true).mapTo(panel.id) --> expandedPanel.writer
        )
      }

    ))
  )
}
