package service.local_storage

import common.CirceStringOps
import io.circe.syntax.EncoderOps
import org.scalajs.dom.window
import wvlet.log.Logger

import scala.collection.mutable

trait LocalStorageService {
  def getItem(key: String): Option[String]

  def setItem(key: String, value: String): Unit

  def getStringsList(key: String): Option[List[String]]

  def setStringsList(key: String, value: List[String]): Unit

  def addListItem(key: String, value: String): Unit

  def addListItems(key: String, value: List[String])
}

case class LocalStorageServiceImp() extends LocalStorageService {
  private val log = Logger.of[LocalStorageServiceImp.type]

  override def getItem(key: String): Option[String] = {
    window.localStorage.getItem(key) match {
      case str: String =>
//        log.info(s"Got $key: $str")
        Some(str)
      case _ => None
    }
  }

  override def setItem(key: String, value: String): Unit = {
    window.localStorage.setItem(key, value)
  }

  override def getStringsList(key: String): Option[List[String]] = {
//    log.info(s"Getting list of strings: $key")
    getItem(key) match {
      case Some(str) =>
//        log.info(s"str: $str")
        str.safelyDecodeAs[List[String]]
      case _ => None
    }
  }

  override def setStringsList(key: String, value: List[String]): Unit = {
    setItem(key, value.asJson.toString)
  }

  override def addListItem(key: String, value: String): Unit = {
//    log.info(s"add list item $value")
    getStringsList(key) match {
      case None => setStringsList(key, List(value))
      case Some(list) => setStringsList(key, (mutable.Set[String]() ++ list + value).toList)
    }
  }

  override def addListItems(key: String, value: List[String]): Unit = {
    getStringsList(key) match {
      case None => setStringsList(key, value)
      case Some(list) => setStringsList(key, (list ::: value).distinct)
    }
  }
}
