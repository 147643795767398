package service.ui.messages

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import com.github.uosis.laminar.webcomponents.material
import portal_router.Page
import wvlet.log.Logger

trait Messages {
  def addMessage(mes: String, onClose: Observer[Unit] = Observer.empty): Unit

  def addReauthenticate(onClose: Observer[Unit]): Unit

  def addAccessDeniedMessage(onClose: Observer[Unit]): Unit
}

case class MessagesImp($currentPage: Signal[Page]) extends Messages {
  private val log = Logger.of[MessagesImp]

  private object Type extends Enumeration {
    type Type = Value
    val Reauthenticate, Notification, AccessDenied = Value
  }

  private case class Message(
                              text: String,
                              `type`: Type.Type,
                              onClose: Observer[Unit],
                              heading: Option[String] = None
                            ) {
    def Id: Int = text.hashCode
  }

  private val messages: Var[List[Message]] = Var(List.empty)

  def addMessage(mes: String, onClose: Observer[Unit] = Observer.empty): Unit =
    appendMsg(mes, Type.Notification, onClose)

  def addReauthenticate(onClose: Observer[Unit] = Observer.empty): Unit = {

    //    log.info(s"addReauthenticate: $onClose")
    appendMsg(
      "Your current session has expired or become inactive and has been terminated.\n Please log in again to continue.",
      Type.Reauthenticate,
      onClose,
      heading = "Your session has expired".some)
  }

  def addAccessDeniedMessage( onClose: Observer[Unit] ): Unit = appendMsg(
    "You do not have the appropriate permissions or maybe you are trying to open an incorrect URL.",
    Type.AccessDenied,
    onClose,
    heading = "Access denied".some
  )

  private def appendMsg(
                         message: String,
                         `type`: Type.Type,
                         onClose: Observer[Unit],
                         heading: Option[String] = None
                       ): Unit = {
    val m = Message(message, `type`, onClose, heading)
    messages.update {
      case list if (!list.exists(_.Id == m.Id)
       && !list.exists(_.text.toLowerCase == m.text.toLowerCase)
        ) =>
        log.info(s"append #${m.Id.abs} message: $message")
        m :: list
      case list =>
        list
    }
  }

  private def removeMsg(m: Message): Unit = {
    messages.update {
      case list if list.exists(_.Id == m.Id) =>
        log.info(s"remove #${m.Id.abs} message: ${m.text}")
        list.filter(list => list.Id != m.Id)
      case list =>
        list
    }
  }

  private def buildReauthenticate(msg: Message, onClose: Observer[Message]): HtmlElement = buildNotificationWithAction(
    msg,
    Observer.combine(onClose, msg.onClose.contramap((_: Message) => ())),
      "Reauthenticate")

//  {
//    div(
//      cls := "aurinko-modal width-max_width-450 slds-m-top--x-large",
//      div(
//        cls := "aurinko-modal-caption",
//        span(cls := "aurinko-h1 slds-hyphenate", "Your session has expired"),
//      ),
//      div(
//        cls := "aurinko-modal-context",
//        p("Your current session has expired or become inactive and has been terminated."),
//        p(cls := "slds-m-top--small", "Please log in again to continue."),
//      ),
//      div(
//        cls := "aurinko-modal-footer",
//        material.Button(
//          _ => cls := "secondary",
//          _.label := "Reauthenticate",
//          _.raised := true,
//          _ => composeEvents(onClick.stopPropagation)(_
//            .mapTo(msg))
//            --> Observer.combine(onClose, msg.onClose.contramap((_: Message) => ())
//          )
//        ),
//      )
//    )
//  }

//  private def buildForbiddenNotification(msg: Message, onClose: Observer[Message]): HtmlElement = {
//    div(
//      cls := "aurinko-modal width-max_width-450 slds-m-top--x-large",
//      msg.heading.map(h => div(
//        cls := "aurinko-modal-caption",
//        span(cls := "aurinko-h1 slds-hyphenate", h),
//      )),
//      div(
//        cls := "aurinko-modal-context",
//        p("You do not have the appropriate permissions or maybe you are trying to open an incorrect URL."),
//        p(cls := "slds-m-top--small", "Please log in again to continue."),
//      ),
//      div(
//        cls := "aurinko-modal-footer",
//        material.Button(
//          _ => cls := "secondary",
//          _.label := "Go to Dashboard",
//          _.raised := true,
//          _ => composeEvents(onClick.stopPropagation)(_
//            .mapTo(msg))
//            --> Observer.combine(onClose, msg.onClose.contramap((_: Message) => ())
//          )
//        ),
//      )
//    )
//  }

  def buildNotificationWithAction(msg: Message, onClose: Observer[Message], closeActionLabel: String) = {
    div(
      cls := "aurinko-modal width-max_width-450 slds-m-top--x-large",
      msg.heading.map(h => div(
        cls := "aurinko-modal-caption",
        span(cls := "aurinko-h1 slds-hyphenate", h),
      )),
      div(
        lineHeight := "2rem",
        cls := "aurinko-modal-context",
        p(msg.text),
      ),
      div(
        cls := "aurinko-modal-footer",
        material.Button(
          _ => cls := "secondary",
          _.label := closeActionLabel,
          _.raised := true,
          _ => composeEvents(onClick.stopPropagation)(_
            .mapTo(msg))
            --> Observer.combine(onClose, msg.onClose.contramap((_: Message) => ())
          )
        ),
      )
    )
  }

  private def renderMsg($event: Signal[Message], onClose: Observer[Message]): Div =
    div(
      child <-- $event.map {
        case msg if msg.`type` == Type.Reauthenticate =>
          buildNotificationWithAction(
            msg,
            Observer.combine(onClose, msg.onClose.contramap((_: Message) => ())),
            "Reauthenticate")
        case msg if msg.`type` == Type.AccessDenied => buildNotificationWithAction(
          msg,
          Observer.combine(onClose, msg.onClose.contramap((_: Message) => ())),
          "Ok")
        case msg => div(
          cls := "message-snackbar slds-grid slds-p-around_large slds-m-top_large slds-grid--vertical-align-center",
          p(
            cls := "slds-col slds-size_8-of-12",
            div(cls := "slds-m-right--large", whiteSpace := "pre-wrap", msg.text),
          ),
          div(
            cls := "slds-col slds-size_4-of-12 slds-align_absolute-center",
            material.Button(
              _.label := "Close",
              _ => composeEvents(onClick.stopPropagation)(_
                .mapTo(msg))
                --> Observer.combine(onClose, msg.onClose.contramap((_: Message) => ())
              )
            )
          )
        )
      },
    )

  val node: Div = div(
    cls := "slds-grid slds-grid_vertical messages-container slds-grid--vertical-align-center",
    children <-- messages.signal
      .combineWith($currentPage)//TODO: do we need this check?
      .map {
        //case (m, _: router.LoadingPage) => (m, m.exists(_.`type` == Type.Reauthenticate))
        case (m, _: portal_router.LoginPage) => (m, m.exists(_.`type` == Type.Reauthenticate))
        case (message, _) => (message, false)
      }
      .map {
        case (message, true) =>
          val withoutReauthenticate = message.filter(_.`type` != Type.Reauthenticate)
          messages.set(withoutReauthenticate)
          withoutReauthenticate
        case (message, _) => message
      }
      .split(_.Id)((_: Int, _: Message, $event: Signal[Message]) => renderMsg(
        $event,
        Observer[Message](onNext = removeMsg),
      ))
  )
}
