package root_pages.aurinko_pages.app.virtual_api

import com.github.uosis.laminar.webcomponents.material
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.{CirceStringOps, InstantOps, PortalTeam, download}
import common.airstream_ops._
import common.ui.breadcrumbs.BreadcrumbsItem
import org.scalajs.dom.{FileReader, html}
import root_pages.aurinko_pages.app.virtual_api.helpers.builder
import portal_router._
import root_pages.aurinko_pages.app.virtual_api.components.VirtualExpansionPanelComponent
import service.apis.dynamic_api.DataMapperApi.ApiException
import service.apis.dynamic_api.DataMapperModels._
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import service.scroll_ops.ScrollOps
import cats.implicits.{catsSyntaxApplicativeError, catsSyntaxOptionId, none}
import com.github.uosis.laminar.webcomponents.material.{Button, Checkbox, Dialog}
import common.ui.buttons_pair.ButtonsPairComponent
import io.circe.generic.auto.{exportDecoder, exportEncoder}
import io.circe.syntax.EncoderOps
import io.circe.{Decoder, Json, parser}
import org.scalajs.dom
import org.scalajs.dom.raw.{Blob, BlobPropertyBag, URL}
import org.scalajs.dom.raw.FileList
import service.apis.dynamic_api.{DataMapperApi, DataMapperModels}
import wvlet.log.Logger

import scala.scalajs.js
import scala.scalajs.js.JSConverters.{JSRichOption, iterableOnceConvertible2JSRichIterableOnce}
import scala.util.{Failure, Success}
import scala.util.matching.Regex

case object FieldsAndRelationsPage {
  private val log = Logger.of[FieldsAndRelationsPage.type]

  private val rxName: Regex = "^[A-Za-z][A-Za-z0-9_-]+$".r

  private object Tab extends Enumeration {
    case class Val(value: String, label: String) extends super.Val(value)

    type Tab = Val

    val NewFields: Tab.Tab = Val("newFields", "Fields") // (NEW UI)
    val Fields: Tab.Tab = Val("fields", "Fields")
    val Relations: Tab.Tab = Val("relations", "Relations")

    val all: Seq[Tab.Tab] = NewFields :: Relations :: Nil
  }

  val searchText: Var[String] = Var("")

  private def jCastToFields(m: Map[String, Json]): List[MetadataModels.Field] =
    m.map {
      case (n, v) if v.isArray =>
        v.asArray.get.headOption.flatMap {
          case a if a.isObject =>
            val l = jCastToFields(a.asObject.get.toMap)
            Some(MetadataModels.Field(
              name = n,
              `type` = MetadataModels.DataType.record,
              structure = MetadataModels.FieldStructure.array,
              recordSchema = Some(MetadataModels.RecordSchema(name = n, fields = l)),
            ))
          case a if a.isString =>
            Some(MetadataModels.Field(
              name = n,
              `type` = MetadataModels.DataType.string,
              structure = MetadataModels.FieldStructure.array,
            ))
          case a if a.isNumber =>
            Some(MetadataModels.Field(
              name = n,
              `type` = MetadataModels.DataType.double,
              structure = MetadataModels.FieldStructure.array,
            ))
          case _ =>
            None
        }
      case (n, v) if v.isObject =>
        val l = jCastToFields(v.asObject.get.toMap)
        Some(MetadataModels.Field(
          name = n,
          `type` = MetadataModels.DataType.record,
          structure = MetadataModels.FieldStructure.singular,
          recordSchema = Some(MetadataModels.RecordSchema(name = n, fields = l)),
        ))
      case (n, v) if v.isString =>
        Some(MetadataModels.Field(
          name = n,
          `type` = MetadataModels.DataType.string,
          structure = MetadataModels.FieldStructure.singular,
        ))
      case (n, v) if v.isNumber =>
        Some(MetadataModels.Field(
          name = n,
          `type` = MetadataModels.DataType.double,
          structure = MetadataModels.FieldStructure.singular,
        ))
      case _ =>
        None
    }.toList.flatten

  private def parseJsonToField(jsonString: Json): Decoder.Result[MetadataModels.Field] = {
    jsonString.as[MetadataModels.Field]
  }

  def parseJson(jsonElem: Json): List[MetadataModels.Field] = {
    if (isJsonClass(jsonElem)) {
      throw new RuntimeException("JSON match MetadataModels.Class")
    }

    parseJsonToField(jsonElem) match {
      case Right(fieldsArray) => List(fieldsArray)
      case Left(error) =>
        println(s"Error parsing JSON element: $error")
        if (jsonElem.isObject) {
          val jsonROOT = jsonElem.asObject.get.toMap
          println(s"Root: $jsonROOT")
          jCastToFields(jsonROOT)
        } else {
          println(s"Unsupported JSON element type")
          throw new RuntimeException("Unexpected error")
        }
    }
  }

  def isJsonClass(jsonElem: Json): Boolean = {
    jsonElem.as[MetadataModels.Class] match {
      case Right(_) => true
      case Left(_) => false
    }
  }

  def byName(
              $route: Signal[VirtualAPIsPage_ClassOfStandardMetadata],
              portalApi: PortalApi,
              dynamicApi: DataMapperApi.API,
              documentScrollOps: ScrollOps,
              portalRouter: PortalRouter,
              portalState: PortalState,
            ): HtmlElement = {
    val modelMetadata: Var[Option[MetadataModels.Standard]] = Var(None)
    val modelMetadataClass: Var[Option[MetadataModels.Class]] = Var(None)
    val accountHasError: Var[Boolean] = Var(false)

    val account: EventBus[Option[Option[(Int, String)]]] = new EventBus[Option[Option[(Int, String)]]]
    val onUpdateAccount: EventBus[Option[(Int, String)]] = new EventBus[Option[(Int, String)]]

    val accountID: Var[Option[Option[(Int, String)]]] = Var[Option[Option[(Int, String)]]](None)

    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_class_page.by_name",

      account.events --> accountID.writer,

      // UI
      FieldsAndRelationsPage(
        portalApi,
        dynamicApi,
        $route.map(_.appKey),
        $route.map(_.className),
        $route.map(_.configurationId.getOrElse[Long](0)),
        modelMetadata.signal.map(_.map(_.convertToMetadata)),
        modelMetadataClass.signal,
        $account = account.events.toSignal(None).map(_.flatten),
        $accountHasError = accountHasError.signal,
        onChangeAccount = onUpdateAccount.writer,

        virtualClassLoader = (className: String) => EventStream.fromValue(className)
          .withCurrentValueOf($route)
          .withCurrentValueOf(accountID.signal.map(_.flatten.map(_._1)))
          .flatMap {
            x =>
              dynamicApi.metadata(x._2.appKey).standard.`class`(
                x._2.metadataName,
                x._1,
                accountId = x._3,
              )
          }
          .map(Some(_)),
        virtualSchemaLoader = (schemaName: String) => EventStream.fromValue(schemaName)
          .withCurrentValueOf($route)
          .withCurrentValueOf(accountID.signal.map(_.flatten.map(_._1)))
          .flatMap {
            x =>
              dynamicApi.metadata(x._2.appKey).standard.schemas(
                x._2.metadataName,
                x._1,
                accountId = x._3,
              )
          }
          .map(Some(_)),
        portalState = portalState,
        documentScrollOps = documentScrollOps,
        portalRouter = portalRouter,
      ),

      // load standard metadata and default account
      child <-- $route
        .flatMap(route => for {
          m <- dynamicApi.metadata(route.appKey).standard.items(search = Some(route.metadataName)).map(_.records.find(_.name == route.metadataName))
          a <- m match {
            case Some(m) if m.requiresAuth.getOrElse(false) => portalApi.accounts(
                route.appKey,
                serviceType = m.serviceType,
                daemon = m.serviceType.exists(_.isDaemon),
                limit = 2,
                accountFilters = common.AccountFilters(hasValidToken = Var(Some(true))))
              .map(a => a.records.map(x => (x.id, x.name.getOrElse(""))))
              .map[Option[(Int, String)]](_.headOption)
              .map(Some(_))
            case Some(m) if !m.requiresAuth.getOrElse(true) => EventStream.fromValue(Some(None))
            case _ => EventStream.fromValue(None)
          }
        } yield (m, a, route))
        .map {
          case (defaultStandardMetadata, defaultAccount, route) =>
            if (defaultStandardMetadata.exists(_.name != route.metadataName)) {
              log.info(s"undefined ${route.metadataName} standard metadata")
              portalRouter.navigate(VirtualAPIsPage_ItemsOfProviderMetadata(route.appKey))
            }

            val accountsDialog = new components.dialogs.authorization.AccountList(
              route.appKey,
              portalApi,
              defaultStandardMetadata.flatMap(_.serviceType),
              account.writer.contramap((x: (Int, String)) => Some(Some(x))),
            )

            div(
              accountsDialog.node,

              onUpdateAccount.events --> accountsDialog.writer,

              // load standard metadata by account
              account.events
                .map(_.flatten.map(_._1))
                .withCurrentValueOf($route.map(_.configurationId))
                .flatMap(x => (for {
                  m <- dynamicApi.metadata(route.appKey).standard.item(route.metadataName, accountId = x._1)
                  c <- dynamicApi.metadata(route.appKey).standard.`class`(route.metadataName, route.className, accountId = x._1)
                } yield (m, Some(c)))
                  .recoverToTry
                  .map {
                    case Success(x) =>
                      accountHasError.set(false)
                      Some(x)
                    case Failure(exception) =>
                      exception match {
                        case e: ApiException if e.status == 404 =>
                          log.warn("class not found in the standard metadata")
                          accountHasError.set(false)
                          defaultStandardMetadata.map((_, None))
                        case e: ApiException if x._1.isDefined && e.status == 400 =>
                          log.warn("wrong account id needs to re-authenticate")
                          accountHasError.set(true) // reauthorize if something went wrong
                          defaultStandardMetadata.map((_, None))
                        case e: ApiException if x._1.isEmpty && e.status == 400 =>
                          log.warn("account id is required to load class list")
                          accountHasError.set(true) // reauthorize if something went wrong
                          defaultStandardMetadata.map((_, None))
                        case e =>
                          log.warn(e.getMessage)
                          throw e
                      }
                  })
                --> Observer.combine(
                modelMetadata.writer
                  .contramap((x: Option[(MetadataModels.Standard, Option[MetadataModels.Class])]) => x.map(_._1)),
                modelMetadataClass.writer
                  .contramap((x: Option[(MetadataModels.Standard, Option[MetadataModels.Class])]) => x.flatMap(_._2)),
              ),

              onMountCallback(_ => {
                modelMetadata.set(defaultStandardMetadata)
                account.emit(defaultAccount)
              })
            )
          case _ =>
            log.warn(s"undefined standard metadata or metadata class")
            div($route.map(route => VirtualAPIsPage_ItemsOfVirtualMetadata(route.appKey)) --> portalRouter.goto)
        }
    )
  }

  def byId(
            $route: Signal[VirtualAPIsPage_ClassOfCustomMetadata],
            portalApi: PortalApi,
            dynamicApi: DataMapperApi.API,
            documentScrollOps: ScrollOps,
            portalRouter: PortalRouter,
            portalState: PortalState,
          ): HtmlElement = {
    val model: Var[Option[MetadataModels.Metadata]] = Var[Option[MetadataModels.Metadata]](None)
    val onUpdateClass: EventBus[Option[MetadataModels.Class]] = new EventBus[Option[MetadataModels.Class]]

    val wrongFields = Var[Seq[String]](Nil)
    val readOnlyModeSignal: Signal[Boolean] = wrongFields.signal.map[Boolean](_.nonEmpty || true)

    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_class_page.by_id",

      $route
        .flatMap(_ => helpers.cache.metadata.LoadModel($route.map(_.appKey), $route.map(_.metadataId), dynamicApi))
        .recoverToTry
        .map {
          case Success(model) =>
            wrongFields.set(Nil)
            model
          case Failure(exception) =>
            exception match {
              case DataMapperApi.NotImplementedFields(fields, message, `object`: MetadataModels.Metadata) =>
                log.warn(s"incorrect fields, $message")
                wrongFields.set(fields)
                `object`
              case e => throw e
            }
        }
        .map(Some(_))
        --> model.writer,

      // update metadata
      onUpdateClass.events
        .withCurrentValueOf($route.map(_.className))
        .withCurrentValueOf(readOnlyModeSignal)
        .withCurrentValueOf(model.signal)
        .filter(x => !x._3 && x._4.isDefined)
        .map(x => (x._2, x._1.map(_.name), helpers.extension.Merge.Metadata.classToMetadata(x._4.get, x._2, x._1)))
        .flatMap(x => helpers.cache.metadata.SaveModel($route.map(_.appKey), dynamicApi, x._3).map(d => (x._1, x._2, d)))
        .withCurrentValueOf($route)
        .map[(MetadataModels.Metadata, Option[portal_router.Page])] {
          case (oldMCN, _, m, _) if m.classes.exists(_.name == oldMCN) =>
            (m, None)
          case (oldMCN, Some(newMCN), m, route) if !m.classes.exists(_.name == oldMCN) && m.classes.exists(_.name == newMCN) =>
            (
              m,
              m.id
                .map[portal_router.Page](mId => VirtualAPIsPage_ClassOfCustomMetadata(route.appKey, mId, newMCN, configurationId = route.configurationId))
                .orElse[portal_router.Page](Some(VirtualAPIsPage_ClassOfStandardMetadata(route.appKey, m.name.getOrElse[String](""), newMCN, configurationId = route.configurationId))),
            )
          case (_, _, m, route) =>
            (
              m,
              m.id
                .map[portal_router.Page](mId => VirtualAPIsPage_CustomMetadata(route.appKey, mId, configurationId = route.configurationId))
                .orElse[portal_router.Page](Some(VirtualAPIsPage_StandardMetadata(route.appKey, m.name.getOrElse[String](""), configurationId = route.configurationId))),
            )
        }
        --> Observer.combine(
        model.writer.contramap((x: (MetadataModels.Metadata, Option[portal_router.Page])) => Some(x._1)),
        portalRouter.gotoSome.contramap((x: (MetadataModels.Metadata, Option[portal_router.Page])) => x._2),
      ),

      // UI
      FieldsAndRelationsPage(
        portalApi,
        dynamicApi,
        $route.map(_.appKey),
        $route.map(_.className),
        $route.map(_.configurationId.getOrElse[Long](0)),
        model.signal,
        model.signal
          .map(_.map(_.classes))
          .combineWith($route)
          .map(x => x._1.flatMap(_.find(_.name == x._2.className))),
        readOnlyModeSignal,
        onUpdateMetadataClass = onUpdateClass.writer,

        virtualClassLoader = (className: String) => EventStream.fromValue(className)
          .withCurrentValueOf(model.signal)
          .map(x => x._2.map(_.classes).getOrElse(Nil).find(_.name == className)),
        virtualSchemaLoader = (schemaName: String) => EventStream.fromValue(schemaName)
          .withCurrentValueOf(model.signal)
          .map(x => x._2.map(_.schemas).getOrElse(Nil).find(_.name == schemaName)),
        portalState = portalState,
        documentScrollOps = documentScrollOps,
        portalRouter = portalRouter,
      ),
    )
  }

  /**
   * The apply method creates html page of MetadataModels.Metadata
   */
  def apply(
             portalApi: PortalApi,
             dynamicApi: DataMapperApi.API,
             $appKey: Signal[common.AppKey],
             $className: Signal[String],
             $defaultConfigurationId: Signal[Long],
             $metadata: Signal[Option[MetadataModels.Metadata]],
             $class: Signal[Option[MetadataModels.Class]],
             $readOnlyMode: Signal[Boolean] = Signal.fromValue(true),
             onUpdateMetadataClass: Observer[Option[MetadataModels.Class]] = Observer.empty,
             $account: Signal[Option[(Int, String)]] = Signal.fromValue(None),
             $accountHasError: Signal[Boolean] = Signal.fromValue(false),
             onChangeAccount: Observer[Option[(Int, String)]] = Observer.empty,
             virtualClassLoader: String => EventStream[Option[MetadataModels.Class]],
             virtualSchemaLoader: String => EventStream[Option[MetadataModels.Schema]],
             portalState: PortalState,
             documentScrollOps: ScrollOps,
             portalRouter: PortalRouter,
           ): HtmlElement = {
    val metadataClassDialog = new components.dialogs.metadata.Class()
    val mappingClassDialog = new components.dialogs.mappingOld.Class()
    val confirmationDialog = new components.dialogs.general.Confirmation(portalApi: PortalApi, portalState: PortalState)

    val appId: Seq[Int] = Seq(208, 426)

    // configuration & mappings
    val relatedMappings = new ObjectsPage.RelatedMappingsSelector(portalApi, dynamicApi, $appKey, $metadata, portalState = portalState, documentScrollOps = documentScrollOps, portalRouter = portalRouter)

    val mappingClass: Var[Option[MappingModels.Class]] = Var(None)
    val providerMetadataClass: Var[Option[MetadataModels.Class]] = Var(None)

    val busOpenMetadataClass = new EventBus[String]
    val busCreateMappingClass = new EventBus[String]
    val busUpdateMappingClass = new EventBus[Option[MappingModels.Class]]

    val activeTab: Var[Option[Tab.Tab]] = Var(Some(Tab.NewFields))
    val activeIndex: Var[Int] = Var(0)
    val tabLabel: Var[String] = Var("")

    val $classes: Signal[List[MetadataModels.Class]] = $metadata.map(_.map(_.classes).getOrElse(Nil))

    val $fields: Signal[List[MetadataModels.Field]] = $class.map(_.flatMap(_.fields).getOrElse(Nil))

    val fieldDialog = new components.dialogs.metadata.Field($fields, $classes)

    val buttonMappingImportBus: EventBus[Unit] = new EventBus[Unit]
    //  val buttonMetadataImportBus: EventBus[Unit] = new EventBus[Unit]
    //  val importMetadataBus: EventBus[List[MetadataModels.Field]] = new EventBus[List[MetadataModels.Field]]
    val importMappingBus: EventBus[List[MappingModels.Field]] = new EventBus[List[MappingModels.Field]]

    val buttonImport: Input = input(
      cls := "hidden",
      `type` := "file",
      onChange
        .mapTo(())
        --> buttonMappingImportBus.writer
    )

    val buttonExport = a(
      cls := "hidden",
      href <-- $class
        .combineWith(mappingClass.signal)
        .map {
          case (Some(_), Some(mappingClass)) =>
            mappingClass.fieldMappings.asJson.spaces2
          case (Some(clientClass), None) =>
            clientClass.fields.getOrElse(List.empty).asJson.spaces2
          case _ =>
            List.empty[String].asJson.spaces2
        }
        .map(f => new Blob(blobParts = f.toArray.map(_.asInstanceOf[js.Any]).toJSArray, options = BlobPropertyBag(`type` = Some("application/json").orUndefined)))
        .map(f => URL.createObjectURL(f)),
      download <-- $class
        .combineWith(mappingClass.signal)
        .map {
          case (Some(_), Some(_)) =>
            "MappingFields.json"
          case (Some(_), None) =>
            "MetadataFields.json"
          case _ =>
            "fields.json"
        },
    )

    //    def importAgree(): Unit = {
    //      val files: FileList = buttonImport.ref.files
    //      if (files.length > 0) {
    //        val file = files.item(0)
    //        val fileReader = new FileReader
    //        fileReader.onload = _ => {
    //          val fileContent = fileReader.result.asInstanceOf[String]
    //          val fieldList: List[MappingModels.Field] = common.CirceStringOps(fileContent).decodeAsRawData match {
    //            case Some(json) if json.isArray =>
    //              println(s"json $json")
    //              json.as[List[MappingModels.Field]]
    //                .getOrElse(List.empty[MappingModels.Field])
    //            case Some(json) if json.isObject =>
    //              json.as[MappingModels.Class]
    //                .map(_.fieldMappings)
    //                .orElse(json.as[MappingModels.Field].map(_ :: Nil))
    //                .getOrElse(List.empty[MappingModels.Field])
    //            case _ =>
    //              throw new RuntimeException("Unexpected error")
    //              List.empty[MappingModels.Field]
    //          }
    //          println(s"IMPORT M/G: json $fieldList")
    //          importMappingBus.emit(fieldList)
    //        }
    //        fileReader.readAsText(file, "UTF-8")
    //      }
    //    }

    val $visible: Var[Boolean] = Var(false)
    val onClose: Observer[Unit] = Observer(_ => $visible.set(false))

    val fileContentList: Var[List[MappingModels.Field]] = Var(List.empty)
    val selectedMappings: Var[List[MappingModels.Field]] = Var(List.empty)
    val allSelected = selectedMappings.signal.map { selected =>
      selected.size == fileContentList.now().size && selected.nonEmpty
    }

    val mDirection = MappingModels.Direction

    def splitItem(item: String): (String, String, String) = {
      val direction =
        if (item.contains(mDirection.bidirectional.icon)) mDirection.bidirectional.value
        else if (item.contains(mDirection.readOnly.icon)) mDirection.readOnly.value
        else if (item.contains(mDirection.writeOnly.icon)) mDirection.writeOnly.value
        else "unknown"

      val separator = direction match {
        case mDirection.bidirectional.value => mDirection.bidirectional.separator
        case mDirection.readOnly.value => mDirection.readOnly.separator
        case mDirection.writeOnly.value => mDirection.writeOnly.separator
        case _ => ""
      }
      val parts = item.split(separator)
      if (parts.length == 2) {
        (parts(0), direction, parts(1))
      } else {
        ("", direction, "")
      }
    }

    val filePreviewDialog: Dialog.El = Dialog(
      _ => cls := "json-dialog",
      _.open <-- $visible.signal,
      _.onClosing.mapTo(()) --> onClose,
      _ => div(
        cls := "filePreviewDialog",
        h1(cls := "title--level-2 slds-m-vertical--medium",
          "Mapping fields from json*"
        ),
        div(
          cls := "data-table data-table_json",
          div(
            cls := "table-header",
            span(cls := "slds-col slds-size--5-of-12  gray", "Client fields"),
            span(cls := "slds-col slds-size--2-of-12  gray", ""),
            span(cls := "slds-col slds-size--4-of-12  gray", "Provider fields"),
            span(cls := "slds-col slds-size--1-of-12  gray", ""),
          ),
          child.maybe <-- fileContentList.signal
            .map {
              case list if list.nonEmpty => Some(div(
                children <-- fileContentList.signal
                  .map { list =>
                    println(s"list $list")
                    list.map { item =>
                      val (clientField, direction, providerField) = splitItem(item.toString)
                      val isChecked = selectedMappings.signal.map(_.contains(item))
                      div(
                        cls := "table-row panel-like",
                        span(cls := "slds-size--5-of-12", clientField),
                        span(cls := "slds-size--2-of-12", direction match {
                          case mDirection.bidirectional.value => mDirection.bidirectional.icon
                          case mDirection.readOnly.value => mDirection.readOnly.icon
                          case mDirection.writeOnly.value => mDirection.writeOnly.icon
                          case _ => "<?>"
                        }),
                        span(cls := "slds-size--4-of-12", providerField),
                        span(cls := "slds-size--1-of-12", Checkbox(
                          _.checked <-- isChecked,
                          _ => onChange.mapToChecked --> { checked =>
                            if (checked) {
                              selectedMappings.update(_ :+ item)
                            } else {
                              selectedMappings.update(_.filterNot(_ == item))
                            }
                            println(s"selectedMappings ${selectedMappings.now()}")
                          }
                        )),
                      )
                    }
                  },

                div(
                  cls := "slds-grid slds-grid--align-end slds-grid_vertical-align-center",
                  p(
                    cls := "slds-m-right--medium text-bolder",
                    "Agree with all"),
                  Checkbox(
                    _.checked <-- allSelected,
                    _ => onChange.mapToChecked --> { checked =>
                      if (checked) {
                        selectedMappings.set(fileContentList.now())
                      } else {
                        selectedMappings.set(List.empty)
                      }
                      println(s"selectedMappings ${selectedMappings.now()}")
                    }
                  )
                ),
                div(
                  cls := "bold slds-m-vertical--medium slds-p-top--medium",
                  fontStyle := "italic",
                  borderTop := "1px solid #eee",
                  "* Please, check your json data and confirm mapping"
                ),
              ))
              case _ => Some(div(
                cls := "grey slds-m-vertical--large slds-align_absolute-center",
                "Your file does not have fields for mappings"
              ))
            },
        ),
      ),
      _.slots.primaryAction(
        div(
          cls := "slds-grid slds-grid--align-end slds-p-bottom_medium slds-p-right_large",
          Button(
            _ => cls := "slds-m-right--x-small secondary",
            _.label := "Cancel",
            _.outlined := true,
            _.raised := false,
            _ => onClick.mapTo(()) --> onClose
          ),
          Button(
            _ => cls := "secondary",
            _.label := "Confirm",
            _.raised := true,
            _ => onClick.mapTo(()) --> Observer[Unit](onNext = _ => {
              $visible.set(false)
              importMappingBus.emit(selectedMappings.now())
            }),
            _.disabled <-- selectedMappings.signal.map(_.isEmpty)
          ),
        )
      ),
    )

    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_class_page",

      //      importMetadataBus.events
      //        .withCurrentValueOf($class)
      //        .map {
      //          case (allNewFields, Some(classMD)) =>
      //            val existingFields = classMD.fields.getOrElse(List.empty)
      //            val notExistingFields = allNewFields.filter(f => !existingFields.exists(_.name == f.name))
      //            Some(classMD.copy(fields = Some(existingFields ++ notExistingFields)))
      //          case _ =>
      //            Option.empty[MetadataModels.Class]
      //        }
      //        --> onUpdateMetadataClass,

      importMappingBus.events
        .withCurrentValueOf(mappingClass)
        .withCurrentValueOf($class)
        .withCurrentValueOf(providerMetadataClass)
        .map {
          case (allNewFields, Some(classMG), Some(clientClassMD), Some(providerClassMD)) =>
            //val clientFields = clientClassMD.fields.getOrElse(List.empty)
            //val providerFields = providerClassMD.fields.getOrElse(List.empty)
            val existingFields = classMG.fieldMappings
            //val newFields = allNewFields.filter(f => clientFields.exists(c => f.clientFields.contains(c.name)) && providerFields.exists(p => f.providerFields.contains(p.name)))
            val notExistingFields = allNewFields.filter(f => !existingFields.exists(_.name == f.name) && !existingFields.exists(_.providerFields.mkString(",") == f.providerFields.mkString(",")))
            Some(classMG.copy(fieldMappings = existingFields ++ notExistingFields))
          case _ =>
            Option.empty[MappingModels.Class]
        }
        --> busUpdateMappingClass,

      // dialogs
      metadataClassDialog.node,
      mappingClassDialog.node,
      confirmationDialog.node,
      fieldDialog.node,
      relatedMappings.dialogs,
      buttonImport,
      buttonExport,
      filePreviewDialog,

      buttonMappingImportBus.events
        .withCurrentValueOf(mappingClass)
        .map(_.flatMap(_.virtual).contains(false))
        --> Observer[Boolean] { _ =>
        val files: FileList = buttonImport.ref.files
        if (files.length > 0) {
          val file = files.item(0)
          val fileReader = new FileReader
          fileReader.onload = _ => {
            val fileContent = fileReader.result.asInstanceOf[String]

            val fileContentToList = common.CirceStringOps(fileContent).decodeAsRawData match {
              case Some(json) if (json.isArray) =>
                json.as[List[MappingModels.Field]]
                  .getOrElse(List.empty[MappingModels.Field])

              case Some(json) if json.isObject =>
                json.as[MappingModels.Class]
                  .map(_.fieldMappings)
                  .orElse(json.as[MappingModels.Field].map(_ :: Nil))
                  .getOrElse(List.empty[MappingModels.Field])

              case _ => List.empty[MappingModels.Field]
            }

            fileContentList.set(fileContentToList)
            $visible.set(true)
            buttonImport.ref.value = ""
            selectedMappings.set(List.empty)
          }
          fileReader.readAsText(file, "UTF-8")
        }
      },

      //       update metadata field of class
      fieldDialog.events
        .withCurrentValueOf($readOnlyMode)
        .withCurrentValueOf($class)
        .filter(x => !x._3 && x._4.isDefined && x._4.isDefined)
        .map(x => helpers.extension.Merge.Metadata.fieldToClass(x._4.get, x._1, Some(x._2)))
        .map(Some(_))
        --> onUpdateMetadataClass,

      // provider class
      relatedMappings.mapping.signal
        .map(_.map(_.classes).getOrElse(Nil))
        .combineWith($className)
        .map(x => x._1.find(_.clientClass == x._2))
        --> mappingClass.writer,

      // load provider metadata class
      mappingClass.signal
        .map[Option[String]](_.flatMap(_.providerClass))
        .map(x => x.flatMap(_.split(":").headOption).orElse(x))
        .combineWith(relatedMappings.configuration.signal
          .map(x => (
            x.flatMap(_.providerMetadataId),
            x.flatMap(_.providerMetadata),
            x.flatMap(_.configAccountId),
          )))
        .combineWith(relatedMappings.providerMetadata.signal.map(_.map(_.classes).getOrElse(Nil)))
        .withCurrentValueOf[common.AppKey]($appKey)
        .flatMap {
          case (Some(providerClass), Some(providerMetadataId), _, _, providerClasses, _) if providerClass.nonEmpty && providerMetadataId > 0 =>
            EventStream.fromValue(providerClasses.find(_.name == providerClass))
          case (Some(providerClass), None, Some(providerMetadataName), aId, _, appKey) if providerClass.nonEmpty && providerMetadataName.nonEmpty =>
            dynamicApi.metadata(appKey).standard.`class`(
                providerMetadataName,
                providerClass,
                accountId = aId,
              )
              .recoverToTry
              .map[Option[MetadataModels.Class]] {
                case Success(x) =>
                  Some(x)
                case Failure(exception) =>
                  exception match {
                    case e: ApiException if e.status == 404 =>
                      log.warn("class not found in the standard metadata")
                      None
                    case e: ApiException if aId.isDefined && e.status == 400 =>
                      log.warn("wrong account id needs to re-authenticate")
                      None
                    case e: ApiException if aId.isEmpty && e.status == 400 =>
                      log.warn("account id is required to load class list")
                      None
                    case e =>
                      log.warn(e.getMessage)
                      throw e
                  }
              }
          case _ =>
            EventStream.fromValue[Option[MetadataModels.Class]](None)
        }
        --> providerMetadataClass.writer,

      // create mapping class
      busCreateMappingClass.events
        .withCurrentValueOf(mappingClass.signal)
        .map {
          case (_, Some(mgClass)) =>
            mgClass
          case (name, _) =>
            MappingModels.Class(clientClass = name)
          case _ =>
            MappingModels.Class()
        }
        --> mappingClassDialog.writer,

      // update mapping class
      busUpdateMappingClass.events
        .withCurrentValueOf(relatedMappings.$readOnlyMode)
        .filter(_._2 == false)
        .map(_._1)
        .withCurrentValueOf($className)
        .withCurrentValueOf(relatedMappings.mapping.signal)
        .map(x => (
          x._1,
          x._3.flatMap(_.classes.find(_.clientClass == x._2)),
          x._3,
        ))
        .map {
          case (newMappingClass, oldMappingClass, Some(mapping)) =>
            Some(helpers.extension.Merge.Mapping.classToMapping(
              mapping,
              oldMappingClass.map(_.clientClass)
                .getOrElse(newMappingClass.map(_.clientClass).getOrElse("")),
              newMappingClass,
            ))
          case _ =>
            None
        }
        .filter(_.isDefined)
        .map(_.get)
        --> relatedMappings.mappingUpdater,

      // update metadata class
      metadataClassDialog.events
        .map(x => Some(x._2))
        --> onUpdateMetadataClass,

      // update mapping class
      mappingClassDialog.events
        .map(x => Some(x._2))
        --> busUpdateMappingClass,

      // open class in new page of routers
      busOpenMetadataClass.events
        .filter(_.nonEmpty)
        .withCurrentValueOf($metadata.map(x => (x.flatMap(_.id), x.flatMap(_.name))))
        .withCurrentValueOf($defaultConfigurationId.map(x => Option.when(x > 0)(x)))
        .withCurrentValueOf($appKey)
        .map {
          case (className, Some(mId), _, configurationId, appKey) =>
            VirtualAPIsPage_ClassOfCustomMetadata(appKey, mId, className, configurationId = configurationId)
          case (className, _, Some(mName), configurationId, appKey) =>
            VirtualAPIsPage_ClassOfStandardMetadata(appKey, mName, className, configurationId = configurationId)
          case (_, _, _, _, appKey) =>
            VirtualAPIsPage_ItemsOfProviderMetadata(appKey)
        }
        --> portalRouter.goto,

      // init mapping class dialog of provider metadata name
      relatedMappings.configuration.signal
        .map[String](_
          .flatMap(x => x.providerMetadataName
            .orElse(x.providerMetadata)
            .orElse(x.providerMetadataServiceType.map(_.label)))
          .getOrElse("-"))
        --> mappingClassDialog.providerName,

      // init metadata class dialog of class names
      $metadata
        .map(_.map(_.classes).getOrElse(Nil))
        .combineWith($className)
        .map(x => x._1.filter(_.name != x._2))
        --> metadataClassDialog.classes,

      // init mapping class dialog of client classes
      $metadata
        .map(_.map(_.classes).getOrElse(Nil))
        --> mappingClassDialog.clientClasses,

      // init mapping class dialog of provider classes
      relatedMappings.providerMetadata.signal
        .map(_.map(_.classes).getOrElse(Nil))
        --> mappingClassDialog.providerClasses,

      // init configuration choice
      $defaultConfigurationId
        --> relatedMappings.writer,

      // UI
      components.BoardComponent(
        name = relatedMappings.mapping.signal
          .map(_.isDefined)
          .combineWith($metadata.map(_.exists(_.serviceType.isDefined)))
          .map[String] {
            case (true, _) => "Mapping object"
            case (false, true) => "Provider metadata object"
            case (false, false) => "Virtual metadata object"
            case _ => "Metadata object"
          },
        leftContext = div(
          // details
          VirtualExpansionPanelComponent(
            header = div(
              cls := "slds-grid slds-grid_vertical",
              // virtual metadata class name
              div(
                cls := "slds-grid slds-grid_vertical-align-center",
                child.maybe <-- $class
                  .map(_.map(_.name))
                  .combineWith($className)
                  .map {
                    case (Some(className), _) if className.nonEmpty =>
                      Some(span(
                        cls := "slds-grid slds-grid--vertical-align-center",
                        className,
                        child.maybe <-- $class
                          .map(_.exists(_.embedded))
                          .combineWith(relatedMappings.mapping.signal.map(_.isDefined))
                          .map(x => x._1 && x._2)
                          .map {
                            case true => Some(span(
                              cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--x-small",
                              small(cls := "badge", "embedded"),
                            ))
                            case _ => None
                          }
                      ))
                    case (None, className) if className.nonEmpty =>
                      Some(span(cls := "red", className.toLowerCase))
                    case _ =>
                      None
                  }.map(_.map(label => div(
                    cls := "slds-grid slds-grid--vertical slds-m-bottom_small",
                    small(
                      cls := "gray",
                      child.text <-- relatedMappings.mapping.signal
                        .map(_.isDefined)
                        .map {
                          case true => "Virtual object name"
                          case _ => "Name"
                        },
                    ),
                    label
                  ))),
                div(
                  cls := "slds-grid slds-grid_vertical-align-center slds-col_bump-left",
                  child.maybe <-- $className
                    .combineWith($readOnlyMode)
                    .combineWith($class)
                    .combineWith(relatedMappings.mapping.signal.map(_.isDefined))
                    .combineWith(relatedMappings.$readOnlyMode)
                    .map {
                      case (className, false, metadataClass, isMapping, _) if !isMapping || metadataClass.isEmpty =>
                        metadataClassDialog.writer.contramap((_: Unit) => metadataClass.getOrElse(MetadataModels.Class(name = className))).some
                      case (className, _, _, true, false) =>
                        busCreateMappingClass.writer.contramap((_: Unit) => className).some
                      case _ =>
                        None
                    }.map {
                      case Some(observer) if observer != Observer.empty => Some(material.Icon(
                        _ => cls := "light medium clickable mat-outlined",
                        _ => "edit",
                        _ => composeEvents(onClick.stopPropagation)(_.mapTo(())) --> observer
                      ))
                      case _ => None
                    },
                  child.maybe <-- $className
                    .combineWith($readOnlyMode)
                    .combineWith($class.map(_.isDefined))
                    .combineWith(relatedMappings.mapping.signal.map(_.isDefined))
                    .combineWith(relatedMappings.$readOnlyMode)
                    .combineWith(mappingClass.signal.map(_.isDefined))
                    .map[Option[(String, String, String, String, Observer[String], Boolean)]] {
                      case (className, _, _, true, false, isEnabled) =>
                        val label = "Delete mapping object"
                        val title = "Delete mapping object"
                        val message = s"Do you want to remove the $className object?"
                        val model = className
                        val observer = busUpdateMappingClass.writer.contramap((_: String) => None)
                        Some((label, title, message, model, observer, !isEnabled))
                      case (className, false, isEnabled, false, _, _) =>
                        val label = "Delete object"
                        val title = "Delete metadata object"
                        val message = s"Do you want to remove the $className object?"
                        val model = className
                        val observer = onUpdateMetadataClass.contramap((_: String) => None)
                        Some((label, title, message, model, observer, !isEnabled))
                      case _ =>
                        None
                    }
                    .map(_.map(x => material.Icon(
                      _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                      _ => "delete_outline",
                      _ => onClick.stopPropagation
                        .mapTo(confirmationDialog.mkHandle[String](
                          title = x._2,
                          message = x._3,
                          model = x._4,
                          onSuccess = x._5,
                          primaryButtonText = "Delete",
                          primaryButtonCls = "red",
                        )) --> confirmationDialog.writer[String],
                    )))
                )
              ),
              // provider metadata class name
              child.maybe <-- relatedMappings.mapping.signal
                .map(_.isDefined)
                .combineWith(mappingClass.signal.map(x => (x.flatMap(_.providerClass), x.flatMap(_.virtual))))
                .combineWith(relatedMappings.providerMetadata.signal.map(x => (x.flatMap(_.id), x.flatMap(_.name))))
                .flatMap {
                  case (true, Some(providerClass), virtual, pMetadataId, pMetadataName) if providerClass.nonEmpty =>
                    EventStream.fromValue((pMetadataId, pMetadataName))
                      .withCurrentValueOf($appKey)
                      .withCurrentValueOf($defaultConfigurationId.map(x => Option.when(x > 0)(x)))
                      .map[Option[portal_router.Page]] {
                        case (Some(metadataId), _, appKey, configurationId) =>
                          Some(VirtualAPIsPage_ClassOfCustomMetadata(appKey, metadataId, providerClass, configurationId = configurationId))
                        case (_, Some(metadataName), appKey, configurationId) =>
                          Some(VirtualAPIsPage_ClassOfStandardMetadata(appKey, metadataName, providerClass.split(":").headOption.getOrElse(providerClass), configurationId = configurationId))
                        case _ =>
                          None
                      }
                      .map(_.map(portalRouter.link))
                      .map[(Boolean, Option[String], Option[Boolean], Option[String])]((
                        true,
                        Some(providerClass),
                        virtual,
                        _,
                      ))
                  case x =>
                    EventStream.fromValue[(Boolean, Option[String], Option[Boolean], Option[String])]((
                      x._1,
                      x._2,
                      x._3,
                      None,
                    ))
                }
                .map[(Boolean, Option[HtmlElement], Option[Boolean], Option[String])] {
                  case (true, Some(providerClass), virtual, link) if providerClass.nonEmpty => (
                    true,
                    Some(span(
                      cls := "slds-grid slds-grid--vertical-align-center",
                      cls <-- providerMetadataClass.signal
                        .map(_.isDefined)
                        .map {
                          case false => "red"
                          case _ => ""
                        },
                      providerClass,
                      virtual.flatMap {
                        case true => Some(span(
                          cls := "badge slds-m-left--xx-small red",
                          "virtual"
                        ))
                        case _ => None
                      },
                      child.maybe <-- providerMetadataClass.signal
                        .map(_.exists(_.embedded))
                        .map {
                          case true => Some(span(
                            cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--x-small",
                            small(cls := "badge", "embedded"),
                          ))
                          case _ => None
                        },
                    )),
                    virtual,
                    link,
                  )
                  case (m, _, isVirtual, link) => (m, None, isVirtual, link)
                }
                .map[Option[HtmlElement]] {
                  case (true, Some(title), _, Some(link)) => Some(a(title, href := link))
                  case (true, Some(title), _, None) => Some(title)
                  case (true, None, Some(isVirtual), _) if isVirtual => Some(span(cls := "badge", "virtual"))
                  case (true, _, _, _) => Some(span(cls := "badge red", "not selected"))
                  case _ => None
                }
                .map(_.map(label => div(
                  cls := "slds-grid slds-grid--vertical",
                  small(cls := "gray", "Provider object name"),
                  label,
                ))),
            ),
            body = div(
              //cls := "border-bottom",
              child.maybe <-- $class
                .map(_.map(_.pluralName))
                .combineWith(relatedMappings.providerMetadata.signal.map(_.isDefined))
                .map {
                  case (Some(v), false) if v.nonEmpty => Some(div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Plural name"),
                    span(v),
                  ))
                  case _ => None
                },
              // virtual metadata class type (default\embedded) **only in the metadata mode**
              child.maybe <-- $class
                .map(_.map(_.embedded))
                .combineWith(relatedMappings.mapping.signal.map(_.isDefined))
                .map {
                  case (Some(true), false) => Some("Embedded")
                  case (Some(false), false) => Some("Default")
                  case _ => None
                }
                .map(_.map(value => div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(
                    cls := "gray",
                    child.text <-- relatedMappings.providerMetadata.signal
                      .map(_.isDefined)
                      .map {
                        case true => "Virtual object type"
                        case _ => "Type"
                      }
                  ),
                  span(value),
                ))),
              // provider metadata class comment
              child.maybe <-- mappingClass.signal
                .map(_.flatMap(_.comment))
                .map {
                  case Some(v) if v.nonEmpty => Some(div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Comment"),
                    span(v),
                  ))
                  case _ => None
                },
              // virtual metadata name
              child.maybe <-- $metadata
                .map(_.flatMap(_.name))
                .map {
                  case Some(v) => Some(div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(
                      cls := "gray",
                      child.text <-- relatedMappings.mapping.signal
                        .map(_.isDefined)
                        .map {
                          case true => "Virtual metadata"
                          case _ => "Metadata"
                        },
                    ),
                    span(v),
                  ))
                  case _ => None
                },
              // virtual metadata service type
              child.maybe <-- $metadata
                .map(_.flatMap(_.serviceType))
                .combineWith(relatedMappings.providerMetadata.signal.map(_.isDefined))
                .map {
                  case (Some(v), false) => Some(div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Service type"),
                    span(v.label),
                  ))
                  case _ => None
                },
              // provider metadata name
              child.maybe <-- relatedMappings.providerMetadata.signal
                .map(_.flatMap(_.name))
                .map {
                  case Some(v) => Some(div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Provider metadata"),
                    span(v),
                  ))
                  case _ => None
                },
              // provider metadata service type
              child.maybe <-- relatedMappings.providerMetadata.signal
                .map(_.flatMap(_.serviceType))
                .map(_.map(v => div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Provider service type"),
                  span(v.label),
                ))),
              // updated at
              child.maybe <-- $metadata
                .map(_.flatMap(_.updatedAt))
                .combineWith(relatedMappings.configuration.signal.map(_.flatMap(_.updatedAt)))
                .map[Option[String]] {
                  case (_, Some(configuration)) => Some(configuration.toPrettyLocalFormat) // provider metadata
                  case (Some(metadata), None) => Some(metadata.toPrettyLocalFormat) // virtual metadata
                  case _ => None
                }
                .map(_.map(v => div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Updated at"),
                  span(v),
                ))),
              // created at
              child.maybe <-- $metadata
                .map(_.flatMap(_.createdAt))
                .combineWith(relatedMappings.configuration.signal.map(_.flatMap(_.createdAt)))
                .map[Option[String]] {
                  case (_, Some(configuration)) => Some(configuration.toPrettyLocalFormat) // provider metadata
                  case (Some(metadata), None) => Some(metadata.toPrettyLocalFormat) // virtual metadata
                  case _ => None
                }
                .map(_.map(v => div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Created at"),
                  span(v),
                ))),
            ).some.signaled,
          ).node,
          // account
          child.maybe <-- $account
            .combineWith($accountHasError)
            .map {
              case (account, err) if account.isDefined || err => Some(div(
                cls := "border-bottom--light",
                div(
                  cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center slds-p-vertical--small",
                  p("Account", cls := "title--level-2"),
                  // TODO REVIEW: how we select account?
                  material.Icon(
                    _ => cls := s"medium clickable",
                    _ => cls <-- $accountHasError.map {
                      case true => "red"
                      case _ => "light"
                    },
                    _ => "edit",
                    _ => composeEvents(onClick.stopPropagation)(_.mapTo(account)) --> onChangeAccount,
                  )
                ),
                account.map {
                  case (accountId, accountName) if accountId > 0 => Some(div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Name"),
                    child <-- $appKey
                      .map(appKey => a(
                        cls := "brown",
                        accountName,
                        href := portalRouter.link(AccountPage(appKey, accountId)),
                      ))
                  ))
                  case _ => None
                },
              ))
              case _ => None
            },
          // related mappings
          relatedMappings.node,
        ),
        rightContext = div(
          marginTop := ".2rem",
          div(
            cls := "content-width virtual-content-width slds-grid",
            Tab.all.map(tab =>
              span(
                cls := "tab-button",
                cls <-- activeTab.signal
                  .map(_.exists(_.value == tab.value))
                  .map {
                    case true => "active"
                    case _ => ""
                  },
                child <-- relatedMappings.mapping.signal
                  .map(_.isDefined)
                  .map((tab, _))
                  .map {
                    case (Tab.NewFields, true) => "Fields mapping" // (NEW UI)
                    case (Tab.Fields, true) => "Fields mapping"
                    case (Tab.Relations, true) => "Relations mapping"
                    case _ => tab.label
                  },
                onClick.mapTo(Some(tab)) --> activeTab.writer,
              )
            ),
            activeTab.signal.combineWith(relatedMappings.mapping).signal
              .map {
                case (Some(tab), Some(_)) =>
                  tab match {
                    case Tab.NewFields if relatedMappings.mapping.signal.now.isDefined => "Fields mapping" // (NEW UI)
                    case Tab.Fields if relatedMappings.mapping.signal.now.isDefined => "Fields mapping"
                    case Tab.Relations if relatedMappings.mapping.signal.now.isDefined => "Relations mapping"
                    case _ => tab.label
                  }
                case (Some(tab), None) => tab.label
                case _ => ""
              } --> tabLabel,

            activeTab.signal.map {
              case Some(t) => Tab.all.indexOf(t)
              case None => -1
            } --> activeIndex,
          ),
          div(cls := "border-bottom--light slds-m-bottom_large"),
          div(
            cls := "slds-grid slds-grid--vertical",
            div(
              cls := "slds-col",
              child.maybe <-- activeTab.signal
                .combineWith($className)
                .map(x => x)
                .map {
                  case (Some(Tab.NewFields), className) => Some(renderFieldsTree(
                    $readOnlyMode = $readOnlyMode
                      .combineWith($appKey)
                      .map(x => x._1 && appId.contains(x._2.appId)),
                    $virtualMD = $metadata,
                    $virtualClassMD = $class,
                    $providerClassMD = providerMetadataClass.signal,
                    $configuration = relatedMappings.configuration.signal,
                    $classMG = mappingClass.signal,
                    virtualClassLoader = virtualClassLoader,
                    virtualSchemaLoader = virtualSchemaLoader,
                    providerClassLoader = (className: String) => EventStream.fromValue(className)
                      .withCurrentValueOf(relatedMappings.configuration.signal
                        .map(x => (
                          x.flatMap(_.providerMetadataId),
                          x.flatMap(_.providerMetadata),
                          x.flatMap(_.configAccountId),
                        )))
                      .withCurrentValueOf(relatedMappings.providerMetadata.signal.map(_.map(_.classes).getOrElse(Nil)))
                      .withCurrentValueOf[common.AppKey]($appKey)
                      .flatMap {
                        case (providerClass, Some(providerMetadataId), _, _, providerClasses, _) if providerClass.nonEmpty && providerMetadataId > 0 =>
                          EventStream.fromValue(providerClasses.find(_.name == providerClass))
                        case (providerClass, None, Some(providerMetadataName), aId, _, appKey) if providerClass.nonEmpty && providerMetadataName.nonEmpty =>
                          dynamicApi.metadata(appKey).standard.`class`(
                              providerMetadataName,
                              providerClass,
                              accountId = aId,
                            )
                            .recoverToTry
                            .map[Option[MetadataModels.Class]] {
                              case Success(x) =>
                                Some(x)
                              case Failure(exception) =>
                                exception match {
                                  case e: ApiException if e.status == 404 =>
                                    log.warn("class not found in the standard metadata")
                                    None
                                  case e: ApiException if aId.isDefined && e.status == 400 =>
                                    log.warn("wrong account id needs to re-authenticate")
                                    None
                                  case e: ApiException if aId.isEmpty && e.status == 400 =>
                                    log.warn("account id is required to load class list")
                                    None
                                  case e =>
                                    log.warn(e.getMessage)
                                    throw e
                                }
                            }
                        case _ =>
                          EventStream.fromValue[Option[MetadataModels.Class]](None)
                      },

                    providerSchemaLoader = (schemaName: String) => EventStream.fromValue(schemaName)
                      .withCurrentValueOf(relatedMappings.configuration.signal
                        .map(x => (
                          x.flatMap(_.providerMetadataId),
                          x.flatMap(_.providerMetadata),
                          x.flatMap(_.configAccountId),
                        )))
                      .withCurrentValueOf[common.AppKey]($appKey)
                      .flatMap {
                        case (providerSchema, None, Some(providerMetadataName), aId, appKey) if providerSchema.nonEmpty && providerMetadataName.nonEmpty =>
                          dynamicApi.metadata(appKey).standard.schemas(
                              providerMetadataName,
                              providerSchema,
                              accountId = aId,
                            )
                            .recoverToTry
                            .map[Option[MetadataModels.Schema]] {
                              case Success(x) =>
                                Some(x)
                              case Failure(exception) =>
                                exception match {
                                  case e: ApiException if e.status == 404 =>
                                    log.warn("schema not found in the standard metadata")
                                    None
                                  case e: ApiException if aId.isDefined && e.status == 400 =>
                                    log.warn("wrong account id needs to re-authenticate")
                                    None
                                  case e: ApiException if aId.isEmpty && e.status == 400 =>
                                    log.warn("account id is required to load class list")
                                    None
                                  case e =>
                                    log.warn(e.getMessage)
                                    throw e
                                }
                            }
                        case _ =>
                          EventStream.fromValue[Option[MetadataModels.Schema]](None)
                      },
                    confirmationDialog = confirmationDialog,
                    onCreateMappingClass = busCreateMappingClass.writer.contramap((_: Unit) => className),
                    onUpdateMappingClass = busUpdateMappingClass.writer,
                    onUpdateMetadataClass = onUpdateMetadataClass,
                  ))
                  case (Some(Tab.Fields), _) => Some(renderFieldsTable(
                    dynamicApi,
                    $appKey,
                    $defaultConfigurationId,
                    $metadata,
                    $class,
                    $readOnlyMode,
                    confirmationDialog,
                    relatedMappings,
                    mappingClass.signal,
                    providerMetadataClass.signal,
                    onUpdateMetadataClass = onUpdateMetadataClass,
                    onUpdateMappingClass = busUpdateMappingClass.writer,
                    onOpenMetadataClass = busOpenMetadataClass.writer,

                    classLoader = virtualClassLoader,

                    documentScrollOps = documentScrollOps,
                    portalRouter = portalRouter,
                  ))
                  case (Some(Tab.Relations), _) => Some(renderRelationsTable(
                    $appKey,
                    $defaultConfigurationId,
                    $metadata,
                    $class,
                    $readOnlyMode,
                    confirmationDialog,
                    relatedMappings,
                    mappingClass.signal,
                    providerMetadataClass.signal,
                    onUpdateMetadataClass = onUpdateMetadataClass,
                    onUpdateMappingClass = busUpdateMappingClass.writer,
                    onOpenMetadataClass = busOpenMetadataClass.writer,
                    documentScrollOps = documentScrollOps,
                    portalRouter = portalRouter
                  ))
                  case _ => None
                },
            ),
          ),
        ),
        documentScrollOps = documentScrollOps,
        navigation = $metadata
          .map(d => (d.flatMap(_.id), d.flatMap(_.name), d.flatMap(_.serviceType)))
          .combineWith($className)
          .combineWith($appKey)
          .combineWith($defaultConfigurationId.map[Option[Long]](x => Option.when(x > 0)(x)))
          .map {
            case (metadataId, Some(metadataName), metadataServiceType, className, appKey, configurationId) => List(
              metadataServiceType.fold[BreadcrumbsItem]
                (BreadcrumbsItem("Virtual models".signaled, Some(portalRouter.link(VirtualAPIsPage_ItemsOfVirtualMetadata(appKey)).signaled)))
                (_ => BreadcrumbsItem("Provider models".signaled, Some(portalRouter.link(VirtualAPIsPage_ItemsOfProviderMetadata(appKey)).signaled))),
              metadataId.map(mId => BreadcrumbsItem(metadataName.signaled, Some(portalRouter.link(VirtualAPIsPage_CustomMetadata(appKey, mId, configurationId = configurationId)).signaled)))
                .getOrElse[BreadcrumbsItem](BreadcrumbsItem(metadataName.signaled, Some(portalRouter.link(VirtualAPIsPage_StandardMetadata(appKey, metadataName, configurationId = configurationId)).signaled))),
              BreadcrumbsItem(className.signaled, None),
            )
            case _ => Nil
          },
        searchText = searchText,
        hasSearch = true,
        searchDelay = 100,

        buttons = $readOnlyMode
          .combineWith(relatedMappings.mapping.signal.map(_.isDefined))
          .combineWith($class.map(_.map(c => c.embedded || c.fields.map(_.flatMap(_.role)).exists(_.exists(_ == MetadataModels.FieldRole.id)))))
          .combineWith(providerMetadataClass.signal)
          .combineWith($appKey)
          .map {
            case (false, false, Some(hasRoleID), _, _) =>
              Seq(
                Option.when(!hasRoleID)(("Create ID field", fieldDialog.writer.contramap((_: Unit) => MetadataModels.Field(`type` = MetadataModels.DataType.string, role = Some(MetadataModels.FieldRole.id), required = true, readOnly = true)), false)),
                Some(("New field", fieldDialog.writer.contramap((_: Unit) => MetadataModels.Field(`type` = MetadataModels.DataType.string)), false)),
                //                Some(("Import fields", Observer[Unit](onNext = _ => buttonImport.ref.click()))), // TODO: icons import
                //                Some(("Export fields", Observer[Unit](onNext = _ => buttonExport.ref.click()))) // TODO: icons export
              ).filter(_.isDefined).map(_.get)
            case (false, true, _, n, _) =>
              println(s"provider class $n")
              Seq(
                Some(("Import mappings", Observer[Unit](onNext = _ => buttonImport.ref.click()), if (n.isEmpty) true else false)), // TODO: icons import
                Some(("Export mappings", Observer[Unit](onNext = _ => buttonExport.ref.click()), false)) // TODO: icons export
              ).filter(_.isDefined).map(_.get)

            case (true, true, _, n, appkey) if appId.contains(appkey.appId) =>
              println(s"provider class $n")
              Seq(
                Some(("Import mappings", Observer[Unit](onNext = _ => buttonImport.ref.click()), if (n.isEmpty) true else false)), // TODO: icons import
                Some(("Export mappings", Observer[Unit](onNext = _ => buttonExport.ref.click()), false)) // TODO: icons export
              ).filter(_.isDefined).map(_.get)
            //            case (true, _, _) =>
            //              Seq(
            //                Some(("Export fields", Observer[Unit](onNext = _ => buttonExport.ref.click()))) // TODO: icons export
            //              ).filter(_.isDefined).map(_.get)
            case _ => Nil
          },
        portalRouter = portalRouter
      ),

      // open this page with new configuration id
      relatedMappings.events
        .map[Option[Long]](x => Option.when(x > 0)(x))
        .withCurrentValueOf($className)
        .withCurrentValueOf($metadata.map(x => (x.flatMap(_.id), x.flatMap(_.name))))
        .withCurrentValueOf($appKey)
        .map[Option[portal_router.Page]] {
          case (configurationId, className, Some(metadataId), _, appKey) if metadataId > 0 =>
            Some(VirtualAPIsPage_ClassOfCustomMetadata(appKey, metadataId, className, configurationId = configurationId))
          case (configurationId, className, None, Some(metadataName), appKey) if metadataName.nonEmpty =>
            Some(VirtualAPIsPage_ClassOfStandardMetadata(appKey, metadataName, className, configurationId = configurationId))
          case _ => None
        }
        --> portalRouter.gotoSome,
    )
  }

  private def renderFieldsTree(
                                $readOnlyMode: Signal[Boolean] = Signal.fromValue(true),
                                $virtualMD: Signal[Option[MetadataModels.Metadata]],
                                $virtualClassMD: Signal[Option[MetadataModels.Class]],
                                $providerClassMD: Signal[Option[MetadataModels.Class]],
                                $configuration: Signal[Option[ConfigurationModels.Configuration]],
                                $classMG: Signal[Option[MappingModels.Class]],

                                virtualClassLoader: String => EventStream[Option[MetadataModels.Class]],
                                virtualSchemaLoader: String => EventStream[Option[MetadataModels.Schema]],
                                providerClassLoader: String => EventStream[Option[MetadataModels.Class]],
                                providerSchemaLoader: String => EventStream[Option[MetadataModels.Schema]],
                                confirmationDialog: components.dialogs.general.Confirmation,

                                onCreateMappingClass: Observer[Unit] = Observer.empty,
                                onUpdateMappingClass: Observer[Option[MappingModels.Class]] = Observer.empty,
                                onUpdateMetadataClass: Observer[Option[MetadataModels.Class]] = Observer.empty,
                              ): ReactiveHtmlElement[html.Div] = {
    val $fields: Signal[List[MetadataModels.Field]] = $virtualClassMD.map(_.flatMap(_.fields).getOrElse(Nil))

    val dlgFieldMD = new components.dialogs.metadata.Field($fields, $virtualMD.map(_.map(_.classes).getOrElse(Nil)))

    val busClickMG: EventBus[(String, Option[MappingModels.Field])] = new EventBus()
    val busClickMD: EventBus[(String, Option[MetadataModels.Field])] = new EventBus()

    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_class_page.treeFields",

      // update metadata field of class
      dlgFieldMD.events
        .withCurrentValueOf($readOnlyMode)
        .withCurrentValueOf($virtualClassMD)
        .filter(x => !x._3 && x._4.isDefined && x._4.isDefined)
        .map(x => helpers.extension.Merge.Metadata.fieldToClass(x._4.get, x._1, Some(x._2)))
        .map(Some(_))
        --> onUpdateMetadataClass,

      // dialogs
      dlgFieldMD.node,

      // create mapping class
      busClickMG.events
        .withCurrentValueOf($classMG)
        .filter(_._3.isEmpty) // ignore if has mapping class
        .map(_ => ())
        --> onCreateMappingClass,

      // dialog of mapping field
      child.maybe <-- busClickMG.events
        .withCurrentValueOf($classMG)
        .filter(_._3.isDefined) // ignore if does not have mapping class
        .withCurrentValueOf($configuration.map(_.map(c => (c.providerMetadataId, c.providerMetadata.orElse(c.providerMetadataName).getOrElse("")))))
        .map {
          case (fieldPath, Some(fieldData), Some(classMG), Some((providerMetadataId, providerMetadataName))) if fieldPath.nonEmpty =>
            log.info(s"update the $fieldPath mapping field")
            Some(components.dialogs.mapping.Field(
              (className: String) => virtualClassLoader(className).map(_.getOrElse(MetadataModels.Class(name = className))),
              (schemaName: String) => virtualSchemaLoader(schemaName).map(_.getOrElse(MetadataModels.Schema(name = schemaName))),
              (className: String) => providerClassLoader(className).map(_.getOrElse(MetadataModels.Class(name = className))),
              (schemaName: String) => providerSchemaLoader(schemaName).map(_.getOrElse(MetadataModels.Schema(name = schemaName))),
              providerName = providerMetadataName,
              fieldPathMG = fieldPath,
              fieldDataMG = fieldData,
              classMG = classMG,
              virtualClassMD = $virtualClassMD,
              providerClassMD = $providerClassMD,
              onChange = onUpdateMappingClass,
            ))
          case (fieldPath, None, Some(classMG), Some((_, providerMetadataName))) =>
            log.info(s"delete the $fieldPath mapping field")
            classMG.fieldMappings
              .find {
                case f if fieldPath.isEmpty =>
                  f.clientFields.mkString("") == ""
                case f if f.clientFields.nonEmpty =>
                  f.clientFields.contains(fieldPath)
                case _ =>
                  false
              }
              .map {
                field =>
                  div(
                    EventStream.fromValue(confirmationDialog.mkHandle[MappingModels.Field](
                      title = s"Delete mapping field for the $providerMetadataName",
                      message = s"Do you want to remove the ${field.clientFields.mkString(", ")} field?",
                      model = field,
                      onSuccess = onUpdateMappingClass.contramap((f: MappingModels.Field) => Some(helpers.extension.Merge.Mapping.fieldToClass(classMG, f.clientFields, None))),
                      primaryButtonText = "Delete",
                      primaryButtonCls = "red",
                    )) --> confirmationDialog.writer[MappingModels.Field],
                  )
              }
          case ("", Some(fieldData), Some(classMG), Some((_, providerMetadataName))) =>
            log.info(s"create new mapping field")
            Some(components.dialogs.mapping.Field(
              (className: String) => virtualClassLoader(className).map(_.getOrElse(MetadataModels.Class(name = className))),
              (schemaName: String) => virtualSchemaLoader(schemaName).map(_.getOrElse(MetadataModels.Schema(name = schemaName))),
              `#providerClassLoader` = (className: String) => providerClassLoader(className).map(_.getOrElse(MetadataModels.Class(name = className))),
              `#providerSchemaLoader` = (schemaName: String) => providerSchemaLoader(schemaName).map(_.getOrElse(MetadataModels.Schema(name = schemaName))),
              providerName = providerMetadataName,
              fieldPathMG = "",
              fieldDataMG = fieldData,
              classMG = classMG,
              virtualClassMD = $virtualClassMD,
              providerClassMD = $providerClassMD,
              onChange = onUpdateMappingClass,
            ))
          case _ =>
            log.info(s"ignore event for mapping field")
            None
        },

      // dialog of metadata field
      child.maybe <-- busClickMD.events
        .withCurrentValueOf($virtualClassMD)
        .map {
          case (fieldName, Some(fieldMD), Some(_)) if fieldName.nonEmpty =>
            log.info(s"update the $fieldName metadata field")
            Some(div(EventStream.fromValue(fieldMD) --> dlgFieldMD.writer))
          case (fieldName, None, Some(virtualClassMD)) =>
            log.info(s"delete the $fieldName metadata field")
            virtualClassMD.fields.
              flatMap(_.find(_.name == fieldName))
              .map {
                field =>
                  div(
                    EventStream.fromValue(confirmationDialog.mkHandle[MetadataModels.Field](
                      title = "Delete metadata field",
                      message = s"Do you want to remove the ${field.name} field?",
                      model = field,
                      onSuccess = onUpdateMetadataClass.contramap((f: MetadataModels.Field) => Some(helpers.extension.Merge.Metadata.fieldToClass(virtualClassMD, f.name, None))),
                      primaryButtonText = "Delete",
                      primaryButtonCls = "red",
                    )) --> confirmationDialog.writer[MetadataModels.Field],
                  )
              }
          case ("", Some(fieldMD), Some(_)) =>
            log.info(s"create new metadata field")
            Some(div(EventStream.fromValue(fieldMD) --> dlgFieldMD.writer))
          case _ =>
            log.info(s"ignore event for metadata field")
            None
        },

      // UI
      components.TreeViewOfField(
        $fields,
        $configuration
          .flatMap {
            case Some(_) => $classMG.map[Option[List[MappingModels.Field]]](_.map(_.fieldMappings).orElse(Nil.some))
            case None => Signal.fromValue[Option[List[MappingModels.Field]]](Option.empty[List[MappingModels.Field]])
          },
        (className: String) => virtualClassLoader(className).map(_.getOrElse(MetadataModels.Class(name = className))),
        (schemaName: String) => virtualSchemaLoader(schemaName).map(_.getOrElse(MetadataModels.Schema(name = schemaName))),
        $virtualObjectName = $configuration
          .map {
            configuration =>
              configuration.flatMap(_.clientMetadataId)
                .flatMap(_ => configuration.flatMap(_.clientMetadataName))
                .orElse(configuration.flatMap(_.clientMetadata))
                .map(_.trim)
          },
        $providerObjectName = $configuration
          .map {
            configuration =>
              configuration
                .flatMap(_.providerMetadataId)
                .flatMap(_ => configuration.flatMap(_.providerMetadataName))
                .orElse(configuration.flatMap(_.providerMetadata))
                .map(_.trim)
                .flatMap(n => Option.when(n.nonEmpty)(n))
          },
        $search = searchText.signal,
        onClickMap = busClickMG.writer,
        onClickField = busClickMD.writer,
        $readOnlyMode = $readOnlyMode
      )
    )
  }

  // TODO DEPRECATED: remove
  private def renderFieldsTable(
                                 dynamicApi: DataMapperApi.API,

                                 $appKey: Signal[common.AppKey],
                                 $defaultConfigurationId: Signal[Long],
                                 $metadata: Signal[Option[MetadataModels.Metadata]],
                                 $metadataClass: Signal[Option[MetadataModels.Class]],

                                 $readOnlyMode: Signal[Boolean] = Signal.fromValue(true),

                                 confirmationDialog: components.dialogs.general.Confirmation,

                                 relatedMappings: ObjectsPage.RelatedMappingsSelector,
                                 $mappingClass: Signal[Option[MappingModels.Class]],
                                 $providerMetadataClass: Signal[Option[MetadataModels.Class]],

                                 onUpdateMetadataClass: Observer[Option[MetadataModels.Class]] = Observer.empty,
                                 onUpdateMappingClass: Observer[Option[MappingModels.Class]] = Observer.empty,
                                 onOpenMetadataClass: Observer[String] = Observer.empty,

                                 classLoader: String => EventStream[Option[MetadataModels.Class]],

                                 documentScrollOps: ScrollOps,
                                 portalRouter: PortalRouter,
                               ): HtmlElement = {

    val $classes: Signal[List[MetadataModels.Class]] = $metadata.map(_.map(_.classes).getOrElse(Nil))
    val $fields: Signal[List[MetadataModels.Field]] = $metadataClass.map(_.flatMap(_.fields).getOrElse(Nil))

    val metadataFieldDialog = new components.dialogs.metadata.Field($fields, $classes)

    val fieldDlgBus = new EventBus[MappingModels.Field]
    // val searchText: Var[String] = Var("")


    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_class_page.fields",

      div(
        cls := "slds-m-bottom--small hidden",
        "DEBUG: ",
        p(
          cls := "slds-m-top--small",
          "MappingClass:",
          div(child.text <-- $mappingClass.map(_.isDefined).map(x => s"isDefined: $x")),
          div(child.text <-- $mappingClass.map(_.map(_.clientClass)).map(x => s"ClientClass: $x")),
          div(child.text <-- $mappingClass.map(_.flatMap(_.providerClass)).map(x => s"ProviderClass: $x")),
          div(child.text <-- $mappingClass.map(_.flatMap(_.virtual)).map(x => s"Virtual: $x")),
        ),
        p(
          cls := "slds-m-top--small",
          "ProviderMetadataClass:",
          div(child.text <-- $providerMetadataClass.map(_.map(_.name)).map(x => s"Name: $x")),
          div(child.text <-- $providerMetadataClass.map(_.flatMap(_.fields)).map(x => s"Fields: ${x.map(_.map(_.name))}")),
          div(child.text <-- $providerMetadataClass.map(_.flatMap(_.relations)).map(x => s"Relations: ${x.map(_.map(_.name))}")),
        ),
      ),

      // update metadata field of class
      metadataFieldDialog.events
        .withCurrentValueOf($readOnlyMode)
        .withCurrentValueOf($metadataClass)
        .filter(x => !x._3 && x._4.isDefined && x._4.isDefined)
        .map(x => helpers.extension.Merge.Metadata.fieldToClass(x._4.get, x._1, Some(x._2)))
        .map(Some(_))
        --> onUpdateMetadataClass,

      // UI
      child <-- $fields
        .combineWith($mappingClass.map(_.map(_.fieldMappings)))
        .map[List[(String, Seq[String], Seq[String], MetadataModels.Field)]] {
          case (metadataFields, Some(mappingFields)) if mappingFields.nonEmpty =>
            val mField = mappingFields
              .map(x => (
                s"${x.clientFields.mkString(";")}<${x.direction.map(_.value).getOrElse("")}>${x.rawValue.fold(x.providerFields.mkString(";"))(_ => "raw-value")}",
                x.clientFields,
                x.providerFields,
              ))
            val fields: Set[String] = mField.foldLeft[Seq[String]](Nil)((l, e) => l ++ e._2).toSet
            val df = metadataFields.filter(f => !fields.contains(f.name)).map(f => (s"${f.name}<->", Seq[String](f.name), Seq[String](), f))
            val mf = mField.map(f => {
              val fieldName = f._2.headOption.getOrElse("") // not support array of client fields
              (
                f._1, // key of string
                f._2, // client fields
                f._3, // provider fields
                metadataFields // client metadata field
                  .find(_.name == fieldName).
                  getOrElse(MetadataModels.Field(name = fieldName)),
              )
            })
            df ++ mf
          case (metadataFields, _) if metadataFields.nonEmpty =>
            metadataFields.map(f => (s"${f.name}<->", Seq[String](f.name), Seq[String](), f))
          case _ =>
            Nil
        }
        .map[String => EventStream[DataMapperApi.PageOf[(String, Seq[String], Seq[String], MetadataModels.Field)]]](fields => (txt: String) =>
          EventStream.fromValue(fields)
            .map {
              case x if txt.nonEmpty =>
                x.filter(_._2.exists(_.toLowerCase.contains(txt)))
              case x =>
                x
            }
            .map(_.sortWith((a, b) => a._4.role
              .map(_.weight)
              .orElse(Some(0))
              .map(_.compareTo(b._4.role.map(_.weight).getOrElse[Int](0)))
              .map {
                case x if x == 0 => b._1.compareTo(a._1)
                case x => x
              }
              .exists(_ > 0)))
            .map(items => DataMapperApi.PageOf[(String, Seq[String], Seq[String], MetadataModels.Field)](records = items))
        )
        .map(loadStream => div(
          components.TableComponent[(String, Seq[String], Seq[String], MetadataModels.Field), String](
            searchText = searchText,
            load = (_, _, txt) => loadStream(txt.toLowerCase),
            key = row => row._1,
            render = (_, $row) => {
              val mappingField: Signal[Option[MappingModels.Field]] = $mappingClass
                .map(_.map(_.fieldMappings).getOrElse(Nil))
                .combineWith($row.map(x => (x._2, x._3)))
                .map(x => x._1.find(f =>
                  f.clientFields.diff(x._2).isEmpty && x._2.diff(f.clientFields).isEmpty &&
                    f.providerFields.diff(x._3).isEmpty && x._3.diff(f.providerFields).isEmpty))
              val metadataField: Signal[MetadataModels.Field] = $row.map(_._4)

              div(
                cls := "table-row",
                div(
                  cls := "slds-size--5-of-12 au-truncate",
                  child <-- $row
                    .map(_._2)
                    .combineWith($metadataClass.map(_.flatMap(_.fields).getOrElse(Nil)))
                    .combineWith($classes.map(_.filter(_.embedded).map(_.name)))
                    .map {
                      case (fields, allFields, embeddedClasses) if fields.nonEmpty =>
                        fields
                          .map(fieldName => (fieldName, allFields.find(_.name == fieldName)))
                          .map {
                            case (fieldName, Some(field)) if fieldName.nonEmpty => span(
                              cls := "slds-grid slds-grid--vertical-align-center slds-m-right--x-small",
                              fieldName,
                              child.maybe <-- relatedMappings.mapping.signal
                                .map(_.isDefined)
                                .map {
                                  case true => Some(small(
                                    cls := "light slds-m-left--xx-small",
                                    builder.metadata.makeFieldTypeEl(
                                      Some(field),
                                      embeddedClasses,
                                      $metadata = $metadata,
                                      $defaultConfigurationId = $defaultConfigurationId.map(Some(_)),
                                      $appKey = $appKey.map(Some(_)),
                                      portalRouter = portalRouter
                                    ),
                                  ))
                                  case _ => None
                                },
                            )
                            case (fieldName, _) => span(cls := "red slds-m-right--x-small", fieldName)
                          }
                      case _ =>
                        Nil
                    }
                    .map(fields => div(cls := "slds-grid slds-grid--vertical-align-center", fields)),
                ),
                child <-- relatedMappings.mapping.signal
                  .map(_.isDefined)
                  .map {
                    case true => div(
                      cls := "slds-size--7-of-12 slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
                      // mapping field
                      child <-- mappingField.map(_.isDefined)
                        .map {
                          case true =>
                            div(
                              cls := "slds-col au-truncate slds-grid slds-grid--vertical-align-center",
                              // provider fields
                              child <-- mappingField
                                .map(mf => (mf.map(_.providerFields), mf.exists(_.rawValue.exists(_.nonEmpty))))
                                .combineWith($providerMetadataClass.map(_.flatMap(_.fields).getOrElse(Nil)))
                                .combineWith(relatedMappings.providerMetadata.signal.map(_.map(_.classes.filter(_.embedded)).getOrElse(Nil).map(_.name)))
                                .map {
                                  case (_, true, _, _) => span(
                                    cls := "slds-grid slds-grid--vertical-align-center slds-m-right--x-small",
                                    small(cls := "badge", "raw-value"),
                                  ) :: Nil
                                  case (Some(providerFields), false, _, _) if providerFields.exists(fn => !rxName.matches(fn)) => span(
                                    cls := "slds-grid slds-grid--vertical-align-center slds-m-right--x-small",
                                    small(cls := "badge", "custom-value"),
                                  ) :: Nil
                                  case (Some(providerFields), false, providerAllFields, embeddedClasses) =>
                                    providerFields
                                      .filter(_.nonEmpty)
                                      .map(fieldName => (fieldName, providerAllFields.find(_.name == fieldName)))
                                      .map {
                                        case (fieldName, Some(field)) if fieldName.nonEmpty => span(
                                          cls := "slds-grid slds-grid--vertical-align-center slds-m-right--x-small",
                                          fieldName,
                                          small(
                                            cls := "light slds-m-left--xx-small",
                                            builder.metadata.makeFieldTypeEl(
                                              Some(field),
                                              embeddedClasses,
                                              $metadata = $metadata,
                                              $defaultConfigurationId = $defaultConfigurationId.map(Some(_)),
                                              $appKey = $appKey.map(Some(_)),
                                              portalRouter = portalRouter
                                            ),
                                          ),
                                        )
                                        case (fieldName, _) => span(cls := "red slds-m-right--x-small", fieldName)
                                      }
                                  case _ =>
                                    Nil
                                }
                                .map(fields => div(cls := "slds-grid slds-grid--vertical-align-center", fields)),
                              // direction
                              child.maybe <-- mappingField
                                .map(_.flatMap(_.direction))
                                .map {
                                  case Some(direction) if direction != MappingModels.Direction.bidirectional => Some(span(
                                    cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--small",
                                    small(cls := "badge", direction.label)
                                  ))
                                  case _ => None
                                },
                              // provider field selector
                              child.maybe <-- mappingField
                                .map(_.flatMap(_.providerFieldSelector))
                                .map {
                                  case Some(selector) => Some(span(
                                    cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--small",
                                    small(cls := "badge", selector.label)
                                  ))
                                  case _ => None
                                },
                              // TODO[SEILBEK]: check and uncomment
                              // mappers or structureMappers
                              child.maybe <-- mappingField
                                .map(x => Seq(
                                  x.flatMap(_.mapper).isDefined,
                                  x.flatMap(_.readMapper).isDefined,
                                  x.flatMap(_.writeMapper).isDefined,
                                  x.flatMap(_.structureMapper).isDefined,
                                  x.flatMap(_.readStructureMapper).isDefined,
                                  x.flatMap(_.writeStructureMapper).isDefined,
                                  x.flatMap(_.providerFieldSelector).isDefined,
                                ).contains(true))
                                .map {
                                  case true => Some(span(
                                    cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--small",
                                    small(cls := "badge", "mapper")
                                  ))
                                  case _ => None
                                },
                              // tag
                              child.maybe <-- mappingField
                                .map(_.flatMap(_.tag).exists(_.nonEmpty))
                                .map {
                                  case true => Some(span(
                                    cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--small",
                                    small(cls := "badge", "tag")
                                  ))
                                  case _ => None
                                },
                              // TODO[SEILBEK]: check and uncomment
                              // description
                              child.maybe <-- mappingField
                                .map(_.flatMap(_.description).exists(_.nonEmpty))
                                .map {
                                  case true => Some(span(
                                    cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--small",
                                    small(cls := "badge", "description")
                                  ))
                                  case _ => None
                                },
                            )
                          case _ =>
                            div(
                              cls := "slds-grid slds-grid--vertical-align-center",
                              material.Icon(
                                _ => styleAttr := "transform: rotate(90deg);  color: var(--mat-grey-500);",
                                _ => "merge",
                              ),
                              material.Button(
                                _ => cls := "blue",
                                _.label := "Map",
                                _ => composeEvents(onClick.stopPropagation)(_
                                  .sample(mappingField)
                                  .withCurrentValueOf($row.map(_._2))
                                  .map(x => x._1.getOrElse(MappingModels.Field(clientFields = x._2))))
                                  --> fieldDlgBus.writer,
                              )
                            )
                        },
                      // mapping buttons
                      child.maybe <-- relatedMappings.$readOnlyMode
                        .combineWith(mappingField)
                        .combineWith($mappingClass)
                        .map {
                          case (false, Some(mField), mappingClass) => Some(div(
                            cls := "slds-col slds-grid slds-grid--vertical-align-center slds-text-align--right",
                            material.Icon(
                              _ => cls := "light medium clickable mat-outlined",
                              _ => "edit",
                              _ => composeEvents(onClick.stopPropagation)(_
                                .sample(mappingField)
                                .withCurrentValueOf($row.map(_._2))
                                .map(x => x._1.getOrElse(MappingModels.Field(clientFields = x._2))))
                                --> fieldDlgBus.writer,
                            ),
                            mappingClass.map(c => material.Icon(
                              _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                              _ => "delete_outline",
                              _ => onClick.stopPropagation
                                .mapTo(confirmationDialog.mkHandle[MappingModels.Field](
                                  title = "Delete mapping field",
                                  message = s"Do you want to remove the ${mField.clientFields.mkString(", ")} field?",
                                  model = mField,
                                  onSuccess = onUpdateMappingClass
                                    .contramap((f: MappingModels.Field) => Some(helpers.extension.Merge.Mapping.fieldToClass(c, f.clientFields, None))),
                                  primaryButtonText = "Delete",
                                  primaryButtonCls = "red",
                                )) --> confirmationDialog.writer[MappingModels.Field],
                            )),
                          ))
                          case _ => None
                        },
                    )
                    case _ => div(
                      cls := "slds-size--7-of-12 slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
                      // metadata field
                      div(
                        cls := "slds-col au-truncate slds-grid slds-grid--vertical-align-center",
                        child.maybe <-- metadataField
                          .combineWith($classes.map(_.filter(_.embedded).map(_.name)))
                          .map(x => builder.metadata.makeFieldTypeEl(
                            Some(x._1),
                            x._2,
                            $metadata = $metadata,
                            $defaultConfigurationId = $defaultConfigurationId.map(Some(_)),
                            $appKey = $appKey.map(Some(_)),
                            portalRouter = portalRouter
                          )),
                        child.maybe <-- metadataField
                          .map(_.role)
                          .map {
                            case Some(role) => Some(span(
                              cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--small",
                              small(cls := "badge", role.label)
                            ))
                            case _ => None
                          },
                        child.maybe <-- metadataField
                          .map(_.readOnly)
                          .map {
                            case true => Some(span(
                              cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--small",
                              small(cls := "badge", "read-only")
                            ))
                            case _ => None
                          },
                        child.maybe <-- metadataField
                          .map(_.required)
                          .map {
                            case true => Some(span(
                              cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--small",
                              small(cls := "badge", "required")
                            ))
                            case _ => None
                          },
                        child.maybe <-- metadataField
                          .map(_.referenceTo)
                          .map {
                            case Some(r) if r.nonEmpty => Some(span(
                              cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-left--small",
                              small(cls := "badge", "reference")
                            ))
                            case _ => None
                          },
                      ),
                      // metadata buttons
                      child.maybe <-- $readOnlyMode
                        .map {
                          case false => Some(div(
                            cls := "slds-col slds-grid slds-grid--vertical-align-center slds-text-align--right",
                            material.Icon(
                              _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                              _ => "edit",
                              _ => composeEvents(onClick.stopPropagation)(_
                                .sample(metadataField))
                                --> metadataFieldDialog.writer,
                            ),
                            child.maybe <-- metadataField
                              .combineWith($metadataClass)
                              .map {
                                case (row, Some(c)) => Some(material.Icon(
                                  _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                                  _ => "delete_outline",
                                  _ => onClick.stopPropagation
                                    .mapTo(confirmationDialog.mkHandle[MetadataModels.Field](
                                      title = "Delete metadata field",
                                      message = s"Do you want to remove the ${row.name} field?",
                                      model = row,
                                      onSuccess = onUpdateMetadataClass.contramap((f: MetadataModels.Field) => Some(helpers.extension.Merge.Metadata.fieldToClass(c, f.name, None))),
                                      primaryButtonText = "Delete",
                                      primaryButtonCls = "red",
                                    )) --> confirmationDialog.writer[MetadataModels.Field],
                                ))
                                case _ => None
                              },
                          ))
                          case _ => None
                        },
                    )
                  },
              )
            },
            header = div(
              cls := "table-header border-bottom--light",
              children <-- relatedMappings.mapping.signal
                .map(_.isDefined)
                .map {
                  case true => Seq(
                    span(
                      cls := "slds-size--5-of-12 gray au-truncate",
                      child.text <-- relatedMappings.configuration.signal.map(c => (
                          c.flatMap(_.clientMetadataId),
                          c.flatMap(_.clientMetadataName),
                          c.flatMap(_.clientMetadata),
                        ))
                        .map {
                          case (Some(clientMetadataId), Some(clientMetadataName), _) if clientMetadataId > 0 =>
                            s"$clientMetadataName field"
                          case (None, _, Some(clientMetadata)) if clientMetadata.nonEmpty =>
                            s"$clientMetadata field"
                          case _ =>
                            "Virtual field"
                        }
                    ),
                    span(
                      cls := "slds-size--7-of-12 gray au-truncate",
                      child.text <-- relatedMappings.configuration.signal
                        .map(c => (
                          c.flatMap(_.providerMetadataId),
                          c.flatMap(_.providerMetadataName),
                          c.flatMap(_.providerMetadata),
                        ))
                        .map[String] {
                          case (Some(providerMetadataId), Some(providerMetadataName), _) if providerMetadataId > 0 =>
                            s"$providerMetadataName field"
                          case (None, _, Some(providerMetadata)) if providerMetadata.nonEmpty =>
                            s"$providerMetadata field"
                          case _ =>
                            "Provider field"
                        },
                    ),
                  )
                  case _ => Seq(
                    span(cls := "slds-size--5-of-12 gray au-truncate", "Name"),
                    span(cls := "slds-size--7-of-12 gray au-truncate", "Type"),
                  )
                },
            ),
            noRows = "No fields",
            documentScrollOps = documentScrollOps
          ))
        ),

      // dialogs
      metadataFieldDialog.node,
      child.maybe <-- relatedMappings.configuration.signal
        .map(_.flatMap(x => x.providerMetadataName.orElse(x.providerMetadata).orElse(x.providerMetadataServiceType.map(_.label))))
        .combineWith($providerMetadataClass.map(_.flatMap(_.fields).getOrElse(Nil)))
        .combineWith($metadataClass.map(_.flatMap(_.fields).getOrElse(Nil)))
        .combineWith(relatedMappings.providerMetadata.signal.map(_.map(_.classes).getOrElse(Nil)))
        .combineWith($metadata.map(_.map(_.classes).getOrElse(Nil)))
        .combineWith($mappingClass.map(_.flatMap(_.virtual).getOrElse(false)))
        .combineWith($mappingClass.map(_.map(_.fieldMappings).getOrElse(Nil)).map(x => (
          x.map(_.clientFields).fold(Nil)((l, i) => l ++ i).toList,
          x.map(_.providerFields).fold(Nil)((l, i) => l ++ i).toList,
        )))
        .map {
          case (Some(providerName), providerFields, clientFields, providerClasses, clientClasses, isVirtualMode, mappingClientFields, mappingProviderFields) =>
            val mappingFieldDialog = new components.dialogs.mappingOld.Field(
              providerName = providerName,
              providerClassList = providerClasses,
              providerFieldList = providerFields,
              clientClassList = clientClasses,
              clientFieldList = clientFields,
              isVirtualMode = isVirtualMode,
              mappingClientFieldNames = mappingClientFields,
              mappingProviderFieldNames = mappingProviderFields,
              portalRouter = portalRouter
            )

            Some(div(
              // ui
              mappingFieldDialog.node,

              // update mapping field of class
              mappingFieldDialog.events
                .withCurrentValueOf($mappingClass)
                .filter(x => x._1.nonEmpty && x._3.isDefined)
                .map(x => helpers.extension.Merge.Mapping.fieldToClass(x._3.get, x._1, Some(x._2)))
                .map(Some(_))
                --> onUpdateMappingClass,

              // trigger
              fieldDlgBus.events --> mappingFieldDialog.writer,
            ))
          case _ => None
        },
    )
  }

  private def renderRelationsTable(
                                    $appKey: Signal[common.AppKey],
                                    $defaultConfigurationId: Signal[Long],
                                    $metadata: Signal[Option[MetadataModels.Metadata]],
                                    $metadataClass: Signal[Option[MetadataModels.Class]],

                                    $readOnlyMode: Signal[Boolean] = Signal.fromValue(true),

                                    confirmationDialog: components.dialogs.general.Confirmation,

                                    relatedMappings: ObjectsPage.RelatedMappingsSelector,
                                    $mappingClass: Signal[Option[MappingModels.Class]],
                                    $providerMetadataClass: Signal[Option[MetadataModels.Class]],

                                    onUpdateMetadataClass: Observer[Option[MetadataModels.Class]] = Observer.empty,
                                    onUpdateMappingClass: Observer[Option[MappingModels.Class]] = Observer.empty,
                                    onOpenMetadataClass: Observer[String] = Observer.empty,
                                    documentScrollOps: ScrollOps,
                                    portalRouter: PortalRouter
                                  ): HtmlElement = {

    val $classes: Signal[List[MetadataModels.Class]] = $metadata.map(_.map(_.classes).getOrElse(Nil))
    val $fields: Signal[List[MetadataModels.Field]] = $metadataClass.map(_.flatMap(_.fields).getOrElse(Nil))
    val $relations: Signal[List[MetadataModels.Relation]] = $metadataClass.map(_.flatMap(_.relations).getOrElse(Nil))

    val metadataRelationDialog = new components.dialogs.metadata.Relation($fields, $relations, $classes)
    val mappingRelationDialog = new components.dialogs.mappingOld.Relation()

    //val searchText: Var[String] = Var("")

    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_class_page.relations",

      // init mapping relation dialog of provider metadata name
      relatedMappings.configuration.signal
        .map[String](_
          .flatMap(x => x.providerMetadataName
            .orElse(x.providerMetadata)
            .orElse(x.providerMetadataServiceType.map(_.label)))
          .getOrElse("-"))
        --> mappingRelationDialog.providerName,

      // init mapping relation dialog of the metadata client relations
      $providerMetadataClass
        .map(_.flatMap(_.relations).getOrElse(Nil))
        --> mappingRelationDialog.providerRelations,

      // init mapping relation dialog of the metadata client relations
      $metadataClass
        .map(_.flatMap(_.relations).getOrElse(Nil))
        --> mappingRelationDialog.clientRelations,

      // init mapping relation dialog of the mapping client relations
      $mappingClass
        .map(_.map(_.relationMappings).getOrElse(Nil))
        --> mappingRelationDialog.mappingRelations,

      // update metadata relation of class
      metadataRelationDialog.events
        .withCurrentValueOf($readOnlyMode)
        .withCurrentValueOf($metadataClass)
        .filter(x => !x._3 && x._4.isDefined && x._4.isDefined)
        .map(x => helpers.extension.Merge.Metadata.relationToClass(x._4.get, x._1, Some(x._2)))
        .map(Some(_))
        --> onUpdateMetadataClass,

      // update mapping relation of class
      mappingRelationDialog.events
        .withCurrentValueOf($mappingClass)
        .filter(x => x._1.nonEmpty && x._3.isDefined)
        .map(x => helpers.extension.Merge.Mapping.relationToClass(x._3.get, x._1, Some(x._2)))
        .map(Some(_))
        --> onUpdateMappingClass,

      // dialogs
      metadataRelationDialog.node,
      mappingRelationDialog.node,

      // UI
      child <-- $relations
        .map[String => EventStream[DataMapperApi.PageOf[MetadataModels.Relation]]](fields => (txt: String) =>
          EventStream.fromValue(fields)
            .map(_.filter(f => txt.isEmpty || f.name.toLowerCase.contains(txt)))
            .map(_.sortBy(_.name))
            .map(items => DataMapperApi.PageOf[MetadataModels.Relation](records = items)))
        .map(loadStream => components.TableComponent[MetadataModels.Relation, String](
          searchText = searchText,
          load = (_, _, txt) => loadStream(txt.toLowerCase),
          key = row => row.name,
          render = (_, $row) => {
            val mappingRelation: Signal[Option[MappingModels.Relation]] = $mappingClass
              .map(_.map(_.relationMappings).getOrElse(Nil))
              .combineWith($row.map(_.name))
              .map(x => x._1.find(_.clientRelation == x._2))

            div(
              cls := "table-row",
              div(
                cls := "slds-size--5-of-12 au-truncate",
                child.text <-- $row.map(_.name),
              ),
              child <-- relatedMappings.mapping.signal
                .map(_.isDefined)
                .map {
                  case true => div(
                    cls := "slds-size--7-of-12 slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
                    // mapping relation
                    div(
                      cls := "slds-col slds-grid slds-grid--vertical-align-center au-truncate",
                      child <-- mappingRelation
                        .map(_.map(_.providerRelation))
                        .combineWith($providerMetadataClass.map(_.flatMap(_.relations).getOrElse(Nil).map(_.name)))
                        .map {
                          case (Some(providerRelation), providerRelations) if providerRelation.nonEmpty && !providerRelations.contains(providerRelation) =>
                            span(cls := "red", providerRelation)
                          case (Some(providerRelation), _) if providerRelation.nonEmpty =>
                            span(providerRelation)
                          case _ =>
                            div(
                              cls := "slds-grid slds-grid--vertical-align-center",
                              material.Icon(
                                _ => styleAttr := "transform: rotate(90deg);  color: var(--mat-grey-500);",
                                _ => "merge",
                              ),
                              material.Button(
                                _ => cls := "blue",
                                _.label := "Map",
                                _ => composeEvents(onClick.stopPropagation)(_
                                  .sample(mappingRelation)
                                  .withCurrentValueOf($row.map(_.name))
                                  .map(x => x._1.getOrElse(MappingModels.Relation(clientRelation = x._2))))
                                  --> mappingRelationDialog.writer,
                              ),
                            )
                        },
                    ),
                    // mapping buttons
                    child.maybe <-- relatedMappings.$readOnlyMode
                      .combineWith(mappingRelation)
                      .combineWith($mappingClass)
                      .map {
                        case (false, Some(mRelation), mappingClass) => Some(div(
                          cls := "slds-col slds-grid slds-grid--vertical-align-center slds-text-align--right",
                          material.Icon(
                            _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                            _ => "edit",
                            // _.label := "Edit mapping relation",
                            _ => composeEvents(onClick.stopPropagation)(_
                              .sample(mappingRelation)
                              .withCurrentValueOf($row.map(_.name))
                              .map(x => x._1.getOrElse(MappingModels.Relation(clientRelation = x._2))))
                              --> mappingRelationDialog.writer,
                          ),
                          mappingClass.map(c => material.Icon(
                            _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                            _ => "delete_outline",
                            // _.label := "Delete mapping relation",
                            _ => onClick.stopPropagation
                              .mapTo(confirmationDialog.mkHandle[MappingModels.Relation](
                                title = "Delete mapping relation",
                                message = s"Do you want to remove the ${mRelation.clientRelation} relation?",
                                model = mRelation,
                                onSuccess = onUpdateMappingClass
                                  .contramap((f: MappingModels.Relation) => Some(helpers.extension.Merge.Mapping.relationToClass(c, f.clientRelation, None))),
                                primaryButtonText = "Delete",
                                primaryButtonCls = "red",
                              )) --> confirmationDialog.writer[MappingModels.Relation],
                          )),
                        ))
                        case _ => None
                      }
                  )
                  case _ => div(
                    cls := "slds-size--7-of-12 slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
                    // metadata relation
                    div(
                      cls := "slds-col slds-grid slds-grid--vertical-align-center au-truncate",
                      child.maybe <-- $row
                        .map(_.relatesToClass)
                        .combineWith($classes)
                        .map(x => (x._1, x._2.exists(_.name == x._1)))
                        .map[Option[HtmlElement]] {
                          case (className, true) if className.nonEmpty =>
                            val $href: Signal[Option[String]] = $metadata.signal
                              .map(x => (x.flatMap(_.id), x.flatMap(_.name)))
                              .combineWith($defaultConfigurationId.map(x => Option.when(x > 0)(x)))
                              .combineWith($appKey)
                              .map[Option[portal_router.Page]] {
                                case (Some(mId), _, configurationId, appKey) =>
                                  Some(VirtualAPIsPage_ClassOfCustomMetadata(appKey, mId, className, configurationId = configurationId))
                                case (_, Some(mName), configurationId, appKey) =>
                                  Some(VirtualAPIsPage_ClassOfStandardMetadata(appKey, mName, className, configurationId = configurationId))
                                case _ =>
                                  None
                              }
                              .map(_.map[String](p => portalRouter.link(p)))
                            Some(div(child.maybe <-- $href.map(_.map(l => a(
                              cls := "clickable brown",
                              href := l,
                              className,
                              target := "_blank",
                            )))))
                          case (className, false) if className.nonEmpty => Some(span(
                            cls := "red",
                            className,
                          ))
                          case _ => None
                        },
                      child.maybe <-- $row
                        .map(_.relatesToField)
                        .combineWith($metadataClass.map(_.flatMap(_.fields).getOrElse(Nil)))
                        .map(x => (x._1, x._1.exists(f => x._2.exists(_.name == f))))
                        .map {
                          case (Some(fieldName), false) if fieldName.nonEmpty => Some(small(cls := "badge red slds-m-left--small", fieldName))
                          case (Some(fieldName), true) if fieldName.nonEmpty => Some(small(cls := "badge slds-m-left--small", fieldName))
                          case _ => None
                        },
                    ),
                    // metadata buttons
                    child.maybe <-- $readOnlyMode
                      .map {
                        case false => Some(div(
                          cls := "slds-col slds-grid slds-grid--vertical-align-center slds-text-align--right",
                          material.Icon(
                            _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                            _ => "edit",
                            // _.label := "Edit",
                            _ => composeEvents(onClick.stopPropagation)(_
                              .sample($row))
                              --> metadataRelationDialog.writer,
                          ),
                          child.maybe <-- $row
                            .combineWith($metadataClass)
                            .map {
                              case (row, Some(c)) => Some(material.Icon(
                                _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                                _ => "delete_outline",
                                //_.label := "Delete relation",
                                _ => onClick.stopPropagation
                                  .mapTo(confirmationDialog.mkHandle[MetadataModels.Relation](
                                    title = "Delete metadata relation",
                                    message = s"Do you want to remove the ${row.name} relation?",
                                    model = row,
                                    onSuccess = onUpdateMetadataClass.contramap((r: MetadataModels.Relation) => Some(helpers.extension.Merge.Metadata.relationToClass(c, r.name, None))),
                                    primaryButtonText = "Delete",
                                    primaryButtonCls = "red",
                                  )) --> confirmationDialog.writer[MetadataModels.Relation],
                              ))
                              case _ => None
                            },
                        ))
                        case _ => None
                      },
                  )
                },
            )
          },
          header = div(
            cls := "table-header border-bottom--light",
            children <-- relatedMappings.mapping.signal
              .map(_.isDefined)
              .map {
                case true => Seq(
                  // TODO REVIEW: why do not we use this name?
                  span(
                    cls := "slds-size--5-of-12 gray au-truncate",
                    child.text <-- relatedMappings.configuration.signal.map(c => (
                        c.flatMap(_.clientMetadataId),
                        c.flatMap(_.clientMetadataName),
                        c.flatMap(_.clientMetadata),
                      ))
                      .map {
                        case (Some(clientMetadataId), Some(clientMetadataName), _) if clientMetadataId > 0 =>
                          s"$clientMetadataName relation"
                        case (None, _, Some(clientMetadata)) if clientMetadata.nonEmpty =>
                          s"$clientMetadata relation"
                        case _ =>
                          "Virtual relation"
                      }
                  ),
                  span(
                    cls := "slds-size--7-of-12 gray au-truncate",
                    child.text <-- relatedMappings.configuration.signal
                      .map(c => (
                        c.flatMap(_.providerMetadataId),
                        c.flatMap(_.providerMetadataName),
                        c.flatMap(_.providerMetadata),
                      ))
                      .map[String] {
                        case (Some(providerMetadataId), Some(providerMetadataName), _) if providerMetadataId > 0 =>
                          s"$providerMetadataName relation"
                        case (None, _, Some(providerMetadata)) if providerMetadata.nonEmpty =>
                          s"$providerMetadata relation"
                        case _ =>
                          "Provider relation"
                      },
                  ),
                )
                case _ => Seq(
                  span(cls := "slds-size--5-of-12 gray au-truncate", "Name"),
                  span(cls := "slds-size--7-of-12 gray au-truncate", "Relates to object"),
                )
              },
          ),
          noRows = "No relations",
          documentScrollOps = documentScrollOps
        )),
    )
  }
}
