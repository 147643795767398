package root_pages.aurinko_pages.app.storage

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.{InstantOps, StoreType, TeamMemberRole}
import common.ui.au_storage_view.StorageComponent
import org.scalajs.dom.html
import portal_router.AppStoragePage
import service.apis.portal_api.PortalApi
import service.exception_handler.ForbiddenError
import service.portal_state.{PortalState, TeamMemberAccess}
import wvlet.log.Logger

class AppStoragePageComponent($route: Signal[AppStoragePage],
                              portalApi: PortalApi,
                              portalState: PortalState) {
  private val log = Logger.of[AppStoragePageComponent]
  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)

  private lazy val storageComponent = $route.map(r =>
    StorageComponent(
      appKey = r.appKey,
      instId = r.appKey.appId.toString,
      storeType = StoreType.application,
      portalApi = portalApi,
      $sessionExpiredEvents = portalState.Session.$sessionExpiredEvents,
      teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
    ))

  private val storage = storageComponent.flatMap(_.storage)

  val node: ReactiveHtmlElement[html.Div] = div(
    cls := "content-padding",

    child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen(
      div(
        cls := "left-section",

        div(
          cls := "",
          div(
            cls := "slds-grid slds-grid--align-spread left-section-header",
            p("Storage", cls := "title--level-1"),
          ),
          div(
            cls := "content",
            div(
              cls := "border-top--light border-bottom--light",

              child <-- storage.map(s =>
                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Total size"),
                  span(s.totalSize)
                )
              ),

              child.maybe <-- storage.map(_.createdAt.map(time =>
                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Created at"),
                  span(time.toPrettyLocalFormat)
                ))
              ),

              child.maybe <-- storage.map(_.updatedAt.map(time =>
                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Updated at"),
                  span(time.toPrettyLocalFormat)
                ))
              )


            )
          )
        )
      )),
    child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen(div(
      cls := "right-section slds-m-top--large",
      child <-- storageComponent.map(_.node)
    ))
  )
    .amend(
      teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
        .allowed --> Observer[Boolean](
        if (_) log.info("TeamMemberRole.developer")
        else throw ForbiddenError()
      ))


}
