package root_pages.aurinko_pages.app.virtual_api.components.dialogs

import com.github.uosis.laminar.webcomponents.material
import com.raquo.airstream.core.Observer
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import common.graphic
import common.ServiceType
import common.ui.{AuFormCustomValidator, AuFormState}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.mat_components_styles.fixMwcDialogOverflow
import org.scalajs.dom
import service.apis.portal_api.PortalApi
import wvlet.log.Logger

case object authorization {
  private val log = Logger.of[authorization.type]

  class AccountList(
                     private val appKey: common.AppKey,
                     private val portalApi: PortalApi,
                     private val serviceType: Option[ServiceType],
                     private val onChange: Observer[(Int, String)],
                     private val msDelay: Int = 1000, // MS
                   ) {
    private val model: Var[Option[Option[(Int, String)]]] = Var(None)
    private val mutableModel: Var[(Int, String)] = Var((0, ""))

    private val requestFilter = common.AccountFilters(hasValidToken = Var(Some(true)))
    private val onSearch = new EventBus[String]

    private val accounts: Var[List[(Int, String)]] = Var(Nil)

    private def buttons: HtmlElement = {
      val mutableModelAuFormEl: AuFormCustomValidator = AuFormCustomValidator()
      val state: AuFormState = AuFormState(
        Nil,
        Nil,
        Nil,
        Nil,
        mutableModelAuFormEl :: Nil
      )

      div(
        mutableModel.signal
          .map(_._1)
          .combineWith(model.signal.map(_.flatten.map(_._1).getOrElse(0)))
          .map[(Boolean, Int)](x => (x._1 != x._2, x._1))
          --> Observer.combine(
          mutableModelAuFormEl.dirty.writer.contramap((x: (Boolean, Int)) => x._1),
          mutableModelAuFormEl.valid.writer.contramap((x: (Boolean, Int)) => x._1 && x._2 > 0),
        ),

        cls := "slds-col slds-size--1-of-1 slds-m-top--large",
        div(
          cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center",
          div(cls := "slds-col"),
          div(
            cls := "slds-col",

            ButtonsPairComponent[(Int, String), dom.MouseEvent](
              primaryButtonText = "Apply",
              primaryDisabled = state.dirtySignal.map(!_),
              primaryEffect = () => EventStream.fromValue(mutableModel.now()),
              primaryObserver = Observer.combine(onChange, model.writer.contramap((_: (Int, String)) => None)),
              secondaryObserver = model.writer.contramap((_: dom.MouseEvent) => None),
            ).node
          ),
        ),
      )
    }

    def node: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.dialogs.accounts",

      child.maybe <-- model.signal
        .map(_.isDefined)
        .map {
          case true =>
            log.info(s"accounts dialog open")
            Some(material.Dialog(
              _ => cls := "width--medium",
              _.open := true,
              _.onClosing.mapTo(None) --> model.writer,
              _.heading := "Accounts",
              _.hideActions := true,

              _.slots.default(div(
                requestFilter.searchText.signal
                  .flatMap(_ => portalApi.accounts(
                    serviceType = serviceType,
                    daemon = serviceType.exists(_.isDaemon),
                    appKey = appKey,
                    accountFilters = requestFilter,
                    limit = 5
                  )
                    .map(_.records.map(a => (a.id, a.name.getOrElse[String]("")))))
                  --> accounts.writer,

                onSearch.events.debounce(msDelay) --> requestFilter.searchText.writer,

                material.Textfield(
                  _.outlined := true,
                  _.label := "Search",
                  _.icon := "search",
                  _ => cls := "width-full slds-m-bottom--small",
                  _.value <-- requestFilter.searchText.signal,
                  _ => onInput.mapToValue --> onSearch.writer,
                  _ => onKeyPress.filter(x => x.keyCode == 13).mapToValue --> requestFilter.searchText.writer,
                  _ => onBlur.mapToValue --> requestFilter.searchText.writer,
                ),

                ul(
                  cls := "width-full slds-dropdown__list",
                  listStyle := "none",
                  padding := "0",
                  children <-- accounts.signal
                    .split(_._1)((_, _, $item: Signal[(Int, String)]) => material.List.ListItem(
                      _ => graphic := "icon",
                      _ => cls <-- $item
                        .map(_._1)
                        .combineWith(mutableModel.signal.map(_._1))
                        .map {
                          case (a, m) if a == m => "non-clickable"
                          case _ => ""
                        },
                      _.slots.graphic(material.Icon(_ => child.text <-- $item
                        .map(_._1)
                        .combineWith(mutableModel.signal.map(_._1))
                        .map {
                          case (a, m) if a == m => "radio_button_checked"
                          case _ => "radio_button_unchecked"
                        })),
                      _.slots.default(span(child.text <-- $item.map(_._2))),
                      _ => composeEvents(onClick.stopPropagation)(_.sample($item)) --> mutableModel.writer,
                    )),
                  children <-- accounts.signal
                    .map(_.length)
                    .map {
                      case l if l < 5 =>
                        (1 :: 2 :: 3 :: 4 :: 5 :: 6 :: 7 :: Nil)
                          .take(5 - l)
                          .map {
                            case i if i == 1 && l == 0 => material.List.ListItem(
                              _ => cls := "non-clickable",
                              _ => alignItems := "center",
                              _ => justifyContent := "center",
                              _.slots.default(span("no accounts"))
                            )
                            case _ => material.List.ListItem(_ => cls := "non-clickable")
                          }
                      case _ => Nil
                    },
                ),

                buttons,
              )),

              _ => onMountCallback(fixMwcDialogOverflow)
            ))
          case _ =>
            log.info(s"accounts dialog close")
            None
        },
    )

    def writer: Observer[Option[(Int, String)]] = Observer.combine(
      mutableModel.writer.contramap((x: Option[(Int, String)]) => x.getOrElse((0, ""))),
      model.writer.contramapSome
    )
  }
}
