package portal_router

import com.raquo.laminar.api.L._
import com.raquo.waypoint._
import common.AppKey
import io.circe.generic.auto.{exportDecoder, exportEncoder}
import io.circe.syntax._
import common.CirceStringOps
import root_pages.aurinko_pages.app.settings.SettingsSection
import urldsl.language.Tupler
import urldsl.vocabulary.UrlMatching

import scala.util.Try

class PortalRouter {
  def goto: Observer[portal_router.Page] = gotoSome.contramapSome

  def gotoSome: Observer[Option[portal_router.Page]] = Observer[Option[portal_router.Page]](onNext = p => if (p.isDefined) router.pushState(p.get))

  def link(p: portal_router.Page): String = router.absoluteUrlForPage(p)

  def navigate(p: portal_router.Page): Unit = router.pushState(p)

  val router = new Router[Page](
    routes = Routes.list,
    getPageTitle = _.title, // mock page title (displayed in the browser tab next to favicon)
    serializePage = page => page.asJson.toString, // serialize page data for storage in History API log
    deserializePage = pageStr => pageStr.decodeAs[Page], // deserialize the above
    routeFallback = _ => BufferPage
  )(
    $popStateEvent = windowEvents.onPopState, // this is how Waypoint avoids an explicit dependency on Laminar
    owner = unsafeWindowOwner, // this router will live as long as the window
  )
}

object Routes {
  val loginRoute: Route[LoginPage, Option[String]] = Route.onlyQuery[LoginPage, Option[String]](
    encode = p => p.redirect,
    decode = args => LoginPage(redirect = args),
    pattern = (root / "login" / endOfSegments) ? param[String]("redirect").?,
    basePath = Router.localFragmentBasePath
  )


  val resetPasswordRoute: Route[ResetPasswordPage.type, Unit] = Route.static(
    ResetPasswordPage,
    root / "resetPassword" / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val notVerifiedRoute: Route[NotVerifiedPage.type, Unit] = Route.static(
    NotVerifiedPage,
    root / "not-verified" / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val newPasswordRoute: Route[NewPasswordPage, PatternArgs[String, Int]] = Route.withQuery[NewPasswordPage, String, Int](
    encode = p => UrlMatching(params = p.timestamp, path = p.key),
    decode = args => NewPasswordPage(args.params, args.path),
    pattern = (root / "newPassword" / segment[String] / endOfSegments) ? param[Int]("timestamp"),
    basePath = Router.localFragmentBasePath
  )


  val signupRoute: Route[SignUpPage.type, Unit] = Route.static(
    SignUpPage,
    root / "signup" / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val acceptInvitationRoute: Route[AcceptInvitationPage, PatternArgs[Int, String]] = Route.withQuery[AcceptInvitationPage, Int, String](
    encode = p => UrlMatching(path = p.invId, params = p.invKey),
    decode = args => AcceptInvitationPage(invId = args.path, invKey = args.params),
    pattern = (root / "invitation" / segment[Int] / endOfSegments) ? param[String]("key"),
    basePath = Router.localFragmentBasePath
  )

  val verifyEmailRoute: Route[VerifyEmailPage, PatternArgs[String, Option[Int]]] = Route.withQuery[VerifyEmailPage, String, Option[Int]](
    encode = p => UrlMatching(path = p.key, params = p.timestamp),
    decode = args => VerifyEmailPage(key = args.path, timestamp = args.params),
    pattern = (root / "verifyEmail" / segment[String] / endOfSegments) ? param[Int]("timestamp").?,
    basePath = Router.localFragmentBasePath
  )

  val loadingRoute: Route[BufferPage.type, Unit] = Route.static(
    BufferPage,
    pattern = root / "start" / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val teamAppsRoute: Route[TeamAppsPage, Int] = Route[TeamAppsPage, Int](
    encode = p => p.teamId,
    decode = arg => TeamAppsPage(arg),
    pattern = root / "teams" / segment[Int] / "apps" / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val teamRoute: Route[TeamSettingsPage, Int] = Route[TeamSettingsPage, Int](
    encode = p => p.teamId,
    decode = teamId => TeamSettingsPage(teamId),
    root / "teams" / segment[Int] / "settings" / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val billingRoute: Route[BillingPage, Int] = Route[BillingPage, Int](
    encode = p => p.teamId,
    decode = teamId => BillingPage(teamId),
    pattern = root / "teams" / segment[Int] / "billing" / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val billingInvoiceRoute: Route[BillingInvoicePage, (Int, String)] = Route[BillingInvoicePage, (Int, String)](
    encode = p => (p.teamId, p.invoiceId),
    decode = args => BillingInvoicePage(teamId = args._1, invoiceId = args._2),
    pattern = root / "teams" / segment[Int] / "billing" / "invoice" / segment[String] / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val createAppRoute: Route[CreateAppPage, Option[Int]] = Route.onlyQuery[CreateAppPage, Option[Int]](
    encode = p => p.teamId,
    decode = teamId => CreateAppPage(teamId),
    pattern = (root / "createApp" / endOfSegments) ? param[Int]("team").?,
    basePath = Router.localFragmentBasePath
  )

  val dashboardRoute: Route[DashboardPage, PatternArgs[(Int, Int), Option[Boolean]]] = Route.withQuery[DashboardPage, (Int, Int), Option[Boolean]](
    encode = p => UrlMatching(path = (p.appKey.teamId, p.appKey.appId), params = p.assignUsers),
    decode = arg => DashboardPage(appKey = AppKey(arg.path._1, arg.path._2), arg.params),
    pattern = (root / "teams" / segment[Int] / "apps" / segment[Int] / "dashboard" / endOfSegments) ? param[Boolean]("assignUsers").?,
    basePath = Router.localFragmentBasePath
  )

  import Tupler5._

  val accountsRoute: Route[AccountsPage, PatternArgs[(Int, Int), (Option[String], Option[String], Option[String], Option[String], Option[Int], Option[Int])]] = Route.withQuery[AccountsPage, (Int, Int), (Option[String], Option[String], Option[String], Option[String], Option[Int], Option[Int])](
    encode = p => UrlMatching(path = (p.appKey.teamId, p.appKey.appId), params = (p.filter, p.auth, p.accType, p.search, p.pageNum, p.pageSize)),
    decode = arg => AccountsPage(appKey = AppKey(arg.path._1, arg.path._2), filter = arg.params._1, auth = arg.params._2, accType = arg.params._3, search = arg.params._4, pageNum = arg.params._5, pageSize = arg.params._6),
    pattern = (root / "teams" / segment[Int] / "apps" / segment[Int] / "accounts" / endOfSegments) ?
      ((param[String]("filter").? & param[String]("auth").? & param[String]("type").?) &
        (param[String]("search").? & param[Int]("pageNum").? & param[Int]("pageSize").?)),
    basePath = Router.localFragmentBasePath
  )

  val accountRoute: Route[AccountPage, (Int, Int, Int)] = Route[AccountPage, (Int, Int, Int)](
    encode = p => (p.appKey.teamId, p.appKey.appId, p.accId),
    decode = arg => AccountPage(appKey = AppKey(arg._1, arg._2), accId = arg._3),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "accounts" / segment[Int] / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val settingsRoute: Route[AppSettingsPage, PatternArgs[(Int, Int), Option[String]]] = Route.withQuery[AppSettingsPage, (Int, Int), Option[String]](
    encode = p => UrlMatching(path = (p.appKey.teamId, p.appKey.appId), params = Option.when(p.expand.isDefined) {
      p.expand.get.toString
    }),
    decode = arg => AppSettingsPage(appKey = AppKey(arg.path._1, arg.path._2), expand = Try(SettingsSection(arg.params.getOrElse(""))).fold(_ => None, v => Some(v))),
    pattern = (root / "teams" / segment[Int] / "apps" / segment[Int] / "settings" / endOfSegments) ? (param[String]("expand").?),
    basePath = Router.localFragmentBasePath
  )

  val appStorageRoute: Route[AppStoragePage, PatternArgs[(Int, Int), (Option[String], Option[Int])]] = Route.withQuery[AppStoragePage, (Int, Int), (Option[String], Option[Int])](
    encode = p => UrlMatching(path = (p.appKey.teamId, p.appKey.appId), params = (p.search, p.pageNum)),
    decode = arg => AppStoragePage(appKey = AppKey(arg.path._1, arg.path._2), search = arg.params._1, pageNum = arg.params._2),
    pattern = (root / "teams" / segment[Int] / "apps" / segment[Int] / "storage" / endOfSegments) ? (param[String]("search").? & param[Int]("pageNum").?),
    basePath = Router.localFragmentBasePath
  )

  val syncRoute: Route[SyncOrgsPage, PatternArgs[(Int, Int), (Option[String], Option[Int])]] = Route.withQuery[SyncOrgsPage, (Int, Int), (Option[String], Option[Int])](
    encode = p => UrlMatching(path = (p.appKey.teamId, p.appKey.appId), params = (p.search, p.pageNum)),
    decode = arg => SyncOrgsPage(appKey = AppKey(arg.path._1, arg.path._2), search = arg.params._1, pageNum = arg.params._2),
    pattern = (root / "teams" / segment[Int] / "apps" / segment[Int] / "sync" / endOfSegments) ? (param[String]("search").? & param[Int]("pageNum").?),
    basePath = Router.localFragmentBasePath
  )

  val syncOrgRoute: Route[SyncOrgPage, PatternArgs[(Int, Int, String), (Option[String], Option[Int])]] = Route.withQuery[SyncOrgPage, (Int, Int, String), (Option[String], Option[Int])](
    encode = p => UrlMatching(path = (p.appKey.teamId, p.appKey.appId, p.orgId), params = (p.search, p.pageNum)),
    decode = arg => SyncOrgPage(appKey = AppKey(arg.path._1, arg.path._2), orgId = arg.path._3, search = arg.params._1, pageNum = arg.params._2),
    pattern = (root / "teams" / segment[Int] / "apps" / segment[Int] / "sync" / segment[String] / "users" / endOfSegments) ? (param[String]("search").? & param[Int]("pageNum").?),
    basePath = Router.localFragmentBasePath
  )

  val syncUserRoute: Route[SyncUserPage, (Int, Int, String, Int)] = Route[SyncUserPage, (Int, Int, String, Int)](
    encode = p => (p.appKey.teamId, p.appKey.appId, p.orgId, p.userId),
    decode = arg => SyncUserPage(appKey = AppKey(arg._1, arg._2), orgId = arg._3, userId = arg._4),
    pattern = root / ("teams" / segment[Int] / "apps" / segment[Int]) / ("sync" / segment[String] / "user" / segment[Int] / endOfSegments),
    basePath = Router.localFragmentBasePath
  )

  val addinSetupRoute: Route[AddinSetupPage, (Int, Int)] = Route[AddinSetupPage, (Int, Int)](
    encode = p => (p.appKey.teamId, p.appKey.appId),
    decode = arg => AddinSetupPage(appKey = AppKey(arg._1, arg._2)),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "addin" / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val usersRoute: Route[UsersPage, PatternArgs[(Int, Int), (Option[String], Option[Int])]] = Route.withQuery[UsersPage, (Int, Int), (Option[String], Option[Int])](
    encode = p => UrlMatching(path = (p.appKey.teamId, p.appKey.appId), params = (p.search, p.pageNum)),
    decode = arg => UsersPage(appKey = AppKey(arg.path._1, arg.path._2), search = arg.params._1, pageNum = arg.params._2),
    pattern = (root / "teams" / segment[Int] / "apps" / segment[Int] / "users" / endOfSegments) ? (param[String]("search").? & param[Int]("pageNum").?),
    basePath = Router.localFragmentBasePath
  )

  val userRoute: Route[UserPage, (Int, Int, String)] = Route[UserPage, (Int, Int, String)](
    encode = p => (p.appKey.teamId, p.appKey.appId, p.userId),
    decode = arg => UserPage(appKey = AppKey(arg._1, arg._2), userId = arg._3),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "users" / segment[String] / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val orgsRoute: Route[OrgsPage, PatternArgs[(Int, Int), (Option[String], Option[String], Option[Int])]] = Route.withQuery[OrgsPage, (Int, Int), (Option[String], Option[String], Option[Int])](
    encode = p => UrlMatching(path = (p.appKey.teamId, p.appKey.appId), params = (p.search, p.serviceProvider, p.pageNum)),
    decode = arg => OrgsPage(appKey = AppKey(arg.path._1, arg.path._2), search = arg.params._1, serviceProvider = arg.params._2, pageNum = arg.params._3),
    pattern = (root / "teams" / segment[Int] / "apps" / segment[Int] / "organizations" / endOfSegments) ? ((param[String]("search").? & param[String]("serviceProvider").?) & param[Int]("pageNum").?),

    basePath = Router.localFragmentBasePath
  )

  val orgRoute: Route[OrganizationPage, (Int, Int, Int)] = Route[OrganizationPage, (Int, Int, Int)](
    encode = p => (p.appKey.teamId, p.appKey.appId, p.orgId),
    decode = arg => OrganizationPage(appKey = AppKey(arg._1, arg._2), orgId = arg._3),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "organizations" / segment[Int] / endOfSegments,
    basePath = Router.localFragmentBasePath
  )

  val virtualAPIsItemsOfVirtualMetadataRoute: Route[VirtualAPIsPage_ItemsOfVirtualMetadata, (Int, Int)] = Route[VirtualAPIsPage_ItemsOfVirtualMetadata, (Int, Int)](
    encode = p => (p.appKey.teamId, p.appKey.appId),
    decode = arg => VirtualAPIsPage_ItemsOfVirtualMetadata(appKey = AppKey(arg._1, arg._2)),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "virtual-api" / "v" / "metadata" / "list" / endOfSegments,
    basePath = Router.localFragmentBasePath,
  )
  val virtualAPIsItemsOfProviderMetadataRoute: Route[VirtualAPIsPage_ItemsOfProviderMetadata, (Int, Int)] = Route[VirtualAPIsPage_ItemsOfProviderMetadata, (Int, Int)](
    encode = p => (p.appKey.teamId, p.appKey.appId),
    decode = arg => VirtualAPIsPage_ItemsOfProviderMetadata(appKey = AppKey(arg._1, arg._2)),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "virtual-api" / "p" / "metadata" / "list" / endOfSegments,
    basePath = Router.localFragmentBasePath,
  )
  val virtualAPIsStandardMetadataRoute: Route[VirtualAPIsPage_StandardMetadata, ((Int, Int, String), String)] = Route[VirtualAPIsPage_StandardMetadata, ((Int, Int, String), String)](
    encode = p => ((p.appKey.teamId, p.appKey.appId, s"${p.configurationId.getOrElse("-")}"), p.metadataName),
    decode = arg => VirtualAPIsPage_StandardMetadata(
      appKey = AppKey(arg._1._1, arg._1._2),
      metadataName = arg._2,
      configurationId = arg._1._3.toLongOption,
    ),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "virtual-api" / segment[String] / "standard" / "metadata" / segment[String] / endOfSegments,
    basePath = Router.localFragmentBasePath,
  )
  val virtualAPIsCustomMetadataRoute: Route[VirtualAPIsPage_CustomMetadata, ((Int, Int, String), Long)] = Route[VirtualAPIsPage_CustomMetadata, ((Int, Int, String), Long)](
    encode = p => ((p.appKey.teamId, p.appKey.appId, s"${p.configurationId.getOrElse("-")}"), p.metadataId),
    decode = arg => VirtualAPIsPage_CustomMetadata(
      appKey = AppKey(arg._1._1, arg._1._2),
      metadataId = arg._2,
      configurationId = arg._1._3.toLongOption,
    ),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "virtual-api" / segment[String] / "custom" / "metadata" / segment[Long] / endOfSegments,
    basePath = Router.localFragmentBasePath,
  )
  val virtualAPIsClassOfStandardMetadataRoute: Route[VirtualAPIsPage_ClassOfStandardMetadata, ((Int, Int, String), String, String)] = Route[VirtualAPIsPage_ClassOfStandardMetadata, ((Int, Int, String), String, String)](
    encode = p => ((p.appKey.teamId, p.appKey.appId, s"${p.configurationId.getOrElse("-")}"), p.metadataName, p.className),
    decode = arg => VirtualAPIsPage_ClassOfStandardMetadata(
      appKey = AppKey(arg._1._1, arg._1._2),
      metadataName = arg._2,
      className = arg._3,
      configurationId = arg._1._3.toLongOption,
    ),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "virtual-api" / segment[String] / "standard" / "metadata" / segment[String] / "objects" / segment[String] / endOfSegments,
    basePath = Router.localFragmentBasePath,
  )
  val virtualAPIsClassOfCustomMetadataRoute: Route[VirtualAPIsPage_ClassOfCustomMetadata, ((Int, Int, String), Long, String)] = Route[VirtualAPIsPage_ClassOfCustomMetadata, ((Int, Int, String), Long, String)](
    encode = p => ((p.appKey.teamId, p.appKey.appId, s"${p.configurationId.getOrElse("-")}"), p.metadataId, p.className),
    decode = arg => VirtualAPIsPage_ClassOfCustomMetadata(
      appKey = AppKey(arg._1._1, arg._1._2),
      metadataId = arg._2,
      className = arg._3,
      configurationId = arg._1._3.toLongOption,
    ),
    pattern = root / "teams" / segment[Int] / "apps" / segment[Int] / "virtual-api" / segment[String] / "custom" / "metadata" / segment[Long] / "objects" / segment[String] / endOfSegments,
    basePath = Router.localFragmentBasePath,
  )

  val list = List(
    signupRoute,
    loginRoute,
    resetPasswordRoute,
    acceptInvitationRoute,
    newPasswordRoute,
    notVerifiedRoute,
    verifyEmailRoute,

    loadingRoute,

    teamAppsRoute,
    createAppRoute,

//    profileRoute,

    teamRoute,
    billingRoute,
    billingInvoiceRoute,

    dashboardRoute,
    settingsRoute,
    accountsRoute,
    accountRoute,
    orgRoute,
    addinSetupRoute,
    usersRoute,
    userRoute,
    appStorageRoute,
    syncRoute,
    syncOrgRoute,
    syncUserRoute,
    orgsRoute,

    virtualAPIsItemsOfVirtualMetadataRoute,
    virtualAPIsItemsOfProviderMetadataRoute,
    virtualAPIsCustomMetadataRoute,
    virtualAPIsClassOfCustomMetadataRoute,
    virtualAPIsStandardMetadataRoute,
    virtualAPIsClassOfStandardMetadataRoute
  )
}

object Tupler5 {

  implicit def tupler5And1[A, B, C, D, E, F]: Tupler[(A, B, C), (D, E, F)] {type Out = (A, B, C, D, E, F)} =
    new Tupler[(A, B, C), (D, E, F)] {
      type Out = (A, B, C, D, E, F)

      def apply(abc: (A, B, C), `def`: (D, E, F)): (A, B, C, D, E, F) = (abc._1, abc._2, abc._3, `def`._1, `def`._2, `def`._3)

      def unapply(out: (A, B, C, D, E, F)): ((A, B, C), (D, E, F)) = {
        val (a, b, c, d, e, f) = out
        ((a, b, c), (d, e, f))
      }
    }
}
