package root_pages.aurinko_pages.team.billing

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Dialog, Textfield}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.BillingModels._
import common.{BillingModelsOps, FormModel, PortalUser}
import common.airstream_ops.{AirstreamOptStringOps, EventStreamOps, OptionSignalOps, SignalNestedOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.forms.{AutoSuggestFormOps, FormsLocalExceptionHandling, TextfieldFormOps}
import common.ui.auto_suggest.AutoSuggestComponent
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.icons.{IconColor, IconComponent, IconType, MaterialIcons}
import org.scalajs.dom
import org.scalajs.dom.html
import common.ui.element_binders.DialogModifying
import io.circe.generic.auto.exportEncoder
import io.circe.syntax.EncoderOps
import service.apis.portal_api.PortalApi
import service.exception_handler.UnknownException
import service.portal_state.PortalState
import service.stripe.{Stripe, StripeElementEvent, StripeElementName, StripeUiTools}
import service.stripe.StripeUiTools.StripeElementsOps
import wvlet.log.Logger

import scala.scalajs.js
import scala.scalajs.js.Object.entries

class PaymentMethodComponent(portalApi: PortalApi,
                             portalState: PortalState,
                             $paymentMethod: Signal[Option[PaymentMethod]],
                             onChange: Observer[Option[PaymentMethod]]
                            ) {

  private val log = Logger.of[PaymentMethodComponent]

  private val editModel: Var[Option[PaymentMethodEditModel]] = Var(None)
//  private val paymentMethod: Var[Option[PaymentMethod]] = Var(None)
//  private val $paymentMethod: Signal[Option[PaymentMethod]] = paymentMethod.signal
  private val $countries: Signal[List[CountryInfo]] = portalState.$team
    .flatMap(t => portalApi.Billing.getCountries(t.id))
    .startWith(Nil)

  private def FormDialog(pm: PaymentMethod): Dialog.El = {


    val stripeCardElementState: Var[Option[StripeElementEvent]] = Var(None)

    def validateStripeElement(event: StripeElementEvent): Unit = stripeCardElementState.set(event.some)

    val stripe = new Stripe(pm.stripePk)
    val stripeElements = stripe.elements()
    val cardElement = StripeUiTools.createCardElement(stripeElements)

    cardElement.on("change", validateStripeElement)

    def stripeApiRequestData(model: PaymentMethodEditModel): js.Object = {

      val result = js.Dynamic.literal(
        "type" -> "card",
        "card" -> cardElement,
        "billing_details" -> model.toJsObject
      )

      result
    }

    Dialog(
      _.heading := "Payment method",
      _.open <-- editModel.signal.map(_.nonEmpty),
      _.onClosed.mapTo(None) --> editModel,
      _.onClosed --> Observer[dom.Event](_ => cardElement.clear()),
//      _ => onUnmountCallback(_ => {
//        log.info("DESTROY CARD ELEMENT")
//        cardElement.destroy()
//      }),

      _ => child.maybe <-- editModel.signal.nestedMap(model => {

        stripeCardElementState.set(None)

        div(
          model.country.signal.logINFO(log)(v => v.toString) --> Observer.empty,
          FormsLocalExceptionHandling.errorView(model.formError.signal),

          cls := "slds-grid slds-grid--vertical gap--large",

          div(cls := "slds-size--1-of-1 block-border--grey slds-p-around--medium")
            .addElement(cardElement, StripeElementName.cardNumber),
//          div(
//            cls := "slds-grid",
//
//            div(cls := "slds-size--1-of-2 block-border--grey slds-p-around--medium")
//              .addElement(cardExpElement, StripeElementName.cardExpiry),
//
//            div(cls := "slds-size--1-of-2 block-border--grey slds-p-around--medium")
//              .addElement(cardCvcElement, StripeElementName.cardCvc),
//          ),



          //          Textfield(
          //            _.label := "Card number",
          //            _.outlined := true,
          //            _.required <-- model.cardCvc.signal
          //              .combineWith(model.cardExpDate.signal)
          //              .map { case (cvc, exp) =>
          //                model.cardNumberLast4.isEmpty ||
          //                  exp != model.source
          //                    .flatMap(_.card.map(c => PaymentMethodEditModel.formatExpDate(c.expMonth, c.expYear)))
          //                    .getOrElse("") ||
          //                  cvc != model.source
          //                    .flatMap(_.card.flatMap(_.cvc.map(_.toString)))
          //                    .getOrElse("")
          //              },
          //
          //            _.maxLength := 19,
          //            _ => onMountCallback(setMinLengthOfTextField(19)),
          //            _.pattern := ".{19}",
          //            _ => onMountCallback(c =>
          //              if (model.cardNumberLast4.nonEmpty) styleTextfieldWithPlaceholder(c)
          //            ),
          //
          //            _ => model.cardNumberLast4.map(cl4 => placeholder := s"**** **** **** $cl4"),
          //
          //            _ => {
          //              val matchRegex = "(?<=^.{0,8}).{4}"
          //              val replacementRegex = "$0 "
          //              def toPrettyView(str: String) = str.replaceAll(matchRegex, replacementRegex)
          //
          //              controlled(
          //                value <-- model.cardNumber.signal.map(toPrettyView),
          //                onInput.mapToValue.map{
          //                  case cutted if toPrettyView(model.cardNumber.now).length > cutted.length
          //                    && cutted.nonEmpty
          //                    && cutted.last != toPrettyView(model.cardNumber.now).last
          //                    && !Character.isDigit(toPrettyView(model.cardNumber.now).last) => cutted.filter(Character.isDigit).dropRight(1)
          //                  case str => str.filter(Character.isDigit)
          //                } --> model.cardNumber
          //              )
          //
          //            },
          //
          //            _ => onInput --> Observer[dom.Event](_ => model.formState.validate())
          //
          //          ).bindToForm(model.formState),
          //          div(
          //            cls := "slds-grid",
          //            div(
          //              cls := "slds-size--1-of-2",
          //              Textfield(
          //                _ => cls := "slds-p-right--x-small slds-size--1-of-1 border-box-sizing",
          //                _.label := "Card expiry date",
          //                _.outlined := true,
          //                _.required := true,
          //
          //                _ => placeholder := "MM / YY",
          //                _.maxLength := 7,
          //                _ => onMountCallback(setMinLengthOfTextField(7)),
          //                _.pattern := ".{7}",
          //
          //                _ => {
          //
          //                  def toPrettyView(value: String) = if (value.length >= 2) {
          //                    value.patch(2, " / ", 0)
          //                  } else value
          //
          //                  controlled(
          //
          //                    value <-- model.cardExpDate.signal.map(toPrettyView),
          //
          //                    onInput.mapToValue.map {
          //                      case cutted if toPrettyView(model.cardExpDate.now).length > cutted.length
          //                        && cutted.nonEmpty
          //                        && cutted.last != toPrettyView(model.cardExpDate.now).last
          //                        && !Character.isDigit(toPrettyView(model.cardExpDate.now).last) => cutted.filter(Character.isDigit).dropRight(1)
          //                      case str => str.filter(Character.isDigit)
          //
          //                    } --> model.cardExpDate
          //                  )
          //                },
          //                _ => onInput --> Observer[dom.Event](_ => model.formState.validate())
          //
          //              ).bindToForm(model.formState, ((value: String) => {
          //                val str = value.filter(Character.isDigit)
          //
          //                if (str.length == 4) {
          //                  val today = new Date()
          //                  val currentYear = today.getFullYear() % 100
          //                  val currentMonth = today.getMonth() + 1
          //
          //                  val (month, year) = str.splitAt(2)
          //
          //                  if ( month.toInt > 12 ||
          //                    year.toInt < currentYear && currentYear - year.toInt <= 90 ||
          //                    year.toInt == currentYear && month.toInt <= currentMonth) {
          //                    false
          //                  } else true
          //                } else true
          //              }).some)
          //            ),
          //
          //            div(
          //              cls := "slds-size--1-of-2",
          //              Textfield(
          //                _ => cls := "slds-p-left--x-small slds-size--1-of-1 border-box-sizing",
          //
          //                _.label := "CVC",
          //                _.outlined := true,
          //                _.required <-- model.cardNumber.signal
          //                  .combineWith(model.cardExpDate.signal)
          //                  .map { case (card, exp) =>
          //                    model.cardNumberLast4.isEmpty ||
          //                      exp != model.source.card.map(c => PaymentMethodEditModel.formatExpDate(c.expMonth, c.expYear))
          //                        .getOrElse("") ||
          //                      card != model.source.card.flatMap(_.number)
          //                        .getOrElse("")
          //                  },
          //
          //                _.maxLength := 3,
          //                _ => onMountCallback(c => {
          //                  setMinLengthOfTextField(3)(c)
          //                }),
          //                _.pattern := ".{3}",
          //
          //                _ => onMountCallback(c =>
          //                  if (model.cardNumberLast4.nonEmpty) styleTextfieldWithPlaceholder(c)
          //                ),
          //
          //                _ => model.cardNumberLast4.map(_ => placeholder := s"***"),
          //
          //                _ => controlled(
          //                  value <-- model.cardCvc,
          //                  onInput.mapToValue.map(_.filter(Character.isDigit)) --> model.cardCvc
          //                ),
          //                _ => onInput --> Observer[dom.Event](_ => model.formState.validate())
          //              ).bindToForm(model.formState)
          //            ),
          //          ),

          Textfield(
            _.outlined := true,
            _.label := "Name on card",

            _.value <-- model.name,
            _ => onInput.mapToValue --> model.name
          ).bindToForm(model.formState),

          new AutoSuggestComponent(
            $value = model.country.signal,
            onChange = model.country.writer,
            $availableValues = $countries,
            label = "Country",
            itemsToShowWithEmptySearch = $countries
          )
            .bindToForm(model.formState)
            .node
            .amend(cls := "slds-size--1-of-1 negative-m-bottom--medium"),

          Textfield(
            _.outlined := true,
            _.label := "Address line 1",

            _.value <-- model.line1,
            _ => onInput.mapToValue --> model.line1
          ).bindToForm(model.formState),

          Textfield(
            _.outlined := true,
            _.label := "Address line 2",

            _.value <-- model.line2,
            _ => onInput.mapToValue --> model.line2
          ).bindToForm(model.formState),
          div(
            cls := "slds-grid",
            div(
              cls := "slds-size--1-of-2",
              Textfield(
                _ => cls := "slds-m-right--small",
                _.outlined := true,
                _.label := "City",

                _.value <-- model.city,
                _ => onInput.mapToValue --> model.city
              ).bindToForm(model.formState)
            ),

            div(
              cls := "slds-size--1-of-2",
              Textfield(
                _ => cls := "slds-m-left--small",
                _.outlined := true,
                _.label := "ZIP / Postal code",

                _.value <-- model.postal_code,
                _ => onInput.mapToValue --> model.postal_code
              ).bindToForm(model.formState))
          ),

          Textfield(
            _.outlined := true,
            _.label := "State",

            _.value <-- model.state,
            _ => onInput.mapToValue --> model.state
          ).bindToForm(model.formState),



//          Textfield(
//            _.outlined := true,
//            _.label := "Phone",
//
//            _.value <-- model.phone,
//            _ => onInput.mapToValue --> model.phone
//          ).bindToForm(model.formState),

          Textfield(
            _.outlined := true,
            _.label := "Billing email",
            _.`type` := "email",

            _.value <-- model.email,
            _ => onInput.mapToValue --> model.email
          ).bindToForm(model.formState),


//          Textfield(
//            _.outlined := true,
//            _.label := "Discount coupon ID",
//
//            _.value <-- model.couponId,
//            _ => onInput.mapToValue --> model.couponId
//          ).bindToForm(model.formState)

        )
      }),

      _.slots.primaryAction(

        ButtonsPairComponent[(PaymentMethod, PortalUser), dom.MouseEvent](

          primaryDisabled = editModel.signal.flatMap(_.$traverse(_.formState.$valid)).map(!_.contains(true))
            .combineWith(stripeCardElementState.signal.nestedMap(_.complete))
            .map{
              case (formInvalid, stripeComplete) =>
                log.info(s"formInvalid $formInvalid stripeComplete $stripeComplete")

                formInvalid || !stripeComplete.contains(true)
            },

          primaryEffect = () => editModel.signal
            .stream
            .collect { case Some(m) => m}
            .withCurrentValueOf(portalState.$team.map(_.id))
            .flatMap { case (model, tid) => for {
              id <- EventStream.fromJsPromise(
                    stripe.createPaymentMethod(stripeApiRequestData(model))

              ).map {
                  case resp if resp.paymentMethod.isDefined => resp.paymentMethod.get.id
                  case resp => resp.error.toOption match {
                    case Some(err) => throw new Exception(s"Stripe error: ${err.`type`} ${err.code}/n${err.message} ")
                    case _ => throw UnknownException("Payment creation failed.")
                  }
                }

              pm <-
                portalApi.Billing.updatePaymentMethod(tid, PaymentMethodUpdate(id))
              me <- portalApi.getMe
            } yield pm -> me
            }
            .withErrorHandlingAndCollect(FormsLocalExceptionHandling
              .handler(str => editModel.now.foreach(_.formError.set(str.some)))),


          primaryObserver = Observer.combine[(PaymentMethod, PortalUser)](
            Observer[(PaymentMethod, PortalUser)](p => { portalState.updateMe(p._2)}),
            editModel.writer.contramap(_ => None),
            onChange.contramap(p => p._1.some)
          ),

          secondaryObserver = editModel.writer.contramap[dom.MouseEvent]((_: dom.MouseEvent) => None)
          //
        ).node
      )
    )
      .withPingOnOpen(portalApi)
      .withFormCaching(
        portalState.FormCache,
        portalState.Session.$sessionExpiredEvents,
        editModel,
        portalState.$me.map { me => PaymentMethodEditModel(pm, me.name, me.email) },
        (m: PaymentMethodEditModel) => editModel.now.foreach(_.updateFromModel(m)),
        (cachedForm: FormModel) => cachedForm match {
          case v: PaymentMethodEditModel => v.some
          case _ => None
        }
      )
  }

  private def PaymentInfo(billingCard: BillingCard, billingDetails: Option[BillingDetails]) = div(
    cls := "slds-grid",

    p(
      cls := "slds-size--1-of-4 au-truncate slds-p-right--small border-box-sizing",
      span(
        cls := "light",
        "card: "
      ),
      span(
        "**** **** **** ",
        billingCard.last4
      )
    ),

    billingDetails.flatMap(_.address).map(address => p(
      cls := "slds-size--2-of-4 au-truncate slds-p-right--small border-box-sizing",
      span(
        cls := "light",
        "address: "
      ),
      span(
        child.text <-- $countries.map(BillingModelsOps.addressStringFromPaymentInfo(address, _))
      )
    )

    ),

//    billingDetails.flatMap(_.phone).map(phone => p(
//      cls := "slds-size--1-of-4 au-truncate",
//      span(
//        cls := "light",
//        "phone: "
//      ),
//      phone
//    ))

  )

  private val eventBinders = List(
    portalState.$team.map(_.id)
      .flatMap(tid => portalApi.Billing.getPaymentMethod(tid))
      .map(_.some) --> onChange
  )

  def apply(): ReactiveHtmlElement[html.Div] = div(
    cls := "block-border--grey height-full slds-p-right--large border-box-sizing",
    div(
      cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-grow",
        img(
          src <-- $paymentMethod.nestedMap(_.card).map {
            case Some(_) => "assets/credit_card.svg"
            case _ => "assets/credit_card_add.svg"
          }
        ),
        div(
          cls := "slds-grow",
          p(
            cls := "title--level-3",
            child.text <-- $paymentMethod.subflatMap(_.card).map {
              case Some(_) => "Your credit card"
              case _ => "Payment method"
            }),

          p(
            cls := "slds-m-top--x-small",
            child <-- $paymentMethod.subflatMap(_.card)
              .combineWith($paymentMethod.subflatMap(_.billingDetails))
              .map {
                case (Some(card), details) => PaymentInfo(card, details)
                case _ => span("You have not added any cards yet.")
              }
          )

        ),


      ),
      child.maybe <-- $paymentMethod
        .subflatMap(_.card)
        .withCurrentValueOf(portalState.$team.map(_.billingInfo.map(_.paymentEnabled)))
        .map {

        case (Some(_), _) => IconComponent(
          icon = MaterialIcons.edit,
          color = IconColor.`lighter-brown`,
          iconType = IconType.outlined
        )
          .amend(cls := "clickable")
          .some

        case (None, Some(true)) => Button(
          _ => cls := "secondary",
          _.raised := true,
          _.label := "Add new card",
          _.disabled <-- portalState.$me.map(!_.verified),
          _ => title <-- portalState.$me.mapOptWhenTrue(!_.verified)("Please verify your email.").getOrElse("")
        ).some

        case _ => None

      }.nestedMap(_.amend(
        composeEvents(onClick)(_
          .sample(portalState.$me)
          .withCurrentValueOf($paymentMethod)
          .collect { case (me, Some(pm)) => PaymentMethodEditModel(pm, me.name, me.email).some }) --> editModel
      )),

    ),
    child.maybe <-- $paymentMethod.nestedMap(FormDialog)
  )
    .amend(
      eventBinders
    )
}

case class PaymentMethodEditModel(cardNumber: Var[String] = Var(""),
                                  cardExpDate: Var[String] = Var(""),
                                  cardCvc: Var[String] = Var(""),
                                  cardNumberLast4: Option[String] = None,
                                  city: Var[String] = Var(""),
                                  country: Var[Option[String]] = Var(None),
                                  line1: Var[String] = Var(""),
                                  line2: Var[String] = Var(""),
                                  postal_code: Var[String] = Var(""),
                                  state: Var[String] = Var(""),
                                  email: Var[String] = Var(""),
                                  name: Var[String] = Var(""),
//                                  phone: Var[String] = Var(""),
//                                  couponId: Var[String] = Var(""),
                                  source: PaymentMethod
                                 ) extends FormModel {

  private val log = Logger.of[PaymentMethodEditModel]



  def toJsObject: js.Object =  {
    val ctr = country.now.getOrElse("")
    js.Dynamic.literal(
      "address" -> js.Dynamic.literal(
        "city" -> city.now,
        "country" -> ctr,
        "line1" -> line1.now,
        "line2" -> line2.now,
        "postal_code" -> postal_code.now,
        "state" ->  state.now
      ),
      "email" -> email.now,
      "name" -> name.now,
//      "phone" -> phone.now
    )
  }

  def toApiModel: PaymentMethod = PaymentMethod(

    card = Option.when(cardNumber.now.trim.nonEmpty) {
      BillingCard(
        cardNumber.now.some,
        cardExpDate.now.substring(0, 2).toInt,
        cardExpDate.now.substring(2).toInt,
        cardCvc.now.toInt.some
      )
    },

    billingDetails = BillingDetails(
      address = BillingAddress(
        country = country.now,
        city = city.now.some,
        postal_code = postal_code.now.some,
        line1 = line1.now.some,
        line2 = line2.now.some,
        state = state.now.some
      ).some,
//      phone = phone.now.some,
      email = email.now.some,
      name = name.now.some
    ).some,

//    couponId = couponId.now.some,
    stripePk = source.stripePk
  )

  def updateFromModel(model: PaymentMethodEditModel): Unit = {

    cardNumber.set(model.cardNumber.now)
    cardExpDate.set(model.cardExpDate.now)
    cardCvc.set(model.cardCvc.now)
    city.set(model.city.now)
    country.set(model.country.now)
    line1.set(model.line1.now)
    line2.set(model.line2.now)
    postal_code.set(model.postal_code.now)
    state.set(model.state.now)
    email.set(model.email.now)
    name.set(model.name.now)
//    couponId.set(model.couponId.now)
  }

}

object PaymentMethodEditModel {
  def formatExpDate(month: Int, year: Int): String = month.toString + year.toString.takeRight(2)

  def apply(paymentMethod: PaymentMethod,
            prefillName: String,
            prefillEmail: String): PaymentMethodEditModel =

    new PaymentMethodEditModel(
      cardNumber = paymentMethod.card.flatMap(_.number).toStringVar,
      cardExpDate = paymentMethod.card.map(cd => formatExpDate(cd.expMonth, cd.expYear)).toStringVar,
      cardCvc = paymentMethod.card.flatMap(_.cvc.map(_.toString)).toStringVar,
      cardNumberLast4 = paymentMethod.card.flatMap(_.last4),
      city = paymentMethod.billingDetails.flatMap(_.address.flatMap(_.city)).toStringVar,
      country = Var(paymentMethod.billingDetails.flatMap(_.address.flatMap(_.country))),
      line1 = paymentMethod.billingDetails.flatMap(_.address.flatMap(_.line1)).toStringVar,
      line2 = paymentMethod.billingDetails.flatMap(_.address.flatMap(_.line2)).toStringVar,
      postal_code = paymentMethod.billingDetails.flatMap(_.address.flatMap(_.postal_code)).toStringVar,
      state = paymentMethod.billingDetails.flatMap(_.address.flatMap(_.state)).toStringVar,
      email = paymentMethod.billingDetails.flatMap(_.email)
        .orElse( Option.when(paymentMethod.card.flatMap(_.last4).isEmpty) { prefillEmail} )
        .toStringVar,
      name = paymentMethod.billingDetails.flatMap(_.name)
        .orElse( Option.when(paymentMethod.card.flatMap(_.last4).isEmpty) { prefillName } )
        .toStringVar,
//      phone = paymentMethod.billingDetails.flatMap(_.phone).toStringVar,
//      couponId = paymentMethod.couponId.toStringVar,
      source = paymentMethod
    )


}
