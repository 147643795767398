package common

package object value_opps {
  implicit final class ValueOpps[T](private val value: T) extends AnyVal {
    def condition[A](c: T => Boolean, t: T => A, f: T => A): A = if (c(value)) t(value) else f(value)

//    def some: Option[T] = Option[T](value)
  }

  implicit final class OptionOpps[T](private val value: Option[T]) extends AnyVal {
    //def condition[A](c: T => Boolean, t: A, f: A): A = if (value.exists(c(_))) t else f

    def getOrFail(exception: Exception): T = value.getOrElse(throw exception)
  }

  implicit final class SeqOpps[T](private val values: Seq[Iterable[T]]) extends AnyVal {
    def expand: Seq[T] = values.foldLeft(Seq.empty[T])((l, e) => l ++ e)
  }

  implicit final class ListOpps[T](private val values: List[Iterable[T]]) extends AnyVal {
    def expand: List[T] = values.foldLeft(List.empty[T])((l, e) => l ++ e)
  }
}
