package root_pages.aurinko_pages.app

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Dialog.El
import com.github.uosis.laminar.webcomponents.material.{Button, Dialog, IconButton, Switch}
import com.raquo.airstream.core.Observer
import com.raquo.laminar.api.L._
import com.raquo.waypoint.SplitRender
import common.PortalAppCapability._
import common.airstream_ops.{EventStreamNestedOps, ValueToObservableOps}
import common.ui.icons.{IconButtonComponent, IconColor, IconComponent, IconSize, IconType, MaterialIcons}
import common.ui.notifications.InfoTextComponent
import common.ui.user_app_permissions.UserAppPermissionsView
import portal_router.{AccountPage, AccountsPage, AppPage, AppSettingsPage, AppStoragePage, AurinkoPage, DashboardPage, OrganizationPage, OrgsPage, PortalRouter, SyncOrgPages, SyncOrgsPage, UserPage, UsersPage, VirtualAPIsPage, VirtualAPIsPage_ItemsOfVirtualMetadata}
import common.{PortalApplication, TeamMemberRole, graphic}
import org.scalajs.dom
import service.portal_state.{PortalState, PortalUserAccess, TeamMemberAccess}
import service.apis.portal_api.PortalApi
import service.scroll_ops.ScrollOps
import wvlet.log.Logger

import java.time.Instant
import java.time.temporal.{ChronoUnit, Temporal}


class AppRootComponent($route: Signal[AppPage],
                       portalApi: PortalApi,
                       portalState: PortalState,
                       childrenRender: Signal[AppPage] => SplitRender[AurinkoPage, HtmlElement],
                       documentScrollOps: ScrollOps,
                       portalRouter: PortalRouter
                      ) {
  private val log = Logger.of[AppRootComponent]
  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)
  private val portalUserAccess = new PortalUserAccess(portalState.$me)

  private val appTrialStatus: Signal[Long] = portalState.$app.map(app => ChronoUnit.DAYS.between(app.createdAt.asInstanceOf[Temporal], Instant.now))

  private var initialTrial: Option[Boolean] = None

  private lazy val UsersAccess = teamMemberAccess.minRoleCheck(TeamMemberRole.admin)
    .sAndThen(
      $route.map {

        case DashboardPage(appKey, Some(true)) => new UsersAccessComponent(
          open = true,
          portalApi = portalApi,
          portalState = portalState,
          portalRouter.goto.contramap[Unit](_ => DashboardPage(appKey))
        )

        case _ => new UsersAccessComponent(open = false, portalApi = portalApi, portalState = portalState)

      }

    )

  private val eventBinders = List(
    $route.map(p => p.appKey)
      .flatMap(appKey => for {
        app <- portalApi.application(appKey)
        tr = app.trial
        teamId = appKey.teamId
      } yield (teamId, app, tr)) --> Observer[(Int, PortalApplication, Boolean)](
      onNext = t => {
        portalState.updateApp(t._2.some)
        portalState.updateTeamId(t._1.some)
        portalState.resetAppRegs()
        initialTrial = t._3.some
      }),

  )


  val node: Div = div(
    eventBinders,

    cls := "slds-size--1-of-1",

    child.maybe <-- portalState.$maybeApp.map(_.nonEmpty)
      .combineWith(portalState.$maybeTeam.map(_.nonEmpty))
      .map { case (true, true) =>
        div(
          cls := "app-wrapper",

          child.maybe <-- UsersAccess.nestedMap(_.dialog),

          div(
            cls := "header-container",
            div(
              cls := "app-menu",
              cls <-- documentScrollOps.$scrolled.map { case true => "shadow" case false => "" },
              div(
                cls := "slds-grid slds-grid--vertical-align-center negative-m-left--medium ",
                a(
                  cls := "menu-item primary",
                  cls <-- $route.map { case _: DashboardPage => "active" case _ => "" },
                  span("Dashboard"),
                  href <-- $route.map(p => portalRouter.router.absoluteUrlForPage(DashboardPage(p.appKey)))
                ),
                a(
                  cls := "menu-item primary",
                  cls <-- $route.map { case _: AccountsPage | _: AccountPage => "active" case _ => "" },
                  graphic := "icon",
                  span("Accounts"),
                  href <-- $route.map(p => portalRouter.router.absoluteUrlForPage(AccountsPage(p.appKey)))
                ),
                child.maybe <-- portalState.$app
                  .map(_.capabilities.exists(_.contains(endUserSessions)))
                  .map {
                    case true => Some(a(
                      cls <-- $route.map { case _: UsersPage | _: UserPage => "active" case _ => "" },
                      cls := "menu-item primary",
                      span("App users"),
                      href <-- $route.map(p => portalRouter.router.absoluteUrlForPage(UsersPage(p.appKey)))
                    ))
                    case false => None
                  },
                child.maybe <-- portalState.$app
                  .map(_.capabilities.exists(_.contains(endUserSessions)))
                  .map {
                    case true => Some(a(
                      cls <-- $route.map { case _: OrgsPage | _: OrganizationPage => "active" case _ => "" },
                      cls := "menu-item primary",
                      span("Organizations"),
                      href <-- $route.map(p => portalRouter.router.absoluteUrlForPage(OrgsPage(p.appKey)))
                    ))
                    case false => None
                  },
                child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen(
                  a(
                    cls := "menu-item primary",
                    cls <-- $route.map { case _: AppStoragePage => "active" case _ => "" },
                    graphic := "icon",
                    span("Storage"),
                    href <-- $route.map(p => portalRouter.router.absoluteUrlForPage(AppStoragePage(p.appKey)))
                  )
                ),
                child.maybe <-- portalState.$app.map(_.syncAvailable).map {
                  case true => Some(a(
                    cls <-- $route.map { case _: SyncOrgsPage | _: SyncOrgPages => "active" case _ => "" },
                    cls := "menu-item primary",
                    span("Sync"),
                    href <-- $route.map(p => portalRouter.router.absoluteUrlForPage(SyncOrgsPage(p.appKey)))
                  ))
                  case false => None
                },
                child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
                  .sAndThen(portalState.$app
                    .map(_.capabilities.exists(_.intersect(Set(apiCrm, emailMarketing)).nonEmpty))
                    .map {
                      case true => a(
                        cls <-- $route.map { case _: VirtualAPIsPage => "active" case _ => "" },
                        cls := "menu-item primary",
                        span("Virtual APIs"),
                        href <-- $route.map(p => portalRouter.router.absoluteUrlForPage(VirtualAPIsPage_ItemsOfVirtualMetadata(p.appKey)))
                      ).some
                      case false => None
                    }).map(_.flatten),
                child.maybe <--
                  teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
                    .and(portalUserAccess.verifiedCheck)
                    .andThen(
                      a(
                        cls <-- $route.map { case _: AppSettingsPage => "active" case _ => "" },
                        cls := "menu-item primary",
                        span("Settings"),
                        href <-- $route.map(p => portalRouter.router.absoluteUrlForPage(AppSettingsPage(p.appKey)))
                      ))
              ),
              div(

                cls := "slds-grid slds-grid--vertical-align-center gap--large slds-is-relative",
                child.maybe <-- portalState.$team.map(_.role.isAdmin)
                  .combineWith(portalState.$app.map(_.trial))
                  .combineWith(appTrialStatus)
                  .map {
                    case (true, trial, status) if trial || status < 30 || initialTrial.contains(true) =>
                      Some(div(
                        cls := "slds-grid slds-grid--vertical-align-center",
                        marginRight := "-1rem",
                        child.maybe <-- appTrialStatus
                          .combineWith(portalState.$app.map(_.trial)).map {
                            case (x, true) if x > 14 => Some(div(
                              cls := "slds-grid  slds-grid--vertical-align-center section-warning slds-m-right--medium",
                              small("Your app is not ready for production!"),
                              InfoTextComponent.standard(
                                "During the trial period, you can test your integration without entering your Google and Microsoft app registration details. However, you will need to specify them in Settings before going into production."
                                  .signaled
                              ).amend(cls := "slds-m-left--x-small moved-right--xx-small")
                            ))
                            case _ => None
                          },
                        Switch(
                          _.checked <-- portalState.$app.map(_.trial),
                          _.disabled := (if (!trial && initialTrial.contains(true) && status > 30) true else false),
                          _ => composeEvents(onChange.mapToChecked)(_
                            .withCurrentValueOf(portalState.$app)
                            .withCurrentValueOf($route.map(_.appKey))
                            .flatMap { case (trial, app, appKey) => portalApi.updateApiApplication(appKey, app.copy(trial = trial)) }
                            .map(Some(_))) --> Observer[Option[PortalApplication]](onNext = portalState.updateApp)
                        ),
                        p(
                          cls := "slds-m-left--medium",
                          cls <-- portalState.$app.map(_.trial).map { case false => "light" case true => "" },
                          "Trial mode"
                        ),
                        child.maybe <-- appTrialStatus
                          .combineWith(portalState.$app.map(_.trial))
                          .map {
                            case (x, b) if (x <= 14 && b) || !b => Some(InfoTextComponent.standard(
                              "In trial period you can test Aurinko without entering secret keys of your provider apps. In this case, our default keys will be used for authorization."
                                .signaled
                            ).amend(cls := "slds-m-left--x-small moved-right--xx-small"))
                            case _ => None
                          },
                      ))

                    case _ => None
                  },

                child.maybe <-- UsersAccess.nestedMap(_.icon.amend(cls := "slds-m-left--large negative-m-right--medium")),

              ),


            )
          ),
          child.maybe <-- portalState.$app
            .combineWith($route)
            .map { case (app, route) =>
              app.id == route.appKey.appId
            }
            .map {
              case true =>
                Some(div(
                  cls := "nested-page-wrapper",
                  div(
                    cls := "content-wrapper",
                    child <-- childrenRender($route).$view
                  )
                ))
              case _ =>
                portalState.resetAppRegs()
                None
            }
        ).some
      case _ => None

      },
  )



}

class UsersAccessComponent(open: Boolean,
                           portalApi: PortalApi,
                           portalState: PortalState,
                           onClosed: Observer[Unit] = Observer.empty,
                          ) {
  private val showPopup = Var(open)

  val icon: IconButton.El = IconButtonComponent.simple(
    icon = MaterialIcons.teams,
    color = IconColor.orange,
    iconType = IconType.outlined,
    size = IconSize.medium,
    clickObserver = showPopup.writer.contramap[dom.MouseEvent](_ => true)
  )

  val dialog: El = Dialog(
      _.open <-- showPopup.signal,
      _.onClosed.mapTo(false) --> showPopup,
      _.onClosed.mapTo(()) --> onClosed,
      _.slots.default(div(
        child.maybe <--
          showPopup.signal.map(Option.when(_) {
            UserAppPermissionsView(
              portalApi,
              portalState.$team.map(_.id),
              portalState.$app.map(_.id).some,
              noUsersToAssignHandler =
                if (open) showPopup.writer.contramap[Unit](_ => false)
                else Observer.empty
            )})
      )),
      _.slots.primaryAction(
        Button(
          _.label := "Close",
          _.raised := true,
          _ => cls := "secondary",
          _ => onClick.mapTo(false) --> showPopup
        )
      )
    )

}
