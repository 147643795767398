package root_pages.aurinko_pages.app.virtual_api.helpers

import com.raquo.laminar.api.L._
import portal_router.{PortalRouter, VirtualAPIsPage_ClassOfCustomMetadata, VirtualAPIsPage_ClassOfStandardMetadata}
import service.apis.dynamic_api.DataMapperModels.MetadataModels

case object builder {
  case object metadata {
    def makeFieldType(f: MetadataModels.Field): String =
      Option
        .when(f.structure == MetadataModels.FieldStructure.array)(s"Array of ${f.`type`.toString}")
        .getOrElse(f.`type`.toString)

    def makeFieldTypeEl(
                         f: Option[MetadataModels.Field],
                         embeddedClassNames: List[String],

                         $metadata: Signal[Option[MetadataModels.Metadata]] = Signal.fromValue(None),
                         $defaultConfigurationId: Signal[Option[Long]] = Signal.fromValue(None),
                         $appKey: Signal[Option[common.AppKey]] = Signal.fromValue(None),
                         portalRouter: PortalRouter
                       ): Option[HtmlElement] = f
      // error
      .map(field => field.`type` match {
        case MetadataModels.DataType.EmbeddedVal(className) =>
          (field.`type`.label, field.structure.name, className, className.exists(embeddedClassNames.contains))
        case _ =>
          (field.`type`.label, field.structure.name, None, false)
      })
      .map[Option[(String, Option[String], Boolean)]] {
        case (label, s, embeddedClassName, embeddedClassExist) if s == MetadataModels.FieldStructure.array.name =>
          Some((s"Array of $label", embeddedClassName, embeddedClassExist))
        case (typeLabel, _, embeddedClassName, embeddedClassExist) =>
          Some((typeLabel, embeddedClassName, embeddedClassExist))
        case _ =>
          None
      }
      .flatMap {
        case Some((label, Some(embeddedClassName), true)) =>
          val $href: Signal[Option[String]] = $metadata.signal
            .map(x => (x.flatMap(_.id), x.flatMap(_.name)))
            .combineWith($defaultConfigurationId.map(_.flatMap[Long] {
              case cid if cid > 0 => Some(cid)
              case _ => None
            }))
            .combineWith($appKey)
            .map[Option[portal_router.Page]] {
              case (Some(mId), _, configurationId, Some(appKey)) =>
                Some(VirtualAPIsPage_ClassOfCustomMetadata(appKey, mId, embeddedClassName, configurationId = configurationId))
              case (_, Some(mName), configurationId, Some(appKey)) =>
                Some(VirtualAPIsPage_ClassOfStandardMetadata(appKey, mName, embeddedClassName, configurationId = configurationId))
              case _ =>
                None
            }
            .map(_.map[String](p => portalRouter.link(p)))
          Some(span(
            label,
            "(",
            child <-- $href
              .map {
                case Some(link) => a(embeddedClassName, href := link, target := "_blank")
                case _ => span(embeddedClassName)
              },
            ")",
          ))
        case Some((label, Some(embeddedClassName), false)) =>
          Some(span(label, "(", span(cls := "red", embeddedClassName), ")"))
        case Some((label, _, _)) =>
          Some(span(label))
        case _ =>
          None
      }
  }
}
