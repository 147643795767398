package service.scroll_ops

import com.raquo.airstream.core.{Observer, Signal}
import com.raquo.airstream.state.Var
import org.scalajs.dom

class ScrollOps(element: dom.Element) {
  private val scrolled = Var(false)
  private val scroll: Var[Double] = Var(0)

  def $scrolled: Signal[Boolean] = scroll.signal.map(_ > 10)
  def $scrollTop: Signal[Double] = scroll.signal

  val detectScroll: Observer[Double] = Observer[Double](scroll.set)

  def scrollToTop(): Unit = element.scrollIntoView(true)
}
