package root_pages.aurinko_pages.team.billing.invoice

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Icon
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.BillingModels.{BillingInvoice, CountryInfo, PaymentMethod}
import common.{BillingModelsOps, InstantOps, PortalTeam}
import common.airstream_ops.{SignalNestedOps, SignalOptionOps, ValueToObservableOps}
import common.ui.breadcrumbs.{BreadcrumbsComponent, BreadcrumbsItem}
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.icons.MaterialIcons
import org.scalajs.dom.html
import portal_router.{BillingInvoicePage, BillingPage, PortalRouter}
import service.apis.portal_api.PortalApi
import service.exception_handler.UnknownException
import service.portal_state.PortalState

class BillingInvoicePageComponent($route: Signal[BillingInvoicePage],
                                  portalApi: PortalApi,
                                  portalRouter: PortalRouter,
                                  portalState: PortalState
                                 ) {
  private val $teamId: Signal[Int] = $route.map(_.teamId)
  private val $invoiceId: Signal[String] = $route.map(_.invoiceId)

  private val $paymentInfo: Signal[Option[PaymentMethod]] = $teamId.flatMap(portalApi.Billing.getPaymentMethod)
    .map(_.some)
    .startWith(None)

  private val $invoiceInfo: Signal[Option[BillingInvoice]] = $route
    .flatMap(r => portalApi.Billing.getInvoice(r.teamId, r.invoiceId))
    .map(_.some)
    .startWith(None)

  private val $countries: Signal[List[CountryInfo]] = portalState.$team
    .flatMap(t => portalApi.Billing.getCountries(t.id))
    .startWith(Nil)

  private def BilledToInfo = div(
    div(
    cls := "slds-grid slds-grid--align-spread",
    p(
      cls := "grey",
      "Billed to"),

    p(
      cls := "grey",
      "Invoice #"
    )
  ),
    div(
      cls := "slds-grid slds-grid--align-spread",

      div(
        cls := "slds-m-top--small",
        p(
          cls := "title--level-4",
          child.text <-- $paymentInfo.subflatMap(_.billingDetails.flatMap(_.name)).getOrElse("")
        ),
        p(
          cls := "slds-m-top--xx-small",
        child.maybe <-- $paymentInfo
          .combineWith($countries)
          .map{
            case (Some(pi), countries) => pi.billingDetails.flatMap(_.address)
              .map(addr => span(BillingModelsOps.addressStringFromPaymentInfo(addr, countries)))
            case _ => None
          }
//        .subflatMap(_.billingDetails.flatMap(_.address)
//          .map(addr => span(BillingModelsOps.addressStringFromPaymentInfo(addr))))
        )
      ),

      p(
        cls := "slds-grid slds-grid--vertical-align-center",
        p(
          cls := "title--level-1 max-content-height",
          child.text <-- $invoiceInfo.subflatMap(i => i.number.orElse(i.id)).getOrElse("")
        ),
        child.maybe <-- $invoiceInfo.subflatMap(_.hostedUrl).nestedMap(url =>
          a(
            cls := "slds-m-left--medium",
            Icon(_ => MaterialIcons.link, _ => cls := "blue small"),
            href := url,
            target := "blank"
          )
        )


      )
  ))

  def ApplicationsDetails($invoice: Signal[Option[BillingInvoice]]) = div(
    cls := "data-table",
    p(
      cls := "grey border-bottom--light slds-p-bottom--small",
      "Applications details"
    ),
    children <-- $invoice.nestedMap(invoice => invoice.details.map { appInfo =>

      ExpansionPanelComponent(
        header = div(
          cls := "slds-grid growing-block",
          b(
            cls := "slds-size--1-of-3",
            child.text <-- portalState.$team.map(_.apps
              .flatMap(_.find(_.id == appInfo.appId)
                .map(_.name))
            ).getOrElse(throw UnknownException(s"app not found: ${appInfo.appId}")
            )),

          p(
            cls := "slds-size--1-of-3",
            s"${appInfo.units} account${Option.when(appInfo.units != 1) {"s"}.getOrElse("")}"
          ),

          p(
            cls := "flex-right-block slds-p-right--small",
            BillingModelsOps.labelForCurrency(invoice.currency),
            appInfo.amountD,
          )
        ),
        body = div(
          cls := "data-table slds-p-around--large",
          appInfo.tiers.map(tier =>
          div(
            cls := "table-row border-bottom--light slds-p-bottom--small",
            p(
              cls := "slds-size--1-of-3",
              tier.tierName.getOrElse("Default").toString //todo: ???
            ),
            p(
              cls := "slds-size--1-of-3",
              s"${tier.units} account${Option.when(tier.units != 1) {"s"}.getOrElse("")}"
            ),

            p(
              cls := "flex-right-block",
              BillingModelsOps.labelForCurrency(invoice.currency),
              tier.amountD,
            )

          )
          )
        ).some
      ).node

    })

      .getOrElse(Nil),

    p(
      cls := "slds-text-align--right slds-m-top--medium title--level-3",
      span(cls := "light", "Total due: "),
      span(
        cls := "text-bolder",
        child.text <-- $invoiceInfo
          .nestedMap(invoice =>
            BillingModelsOps.labelForCurrency(invoice.currency) + invoice.amount).getOrElse(""),


      )
    )
  )

  val node: ReactiveHtmlElement[html.Div] = div(
    cls := "x-nested-page",

    div(
      cls := "nav-filters-container",
      BreadcrumbsComponent(
        BreadcrumbsItem(
          "Billing".signaled,
          $teamId.map(id => portalRouter.link(BillingPage(id))).some),

        BreadcrumbsItem(
          $invoiceInfo.subflatMap(_.number)
            .$getOrElse($invoiceId)
            .map(id => s"Invoice $id"),
          None)

      )
    ),

    div(
      cls := "content-padding",


    div(
      cls := "slds-grid slds-grid--align-spread slds-m-top--large",
      p(
      cls := "title--level-1",
      "Invoice"
    ),
      p(
        cls := "secondary",
        child.text <-- $invoiceInfo
          .nestedMap(BillingModelsOps.invoicePeriodString)
          .getOrElse(""),

      )
    ),

    BilledToInfo.amend(cls := "slds-m-top--xx-large"),
    ApplicationsDetails($invoiceInfo).amend(cls := "slds-m-top--xx-large")


  )
  )
}
