package common.ui

import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Dialog, Fab, IconButton, Textarea, Textfield}
import com.raquo.laminar.api.L._
import org.scalajs.dom
import org.scalajs.dom.{Attr, document, html}
import wvlet.log.Logger

import scala.annotation.tailrec
import scala.scalajs.js

package object mat_components_styles {

  implicit final class MountContextOpps(private val context: MountContext[HtmlElement]) {

    private val log = Logger.of[MountContextOpps]

    def findElementInShadowRoot(cssSelector: String, node: dom.html.Element = context.thisNode.ref, withDelay: Boolean): EventStream[Option[dom.html.Element]] = {
      val done: Var[Boolean] = Var(false)
      val elemBus = new EventBus[Option[dom.html.Element]]

      done.signal
        .flatMap {
          case false =>
            EventStream.periodic(
              intervalMs = 300,
              resetOnStop = true,
              emitInitial = !withDelay,
            )
              .mapTo(node.asInstanceOf[js.Dynamic].shadowRoot.querySelector(cssSelector))
          case _ =>
            EventStream.empty
        }
        .foreach {
          case elem if elem != null =>
            done.set(true)
            elemBus.emit(Some(elem.asInstanceOf[dom.html.Element]))
          case _ =>
            log.info("elem is null or not found")
        }(context.owner)

      //stop if element not found in 3 seconds
      EventStream.fromValue(())
        .delay(3000)
        .filter(_ => !done.now)
        .foreach { _ =>
          log.warn(s"elem $cssSelector not found in shadow root")
          done.set(true)
          elemBus.emit(None)
        }(context.owner)

      elemBus.events
    }

    def findElementInShadowRootEx(cssSelectorPath: Iterable[String], node: dom.html.Element = context.thisNode.ref, withDelay: Boolean): EventStream[Option[dom.html.Element]] =
      EventStream.fromValue(cssSelectorPath)
        .flatMap {
          case head :: tail => // find element
            findElementInShadowRoot(cssSelector = head, node = node, withDelay = withDelay).map((_, tail))
          case Nil => // dummy value
            EventStream.fromValue((None, Nil))
        }
        .flatMap {
          case (Some(child), Nil) => // recursion done
            EventStream.fromValue(Some(child))
          case (Some(child), path) => // recursive loop
            findElementInShadowRootEx(path, child, withDelay = withDelay)
          case _ => // recursion is broken
            EventStream.fromValue(Option.empty[dom.html.Element])
        }

    def addStylesToShadowElement(cssSelector: String, pairs: List[(String, String)], withDelay: Boolean = false): Unit = {

      findElementInShadowRoot(cssSelector, withDelay = withDelay).foreach {
        case Some(elem) => {
          pairs.foreach(p => elem.style.setProperty(p._1, p._2))
        }
        case None => ()
      }(context.owner)
    }

    def addStyleToShadowElement(cssSelector: String, property: String, value: String, withDelay: Boolean = false): Unit = {

      findElementInShadowRoot(cssSelector, withDelay = withDelay).foreach {
        case Some(elem) => {
          elem.style.setProperty(property, value)
        }
        case None => ()
      }(context.owner)
    }

    def addStyleToShadowPseudoElement(cssSelector: String, pseudoElemName: String, property: String, value: String, withDelay: Boolean = false): Unit = {
      findElementInShadowRoot(cssSelector, withDelay = withDelay).foreach {
        case Some(elem) => {
          val styleText = s"$pseudoElemName {$property: $value;}"
          val styleElem = document.createElement("style")
          styleElem.innerHTML = styleText
          //log.info(s"appended style elem $styleText to $cssSelector")
          elem.appendChild(styleElem)
        }
        case None => ()
      }(context.owner)
    }

    def addClassToShadowElement(cssSelector: String, className: String, withDelay: Boolean = false): Unit = {
      findElementInShadowRoot(cssSelector, withDelay = withDelay).foreach {
        case Some(elem) => {
          elem.classList.add(className)
        }
        case None => ()
      }(context.owner)
    }

    def addAttrToShadowElement(cssSelector: String, attrName: String, attrValue: String, withDelay: Boolean = false): Unit = {
      findElementInShadowRoot(cssSelector, withDelay = withDelay).foreach {
        case Some(elem) => {
          elem.setAttribute(attrName, attrValue)
        }
        case None => ()
      }(context.owner)
    }

    def addClickListenerToShadowElement(cssSelector: String, callback: dom.MouseEvent => Unit, withDelay: Boolean = false): Unit = {
      findElementInShadowRoot(cssSelector, withDelay = withDelay).foreach {
        case Some(elem) => elem.onclick = callback
        case None => ()
      }(context.owner)
    }

    def addClickListenerToNestedShadowElement(cssSelector: String, cssSelector2: String, callback: dom.MouseEvent => Unit, withDelay: Boolean = false): Unit = {
      findNestedShadowElement(cssSelector, cssSelector2, withDelay = withDelay).foreach {
        case Some(elem) => {
          elem.onclick = callback
        }
        case None => ()
      }(context.owner)
    }

    def findNestedShadowElement(cssSelector1: String, cssSelector2: String, withDelay: Boolean): EventStream[Option[html.Element]] = {
      val elemBus = new EventBus[Option[dom.html.Element]]

      findElementInShadowRoot(cssSelector1, withDelay = withDelay).foreach {
        case Some(elem) => findElementInShadowRoot(cssSelector2, elem, withDelay = withDelay).foreach {
          case Some(nestedEl) =>
            //            log.info("EMIT NESTED ELEM")
            elemBus.emit(Some(nestedEl))
          case None => ()
        }(context.owner)
        case None => ()
      }(context.owner)

      elemBus.events
    }

    def addStyleToNestedShadowElement(cssSelector: String, cssSelector2: String, property: String, value: String, withDelay: Boolean = false): Unit = {

      findNestedShadowElement(cssSelector, cssSelector2, withDelay = withDelay).foreach {
        case Some(elem) => elem.style.setProperty(property, value)
        case None => ()
      }(context.owner)
    }

    def addAttrToNestedShadowElement(cssSelector: String, cssSelector2: String, attr: String, value: String, withDelay: Boolean = false): Unit = {

      findNestedShadowElement(cssSelector, cssSelector2, withDelay = withDelay).foreach {
        case Some(elem) => elem.setAttribute(attr, value)
        case None => ()
      }(context.owner)
    }

  }

  def fixMwcDialogOverflow(ctx: MountContext[Dialog.El]): Unit = {
    fixMwcDialogOverflowEx(ctx, "visible")
  }

  def fixMwcDialogOverflowEx(ctx: MountContext[Dialog.El], overflow: String = "visible"): Unit = {
    ctx.addStyleToShadowElement(".mdc-dialog__surface", "overflow", overflow, withDelay = true)
    ctx.addStyleToShadowElement(".mdc-dialog__content", "overflow", overflow, withDelay = true)
  }

  def makeIconButtonOutlined(ctx: MountContext[IconButton.El]): Unit = {
    ctx.addStyleToShadowElement(".material-icon", "font-family", "Material Icons Outlined")
  }

  def styleTextfieldWithPlaceholder(ctx: MountContext[Textfield.El]): Unit = {
    ctx.addStylesToShadowElement(
      ".mdc-floating-label",
      ("transform" -> "translateY(-225%) scale(1)") ::
        ("background-color" -> "white") ::
        ("font-size" -> "0.75rem") ::
        ("padding" -> "0 .3rem") ::
        ("margin-right" -> "-.3rem") ::
        ("max-width" -> "fit-content") ::
        Nil,
      withDelay = true)
    ctx.addStyleToShadowPseudoElement(".mdc-text-field__input", "::placeholder", "opacity", "1 !important", withDelay = true)
  }

  def styleTextareaWithPlaceholder(ctx: MountContext[Textarea.El]): Unit = {
    ctx.addStylesToShadowElement(
      ".mdc-floating-label",
      ("transform" -> "translateY(-225%) scale(1)") ::
        ("background-color" -> "white") ::
        ("font-size" -> "0.75rem") ::
        ("padding" -> "0 .3rem") ::
        ("margin-right" -> "-.3rem") ::
        Nil,
      withDelay = true)
    ctx.addStyleToShadowPseudoElement(".mdc-text-field__input", "::placeholder", "opacity", "1 !important", withDelay = true)
  }

  def fixFabStyle(ctx: MountContext[Fab.El]): Unit = {

    ctx.addStyleToShadowElement(".mdc-fab", "height", "2rem", withDelay = true)
    ctx.addStyleToShadowElement(".mdc-fab", "box-shadow", "none", withDelay = true)
  }

  def setInputAutoCompleteAndId(autoComplete: String, idAttr: String)(ctx: MountContext[Textfield.El]): Unit = {
    ctx.addAttrToShadowElement(".mdc-text-field__input", "autocomplete", autoComplete)
    ctx.addAttrToShadowElement(".mdc-text-field__input", "id", idAttr)
    ctx.addStyleToShadowPseudoElement(".mdc-text-field", "input:-webkit-autofill,\ninput:-webkit-autofill:hover, \ninput:-webkit-autofill:focus,\ntextarea:-webkit-autofill,\ntextarea:-webkit-autofill:hover,\ntextarea:-webkit-autofill:focus,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus", "-webkit-box-shadow:", "-webkit-box-shadow: 0 0 0px 1000px #000 inset;\n  transition: background-color 5000s ease-in-out 0s", withDelay = true)
  }

  def setMinLengthOfTextField(minLength: Double)(ctx: MountContext[Textfield.El]): Unit = {
    ctx.addAttrToShadowElement(".mdc-text-field__input", "minlength", minLength.toString)
  }

  def setStyles(cssSelectors: Iterable[String], styles: Iterable[(String, String)])(ctx: MountContext[HtmlElement]): Unit = {
    ctx.findElementInShadowRootEx(cssSelectors, withDelay = true).foreach {
      case Some(elem) =>
        styles.foreach(property => elem.style.setProperty(property._1, property._2))
      case None => ()
    }(ctx.owner)
  }

  def fixLineItemMeta(ctx: MountContext[ListItem.El], styles: Iterable[(String, String)]): Unit = {
    ctx.findElementInShadowRoot(".mdc-list-item__meta", withDelay = true).foreach {
      case Some(elem) =>
        styles.foreach(property => elem.style.setProperty(property._1, property._2))
      case None => ()
    }(ctx.owner)
  }
}
