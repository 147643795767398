package service.apis.dynamic_api

import com.raquo.laminar.api.L._
import common.{AppKey, CirceStringOps}
import io.circe.generic.auto.{exportDecoder, exportEncoder}
import io.circe.syntax.EncoderOps
import org.scalajs.dom
import DataMapperModels._
import service.http.ApiRequester
import service.ui.spinner.Spinner
import wvlet.log.Logger

case object DataMapperApi {
  case class API(apiUrl: String, spinnnerManager: Spinner) {
    private val apiRequester: ApiRequester =
      ApiRequester(apiUrl, validateResponse, spinnnerManager)
    private val log = Logger.of[DataMapperApi.API.type]

    private def validateResponse(resp: dom.XMLHttpRequest): String =
      resp.status match {
        case c if c >= 200 && c < 300 => resp.responseText
        case 401 =>
          log.info(s"${resp.status}: datamapper-srv not authorized")
          throw service.exception_handler.NotAuthorizedException(
            username = Option(resp.getResponseHeader("X-Aurinko-UsernameHint"))
          )
        case _ =>
          log.warn(s"${resp.status}: ${resp.responseText}")
          val err = resp.responseText.safelyDecodeAs[Error]
          if (err.isDefined && err.get.message.getOrElse("").nonEmpty)
            throw ApiException(
              status = resp.status,
              message = s"${err.get.message.get}",
              code = err.get.code.getOrElse("")
            )
          else
            throw ApiException(
              status = resp.status,
              message = s"Exception ${resp.status}",
              code = ""
            )
      }

    def metadata(appKey: AppKey): DataMapperMetadataApi =
      DataMapperMetadataApi(apiRequester, appKey)

    def configuration(appKey: AppKey): DataMapperConfigurationApi =
      DataMapperConfigurationApi(apiRequester, appKey)

    def description(appKey: AppKey): DataMapperDescriptionApi =
      DataMapperDescriptionApi(apiRequester, appKey)

    def mapping(appKey: AppKey): DataMapperMappingApi =
      DataMapperMappingApi(apiRequester, appKey)
  }

  case class DataMapperConfigurationApi(
      apiRequester: ApiRequester,
      appKey: AppKey
  ) extends IDataMapper[ConfigurationModels.Configuration]("configurations") {
    def items(
        search: Option[String] = None,
        clientMetadataId: Option[Long] = None,
        clientMetadata: Option[String] = None,
        providerMetadataId: Option[Long] = None,
        providerMetadata: Option[String] = None,
        pageOffset: Int = 0,
        pageLimit: Int = standardApiPageSize
    ): EventStream[PageOf[ConfigurationModels.Configuration]] =
      apiRequester
        .get(
          path = mkPath(),
          queryParams = mkQueryPagination(pageLimit, pageOffset).++(
            Map[String, Option[String]](
              "search" -> search.flatMap {
                case txt if txt.nonEmpty => Some(txt)
                case _                   => None
              },
              "clientMetadataId" -> clientMetadataId.map(_.toString),
              "clientMetadata" -> clientMetadata,
              "providerMetadataId" -> providerMetadataId.map(_.toString),
              "providerMetadata" -> providerMetadata
            )
          )
        )
        .map(_.decodeAs[PageOf[ConfigurationModels.Configuration]])

    def item(id: Long): EventStream[ConfigurationModels.Configuration] =
      apiRequester
        .get(path = mkPath(Some(id)))
        .map(_.decodeAs[ConfigurationModels.Configuration])

    def create(
        data: ConfigurationModels.Configuration
    ): EventStream[ConfigurationModels.Configuration] =
      apiRequester
        .post(path = mkPath(), body = Some(data.asJson.deepDropNullValues))
        .map(_.decodeAs[ConfigurationModels.Configuration])

    def update(
        id: Long,
        data: ConfigurationModels.Configuration
    ): EventStream[ConfigurationModels.Configuration] =
      apiRequester
        .patch(
          path = mkPath(Some(id)),
          body = Some(data.asJson.deepDropNullValues)
        )
        .map(_.decodeAs[ConfigurationModels.Configuration])

    def delete(id: Long): EventStream[Boolean] =
      apiRequester
        .delete(path = mkPath(Some(id)))
        .map(_ => true)

    def clone(id: Long): EventStream[ConfigurationModels.Configuration] =
      apiRequester
        .post(path = mkPath(id = Some(id), suffix = "/clone"))
        .map(_.decodeAs[ConfigurationModels.Configuration])
  }

  case class DataMapperMetadataApi(apiRequester: ApiRequester, appKey: AppKey)
      extends IDataMapper[MetadataModels.Metadata]("metadata") {
    def items(
        search: Option[String] = None,
        `type`: Option[MetadataModels.MetadataType.MetadataType] = None,
        serviceType: Option[common.ServiceType] = None,
        pageOffset: Int = 0,
        pageLimit: Int = standardApiPageSize
    ): EventStream[PageOf[MetadataModels.Metadata]] =
      apiRequester
        .get(
          path = mkPath(),
          queryParams = mkQueryPagination(pageLimit, pageOffset).++(
            Map[String, Option[String]](
              "search" -> search.flatMap {
                case txt if txt.nonEmpty => Some(txt)
                case _                   => None
              },
              "serviceTypeSet" -> `type`.flatMap {
                case MetadataModels.MetadataType.provider => Some("true")
                case MetadataModels.MetadataType.client   => Some("false")
                case _                                    => None
              },
              "serviceType" -> serviceType.map(_.value)
            )
          )
        )
        .map(_.decodeAs[PageOf[MetadataModels.Metadata]])

    def item(id: Long): EventStream[MetadataModels.Metadata] =
      apiRequester
        .get(path = mkPath(Some(id)))
        .map(x => {
          val model = x.decodeAs[MetadataModels.Metadata]
          MetadataModels.Metadata.CheckFieldNames(x.decodeAsRawData.get) match {
            case Some(wrongFields) if wrongFields.length > 1 =>
              throw NotImplementedFields[MetadataModels.Metadata](
                wrongFields,
                s"${wrongFields.mkString(", ")} fields not implemented",
                model
              )
            case Some(wrongFields) if wrongFields.length == 1 =>
              throw NotImplementedFields[MetadataModels.Metadata](
                wrongFields,
                s"${wrongFields.head} field not implemented",
                model
              )
            case Some(wrongFields) if wrongFields.isEmpty =>
              throw new Exception("something went wrong on json validation")
            case _ => model
          }
        })

    def create(
        data: MetadataModels.Metadata
    ): EventStream[MetadataModels.Metadata] =
      apiRequester
        .post(path = mkPath(), body = Some(data.asJson.deepDropNullValues))
        .map(_.decodeAs[MetadataModels.Metadata])

    def update(
        id: Long,
        data: MetadataModels.Metadata
    ): EventStream[MetadataModels.Metadata] =
      apiRequester
        .patch(
          path = mkPath(Some(id)),
          body = Some(data.asJson.deepDropNullValues)
        )
        .map(_.decodeAs[MetadataModels.Metadata])

    def delete(id: Long): EventStream[Boolean] =
      apiRequester
        .delete(path = mkPath(Some(id)))
        .map(_ => true)

    def clone(id: Long): EventStream[MetadataModels.Metadata] =
      apiRequester
        .post(path = mkPath(id = Some(id), suffix = "/clone"))
        .map(_.decodeAs[MetadataModels.Metadata])

    val standard: Standard = Standard()

    case class Standard() {
      def items(
          search: Option[String] = None,
          `type`: Option[MetadataModels.MetadataType.MetadataType] = None,
          serviceType: Option[common.ServiceType] = None,
          pageOffset: Int = 0,
          pageLimit: Int = standardApiPageSize
      ): EventStream[PageOf[MetadataModels.Standard]] =
        apiRequester
          .get(
            path = mkPath(suffix = "/standard"),
            queryParams = mkQueryPagination(pageLimit, pageOffset).++(
              Map[String, Option[String]](
                "search" -> search.flatMap {
                  case txt if txt.nonEmpty => Some(txt)
                  case _                   => None
                },
                "serviceTypeSet" -> `type`.flatMap {
                  case MetadataModels.MetadataType.provider => Some("true")
                  case MetadataModels.MetadataType.client   => Some("false")
                  case _                                    => None
                },
                "serviceType" -> serviceType.map(_.value)
              )
            )
          )
          .map(_.decodeAs[PageOf[MetadataModels.Standard]])

      def item(
          name: String,
          accountId: Option[Int] = None
      ): EventStream[MetadataModels.Standard] =
        apiRequester
          .get(
            path = mkPath(suffix = s"/standard/$name"),
            queryParams =
              if (accountId.isDefined)
                Map("accountId" -> Some(s"${accountId.get}"))
              else
                Map()
          )
          .map(_.decodeAs[MetadataModels.Standard])

      def `class`(
          name: String,
          className: String,
          accountId: Option[Int] = None
      ): EventStream[MetadataModels.Class] =
        apiRequester
          .get(
            path = mkPath(suffix = s"/standard/$name/classes/$className"),
            queryParams =
              if (accountId.isDefined)
                Map("accountId" -> Some(s"${accountId.get}"))
              else
                Map()
          )
          .map(_.decodeAs[MetadataModels.Class])

      def schemas(
          name: String,
          schemaName: String,
          accountId: Option[Int] = None
      ): EventStream[MetadataModels.Schema] =
        apiRequester
          .get(
            path = mkPath(suffix = s"/standard/$name/schemas/$schemaName"),
            queryParams =
              if (accountId.isDefined)
                Map("accountId" -> Some(s"${accountId.get}"))
              else
                Map()
          )
          .map(_.decodeAs[MetadataModels.Schema])

      def search(name: String): EventStream[Option[MetadataModels.Standard]] =
        name match {
          case n if n.nonEmpty =>
            apiRequester
              .get(
                path = mkPath(suffix = "/standard"),
                queryParams = mkQueryPagination(0, standardApiPageSize).++(
                  Map[String, Option[String]](
                    "search" -> Some(name)
                  )
                )
              )
              .map(_.decodeAs[PageOf[MetadataModels.Standard]])
              .map(_.records.filter(_.name == name))
              .map(_.headOption)
          case _ =>
            EventStream.fromValue(None)
        }
    }
  }

  case class DataMapperDescriptionApi(
      apiRequester: ApiRequester,
      appKey: AppKey
  ) extends IDataMapper[DescriptionModels.Description]("descriptions") {
    def items(
        search: Option[String] = None,
        serviceType: Option[common.ServiceType] = None,
        pageOffset: Int = 0,
        pageLimit: Int = standardApiPageSize
    ): EventStream[PageOf[DescriptionModels.Description]] =
      apiRequester
        .get(
          path = mkPath(),
          queryParams = mkQueryPagination(pageLimit, pageOffset).++(
            Map[String, Option[String]](
              "search" -> search.flatMap {
                case txt if txt.nonEmpty => Some(txt)
                case _                   => None
              },
              "serviceType" -> serviceType.map(_.value)
            )
          )
        )
        .map(_.decodeAs[PageOf[DescriptionModels.Description]])

    def item(id: Long): EventStream[DescriptionModels.Description] =
      apiRequester
        .get(path = mkPath(Some(id)))
        .map(_.decodeAs[DescriptionModels.Description])

    def create(
        data: DescriptionModels.Description
    ): EventStream[DescriptionModels.Description] =
      apiRequester
        .post(path = mkPath(), body = Some(data.asJson.deepDropNullValues))
        .map(_.decodeAs[DescriptionModels.Description])

    def update(
        id: Long,
        data: DescriptionModels.Description
    ): EventStream[DescriptionModels.Description] =
      apiRequester
        .patch(
          path = mkPath(Some(id)),
          body = Some(data.asJson.deepDropNullValues)
        )
        .map(_.decodeAs[DescriptionModels.Description])

    def delete(id: Long): EventStream[Boolean] =
      apiRequester
        .delete(path = mkPath(Some(id)))
        .map(_ => true)

    def clone(id: Long): EventStream[DescriptionModels.Description] =
      apiRequester
        .post(path = mkPath(id = Some(id), suffix = "/clone"))
        .map(_.decodeAs[DescriptionModels.Description])

    val standard: Standard = Standard()

    case class Standard() {
      def items(
          search: Option[String] = None,
          serviceType: Option[common.ServiceType] = None,
          pageOffset: Int = 0,
          pageLimit: Int = standardApiPageSize
      ): EventStream[PageOf[DescriptionModels.Standard]] =
        apiRequester
          .get(
            path = mkPath(suffix = "/standard"),
            queryParams = mkQueryPagination(pageLimit, pageOffset).++(
              Map[String, Option[String]](
                "search" -> search.flatMap {
                  case txt if txt.nonEmpty => Some(txt)
                  case _                   => None
                },
                "serviceType" -> serviceType.map(_.value)
              )
            )
          )
          .map(_.decodeAs[PageOf[DescriptionModels.Standard]])
    }
  }

  case class DataMapperMappingApi(apiRequester: ApiRequester, appKey: AppKey)
      extends IDataMapper[MappingModels.Mapping]("mappings") {
    def items(
        search: Option[String] = None,
        pageOffset: Int = 0,
        pageLimit: Int = standardApiPageSize
    ): EventStream[PageOf[MappingModels.Mapping]] =
      apiRequester
        .get(
          path = mkPath(),
          queryParams = mkQueryPagination(pageLimit, pageOffset).++(
            Map[String, Option[String]](
              "search" -> search.flatMap {
                case txt if txt.nonEmpty => Some(txt)
                case _                   => None
              }
            )
          )
        )
        .map(_.decodeAs[PageOf[MappingModels.Mapping]])

    def item(id: Long): EventStream[MappingModels.Mapping] =
      apiRequester
        .get(path = mkPath(Some(id)))
        .map(x => {
          val model = x.decodeAs[MappingModels.Mapping]
          MappingModels.Mapping.CheckFieldNames(x.decodeAsRawData.get) match {
            case Some(wrongFields) if wrongFields.length > 1 =>
              throw NotImplementedFields[MappingModels.Mapping](
                wrongFields,
                s"${wrongFields.mkString(", ")} fields not implemented",
                model
              )
            case Some(wrongFields) if wrongFields.length == 1 =>
              throw NotImplementedFields[MappingModels.Mapping](
                wrongFields,
                s"${wrongFields.head} field not implemented",
                model
              )
            case Some(wrongFields) if wrongFields.isEmpty =>
              throw new Exception("something went wrong on json validation")
            case _ => model
          }
        })

    def create(
        data: MappingModels.Mapping
    ): EventStream[MappingModels.Mapping] =
      apiRequester
        .post(path = mkPath(), body = Some(data.asJson.deepDropNullValues))
        .map(_.decodeAs[MappingModels.Mapping])

    def update(
        id: Long,
        data: MappingModels.Mapping
    ): EventStream[MappingModels.Mapping] =
      apiRequester
        .patch(
          path = mkPath(Some(id)),
          body = Some(data.asJson.deepDropNullValues)
        )
        .map(_.decodeAs[MappingModels.Mapping])

    def delete(id: Long): EventStream[Boolean] =
      apiRequester
        .delete(path = mkPath(Some(id)))
        .map(_ => true)

    def clone(id: Long): EventStream[MappingModels.Mapping] =
      apiRequester
        .post(path = mkPath(id = Some(id), suffix = "/clone"))
        .map(_.decodeAs[MappingModels.Mapping])
  }

  case class PageOf[T](
      records: List[T],
      totalSize: Int = 0,
      offset: Int = 0,
      done: Boolean = true
  )

  protected abstract class IDataMapper[T](pathSuffix: String) {
    def appKey: AppKey

    def item(id: Long): EventStream[T]

    protected def mkPath(id: Option[Long] = None, suffix: String = ""): String =
      if (id.isDefined)
        s"/teams/${appKey.teamId}/apps/${appKey.appId}/dynamic_api/$pathSuffix/${id.get}$suffix"
      else
        s"/teams/${appKey.teamId}/apps/${appKey.appId}/dynamic_api/$pathSuffix$suffix"

    protected def mkQueryPagination(
        limit: Int,
        offset: Int
    ): Map[String, Option[String]] = Map[String, Option[String]](
      "limit" -> Some(s"$limit"),
      "offset" -> Some(s"$offset")
    )
  }

  case class ApiException(status: Int, message: String, code: String)
      extends Throwable(message) {
    override def toString = s"$message"
  }

  case class NotImplementedFields[T](
      wrongFields: Seq[String],
      message: String,
      `object`: T
  ) extends Throwable(message) {
    override def toString = s"$message"
  }

  private case class Error(
      code: Option[String],
      message: Option[String],
      requestId: Option[String]
  )

  val standardApiPageSize: Int = 30

  def apply(apiUrl: String, spinnerManager: Spinner): DataMapperApi.API =
    API(apiUrl, spinnerManager)
}
