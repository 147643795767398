package common

import common.value_opps.{OptionOpps, ValueOpps}
import wvlet.log.Logger

import scala.util.Try

sealed abstract class PortalAppCapability {
  def name: String

  def label: String

  override def equals(other: Any): Boolean = other match {
    case that: PortalAppCapability => that.name == name
    case that => super.equals(that)
  }

  override def toString: String = name
}

object PortalAppCapability {
  private val log = Logger.of[PortalAppCapability.type]

  private case class CapabilityImpl(override val name: String,
                                    override val label: String = "") extends PortalAppCapability

  def apply(name: String): PortalAppCapability = ALL.find(_.name == name).getOrElse(CapabilityImpl(name))

  val apiMailbox: PortalAppCapability = CapabilityImpl("ApiMailbox", "Mailbox")
  val apiCrm: PortalAppCapability = CapabilityImpl("ApiCRM", "CRM")
  val apiChat: PortalAppCapability = CapabilityImpl("ApiChat", "Messaging")
  val apiPM: PortalAppCapability = CapabilityImpl("ApiPM", "Project management")
  val endUserSessions: PortalAppCapability = CapabilityImpl("EndUserSessions", "End user sessions")
  val emailMarketing: PortalAppCapability = CapabilityImpl("EmailMarketing", "Email marketing")
  val scheduler: PortalAppCapability = CapabilityImpl("Scheduler", "Scheduler")
  val apiAccounting: PortalAppCapability = CapabilityImpl("ApiAccounting", "Accounting")

  val unknown: PortalAppCapability = CapabilityImpl("undefined", "Undefined")

  val ALL: List[PortalAppCapability] = List(
    apiMailbox,
    apiCrm,
    apiChat,
    endUserSessions,
    scheduler,
    // apiPM,
    emailMarketing,
   apiAccounting,
  )


  import io.circe._, io.circe.generic.codec.DerivedAsObjectCodec.deriveCodec

  implicit def encoder: Encoder[PortalAppCapability] = Encoder[String].contramap(_.name)

  implicit def decoder: Decoder[PortalAppCapability] =
    Decoder.decodeJson.emapTry {
      case raw if raw.isObject =>
        raw.as[PortalAppCapability.CapabilityImpl].toTry
      case raw if raw.isString =>
        val n = raw.asString.getOrFail(new Exception(s"undefined type PortalAppCapability($raw)"))
        Try(PortalAppCapability.apply(n))
      case raw =>
        log.warn(s"undefined capability, should be check $raw")
        Try(PortalAppCapability.unknown)
    }

  implicit final class ServiceTypeExtension(private val capability: PortalAppCapability) extends AnyVal {

    // TODO: rethink
    def weight: Int = ALL
      .indexWhere(e => e.name == capability.name)
      .condition(_ >= 0, index => index, _ => ALL.length + 1)
  }
}


