package common.ui.secret_input

import com.github.uosis.laminar.webcomponents.material.{Icon, Textarea, Textfield}
import com.raquo.laminar.api.L._
import org.scalajs.dom
import wvlet.log.Logger
import common._
import common.ui.mat_components_styles.setInputAutoCompleteAndId

case class SecretInputComponent(label: String = "", value: Var[String] = Var(""), newPassword: Boolean = false) {

  private val log = Logger.of[SecretInputComponent]

  val clickBus: EventBus[dom.MouseEvent] = new EventBus[dom.MouseEvent]

  val showText: Var[Boolean] = Var(false)

  val valid: Var[Boolean] = Var(false)

  val newPasswordPattern = """(?!.*\s)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*\(\)_\-\+=\?,\|“<>\~`]).{8,}$"""

  val inputNode = Textfield(
    _ => cls := "low-disabling slds-size--1-of-1",
    _.outlined := true,
    _.label := label,
    _.`type` <-- showText.signal.map{
      case false => "password"
      case _ => "text"
    },
    _ => autoComplete := "new-password",
    _.value <-- value.signal,
    _.required := true,
    _ => onInput.mapToValue --> value.writer,
    _ => inContext(ctx => onInput.mapTo(ctx.isValid) --> valid.writer),
    _.validateOnInitialRender <-- value.signal.map(_.nonEmpty),
    _.pattern := (if(newPassword) newPasswordPattern else ""),
    _.validationMessage := (if(!newPassword) "Please, enter a value" else "The password should be at least eight characters long and include letters in upper case and lower case, numbers and special characters"),

    _ => onMountCallback(setInputAutoCompleteAndId("new-password", "password"))

  )

  val node: Div = {

    div(
      cls := "slds-is-relative slds-size--1-of-1",
      inputNode,
      Icon(
        _ => cls := "small clickable light-brown slds-is-absolute",
        _ => borderRadius := "50%",
        _ => top := "1.7rem",
        _ => right := "1rem",
        _ => child.text <-- showText.signal.map {
          case false => "visibility"
          case _ => "visibility_off"
        },
        _ => onClick --> clickBus.writer,
        _ => clickBus.events --> Observer[dom.MouseEvent](onNext = _ => showText.set(!showText.now))
      )
    )
  }
}
