package common.ui.user_app_permissions

import com.github.uosis.laminar.webcomponents.material.Checkbox
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.{PortalUserAppPermission, PortalUserAppPermissions}
import common.airstream_ops.{EventStreamNestedOps, OptionEventStreamOps, OptionSignalOps, SignalOps, ValueToObservableOps}
import common.ui.grid_configs.GridConfig3
import common.ui.notifications.InfoTextComponent
import org.scalajs.dom.html
import service.apis.portal_api.PortalApi

import scala.annotation.unused

class UserAppPermissionsView(portalApi: PortalApi,
                             teamId: Signal[Int],
                             appId: Option[Signal[Int]], //None for new app
                             noUsersToAssignHandler: Observer[Unit] = Observer.empty
                            ) {
  private val users: Var[List[PortalUserAppPermissions]] = Var(Nil)


  private val eventHandlers = List(
    getUsers() --> users,
    users.signal.changes.filter(_.size == 1).mapTo(()) --> noUsersToAssignHandler
  )

  val node: ReactiveHtmlElement[html.Div] = div(
    div(
      cls := "slds-grid gap--medium",

      p(cls := "title--level-3", "Assign users who will have access to the application"),
      InfoTextComponent.onlyTitle("The owner and administrators have access to all applications.".signaled)
    ),
    div(
      cls := "data-table slds-m-top--large",
      children <-- users.signal.split(_.id)(renderUser)
    )
  ).amend(eventHandlers)


  private def getUsers(): EventStream[List[PortalUserAppPermissions]] = for {
    t <- teamId
    a <- appId.$traverse(app => app)
    appUsers <- a.$eTraverse(portalApi.getUsersAppPermissions(t, _))
    result <- appUsers.map(_.streamed)
      .getOrElse{
        portalApi.getMembers(t)
          .nestedMap( m =>

            PortalUserAppPermissions(
              m.id,
              m.email,
              m.name,
              m.role,
              Option.when(m.role.isAdmin){ Nil })
          )
      }

  } yield result


  private def onPermissionsChanging(uid: Int, permissions: Option[List[PortalUserAppPermission]]) =
    appId match {

      case Some(app) => for {
        t <- teamId
        a <- app
        _ <- portalApi.updateUserAppPermissions(t, a, uid, permissions)
        users <- portalApi.getUsersAppPermissions(t, a)
      } yield users

      case None => users.signal.map(_.map { user =>
        if (user.id == uid) user.copy(appPermissions = permissions)
        else user
      }).stream
    }

  private def renderUser(id: Int, @unused initial: PortalUserAppPermissions, user: Signal[PortalUserAppPermissions]) = {

    val gridConfig = GridConfig3((3, 18, 3), 24)
    div(
      cls := "table-row",
      cls <-- user.map(_.teamMemberRole.isAdmin).map(if (_) "light" else "") ,
      p(
        width := gridConfig.widthSetters.col_1,
        Checkbox(
          _.checked <-- user.map(_.appPermissions.nonEmpty),
          _ => composeEvents(Checkbox.onChange.mapToChecked)(_
            .flatMap(c =>
              onPermissionsChanging(id, Option.when(c){Nil}))) --> users,
          _.disabled <-- user.map(_.teamMemberRole.isAdmin)
        )
      ),

      p(
        width := gridConfig.widthSetters.col_2,
        child.text <-- user.map(_.name),
        child.text <-- user.map(u => s" (${u.email})")
      ),
      p(
        cls := gridConfig.classNames.col_3,
        cls := "badge-container slds-grid slds-grid--align-end flex-right-block",

        small( cls  := "badge", child.text <-- user.map(_.teamMemberRole.label))
      ),


    )
  }
}

object UserAppPermissionsView {
  def apply(portalApi: PortalApi,
            teamId: Signal[Int],
            appId: Option[Signal[Int]], //None for new app
            noUsersToAssignHandler: Observer[Unit] = Observer.empty
           ): ReactiveHtmlElement[html.Div] = new UserAppPermissionsView(portalApi, teamId, appId, noUsersToAssignHandler).node
}
