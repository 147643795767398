package root_pages.aurinko_pages.app.users

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.{SignalOptionOps, ValueToObservableOps}
import common.{EndUser, InstantOps, StoreType, TeamMemberRole, UserAuthType}
import common.ui.au_storage_view.StorageComponent
import common.ui.breadcrumbs.{BreadcrumbsComponent, BreadcrumbsItem}
import common.ui.text_data_view.ColoredStrings
import org.scalajs.dom
import org.scalajs.dom.html
import portal_router.{AccountPage, OrganizationPage, Page, PortalRouter, UserPage, UsersPage}
import service.apis.portal_api.PortalApi
import service.portal_state.{PortalState, TeamMemberAccess}
import service.scroll_ops.ScrollOps

class EndUserComponent($route: Signal[UserPage],
                       portalApi: PortalApi,
                       portalRouter: PortalRouter,
                       documentScrollOps: ScrollOps,
                       portalState: PortalState
                      ) {
  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)

  val previous: Option[Page] = portalRouter.router.pageForAbsoluteUrl(dom.document.referrer)

  val modelBus: EventBus[EndUser] = new EventBus[EndUser]

  val $serviceTypeRenderers: Signal[ColoredStrings.ColoredStringsSet] =
    portalState.$serviceTypes.map(serviceTypes => ColoredStrings(serviceTypes.map(_.label)))


  val node: ReactiveHtmlElement[html.Div] = div(
    $route.flatMap(route => portalApi.user(route.appKey, route.userId)) --> modelBus.writer,

    cls := "x-nested-page",
    div(
      cls := "nav-filters-container",
      BreadcrumbsComponent(
        BreadcrumbsItem("App users".signaled,
          $route.map(r => portalRouter.router.absoluteUrlForPage(UsersPage(r.appKey))).some),

        BreadcrumbsItem(modelBus.events.map(_.name).startWith(None).getOrElse("App user"), None)
      ))
      .amend(cls <-- documentScrollOps.$scrolled
        .map { case true => "shadow" case _ => "" }),

    child <-- modelBus.events.map(user => div(
      cls := "content-padding",
      div(
        cls := "left-section",
        paddingTop <-- documentScrollOps.$scrollTop.changes
          .debounce(0).map(i => s"calc(var(--au-header-height) - ${i}px)"),
        div(
          cls := "",
          div(
            cls := "slds-grid slds-grid--align-spread left-section-header",
            user.name.map(un => p(
              cls := "title--level-1",
              un
            )).getOrElse(p(s"App user", cls := "title--level-1")),
          ),
          div(
            cls := "content-container",
            div(
              cls := "content",
              div(
                cls := "border-top--light border-bottom--light",

                div(
                  //                cls := "slds-grid slds-grid_vertical-align-center slds-m-vertical--medium",
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  span(cls := "gray", "Id"),
                  span(user.id),
                ),

                user.email.map(userEmail => div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Email"),
                  span(userEmail)
                )).getOrElse(""),

                user.authOrgId.map(userAuthOrgId => div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Organization"),
                  span(userAuthOrgId),
                )).getOrElse(""),

                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Authorization type"),
                  span(user.externalIdType.getOrElse(UserAuthType.cookieOrHeader).label),
                ),

                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Created at"),
                  span(user.createdAt.toPrettyLocalFormat),
                ),
                user.lastActivity.map(userLastActivity => div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Last activity"),
                  span(userLastActivity.toPrettyLocalFormat)
                ),
                ),
              )
            ),
          )
        )
      ),

      div(
        cls := "right-section slds-m-top--large",
        // accounts
        if (user.accounts.getOrElse(Nil).nonEmpty) div(
          div(
            cls := "slds-m-top_xx-large slds-m-bottom_medium",
            span(cls := "title--level-3", "Accounts")
          ),
          div(
            cls := "data-table",
            div(
              cls := "table-header",
              span(cls := "slds-size--1-of-12", "Id"),
              span(cls := "slds-size--2-of-12", "Service type"),
              span(cls := "slds-size--3-of-12", "Email"),
              span(cls := "slds-size--4-of-12", "Organization"),
            ),

            user.accounts.getOrElse(Nil).map { account =>
              div(
                cls := "table-row panel-like",
                p(
                  cls := "slds-size--1-of-12",
                  a(
                    account.id,
                    href <-- $route.map(route => portalRouter.router.absoluteUrlForPage(AccountPage(route.appKey, account.id)))

                  )),
                span(cls := "slds-size--2-of-12", child <-- $serviceTypeRenderers.map(_.renderFuncForText(account.serviceType.label).apply())),
                span(cls := "slds-size--3-of-12", account.email),
                p(
                  cls := "slds-size--3-of-12",
                  a(
                  account.organization.flatMap(_.name)
                    .orElse(account.authOrgId),

                  account.organization.map(org =>
                    href <-- $route
                      .map(route => portalRouter.router.absoluteUrlForPage(OrganizationPage(
                        route.appKey,
                        org.id)
                      ))
                  )
                )),
                div(
                  cls := "slds-size--3-of-12 slds-grid slds-grid--align-end badge-container",

                  account.userAccountType.flatMap {
                    case "primary" => Some(small(cls := "badge slds-m-left--xx-small", "Primary"))
                    case "managed" => Some(small(cls := "badge slds-m-left--xx-small", "Managed"))
                    case _ => None
                  },
                  account.bookingCount.collect { case x if x > 0 =>
                    small(cls := "badge slds-m-left--xx-small", "Booking")
                  },

                  account.trackingActive.collect { case true =>
                    small(cls := "badge slds-m-left--xx-small", "Email tracking")
                  },

                  account.templatesCount.collect { case x if x > 0 =>
                    small(cls := "badge slds-m-left--xx-small", "Email templates")
                  },

                  Option.when(account.`type` == "daemon")(small(cls := "badge slds-m-left--xx-small", "Service")),
                  Option.when(!account.active)(small(cls := "badge orange slds-m-left--xx-small", "Inactive")),
                  Option.when(account.hasApiErrors.getOrElse(false))(small(cls := "badge red slds-m-left--xx-small", "Error"))
                ),
              )
            }
          )
        ) else None,
        // properties
        child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen(
          div(
            cls := "slds-m-top_xx-large",
            child <-- $route.map { route =>
              StorageComponent(
                appKey = route.appKey,
                instId = route.userId,
                storeType = StoreType.user,
                portalApi = portalApi,
                $sessionExpiredEvents = portalState.Session.$sessionExpiredEvents,
                teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
              ).node
            },
          )
        ),
      ),
    )),
  )
}
