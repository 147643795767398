package root_pages.aurinko_pages.app.sync

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import com.raquo.waypoint.SplitRender
import common.SyncOrganization
import org.scalajs.dom.html
import portal_router.{SyncOrgPages, SyncPage}
import service.apis.sync_api.SyncApi

case object SyncOrgRoot{

  def apply($route: Signal[SyncOrgPages],
            syncApi: SyncApi,
            childrenRender: Signal[SyncOrgPages] => SplitRender[SyncOrgPages, HtmlElement]
           ): Div = div(
      $route.map(p => (p.appKey, p.orgId))
        .flatMap(t => syncApi.syncOrg(t._1, t._2)) --> Observer[SyncOrganization](onNext = syncApi.setOrg),

      child <-- syncApi.$maybeOrg.map(_.isDefined).map {
        case false => emptyNode
        case true =>
          div(
            child <-- childrenRender($route).$view
          )
      }
  )

}

case object SyncRoot {
  def apply(childrenRender: Signal[SyncPage] => SplitRender[SyncPage, HtmlElement],
            $page: Signal[SyncPage]): ReactiveHtmlElement[html.Div] =
    div(
      child <-- childrenRender($page).$view,
    )
}
