package root_pages.aurinko_pages

import common._
import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.Button
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import com.raquo.waypoint.SplitRender
import common.airstream_ops.{SignalNestedOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.ui.icons.{IconColor, IconComponent, IconShape, IconSize, IconType, MaterialIcons}
import common.ui.notifications.{BillingNotifications, PageFixedNotifications, SuperscriptWarningIcon}
import common.{AppKey, PortalApplication, PortalTeam, PortalUser, graphic}
import org.scalajs.dom
import org.scalajs.dom.html
import portal_router.{AppPage, AurinkoPage, BillingInvoicePage, BillingPage, BufferPage, DashboardPage, LoginPage, PortalRouter, TeamAppsPage, TeamPage}
import root_pages.aurinko_pages.main.TeamsManagement
import root_pages.aurinko_pages.profile_dialog.ProfileComponent
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import service.scroll_ops.ScrollOps

import wvlet.log.Logger

class AurinkoRootComponent($route: Signal[AurinkoPage],
                           portalApi: PortalApi,
                           portalState: PortalState,
                           childrenRender: Signal[AurinkoPage] => SplitRender[AurinkoPage, HtmlElement],
                           documentScrollOps: ScrollOps,
                           portalRouter: PortalRouter,
                           profileDialog: ProfileComponent) {
  private val log = Logger.of[AurinkoRootComponent]

  private val showProfileMenu: Var[Boolean] = Var(false)

  def userOnlyElement(f: PortalUser => ReactiveHtmlElement[dom.html.Element]): Signal[Option[ReactiveHtmlElement[html.Element]]] =
    portalState.$maybeMe.map(_.map(f))

  lazy val BillingNotification = new PageFixedNotifications(

    portalState.$maybeTeam
      .combineWith($route).map {
        case (_, BillingPage(_)) | (_, TeamAppsPage(_)) | (_, BillingInvoicePage(_, _)) => None
        case (Some(t), _) if t.role.isAdmin =>
          t.billingInfo.map(bi => List(

            Option.when(bi.paymentEnabled && !bi.paymentMethodConfigured && bi.trialDaysLeft <= 7) {
              BillingNotifications.PaymentMethodRequired(t.id, bi.trialDaysLeft, portalRouter.goto)
            },

            Option.when(bi.paymentInfo.exists(_.hasDebt)) {
              BillingNotifications.HasDebt(t.id, portalRouter.goto)
            }
          ).flatten
          )
        case _ => None
      }.map(_.toList.flatten)
  )

  val signOutBus: EventBus[dom.MouseEvent] = new EventBus[dom.MouseEvent]

  private def VerificationWarningIcon = SuperscriptWarningIcon("Email verification required.")

  def profileMenu(user: PortalUser): Div = div(
    cls := "slds-grid",
    position := "relative",
    div(
      cls := "slds-grid slds-grid--vertical-align-center slds-grid-align-spread clickable",
      IconComponent(
        icon = MaterialIcons.person,
        color = IconColor.orange,
        shape = IconShape.circle.some
      ),

      div(
        cls := "slds-m-left--small",
        p(user.name)
      ),
      child.maybe <-- portalState.$me.map(_.verified).map {
        case true => None
        case false => VerificationWarningIcon.some
      },
      onClick.mapTo(true) --> showProfileMenu,
    ),
    material.Menu(
      _ => cls := "dropdown_menu right",
      _.open <-- showProfileMenu,
      _.onClosed.mapTo(false) --> showProfileMenu.writer,

      _.slots.default(
        ListItem(
          _ => cls := "blue",
          _ => graphic := "icon",
          _.hasMeta := true,
          _.slots.graphic(material.Icon(_ => "person")),
          _.slots.default(span("Profile", cls := "blue")),
          _.slots.meta(
            VerificationWarningIcon.amend(cls <-- portalState.$me.map(_.verified).map { case true => "hidden" case _ => "" })
          ),
          _ => onClick.mapTo(false) --> showProfileMenu,
          _ => onClick.mapTo(true) --> profileDialog.showProfileDialog,
        ),
        ListItem(
          _ => cls := "blue",
          _ => graphic := "icon",
          _.slots.graphic(material.Icon(_ => "exit_to_app")),
          _.slots.default(span("Sign out", cls := "blue")),
          _ => onClick.mapTo(false) --> showProfileMenu,
          _ => onClick --> Observer.combine(
            signOutBus.writer,
          )
        )
      )
    ),
  )

  private def AppsSelect() = {

    val showMenu = Var(false)
    div(
      cls := "slds-is-relative",

      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-grid-align-spread slds-m-left--x-small",
        cls <-- portalState.$maybeTeam.nestedMap(_.apps.exists(_.nonEmpty)).map { case Some(true) => "clickable" case _ => "" },

        composeEvents(onClick)(_.sample(portalState.$maybeTeam)
          .map(_.exists(_.apps.exists(_.nonEmpty)))) --> showMenu,

        img(
          cls := "slds-m-right--x-medium",
          src := "Vector_80.svg",
          height := "20px",
          flexShrink := "0"
        ),

        p(
          cls := "slds-m-right--small slds-m-left--small",
          child.text <-- portalState.$maybeApp.nestedMap(_.name).getOrElse("")
        ),
        child.maybe <-- portalState.$maybeTeam.nestedMap(_.apps).mapOptWhenTrue(_.exists(_.nonEmpty))(IconComponent(
          icon = MaterialIcons.unfold,
          color = IconColor.grey,
          iconType = IconType.outlined,
          size = IconSize.medium
        )),
      ),
      material.Menu(
        _ => cls := "dropdown_menu",
        _.open <-- showMenu.signal,
        _.onClosed.mapTo(false) --> showMenu,
        _ => div(
          maxHeight := "calc(100vh - 2 * var(--au-header-height))",
          children <-- portalState.$maybeTeam.subflatMap(_.apps).getOrElse(Nil).nestedMap(appl =>
            ListItem(
              _ => height := "36px",
              _.slots.default(span(appl.name)),
              _ => composeEvents(onClick)(_.sample(portalState
                .$maybeTeam.nestedMap(t => DashboardPage(AppKey(t.id, appl.id))))) --> portalRouter.gotoSome,
              _ => onClick.mapTo(false) --> showMenu
            )
          ),
          ListItem(
            _ => position := "sticky",
            _ => height := "36px",
            _ => bottom := "0",
            _ => cls := "border-top--light",
            _ => backgroundColor := "white",
            _.slots.default(span(cls := "blue", "All applications →")),
            _ => composeEvents(onClick)(_.sample(portalState
              .$maybeTeam.nestedMap(t => TeamAppsPage(t.id)))) --> portalRouter.gotoSome,
            _ => onClick.mapTo(false) --> showMenu
          )
        ),
      )
    )
  }

  private val eventBinders = List(
    $route.changes.flatMap(_ => portalApi.ping) --> Observer.empty,

    $route.changes --> Observer[AurinkoPage]({
      case _: AppPage => ()
      case _: TeamPage =>
        portalState.resetAppRegs()
        portalState.updateApp(None)
      case _ =>
        portalState.resetAppRegs()
        portalState.updateApp(None)
        portalState.updateTeamId(None)
    }),

    portalState.$maybeMe.flatMap {
      case None => portalApi.getMe
      case Some(me) => EventStream.fromValue(me)
    } --> Observer[PortalUser](onNext = portalState.updateMe),

    portalState.$serviceTypes.flatMap {
      case Nil => portalApi.getServiceTypes
      case items => EventStream.fromValue(items)
    } --> Observer[List[ServiceType]](onNext = portalState.updateServiceTypes),

    portalState.$capabilities.flatMap {
      case Nil => portalApi.getCapabilities
      case items => EventStream.fromValue(items)
    } --> Observer[List[PortalAppCapability]](onNext = portalState.updateCapabilities),

    signOutBus.events.flatMap(_ => {
      portalApi.signOut
    }) --> Observer[Unit](onNext = _ => {
      portalRouter.navigate(LoginPage())
      portalState.resetState()
    })
  )

  val newTeam: EventBus[Unit] = new EventBus[Unit]
  private val TeamsComponent = new TeamsManagement(
    portalApi,
    portalState,
    portalRouter,
    portalState.$maybeTeam,
    portalState.$maybeMe.nestedMap(_.teams).map(_.toList.flatten),
    newTeam.events
  )

  val node: ReactiveHtmlElement[html.Div] = div(
    eventBinders,

    minWidth := "calc(var(--document-max-width));", minHeight := "100vh", overflow := "hidden",
    div(
      cls := "aurinko-header",
      cls <-- documentScrollOps.$scrolled
        .combineWith($route.map {
          case _: AppPage | _: TeamPage => false
          case _ => true
        }).map { case (true, true) => "shadow" case _ => "" },
      div(
        cls := "slds-grid slds-grid--vertical-align-center",
        div(
          cls := "slds-grid slds-grid--vertical-align-center clickable",
          img(src := "aurinko_icon.svg", width := "38px", marginRight := "4px", cls := ""),
          img(src := "aurinko.svg", height := "20px", verticalAlign := "super", cls := "slds-p-around--xx-small"),

          composeEvents(onClick)(_
            .sample(portalState.$maybeTeam)
            .map {
              case Some(team) => TeamAppsPage(team.id)
              case _ => BufferPage
            }
          ) --> portalRouter.goto,
        ),

        TeamsComponent()
          .amend(cls := "slds-m-left--x-large").some,

        child.maybe <-- portalState.$maybeApp.map(_.nonEmpty).mapOptWhenTrue(_ == true)(AppsSelect())
      ),
      div(child.maybe <-- userOnlyElement(profileMenu))
    ),

    div(child.maybe <-- userOnlyElement(me => profileDialog.profileDialog)),

    BillingNotification(),

    child.maybe <-- userOnlyElement(me => div(
      cls := "aurinko-wrapper",
      div(
        cls := "au-page-wrapper",

        child <-- (me.teams match {
          case Nil => div(
            div(
              cls := "content-padding",
              cls := "slds-size--1-of-1 slds-p-vertical--x-large slds-grid slds-grid--vertical slds-grid--vertical-align-center border-around--light gap--large",
              p(
                cls := "title--level-2",
                "You don't have any teams yet."),

              Button(
                _.raised := true,
                _ => cls := "secondary",
                _.label := "Create team",
                _ => onClick.mapTo(()) --> newTeam
              )
            )
          ).signaled

          case _ => childrenRender($route).$view
        })
      )
    ),
    )
  )
}



