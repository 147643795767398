package common.ui.auto_suggest

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.Textfield
import com.raquo.laminar.api.L
import com.raquo.laminar.api.L._
import common.airstream_ops.{EventStreamOps, SignalNestedOps, SignalOps, ValueToObservableOps}
import org.scalajs.dom
import wvlet.log.Logger

trait SuggestItem {
  val label: String
  val value: String
}

case class BaseItem(label: String, value: String) extends SuggestItem

class AutoSuggestComponent(
                            $value: Signal[Option[String]],
                            onChange: Observer[Option[String]],
                            $availableValues: Signal[List[SuggestItem]],
                            label: String,
                            $disabled: Signal[Boolean] = false.signaled,
                            inputCls: Option[String] = None,
                            itemsToShowWithEmptySearch: Signal[List[SuggestItem]] = Nil.signaled,
                            strict: Boolean = true, // the value must be one of the availableValues
                            minSearchCharacters: Int = 0,
                            initialErrorsShow: Boolean = true,
                            required: Boolean = false
                                  ) {
  private val log = Logger.of[AutoSuggestComponent]

  def value: L.Signal[Option[String]] = $value

  private val showMenu: Var[Boolean] = Var(false)

  private val inputString: Var[String] = Var("")

  private val changed = Var(false)

  private val shownList: Var[List[SuggestItem]] = Var(Nil)

  private val inputClickBus = new EventBus[Unit]

  private val focusedItem: Var[Option[SuggestItem]] = Var(None)

  val $valid: Signal[Boolean] = $value.withCurrentValueOf(inputString.signal).map{
    case (v, inputStr) =>
      !(v.isEmpty && (required || inputStr.nonEmpty))
  }.logINFO(log)(str => s"valid $str")

  private val $showError = $valid.combineWith(changed.signal).map{case (valid, ch) => !valid && (initialErrorsShow || ch)}

  val eventBinders = List(

    $availableValues
      .withCurrentValueOf($value)
      .map{
        case (items, Some(value)) => items.find(i => value.contains(i.value))
          .map(_.label)
          .getOrElse(value)
        case _ => ""
      } --> inputString,

    inputString.signal.changes
      .withCurrentValueOf($availableValues) --> Observer.combine[(String, List[SuggestItem])](
      onChange.contramap{ case (str, values) =>
        val newVal = values.find(_.label.toLowerCase == str.trim.toLowerCase).map(_.value)

        if (strict) newVal
        else  newVal.getOrElse(str.trim).some
      },
      Observer[(String, List[SuggestItem])](t => setItemsToShow(t._1, t._2))
    ),

    onClick.stopPropagation --> Observer.empty,
    showMenu.signal --> Observer[Boolean]({
      case true => dom.document.addEventListener[dom.MouseEvent]("click", domClickListener)
      case false => {
        focusedItem.set(None)
        dom.document.removeEventListener[dom.MouseEvent]("click", domClickListener)
      }
    }),
    onUnmountCallback((_: Div) => dom.document.removeEventListener("click", domClickListener)),

    onKeyDown.filter(e => e.keyCode == 38 || e.keyCode == 40)
      --> Observer[dom.KeyboardEvent](e => {
      val focusedIndex = focusedItem.now.map(v => shownList.now.indexOf(v))
      if (shownList.now.nonEmpty && (showMenu.now || focusedItem.now.isEmpty)) {

        val newIndex = e.keyCode match {
          case 38 => focusedIndex match {
            case Some(0) => shownList.now.length - 1
            case None | Some(-1) => 0
            case Some(other) => other - 1
          }
          case _ => focusedIndex match {
            case None | Some(-1) => 0
            case Some(last) if last == shownList.now.length - 1 => 0
            case Some(other) => other + 1

          }
        }

        shownList.now.lift(newIndex).foreach(v => focusedItem.set(v.some))
      }
      showMenu.set(true)
    }),
    onKeyDown.filter(e => e.keyCode == 13).stopPropagation --> Observer[dom.KeyboardEvent](_ => {
      focusedItem.now.foreach(v => inputString.set(v.label))
      showMenu.set(false)
    })

  )

  private val textInput = Textfield(
    _ => cls := "slds-size--1-of-1",
    _ => inputCls.map(str => cls := str),
    _.outlined := true,
    _.label := label,
    _.value <-- inputString,
    _ => onInput.mapToValue --> Observer.combine[String](
      inputString.writer,
      changed.writer.contramap(_ => true),
      showMenu.writer.contramap(_ => true),
    ),
    _.helper <--$showError.map {
      case false => " "
      case true => s"Please enter valid ${label.toLowerCase()}"
    },
    _.helperPersistent := true,
    _ => cls <-- $showError.map { case true => "with-error" case false => "" },
    _.disabled <-- $disabled,
    _ => onClick.mapTo(()) --> inputClickBus,
    _ => inputClickBus.events
      .withCurrentValueOf($disabled)
      .filter(_ == false)
      .mapTo(true) --> showMenu,

    _.iconTrailing := (if(strict) "search" else "")
  )

  private def sortValues(string: String, values: List[SuggestItem]): List[SuggestItem] =
    values.filter(_.label.toLowerCase.startsWith(string.toLowerCase)).sortBy(_.label) ++
      values.filter(i =>
        i.value.toLowerCase.startsWith(string.toLowerCase) &&
          !i.label.toLowerCase.startsWith(string.toLowerCase)).sortBy(_.label) ++
      values.filterNot(i =>
        i.label.toLowerCase.startsWith(string.toLowerCase) ||
          i.value.toLowerCase.startsWith(string.toLowerCase))
        .sortBy(_.label)

  private def setItemsToShow(string: String, values: List[SuggestItem]): Unit = shownList.set(
    if(string.trim.length >= minSearchCharacters) {
      sortValues(string.trim, values.filter(i => i.label.toLowerCase.contains(string.trim.toLowerCase) || i.value.toLowerCase.contains(string.trim.toLowerCase)))
    } else Nil
  )

  def domClickListener(e: dom.MouseEvent): Unit = {
    showMenu.set(false)
  }

  val node: Div = div(
    cls := "slds-is-relative",

    textInput,

    div(
      cls := "shadow-box slds-is-absolute slds-size--1-of-1",
      cls <-- showMenu.signal.combineWith(shownList.signal).map{
        case (show, items) if show && (strict || items.nonEmpty) => ""
        case _ => "hidden"
      },
      top := "56px",
      maxHeight := "140px",
      zIndex := 5,
      overflowY := "auto",
      child.maybe <-- shownList.signal
        .combineWith(itemsToShowWithEmptySearch)
        .combineWith(inputString.signal)
        .map {
          case (_, items, inputStr) if items.nonEmpty && inputStr.isEmpty => None
          case (Nil, _, inputStr) if strict => Some(small(
            cls := "slds-show slds-m-vertical--small grey",
            paddingLeft := "var(--mdc-list-side-padding, 16px)",
            paddingRight := "var(--mdc-list-side-padding, 16px)",
            if (inputStr.length < minSearchCharacters)
              s"Type $minSearchCharacters or more characters to search ${label.toLowerCase}"
            else "No items found"

          ))
          case _ => None
        },
      children <-- shownList.signal
        .combineWith(inputString.signal).flatMap{
        case (Nil, str) if str.isEmpty => itemsToShowWithEmptySearch.map(_.sortBy(_.label))
        case (l, _) => l.signaled
      }
        .split(_.value)((_: String, _: SuggestItem, $item: Signal[SuggestItem]) => {
          ListItem(
            _.disabled <-- $disabled,
            _.slots.default(
              span(child.text <-- $item.map(_.label))),
            _.selected <-- $value.combineWith($item).map { case (v, i) => v.contains(i.value) },
            _ => cls <-- focusedItem.signal.combineWith($item).map { case (f, i) => if (f.contains(i)) "focused" else "" },
            _ => inContext(t => focusedItem
              .signal.combineWith($item) --> Observer[(Option[SuggestItem], SuggestItem)]{ case (f, i) =>
              if (f.contains(i)) { t.ref.scrollIntoView(false) }
            }),

            _ => composeEvents(onClick.stopPropagation)(_
              .withCurrentValueOf($item)
              .withCurrentValueOf($availableValues)
              .map(t => (t._2, t._3))) --> Observer[(SuggestItem, List[SuggestItem])](onNext = {
                case (v, values) =>
                  inputString.set(v.label)
                  showMenu.set(false)
                  changed.set(true)
//                  setItemsToShow(v.label, values)
              })

          )
        }
        )

    )

  ).amend(eventBinders)

}

object AutoSuggestComponent {
  def apply(
             value: Signal[Option[String]],
             onChange: Observer[Option[String]],
             $availableValues: Signal[List[String]],
             label: String,
             $disabled: Signal[Boolean] = false.signaled,
             inputCls: Option[String] = None,
             itemsToShowWithEmptySearch: Signal[List[String]] = Nil.signaled,
             strict: Boolean = true, // if the value must be one of the availableValues
             minSearchCharacters: Int = 0,
             initialValidation: Boolean = false,
             required: Boolean = false
           ): AutoSuggestComponent = new AutoSuggestComponent(
    value,
    onChange,
    $availableValues.nestedMap(i => BaseItem(i, i)),
    label,
    $disabled,
    inputCls,
    itemsToShowWithEmptySearch.nestedMap(i => BaseItem(i, i)),
    strict,
    minSearchCharacters,
    initialValidation,
    required
  )
}
