package common

import cats.{FlatMap, Functor}
import com.raquo.laminar.api.L._
import cats.implicits.{catsSyntaxOptionId, none}

import scala.collection.BuildFrom
import wvlet.log.Logger

import scala.util.{Failure, Success}

package object airstream_ops {

  def emptyStream[T]: EventStream[T] = EventStream.fromCustomSource[T](
    shouldStart = _ => false,
    start = (_, _, _, _) => (),
    stop = _ => ()
  )

  implicit final class ValueToObservableOps[T](private val value: T) extends AnyVal {
    def streamed: EventStream[T] = EventStream.fromValue(value)

    def signaled: Signal[T] = Signal.fromValue(value)
  }

  implicit final class AirstreamStringOps(private val string: String) extends AnyVal {
    def toStringVar: Var[String] = Var(string)
  }

  implicit final class AirstreamOptStringOps(private val value: Option[String]) extends AnyVal {
    def toStringVar: Var[String] = Var(value.getOrElse(""))
  }

  implicit final class AirstreamStringVarOps(private val stringVar: Var[String]) extends AnyVal {
    def toStringIfNonEmpty: Option[String] = stringVar.now match {
      case "" => None
      case str => str.some
    }

  }

  implicit final class SignalOps[T](private val $signal: Signal[T]) extends AnyVal {
    def stream: EventStream[T] = EventStream.fromValue(()).sample($signal)

    def tap(effect: T => Unit): Signal[T] = $signal.map(value => {
      effect(value)
      value
    })

    //todo: rename
    def mapOptWhenTrue[T2](f: T => Boolean)(ret: => T2): Signal[Option[T2]] = $signal.map(f).map{
      case true => ret.some
      case _ => None
    }

    def flatMapOptWhenTrue[T2](f: T => Boolean)(ret: => Signal[T2]): Signal[Option[T2]] = $signal.map(f).flatMap{
      case true => ret.map(_.some)
      case _ => none[T2].signaled
    }

    def condition[A](c: T => Boolean, t: T => A, f: T => A): Signal[A] = $signal.map(v => if (c(v)) t(v) else f(v))

    def logINFO(l: Logger)(desc: T => String): Signal[T] = $signal.tap(v => l.info(desc(v)))

    def logWARN(l: Logger)(desc: T => String): Signal[T] = $signal.tap(v => l.warn(desc(v)))

    def withErrorHandling(f: Throwable => Unit): Signal[Option[T]] = $signal.recoverToTry
      .map{
        case Success(success) => success.some
        case Failure(exception) => f(exception)
         None
      }
  }

  implicit final class EventStreamOps[T](private val $stream: EventStream[T]) extends AnyVal {

    def tap(effect: T => Unit): EventStream[T] = $stream.map(value => {
      effect(value)
      value
    })

    def condition[A](c: T => Boolean, t: T => A, f: T => A): EventStream[A] = $stream.map(v => if (c(v)) t(v) else f(v))

    def logINFO(l: Logger)(desc: T => String): EventStream[T] = $stream.tap(v => l.info(desc(v)))

    def logWARN(l: Logger)(desc: T => String): EventStream[T] = $stream.tap(v => l.warn(desc(v)))

    def withErrorHandling(f: Throwable => Unit): EventStream[Option[T]] = $stream.recoverToTry
      .map{
        case Success(success) => success.some
        case Failure(exception) => f(exception)
          None
      }

    def withErrorHandlingAndCollect(f: Throwable => Unit): EventStream[T] = $stream.recoverToTry
      .map{
        case Success(success) => success.some
        case Failure(exception) => f(exception)
          None
      }.collect{case Some(value) => value}

    def mapOptWhenTrue[T2](f: T => Boolean)(ret: => T2): EventStream[Option[T2]] = $stream.map(f).map{
      case true => ret.some
      case _ => None
    }

    def flatMapOptWhenTrue[T2](f: T => Boolean)(ret: => EventStream[T2]): EventStream[Option[T2]] = $stream.map(f).flatMap{
      case true => ret.map(_.some)
      case _ => none[T2].streamed
    }


  }


  implicit final class SignalOptionOps[T](private val $signal: Signal[Option[T]]) extends AnyVal {

    def $getOrElse($alt: Signal[T]): Signal[T] = $signal.flatMap {
      case Some(value) => value.signaled
      case None => $alt
    }

    def $orElse($alt: Signal[Option[T]]): Signal[Option[T]] = $signal.flatMap {
      case Some(value) => value.some.signaled
      case None => $alt
    }

    def getOrElse(alt: => T): Signal[T] = $signal.map(_.getOrElse(alt))

    def orElse(alt: => Option[T]): Signal[Option[T]] = $signal.map(_.orElse(alt))

    def nestedMap[T2](f: T => T2): Signal[Option[T2]] = $signal.map(_.map(f))

    def semiflatMap[T2](f: T => Signal[T2]): Signal[Option[T2]] = $signal.flatMap{
      case Some(v) => f(v).map(Some(_))
      case _ => none[T2].signaled
    }

    def nestedFlatMap[T2](f: T => Signal[Option[T2]]): Signal[Option[T2]] = $signal.semiflatMap(f).map(_.flatten)

    def getOrFail(exception: Exception): Signal[T] = $signal.map(_.getOrElse(throw exception))

    def someOrFail(exception: Exception): Signal[Option[T]] = $signal.map {
      case Some(v) => v.some
      case None => throw exception
    }

    def $contains(element: T): Signal[Boolean] = $signal.map(_.contains(element))

    def $exists(f: T => Boolean): Signal[Boolean] = $signal.map(_.exists(f))
  }

  implicit final class OptionEventStreamOps[T](private val opt: Option[T]) extends AnyVal {

    def $eTraverse[T2](f: T => EventStream[T2]): EventStream[Option[T2]] = opt match {
      case Some(t) => f(t).map(_.some)
      case None => none[T2].streamed
    }
  }

  implicit final class OptionSignalOps[T](private val opt: Option[T]) extends AnyVal {

    def $traverse[T2](f: T => Signal[T2]): Signal[Option[T2]] = opt match {
      case Some(t) => f(t).map(_.some)
      case None => none[T2].signaled
    }

  }


  implicit final class EventStreamOptionOps[T](private val $stream: EventStream[Option[T]]) extends AnyVal {

    def $getOrElse($alt: EventStream[T]): EventStream[T] = $stream.flatMap {
      case Some(value) => value.streamed
      case None => $alt
    }

    def $orElse($alt: EventStream[Option[T]]): EventStream[Option[T]] = $stream.flatMap {
      case Some(value) => value.some.streamed
      case None => $alt
    }

    def getOrElse(alt: T): EventStream[T] = $stream.map(_.getOrElse(alt))

    def orElse(alt: Option[T]): EventStream[Option[T]] = $stream.map(_.orElse(alt))

    def nestedMap[T2](f: T => T2): EventStream[Option[T2]] = $stream.map(_.map(f))

    def semiflatMap[T2](f: T => EventStream[T2]): EventStream[Option[T2]] = $stream.flatMap{
      case Some(v) => f(v).map(Some(_))
      case _ => none[T2].streamed
    }

    def nestedFlatMap[T2](f: T => EventStream[Option[T2]]): EventStream[Option[T2]] = $stream.semiflatMap(f).map(_.flatten)

    def getOrFail(exception: Exception): EventStream[T] = $stream.map(_.getOrElse(throw exception))

    def someOrFail(exception: Exception): EventStream[Option[T]] = $stream.map {
      case Some(v) => v.some
      case None => throw exception
    }

    def logINFO(l: Logger)(desc: T => String): EventStream[Option[T]] = $stream.tap(_.map(v => l.info(desc(v))))

    def logWARN(l: Logger)(desc: T => String): EventStream[Option[T]] = $stream.tap(_.map(v => l.warn(desc(v))))
  }

  final implicit class SeqEventStreamOps[Col[+t] <: Seq[t], T](private val it: Col[T]) extends AnyVal {
    def $traverse[T2](f: T => EventStream[T2]): EventStream[Seq[T2]] = EventStream.combineSeq(it.map(f))

    def $flatTraverse[T2, T3](f: T => EventStream[T2])
                             (implicit bf: BuildFrom[Col[T], T2, Col[T2]],
                              toIterableOnce: T2 => IterableOnce[T3]): EventStream[Seq[T3]] =
      $traverse(f).map(_.flatten)
  }


  final implicit class SeqSignalOps[Col[+t] <: Seq[t], T](private val it: Col[T]) extends AnyVal {
    def $traverse[T2](f: T => Signal[T2]): Signal[Seq[T2]] = Signal.combineSeq(it.map(f))

    def sFlatTraverse[T2, T3](f: T => Signal[T2])
                             (implicit bf: BuildFrom[Col[T], T2, Col[T2]],
                              toIterableOnce: T2 => IterableOnce[T3]): Signal[Seq[T3]] =
      $traverse(f).map(_.flatten.toSeq)
  }

  implicit final class SignalSeqOps[Col[+t] <: Seq[t], T](private val $signal: Signal[Col[T]]) extends AnyVal {
    def nestedMap[T2](f: T => T2): Signal[Seq[T2]] = $signal.map(_.map(f))

    def semiflatMap[T2](f: T => Signal[T2])(implicit bf: BuildFrom[Col[T], T2, Col[T2]]): Signal[Seq[T2]] =
      $signal.flatMap { t => Signal.combineSeq(t.map(f)) }

    def nestedFlatMap[T2](f: T => Signal[Col[T2]])
                         (implicit bf: BuildFrom[Col[T], Col[T2], Col[Col[T2]]]): Signal[Seq[T2]] =
      $signal.flatMap(s => s.sFlatTraverse(f))(com.raquo.airstream.flatten.FlattenStrategy.SwitchSignalStrategy)


    def $exists(f: T => Boolean): Signal[Boolean] = $signal.map(_.exists(f))
  }

  implicit final class EventStreamSeqOps[Col[+t] <: Seq[t], T](private val $signal: EventStream[Col[T]]) extends AnyVal {
    def nestedMap[T2](f: T => T2): EventStream[Seq[T2]] = $signal.map(_.map(f))

    def semiFlatMap[T2](f: T => EventStream[T2])(implicit bf: BuildFrom[Col[T], T2, Col[T2]]): EventStream[Seq[T2]] =
      $signal.flatMap { t => EventStream.combineSeq(t.map(f)) }

    def nestedFlatMap[T2](f: T => EventStream[Col[T2]])
                         (implicit bf: BuildFrom[Col[T], Col[T2], Col[Col[T2]]], fs: com.raquo.airstream.flatten.SwitchEventStream[T, T2]): EventStream[Seq[T2]] =
      $signal.flatMap(es => es.$flatTraverse(f))(com.raquo.airstream.flatten.FlattenStrategy.SwitchStreamStrategy)


    def $exists(f: T => Boolean): EventStream[Boolean] = $signal.map(_.exists(f))
  }




  final implicit class SignalNestedOps[T, F[+_]](private val $signal: Signal[F[T]]) extends AnyVal {

    def subflatMap[T2](f: T => F[T2])(implicit F: FlatMap[F]): Signal[F[T2]] =
      $signal.map(fa => F.flatMap(fa)(f))

    def nestedMap[T2](f: T => T2)(implicit F: Functor[F]): Signal[F[T2]] =
      $signal.map(fa => F.map(fa)(f))
  }

  final implicit class EventStreamNestedOps[T, F[+_]](private val $stream: EventStream[F[T]]) extends AnyVal {

    def subFlatMap[T2](f: T => F[T2])(implicit F: FlatMap[F]): EventStream[F[T2]] =
      $stream.map(fa => F.flatMap(fa)(f))

    def nestedMap[T2](f: T => T2)(implicit F: Functor[F]): EventStream[F[T2]] =
      $stream.map(fa => F.map(fa)(f))


  }
}
