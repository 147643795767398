package service.portal_state

import cats.implicits.catsSyntaxOptionId
import com.raquo.airstream.core.{EventStream, Signal}
import com.raquo.airstream.eventbus.EventBus
import com.raquo.airstream.state.Var
import common.airstream_ops.SignalOptionOps
import common.{FormModel, PortalAppCapability, PortalAppRegistration, PortalApplication, PortalTeam, PortalUser, ServiceType}
import service.apis.portal_api.TeamApp
import service.exception_handler.{MissingStateVarException, UnknownException}
import wvlet.log.Logger

class PortalState {

  private val log = Logger.of[PortalState]

  private val me: Var[Option[PortalUser]] = Var(None)
  private val app: Var[Option[PortalApplication]] = Var(None)
  private val teamId: Var[Option[Int]] = Var(None)
  private val appRegs: Var[Option[List[PortalAppRegistration]]] = Var(None)
  private val serviceTypeList: Var[List[ServiceType]] = Var(Nil)
  private val capabilityList: Var[List[PortalAppCapability]] = Var(Nil)

  def $me: Signal[PortalUser] = me.signal
    .map(_.getOrElse(throw MissingStateVarException("user")))

  def $app: Signal[PortalApplication] = app.signal
    .map(_.getOrElse(throw MissingStateVarException("application")))

  def $team: Signal[PortalTeam] = $maybeTeam.getOrElse(throw UnknownException(s"team not found"))


  def $teamApp: Signal[TeamApp] = for {
    app <- $app
    team <- $team
  } yield TeamApp(team, app)

  def $maybeMe: Signal[Option[PortalUser]] = me.signal

  def $maybeApp: Signal[Option[PortalApplication]] = app.signal

  def $maybeTeam: Signal[Option[PortalTeam]] = teamId.signal
    .combineWith($maybeMe)
    .map {
      case (Some(tId), Some(me)) => me.teams.find(_.id == tId)
      case _ => None
    }

  def $appRegs: Signal[Option[List[PortalAppRegistration]]] = appRegs.signal

  def $serviceTypes: Signal[List[ServiceType]] = serviceTypeList.signal

  def $capabilities: Signal[List[PortalAppCapability]] = capabilityList.signal

  def updateMe(user: PortalUser): Unit = me.set(Some(user))

  def updateApp(application: Option[PortalApplication]): Unit = app.set(application)

  def updateTeamId(tid: Option[Int]): Unit = teamId.set(tid)

  def updateAppRegs(list: List[PortalAppRegistration]): Unit = appRegs.set(Some(list))

  def resetAppRegs(): Unit = appRegs.set(None)

  def updateServiceTypes(items: List[ServiceType]): Unit = serviceTypeList.set(items)

  def updateCapabilities(items: List[PortalAppCapability]): Unit = capabilityList.set(items)

  def resetState(): Unit = {
    me.set(None)
    teamId.set(None)
    app.set(None)
    appRegs.set(None)
  }

  val FormCache = new FormInputDataCaching()

  object Session {
    private val expireBus = new EventBus[Unit]
    val $sessionExpiredEvents: EventStream[Unit] = expireBus.events

    def onSessionExpired(): Unit = expireBus.emit(())

    val PortalUserAuthInfo = new UserAuthInfo()
  }

  //  object
}

class FormInputDataCaching() {
  private val log = Logger.of[FormInputDataCaching]
  private var formModel: Option[FormModel] = None

  def model: Option[FormModel] = formModel
  def cacheFormModel(model: FormModel): Unit = formModel = model.some

  def resetCache(): Unit = formModel = None

}

class UserAuthInfo() {
  private val username: Var[Option[String]] = Var(None)

  def setUsername(un: Option[String]): Unit = username.set(un)

  def usernameHint: Signal[Option[String]] = username.signal

  def resetUsername(): Unit = username.set(None)
}
