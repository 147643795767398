package root_pages.aurinko_pages.app.organizations

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Fab
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.SignalNestedOps
import common.ui.mat_components_styles.fixFabStyle
import common._
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.ui.text_data_view.ColoredStrings
import common.value_opps.ValueOpps
import org.scalajs.dom.html
import portal_router.{OrganizationPage, OrgsPage, PortalRouter}
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import service.scroll_ops.ScrollOps
import wvlet.log.Logger

class OrganizationsComponent($route: Signal[OrgsPage],
                             portalApi: PortalApi,
                             documentScrollOps: ScrollOps,
                             portalRouter: PortalRouter,
                             portalState: PortalState) {
  val $pageSize: Signal[Int] = $route.map(_.pageSize.getOrElse(portalApi.standardApiPageSize))

  val pageFilters: OrgFilters = OrgFilters()

  val organizations: Var[AurinkoApiPage[Organization]] = Var(AurinkoApiPage[Organization](Nil, 0, done = true, 0))

  val currentPageNum: Signal[Int] = organizations.signal.map(_.offset)
    .combineWith($pageSize)
    .map(t => t._1 / t._2)

  private val log = Logger.of[OrganizationsComponent]

  def requestOrgs(pageNum: Int, limit: Int, appKey: AppKey): EventStream[AurinkoApiPage[Organization]] = portalApi.organizations(
    appKey = appKey,
    limit = limit,
    offset = limit * pageNum,
    orgFilters = pageFilters
  )

  def handleQueryParams(route: OrgsPage): Unit = {
    log.info(s"searchText: ${route.search}")
    pageFilters.searchText.set(route.search.getOrElse(""))
    pageFilters.serviceProvider.set(ServiceProvider.values.find(_.toString == route.serviceProvider.getOrElse("")))
    reloadPageBus.emit(route.pageNum.getOrElse(0) -> route.pageSize.getOrElse(portalApi.standardApiPageSize))
  }

  val reloadPageBus = new EventBus[(Int, Int)]
  val mountBus = new EventBus[Unit]

  val coloredServiceProviders: ColoredStrings.ColoredStringsSet = ColoredStrings(ServiceProvider.list.map(_.toString))

  def renderOrganizationRow(orgId: Int, initialOrg: Organization, $organization: Signal[Organization]): ReactiveHtmlElement[html.Anchor] = {

    val $serviceProviderView = $organization.map(s => coloredServiceProviders.renderFuncForText(s.serviceProvider.toString))
    a(
      cls := "slds-grid table-row clickable primary",

      href <-- $route.map(r => portalRouter.router.absoluteUrlForPage(OrganizationPage(r.appKey, orgId))),

      span(cls := "slds-size--1-of-12 text-bold", child.text <-- $organization.map(_.id)),
      span(cls := "slds-size--2-of-12", child <-- $serviceProviderView.map(_.apply())),
      span(cls := "slds-size--4-of-12", child.text <-- $organization.map(_.xid)),
      span(cls := "slds-size--3-of-12 text-bold", child.text <-- $organization.map(_.name.getOrElse(""))),
      span(cls := "slds-size--2-of-12", child.text <-- $organization.map(_.createdAt.toPrettyLocalFormat)),
    )
  }

  val node: Div = div(
    cls := "headered-footered-section content-padding",

    mountBus.events.withCurrentValueOf($route) --> Observer[OrgsPage](onNext = p => {
      log.info(s"mountBus")
      handleQueryParams(p)
    }),

    pageFilters.searchText.signal
      .combineWith(pageFilters.serviceProvider.signal)
      .withCurrentValueOf($pageSize)
      .changes.map(changes => 0 -> changes._3) --> reloadPageBus.writer,

    reloadPageBus.events.withCurrentValueOf($route).flatMap {
      case (pageNum, limit, route) =>
        portalRouter.router.replaceState(
          OrgsPage(
            appKey = route.appKey,
            pageNum = Some(pageNum),
            pageSize = Some(limit),
            search = Option.when(pageFilters.searchText.now.trim.nonEmpty) {
              pageFilters.searchText.now
            },
            serviceProvider = Option.when(pageFilters.serviceProvider.now.isDefined) {
              pageFilters.serviceProvider.now.get.toString
            }
          )
        )

        requestOrgs(pageNum = pageNum, limit, appKey = route.appKey)
    } --> organizations,

    div(
      cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-end slds-p-bottom--large border-bottom--light",
      p(cls := "caption title--level-1", "Organizations"),
      SearchInputComponent(
        onChange = pageFilters.searchText.writer,
        className = Some("width-small"),
        $value = pageFilters.searchText.signal
      ).node
    ),

    div(
      cls := "slds-grid slds-wrap slds-p-top--large",
      children <-- portalState.$app
        .map(_.capabilities)
        .combineWith(portalState.$serviceTypes)
        .map {
          case (Some(enabledCapabilities), allServiceTypes) if enabledCapabilities.exists(_ != PortalAppCapability.endUserSessions) => // if EndUserSessions is selected and plus one or more capabilities
            // recognize ignored service type
            val ignoredServiceTypes = allServiceTypes.filter(st => st.capabilities.intersect(enabledCapabilities).isEmpty)
            log.info(s"ignored: ${ignoredServiceTypes.map(_.label).mkString(", ")}")
            // ignore ServiceProvider that equals to the ignored ServiceTypes
            ServiceProvider.values.filter(sp => !ignoredServiceTypes.exists(_.value == sp.toString)).toSeq
          case _ =>
            ServiceProvider.values.toSeq
        }
        .nestedMap {
          serviceProvider =>
            Fab(
              _.label := serviceProvider.toString,
              _.extended := true,
              _.showIconAtEnd := true,
              _.icon <-- pageFilters.serviceProvider.signal.map(_.condition(_.contains(serviceProvider), _ => "cancel", _ => "")),
              _ => cls := "slds-m-right--x-small slds-m-bottom--xx-small",
              _ => cls <-- pageFilters.serviceProvider.signal.map(_.condition(_.contains(serviceProvider), _ => "", _ => "inactive")),
              _ => composeEvents(onClick)(_
                .sample(pageFilters.serviceProvider.signal)
                .map(_.condition(_.contains(serviceProvider), _ => None, _ => serviceProvider.some)))
                --> pageFilters.serviceProvider.writer,
              _ => onMountCallback(fixFabStyle)
            )
        },
    ),

    div(
      cls := "body data-table",
      div(
        cls := "slds-grid table-header",
        span(cls := "slds-size--1-of-12", "Id"),
        span(cls := "slds-size--2-of-12", "Service provider"),
        span(cls := "slds-size--4-of-12", "Org id"),
        span(cls := "slds-size--3-of-12", "Name"),
        span(cls := "slds-size--2-of-12", "Created at"),
      ),

      children <-- organizations.signal.map(_.records).split(_.id)(renderOrganizationRow),

      div(
        cls <-- organizations.signal.map(_.totalSize <= portalApi.standardApiPageSize)
          .map { case true => "hidden" case false => "" },
        Paginator(
          pageNum = currentPageNum,
          totalCount = organizations.signal.map(_.totalSize),
          pageSize = $pageSize,
          // TODO: REVIEW: reloadPageBus has observer for this
          onPageChange = Observer[(Int, Int)](onNext = num => reloadPageBus.emit(num._1 -> num._2)),
          documentScrollTopAfterPageChange = true,
          documentScrollOps.some,
          itemsPluralLabel = "Organizations"
        ).node
      ),
      div(
        cls <-- organizations.signal.map(_.totalSize == 0).map { case true => "" case false => "hidden" },
        p(
          cls := "slds-grid slds-grid--align-center gray slds-m-top--large",
          span("No organizations", cls := "gray"),
        )
      ),
    ),
    onMountCallback(_ => mountBus.emit(()))
  )
}
