package root_pages.aurinko_pages.app.sync

import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Button, Checkbox, Dialog, Formfield, Radio, Select, Switch}
import portal_router.SyncUserPage
import com.raquo.laminar.api.L._
import common.{ServiceType, SyncDirection, SyncServiceType, SyncUserSettings}
import org.scalajs.dom
import root_pages.aurinko_pages.app.sync.UserSyncSettings.CalendarTimeRanges.CalendarTimeRanges
import service.apis.sync_api.SyncApi
import wvlet.log.Logger

object UserSyncSettings {
  private val log = Logger.of[UserSyncSettings.type]
  val settingsModel: Var[Boolean] = Var(false)
  val userSettings: Var[Option[SyncUserSettings]] = Var(None)
  val $userSettings = userSettings.signal

  def scrollToSection(sectionId: String): Unit = {
    dom.document.getElementById(sectionId).scrollIntoView()
  }

  def apply(
             $route: Signal[SyncUserPage],
             syncApi: SyncApi,
           ): Dialog.El = {

    val $officeAurinkoAdminMode: Signal[Boolean] = $userSettings.map {
      case Some(model) => model.mailboxTypes
        .forall(_.value.contains(ServiceType.office365.value))
      case _ => false
    }


    Dialog(
      _ => cls := "sync-settings-dialog",
      _.heading := "Sync settings",
      _.open <-- settingsModel.signal,
      _.onClosing.mapTo(false) --> settingsModel.writer,
      _.slots.default(
        div(
          $route
            .flatMap(route => syncApi.syncUserSettings(route.appKey, route.orgId, route.userId)) --> userSettings.writer,
          div(
            cls := "slds-grid",

            div(
              cls := "left-section-dialog title--level-3",
              child.maybe <-- $userSettings.map {
                case Some(settings) if settings.logEmail => Some(div(
                  cls := "border-bottom--light slds-grid slds-grid--align-spread slds-p-right--large slds-p-vertical--large slds-grid--vertical-align-center",
                  span(
                    cls := "menu-item slds-col clickable",
                    "Email",
                    onClick --> Observer[dom.MouseEvent](onNext = _ => scrollToSection("Email"))
                  ),
                  Switch(
                    _.checked <-- $userSettings.map(_.exists(_.logEmail)),
                    _.disabled := true,
                  )
                ))
                case _ => None
              },
              child.maybe <-- $userSettings.map {
                case Some(settings) if settings.syncCalendar => Some(div(
                  cls := "border-bottom--light slds-grid slds-grid--align-spread slds-p-right--large slds-p-vertical--large slds-grid--vertical-align-center",
                  span(
                    cls := "menu-item slds-col clickable",
                    "Calendar",

                    onClick --> Observer[dom.MouseEvent](onNext = _ => {
                      scrollToSection("Calendar")
                      cls := "active"
                    })
                  ),
                  Switch(
                    _.checked <-- $userSettings.map(_.exists(_.syncCalendar)),
                    _.disabled := true,
                  )
                ))
                case _ => None
              },

              child.maybe <-- $userSettings.map {
                case Some(settings) if settings.syncContacts => Some(div(
                  cls := "border-bottom--light slds-grid slds-grid--align-spread slds-p-right--large slds-p-vertical--large slds-grid--vertical-align-center",
                  span(
                    cls := "menu-item slds-col clickable",
                    "Contact",
                    onClick --> Observer[dom.MouseEvent](onNext = _ => scrollToSection("Contact")),
                  ),
                  Switch(
                    _.checked <-- $userSettings.map(_.exists(_.syncContacts)),
                    _.disabled := true,
                  )
                ))
                case _ => None
              },

              child.maybe <-- $userSettings
                .combineWith($officeAurinkoAdminMode)
                .map {
                  case (Some(settings), adminMode) if settings.syncTasks && !adminMode => Some(div(
                    cls := "border-bottom--light slds-grid slds-grid--align-spread slds-p-right--large slds-p-vertical--large slds-grid--vertical-align-center",

                    span(
                      cls := "menu-item slds-col clickable",
                      "Task",
                      onClick --> Observer[dom.MouseEvent](onNext = _ => scrollToSection("Tasks")),
                    ),
                    Switch(
                      _.checked <-- $userSettings.map(_.exists(_.syncTasks)),
                      _.disabled := true,
                    )
                  ))
                  case _ => None
                },
            ),

            div(
              cls := "right-section-dialog",
             // styleAttr := "flex: 1 1 auto",
              // "Email sync settings"
              child.maybe <-- $userSettings.map {
                case Some(settings) if settings.logEmail => Some(div(
                  styleAttr := "scroll-margin-top: 70px",
                  idAttr := "Email",
                  cls := "dialog-scroll-section slds-box slds-m-bottom--large",
                  h2(
                    cls := "title--level-3 slds-m-bottom--large",
                    "Email sync settings"),
                  div(
                    div(
                      cls := "subtitle--level-2 slds-grid slds-wrap slds-grid--vertical-align-center",
                      Formfield(
                        _ => cls := "slds-m-right--large",
                        _.label := "Sync attachments",
                        _.slots.default(Checkbox(
                          _.checked <-- $userSettings.map {
                            case Some(settings) => settings.emailFetchAttachments.contains(true)
                            case _ => false
                          },
                          _.disabled := true

                        )),
                      ),
                      child.maybe <-- $userSettings.map {
                        case Some(settings) if settings.emailRelateAllContacts.contains(true) =>
                          Some(Formfield(
                            _ => cls := "slds-m-right--large",
                            _.label := "Relate to all matching contacts",
                            _.slots.default(Checkbox(
                              _.checked := true,
                              _.disabled := true
                            )),
                          ))
                        case _ => None
                      },
                      child.maybe <-- $userSettings.map {
                        case Some(settings) if settings.emailCreateLeads.contains(true)
                          && settings.emailCreateContacts.contains(false) =>
                          Some(Formfield(
                            _ => cls := "slds-m-right--large",
                            _.label := "Create leads for all external email participants",
                            _.slots.default(Checkbox(
                              _.checked := true,
                              _.disabled := true
                            )),
                          ))
                        case _ => None
                      },
                    ),

                    div(
                      cls := "slds-m-bottom--xx-large",
                      Formfield(
                        _ => cls := "slds-m-right--large",
                        _.label := "Create contacts for all external email participants",
                        _.slots.default(Checkbox(
                          _.checked <-- $userSettings.map {
                            case Some(settings) => settings.emailCreateContacts.contains(true)
                            case _ => false
                          },
                          _.disabled := true,
                        )),
                      ),
                      child.maybe <-- $userSettings.map(_.flatMap(_.emailCreateContacts)).map {
                        case Some(true) => Some(div(
                          cls := "subtitle--level-2 slds-grid slds-wrap slds-grid--vertical-align-center slds-m-bottom--xx-large",
                          Formfield(
                            _ => cls := "slds-m-right--large",
                            _.label := "Require existing account match",
                            _.slots.default(Radio(
                              _.checked <-- $userSettings.map(_.flatMap(_.emailAllowPrivateContacts)).
                                combineWith($userSettings.map(_.flatMap(_.emailCreateCompany)))
                                .map {
                                  case (x, t) => x.contains(false) && t.contains(false)
                                  case _ => false
                                },
                              _.name := "emailCreate",
                              _.disabled := true,
                            )),
                          ),
                          Formfield(
                            _ => cls := "slds-m-right--large",
                            _.label := "Allow creating new accounts",
                            _.slots.default(Radio(
                              _.checked <-- $userSettings.map(_.flatMap(_.emailCreateCompany)).map(_.contains(true)),
                              _.name := "emailCreate",
                              _.disabled := true,
                            )),
                          ),
                          Formfield(
                            _ => cls := "slds-m-right--large",
                            _.label := "Allow creating private contacts",
                            _.slots.default(Radio(
                              _.checked <-- $userSettings.map(_.flatMap(_.emailAllowPrivateContacts)).map(_.contains(true)),
                              _.name := "emailCreate",
                              _.disabled := true,
                            )),
                          )
                        ))
                        case _ => None
                      },
                    ),
                    // domain
                    div(
                      cls := "subtitle--level-2 slds-m-bottom--xx-large",
                      cls <-- $userSettings.map(_.flatMap(_.emailSkipDomains)).map {
                        case Some(domain) if domain.nonEmpty => ""
                        case _ => "slds-grid slds-grid--vertical-align-center"
                      },

                      p(
                        cls <-- $userSettings.map(_.flatMap(_.emailSkipDomains)).map {
                          case Some(domain) if domain.nonEmpty => "slds-m-bottom--medium"
                          case _ => "slds-m-right--medium"
                        },
                        s"Internal domains to prevent logging internal emails to ${settings.portalType.label}:",
                      ),

                      if (settings.emailSkipDomains.nonEmpty) span(
                        cls := "slds-grid slds-wrap",
                        settings.emailSkipDomains
                          .map(tags => tags.split(",")
                            .map(_.trim).filterNot(_.isEmpty)
                            .distinct.toList
                            .map(tag =>
                              span(
                                cls := "slds-m-right--xx-small slds-m-bottom--xx-small tags",
                                tag))))
                      else strong("None")
                    ),

                    //tags
                    div(
                      cls <-- $userSettings.map(_.flatMap(_.emailSkipContactTags)).map {
                        case Some(tags) if tags.nonEmpty => "subtitle--level-2 slds-m-bottom--xx-large"
                        case _ => "hidden"
                      },
                      p(
                        cls <-- $userSettings.map(_.flatMap(_.emailSkipContactTags)).map {
                          case Some(tags) if tags.nonEmpty => "slds-m-bottom--medium"
                          case _ => "slds-m-right--medium"
                        },
                        s"Prevent logging emails to ${settings.portalType.label} contacts tagged with:"
                      ),
                      if (settings.emailSkipContactTags.nonEmpty) span(
                        cls := "slds-grid slds-wrap",
                        settings.emailSkipContactTags
                          .map(tags => tags.split(",")
                            .map(_.trim).filterNot(_.isEmpty)
                            .distinct.toList
                            .map(tag =>
                              span(
                                cls := "slds-m-right--xx-small slds-m-bottom--xx-small tags",
                                tag)))) else strong("None")
                    ),
                  )
                ))
                case _ => None
              },


              // "Calendar sync settings"
              child.maybe <-- $userSettings.map {
                case Some(settings) if settings.syncCalendar => Some(div(
                  cls := "dialog-scroll-section slds-box slds-m-bottom--large",
                  idAttr := "Calendar",
                  h2(
                    cls := "title--level-3 slds-m-bottom--large",
                    "Calendar sync settings"
                  ),

                  div(
                    // direction
                    Select(
                      _ => cls := "subtitle--level-2 slds-m-bottom--xx-large",
                      _.label := "Sync direction",
                      _.disabled := true,
                      _ => SyncDirection.syncDirectionUsers.map(dir => ListItem(
                        _.value := dir.generateLabel(settings.portalType.label),
                        _ => dir.generateLabel(settings.portalType.label),
                        _.selected <-- $userSettings.map {
                          case Some(settings) => settings.calSyncDirection.isDefined && settings.calSyncDirection.get == dir
                          case _ => false
                        },
                      )),
                    ),
                    //time range
                    div(
                      cls := "subtitle--level-2 slds-m-bottom--xx-large",
                      p(
                        cls := "slds-m-vertical--small",
                        "Please indicate time range for which Yoxel Sync application should sync events:",
                      ),
                      div(
                        cls := "slds-grid",
                        Select(
                          _ => cls := "slds-m-right--xx-large",
                          _.label := "Past events",
                          _.disabled := true,
                          _ => children <-- $userSettings.map {
                            case Some(settings) if settings.calPastWeeks.isDefined =>
                              List(
                                ListItem(
                                  _.value := settings.calPastWeeks.get.toString,
                                  _ => s"${settings.calPastWeeks.get} weeks",
                                  _.selected := true
                                )
                              )
                            case _ =>
                              List.empty
                          }

                        ),
                        Select(
                          _ => cls := "input-width--small",
                          _.label := "Future events",
                          _.disabled := true,
                          _ => children <-- $userSettings.map {
                            case Some(settings) if settings.calFutureWeeks.isDefined =>
                              List(
                                ListItem(
                                  _.value := settings.calFutureWeeks.get.toString,
                                  _ => s"${settings.calFutureWeeks.get} weeks",
                                  _.selected := true
                                )
                              )
                            case _ =>
                              List.empty
                          }
                        ),
                      )
                    ),
                    // Salesforce
                    div(
                      cls := "slds-m-bottom--xx-large",
                      div(
                        cls := "slds-p-bottom--medium",
                        cls <-- $userSettings.map(_.flatMap(_.calSyncDirection))
                          .combineWith($userSettings.map(_.flatMap(_.calMailboxSyncTags))).map {
                            case (Some(direction), mailBox) if direction == SyncDirection.BOTH
                              || direction == SyncDirection.TO_PORTAL
                              || direction == SyncDirection.FROM_PORTAL && mailBox.nonEmpty => ""
                            case _ => "hidden"
                          },
                        strong(settings.portalType.label)
                      ),
                      // (direction.get == SyncDirection.BOTH || direction.get == SyncDirection.TO_PORTAL)
                      div(
                        cls <-- $userSettings.map(_.flatMap(_.calSyncDirection)).map {
                          case Some(direction) if direction == SyncDirection.BOTH
                            || direction == SyncDirection.TO_PORTAL => ""
                          case _ => "hidden"
                        },
                        // checkbox
                        div(
                          cls := "subtitle--level-2 slds-grid slds-wrap slds-grid--vertical-align-center",
                         child.maybe <-- $userSettings.map(_.map(_.portalType)).map(!_.contains(SyncServiceType.teamwork)).map{
                           case true => Some(Formfield(
                             _ => cls := "slds-m-right--large",
                             _.label := "Require a CRM record match",
                             _.slots.default(Checkbox(
                               _.checked <-- $userSettings.map(_.flatMap(_.calMatchRequired)).map(_.contains(true)),
                               _.disabled := true
                             )),
                           ))
                           case _ => None
                         },
                          child.maybe <-- $userSettings.map(_.flatMap(_.calRelateAllContacts)).map {
                            case x if x.contains(true) => Some(Formfield(
                              _ => cls := "slds-m-right--large",
                              _.label := "Relate to all matching contacts",
                              _.slots.default(Checkbox(
                                _.checked <-- $userSettings.map(_.flatMap(_.calRelateAllContacts)).map(_.contains(true)),
                                _.disabled := true
                              )),
                            ))
                            case _ => None
                          },
                          child.maybe <-- $userSettings.map(_.map(_.portalType)).map(!_.contains(SyncServiceType.teamwork)).map {
                            case true => Some( Formfield(
                            _ => cls := "slds-m-right--large",
                            _.label := "Convert matched leads to contacts",
                            _.slots.default(Checkbox(
                              _.checked <-- $userSettings.map(_.flatMap(_.calConvertLeads)).map(_.contains(true)),
                              _.disabled := true
                            )),
                          ))
                            case _ => None
                          },
                          child.maybe <-- $userSettings.map(_.map(_.calSyncPrivate)).map {
                            case Some(x) if x.contains(true) => Some(Formfield(
                              _ => cls := "slds-m-right--large",
                              _.label := "Sync private events",
                              _.slots.default(Checkbox(
                                _.checked <-- $userSettings.map(_.flatMap(_.calSyncPrivate)).map(_.contains(true)),
                                _.disabled := true
                              )),
                            ))
                            case _ => None

                          },
                          child.maybe <-- $userSettings.map(_.flatMap(_.calCreateLeads))
                            .combineWith($userSettings.map(_.flatMap(_.calCreateContacts))).map {
                              case (Some(true), Some(false)) => Some(Formfield(
                                _.label := "Create leads for all external attendees",
                                _.slots.default(Checkbox(
                                  _.checked <-- $userSettings.map(_.flatMap(_.calCreateLeads)).map(_.contains(true)),
                                  _.disabled := true
                                )),
                              ))
                              case _ => None
                            },
                        ),

                        // radiobutton
                        child.maybe <-- $userSettings.map(_.map(_.portalType)).map(!_.contains(SyncServiceType.teamwork)).map {
                          case true => Some(div(
                          cls := " slds-m-bottom--xx-large",
                          Formfield(
                            _ => cls := "slds-m-right--large",
                            _.label := "Create contacts for all external attendees",
                            _.slots.default(Checkbox(
                              _.checked <-- $userSettings.map(_.flatMap(_.calCreateContacts)).map(_.contains(true)),
                              _.disabled := true
                            )),
                          ),
                          child.maybe <-- $userSettings.map(_.flatMap(_.calCreateContacts)).map {
                            case Some(true) => Some(
                              div(
                                cls := "subtitle--level-2 slds-grid slds-wrap slds-grid--vertical-align-center slds-m-bottom--xx-large",
                                Formfield(
                                  _ => cls := "slds-m-right--large",
                                  _.label := "Require existing account match",
                                  _.slots.default(Radio(
                                    _.checked <-- $userSettings.map(_.flatMap(_.calCreateCompany))
                                      .combineWith($userSettings.map(_.flatMap(_.calAllowPrivateContacts)))
                                      .map {
                                        case (x, t) => x.contains(false) && t.contains(false)
                                        case _ => false
                                      },
                                    _.name := "calCreate",
                                    _.disabled := true,
                                  )),
                                ),
                                Formfield(
                                  _ => cls := "slds-m-right--large",
                                  _.label := "Allow creating new accounts",
                                  _.slots.default(Radio(
                                    _.checked <-- userSettings.signal.map(_.flatMap(_.calCreateCompany)).map(_.contains(true)),
                                    _.name := "calCreate",
                                    _.disabled := true,
                                  )),
                                ),
                                Formfield(
                                  _ => cls := "slds-m-right--large",
                                  _.label := "Allow creating private contacts",
                                  _.slots.default(Radio(
                                    _.checked <-- $userSettings.map(_.flatMap(_.calAllowPrivateContacts)).map(_.contains(true)),
                                    _.name := "calCreate",
                                    _.disabled := true,
                                  )),
                                ),
                              ),
                            )
                            case _ => None
                          }
                        ))
                          case _ => None
                        },


                        // domains
                        div(
                          cls := "subtitle--level-2 slds-m-bottom--xx-large",

                          p(
                            cls <-- $userSettings.map(_.flatMap(_.calSkipDomains)).map {
                              case domains if domains.nonEmpty && !domains.contains("") => "slds-m-bottom--medium"
                              case _ => "slds-m-right--medium"
                            },
                            s"Internal domains to prevent logging internal emails to ${settings.portalType.label}:"
                          ),

                          if (settings.calSkipDomains.nonEmpty && !settings.calSkipDomains.contains("")) span(
                            cls := "slds-grid slds-wrap",
                            settings.calSkipDomains
                              .map(domain => domain.split(",")
                                .map(_.trim).filterNot(_.isEmpty)
                                .distinct.toList
                                .map(domain =>
                                  span(
                                    cls := "slds-m-right--xx-small slds-m-bottom--xx-small tags",
                                    domain)
                                )))
                          else strong("None")
                        ),
                        // tags
                        div(
                          cls <-- $userSettings.map(_.flatMap(_.calSkipContactTags)).map {
                            case Some(tags) if tags.nonEmpty => "subtitle--level-2 slds-m-bottom--xx-large"
                            case _ => "hidden"
                          },
                          p(
                            cls <-- $userSettings.map(_.flatMap(_.calSkipContactTags)).map {
                              case Some(tags) if tags.nonEmpty => "slds-m-bottom--medium"
                              case _ => "slds-m-right--medium"
                            },
                            s"Prevent logging emails to ${settings.portalType.label} contacts tagged with:"
                          ),
                          child.maybe <-- $userSettings.map {
                            case Some(settings) if settings.calSkipContactTags.nonEmpty =>
                              settings.calSkipContactTags.map(tags => tags
                                .split(",")
                                .map(_.trim)
                                .filterNot(_.isEmpty)
                                .distinct
                                .toList).map(tagsList =>
                                if (tagsList.nonEmpty) span(
                                  cls := "slds-m-right--xx-small slds-m-bottom--xx-small tags",
                                  tagsList)
                                else strong("None")
                              )
                            case _ => Some(strong("None"))
                          }
                        )
                      ),
                    ),

                    // mailboxes
                    div(
                      cls <-- $userSettings.map(_.flatMap(_.calSyncDirection))
                        .combineWith($userSettings.map(_.flatMap(_.calMailboxSyncTags)))
                        .combineWith($userSettings.map(_.map(_.mailboxTypes))).map {
                          case (Some(direction), mailBox, Some(mailboxTypes)) if (direction == SyncDirection.BOTH
                            || direction == SyncDirection.TO_PORTAL
                            || (direction == SyncDirection.FROM_PORTAL && mailBox.nonEmpty)) && mailboxTypes.nonEmpty => ""
                          case _ => "hidden"
                        },
                      div(
                        cls := "slds-p-bottom--medium",
                        child.maybe <-- $userSettings.map(_.map(_.mailboxTypes)).map {
                          case l if l.nonEmpty => Some(strong(
                            l.map(_.map(_.label).mkString(", "))
                          ))
                          case _ => None
                        }
                      ),
                      div(
                        cls := "slds-grid slds-wrap slds-grid--vertical-align-center",
                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Sync all events from the primary calendar",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map(_.flatMap(_.calMailboxSyncAll)).map(_.contains(true)),
                            _.name := "calMailbox",
                            _.disabled := true,
                          )),
                        ),
                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Sync meetings only",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map(_.flatMap(_.calMailboxSyncMeetingsOnly)).map(_.contains(true)),
                            _.name := "calMailbox",
                            _.disabled := true,
                          )),
                        ),
                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Sync tagged events",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map(_.flatMap(_.calMailboxSyncAll))
                              .combineWith($userSettings.map(_.flatMap(_.calMailboxSyncMeetingsOnly)))
                              .combineWith($userSettings.map(_.flatMap(_.calMailboxSyncTags)))
                              .map {
                                case (all, mine, tags) => all.contains(false) && mine.contains(false)
                                case _ => false
                              },
                            _.name := "calMailbox",
                            _.disabled := true,
                          )),
                        ),
                      ),

                      child.maybe <-- $userSettings.map(_.flatMap(_.calMailboxSyncTags))
                        .combineWith($userSettings.map(_.flatMap(_.calMailboxSyncAll)))
                        .combineWith($userSettings.map(_.flatMap(_.calMailboxSyncMeetingsOnly))).map {
                          case (tags, l, m) if tags.nonEmpty && l.contains(false) && m.contains(false) => tags.map(tag =>
                              tag.split(",")
                                .map(_.trim)
                                .filterNot(_.isEmpty)
                                .distinct
                                .toList)
                            .map {
                              case tagsList if tagsList.nonEmpty => span(tagsList.map(
                                tag => span(
                                  cls := "slds-m-right--xx-small slds-m-bottom--xx-small tags",
                                  tag)))
                              case _ => div("No tags")
                            }
                          case (_, l, m) if l.contains(false) && m.contains(false) => Some(div("No tags"))
                          case _ => None
                        },

                      //                      if (settings.calMailboxSyncAll.contains(false) &&
                      //                        settings.calMailboxSyncMeetingsOnly.contains(false) &&
                      //                        settings.calMailboxSyncTags.nonEmpty) span(
                      //                        cls := "slds-grid slds-wrap",
                      //                        settings.calMailboxSyncTags
                      //                          .map(tags => tags.split(",")
                      //                            .map(_.trim).filterNot(_.isEmpty)
                      //                            .distinct.toList
                      //                            .map(tag =>
                      //                              span(
                      //                                cls := "slds-m-right--xx-small slds-m-bottom--xx-small tags",
                      //                                tag)))) else if (settings.calMailboxSyncAll.contains(false) &&
                      //                        settings.calMailboxSyncMeetingsOnly.contains(false) &&
                      //                        settings.calMailboxSyncTags.isEmpty) div("No tags") else None
                    ),
                  )
                ))
                case _ => None
              },

              // "Contact sync settings"
              child.maybe <-- $userSettings.map {
                case Some(settings) if settings.syncContacts => Some(div(
                  cls := "dialog-scroll-section slds-box slds-m-bottom--large",
                  idAttr := "Contact",
                  h2(cls := "title--level-3 slds-m-bottom--large",
                    "Contact sync settings"),
                  // direction
                  div(
                    Select(
                      _ => cls := "subtitle--level-2 slds-m-bottom--xx-large",
                      _.label := "Sync direction",
                      _.disabled := true,
                      _ => SyncDirection.syncDirectionUsers.map(dir => ListItem(
                        _.value := dir.generateLabel(settings.portalType.label),
                        _ => dir.generateLabel(settings.portalType.label),
                        _.selected <-- $userSettings.map {
                          case Some(settings) => settings.contSyncDirection.isDefined && settings.contSyncDirection.get == dir
                          case _ => false
                        },
                      )),
                    )
                  ),

                  div(
                    // SF
                    div(
                      cls := "slds-p-bottom--medium",
                      cls <-- $userSettings.map(_.flatMap(_.contSyncDirection))
                        .map {
                          case Some(direction) if direction == SyncDirection.BOTH
                            || direction == SyncDirection.TO_PORTAL => ""
                          case _ => "hidden"
                        },
                      strong(
                        settings.portalType.label
                      ),
                    ),
                    // radiobutton with tags To_Portal
                    div(
                      cls <-- $userSettings.map(_.flatMap(_.contSyncDirection))
                        .map {
                          case Some(SyncDirection.TO_PORTAL) => "hidden"
                          case _ => "slds-m-bottom--xx-large"
                        },
                      div(
                        cls := "subtitle--level-2 slds-grid slds-wrap slds-grid--vertical-align-center slds-m-bottom--small",

                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Sync all contacts",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map {
                              case Some(settings) => settings.contPortalSyncAll.contains(true)
                              case None => false
                            },
                            _.name := "contSync",
                            _.disabled := true,
                          )),
                        ),
                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Sync my contacts",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map {
                              case Some(settings) => settings.contPortalSyncMine.contains(true)
                              case _ => false
                            },
                            _.name := "contSync",
                            _.disabled := true,
                          )),
                        ),
                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Sync tagged contacts",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map(_.flatMap(_.contPortalSyncAll))
                              .combineWith($userSettings.map(_.flatMap(_.contPortalSyncMine)))
                              .combineWith($userSettings.map(_.flatMap(_.contPortalSyncTags)))
                              .map {
                                case (all, mine, tags) => all.contains(false) && mine.contains(false) || tags.nonEmpty
                                case _ => false
                              },
                            _.name := "contSync",
                            _.disabled := true,
                          )),
                        ),
                      ),
                      if (settings.contPortalSyncTags.nonEmpty) span(
                        cls := "slds-grid slds-wrap",
                        settings.contPortalSyncTags
                          .map(tags => tags.split(",")
                            .map(_.trim).filterNot(_.isEmpty)
                            .distinct.toList
                            .map(tag =>
                              span(
                                cls := "slds-m-right--xx-small slds-m-bottom--xx-small tags",
                                tag)))) else if (settings.contPortalSyncAll.contains(false)
                        && settings.contPortalSyncMine.contains(false)
                        && settings.contPortalSyncTags.isEmpty) div("No tags") else None
                    ),

                    // radiobutton, FROM_Portal
                    div(
                      cls <-- $userSettings.map(_.flatMap(_.contSyncDirection))
                        .map {
                          case Some(SyncDirection.FROM_PORTAL) => "hidden"
                          case _ => "subtitle--level-2 slds-m-bottom--xx-large"
                        },
                      p(cls := "slds-m-bottom--medium",
                        "Account matching mode:"),
                      div(
                        cls := "slds-grid slds-wrap slds-grid--vertical-align-center",
                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Require existing account match",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map {
                              case Some(settings) => settings.contCreateCompany.contains(false) && settings.contAllowPrivateContacts.contains(false)
                              case _ => false
                            },
                            _.name := "accountMode",
                            _.disabled := true,
                          )),
                        ),
                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Allow creating new accounts",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map(_.flatMap(_.contCreateCompany)).map(_.contains(true)),
                            _.name := "accountMode",
                            _.disabled := true,
                          )),
                        ),
                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Allow creating private contacts",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map(_.flatMap(_.contAllowPrivateContacts)).map(_.contains(true)),
                            _.name := "accountMode",
                            _.disabled := true,
                          )),
                        ),
                      )
                    ),

                    // mailboxes
                    div(
                      cls <-- $userSettings.map(_.flatMap(_.contSyncDirection))
                        .combineWith($userSettings.map(_.flatMap(_.contMailboxSyncTags)))
                        .combineWith($userSettings.map(_.map(_.mailboxTypes))).map {
                          case (Some(direction), _, Some(mailboxTypes)) if direction == SyncDirection.BOTH && mailboxTypes.nonEmpty => ""
                          case (Some(direction), Some(mailBox), Some(mailboxTypes))
                            if direction == SyncDirection.FROM_PORTAL && mailBox.nonEmpty && mailboxTypes.nonEmpty => ""
                          case _ => "hidden"
                        },
                      div(
                        cls := "slds-p-bottom--medium",
                        child.maybe <-- $userSettings.map(_.map(_.mailboxTypes)).map {
                          case l if l.nonEmpty => Some(strong(
                            l.map(_.map(_.label).mkString(", "))
                          ))
                          case _ => None
                        }
                      ),
                      div(
                        cls := "slds-grid slds-wrap slds-grid--vertical-align-center",
                        cls <-- $userSettings.map(_.flatMap(_.contPortalSyncTags)).map {
                          case Some(tags) if tags.nonEmpty => "slds-m-bottom--medium"
                          case _ => ""
                        },
                        Formfield(
                          _ => cls := "slds-m-right--large",
                          _.label := "Sync all contacts",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map(_.flatMap(_.contMailboxSyncAll)).map(_.contains(true)),
                            _.name := "contMailbox",
                            _.disabled := true,
                          )),
                        ),
                        Formfield(
                          _.label := "Sync tagged contacts",
                          _.slots.default(Radio(
                            _.checked <-- $userSettings.map(_.flatMap(_.contMailboxSyncTags))
                              .combineWith($userSettings.map(_.flatMap(_.contMailboxSyncAll)))
                              .map {
                                case (tags, l) => l.contains(false)
                                case _ => false
                              },
                            _.name := "contMailbox",
                            _.disabled := true,
                          )),
                        ),
                      ),

                      child.maybe <-- $userSettings.map(_.flatMap(_.contMailboxSyncTags))
                        .combineWith($userSettings.map(_.flatMap(_.contMailboxSyncAll))).map {
                          case (tags, l) if tags.nonEmpty && l.contains(false) => tags.map(tag =>
                              tag.split(",")
                                .map(_.trim)
                                .filterNot(_.isEmpty)
                                .distinct
                                .toList)
                            .map {
                              case tagsList if tagsList.nonEmpty => span(tagsList.map(
                                tag => span(
                                  cls := "slds-m-right--xx-small slds-m-bottom--xx-small tags",
                                  tag)))
                              case _ => div("No tags")
                            }
                          case (_, l) if l.contains(false) => Some(div("No tags"))
                          case _ => None
                        },
                    ),
                  ),
                ))
                case _ => None
              },

              // "Tasks sync settings"
              child.maybe <-- $userSettings.
                combineWith($officeAurinkoAdminMode).map {
                  case (Some(settings), adminMode) if settings.syncTasks && !adminMode => Some(div(
                    idAttr := "Tasks",
                    cls := "dialog-scroll-section slds-box slds-m-bottom--large",
                    h2(cls := "title--level-3 slds-m-bottom--large",
                      "Tasks sync settings"),

                    div(
                      Select(
                        _ => cls := "subtitle--level-2 slds-m-bottom--xx-large",
                        _.label := "Sync direction",
                        _.disabled := true,
                        _ => SyncDirection.syncDirectionUsers.map(dir => ListItem(
                          _.value := dir.generateLabel(settings.portalType.label),
                          _ => dir.generateLabel(settings.portalType.label),
                          _.selected <-- $userSettings.map {
                            case Some(settings) => settings.taskSyncDirection.isDefined && settings.taskSyncDirection.get == dir
                            case _ => false
                          },
                        )),
                      )

                    ),
                    div(
                      cls <-- $userSettings.map {
                        case Some(direction) if direction.taskSyncDirection.isDefined && direction.taskSyncDirection.get != SyncDirection.FROM_PORTAL => ""
                        case _ => "hidden"
                      },
                      // mailboxes
                      div(
                        cls <-- $userSettings.map(_.flatMap(_.taskSyncDirection))
                          .combineWith($userSettings.map(_.map(_.mailboxTypes)))
                          .map {
                            case (Some(direction), Some(mailboxTypes)) if direction != SyncDirection.FROM_PORTAL && mailboxTypes.nonEmpty => ""
                            case _ => "hidden"
                          },
                        div(
                          cls := "slds-p-bottom--medium",
                          child.maybe <-- $userSettings.map(_.map(_.mailboxTypes)).map {
                            case l if l.nonEmpty => Some(strong(
                              l.map(_.map(_.label).mkString(", "))
                            ))
                            case _ => None
                          }
                        ),
                        div(
                          cls := "slds-grid slds-wrap slds-grid--vertical-align-center",
                          cls <-- $userSettings.map(_.flatMap(_.taskMailboxSyncTags)).map {
                            case Some(tags) if tags.nonEmpty => "slds-m-bottom--medium"
                            case _ => ""
                          },
                          Formfield(
                            _ => cls := "slds-m-right--large",
                            _.label := "Sync all tasks",
                            _.slots.default(Radio(
                              _.checked <-- $userSettings.map(_.flatMap(_.taskMailboxSyncAll)).map(_.contains(true)),
                              _.name := "taskMailbox",
                              _.disabled := true,
                            )),
                          ),
                          Formfield(
                            _.label := "Sync tagged tasks",
                            _.slots.default(Radio(
                              _.checked <-- userSettings.signal
                                .map {
                                  case Some(settings) => settings.taskMailboxSyncAll.contains(false)
                                  case _ => false
                                },
                              _.name := "taskMailbox",
                              _.disabled := true,
                            )),
                          ),
                        ),

                        child.maybe <-- $userSettings.map(_.flatMap(_.taskMailboxSyncTags))
                          .combineWith($userSettings.map(_.flatMap(_.taskMailboxSyncAll))).map {
                            case (tags, l) if tags.nonEmpty && l.contains(false) => tags.map(tag =>
                                tag.split(",")
                                  .map(_.trim)
                                  .filterNot(_.isEmpty)
                                  .distinct
                                  .toList)
                              .map {
                                case tagsList if tagsList.nonEmpty => span(tagsList.map(
                                  tag => span(
                                    cls := "slds-m-right--xx-small slds-m-bottom--xx-small tags",
                                    tag)))
                                case _ => div("No tags")
                              }
                            case (_, l) if l.contains(false) => Some(div("No tags"))
                            case _ => None
                          },
                      ),
                    ),
                  ))
                  case _ => None
                },
            )
          )
        )),

      _.slots.secondaryAction(
        Button(
          _.label := "Close",
          _.outlined := true,
          _ => cls := "slds-m-left--small secondary",
          _ => onClick.mapTo(false) --> settingsModel,
        )
      )
    )
  }

  object CalendarTimeRanges extends Enumeration {
    case class Val(value: Int, label: String) extends super.Val(value.toString)

    type CalendarTimeRanges = Val

    val oneMonth: CalendarTimeRanges = Val(4, "1 month")
    val threeMonths: CalendarTimeRanges = Val(12, "3 months")
    val sixMonths: CalendarTimeRanges = Val(25, "6 months")
    val oneYear: CalendarTimeRanges = Val(52, "1 year")
    val oneAndHalfYear: CalendarTimeRanges = Val(80, "1,5 years")
    val twoYears: CalendarTimeRanges = Val(105, "2 years")

    val pastWeeks: List[CalendarTimeRanges] = oneMonth :: threeMonths :: sixMonths :: Nil
    val futureWeeks: List[CalendarTimeRanges] = sixMonths :: oneYear :: oneAndHalfYear :: twoYears :: Nil

    def pastWeeksFromInt(n: Option[Int]): Option[CalendarTimeRanges] = n match {
      case Some(numb) => Some(pastWeeks.reduce((range, element) => if ((numb - element.value).abs < (numb - range.value).abs) element else range))
      case None => None
    }

    def futureWeeksFromInt(n: Option[Int]): Option[CalendarTimeRanges] = n match {
      case Some(numb) => Some(futureWeeks.reduce((range, element) => if ((numb - element.value).abs < (numb - range.value).abs) element else range))
      case None => None
    }
  }


  implicit final class CalendarRangeOpps(private val int: Option[Int]) extends AnyVal {
    def toPastWeeks: Option[CalendarTimeRanges] = CalendarTimeRanges.pastWeeksFromInt(int)

    def toFutureWeeks: Option[CalendarTimeRanges] = CalendarTimeRanges.futureWeeksFromInt(int)
  }
}
