package root_pages.aurinko_pages.app.organizations

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Dialog, Icon, Textfield}
import com.raquo.airstream.eventbus.EventBus
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.{ReactiveElement, ReactiveHtmlElement}
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.forms.FormsLocalExceptionHandling
import common.{AppKey, AurinkoApiPage, EndUser, FormModel, InstantOps, Organization, OrganizationEditModel, StoreType, TeamMemberRole, UserAuthType, UserFilters}
import common.forms.TextfieldFormOps
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.au_storage_view.StorageComponent
import common.ui.breadcrumbs.{BreadcrumbsComponent, BreadcrumbsItem}
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.ui.element_binders.DialogModifying
import org.scalajs.dom
import org.scalajs.dom.html
import portal_router.{OrganizationPage, OrgsPage, PortalRouter, UserPage}
import service.apis.portal_api.PortalApi
import service.portal_state.{PortalState, PortalUserAccess, TeamMemberAccess}
import service.scroll_ops.ScrollOps

class OrganizationComponent(
                             $route: Signal[OrganizationPage],
                             portalApi: PortalApi,
                             portalState: PortalState,
                             portalRouter: PortalRouter,
                             documentScrollOps: ScrollOps
                           ) {
  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)

  private val userReloadPageBus = new EventBus[(Int, Int)]

  private val userPageSize: Int = 15

  val $pageSize: Signal[Int] = $route.map(_.pageSize.getOrElse(userPageSize))

  private val userPageFilters: UserFilters = UserFilters()

  private val users: Var[AurinkoApiPage[EndUser]] = Var(AurinkoApiPage[EndUser](Nil, 0, done = true, 0))

  val currentPageNum: Signal[Int] = users.signal.map(_.offset)
    .combineWith($pageSize)
    .map(t => t._1 / t._2)

  private def getUsersPage(pageNum: Int, limit: Int, appKey: AppKey, orgId: Int): EventStream[AurinkoApiPage[EndUser]] = {
    portalApi.users(
      appKey = appKey,
      limit = limit,
      offset = limit * pageNum,
      userFilters = userPageFilters
    )
  }

  private def renderUserRow(userId: String, @annotation.unused initialUser: EndUser, $user: Signal[EndUser]): ReactiveHtmlElement[html.Anchor] = {
    a(
      cls := "slds-grid table-row clickable primary",
      href <-- $route.map(r => portalRouter.router.absoluteUrlForPage(UserPage(r.appKey, userId))),
      span(cls := "slds-size--1-of-5 au-truncate  slds-m-right--xx-medium", child.text <-- $user.map(_.id)),
      span(cls := "slds-size--1-of-5 text-bold", child.text <-- $user.map(_.name.getOrElse(""))),
      span(cls := "slds-size--1-of-5", child.text <-- $user.map(_.email.getOrElse(""))),
      i(cls := "slds-size--1-of-5 text-light", child.text <-- $user.map(_.externalIdType.getOrElse(UserAuthType.cookieOrHeader).label)),
      span(cls := "slds-size--1-of-5", child.text <-- $user.map(_.lastActivity).map(_.map(_.toPrettyLocalFormat).getOrElse("")))
    )
  }

  val modelBus: EventBus[Organization] = new EventBus[Organization]

  val editModel: Var[Option[OrganizationEditModel]] = Var(None)

  def editPopup(organization: Organization): Dialog.El = {

    Dialog(
      _.open <-- editModel.signal.map(_.isDefined),
      _.onClosing.mapTo(None) --> editModel,
      _.heading <-- editModel.signal.map(o => if (o.isDefined) s"${o.get.source.serviceProvider} organization" else ""),

      _ => child.maybe <-- editModel.signal.nestedMap { model =>

        div(

          cls := "slds-grid slds-grid--vertical gap--large",

          FormsLocalExceptionHandling.errorView(model.formError.signal),

          Textfield(
            _.outlined := true,
            _.label := "Name",
            _.value <-- model.name,
            _ => onInput.mapToValue --> model.name
          ).bindToForm(model.formState),

          Textfield(
            _.outlined := true,
            _.label := "Domain",
            _.value <-- model.domain,
            _ => onInput.mapToValue --> model.domain
          ).bindToForm(model.formState)
        )
      },
      _.slots.primaryAction(
        ButtonsPairComponent[Organization, dom.MouseEvent](
          primaryDisabled = editModel.signal
            .flatMap(_.$traverse(_.formState.$submitAllowed))
            .map(!_.contains(true)),

          secondaryObserver = editModel.writer.contramap((_: dom.MouseEvent) => None),
          primaryEffect = () => ()
            .streamed
            .sample(editModel.signal)
            .collect { case Some(model) => model }
            .withCurrentValueOf($route)
            .flatMap { case (model, route) => portalApi.updateOrganization(route.appKey, route.orgId, model) }
            .withErrorHandlingAndCollect(
              FormsLocalExceptionHandling
                .handler(str => editModel.now.foreach(_.formError.set(str.some)))),

          primaryObserver = Observer[Organization](onNext = o => {
            modelBus.emit(o)
            editModel.set(None)
          })
        ).node,
      ),
    )
      .withPing(portalApi)
      .withFormCaching(
        portalState.FormCache,
        portalState.Session.$sessionExpiredEvents,
        editModel,
        organization.toEditModel.signaled,
        (m: OrganizationEditModel) => editModel.now.foreach(_.update(m)),
        (cashedForm: FormModel) => cashedForm match {
          case org: OrganizationEditModel => org.some
          case _ => None
        },
        () => editModel.now.foreach(_.formState.validate())
      )
  }

  val eventsBinders: List[Modifier[Div]] = List(
    $route.map(_.orgId).map(_.some) --> userPageFilters.orgId,

    $route.map(_.orgId)
      .withCurrentValueOf(portalState.$teamApp.map(_.appKey))
      .flatMap(t => portalApi.organization(t._2, t._1)) --> modelBus.writer,

    userPageFilters.searchText.signal
      .withCurrentValueOf($pageSize)
      .changes.map(changes => 0 -> changes._2) --> userReloadPageBus.writer,

    modelBus.events
      .combineWith(userReloadPageBus.events)
      .withCurrentValueOf($route).flatMap {
      case (organization, pageNum, limit, route) => getUsersPage(
        pageNum = pageNum,
        limit = limit,
        appKey = route.appKey,
        orgId = organization.id)
    } --> users,

    onMountCallback((_: MountContext[Div]) => {
      userReloadPageBus.emit(0 -> userPageSize)
    })

  )

  val node: Div = div(
    cls := "x-nested-page",

    div(
      cls := "nav-filters-container",
      BreadcrumbsComponent(
        BreadcrumbsItem("Organizations".signaled,
          $route.map(r => portalRouter.router.absoluteUrlForPage(OrgsPage(r.appKey))).some),

        BreadcrumbsItem(modelBus.events.map(_.name).startWith(None).getOrElse("Organization"), None)
      ))
      .amend(cls <-- documentScrollOps.$scrolled
        .map { case true => "shadow" case _ => "" }),

    child <-- modelBus.events.map(organization => div(
      cls := "content-padding",
      editPopup(organization),
      div(
        cls := "left-section",
        paddingTop <-- documentScrollOps.$scrollTop.changes
          .debounce(0).map(i => s"calc(var(--au-header-height) - ${i}px)"),

        div(
          cls := "left-section-header",
          div(
            cls := "slds-grid slds-grid--align-spread",
            div(
              cls := "slds-m-bottom--medium",
              p(s"${organization.serviceProvider} organization", cls := "title--level-1")
            ),
            child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
              .andThen(Icon(
              _ => cls := "light medium clickable slds-m-top--x-small material-icons-outlined",
              _ => "edit",
              _ => composeEvents(onClick)(_.mapTo(organization.toEditModel).map(Some(_))) --> editModel
            )
            )
          )
        ),
        div(
          cls := "content-container",
          div(
            cls := "content",
            div(
              cls := "border-top--light border-bottom--light",


              div(
                //                cls := "slds-grid slds-grid_vertical-align-center slds-m-vertical--medium",
                cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                small(cls := "gray", "Id"),
                span(organization.id),
              ),

              if (organization.name.getOrElse("").nonEmpty) div(
                //                cls := "slds-grid slds-grid_vertical-align-center slds-m-vertical--medium",
                cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                small(cls := "gray", "Name"),
                span(organization.name),
              ) else None,

              div(
                //                cls := "slds-grid slds-grid_vertical-align-center slds-m-vertical--medium",
                cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                small(cls := "gray", "Org id"),
                span(organization.xid)
              ),

              if (organization.domain.getOrElse("").nonEmpty) div(
                //                cls := "slds-grid slds-grid_vertical-align-center slds-m-vertical--medium",
                cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                small(cls := "slds-size--1-of-3 gray", "Domain"),
                span(organization.domain),
              ) else None,

              div(
                //                cls := "slds-grid slds-grid_vertical-align-center slds-m-vertical--medium",
                cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                small(cls := "gray", "Created at"),
                span(organization.createdAt.toPrettyLocalFormat),
              ),
            )
          )
        )
      ),

      div(
        cls := "right-section slds-m-top--large",
        // users
        div(
          div(
            cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread",

            div(
              cls := "slds-grid slds-grid--vertical-align-end slds-m-top_xx-large slds-m-bottom_medium",
              span(cls := "title--level-3", "Users")
            ),

            SearchInputComponent(onChange = userPageFilters.searchText.writer).node,
          ),

          child.maybe <-- users.signal.map(_.records.nonEmpty).map {
            case true => Some(div(
              cls := "data-table",
              div(
                cls := "table-header",
                span(cls := "slds-size--1-of-5 au-truncate slds-m-right--xx-medium", "Id"),
                span(cls := "slds-size--1-of-5", "Name"),
                span(cls := "slds-size--1-of-5", "Email"),
                span(cls := "slds-size--1-of-5", "Authorization type"),
                span(cls := "slds-size--1-of-5", "Last activity"),
              ),

              children <-- users.signal.map(_.records)
                .split(_.id)(renderUserRow),

              div(
                cls <-- users.signal.map(_.totalSize <= userPageSize)
                  .map { case true => "hidden" case false => "" },

                Paginator(
                  pageNum = currentPageNum,
                  totalCount = users.signal.map(_.totalSize),
                  pageSize = $pageSize,
                  onPageChange = Observer[(Int, Int)](onNext = num => userReloadPageBus.emit(num._1 -> num._2)),
                  documentScrollTopAfterPageChange = false,
                  None,
                  itemsPluralLabel = "Users"
                ).node
              )
            ))
            case _ => Some(div(cls := "slds-size--1-of-1 gray slds-grid slds-grid--align-center", "No users"))
          },
        ),
        // properties
        child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen(
          div(
            cls := "slds-m-top_xx-large",
            child <-- $route.map { route =>
              StorageComponent(
                appKey = route.appKey,
                instId = organization.id.toString,
                storeType = StoreType.organization,
                portalApi = portalApi,
                $sessionExpiredEvents = portalState.Session.$sessionExpiredEvents,
                teamMemberAccess.minRoleCheck(TeamMemberRole.developer)
              ).node
            },
          )
        ),
      ),
    )),
  ).amend(
    eventsBinders
  )
}
