package common.ui.breadcrumbs

import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom.html


object BreadcrumbsComponent {

  def apply(items: BreadcrumbsItem*): ReactiveHtmlElement[html.Div] = {
    div(
      cls := "breadcrumbs-container",
      items.map(bi =>
        a(
          cls := "slds-m-right--x-small",
          cls.toggle("light non-clickable")(bi.reference.isEmpty),
          child.text <-- bi.label,
          bi.reference.map(r => href <-- r)
        ) ::
          Option.when(items.indexOf(bi) < items.length - 1) {
            img(
              cls := "slds-m-right--x-small",
              src := "Vector_80.svg",
              height := "20px",
              flexShrink := "0"
            )
          }.toList
      )
    )
  }
}

case class BreadcrumbsItem(label: Signal[String], reference: Option[Signal[String]])
