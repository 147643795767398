package root_pages.aurinko_pages.app.dashboard

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.{AccountsStats, AppKey, AurinkoApiPage, InstantOps, TeamMemberRole, Usage}
import common.airstream_ops.{SignalOps, ValueToObservableOps}
import common.ui.grid_configs.{GridConfig4, GridConfig5}
import portal_router.{DashboardPage, PortalRouter}
import common.ui.notifications.AppTodosComponent
import org.scalajs.dom.html
import root_pages.aurinko_pages.main.AppInfoRowComponent
import service.apis.portal_api.PortalApi
import service.aurinko_api.AurinkoApi
import service.clipboard_service.ClipboardService
import service.local_storage.LocalStorageService
import service.portal_state.{PortalState, PortalUserAccess, TeamMemberAccess}
import wvlet.log.Logger

import java.time.Instant
import java.time.temporal.ChronoUnit

class DashboardPageComponent($route: Signal[DashboardPage],
                             portalApi: PortalApi,
                             aurinkoApi: AurinkoApi,
                             clipboardService: ClipboardService,
                             localStorageService: LocalStorageService,
                             portalRouter: PortalRouter,
                             portalState: PortalState,
                             apiOrigin: String
                            ) {

  private val log = Logger.of[DashboardPageComponent]

  private val $stats: Signal[AccountsStats] = $route.flatMap(r => portalApi.accountStats(r.appKey).startWith(AccountsStats()))
  val usageData: Var[List[Usage]] = Var(Nil)
  private val AppUsage = new AppUsageComponent($route = $route, usageData.signal, portalApi, portalRouter)

  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)
  private val portalUserAccess = new PortalUserAccess(portalState.$me)
  private val $team = portalState.$team

  val Todos = new AppTodosComponent(
    portalApi,
    aurinkoApi,
    localStorageService,
    portalRouter,
    portalState,
    apiOrigin)


  private def AppKeysComponent: ReactiveHtmlElement[html.Div] = div(
    cls := "data-table",
    AppInfoRowComponent.AppInfo(
      $route.map(_.appKey),
      portalState.$app,
      teamMemberAccess,
      portalUserAccess,
      portalApi,
      clipboardService,
      withSecretsLabels = true,
      tableGridConfigs = $team.map {
        case team if team.role.isAdmin => GridConfig5((4, 3, 3, 3, 3), 16)
        case team if team.role == TeamMemberRole.developer => GridConfig5((4, 4, 4, 4, 0), 16)
        case _ => GridConfig5((4, 12, 0, 0, 0), 16)
      },
      usage = usageData.signal.combineWith(portalState.$app).map {
        case (usages, app) => usages.find(_.key.contains(app.name))
      }
    ),
    AppInfoRowComponent.RegenerateSigningConfirmation(portalApi)
  )

  val node: Div = div(
    $route
      .withCurrentValueOf($team)
      .flatMap {
        case (route, team) if team.role.isAdmin => portalApi.appsUsage(route.appKey.teamId)
        case _ => EventStream.empty
      }
      --> usageData.writer,

    cls := "content-padding slds-m-top--medium",

    p(
      cls := "title--level-1 slds-m-bottom--medium",
      "Dashboard"
    ),
    AppKeysComponent,

    child.maybe <-- Todos.$todoElements.mapOptWhenTrue(_.nonEmpty)(
      p(
        cls := "title--level-2 slds-m-top--medium slds-m-bottom--medium",
        "Notifications"
      )),
    div(
      cls := "slds-m-bottom--xx-large",
      Todos.renderElements
    ),

    AppUsage.node,

    child <-- portalState.$teamApp.map { st =>
      new AccountsStatsComponent(st.appKey, $stats, portalRouter).node
        .amend(cls := "slds-m-top--medium")
    },
  )
}
