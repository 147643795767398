package root_pages.aurinko_pages.app.users

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.{ReactiveElement, ReactiveHtmlElement}
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.{AppKey, AurinkoApiPage, EndUser, InstantOps, UserAuthType, UserFilters}
import org.scalajs.dom.html
import portal_router.{PortalRouter, UserPage, UsersPage}
import service.apis.portal_api.PortalApi
import service.scroll_ops.ScrollOps

class EndUsersComponent($route: Signal[UsersPage],
                        portalApi: PortalApi,
                        documentScrollOps: ScrollOps,
                        portalRouter: PortalRouter) {
  //  private val log = Logger.of[EndUsersComponent]

  val $pageSize: Signal[Int] = $route.map(_.pageSize.getOrElse((portalApi.standardApiPageSize)))

  val pageFilters: UserFilters = UserFilters()

  val users: Var[AurinkoApiPage[EndUser]] = Var(AurinkoApiPage[EndUser](Nil, 0, done = true, 0))

  val currentPageNum: Signal[Int] = users.signal.map(_.offset)
    .combineWith($pageSize)
    .map(elem => elem._1 / elem._2)

  def requestUsers(pageNum: Int, limit: Int, appKey: AppKey): EventStream[AurinkoApiPage[EndUser]] = portalApi.users(
    appKey = appKey,
    limit = limit,
    offset = limit * pageNum,
    userFilters = pageFilters
  )

  def handleQueryParams(route: UsersPage): Unit = {
    pageFilters.searchText.set(route.search.getOrElse(""))
    reloadPageBus.emit(route.pageNum.getOrElse(0) -> route.pageSize.getOrElse(portalApi.standardApiPageSize))
  }

  val reloadPageBus = new EventBus[(Int, Int)]
  val mountBus = new EventBus[Unit]

  def renderUserRow(userId: String, @annotation.unused initialUser: EndUser, $user: Signal[EndUser]): ReactiveHtmlElement[html.Anchor] = {
    a(
      cls := "slds-grid table-row clickable primary",

//      composeEvents(onClick)(_.sample($route)) --> Observer[UsersPage](onNext = route =>
//        portalRouter.navigate(UserPage(route.appKey, userId))),

      href <-- $route.map(r => portalRouter.router.absoluteUrlForPage(UserPage(r.appKey, userId))),

      span(cls := "slds-size--2-of-12 slds-m-right--small text-bolder", child.text <-- $user.map(_.id)),
      span(cls := "slds-size--2-of-12 slds-m-right--small", child.text <-- $user.map(_.email.getOrElse(""))),
      span(cls := "slds-size--2-of-12 slds-m-right--small text-bolder", child.text <-- $user.map(_.name.getOrElse(""))),
      span(cls := "slds-size--2-of-12 slds-m-right--small", child.text <-- $user.map(_.createdAt.toPrettyLocalFormat)),
      i(cls := "slds-size--2-of-12 slds-m-right--small text-light", child.text <-- $user.map(_.externalIdType.getOrElse(UserAuthType.cookieOrHeader).label)),
      span(cls := "slds-size--2-of-12 slds-m-right--small text-bolder", child.text <-- $user.map(_.lastActivity).map(_.map(_.toPrettyLocalFormat).getOrElse(""))),
    )
  }

  val eventsBinders: List[Binder[ReactiveElement.Base]] = List(

    mountBus.events.withCurrentValueOf($route) --> Observer[UsersPage](onNext = handleQueryParams),

    pageFilters.searchText.signal
      .withCurrentValueOf($pageSize)
      .changes.map(changes => 0 -> changes._2) --> reloadPageBus.writer,

    reloadPageBus.events.withCurrentValueOf($route).flatMap {
      case (pageNum, pageSize, route) =>
        portalRouter.router.replaceState(
          UsersPage(
            appKey = route.appKey,
            pageNum = Some(pageNum),
            pageSize = Some(pageSize),
            search = Option.when(pageFilters.searchText.now.trim.nonEmpty) {
              pageFilters.searchText.now
            }
          )
        )

        requestUsers(pageNum, pageSize, route.appKey)
    } --> users
  )

  val node: Div = div(
    cls := "content-padding",

    div(
      cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center slds-p-bottom--medium",
      p(cls := "caption title--level-1", "Users"),

      SearchInputComponent(
        onChange = pageFilters.searchText.writer,
        $value = pageFilters.searchText.signal
      ).node,
    ),
    div(
      cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-end",
    ),

    div(
      cls := "data-table",
      div(
        cls := "slds-grid table-header",
        span(cls := "slds-size--2-of-12 slds-m-right--small au-truncate", "Id"),
        span(cls := "slds-size--2-of-12 slds-m-right--small au-truncate", "Email"),
        span(cls := "slds-size--2-of-12 slds-m-right--small au-truncate", "Name"),
        span(cls := "slds-size--2-of-12 slds-m-right--small au-truncate", "Created at"),
        span(cls := "slds-size--2-of-12 slds-m-right--small au-truncate", "Authorization type"),
        span(cls := "slds-size--2-of-12 slds-m-right--small au-truncate", "Last activity"),
      ),

      children <-- users.signal.map(_.records).split(_.id)(renderUserRow),

      div(
        cls <-- users.signal.map(_.totalSize <= portalApi.standardApiPageSize)
//          .combineWith($pageSize)
//          .map(p => p._1 <= p._2)
          .map { case true => "hidden" case false => "" },
        Paginator(
          pageNum = currentPageNum,
          totalCount = users.signal.map(_.totalSize),
          pageSize = $pageSize,
          onPageChange = Observer[(Int, Int)](onNext = num => {
            reloadPageBus.emit(num._1 -> num._2)
          }), // TODO: REVIEW: reloadPageBus has observer for this
          documentScrollTopAfterPageChange = true,
          documentScrollOps.some,
          itemsPluralLabel = "Users",
        ).node
      ),
        div(
        cls <-- users.signal.map(_.totalSize == 0).map { case true => "" case false => "hidden" },
        p(
          cls := "slds-grid slds-grid--align-center gray slds-m-top--large",
          span("No users", cls := "gray"),
        )
      ),
    )
  )
    .amend(
      eventsBinders,
      onMountCallback(_ => mountBus.emit(()))
    )
}
