package root_pages.aurinko_pages.create_app

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Checkbox, Formfield, Select, Textfield}
import com.raquo.laminar.api.L._
import common.{AppKey, FormModel, PortalAppCapability, PortalAppType, PortalApplication, PortalTeam, PortalUser, nameFieldPattern}
import common.PortalAppCapability.apiMailbox
import common.PortalAppType.PortalAppType
import common.airstream_ops.{SignalNestedOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.ui.buttons_pair.ButtonsPairComponent
import org.scalajs.dom
import org.scalajs.dom.window
import portal_router.{CreateAppPage, DashboardPage, PortalRouter}
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import wvlet.log.Logger


class CreateAppPageComponent($route: Signal[CreateAppPage],
                             portalApi: PortalApi,
                             portalRouter: PortalRouter,
                             portalState: PortalState) {


  private val log = Logger.of[CreateAppPageComponent]
  private val newApp = portalState.FormCache.model match {
    case Some(m: NewApplication) => m
    case _ => NewApplication()
  }

  private val $adminTeams = portalState.$me
    .map(_.teams).map(_
      .filter(_.role.isAdmin))

  private val validationNameIsExists: Signal[(Boolean, String)] = newApp.name.signal.combineWith(newApp.team.signal).map(t => {
    val r = t._2.isDefined && t._2.get.apps.exists(_.exists(_.name.trim == t._1.trim))
    (r, if (r) s"${t._1} app exists in the ${t._2.get.name}" else " ") // space instead of empty string gor helper to prevent helper disappearing and height jumping
  })


  private def createAppAndGetMe: EventStream[(PortalApplication, PortalUser)] = for {
    t1 <- portalApi.createApp(
      name = newApp.name.now,
      teamId = newApp.team.now.get.id,
      appType = newApp.applicationType.now.get,
      capabilities =
        if (newApp.capabilities.now.contains(PortalAppCapability.scheduler)) newApp.capabilities.now + PortalAppCapability.apiMailbox
        else newApp.capabilities.now
    )
    t2 <- portalApi.getMe
  } yield (t1, t2)

  private val eventSubscriptions = {
    ().streamed --> Observer[Unit](_ => portalState.FormCache.resetCache()) ::
      portalState.Session.$sessionExpiredEvents.mapTo(newApp) -->
        Observer[NewApplication](appModel => portalState.FormCache.cacheFormModel(appModel)) ::
      $adminTeams.map {
        case list if list.length == 1 => list.headOption
        case _ => None
      } --> newApp.team ::
      $route.map(_.teamId)
        .semiflatMap(tid => $adminTeams.map(_.find(_.id == tid)))
        .map(_.flatten) --> newApp.team ::
      Nil
  }

  val node: Div = div(
    cls := "content-wrapper",
    div(
      cls := "slds-grid  slds-grid--vertical slds-grid--vertical-align-center slds-p-top--xx-large",
      p(
        cls := "slds-p-bottom--large title--level-1",
        "Create new application"),
      Textfield(
        _ => cls := "width-large slds-p-bottom--medium",
        _.outlined := true,
        _ => cls <-- validationNameIsExists.map(_._1).map(if (_) "with-error" else ""),
        _.label := "Name",
        _.required := true,
        _.pattern := nameFieldPattern,
        _.value <-- newApp.name,
        _ => onInput.mapToValue --> newApp.name.writer,
        _.helper <-- validationNameIsExists.map(_._2),
        _.helperPersistent := true,
      ),
      child.maybe <-- $adminTeams.mapOptWhenTrue(_.length > 1) {
        Select(
          _ => cls := "width-large slds-p-bottom--medium",
          _.outlined := true,
          _.label := "Team",
          _.required := true,
          _ => children <-- $adminTeams.nestedMap(t =>
            ListItem(
              _.value := t.name,
              _ => span(t.name),
              _.selected <-- newApp.team.signal.map(team => team.isDefined && team.get == t),
              _ => onClick.mapTo(Some(t)) --> newApp.team.writer
            )
          )
        )
      },
      div(
        cls := "slds-p-bottom--large slds-m-top--x-large",
        p("Please check categories of API/features that your app will use.")
      ),
      div(
        cls := "slds-grid",
        children <-- portalState.$capabilities
          .map(_.map {
            capability =>
              div(
                cls := "slds-m-right--x-large slds-grid slds-grid--vertical-align-center",
                Formfield(
                  _.label := capability.label,

                  _.slots.default(
                    Checkbox(
                      _.checked <-- (capability match {
                        case PortalAppCapability.apiMailbox => newApp.capabilities.signal.map(l => l.contains(capability) || l.contains(PortalAppCapability.scheduler))
                        case _ => newApp.capabilities.signal.map(_.contains(capability))
                      }),

                      _.disabled <-- newApp.applicationType.signal.map(_.getOrElse(PortalAppType.apiOnly).defaultCapabilities.contains(capability))
                        .combineWith(capability match {
                          case PortalAppCapability.apiMailbox => newApp.capabilities.signal.map(_.contains(PortalAppCapability.scheduler))
                          case _ => Signal.fromValue(false)
                        })
                        .map(t => t._1 || t._2),

                      _.onChange.mapToChecked.map {
                        case true => newApp.capabilities.now() + capability
                        case _ => newApp.capabilities.now() - capability
                      } --> newApp.capabilities,
                    )
                  )
                )
              )
          })
      ),
      ButtonsPairComponent[(PortalApplication, PortalUser), dom.MouseEvent](
        cssClass = "slds-grid slds-grid--align-center slds-m-top--x-large",

        primaryDisabled = newApp.name.signal
          .combineWith(newApp.team.signal)
          .combineWith(validationNameIsExists.map(_._1))
          .combineWith(newApp.capabilities.signal)
          .map(t => t._1.trim.isEmpty || t._2.isEmpty || t._3 || t._4.isEmpty),

        primaryEffect = () => createAppAndGetMe,

        primaryObserver = Observer[(PortalApplication, PortalUser)](onNext = t => {
          portalState.updateMe(t._2)
          portalRouter.navigate(
            DashboardPage(AppKey(newApp.team.now.get.id, t._1.id), true.some)
          )
        }),

        secondaryObserver = Observer[dom.MouseEvent](_ => window.history.back())

      ).node
    )
  ).amend(
    eventSubscriptions
  )
}

case class NewApplication(
                           name: Var[String] = Var(""),
                           applicationType: Var[Option[PortalAppType]] = Var(Some(PortalAppType.apiOnly)),
                           team: Var[Option[PortalTeam]] = Var(None),
                           capabilities: Var[Set[PortalAppCapability]] = Var(Set(apiMailbox))
                         ) extends FormModel



