package root_pages.aurinko_pages.app.accounts

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Dialog}
import com.raquo.airstream.eventbus.EventBus
import com.raquo.laminar.api.L._
import common.{AppKey, AurinkoApiPage, ClientSubscription}
import portal_router.AccountPage
import common._
import common.airstream_ops.ValueToObservableOps
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.paginator.Paginator
import service.apis.portal_api.PortalApi

case class AccountWebhooks(
                            $route: Signal[AccountPage],
                            portalApi: PortalApi,
                            $webhooks: Signal[AurinkoApiPage[ClientSubscription]]
                          ) {

  val webhooks: Var[Option[AurinkoApiPage[ClientSubscription]]] = Var(None)

  val pageBus: EventBus[(Int, Int)] = new EventBus[(Int, Int)]

  val webhooksPageSize: Int = 10

  val errorsPageSize: Int = 5

  val eventsPageSize: Int = 5



  val webhooksEvents: Var[AurinkoApiPage[ClientSubscriptionEvent]] = Var(AurinkoApiPage(Nil, 0, done = false, 0))

  val webhooksEventId: Var[Option[Int]] = Var(None)

  val secondsUntilRefresh: Var[Int] = Var(0)

  def formatTime(seconds: Int): String = {
    val minutes = seconds / 60
    val countSeconds = seconds % 60
    f"$minutes:$countSeconds%02d"
  }

  def periodLoadUpdate: EventStream[String] = EventStream.periodic(1000, emitInitial = false).flatMap(_ => {
    val currentvalue = secondsUntilRefresh.now()
    if (currentvalue >= 1) {
      secondsUntilRefresh.update(_ - 1)
      EventStream.fromValue(formatTime(secondsUntilRefresh.now()))
    } else {
      secondsUntilRefresh.set(120)
      EventStream.fromValue(formatTime(secondsUntilRefresh.now()))
    }
  })

  def requestWebhooks(appKey: AppKey, accId: Int, pageNum: Int, limit: Int): EventStream[AurinkoApiPage[ClientSubscription]] = {
    portalApi.getWebhooks(
      appKey,
      accId,
      pageNum,
      limit
    )
  }

  def renderWebhooksRow(routeInfo: AccountPage)
                       (
                         @annotation.unused id: Int,
                         initial: ClientSubscription,
                         $shortAction: Signal[ClientSubscription]): Div = {
    val expanded = Var(false)
    val clientSubscr: Var[(ClientSubscription, Boolean)] = Var(ClientSubscription(id = 0), false)
    val $clientSubscr = clientSubscr.signal.map(_._1)

    val webhooksErrors: Var[AurinkoApiPage[ProcessingErrors]] = Var(AurinkoApiPage(Nil, 0, done = false, 0))

    val dialogBus: EventBus[(Int, Int)] = new EventBus[(Int, Int)]

    val panel = ExpansionPanelComponent(
      header = div(
        cls := "slds-grid slds-grid--vertical-align-center growing-block au-truncate",

        styleAttr <-- $clientSubscr.map {
          case webhook if !webhook.active => "color: var(--au-lighter-brown);"
          case _ => ""
        },

        div(
          cls := "slds-grid slds-grid_vertical slds-col slds-size--1-of-12 slds-m-right_xxx-small",
          span(cls := "slds-col slds-m-bottom_xxx-small",
            child.text <-- $clientSubscr.map(_.id)),
        ),
        span(cls := "slds-col slds-size--3-of-12 slds-m-right_small",
          title <-- $clientSubscr.map(_.resource.getOrElse("")),
          child.text <-- $clientSubscr.map(_.resource.getOrElse(""))),
        span(cls := "slds-col slds-size--2-of-12 slds-m-right_xxx-small",
          child.text <-- $clientSubscr.map {
            case webhook if webhook.latestDeliveredEventTime.nonEmpty => webhook.latestDeliveredEventTime.get.toPrettyLocalFormat
            case _ => ""
          }),
        span(cls := "slds-col slds-size--2-of-12",
          child.text <-- $clientSubscr.map {
            case webhook if webhook.deliveryFailSince.nonEmpty => webhook.deliveryFailSince.get.toPrettyLocalFormat
            case _ => ""
          }),
        child.maybe <-- $clientSubscr.map {
          case webhook if (!webhook.active) || webhook.hasProcessingErrors => Some(div(
            cls := "slds-grid slds-grid_vertical-align-center flex-wrap",
            if (!webhook.active) small(cls := "badge orange  slds-m-right--xx-small",
              "inactive",
            ) else None,
            if (webhook.hasProcessingErrors) small(cls := "badge red",
              "error"
            ) else None
          ))
          case _ => None
        }),

      body = Some(div(
        child <-- $clientSubscr
          .map(webhook => div(
            div(
              cls := "slds-grid slds-m-bottom--large",
              small(cls := "gray slds-col slds-size--2-of-12",
                child.text <-- Signal.fromValue("Resource")),
              span(cls := "slds-col slds-size--6-of-12",
                title := webhook.resource.getOrElse(""),
                webhook.resource)
            ),
            div(
              cls := "slds-grid slds-m-bottom--large",
              small(cls := "gray slds-col slds-size--2-of-12", "NotificationUrl"),
              span(cls := "slds-col slds-size--6-of-12", webhook.notificationUrl)
            ),

            // open popup delivery details
            div(
              cls := "slds-grid slds-m-bottom--large",
              small(
                cls := "gray slds-col slds-size--2-of-12",
                "Delivery events"
              ),
              span(
                "Details",
                cls := "clickable blue",
                styleAttr := "text-decoration: underline;",
                onClick.mapTo(webhook.id).map(_.some) --> webhooksEventId.writer
              ),

            ),

            // "Processing Errors"
            div(
              cls := "slds-grid slds-m-bottom--large",
              small(cls := "gray slds-col slds-size--2-of-12", "Processing Errors"),

              if (webhook.hasProcessingErrors) div(
                cls := "slds-col slds-size--8-of-12",
                cls := "data-table",
                div(
                  cls := "table-header slds-m-bottom--medium",
                  styleAttr := "align-items: flex-start;",
                  span(cls := "slds-col slds-size--3-of-12 gray", "Time"),
                  span(cls := "slds-col slds-size--9-of-12 gray", "Message"),
                ),

                child <-- webhooksErrors.signal.map {
                  case webhooksErrors if webhooksErrors.records.nonEmpty => div(
                    webhooksErrors.records.map(webhooksError => div(
                      cls := "table-row table-row-dialog panel-like",
                      span(cls := "slds-col slds-size--3-of-12",
                        webhooksError.createdAt.toPrettyLocalFormat),
                      span(cls := "slds-col slds-hyphenate slds-size--9-of-12",
                        webhooksError.message
                      ),
                    ))
                  )
                  case _ => div()
                },
                child.maybe <-- webhooksErrors.signal
                  .map {
                    case webhookErrorsPage if webhookErrorsPage.totalSize > errorsPageSize =>
                      Some(Paginator(
                        pageNum = (webhookErrorsPage.offset / errorsPageSize).signaled,
                        totalCount = webhooksErrors.signal.map(_.totalSize),
                        pageSize = Signal.fromValue(errorsPageSize),
                        onPageChange = Observer[(Int, Int)](onNext = i =>
                          dialogBus.emit(i._1 -> i._2),
                        ),
                        documentScrollTopAfterPageChange = false,
                        None,
                        itemsPluralLabel = "Errors",
                      ).node)
                    case _ => Option.empty
                  },
                onMountCallback(_ => dialogBus.emit((0, errorsPageSize)))
              ) else div(cls := "gray",
                "No processing errors"
              ),
            ),
          ))),
      ),
      expanded = expanded.signal,
      onExpansionChange = expanded.writer
    )

    div(
      $shortAction --> Observer.combine(
        clientSubscr.writer.contramap((x: ClientSubscription) => (x, true)),
        expanded.writer.contramap((_: ClientSubscription) => false),
      ),

      panel.$isExpanded
        .withCurrentValueOf(clientSubscr.signal.map(_._2)).changes
        .filter(x => x._1 && x._2)
        .combineWith(dialogBus.events)
        .flatMap {
          case (_, _, pageNum, pageLimit) => portalApi.getProcessingErrors(routeInfo.appKey, routeInfo.accId, initial.id, pageNum, pageLimit)
        }
        --> webhooksErrors.writer,

      panel.node,
    )
  }

  val webhooksDetailsPopup: Dialog.El = {

    val webhooksEventsBus: EventBus[(Int, Int)] = new EventBus[(Int, Int)]

    Dialog(
      _.open <-- webhooksEventId.signal.map(_.isDefined),
      _ => cls := "width--xx--medium",
      _.onOpened.mapTo(0 -> eventsPageSize) --> webhooksEventsBus.writer,
      _.onClosing.mapTo(None) --> webhooksEventId.writer,
      _.onOpening.mapTo(120) --> secondsUntilRefresh.writer,

      _ => child.maybe <-- webhooksEventId.signal.map {
        case Some(webhooksId) => Some(div(

          webhooksEventsBus.events
            .combineWith(secondsUntilRefresh.signal.changes.filter(_ == 120))
            .withCurrentValueOf($route)
            .flatMap {
              case (pageNum, pageSize, _, route) =>
                portalApi.getWebhooksEvents(route.appKey, route.accId, webhooksId, pageNum, pageSize)
            } --> webhooksEvents,

          div(
            cls := "slds-grid slds-grid_align-spread slds-grid_vertical-align-center",
            h2(
              cls := "title--level-3",
              "Delivery details"
            ),
            small(
              fontStyle := "italic",
              child.text <-- periodLoadUpdate.map(minutes => s"The list will refresh in $minutes"),
            )
          ),

          div(
            cls := "data-table",
            div(
              cls := "table-header slds-m-bottom--medium",
              span(cls := "slds-col slds-size--3-of-12 gray", "Time"),
              span(cls := "slds-col slds-size--9-of-12 gray", "Payload"),
            ),
            child.maybe <-- webhooksEvents.signal
              .map {
                case webhookEventsList if webhookEventsList.records.nonEmpty => Some(div(
                  webhookEventsList.records.map(webhookEvent => div(
                    div(
                      cls := "table-row table-row-dialog panel-like",
                      span(cls := "slds-col slds-size--3-of-12 gray", webhookEvent.createdAt.map(_.toPrettyLocalFormat)),
                      span(cls := "slds-col slds-size--9-of-12 gray", webhookEvent.payload),
                    ),
                  ))))
                case _ => Some(div(
                  cls := "gray  slds-align_absolute-center",
                  "No delivery errors"))
              }
          ),
          child.maybe <-- webhooksEvents.signal
            .map {
              case webhooksEventsPage if webhooksEventsPage.totalSize > eventsPageSize =>
                Some(Paginator(
                  pageNum = (webhooksEventsPage.offset / eventsPageSize).signaled,
                  totalCount = Signal.fromValue(webhooksEventsPage.totalSize),
                  pageSize = Signal.fromValue(eventsPageSize),
                  onPageChange = Observer.combine[(Int, Int)](
                    webhooksEventsBus.writer,
                  ),
                  documentScrollTopAfterPageChange = false,
                  None,
                  itemsPluralLabel = "Errors",
                ).node)
              case _ => Option.empty
            },
        ))
        case _ => None
      },

      _.slots.primaryAction(
        Button(
          _ => cls := "blue",
          _.label := "Close",
          _.outlined := true,
          _ => onClick.mapTo(None) --> webhooksEventId.writer
        )
      ),
    )
  }

  val node: Div = div(

    pageBus.events
      .withCurrentValueOf($route)
      .flatMap {
        case (pageNum, limit, route) =>
          requestWebhooks(route.appKey, route.accId, pageNum, limit)
      }
      .map(_.some) --> webhooks.writer,

    div(
      cls := "slds-m-top_xx-large slds-m-bottom_medium",
      span(cls := "title--level-3", "Webhooks subscriptions")
    ),
    div(
      cls := "data-table",
      div(
        cls := "table-header with-expansion-panels slds-m-bottom_xx-small",
        span(cls := "slds-col slds-size--1-of-12 gray slds-m-right_xxx-small", "Id"),
        span(cls := "slds-col slds-size--3-of-12 gray slds-m-right_small", "Resource"),
        span(cls := "slds-col slds-size--2-of-12 gray slds-m-right_xxx-small",
          "Last delivery",
        ),
        span(cls := "slds-col slds-size--2-of-12 gray",
          styleAttr := "white-space: wrap;",
          div("Failed"), div("Delivery Since")),
        span(cls := "slds-col slds-size--1-of-12 gray"),
      ),

      child.maybe <-- $route.combineWith(webhooks.signal).map {
        case (route, Some(t)) if t.records.nonEmpty => Some(div(
          children <-- Signal.fromValue(t.records).split(_.id)(renderWebhooksRow(route))))
        case _ => Some(div(
          cls := "gray slds-m-top--large slds-align_absolute-center",
          "No subscriptions"))
      },

      webhooksDetailsPopup,

      child.maybe <-- webhooks.signal
        .map {
          case Some(webhookPage) if webhookPage.totalSize >= webhooksPageSize =>
            Some(Paginator(
              pageNum = (webhookPage.offset / webhooksPageSize).signaled,
              totalCount = Signal.fromValue(webhookPage.totalSize),
              pageSize = Signal.fromValue(webhooksPageSize),
              onPageChange = pageBus.writer,
              documentScrollTopAfterPageChange = false,
              None,
              itemsPluralLabel = "Subscriptions",
            ).node)

          case _ => Option.empty
        },
      onMountCallback(_ => pageBus.emit((0, webhooksPageSize)))
    ))
}



