package root_pages.aurinko_pages.team.team_settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Checkbox, Dialog, Formfield, Icon, Radio, Textfield}
import com.raquo.airstream.eventbus.EventBus
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.TeamMemberRole.TeamMemberRole
import common.airstream_ops.{EventStreamOps, OptionEventStreamOps, OptionSignalOps, SignalNestedOps, SignalOps, SignalOptionOps, ValueToObservableOps}
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.element_binders.DialogModifying
import common.forms.{FormsLocalExceptionHandling, RadioFormOps, TextfieldFormOps, CheckboxFormOps}
import common.ui.notifications.InfoTextComponent
import common.ui.{AuFormField, AuFormState, AuFormStateExp, animateVisibilityModifier}
import common.{FormModel, InstantOps, InvitationModel, PingTools, PortalInvitation, PortalTeam, PortalTeamMember, PortalUser, TeamMemberOutDto, TeamMemberRole, nameFieldPattern}
import org.scalajs.dom
import org.scalajs.dom.html
import portal_router.TeamSettingsPage
import portal_router.{BufferPage, PortalRouter}
import service.exception_handler.{BadUrlParam, ForbiddenError, UnknownException}
import service.apis.portal_api.PortalApi
import service.portal_state.{PortalState, TeamMemberAccess}
import wvlet.log.Logger

class TeamComponent($route: Signal[TeamSettingsPage], portalApi: PortalApi, portalState: PortalState, portalRouter: PortalRouter) {
  private val log = Logger.of[TeamComponent]
  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)

  private val cancelBus: EventBus[Unit] = new EventBus[Unit]

  private val updateBus: EventBus[Unit] = new EventBus[Unit]

  private val editedUser: Var[Option[PortalTeamMember]] = Var(None)
  private val deletedUser: Var[Option[PortalTeamMember]] = Var(None)
  private val inviteModel: Var[Option[InvitationModel]] = Var(None)


  private val $team: Signal[PortalTeam] = $route
    .combineWith(portalState.$me.map(_.teams))
    .map { case (page, teams) =>
      teams
        .find(t => t.id == page.teamId)
        .getOrElse(throw BadUrlParam("team id", page.teamId.toString))
    }


  private val $invitations: Signal[List[PortalInvitation]] = updateBus.events.withCurrentValueOf($team).flatMap {
    case team if team.role == TeamMemberRole.collaborator => EventStream.fromValue(List[PortalInvitation]())
    case team => portalApi.getInvitations(team.id)
  }.startWith(Nil)

  private val $members: Signal[List[PortalTeamMember]] = updateBus.events.withCurrentValueOf($team)
    .flatMap(x => portalApi.getMembers(x.id)).startWith(Nil)

  private val showEditTeam: Var[Boolean] = Var(false)

  private val editTeamPopup: Dialog.El = {

    val dialogTextField: AuFormField = AuFormField(
      Textfield(
        _ => cls := "width-medium slds-m-bottom_large",
        _.outlined := true,
        _.label := "Team name",
        _.required := true,
        _.pattern := nameFieldPattern,
        _.value <-- $team.map(_.name),
        _.value <-- cancelBus.events.withCurrentValueOf($team.map(_.name)),
      ), initialValidation = true)

    val teamNameState: AuFormState = AuFormState(dialogTextField :: Nil)


    Dialog(
      _.open <-- showEditTeam.signal,
      _.heading := "Edit team name",
      _.onClosing.mapTo(false) --> Observer[Boolean](onNext = x => {
        cancelBus.emit()
        showEditTeam.set(x)
      }),
      _ => PingTools.dialogBinders(portalApi),

      _.slots.default(
        dialogTextField.node,
      ),

      _.slots.primaryAction(
        ButtonsPairComponent[PortalUser, dom.MouseEvent](

          primaryDisabled = teamNameState.dirtySignal.combineWith(teamNameState.validSignal).map(t => !t._1 || !t._2),

          primaryEffect = () => EventStream.fromValue(())
            .sample($team)
            .flatMap(portalTeam => for {
              _ <- portalApi.upadateTeam(
                dialogTextField.node.ref.value,
                portalTeam.id)

              me <- portalApi.getMe
            } yield me),

          primaryObserver = Observer[PortalUser](me => {
            portalState.updateMe(me)
            dialogTextField.dirtyUpdate()
            showEditTeam.set(false)
          }),

          secondaryObserver = Observer[dom.MouseEvent](onNext = _ => {
            cancelBus.emit()
            showEditTeam.set(false)
          })
        ).node
      )
    )
      .withPing(portalApi)
      .closeOnSessionExpired(portalState.Session.$sessionExpiredEvents)

  }


  private def renderUser(userId: Int,
                 initialUser: PortalTeamMember,
                 $member: Signal[PortalTeamMember]): ReactiveHtmlElement[html.Div] = {

    val editUserBus: EventBus[dom.MouseEvent] = new EventBus[dom.MouseEvent]
    val deleteUserBus: EventBus[dom.MouseEvent] = new EventBus[dom.MouseEvent]

    val editIcon = Icon(
      _ => cls := " clickable light small mat-outlined",
      _ => "edit",
      _ => onClick --> editUserBus.writer)


    val deleteIcon = Icon(
      _ => cls := "light slds-m-left_large clickable medium mat-outlined red",
      _ => "delete_outline",
      _ => onClick --> deleteUserBus.writer)


    div(
      cls := "slds-grid--vertical-align-center table-row",
      span(cls := "slds-col slds-size--3-of-12", child.text <-- $member.map(_.name)),
      span(cls := "slds-col slds-size--3-of-12", child.text <-- $member.map(_.email)),
      span(
        cls := "slds-col slds-size--2-of-12",
        child.text <-- $member.map(_.lastLoginAt match {
          case Some(value) => value.toPrettyLocalFormat
          case None => ""
        })
      ),
      div(
        cls := "slds-col slds-size--3-of-12",
        child <-- $member.map { u =>
          small(
            cls := "badge slds-m-right--xx-small",
            u.role.label)

        },

        child.maybe <-- $member.map {
          case u if !u.verified => Some(small(
            cls := "badge orange slds-m-right--xx-small",
            "unverified"
          ))
          case _ => None
        }
      ),

      div(cls := "slds-col slds-size--1-of-12 slds-grid slds-grid_align-end",
        child.maybe <-- $team.map(_.role)
          .withCurrentValueOf($member.map(_.role))
          .map { case (role, memberRole) => Option.when(
            memberRole != TeamMemberRole.owner && role.isAdmin
          ) {
            editIcon
          }},

        child.maybe <-- $team.map(_.role)
          .withCurrentValueOf($member)
          .withCurrentValueOf(portalState.$me)
          .map { case (role, member, user) =>
            Option.when(
              member.role != TeamMemberRole.owner && (role.isAdmin || member.email == user.email)
            ) {
              deleteIcon
            }

          },

        editUserBus.events.withCurrentValueOf($member).map(_._2) --> Observer[PortalTeamMember](onNext = user =>
          editedUser.set(Some(user))
        ),
        deleteUserBus.events.withCurrentValueOf($member).map(_._2) --> Observer[PortalTeamMember](onNext = user =>
          deletedUser.set(Some(user))
        )
      )
    )
  }

  private def renderInvitedUser(userId: Int,
                        initialUser: PortalInvitation,
                        $invitation: Signal[PortalInvitation]): ReactiveHtmlElement[html.Div] = {

    val editInviteBus: EventBus[dom.MouseEvent] = new EventBus[dom.MouseEvent]
    val deleteInviteBus: EventBus[dom.MouseEvent] = new EventBus[dom.MouseEvent]

    val editIcon = Icon(_ => cls := "light clickable small mat-outlined", _ => "edit", _ => onClick --> editInviteBus.writer)

    val deleteIcon = Icon(_ => cls := "light slds-m-left_large clickable medium mat-outlined red", _ => "delete_outline", _ => onClick --> deleteInviteBus.writer)

    div(
      cls := "slds-grid--vertical-align-center table-row",
      span(cls := "slds-col slds-size--3-of-12", child.text <-- $invitation.map(_.username)),
      span(cls := "slds-col slds-size--3-of-12", child.text <-- $invitation.map(_.email)),
      span(cls := "slds-col slds-size--2-of-12", child.text <-- $invitation.mapTo("Invitation sent")),
      div(
        cls := "slds-col slds-size--3-of-12",
        child <-- $invitation.map { inv => small(cls := "badge", inv.role.label) }
      ),
      div(
        cls := "slds-col slds-size--1-of-12 slds-grid slds-grid_align-end",
        editIcon,
        deleteIcon,

        editInviteBus.events
          .withCurrentValueOf($invitation).map(_._2)
          --> Observer[PortalInvitation](invite =>
          inviteModel.set(Some(invite.toEditModel))),

        deleteInviteBus.events
          .withCurrentValueOf($invitation).map(_._2)
          .withCurrentValueOf($team)
          .flatMap(t => portalApi.deleteInvite(t._2.id, t._1))
          --> Observer[Unit](onNext = _ => updateBus.emit(()))

      )
    )
  }

  private val editMamberPopup: Dialog.El = {
    val userRole: Var[Option[TeamMemberRole]] = Var(None)
    val apps: Var[Set[Int]] = Var(Set())

    def roleRadio(role: TeamMemberRole): Div = div(
      cls := "slds-grid slds-grid--vertical-align-center slds-m-right_x-large",
      Formfield(
        _.label := role.label,

        _.slots.default(Radio(
          _.name := "roleGroup",
          _ => cls := "no-left-margin",
          _ => checked <-- userRole.signal.map(r => r.isDefined && r.get == role),
          _ => onClick.mapTo(Some(role)) --> userRole
        ))
      )
    )


    Dialog(
      _.open <-- editedUser.signal.map(_.isDefined),
      _.heading := "Edit team member permissions",
      _.onClosing.mapTo(None) --> editedUser,
      _ => cls := "dialog-width--medium",

      _ => editedUser.signal.map {
        case None => None
        case Some(user) => (user.role -> user.accessibleApps).some
      } --> Observer.combine(
        userRole.writer.contramap[Option[(TeamMemberRole, Set[Int])]](_.map(_._1)),
        apps.writer.contramap[Option[(TeamMemberRole, Set[Int])]](_.map(_._2).getOrElse(Set()))
      ),

      _.slots.default(
        div(
          cls := "slds-m-bottom_large",
          div(
            div(
              cls := "slds-grid slds-grid--vertical-align-center section-warning",
              Icon(_ => "error", _ => cls := "slds-col slds-size--1-of-12"),
              span(
                cls := "slds-col slds-size--11-of-12",
                "You're transferring ownership of this team to ",
                child.text <-- editedUser.signal.map { case Some(u) => u.name case _ => "" },
                ". You will remain an administrator but not an owner."
              ),
            ),

            inContext(animateVisibilityModifier(userRole.signal
              .map(_.getOrElse(TeamMemberRole.collaborator) == TeamMemberRole.owner)))

          )

        ),

        div(
          cls := "slds-m-bottom_large",
          child.maybe <-- editedUser.signal.map {
            case Some(value) => Some(p("Choose " + value.name + "'s new role:", cls := "title--level-4 slds-m-bottom--large"))
            case None => None
          },
          div(
            cls := "slds-grid slds-grid--vertical-align-center slds-m-bottom--large",
            children <-- $team.map(team => TeamMemberRole.ALL
                .filter(_ != TeamMemberRole.owner || team.role == TeamMemberRole.owner))
              .nestedMap(roleRadio)
          ),

          AppsAccessView(
            apps.signal,
            editedUser.signal.nestedMap(_.name),
            onChanged = apps.writer,
            isAdmin = userRole.signal.map(_.exists(_.isAdmin)),
            formState = new AuFormStateExp()
          )
        )),
      _.slots.primaryAction(
        ButtonsPairComponent[Unit, dom.MouseEvent](
          primaryDisabled = editedUser.signal
            .combineWith(userRole.signal)
            .combineWith(apps.signal)
            .map{ case (member, role, apps) => member match {
              case Some(v) => v.role == role && v.accessibleApps.equals(apps)
              case None => true
            }},

          primaryEffect = () => EventStream.fromValue(())
            .sample($team)
            .map(_.id)
            .flatMap(teamId => portalApi.updateTeamMember(
              teamId,
              editedUser.now.getOrElse(throw UnknownException("No user to save")).id,
              TeamMemberOutDto(
                userRole.now.getOrElse(throw UnknownException("No role selected")),
                apps.now)
            )),

          primaryObserver = Observer[Unit](onNext = _ => {
            updateBus.emit(())
            editedUser.set(None)
          }),

          secondaryObserver = editedUser.writer.contramap((_: dom.MouseEvent) => None)

        ).node
      )
    )
      .withPing(portalApi)
      .closeOnSessionExpired(portalState.Session.$sessionExpiredEvents)


  }

  private val deleteMemberPopup = {
    Dialog(
      _.open <-- deletedUser.signal.map {
        case None => false
        case Some(_) => true
      },
      _.heading <-- deletedUser.signal
        .combineWith(portalState.$me)
        .map {
          case (deleted, current) if deleted.isDefined && deleted.get.email == current.email => "Leave team"
          case (deleted, _) if deleted.isDefined => s"Delete ${deleted.get.name}"
          case _ => ""
        },

      _.onClosing.mapTo(None) --> deletedUser,

      _.slots.default(p("This action cannot be undone!")),

      _.slots.primaryAction(

        ButtonsPairComponent[Option[PortalUser], dom.MouseEvent](
          primaryButtonText = "Delete",

          primaryEffect = () => EventStream.fromValue(())
            .sample($team)
            .flatMap(x => portalApi.deleteMember(x.id, deletedUser.now().get.id))
            .sample(deletedUser.signal)
            .withCurrentValueOf(portalState.$me)
            .flatMap {
              case (deleted, current) if deleted.isDefined && deleted.get.email == current.email => portalApi.getMe.map(Some(_))
              case _ => EventStream.fromValue(None)
            },

          primaryObserver = Observer[Option[PortalUser]](onNext = userToUpdate => {
            if (userToUpdate.isDefined) {
              portalState.updateMe(userToUpdate.get)
              portalRouter.navigate(BufferPage)
            } else {
              updateBus.emit(())
              deletedUser.set(None)
            }
          }),

          secondaryObserver = deletedUser.writer.contramap((_: dom.MouseEvent) => None)
        ).node
      ))
      .withPing(portalApi)
    //      .closeOnSessionExpired(portalState.Session.$sessionExpiredEvents)

  }

  private val invitePopup: Dialog.El = Dialog(
    _.open <-- inviteModel.signal.map(_.isDefined),
    _.onClosing.mapTo(None) --> inviteModel,

    _.heading <-- inviteModel.signal.flatMap {
      case Some(inviteModel) => inviteModel.id.signal.map {
        case None => "New user"
        case Some(_) => "Resend invitation"
      }
      case None => Signal.fromValue("")
    },

    _ => child.maybe <-- inviteModel.signal.nestedMap { invite =>

      div(
        cls := "slds-grid slds-grid--vertical gap--large",

        FormsLocalExceptionHandling.errorView(invite.formError.signal),

        Textfield(
          _.outlined := true,
          _.label := "Name",
          _.pattern := nameFieldPattern,
          _.required := true,
          _.value <-- invite.username,
          _ => onInput.mapToValue --> invite.username
        ).bindToForm(invite.formState),

        Textfield(
          _.outlined := true,
          _.label := "Email",
          _.required := true,
          _.`type` := "email",
          _.helperPersistent := true,
          _.helper <-- invite.email.signal
            .combineWith($invitations)
            .combineWith($members)
            .map {
              case (email, invitations, members)
                if (invitations.map(_.email) ++ members.map(_.email)).contains(email) => "User is already in team or has been invited."
              case _ => " "
            },

          _.value <-- invite.email,
          _ => onInput.mapToValue --> invite.email
        )
          .bindToForm(invite.formState, $customValidation =
            (inputValue: String) => $invitations
              .combineWith($members)
              .map { case (invitations, members) =>
                !(invitations.map(_.email) ++ members.map(_.email)).contains(inputValue)
              }),

        div(
          cls := "slds-grid slds-grid--vertical-align-center gap--medium",

          TeamMemberRole.ALL.filterNot(_ == TeamMemberRole.owner)
            .map(role => div(
              cls := "slds-grid slds-grid--vertical-align-center",

              Formfield(
                _.label := role.label,

                _.slots.default(
                  Radio(
                    _.name := "roleGroup",
                    _ => cls := "no-left-margin",
                    _ => checked <-- invite.role.signal.map(r => r == role),
                    _ => onClick.mapTo(role) --> invite.role
                  ).bindToForm(invite.formState)
                )))
            )


        ),

        AppsAccessView(
          inviteModel.signal.semiflatMap(_.accessibleApps.signal).map(_.getOrElse(Set())),
          None.signaled,
          onChanged = Observer[Set[Int]](l => inviteModel.now.foreach(_.accessibleApps.set(l))),
          isAdmin = inviteModel.signal.semiflatMap(_.role.signal.map(_.isAdmin)).map(_.getOrElse(false)),
          formState = invite.formState
        )
      )
    },

    _.slots.primaryAction(
      ButtonsPairComponent[Unit, dom.MouseEvent](
        primaryButtonText = "Send",
        primaryDisabled = inviteModel.signal
          .flatMap(_.$traverse(_.formState.$submitAllowed))
          .map(!_.contains(true)),

        primaryEffect = () => ()
          .streamed
          .sample(inviteModel.signal)
          .collect { case Some(model) => model }
          .withCurrentValueOf($route)
          .flatMap { case (model, route) => for {
            _ <- model.id.now.$eTraverse(_ => portalApi.deleteInvite(route.teamId, model.toImmutableModel))
            _ <- portalApi.inviteMember(route.teamId, model.toImmutableModel)
          } yield ()
          }
          .withErrorHandlingAndCollect(
            FormsLocalExceptionHandling
              .handler(str => inviteModel.now.foreach(_.formError.set(str.some)))),

        primaryObserver = Observer[Unit](onNext = _ => {
          log.info("primary observer")
          updateBus.emit(())
          inviteModel.set(None)
        }),

        secondaryObserver = inviteModel.writer.contramap((_: dom.MouseEvent) => None)

      ).node

    )

  )
    .withPingOnOpen(portalApi)
    .withFormCaching(
      portalState.FormCache,
      portalState.Session.$sessionExpiredEvents,
      inviteModel,
      inviteModel.signal.nestedMap(_.initial).getOrElse(InvitationModel()),
      (m: InvitationModel) => inviteModel.now.foreach(_.update(m)),
      (cachedForm: FormModel) => cachedForm match {
        case v: InvitationModel => v.some
        case _ => None
      },
      () => inviteModel.now.foreach(_.formState.validate()))

  private def AppsAccessView(accessibleApps: Signal[Set[Int]],
                             username: Signal[Option[String]],
                             onChanged: Observer[Set[Int]],
                             isAdmin: Signal[Boolean],
                             formState: AuFormStateExp
                            ) = {
    div(
      isAdmin.changes.filter(_ == true)
        .sample($team.map(_.apps.getOrElse(Nil).map(_.id).toSet)) --> onChanged,

      div(
        cls := "slds-grid gap--small",
        p(
          child.text <-- username.map(name => s"Applications that ${name.getOrElse("invited  user")} has access to"),
          cls := "title--level-4 slds-m-bottom--medium"
        ),
        child.maybe <-- isAdmin.mapOptWhenTrue(_ == true)(
          InfoTextComponent.onlyTitle("The owner and administrators have access to all applications.".signaled))
      ),
      children <-- $team.map(_.apps.getOrElse(Nil))
        .nestedMap(app => Formfield(
          _.label := app.name,

          _.slots.default(
            Checkbox(
            _.checked <-- accessibleApps.map(_.contains(app.id)),
            _ => composeEvents(onChange.mapToChecked)(_
                .withCurrentValueOf(accessibleApps)
                .map { case (checked, apps) =>
                  if (checked) apps + app.id
                  else apps.filterNot(_ == app.id)
                }) --> onChanged,

              _.disabled <-- isAdmin

          ).bindToForm(formState)
          )
        )
    ))
  }

  val node: Div = {
    div(
      cls := "content-wrapper",
      cls <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin).allowed.map(if (_) "" else "hidden"),

      div(
        cls := "content-padding nested-page-wrapper",
        div(
          cls := "left-section",

          div(
            cls := "left-section-header",
            div(
              cls := "slds-grid slds-grid--align-spread slds-m-top--medium",
              div(
                cls := "slds-m-bottom--medium",
                p(child.text <-- $team.map(_.name), cls := "title--level-1"),

                div(
                  cls := "slds-grid badge-container content-height",

                  child.maybe <-- $team.mapOptWhenTrue(_.primary)(
                    small(cls := "badge slds-m-right--xx-small", "primary")),

                  child.maybe <-- $team.mapOptWhenTrue(_.role == TeamMemberRole.owner)(
                    small(cls := "badge slds-m-right--xx-small", "ownership")),

                  child.maybe <-- $team.mapOptWhenTrue(_.role == TeamMemberRole.admin)(
                    small(cls := "badge slds-m-right--xx-small", "administration"))
                )

              ),
              child.maybe <-- $team.map(_.role.isAdmin).map {
                case true => Some(Icon(
                  _ => cls := "light medium clickable slds-m-top--x-small mat-outlined",
                  _ => "edit",
                  _ => onClick.mapTo(true) --> showEditTeam
                ))
                case false => None
              }
            )
          ),


          div(
            cls := "content-container",
            div(
              cls := "content",
              div(
                cls := "border-top--light",

                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Id"),
                  span(child.text <-- $team.map(_.id)),
                ),

                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Applications"),
                  span(child.text <-- $team.map(_.apps.toList.flatten.length)),
                ),

                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Created at"),
                  span(child.text <-- $team.map(_.createdAt.toPrettyLocalFormat)),
                ),
              ),

              div(
                cls := "border-top--light content slds-p-top--small",
                child.maybe <-- $team.map(_.role == TeamMemberRole.owner).map {
                  case false => Some(Button(
                    _.label := "Leave team",
                    _.outlined := true,
                    _ => cls := "red",
                    _ => composeEvents(onClick)(_
                      .sample(portalState.$me)
                      .withCurrentValueOf($members)
                      .map { case (me, members) => members.find(_.email == me.email) }) --> deletedUser,
                  ))
                  case _ => None
                }
              ),

            )
          )
        ),

        div(
          cls := "right-section slds-m-top--medium",
          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-end",
            p(cls := "title--level-2", "Team members"),

            child.maybe <-- $team.map(_.role match {
              case common.TeamMemberRole.collaborator => None
              case _ => Button(
                _ => cls := "blue  slds-m-top--medium",
                _ => cls <-- portalState.$me.map(_.verified).map { case true => "clickable" case _ => "light" },
                _.outlined := true,
                _ => title <-- portalState.$me.map(_.verified).map { case true => "" case _ => "Please verify your email." },
                _.label := "Invite user",
                _.icon := "add",

                _ => composeEvents(onClick)(_
                  .sample(portalState.$me.map(_.verified))
                  .filter(_ == true)
                  .mapTo(InvitationModel().some)

                ) --> inviteModel

              ).some
            })
          ),
          div(
            cls := "data-table slds-m-top--large",
            div(
              cls := "slds-p-vertical--medium table-header",
              span(cls := "slds-col slds-size--3-of-12", "Full name"),
              span(cls := "slds-col slds-size--3-of-12", "Email"),
              span(cls := "slds-col slds-size--2-of-12", "Last login")
            ),
            children <-- $members.split(_.id)(renderUser),

            children <-- $invitations.split(_.id.get)(renderInvitedUser)


          ),

          deleteMemberPopup,
          editMamberPopup,
          invitePopup,
          editTeamPopup
        )
      )


    )
      .amend(
        teamMemberAccess.minRoleCheck(TeamMemberRole.admin)
          .allowed --> Observer[Boolean](
          if (_) {
            log.info("TeamMemberRole.admin")
            updateBus.emit(())
          }
          else throw ForbiddenError()
        ))
  }
}
