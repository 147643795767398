package service.portal_state

import cats.implicits.{catsSyntaxOptionId, none}
import com.raquo.laminar.api.L._
import common.{PortalTeam, PortalUser, TeamMemberRole}
import common.TeamMemberRole.TeamMemberRole
import common.airstream_ops.{SignalOps, ValueToObservableOps}

case class AccessCheck(allowed: Signal[Boolean]) {

  def and(other: => AccessCheck): AccessCheck = AccessCheck(
    allowed.flatMap {
      case true => other.allowed
      case _ => false.signaled
    }
  )

  def andThen[T](what: T): EventStream[Option[T]] = allowed.stream.map {
    case true => what.some
    case _ => None
  }

  def andThenOpt[T](what: Option[T]): EventStream[Option[T]] = allowed.stream.map {
    case true => what
    case _ => None
  }

  def sAndThen[T](what: Observable[T]): EventStream[Option[T]] = allowed.flatMap {
    case true => what.map(_.some).toStreamIfSignal(_.flatMap(_.streamed))
    case _ => none[T].streamed
  }

  def sAndThenOpt[T](what: Observable[Option[T]]): EventStream[Option[T]] = allowed.flatMap {
    case true => what.toStreamIfSignal(_.flatMap(_.streamed))
    case _ => none[T].streamed
  }
}
class TeamMemberAccess(team: Signal[PortalTeam]) {

 def minRoleCheck(minRole: TeamMemberRole): AccessCheck = AccessCheck(team.map(t => TeamMemberRole.ALL.indexOf(t.role) <= TeamMemberRole.ALL.indexOf(minRole)))

}

class PortalUserAccess(user: Signal[PortalUser]) {
  def verifiedCheck: AccessCheck = AccessCheck(user.map(_.verified))
}



object AccessErrorCode extends Enumeration {
  type AccessErrorCode = Value

  val teamPermissions, unverified = Value
}
