package service.exception_handler

case class NotAuthorizedException(
                                   private val message: String = "Not authorized",
                                   username: Option[String] = None
                                 ) extends Exception(message)

case class NotAuthorizedSilentException(private val message: String = "Not authorized",
                                        username: Option[String] = None,
                                        redirect: Option[String]
                                       ) extends Exception(message)

case class SilentException(private val message: Option[String] = None) extends Exception(message.getOrElse(""))

case class MissingStateVarException(private val what: String)
  extends Exception(s"Missing portal state variable: $what")


case class UnknownException(message: String) extends Exception(s"Unknown error: ${message}")

case class BadUrlParam(what: String, value: String) extends Exception(s"Wrong URL parameter: $what $value is not found.")

case class UnexpectedApiResponse(message: String) extends Exception(s"Unexpected server response: $message")

case class ForbiddenError() extends Exception("Access denied")



case class ApiException(message: String, code: String) extends Throwable("ApiException") {
  override
  def toString = s"$message"
}

case class ApiNotFoundException(message: String, code: String) extends Throwable("NotFoundApiException") {
  override
  def toString = s"$message"
}

case class ApiBadRequestException(message: String, code: String) extends Throwable("ApiBadRequestException") {
  override
  def toString = s"$message"
}

case class XhrFailedException() extends Exception("XhrFailedException") {

  val message: String = "Failed to get server response. Please, try refreshing the page."

  override
  def toString = s"$message"
}
