package common.ui.buttons_pair

import com.github.uosis.laminar.webcomponents.material.Button
import com.raquo.laminar.api.L._
import org.scalajs.dom
import org.scalajs.dom.MouseEvent


case class ButtonsPairComponent[T1, T2](
                                         primaryButtonText: String = "Save",
                                         secondaryButtonText: String = "Cancel",
                                         primaryRaised: Boolean = true,
                                         secondaryRaised: Boolean = false,
                                         primaryCls: String = "",
                                         secondaryCls: String = "",
                                         cssClass: String = "",
                                         primaryDisabled: Signal[Boolean] = Signal.fromValue(false),
                                         disabled: Signal[Boolean] = Signal.fromValue(false),
                                         primaryObserver: Observer[T1] = Observer.empty,
                                         primaryEffect: () => EventStream[T1] = () => EventStream.empty,
                                         secondaryObserver: Observer[T2] = Observer.empty,
                                         secondaryEffect: () => EventStream[T2] = () => EventStream.empty,
                               ) {




  val node: Div = div(

    cls := "slds-grid slds-grid--align-end " + cssClass,
    Button(
      _ => cls := "slds-m-right--x-small secondary",
      _ => cls := secondaryCls,
      _.outlined := true,
      _.label := secondaryButtonText,
      _.raised := secondaryRaised,
      _ => composeEvents(onClick)(_.flatMap(e => secondaryEffect() match {
        case EventStream.empty => EventStream.fromValue(e.asInstanceOf[T2])
        case eff => eff
      })) --> secondaryObserver,
      _.disabled <-- disabled
    ),
    Button(
      _ => cls := (primaryCls match {
        case str if str.isEmpty => "secondary"
        case _ => primaryCls
      }),
      _.label := primaryButtonText,
      _.raised := primaryRaised,
      _ => composeEvents(onClick)(_.flatMap(e => primaryEffect() match {
        case EventStream.empty => EventStream.fromValue(e.asInstanceOf[T1])
        case eff => eff
      })) --> primaryObserver,
      _.disabled <-- disabled.combineWith(primaryDisabled).map(t => t._1 || t._2)
    )
  )
}
