package root_pages.aurinko_pages.app.virtual_api.components

import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom.html
import service.apis.dynamic_api.DataMapperModels.MetadataModels
import service.apis.dynamic_api.DataMapperModels.MappingModels
import common.value_opps.ValueOpps
import com.raquo.laminar.api.L._
import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.Button
import cats.implicits.catsSyntaxOptionId
import common.airstream_ops.SignalOps
import wvlet.log.Logger

// name ->
// company.name ->
// company.phones[*] ->
// company.phones[*].number ->
// company.id ->
// emails[*] ->
// phones[*].number ->
// emails[0] ->
// phones[0].number ->
// emails[type=home] ->

case object TreeViewOfField {
  private val log = Logger.of[TreeViewOfField.type]

  def apply(
             $fieldsOfMetadata: Signal[List[MetadataModels.Field]],
             $fieldsOfMapping: Signal[Option[List[MappingModels.Field]]],
             `#classLoader`: String => EventStream[MetadataModels.Class],
             `#schemaLoader`: String => EventStream[MetadataModels.Schema],
             $virtualObjectName: Signal[Option[String]] = Signal.fromValue("Virtual fields".some),
             $providerObjectName: Signal[Option[String]] = Signal.fromValue("Provider fields".some),
             $search: Signal[String] = Signal.fromValue[String](""),
             onClickMap: Observer[(String, Option[MappingModels.Field])],
             onClickField: Observer[(String, Option[MetadataModels.Field])],
             $readOnlyMode: Signal[Boolean] = Signal.fromValue(true),
           ): ReactiveHtmlElement[html.Div] = {
    div(
      common.ui.Attribute.Selector := "data_mapper.components.tree",
      cls := "data-table",
      cls := "slds-grid slds-grid--vertical",
      children <-- $fieldsOfMetadata
        .combineWith($fieldsOfMapping)
        .map(x => NodeMD.from(x._1, x._2.map(_.map(NodeMG.from)), `#classLoader`, `#schemaLoader`, deepPath = "", deepLevel = 0))
        .combineWith($search.map(_.trim))
        .flatMap {
          case (rows, filterText) if filterText.nonEmpty =>
            Signal.combineSeq(rows.map(row => row.$contains(filterText).map((row, _)))).map(_.filter(_._2).map(_._1))
          case (rows, _) =>
            Signal.fromValue(rows)
          case _ =>
            Signal.fromValue(Nil)
        }
        .split(_.id)((_: String, _: NodeMD, $node: Signal[NodeMD]) => renderNode($node, onClickField, onClickMap, $readOnlyMode))
        .map {
          case rows if rows.nonEmpty =>
            renderHeader($virtualObjectName, $providerObjectName) :: Nil ++ rows
          case _ =>
            p(cls := "gray slds-grid slds-grid--align-center slds-m-around--large", "No fields") :: Nil
        }
    )
  }

  private val defaultHeight = "2.5rem"

  private trait Node {
    def name: String
  }

  private object Mode extends Enumeration {
    case class Val(value: String, label: String) extends super.Val(value)

    type NodeMode = Val

    val metadata: NodeMode = Val("metadata", "Metadata")
    val mappings: NodeMode = Val("mappings", "Mappings")
  }

  // node of mapping
  private case class NodeMG(
                             name: String,
                             index: Option[String] = None,
                             hasDot: Boolean = false,
                             nextMappingPath: Option[String] = None,
                             deepPath: String,
                             deepLevel: Int,
                             origin: MappingModels.Field,
                           ) extends Node {
    def direction: Option[MappingModels.Direction.Direction] = origin.direction

    def providerFields: Seq[String] = origin.providerFields

    def rawValue: Option[String] = origin.rawValue

    def mapper: Option[MappingModels.MappingFunction.Mapper] = origin.mapper

    def tag: Option[String] = origin.tag

    def description: Option[String] = origin.description

    def hasNext: Boolean =
      hasDot && nextMappingPath.exists(_.nonEmpty)

    override def toString: String =
      s"mapping: $name${index.map(v => s"[$v]").getOrElse("")}${Option.when(hasDot)(".").getOrElse("")}$nextMappingPath"
  }

  private object NodeMG {
    private val rxMField = "^(\\w+)(?:\\[([^\\[\\]]+)\\])?(?:(\\.)(.+))?$".r

    def from(mmf: MappingModels.Field): NodeMG =
      mmf.name match {
        case rxMField(fName, indexSep, dotSep, nextPath) if fName.nonEmpty =>
          val indexValue = Option.when(indexSep != null && indexSep.nonEmpty)(indexSep)
          val dotValue = Option.when(dotSep != null && dotSep == ".")(".")
          NodeMG(
            name = fName,
            index = indexValue,
            hasDot = dotValue.isDefined,
            nextMappingPath = Option.when(nextPath != null && nextPath.trim.nonEmpty)(nextPath),
            origin = mmf,
            deepPath = s"$fName${indexValue.map(iV => s"[$iV]").getOrElse("")}${dotValue.getOrElse("")}",
            deepLevel = 0,
          )
        case n =>
          NodeMG(
            name = n,
            origin = mmf,
            deepPath = n,
            deepLevel = 0,
          )
      }

    def toNext(p: NodeMG): Option[NodeMG] =
      p.nextMappingPath
        .map(_.trim)
        .map {
          case rxMField(fName, indexSep, dotSep, nextPath) if fName.nonEmpty =>
            val indexValue = Option.when(indexSep != null && indexSep.nonEmpty)(indexSep)
            val dotValue = Option.when(dotSep != null && dotSep == ".")(".")
            p.copy(
              name = fName,
              index = indexValue,
              hasDot = dotValue.isDefined,
              nextMappingPath = Option.when(nextPath != null && nextPath.trim.nonEmpty)(nextPath),
              deepPath = s"${p.deepPath}$fName${indexValue.map(iV => s"[$iV]").getOrElse("")}${dotValue.getOrElse("")}",
              deepLevel = p.deepLevel + 1,
            )
          case n =>
            p.copy(
              name = n,
              index = None,
              hasDot = false,
              nextMappingPath = None,
              deepPath = s"${p.deepPath}$n",
              deepLevel = p.deepLevel + 1,
            )
        }
  }

  // node of metadata
  private case class NodeMD(
                             id: String,
                             name: String,
                             field: Option[MetadataModels.Field],
                             mappings: Option[Seq[NodeMG]],
                             deepPath: String,
                             deepLevel: Int,
                             private val $childList: Signal[Option[Seq[NodeMD]]],
                           ) extends Node {
    def mode: Mode.NodeMode =
      mappings.map(_ => Mode.mappings).getOrElse(Mode.metadata)

    def $children: Signal[Seq[NodeMD]] =
      $childList.map(_.getOrElse(Nil))

    def hasError: Boolean =
      name.nonEmpty && field.isEmpty

    def $contains(text: String): Signal[Boolean] =
      Signal.fromValue(name.indexOf(text) >= 0)
        .combineWith($children).map(f => f)
        .flatMap {
          case (true, _) =>
            Signal.fromValue(true)
          case (false, children) =>
            Signal.combineSeq(children.map(_.$contains(text))).map(_.contains(true))
        }

    override def toString: String = s"metadata: $id"
  }

  private object NodeMD {

    def from(
              metadataFields: Seq[MetadataModels.Field],
              mappingFields: Option[Seq[NodeMG]],
              `#classLoader`: String => EventStream[MetadataModels.Class],
              `#schemaLoader`: String => EventStream[MetadataModels.Schema],
              deepPath: String,
              deepLevel: Int,
            ): Seq[NodeMD] = {
      val parentDeepPath = Option.when(deepPath.nonEmpty)(s"$deepPath.")
      (metadataFields.map(_.name) ++ mappingFields.getOrElse(Nil).map(_.name))
        .distinct
        .map { name =>
          (
            name,
            metadataFields.filter(_.name == name),
            mappingFields.map {
              _
                .filter(_.name == name)
                .sortWith((a, b) =>
                  a.direction
                    .flatMap {
                      case direction if b.direction.exists(_.weight == direction.weight) => None
                      case direction => b.direction.map(_.weight.compareTo(direction.weight))
                    }
                    .orElse(Some(b.nextMappingPath.getOrElse("").compareTo(a.nextMappingPath.getOrElse(""))))
                    .exists(_ > 0))
            }
          )
        }
        .flatMap {
          case (fieldName, metadataFields, maybeMappingFields) if metadataFields.nonEmpty =>
            metadataFields.zipWithIndex.map(f => (s"$fieldName#${f._2}", fieldName, Some(f._1), maybeMappingFields))
          case (fieldName, _, maybeMappingFields) =>
            Seq((fieldName, fieldName, None, maybeMappingFields))
        }
        .sortBy(_._1) // sort by field name
        .map {
          case (nodeId, nodeName, Some(metadataField), mmf) =>
            val loader: EventStream[Option[Seq[NodeMD]]] =
              EventStream.fromValue(metadataField)
                .flatMap {
                  f =>
                    f.`type` match {
                      case MetadataModels.DataType.EmbeddedVal(Some(className)) if className.nonEmpty =>
                        `#classLoader`(className).map[Seq[MetadataModels.Field]](_.fields.getOrElse(Nil))
                      case MetadataModels.DataType.RecordVal(_) if f.recordSchema.isDefined =>
                        EventStream.fromValue[Seq[MetadataModels.Field]](f.recordSchema.map(_.fields).getOrElse(Nil))
                      case MetadataModels.DataType.RecordVal(Some(schemaName)) if schemaName.nonEmpty =>
                        `#schemaLoader`(schemaName).map[Seq[MetadataModels.Field]](_.fields.getOrElse(Nil))
                      case _ =>
                        EventStream.fromValue[Seq[MetadataModels.Field]](Nil)
                    }
                }
                .map {
                  children =>
                    NodeMD.from(
                      children,
                      mmf.map(_.flatMap(NodeMG.toNext)),
                      `#classLoader`,
                      `#schemaLoader`,
                      deepPath = s"${Option.when(deepPath.nonEmpty)(deepPath).map(p => s"$p.").getOrElse("")}$nodeName",
                      deepLevel = deepLevel + 1,
                    )
                }
                .map(Some(_))
            NodeMD(
              nodeId,
              nodeName,
              Some(metadataField),
              mmf.map(_.filter(!_.hasNext)),
              deepPath = s"${parentDeepPath.getOrElse("")}$nodeName",
              deepLevel = deepLevel,
              $childList = loader.toSignal(None),
            )
          case (nodeId, nodeName, None, mmf) =>
            NodeMD(
              nodeId,
              nodeName,
              Option.empty[MetadataModels.Field],
              mmf.map(_.filter(!_.hasNext)),
              deepPath = s"${parentDeepPath.getOrElse("")}$nodeName",
              deepLevel = deepLevel,
              $childList = Signal.fromValue(None),
            )
          case x =>
            throw new Exception(s"undefined type ${x.toString()}")
        }
    }
  }

  private def renderNode(
                          $node: Signal[NodeMD],
                          onClickField: Observer[(String, Option[MetadataModels.Field])],
                          onClickMap: Observer[(String, Option[MappingModels.Field])],
                          $readOnlyMode: Signal[Boolean],
                        ): ReactiveHtmlElement[html.Div] = {
    val showChildList: Var[Boolean] = Var(false)
    div(
      cls := "slds-grid slds-grid--vertical",
      // show current node
      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-size--1-of-1 table-row",
        minHeight := defaultHeight,
        // node title
        div(
          cls := "slds-col growing-block slds-grid slds-grid--vertical-align-center",
          cls <-- $node.condition(_.hasError, _ => "red", _ => ""),
          paddingLeft <-- $node.map(n => s"${n.deepLevel * 1 + 0.5}rem"),
          child <-- $node
            .flatMap(n => n.$children.map(_.nonEmpty).map(x => x || n.mappings.exists(_.length > 1)))
            .map {
              case true => material.Icon(
                _ => cls := "slds-align-top light clickable medium mat-outlined",
                _ => child.text <-- showChildList.signal.map {
                  case true => "expand_less"
                  case _ => "expand_more"
                },
                _ => marginTop := "0.5rem",
                _ => composeEvents(onClick)(_.sample(showChildList.signal.map(!_))) --> showChildList.writer,
              )
              case _ => span(width := "1rem", marginRight := "0.5rem")
            },
          child.maybe <-- $node
            .map(n => (n.name, n.deepLevel, n.deepPath, n.field, n.mappings))
            .combineWith(showChildList.signal)
            .map {
              case (name, deepLevel, deepPath, field, Some(mappings), false) =>
                renderNodeMGName(name, deepLevel, deepPath, mappings.headOption.map(_ :: Nil).getOrElse(Nil), field).some
              case (name, deepLevel, deepPath, field, Some(mappings), true) =>
                renderNodeMGName(name, deepLevel, deepPath, mappings, field).some
              case (name, deepLevel, deepPath, Some(field), _, _) =>
                renderNodeMDName(name, deepLevel, deepPath, field).some
              case _ =>
                None
            },
        ),
        // node details
        div(
          cls := "slds-col slds-size--7-of-12 slds-grid",
          child.maybe <-- $node
            .flatMap(n => n.$children
              .map(_.nonEmpty)
              .map(x => (x || n.mappings.exists(_.length > 1), n.deepLevel, n.deepPath, n.field, n.mappings)))
            .combineWith(showChildList.signal)
            .map {
              case (_, deepLevel, deepPath, field, Some(mappings), _) =>
                renderNodeMGDetails(deepLevel, deepPath, mappings, field, onClickMap, $readOnlyMode).some
              case (_, deepLevel, deepPath, Some(field), _, _) =>
                renderNodeMDDetails(deepLevel, deepPath, field, onClickField).some
              case _ =>
                None
            }
        ),
      ),
      // show child nodes
      children <-- showChildList.signal
        .flatMap {
          case true => $node.flatMap(_.$children)
          case _ => Signal.fromValue(Nil)
        }
        .split(_.id)((_: String, _: NodeMD, $node: Signal[NodeMD]) => renderNode($node, onClickField, onClickMap, $readOnlyMode))
    )
  }

  private def renderNodeMDName(
                                name: String,
                                deepLevel: Int,
                                deepPath: String,
                                field: MetadataModels.Field,
                              ): ReactiveHtmlElement[html.Div] =
    div(
      styleAttr := "font-size: 1.1rem; min-height: 2.5rem; line-height: 2.5rem;",
      common.ui.Attribute.Description := s"MDn$deepLevel:$deepPath",
      span(
        field.condition(
          _.name.nonEmpty,
          _.name,
          _ => name
        )
      ),
    )

  private def renderNodeMGName(
                                name: String,
                                deepLevel: Int,
                                deepPath: String,
                                mappings: Seq[NodeMG],
                                field: Option[MetadataModels.Field],
                              ): ReactiveHtmlElement[html.Div] =
    div(
      cls := "slds-col slds-grid slds-grid--vertical",
      common.ui.Attribute.Description := s"MGn$deepLevel:$deepPath",
      mappings.condition(
        _.nonEmpty,
        _.map(mapping => div(
          styleAttr := "font-size: 1.1rem; min-height: 2.5rem; line-height: 2.5rem;",
          cls := "slds-grid slds-grid--vertical-align-center slds-is-relative",
          div(
            field.map(_.name)
              .filter(_.nonEmpty)
              .orElse(name.some)
              .filter(_.nonEmpty)
              .map(span(_, cls := "slds-m-right--x-small")),
            badges(Seq(
              mapping.rawValue.map(_.trim).map((_, mapping.direction)).flatMap {
                case ("[]", Some(MappingModels.Direction.writeOnly)) => ("empty-array", None, Nil).some
                case (v, Some(MappingModels.Direction.writeOnly)) => ("raw-value", v.some, Nil).some
                case _ => None
              },
            ).flatten),
          ),
          field.map(f => small(
            cls := "slds-m-left--xx-small",
            styleAttr := "padding: 1px 11px; border-radius: 8px; line-height: 1.1;",
            backgroundColor := s"${f.`type`.basisType.color}",
            f.getDataType
          )),
        )),
        _ => div(
          styleAttr := "font-size: 1.1rem; min-height: 2.5rem; line-height: 2.5rem;",
          cls := "slds-grid slds-grid--vertical-align-center slds-is-relative",
          span(
            field.map(_.name)
              .filter(_.nonEmpty)
              .getOrElse[String](name)
          ),
          field.map(f => small(
            cls := "slds-m-left--xx-small",
            styleAttr := "padding: 1px 11px; border-radius: 8px; line-height: 1.1;",
            backgroundColor := s"${f.`type`.basisType.color}",
            f.getDataType
          )),
        ),
      )
    )

  private def renderNodeMDDetails(
                                   deepLevel: Int,
                                   deepPath: String,
                                   field: MetadataModels.Field,
                                   onClickField: Observer[(String, Option[MetadataModels.Field])],
                                 ): ReactiveHtmlElement[html.Div] =
    div(
      common.ui.Attribute.Description := s"MDd$deepLevel:$deepPath",
      cls := "slds-col slds-grid slds-grid--vertical",
      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-is-relative",
        div(
          cls := "slds-col slds-grid slds-grid--vertical-align-center growing-block au-truncate",
          span(field.getDataType, cls := "slds-m-right--x-small",
            backgroundColor := s"${field.`type`.basisType.color}",
            padding := "1px 11px",
            borderRadius := "8px"
          ),
          badges(Seq(
            field.role.map(v => ("role", v.label.some, Nil)),
            Option.when(field.required)("required").map((_, None, Nil)),
            Option.when(field.readOnly)("read-only").map((_, None, Nil)),
            Option.when(field.primaryReference.contains(true))("primary-reference").map((_, None, Nil)),
            field.referenceTo.flatMap {
              case v if v.nonEmpty => ("reference-to", v.some, Nil).some
              case _ => None
            },
            field.relationshipName.flatMap {
              case v if v.nonEmpty => ("relationship-name", v.some, Nil).some
              case _ => None
            },
          ).flatten),
        ),
        Option.when(deepLevel == 0)(
          div(
            cls := "slds-grid slds-grid--vertical-align-center",
            material.Icon(
              _ => cls := "light slds-m-left_large clickable medium mat-outlined",
              _ => "edit",
              _ => composeEvents(onClick.stopPropagation)(_
                .mapTo((field.name, Some(field))))
                --> onClickField,
            ),
            material.Icon(
              _ => cls := "light slds-m-left_large clickable medium mat-outlined",
              _ => "delete_outline",
              _ => composeEvents(onClick.stopPropagation)(_
                .mapTo((field.name, None)))
                --> onClickField,
            )
          )
        ),
      )
    )

  private def renderNodeMGDetails(
                                   deepLevel: Int,
                                   deepPath: String,
                                   mappings: Seq[NodeMG],
                                   field: Option[MetadataModels.Field],
                                   onClickMap: Observer[(String, Option[MappingModels.Field])],
                                   $readOnlyMode: Signal[Boolean],
                                 ): ReactiveHtmlElement[html.Div] =
    div(
      common.ui.Attribute.Description := s"MGd$deepLevel:$deepPath",
      cls := "slds-col slds-grid slds-grid--vertical",
      mappings.condition(
        _.nonEmpty,
        _.map {
          mapping =>
            div(
              cls := "slds-grid slds-grid--vertical-align-center slds-is-relative",
              minHeight := "2.5rem",
              div(
                cls := "slds-grid slds-grid--vertical-align-center slds-m-right--medium",
                maxWidth := "7.4rem",
                width := "100%",
                div(cls := "line"),
                span(cls := "mapping-button connect", "Connect"),
                div(cls := "line"),
              ),
              div(
                cls := "slds-col slds-grid slds-grid--vertical-align-center growing-block au-truncate",
                mapping.providerFields.map(span(_, cls := "slds-m-right--x-small")),
                badges(Seq(
                  mapping.index.map(_.trim).flatMap {
                    case "*" => ("for all elements", None, Nil).some
                    case "0" => ("first element only", None, Nil).some
                    case v if v.nonEmpty => ("index", s"[$v]".some, Nil).some
                    case _ => None
                  },
                  mapping.direction.flatMap {
                    case MappingModels.Direction.bidirectional => None
                    case d => Some((d.label, None, Nil))
                  },
                  mapping.rawValue.map(_.trim).map((_, mapping.direction)).flatMap {
                    case ("[]", Some(MappingModels.Direction.readOnly)) => ("empty-array", None, Nil).some
                    case (v, Some(MappingModels.Direction.readOnly)) => ("raw-value", v.some, Nil).some
                    case (v, Some(MappingModels.Direction.bidirectional)) => ("raw-value", v.some, "red" :: Nil).some
                    case _ => None
                  },
                  mapping.mapper.map(m => ("mapper", m.label.some, Nil)),
                  mapping.tag.flatMap {
                    case v if v.nonEmpty => ("tag", v.some, Nil).some
                    case _ => None
                  },
                  mapping.description.flatMap {
                    case v if v.nonEmpty => ("description", v.some, Nil).some
                    case _ => None
                  },
                ).flatten),
              ),
              div(
                cls := "slds-grid slds-grid--vertical-align-center",
                material.Icon(
                  _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                  _ => "edit",
                  _ => composeEvents(onClick.stopPropagation)(_
                    .mapTo((mapping.deepPath, mapping.origin.some)))
                    --> onClickMap,
                ),
                material.Icon(
                  _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                  _ => "delete_outline",
                  _ => composeEvents(onClick.stopPropagation)(_
                    .mapTo((mapping.deepPath, None)))
                    --> onClickMap,
                )
              ),
            )
        },
        _ => child <-- $readOnlyMode.map {
          case true =>  div(
            cls := "slds-grid slds-grid--vertical-align-center",
            cursor := "pointer",
            maxWidth := "7.4rem",
            div(cls := "line"),
            span(cls := "mapping-button", "Map"),
            div(cls := "line disconnect"),
            composeEvents(onClick.stopPropagation)(_
            .mapTo((deepPath, Some(MappingModels.Field(clientFields = deepPath :: Nil)))))
          --> onClickMap,
          )
          case _ => div(
            cls := "slds-grid slds-grid--vertical-align-center",
          )
        },

      ),
    )

  private def badges(dataset: Seq[(String, Option[String], Seq[String])]): ReactiveHtmlElement[html.Div] = {
    val showOnMenu = Var(Option.empty[String])
    div(
      cls := "slds-grid slds-grid--vertical-align-center",

      material.Menu(
        _ => cls := "info-popup",
        _.open <-- showOnMenu.signal.map(_.exists(_.nonEmpty)),
        _.corner := "BOTTOM_LEFT",
        _.onClosed.mapTo(None) --> showOnMenu.writer,
        _.slots.default(
          div(
            cls := "slds-grid slds-p-around--medium popup-content break-word",
            child.text <-- showOnMenu.signal.map(_.getOrElse[String]("")),
          )
        )
      ),

      dataset
        .map {
          case (label, Some(description), cssClasses) if description.trim.nonEmpty =>
            small(
              cls := "badge clickable slds-m-right--x-small",
              cls := cssClasses.mkString(" "),
              label,
              composeEvents(onClick.stopPropagation)(_
                .mapTo(Some(description)))
                --> showOnMenu.writer,
            )
          case (label, _, _) =>
            small(
              cls := "badge slds-m-right--x-small",
              label,
            )
        }
    )
  }

  private def buttons(dataset: Seq[(String, Observer[Unit])]): Seq[Button.El] =
    dataset.map(btn => Button(
      _ => cls := "slds-m-left--small blue",
      _.label := btn._1,
      _ => onClick.mapTo(()) --> btn._2,
    ))

  private def renderHeader(
                            $virtualObjectName: Signal[Option[String]],
                            $providerObjectName: Signal[Option[String]],
                          ): ReactiveHtmlElement[html.Div] = {
    div(
      cls := "table-header slds-m-bottom_x-small",
      children <-- $virtualObjectName
        .combineWith($providerObjectName)
        .map {
          case (Some(virtualMD), Some(providerMD)) if virtualMD.nonEmpty && providerMD.nonEmpty =>
            virtualMD :: providerMD :: Nil
          case (None, None) =>
            "Name" :: "Type" :: Nil
          case _ =>
            "Virtual fields" :: "Provider fields" :: Nil
        }
        .map {
          case v :: p :: Nil => Seq(
            span(cls := "slds-col growing-block au-truncate gray", marginLeft := "2.1rem", v),
            if (v != "Name" && p != "Type") div(
              cls := "slds-grid slds-size--7-of-12 au-truncate gray",
              span(cls := "slds-m-right--medium", maxWidth := "7.4rem", width := "100%"),
              div(
                cls := "slds-grid slds-grid--vertical-align-center",
                material.Icon(
                  _ => cls := "slds-m-right--small",
                  _ => styleAttr := "transform: rotate(90deg);  color: var(--mat-grey-500);",
                  _ => "merge",
                ),
                span(p)
              )
            ) else span(cls := "slds-size--7-of-12 au-truncate gray", p)
          )
          case l =>
            l.map(title => span(cls := "slds-col au-truncate gray", title))
        }
    )
  }
}
