package root_pages.aurinko_pages.app.sync

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Dialog.El
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.TabBar.Tab
import com.github.uosis.laminar.webcomponents.material.{Button, Checkbox, CircularProgress, Dialog, Formfield, Icon, Radio, Select, Switch, TabBar, Textfield}
import portal_router.{Page, PortalRouter, SyncOrgPage, SyncOrgsPage, SyncUserPage}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.SyncRuleType.SyncRuleType
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalOptionOps, ValueToObservableOps}
import common.ui.breadcrumbs.{BreadcrumbsComponent, BreadcrumbsItem}
import common.{EmptyFieldException, FormModel, InstantOps, PingTools, ResyncOptions, SyncError, SyncRuleType, SyncService, SyncState, SyncStatus, SyncUser, SyncUserEditModel, TeamMemberRole, TemplateGroup, nameFieldPattern}
import common.ui.animateVisibilityModifier
import common.ui.buttons_pair.ButtonsPairComponent
import common.ui.expansion_panel.{AutoDismissiblePanelsGroup, ExpansionPanelComponent}
import common.ui.element_binders.DialogModifying
import common.forms.{CheckboxFormOps, FormsLocalExceptionHandling, SelectFormOps, TextfieldFormOps}
import org.scalajs.dom
import org.scalajs.dom.html
import service.apis.sync_api.SyncApi
import service.portal_state.{PortalState, TeamMemberAccess}
import service.scroll_ops.ScrollOps

import scala.util.Try

class SyncUserComponent($route: Signal[SyncUserPage],
                        syncApi: SyncApi,
                        documentScrollOps: ScrollOps,
                        portalRouter: PortalRouter,
                        portalState: PortalState,
                       ) {

  private val teamMemberAccess = new TeamMemberAccess(portalState.$team)

  val userBus: EventBus[SyncUser] = new EventBus[SyncUser]

  val services: Var[List[SyncService]] = Var(Nil)

  val $syncRuleTypes: Signal[List[SyncRuleType]] = services.signal.map(_.flatMap(_.activeSyncRules).distinct.sortBy(_.toString))

  //int - delay
  val syncStatus: Var[Option[SyncStatus]] = Var(None)
  val reloadSyncStatusBus: EventBus[Int] = new EventBus[Int]
  val reloadTableBus = new EventBus[Unit]

  val syncStatusObserver: Observer[SyncStatus] = Observer[SyncStatus](onNext = status => {
    if (syncStatus.now().exists(_.syncState != SyncState.IDLE) && status.syncState == SyncState.IDLE) {
      reloadTableBus.emit(())
    }
    syncStatus.set(Some(status))
    if (status.syncState != SyncState.IDLE) {
      reloadSyncStatusBus.emit(3000)
    }
  })


  val $syncStatus: EventStream[SyncStatus] = syncStatus.signal.changes.collect { case Some(v) => v }

  val $isIdle: Signal[Boolean] = $syncStatus.map(_.syncState == SyncState.IDLE).startWith(true)

  val errorsList: Var[Option[List[SyncError]]] = Var(None)

  def userBadges(user: SyncUser): Div = div(
    cls := "slds-grid slds-wrap badge-container",
    if (user.disabled) small(cls := "badge orange slds-m-right--xx-small", "User disabled") else None,
    if (user.admin) small(cls := "badge slds-m-right--xx-small", "Admin") else None,
    if (user.manager) small(cls := "badge slds-m-right--xx-small", "Manager") else None,
    if (user.syncEnabled) small(cls := "badge slds-m-right--xx-small", "Sync enabled") else None,
    if (user.useAurinko) small(cls := "badge slds-m-right--xx-small", "use aurinko") else None,
    if (user.pkgCheckFailed.getOrElse(false)) small(cls := "badge", "Package issues") else None,
  )

  def renderError(error: SyncError): ReactiveHtmlElement[html.Div] = error.notificationType.syncType match {
    case Some(syncType) => div(
      cls := "section-warning slds-m-bottom--small slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
      Icon(_ => "feedback", _ => cls := "small slds-m-right--small"),
      p(
        cls := "growing-block text--word-break--all",
        s"The sync encountered " +
          s"${Try(error.details.get.toInt).getOrElse(throw new Exception(s"failed to calc ${error.notificationType.value} errors count"))}" +
          s" errors during updating/creating ${syncType.toString.toLowerCase()}."
      ),

      child.maybe <-- services.signal.map(_.flatMap(_.activeSyncRules)).map(v => v.contains(syncType)).map {
        case true =>
          Some(small(
            "View",
            cls := "blue clickable slds-m-left--small",
            onClick.mapTo((syncType, error.notificationType.syncFilters)) --> activeTab,
          ))
        case false => None
      }
    )
    case None => div(
      cls := "section-error slds-m-bottom--small slds-grid slds-grid--vertical-align-center",
      Icon(_ => "error", _ => cls := "small slds-m-right--small"),
      p(
        cls := "text--word-break--all",
        if (error.details.getOrElse("") == "PARENT") {
          span(
            child.text <-- services.signal.map(_.find(_.id == error.serviceId.getOrElse("-1").toInt)).map {
              case Some(service) => service.serviceType.value.toLowerCase.capitalize + " error: "
              case None => ""
            })
        } else None,
        error.message
      )
    )
  }

  def buildServicePanel(id: String, initialService: SyncService, $service: Signal[SyncService]): ExpansionPanelComponent = {
    ExpansionPanelComponent(
      header = div(
        cls := "slds-grid slds-grid--align-spread growing-block slds-p-right--small slds-grid--vertical-align-center",
        span(
          child <-- $service.map(s => div(
            cls := "slds-grid slds-grid--vertical",
            div(cls := "slds-col", s.displayName),
            small(cls := "slds-col gray text-xx-small", s.`type`),
          ))
        ),

        Switch(
          _.checked <-- $service.map(_.offline).map(!_),
          _ => composeEvents(onChange.mapToChecked)(_
            .withCurrentValueOf($service)
            .withCurrentValueOf($route)
            .flatMap { case (active, service, route) =>
              syncApi.updateService(route.appKey, route.orgId, route.userId, service.copy(offline = !active))
            }
            .map(updated => services.now.map(svc => if (svc.id == updated.id) updated else svc))
          ) --> services,
          _ => onClick.stopPropagation --> Observer.empty,
        )
      ),
      body = Some(div(
        div(
          cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
          small(cls := "gray", "Type"),
          span(child.text <-- $service.map(_.`type`))
        ),

        child.maybe <-- $service.map(_.server)
          .map(_
            .map { server =>
              div(
                cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                small(cls := "slds-size--1-of-3 gray", "Server url"),
                span(server)
              )
            }),

        child.maybe <-- $service.map(_.email)
          .map(_
            .map {
              email =>
                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "slds-size--1-of-3 gray", "Email"),
                  span(email)
                )
            }),

        div(
          cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
          small(cls := "slds-size--1-of-3 gray", "Sync"),
          span(child.text <-- $service.map(_.activeSyncApisLabel))
        )
      )),
      horizontalPadding = false,
      id = Some(id)
    )
  }

  // TODO: REVIEW: portalRouter has observer for this
  val navigationObserver: Observer[Page] = Observer[Page](onNext = route => portalRouter.navigate(route))

  val editModel: Var[Option[SyncUserEditModel]] = Var(None)

  def editPopup(user: SyncUser): Dialog.El = Dialog(
    _.open <-- editModel.signal.map(_.isDefined),
    _.onClosing.mapTo(None) --> editModel,
    _.heading <-- userBus.events.map(u => s"${u.firstName.getOrElse("")} ${u.lastName.getOrElse("")}"),
    //    _.hideActions := true,

    _ => child.maybe <-- editModel.signal.nestedMap { model => {
      val templateGroups = Var[List[TemplateGroup]](Nil)
      div(
        div(
          FormsLocalExceptionHandling.errorView(model.formError.signal),
          Textfield(
            _ => cls := "slds-col slds-grid slds-m-vertical--medium",
            _.outlined := true,
            _.label := "First name",
            _.pattern := nameFieldPattern,
            _.value <-- model.firstName,
            _ => onInput.mapToValue --> model.firstName,
            _.required := true
          ).bindToForm(model.formState),

          Textfield(
            _ => cls := "slds-col slds-grid slds-m-vertical--medium",
            _.outlined := true,
            _.label := "Last name",
            _.pattern := nameFieldPattern,
            _.value <-- model.lastName,
            _ => onInput.mapToValue --> model.lastName,
            _.required := true
          ).bindToForm(model.formState),

          Textfield(
            _ => cls := "slds-col slds-grid slds-m-vertical--medium",
            _.outlined := true,
            _.label := "Email",
            _.value <-- model.email,
            _ => onInput.mapToValue --> model.email,
            _.`type` := "email",
            _.required := true
          ).bindToForm(model.formState),
        ),
        div(
          cls := "slds-grid slds-m-vertical--medium",

          $route.flatMap(route => syncApi.templateGroups(route.appKey, route.orgId)) --> templateGroups.writer,

          child <-- templateGroups.signal.withCurrentValueOf(model.templGroupId).map(v => {
            val gId = v._2.getOrElse(0)
            val gName = model.templGroupName.getOrElse("")
            v._1.find(g => if (gId != 0) g.id == gId else g.name == gName)
          }).map(_.getOrElse(TemplateGroup(0, ""))).map(currentGroup =>
            Select(
              _ => cls := "slds-col",
              _.outlined := true,
              _.label := "Template group",
              _.value := currentGroup.id.toString,
              _ => children <-- templateGroups.signal.map(g => g.map(g =>
                ListItem(
                  _.value := g.id.toString,
                  _.activated := g.id == currentGroup.id,
                  _.selected := g.id == currentGroup.id,
                  _ => g.name,
                  _ => onClick.mapTo(g.id) --> Observer[Int](onNext = v => {
                    model.templGroupName = Some(g.name)
                    model.templGroupId.set(Some(v))
                  }),
                ))),
            ).bindToForm(model.formState)
          ),
        ),
        div(
          cls := "slds-p-top--small",
          small(cls := "gray", "Status")
        ),
        div(
          cls := "slds-grid slds-grid--vertical-align-center",

          Formfield(
            _ => cls := "slds-m-right--x-large",
            _.label := "Admin",

            _.slots.default(Checkbox(
              _.checked <-- model.admin.signal,
              _.onChange.mapToChecked --> model.admin,
            ).bindToForm(model.formState))
          ),

          Formfield(
            _ => cls := "slds-m-right--x-large",
            _.label := "Manager",

            _.slots.default(Checkbox(
              _.checked <-- model.manager.signal,
              _.onChange.mapToChecked --> model.manager,
            ).bindToForm(model.formState))
          ),

          Formfield(
            _ => cls := "slds-m-right--x-large",
            _.label := "Disabled",

            _.slots.default(Checkbox(
              _.checked <-- model.disabled,
              _.onChange.mapToChecked --> model.disabled,
            ).bindToForm(model.formState))
          ),

          Formfield(
            _.label := "Sync enabled",
            _.slots.default(Checkbox(
              _.checked <-- model.syncEnabled,
              _.onChange.mapToChecked --> model.syncEnabled,
            ).bindToForm(model.formState))
          ),
        ),
      )
    }
    },
    _.slots.primaryAction(ButtonsPairComponent[SyncUser, dom.MouseEvent](
      primaryDisabled = editModel.signal
        .flatMap(_.$traverse(_.formState.$submitAllowed))
        .map(!_.contains(true)),

      primaryEffect = () => ()
        .streamed
        .sample(editModel.signal)
        .collect {
          case Some(model) => model
        }
        .withCurrentValueOf($route)
        .flatMap { case (model, route) => syncApi.updateUser(route.appKey, route.orgId, model.toApiModel) }
        .withErrorHandlingAndCollect(
          FormsLocalExceptionHandling
            .handler(str => editModel.now.foreach(_.formError.set(str.some)))),
      primaryObserver = Observer[SyncUser](onNext = u => {
        userBus.emit(u)
        editModel.set(None)
      }),

      secondaryObserver = editModel.writer.contramap((_: dom.MouseEvent) => None)
    ).node),
  )
    .withPing(syncApi)
    .withFormCaching(
      portalState.FormCache,
      portalState.Session.$sessionExpiredEvents,
      editModel,
      user.toEditModel.signaled,
      (m: SyncUserEditModel) => {
        editModel.now.foreach(_.update(m))
      },
      (cashedForm: FormModel) => cashedForm match {
        case org: SyncUserEditModel => org.some
        case _ => None
      },
      () => editModel.now.foreach(_.formState.validate())
    )

  private val activeTab: Var[(SyncRuleType, Option[Set[String]])] = Var(null, None)

  private val resyncOptions: Var[Option[ResyncOptions]] = Var(None)

  val resyncOptionsPopup: El = Dialog(
    _.heading := "Sync run advanced configuration",
    _.open <-- resyncOptions.signal.map(_.isDefined),
    _.onClosing.mapTo(None) --> resyncOptions.writer,
    _.hideActions := true,
    _ => PingTools.dialogBinders(syncApi),

    _ => children <-- resyncOptions.signal.map {
      case Some(options) => div(
        cls := "negative-m-bottom--medium",

        children <-- $syncRuleTypes.map(_.map(t => Formfield(
          _.label := s"${t.label}: full rescan",
          _.slots.default(Checkbox(
            _.onChange.mapToChecked --> options.toggleRuleObserver(t),
            _.checked <-- options.checked(t)
          ))
        )))) ::
        ButtonsPairComponent[Int, Option[ResyncOptions]](
          primaryButtonText = "Sync now",
          primaryEffect = () => EventStream.fromValue(()).sample($route)
            .flatMap(page => syncApi.postStatus(page.appKey, page.orgId, page.userId, options.toApiBody))
            .mapTo(0),
          primaryObserver = Observer.combine(reloadSyncStatusBus.writer, resyncOptions.writer.contramap((_: Int) => None)),
          secondaryEffect = () => EventStream.fromValue(None),
          secondaryObserver = resyncOptions.writer,
          cssClass = "dialog-submit-buttons"
        ).node ::
        Nil
      case None => Nil
    }
  )

  val eventBinders = List(
    $route.flatMap(page => syncApi.syncUser(page.appKey, page.orgId, page.userId)) --> userBus.writer,

    userBus.events.filter(u => u.syncEnabled && syncStatus.now.isEmpty).mapTo(0) --> reloadSyncStatusBus,

    $route.flatMap(route => syncApi.services(route.appKey, route.orgId, route.userId)) --> services,

    reloadSyncStatusBus.events.flatMap(ms => EventStream.fromValue(()).delay(ms))
      .sample($route)
      .flatMap(page => syncApi.syncStatus(page.appKey, page.orgId, page.userId))
      --> syncStatusObserver
  )

  val node: Div = div(
    cls := "x-nested-page",
    div(
      cls := "nav-filters-container",
      BreadcrumbsComponent(
        BreadcrumbsItem("Sync organizations".signaled,
          $route.map(r => portalRouter.router.absoluteUrlForPage(SyncOrgsPage(r.appKey))).some),

        BreadcrumbsItem(syncApi.$organization.map(_.name),
          $route.map(r => portalRouter.router.absoluteUrlForPage(SyncOrgPage(r.appKey, r.orgId))).some),

        BreadcrumbsItem(userBus.events.map(_.name).startWith(None).getOrElse("Sync user"), None)
      ))
      .amend(cls <-- documentScrollOps.$scrolled
        .map { case true => "shadow" case _ => "" }),


    child <-- userBus.events.map(user => div(
      cls := "content-padding",
      div(
        cls := "left-section",
        paddingTop <-- documentScrollOps.$scrollTop.changes
          .debounce(0).map(i => s"calc(var(--au-header-height) - ${i}px)"),

        children <-- userBus.events.map(user => div(
          cls := "left-section-header",

          div(
            cls := "slds-grid slds-grid--align-spread ",
            div(
              cls := "slds-m-bottom--medium",
              p(s"${user.firstName.getOrElse("")} ${user.lastName.getOrElse("")}", cls := "title--level-1"),
              userBadges(user)
            ),
            div(

            child.maybe <-- UserSyncSettings.$userSettings.map{
              case Some(userSettings) if userSettings.logEmail || userSettings.syncCalendar || userSettings.syncTasks || userSettings.syncContacts => Some(Icon(
              _ => cls := "light medium clickable slds-m-right--medium slds-m-top--x-small mat-outlined",
              _ => "settings",
              _ => onClick.mapTo(true) --> UserSyncSettings.settingsModel
            ))
              case _ => None},
              child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen(Icon(
              _ => cls := "light medium clickable slds-m-top--x-small mat-outlined",
              _ => "edit",
              _ => composeEvents(onClick)(_.mapTo(user.toEditModel).map(Some(_))) --> editModel
            )))
          )) ::
          div(
            cls := "content-container",
            div(
              cls := "content",
              div(
                cls := "border-top--light border-bottom--light",

                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Id"),
                  span(user.id),
                ),

                if (user.extId.getOrElse("").nonEmpty) div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "External id"),
                  span(user.extId),
                ) else None,

                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Email"),
                  span(user.email),
                ),

                div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Timezone"),
                  span(user.timeZoneInfo),
                ),

                if (user.templGroupName.isDefined) div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Template group"),
                  span(user.templGroupName),
                ) else None,

                child.maybe <-- $route.flatMap(v => syncApi.userRules(v.appKey, v.orgId, v.userId)).map(v => v.filter(_.enabled)).map {
                  case v if v.nonEmpty => Some(div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Sync"),
                    span(v.map(_.`type`.label.capitalize).mkString(", ")),
                  ))
                  case _ => None
                },

                if (user.createdAt.isDefined) div(
                  cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                  small(cls := "gray", "Created at"),
                  span(user.createdAt.get.toPrettyLocalFormat)
                ) else None,
              ),

              div(
                div(
                  cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center slds-p-vertical--small",
                  p("Sync", cls := "title--level-2"),
                ),

                child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.developer).andThen(div(
                  cls := "slds-grid slds-grid--vertical-align-center slds-m-bottom--small",

                  Button(
                    _.label := "Sync now",
                    _.disabled <-- $syncStatus.map(_.syncState != SyncState.IDLE),
                    _.outlined := true,
                    _.icon := "sync",
                    //                    _ => cls := "white moved-left--xxxx-small",
                    _ => composeEvents(onClick)(_
                      .sample($route)
                      .flatMap(page => syncApi.postStatus(page.appKey, page.orgId, page.userId, None))
                      .mapTo(0)) --> reloadSyncStatusBus.writer
                  ),


                  div(
                    cls := "slds-grid slds-grid--vertical-align-center",
                    child.maybe <-- $syncRuleTypes.map(_.nonEmpty).map {
                      case true => Some(span(
                        "Advanced",
                        cls := "blue  slds-m-left--medium",
                        cls <-- $isIdle.map { case false => "light" case _ => "clickable" },

                        composeEvents(onClick)(_
                          .sample($isIdle)
                          .filter(_ == true)
                          .mapTo(Some(ResyncOptions()))) --> resyncOptions
                      ))
                      case false => None
                    }
                  )
                )),


                div(
                  //cls := "slds-grid slds-grid--vertical-align-center slds-m-vertical--medium",
                  cls := "slds-grid slds-grid--vertical",
                  cls <-- syncStatus.signal.map(_.isDefined).map { case true => "slds-m-vertical--medium" case false => "" },
                  child.maybe <-- $syncStatus.map {
                    case status if status.syncState == SyncState.IDLE => Some(small(cls := "gray", "Last ran at"))
                    case _ => None
                  },
                  div(
                    child <-- $syncStatus.map {
                      case status if status.syncState == SyncState.IDLE =>
                        status.userLastSynced.getOrElse(throw EmptyFieldException("userLastSynced")).toPrettyLocalFormat
                      case status => div(
                        cls := "slds-grid slds-grid--vertical-align-center",
                        p("Sync ", status.syncState.toString.toLowerCase, cls := "slds-m-right--medium"),
                        CircularProgress(
                          _ => cls := "",
                          _.closed := false,
                          _.indeterminate := true,
                          _.density := -8
                        )
                      )
                    }
                  )
                ),


                {
                  val errorsToShow = syncStatus.signal.map {
                    case None => Nil
                    case Some(syncStatus) =>
                      val syncError = syncStatus.errors.getOrElse(Nil).find(_.notificationType.syncType.isEmpty)
                      val pushErrors = syncStatus.errors.getOrElse(Nil).filter(_.notificationType.syncType.isDefined).map(Some(_))

                      (syncError :: pushErrors).collect { case Some(v) => v }.distinct
                  }

                  div(
                    inContext(animateVisibilityModifier(errorsToShow.map(_.nonEmpty))),
                    children <-- errorsToShow.map(_.map(renderError)),
                  )
                },

                AutoDismissiblePanelsGroup[SyncService](services.signal, buildServicePanel, (s: SyncService) => s.id.toString).node
              )
            )
          ) :: Nil
        )
      ),

      editPopup(user),
      UserSyncSettings($route, syncApi),
      resyncOptionsPopup,


      div(
        cls := "right-section",
        child <-- $syncRuleTypes.map(syncRulesTypes =>
          div(
            onMountCallback(_ => activeTab.set((syncRulesTypes.headOption.orNull, None))),

            TabBar(
              _ => cls := "content-width",
              _ => syncRulesTypes.map(t =>
                Tab(
                  _.label := t.label,
                  _.onInteracted.mapTo((t, None)) --> activeTab,
                  _ => cls <-- activeTab.signal.map(_._1 == t).map {
                    case true => "active"
                    case _ => ""
                  }
                )
              ),
              _.activeIndex <-- activeTab.signal.map(_._1).map(t => syncRulesTypes.indexOf(t)),
            ),

            child <-- activeTab.signal.map {
              case (SyncRuleType.Emails, filters) => UserEmailsSyncComponent($route, syncApi, Var(filters.getOrElse(Set[String]())), reloadTableBus.events, documentScrollOps)
              case (SyncRuleType.Calendar, filters) => UserCalendarSyncComponent($route, syncApi, Var(filters.getOrElse(Set[String]())), reloadTableBus.events, documentScrollOps)
              case (SyncRuleType.Contacts, filters) => UserContactsSyncComponent($route, syncApi, Var(filters.getOrElse(Set[String]())), reloadTableBus.events, documentScrollOps)
              case (SyncRuleType.Tasks, filters) => UserTasksSyncComponent($route, syncApi, Var(filters.getOrElse(Set[String]())), reloadTableBus.events, documentScrollOps)
              case (_, _) => emptyNode
            }
          )
        )
      )
    )),
  )
    .amend(eventBinders)
}
