package root_pages.aurinko_pages.app.sync

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.{AppKey, InstantOps, SyncApiPage, SyncOrganization}
import portal_router.{PortalRouter, SyncOrgPage, SyncOrgsPage}
import service.apis.sync_api.SyncApi
import service.scroll_ops.ScrollOps
import wvlet.log.Logger

class SyncOrgsComponent($route: Signal[SyncOrgsPage],
                        syncApi: SyncApi,
                        documentScrollOps: ScrollOps,
                        portalRouter: PortalRouter
                       ) {

  val reloadBus = new EventBus[(Int, Int)]

  val searchVar = Var("")

  val $pageSize = $route.map(_.pageSize.getOrElse(syncApi.standardApiPageSize))
  //val pageSizeValue = Var(syncApi.standardApiPageSize)
  //pageSizeValue.signal.changes.mapTo(0) --> pageSizeReloadBus.writer
  private val log = Logger.of[SyncOrgsComponent]

  def requestOrgs(tuple: (Int, Int, AppKey)): EventStream[SyncApiPage[SyncOrganization]] =
    syncApi.organizations(tuple._3,
      searchVar.now(),
      limit = tuple._2,
      offset = tuple._1 * tuple._2,
    )

  def navigateToOrg(tuple: (AppKey, String)): Unit = portalRouter.navigate(SyncOrgPage(tuple._1, tuple._2))

  /*
  def handleQueryParams(page: SyncPage): Unit = {
    filters.searchText.set(page.search.getOrElse(""))
    filters.onlyWithErrors.set(page.filter.getOrElse("").contains("hasErrors"))
    filters.hasValidToken.set(Option.when(page.filter.getOrElse("").contains("active")) {!page.filter.contains("inactive")})

    if(page.accType.getOrElse("") == "both") {
      serviceAccountFilters.searchText.set(page.search.getOrElse(""))
      serviceAccountFilters.onlyWithErrors.set(page.filter.getOrElse("").contains("hasErrors"))
      serviceAccountFilters.hasValidToken.set(Option.when(page.filter.getOrElse("").contains("active")) {!page.filter.contains("inactive")})
    }

    //    if(page.pageNum.isDefined) {
    reloadPageBus.emit(page.pageNum.getOrElse(0))

    //    }
  }*/

  val node: Div = div(
    cls := "content-padding",

    $route --> Observer[SyncOrgsPage](onNext = p => println(p.pageNum)),

    div(
      cls := "headered-footered-section",
      div(
        cls := "header captions",
        "Organizations"
      ),
      div(
        cls := "slds-grid slds-grid--align-end",
        searchVar.signal
          .withCurrentValueOf($pageSize)
          .changes.map(changes => 0 -> changes._2) --> reloadBus.writer,
        SearchInputComponent(onChange = searchVar.writer,
          $value = searchVar.signal
        ).node,
      ),
      child <-- reloadBus.events
        .withCurrentValueOf($route.map(_.appKey))
        .flatMap(tuple => {
          portalRouter.router.replaceState(
            SyncOrgsPage(
              tuple._3,
              search = Option.when(searchVar.now.trim.nonEmpty) {
                searchVar.now
              },
              pageNum = Some(tuple._1),
              pageSize = Some(tuple._2)
            )
          )
          requestOrgs(tuple)
        })
        .withCurrentValueOf($route)
        .map(syncPage => {

          div(
            cls := "body",
            div(
              cls := "data-table",
              p(
                cls := "table-header",
                span(cls := "slds-size--1-of-12  gray", "Id"),
                span(cls := "slds-size--2-of-12  gray", "ExtId"),
                span(cls := "slds-size--3-of-12  gray", "Name"),
                span(cls := "slds-size--3-of-12  gray", "Domain"),
                span(cls := "slds-size--2-of-12  gray", "Registration date")
              ),
              syncPage._1.summary.map(org =>
                div(
                  cls := "table-row clickable",
                  composeEvents(onClick)(_.flatMap(_ => {
                    if (org.fromHeroku) {
                      syncApi.syncOrgFromHeroku(syncPage._2.appKey, org.extId.getOrElse(throw new Exception("Missing org ext id")))
                        .map(v => syncPage._2.appKey -> s"${v.id}_h")
                    }
                    else EventStream.fromValue(syncPage._2.appKey -> s"${org.id}")
                  })) --> Observer[(AppKey, String)](onNext = navigateToOrg),
                  //                onClick.mapTo((syncPage._2.appKey, org.orgId)) --> Observer[(AppKey, String)](onNext = navigateToOrg),

                  span(cls := "slds-size--1-of-12 text-bolder", org.id),
                  span(cls := "slds-size--2-of-12", org.extId),
                  span(cls := "slds-size--3-of-12  text-bolder", org.name),
                  i(cls := "slds-size--3-of-12 text-light", org.domain),
                  span(cls := "slds-size--2-of-12", org.regDate.toPrettyLocalFormat),
                  div(cls := "slds-size--1-of-12",
                    if (!org.isActive) small(cls := "orange badge", "inactive") else None,
                    //                  if (org.useAurinko) small(cls := "badge slds-m-left--xx-small", "use aurinko") else None
                  )
                )
              ),
              if (syncPage._1.total >= syncApi.standardApiPageSize)
                div(
                  Paginator(
                    pageNum = Signal.fromValue(syncPage._2.pageNum.getOrElse(0)),
                    totalCount = Signal.fromValue(syncPage._1.total),
                    pageSize = $pageSize,
                    onPageChange = Observer[(Int, Int)](onNext = i => {
                      reloadBus.emit(i._1 -> i._2)
                    }), // TODO: REVIEW: reloadBus has observer for this
                    documentScrollTopAfterPageChange = true,
                    documentScrollOps.some,
                    itemsPluralLabel = "Sync organizations",
                  ).node
                )
              else None,

              div(
                cls := (if (syncPage._1.total == 0) "" else "hidden"),
                p(
                  cls := "slds-grid slds-grid--align-center gray",
                  span("No sync organizations", cls := "gray"),
                )
              ),
            )
          )
        }),
      EventStream.fromValue(())
        .withCurrentValueOf($route)
        .map(page => {
          searchVar.set(page.search.getOrElse(""))
          page.pageNum.getOrElse(0) -> page.pageSize.getOrElse(syncApi.standardApiPageSize)
        }) --> reloadBus.writer
    )
  )
}
