package root_pages.aurinko_pages.app.virtual_api.helpers

import com.raquo.airstream.core.Observer
import service.apis.dynamic_api.DataMapperModels.{MappingModels, MetadataModels}

case object extension {
  case object Mapping {
    def classToMapping(
                        mapping: MappingModels.Mapping,
                        clientClass: String,
                        `class`: Option[MappingModels.Class],
                      ): MappingModels.Mapping = {
      var classes = mapping.data.getOrElse(MappingModels.ClassList()).classMappings

      if (`class`.isDefined) {
        val index = classes.indexWhere(_.clientClass == clientClass)
        if (index < 0)
          classes = classes.appended(`class`.get) // create
        else
          classes = classes.updated(index, `class`.get) // update
      } else
        classes = classes.filter(_.clientClass != clientClass) // delete

      mapping.copy(data = Some(MappingModels.ClassList(classMappings = classes)))
    }
  }

  case object Merge {
    case object Metadata {
      def classToMetadata(
                           metadata: MetadataModels.Metadata,
                           className: String,
                           data: Option[MetadataModels.Class],
                         ): MetadataModels.Metadata = {
        var classes = metadata.classes

        if (data.isDefined) {
          val index = classes.indexWhere(_.name == className)
          if (index < 0)
            classes = classes.appended(data.get) // create
          else
            classes = classes.updated(index, data.get) // update
        } else
          classes = classes.filter(_.name != className) // delete

        metadata.copy(data = Some(MetadataModels.ClassList(classes = classes)))
      }

      def fieldToClass(
                        `class`: MetadataModels.Class,
                        fieldName: String,
                        data: Option[MetadataModels.Field],
                      ): MetadataModels.Class = {
        var fields = `class`.fields.getOrElse(Nil)

        if (data.isDefined) {
          val index = fields.indexWhere(_.name == fieldName)
          if (index < 0)
            fields = fields.appended(data.get) // create
          else
            fields = fields.updated(index, data.get) // update
        } else
          fields = fields.filter(_.name != fieldName) // delete

        `class`.copy(fields = Some(fields))
      }

      def relationToClass(
                           `class`: MetadataModels.Class,
                           relationName: String,
                           data: Option[MetadataModels.Relation],
                         ): MetadataModels.Class = {
        var relations = `class`.relations.getOrElse(Nil)

        if (data.isDefined) {
          val index = relations.indexWhere(_.name == relationName)
          if (index < 0)
            relations = relations.appended(data.get) // create
          else
            relations = relations.updated(index, data.get) // update
        } else
          relations = relations.filter(_.name != relationName) // delete

        `class`.copy(relations = Some(relations))
      }
    }

    case object Mapping {
      def classToMapping(
                          mapping: MappingModels.Mapping,
                          clientClassName: String,
                          data: Option[MappingModels.Class],
                        ): MappingModels.Mapping = {
        var classes = mapping.classes

        if (data.isDefined) {
          val index = classes.indexWhere(_.clientClass == clientClassName)
          if (index < 0)
            classes = classes.appended(data.get) // create
          else
            classes = classes.updated(index, data.get) // update
        } else
          classes = classes.filter(_.clientClass != clientClassName) // delete

        mapping.copy(data = Some(MappingModels.ClassList(classMappings = classes)))
      }

      def fieldToClass(
                        `class`: MappingModels.Class,
                        clientFieldNames: Seq[String],
                        data: Option[MappingModels.Field],
                      ): MappingModels.Class = {
        var fields = `class`.fieldMappings

        if (data.isDefined) {
          val index = fields.indexWhere(f => clientFieldNames.diff(f.clientFields).isEmpty && f.clientFields.diff(clientFieldNames).isEmpty)
          if (index < 0)
            fields = fields.appended(data.get) // create
          else
            fields = fields.updated(index, data.get) // update
        } else
          fields = fields.filter(f => clientFieldNames.diff(f.clientFields).nonEmpty || f.clientFields.diff(clientFieldNames).nonEmpty) // delete

        `class`.copy(fieldMappings = fields)
      }

      def relationToClass(
                           `class`: MappingModels.Class,
                           clientRelationName: String,
                           data: Option[MappingModels.Relation],
                         ): MappingModels.Class = {
        var relations = `class`.relationMappings

        if (data.isDefined) {
          val index = relations.indexWhere(_.clientRelation == clientRelationName)
          if (index < 0)
            relations = relations.appended(data.get) // create
          else
            relations = relations.updated(index, data.get) // update
        } else
          relations = relations.filter(_.clientRelation != clientRelationName) // delete

        `class`.copy(relationMappings = relations)
      }
    }
  }

  case object Observers {
    def LogInfo(log: wvlet.log.Logger): Observer[String] = Observer[String](onNext = s => log.info(s))

    /**
     * Mapping
     */
    case object Mapping {
      def toClass(
                   observer: Observer[(String, MappingModels.Mapping)],
                   mapping: MappingModels.Mapping,
                 ): Observer[(String, Option[MappingModels.Class])] =
        observer
          .contramap((x: (String, List[MappingModels.Class])) => (x._1, mapping.copy(data = Some(MappingModels.ClassList(classMappings = x._2)))))
          .contramap((x: (String, Option[MappingModels.Class])) => {
            val classes = mapping.data.getOrElse(MappingModels.ClassList()).classMappings
            if (x._2.isDefined) {
              val index = classes.indexWhere(r => r.clientClass == x._1)
              if (index < 0)
                (x._1, classes.appended(x._2.get)) // create
              else
                (x._1, classes.updated(index, x._2.get)) // update
            } else
              (x._1, classes.filter(_.clientClass != x._1)) // delete
          })

      def toField(
                   observer: Observer[(String, MappingModels.Mapping)],
                   mapping: MappingModels.Mapping,
                   `class`: MappingModels.Class,
                 ): Observer[(Seq[String], Option[MappingModels.Field])] =
        toClass(observer, mapping)
          .contramap((x: (Seq[String], List[MappingModels.Field])) => (`class`.clientClass, Some(`class`.copy(fieldMappings = x._2))))
          .contramap((x: (Seq[String], Option[MappingModels.Field])) => {
            val key = x._1.sorted.mkString(",")
            val fields = `class`.fieldMappings
            if (x._2.isDefined) {
              val index = fields.indexWhere(f => f.clientFields.sorted.mkString(",") == key)
              if (index < 0)
                (x._1, fields.appended(x._2.get)) // create
              else
                (x._1, fields.updated(index, x._2.get)) // update
            } else
              (x._1, fields.filter(_.clientFields.sorted.mkString(",") != key)) // delete
          })

      def toRelation(
                      observer: Observer[(String, MappingModels.Mapping)],
                      mapping: MappingModels.Mapping,
                      `class`: MappingModels.Class,
                    ): Observer[(String, Option[MappingModels.Relation])] =
        toClass(observer, mapping)
          .contramap((x: (String, List[MappingModels.Relation])) => (`class`.clientClass, Some(`class`.copy(relationMappings = x._2))))
          .contramap((x: (String, Option[MappingModels.Relation])) => {
            val relations = `class`.relationMappings
            if (x._2.isDefined) {
              val index = relations.indexWhere(f => f.clientRelation == x._1)
              if (index < 0)
                (x._1, relations.appended(x._2.get)) // create
              else
                (x._1, relations.updated(index, x._2.get)) // update
            } else
              (x._1, relations.filter(_.clientRelation != x._1)) // delete
          })
    }

    /**
     * Metadata
     */
    case object Metadata {
      def toClass(
                   observer: Observer[(String, MetadataModels.Metadata)],
                   metadata: MetadataModels.Metadata,
                 ): Observer[(String, Option[MetadataModels.Class])] =
        observer.contramap((x: (String, Option[MetadataModels.Class])) => (
          x._1,
          extension.Merge.Metadata.classToMetadata(metadata, x._1, x._2),
        ))

      def toField(
                   observer: Observer[(String, MetadataModels.Metadata)],
                   metadata: MetadataModels.Metadata,
                   `class`: MetadataModels.Class,
                 ): Observer[(String, Option[MetadataModels.Field])] =
        toClass(observer, metadata)
          .contramap((x: (String, List[MetadataModels.Field])) => (`class`.name, Some(`class`.copy(fields = Some(x._2)))))
          .contramap((x: (String, Option[MetadataModels.Field])) => {
            val fields = `class`.fields.getOrElse(Nil)
            if (x._2.isDefined) {
              val index = fields.indexWhere(f => f.name == x._1)
              if (index < 0)
                (x._1, fields.appended(x._2.get)) // create
              else
                (x._1, fields.updated(index, x._2.get)) // update
            } else
              (x._1, fields.filter(_.name != x._1)) // delete
          })

      def toRelation(
                      observer: Observer[(String, MetadataModels.Metadata)],
                      metadata: MetadataModels.Metadata,
                      `class`: MetadataModels.Class,
                    ): Observer[(String, Option[MetadataModels.Relation])] =
        toClass(observer, metadata)
          .contramap((x: (String, List[MetadataModels.Relation])) => (`class`.name, Some(`class`.copy(relations = Some(x._2)))))
          .contramap((x: (String, Option[MetadataModels.Relation])) => {
            val relations = `class`.relations.getOrElse(Nil)
            if (x._2.isDefined) {
              val index = relations.indexWhere(f => f.name == x._1)
              if (index < 0)
                (x._1, relations.appended(x._2.get)) // create
              else
                (x._1, relations.updated(index, x._2.get)) // update
            } else
              (x._1, relations.filter(_.name != x._1)) // delete
          })
    }
  }
}
