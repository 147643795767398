package service.ui.spinner

import com.github.uosis.laminar.webcomponents.material.CircularProgress
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._

trait Spinner {
  def show(caller: SpinnerCaller): Unit

  def hide(caller: SpinnerCaller): Unit
}

case class SpinnerImp() extends Spinner {
  private val callers: Var[List[SpinnerCaller]] = Var(Nil)

  override def show(caller: SpinnerCaller): Unit = {
    callers.update(caller :: _)
  }

  override def hide(caller: SpinnerCaller): Unit = {
    callers.update(_.filterNot(_ == caller))
  }

  val node: Div =
    div(
      cls := "slds-align--absolute-center spinner-container",
      cls <-- callers.signal.map(l => if (l.nonEmpty) "" else "hidden"),
      CircularProgress(
        _ => cls := "global",
        _.closed := false,
        _.indeterminate := true,
        _.density := -4
      )
    )
}

class SpinnerCaller
