package root_pages.aurinko_pages.app.sync

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Fab
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import common.ui.expansion_panel.ExpansionPanelComponent
import common.ui.mat_components_styles.fixFabStyle
import common.ui.paginator.Paginator
import common.ui.search_input.SearchInputComponent
import common.{ContactsQuery, InstantOps, JsonEnum, SyncApiPage, SyncObjFilters, SyncRuleType}
import org.scalajs.dom
import root_pages.aurinko_pages.app.sync.widgets.{Flags, MergeHistoryGrid, ServicesGrid, SortableTableColumnHeader}
import portal_router.SyncUserPage
import service.apis.sync_api.SyncApi
import service.scroll_ops.ScrollOps

object UserContactsSyncComponent {
  case class Handler($route: Signal[SyncUserPage], syncApi: SyncApi, filters: SyncObjFilters) {
    val reloadBus = new EventBus[Unit]
    val $contacts: EventStream[SyncApiPage[ContactsQuery]] = reloadBus.events.sample($route)
      .flatMap { route =>
        syncApi.ruleQuery(
          route.appKey,
          route.orgId,
          route.userId,
          SyncRuleType.Contacts,
          filters,
        )
      }
      .map(_.asInstanceOf[SyncApiPage[ContactsQuery]])

    def renderRow(routeInfo: SyncUserPage)(
      @annotation.unused id: Int,
      initialContact: ContactsQuery,
      $shortContact: EventStream[ContactsQuery],
    ): Div = {

      val contact: Var[(ContactsQuery, Boolean)] = Var[(ContactsQuery, Boolean)]((ContactsQuery(id = 0), false))
      val $contact: Signal[ContactsQuery] = contact.signal.map(_._1)
      val expanded = Var(false)

      val panel = ExpansionPanelComponent(
        header = div(
          cls := "slds-grid slds-grid--vertical-align-center growing-block au-truncate",
          div(cls := "slds-size--1-of-12 au-truncate", child.text <-- $contact.map(_.id.toString)),
          div(cls := "slds-size--2-of-12 au-truncate", child.text <-- $contact.map(c => c.firstName.getOrElse(""))),
          div(cls := "slds-size--2-of-12 au-truncate", child.text <-- $contact.map(c => c.lastName.getOrElse(""))),
          div(cls := "slds-size--3-of-12 au-truncate", child.text <-- $contact.map(_.summary).map { case None => "" case Some(v) => v }),
          div(cls := "slds-size--2-of-12 au-truncate", child.text <-- $contact.map(_.updateTime).map { case None => "" case Some(v) => v.toPrettyLocalFormat }),
          div(cls := "slds-size--2-of-12 au-truncate",
            Flags(
              $contact.map(e => (
                (e.autoMerged, Flags.Badge(label = "auto merged")) ::
                  (e.syncEnabled, Flags.Badge(label = "sync enabled")) ::
                  (e.sameAsId > 0 && e.skipMerge, Flags.Badge(label = "target to merge")) ::
                  (e.sameAsId > 0 && e.sureMatch, Flags.Badge(label = "duplicate")) ::
                  (e.errorsDuringSync, Flags.Badge(label = "error", cls = "orange")) ::
                  Nil
                ).filter(v => v._1).map(_._2)),
            ),
          ),
        ),
        body = Some(div(
          child.maybe <-- $contact.map(_.firstName).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "First name"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $contact.map(_.lastName).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid  slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Last name"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $contact.map(_.jobTitle).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Job title"),
              div(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $contact.map(_.department).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Department"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $contact.map(_.companyName).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Company"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $contact.map(_.email.mkString(", ").trim).map {
            case "" => None
            case v => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Email"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $contact.map(_.phone.mkString(", ").trim).map {
            case "" => None
            case v => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Phone"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $contact.map(_.web.mkString(", ").trim).map {
            case "" => None
            case v => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Web"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $contact.map(_.addr.mkString(", ").trim).map {
            case "" => None
            case v => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Address"),
              span(cls := "au-truncate", v),
            ))
          },

          child.maybe <-- $contact.map(_.background).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Background"),
              pre(cls := "slds-m-around--none", v),
            ))
          },

          child.maybe <-- $contact.map(_.keywords).map {
            case None => None
            case Some(v) => Some(div(
              cls := "slds-grid slds-grid--vertical slds-m-bottom--medium",
              small(cls := "gray", "Keywords"),
              span(cls := "au-truncate", v),
            ))
          },

          Flags.Grid(
            $contact.map(e => (
              (e.autoMerged, Flags.Badge(label = "Auto merged")) ::
                (e.syncEnabled, Flags.Badge(label = "Sync enabled")) ::
                (e.sameAsId > 0 && e.skipMerge, Flags.Badge(label = "Target to merge")) ::
                (e.sameAsId > 0 && e.sureMatch, Flags.Badge(label = "Duplicate")) ::
                (e.errorsDuringSync, Flags.Badge(label = "Error", cls = "orange")) ::
                Nil
              ).filter(v => v._1).map(_._2)),
          ),

          ServicesGrid($contact.map(_.accountsSummary.getOrElse(Nil))),

          MergeHistoryGrid($contact.map(_.mergeHistory.getOrElse(Nil))),
        )),
        horizontalPadding = false,
        expanded = expanded.signal,
        onExpansionChange = expanded.writer
      )

      div(
        $shortContact --> Observer.combine(
          contact.writer.contramap((x: ContactsQuery) => (x, true)),
          expanded.writer.contramap((_: ContactsQuery) => false),
        ),

        panel.$isExpanded.withCurrentValueOf(contact.signal.map(_._2)).changes
          .filter(x => x._1 && x._2)
          .flatMap(_ => syncApi.ruleObject(routeInfo.appKey, routeInfo.orgId, routeInfo.userId, SyncRuleType.Contacts, initialContact.id))
          .map(_.asInstanceOf[ContactsQuery])
          --> contact.writer.contramap((x: ContactsQuery) => (x, false)),

        panel.node,
      )
    }
  }

  object Filters extends Enumeration with JsonEnum {
    case class Val(name: String, label: String) extends super.Val(name)

    type Filters = Val
    type EnumValue = Val

    val autoMerged: Filters = Val("autoMerged", "Auto merged")
    val syncEnabled: Filters = Val("syncEnabled", "Sync enabled")
    val showDupes: Filters = Val("showDupes", "Duplicate")
    val errors: Filters = Val("errors", "Errors")

    val All: List[Filters] = autoMerged :: syncEnabled :: showDupes :: errors :: Nil
  }

  object Columns extends Enumeration {
    case class Val(label: String, sizeCss: String, sortValue: Option[String] = None)

    type Columns = Val

    val id = Val("Id", "slds-size--1-of-12")
    val firstName = Val("First name", "slds-size--2-of-12", Some("firstName"))
    val lastName = Val("Last name", "slds-size--2-of-12", Some("lastName"))
    val summary = Val("Summary", "slds-size--3-of-12", None)
    val updateTime = Val("Update time", "slds-size--2-of-12", Some("updateTime"))
    val badges = Val("", "slds-size--2-of-12", None)

    val all = id :: firstName :: lastName :: summary :: updateTime :: badges :: Nil
  }

  // UI\HTML structures
  def apply(
             $route: Signal[SyncUserPage],
             syncApi: SyncApi,
             defaultFilters: Var[Set[String]] = Var(Set.empty),
             $reload: EventStream[Unit],
             documentScrollOps: ScrollOps
           ): Div = {
    val handler = Handler($route, syncApi, SyncObjFilters(defaultFilters))

    div(
      common.ui.Attribute.Selector := "sync.user_contacts_sync",

      $reload --> handler.reloadBus.writer,

      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread slds-m-top--medium",
        div(
          cls := "slds-grid slds-grid--vertical-align-center",
          Filters.All.map(filter =>
            Fab(
              _.label := filter.label,
              _.extended := true,
              _.showIconAtEnd := true,
              _.icon <-- handler.filters.$filters.map(_.contains(filter.name)).map { case true => "cancel" case false => "" },
              _ => cls := "slds-m-right--x-small",
              _ => cls <-- handler.filters.$filters.map(_.contains(filter.name)).map { case true => "" case _ => "inactive" },
              _ => onClick --> Observer[dom.MouseEvent](onNext = _ => handler.filters.toggleFilter(filter.name)),
              _ => onMountCallback(fixFabStyle)
            )
          )
        ),
        SearchInputComponent(onChange = handler.filters.searchText.writer).node
      ),

      child <-- $route.map { route =>

        div(
          child <-- handler.$contacts.map(_.summary.nonEmpty).toSignal().map {
            case true => div(
              cls := "data-table slds-m-top--medium",
              div(
                cls := "table-header with-expansion-panels",
                Columns.all.map {
                  case c if c.sortValue.isEmpty => span(c.label, cls := c.sizeCss)
                  case column => SortableTableColumnHeader(
                    label = column.label,
                    onSorted = Observer[dom.MouseEvent](onNext = _ => handler.filters.toggleSortParam(column.sortValue.get)),
                    state = handler.filters.sortParamState(column.sortValue.get),
                    cssClass = column.sizeCss
                  )
                }
              ),

              div(children <-- handler.$contacts.map(_.summary).split(_.id)(handler.renderRow(route))),

              div(
                cls <-- handler.$contacts.map(_.total < handler.filters.pagesLimit.now).map { case true => "hidden" case false => "" },
                Paginator(
                  pageNum = handler.filters.pageNum.signal,
                  totalCount = handler.$contacts.map(_.total).startWith(0),
                  pageSize = handler.filters.pagesLimit.signal,
                  onPageChange = Observer[(Int, Int)](onNext = i => {
                    handler.filters.pageNum.set(i._1)
                    handler.filters.pagesLimit.set(i._2)
                    handler.reloadBus.emit(())
                  }),
                  documentScrollTopAfterPageChange = true,
                  documentScrollOps.some,
                  itemsPluralLabel = "Contacts",
                ).node
              ),
            )
            case _ => p(cls := "gray slds-grid slds-grid--align-center slds-m-around--large", "No contacts")
          }
        )
      },

      handler.filters.$filters
        .combineWith(handler.filters.searchText.signal)
        .combineWith(handler.filters.$sorting) --> Observer[(Set[String], String, Set[String])](onNext = _ => {
        handler.filters.pageNum.set(0)
        handler.reloadBus.emit(())
      }),
    )
  }
}
