package root_pages.aurinko_pages.app.settings

import com.raquo.laminar.api.L._
import com.github.uosis.laminar.webcomponents.material.Textfield
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ui.AuFormField
import org.scalajs.dom.html
import root_pages.aurinko_pages.app.settings.ColorInput.{colorRegex, formatColor}

import scala.util.matching.Regex

case class ColorInput(colorVar: Var[String], fieldLabel: String, fieldCls: String = "", helpText: String, spanCls: String = "") {
  val classVar: Var[String] = Var("slds-m-bottom_xx-small" + fieldCls)

  val colorField: AuFormField = AuFormField(
    Textfield(
      _ => cls := "slds-col",
      _.outlined := true,
      _ => cls <-- classVar,
      _.label := fieldLabel,//"Primary color"
      _ => maxLength := 50,
      _.helper := helpText.text,
      _.helperPersistent := helpText.nonEmpty,

      _ => onInput.mapToValue --> colorVar,
      _ => colorVar.signal --> Observer[String](onNext = tryToSetColor),
      _.value <-- colorVar
    ),
    initialValidation = true,
    customValidation = Some((x: String) => if (coloredElement.ref.style.backgroundColor.split(" ").mkString("") != formatColor(x) && !colorRegex.matches(x) && x != "") false else true)
  )

  //colorField.node.ref.validityTransform = ((x:Any) => true)

  val coloredElement: ReactiveHtmlElement[html.Div] =
    div(cls := "color-element-values", backgroundColor := colorVar.now())


  val colorStatusVar: Var[Boolean] = if (coloredElement.ref.style.backgroundColor != "") Var(true) else Var(false)

//  val underDescription: ReactiveHtmlElement[html.Div] = div(span(helpText, cls := spanCls))

  def tryToSetColor(c: String): Unit = {
    coloredElement.ref.style.backgroundColor = c
    if (coloredElement.ref.style.backgroundColor.split(" ").mkString("") != formatColor(c) && !colorRegex.matches(c) && c != "") {
      //println("Got false")
      coloredElement.ref.style.backgroundColor = ""
      classVar.update(cssClasses => if (cssClasses.substring(cssClasses.length - 11) != " with-error")
        cssClasses + " with-error" else cssClasses)

    } else
      classVar.update(cssClasses => if (cssClasses.substring(cssClasses.length - 11) == " with-error")
        cssClasses.substring(0, cssClasses.length - 11) else cssClasses)

  }


  val node: ReactiveHtmlElement[html.Div] = div(
    div(
      cls := "slds-grid",
      styleAttr := "position: relative",
      colorField.node,
      div(styleAttr := "top: 1.5rem; right: 5px; position: absolute;", coloredElement)
    ),
//    underDescription
  )
}

case object ColorInput {
  def formatColor(c: String): String = {
    c.split(" ").mkString("").split(",").
      map(line => if (line.nonEmpty) if (line.head == '.') "0" + line else line).mkString(",")
  }

  val colorRegex: Regex = "^#(?:[0-9a-fA-F]{3}){1,2}$".r
}
