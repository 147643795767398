package root_pages.aurinko_pages.app.virtual_api.components

import com.github.uosis.laminar.webcomponents.material
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ui.mat_components_styles.{MountContextOpps, fixLineItemMeta}
import common.value_opps._
import cats.implicits.catsSyntaxOptionId
import org.scalajs.dom
import org.scalajs.dom.html
import service.apis.dynamic_api.DataMapperModels.MetadataModels
import wvlet.log.Logger

case object SuggestBoxOfField {
  private val log = Logger.of[SuggestBoxOfField.type]
  private val CustomFieldName = "<custom value>"

  private val DefaultMaxOptions: Int = 50

  private case class NativeMaterialInput(
                                          input: dom.html.Element,
                                          label: Option[dom.html.Element],
                                        ) {
    private val slot: html.Div = {
      val e = div(cls := "suggestBox-value-section", display := "flex", width := "100%").ref
      val q = input.parentElement.querySelectorAll(".suggestBox-value-section")
      for (i <- 0 until q.length) {
        input.parentElement.removeChild(q.item(i))
      }
      input.parentElement.insertBefore(e, input)
      e
    }

    private def showInput(): Unit = {
      input.style.setProperty("display", "")
      label.foreach(_.style.setProperty("transform", ""))
      val tmp = input.parentElement.querySelectorAll("i.material-icons")
      if (tmp.length > 0) {
        val icon = tmp.item(0).asInstanceOf[dom.html.Element]
        icon.style = ""
      }
      slot.style = "display: none"
    }

    private def hideInput(): Unit = {
      input.style.setProperty("display", "none")
      label.foreach(_.style.setProperty("transform", "translateY(-106%) scale(0.75)"))
      val tmp = input.parentElement.querySelectorAll("i.material-icons")
      if (tmp.length > 0) {
        val icon = tmp.item(0).asInstanceOf[dom.html.Element]
        icon.style = "display: none"
      }
      slot.style = "display: flex; width: 100%"
    }

    private def clear(): Unit = {
      for (_ <- 0 until slot.children.length) {
        slot.removeChild(slot.children.item(0))
      }
    }

    def value(value: Option[html.Div]): Unit = {
      clear()
      if (value.isDefined) {
        hideInput()
        slot.appendChild(value.get)
      } else {
        showInput()
      }
    }
  }

  private case class Node(
                           label: String,
                           fields: Seq[MetadataModels.Field], // array of field on one level
                           `#classLoader`: String => EventStream[MetadataModels.Class], // class loader for embedded structures
                           `#schemaLoader`: String => EventStream[MetadataModels.Schema], // class loader for schemas
                           deepValue: Seq[(String, String)] // has current value (fieldName, fieldIndex) on top and tail for their children
                         ) {
    private val searchText: Var[String] = Var[String](deepValue.headOption.flatMap(v => Option.when[String](!fields.exists(_.name == v._1))(v._1)).getOrElse(""))
    private val native: Var[Option[NativeMaterialInput]] = Var(None)
    val selectFieldVar: Var[Option[MetadataModels.Field]] = Var[Option[MetadataModels.Field]](deepValue.headOption.flatMap(v => fields.find(_.name == v._1)))
    val selectIndexVar: Var[String] = Var[String](deepValue.headOption.map(_._2).getOrElse(""))

    private val text: Var[Option[(String, MetadataModels.Field, String)]] = Var[Option[(String, MetadataModels.Field, String)]](None)

    val required: Var[Boolean] = Var(false)
    val helper: Var[Option[String]] = Var(None)
    val errors: Var[Option[String]] = Var(None)

    private val $helper: Signal[Option[(String, Boolean)]] = selectFieldVar.signal
      .map {
        case Some(f) => !fields.exists(_.name == f.name)
        case _ => false
      }
      .combineWith(helper.signal)
      .combineWith(errors.signal)
      .map {
        case (true, _, _) => Some("field not found", true)
        case (_, _, Some(e)) => Some(e, true)
        case (_, Some(h), _) => Some(h, false)
        case _ => None
      }

    def $node: Signal[Seq[ReactiveHtmlElement[html.Div]]] = {
      val isOpen: EventBus[Boolean] = new EventBus[Boolean]()
      val searchEl: material.Textfield.El = material.Textfield(
        _ => cls := "slds-col slds-size--1-of-1",
        _.label := label,
        _.value <-- searchText.signal
          .combineWith(selectFieldVar.signal.map(_.nonEmpty))
          .map {
            case (_, true) => "   "
            case (s, _) => s
          },
        _.required <-- required.signal,
        _.helper <-- $helper.map(_.map(_._1).getOrElse("")),
        _.helperPersistent := true,
        _ => cls <-- $helper.signal
          .map(_.map(_._2))
          .map {
            case Some(true) => "with_error"
            case _ => ""
          },
        _ => onInput.mapToValue --> Observer.combine(searchText.writer, isOpen.writer.contramap((_: String) => true)),
        _ => onClick.stopPropagation.mapTo(true) --> isOpen.writer,
        _ => onKeyPress.filter(x => x.keyCode == 13).mapTo(true) --> isOpen.writer,
        _.iconTrailing := "search",
        _ => onMountCallback {
          ctx =>
            EventStream.combineSeq(Seq(
              ctx.findElementInShadowRoot("input", withDelay = true),
              ctx.findElementInShadowRoot(".mdc-floating-label", withDelay = true),
            ))
              .foreach {
                case Seq(Some(i), l) =>
                  native.update {
                    case Some(n) if n.input == i && n.label == l =>
                      Some(n)
                    case _ =>
                      Some(NativeMaterialInput(i, l))
                  }
                case _ =>
                  native.set(None)
              }(ctx.owner)
        },
      )
      $child
        .flatMap {
          case Some(child) =>
            child.$node.map((_, child.$value))
          case _ =>
            Signal.fromValue((Nil, Signal.fromValue(None)))
        }
        .map(x => div(
          //div(
          //  p(fields.map(f => f.name).mkString("; ")),
          //  p(child.text <-- selectFieldVar.signal.map(e => e.map(_.name).getOrElse("")))
          //),
          cls := "input-width--medium",
          searchEl,
          // build menu
          child.maybe <-- selectFieldVar.signal
            .combineWith(native.signal)
            .map {
              case (value, Some(nElement)) =>
                nElement.value(value.map(renderValue))
                value
              case _ =>
                None
            }
            .map {
              case Some(_) => None
              case _ => Some(div(
                cls := "slds-is-relative",
                material.Menu(
                  _ => cls := "width-medium height-medium",
                  _ => position := "fixed",
                  _ => zIndex := "5",
                  _.open <-- isOpen.events,
                  _.onOpened --> Observer[dom.Event](onNext = _ => searchEl.ref.focus),
                  _.onClosed.mapTo(false) --> isOpen.writer,
                  _ => child <-- searchText.signal
                    .map(_.trim.toLowerCase)
                    .map {
                      case searchText if searchText.nonEmpty =>
                        fields.filter(_.name.toLowerCase.indexOf(searchText) >= 0)
                      case _ =>
                        fields
                    }
                    .map(_.take(DefaultMaxOptions))
                    .split(_.name)((_: String, _: MetadataModels.Field, $field: Signal[MetadataModels.Field]) => renderOption($field))
                    .map {
                      case items if items.nonEmpty =>
                        items
                      case _ =>
                        small(
                          cls := "slds-show slds-m-vertical--small slds-p-left--medium slds-p-right--medium grey",
                          "No fields"
                        ) :: Nil
                    }
                    .map(options => div(maxHeight := "40vh", options)),
                )
              ))
            },
          // make full value
          selectFieldVar.signal
            .combineWith(selectIndexVar.signal)
            .map {
              case (Some(f), index) if f.name.nonEmpty && index.trim.nonEmpty => Some((s"${f.name}$index", f, ""))
              case (Some(f), _) if f.name.nonEmpty => Some((f.name, f, ""))
              case _ => None
            }
            .combineWith(x._2)
            .map {
              case (Some(parent), Some(child)) if parent._1.nonEmpty && child._1.nonEmpty =>
                Some((s"${parent._1}.${child._1}", child._2, child._3))
              case (Some(parent), _) if parent._1.nonEmpty =>
                Some(parent)
              case _ =>
                None
            }
            --> text.writer,
        ) :: Nil ++ x._1)
    }

    def $value: Signal[Option[(String, MetadataModels.Field, String)]] = text.signal

    private def $child: Signal[Option[Node]] =
      selectFieldVar.signal
        .flatMap {
          case Some(field) =>
            field.`type` match {
              case MetadataModels.DataType.EmbeddedVal(Some(className)) if className.nonEmpty =>
                `#classLoader`(className).map[Option[Seq[MetadataModels.Field]]](
                  _.fields
                )
              case MetadataModels.DataType.RecordVal(_) if field.recordSchema.isDefined =>
                EventStream.fromValue[Option[Seq[MetadataModels.Field]]](
                  field.recordSchema.map(_.fields.toSeq)
                )
              case MetadataModels.DataType.RecordVal(Some(schemaName)) if schemaName.nonEmpty =>
                `#schemaLoader`(schemaName).map[Option[Seq[MetadataModels.Field]]](
                  _.fields
                )
              case _ =>
                EventStream.fromValue[Option[Seq[MetadataModels.Field]]](None)
            }
          case _ =>
            EventStream.fromValue[Option[Seq[MetadataModels.Field]]](None)
        }
        .map(_.flatMap {
          case fields if fields.nonEmpty =>
            Some(Node("Subfield", fields, `#classLoader`, `#schemaLoader`, deepValue.drop(1)))
          case _ =>
            None
        })
        .toSignal(None)

    private def renderOption($field: Signal[MetadataModels.Field]): material.List.ListItem.El = {
      material.List.ListItem(
        _.hasMeta := true,
        _.slots.default(span(
          cls := "slds-grid slds-grid--vertical-align-center slds-grid--align-spread",
          child <-- $field
            .combineWith(searchText.signal.map(_.trim.toLowerCase))
            .map {
              case (field, searchText) if searchText.nonEmpty =>
                val n = field.name.toLowerCase
                val l = searchText.length
                var begin = 0
                var end = n.indexOf(searchText, begin)
                var children = Seq[HtmlElement]()
                while (end >= 0 && end < n.length && children.length < n.length) {
                  if (begin != end) {
                    children = children.appended(span(field.name.substring(begin, end)))
                  }
                  begin = end + l
                  children = children.appended(b(field.name.substring(end, begin)))
                  end = n.indexOf(searchText, begin)
                }
                if (begin < n.length) {
                  children = children.appended(span(field.name.substring(begin)))
                }
                span(cls := "slds-col growing-block", children)
              case (field, _) =>
                span(cls := "slds-col growing-block", field.name)
            },
        )),
        _.slots.meta(span(
          child.maybe <-- $field.map {
            field =>
              field.role.collect {
                case MetadataModels.FieldRole.id => small(
                  cls := "slds-m-left--small",
                  styleAttr := "padding: 1px 11px;",
                  color := "gray",
                  MetadataModels.FieldRole.id.label,
                )
              }
          },
          child.maybe <-- $field.map {
            case f if f.`type` != MetadataModels.DataType.unknown =>
              small(
                cls := "light slds-m-left--xx-small slds-col",
                styleAttr := "padding: 1px 11px; border-radius: 8px;",
                backgroundColor := s"${f.`type`.basisType.color}",
                f.getDataType,
              ).some
            case _ =>
              None
          }
        )),
        _ => composeEvents(onClick)(_.sample($field)
          .map(Some(_)))
          --> selectFieldVar.writer,
        _ => onMountCallback(ctx => fixLineItemMeta(ctx, "width" -> "auto" :: "color" -> "inherit" :: Nil)),
      )
    }

    private def renderValue(field: MetadataModels.Field): html.Div = {
      val icon = material.Icon(
        _ => cursor := "pointer",
        _ => fontSize := "1rem",
        _ => marginLeft := "1rem",
        _ => marginRight := "1rem",
        _ => color := "gray",

        _ => "cancel",
      )

      val indexValue = selectIndexVar.now().trim

      val index = select(
        display := "flex",
        border := "0px",
        outline := "0px",
        color := "inherit",
        option(selected := indexValue == "", value := "", ""),
        Option.when(field.structure == MetadataModels.FieldStructure.array)(Seq(
          option(selected := indexValue == "[*]", value := "[*]", "[each]"),
          option(selected := indexValue == "[0]", value := "[0]", "[first]"),
        )),
        Option.when(indexValue != "" && indexValue != "[0]" && indexValue != "[*]")(
          option(selected := true, value := indexValue, indexValue)
        ),
      )

      val res = div(
        display := "flex",
        alignItems := "center",
        width := "100%",

        span(
          width := "100%",
          overflow := "hidden",
          textOverflow := "ellipsis",
          whiteSpace := "nowrap",
          display := "flex",

          span(
            width := "100%",
            display := "flex",
            field.name,
            Option.when(field.structure == MetadataModels.FieldStructure.array || indexValue.nonEmpty)(index)
              .map(el => span(display := "flex", marginLeft := "0.2rem", el)),
          ),

          field.role.collect {
            case MetadataModels.FieldRole.id => small(
              cls := "slds-m-left--small",
              styleAttr := "padding: 1px 11px;",
              color := "gray",
              MetadataModels.FieldRole.id.label,
            )
          },

          Option.when(field.`type` != MetadataModels.DataType.unknown)(small(
            cls := "slds-m-left--small",
            styleAttr := "padding: 1px 11px; border-radius: 8px;",
            backgroundColor := s"${field.`type`.basisType.color}",
            field.getDataType,
          )),
        ),

        icon,
      )

      icon.ref.onclick = (e: dom.MouseEvent) => {
        e.stopPropagation()
        e.stopImmediatePropagation()
        selectFieldVar.set(None)
      }
      index.ref.onchange = (e: dom.Event) => {
        e.stopPropagation()
        e.stopImmediatePropagation()
        selectIndexVar.set(e.target.asInstanceOf[dom.html.Select].value)
      }
      res.ref.onclick = (e: dom.MouseEvent) => {
        e.stopPropagation()
        e.stopImmediatePropagation()
      }

      res.ref
    }
  }

  private def extractField(value: Option[String]): Seq[(String, String)] = {
    val rxMField = """^(\w+)(?:\[([^\[\]]+)\])$""".r
    value
      .map {
        case txt if txt.indexOf(".") > 0 => txt.split("\\.").toSeq
        case txt => Seq(txt)
      }
      .getOrElse(Nil)
      .map {
        case rxMField(fieldName, maybeArrayIndex) if fieldName.nonEmpty && maybeArrayIndex.nonEmpty =>
          (fieldName, s"[$maybeArrayIndex]")
        case fieldName =>
          (fieldName.trim, "")
      }
  }

  def apply(
             label: String,
             $fields: Signal[List[MetadataModels.Field]],
             `#ClassLoader`: String => EventStream[MetadataModels.Class],
             `#SchemaLoader`: String => EventStream[MetadataModels.Schema],
             onChange: Observer[Option[(String, Option[MetadataModels.Field], Option[String])]] = Observer.empty,
             $value: Signal[Option[String]] = Signal.fromValue(None),
             helper: Signal[String] = Signal.fromValue(""),
             error: Signal[String] = Signal.fromValue(""),
             required: Signal[Boolean] = Signal.fromValue(false),
             customizable: Signal[Boolean] = Signal.fromValue(false),
           ): ReactiveHtmlElement[html.Div] =
    div(
      common.ui.Attribute.Selector := "data_mapper.components.suggest_box_of_field",
      cls := "slds-grid slds-grid--vertical",
      child.maybe <-- $fields
        .combineWith($value.map(v => (v, extractField(v))))
        .combineWith(customizable.map(_.condition(_ == true, _ => MetadataModels.Field(name = CustomFieldName) :: Nil, _ => Nil)))
        .map(x => (x._4 ++ x._1, x._2, x._3))
        .map {
          case (fields, Some(originalValue), _) if common.CirceStringOps(originalValue).decodeError.isEmpty =>
            (Node(label, fields, `#ClassLoader`, `#SchemaLoader`, (CustomFieldName, "") :: Nil), Some(originalValue))
          case (fields, Some(originalValue), value) if value.length == 1 && originalValue.trim.nonEmpty && originalValue != CustomFieldName && !fields.exists(_.name == originalValue) =>
            (Node(label, fields, `#ClassLoader`, `#SchemaLoader`, (CustomFieldName, "") :: Nil), Some(originalValue))
          case (fields, _, value) =>
            (Node(label, fields, `#ClassLoader`, `#SchemaLoader`, value), None)
          case _ =>
            log.warn(s"unexpected value for render the box level correctly")
            (Node(label, Nil, `#ClassLoader`, `#SchemaLoader`, Nil), None)
        }
        .map {
          case (box, originalValue) =>
            val $isCustomField = box.selectFieldVar.signal.map(_.exists(_.name == CustomFieldName))
            Some(div(
              box.$value
                .changes
                .filter(_.forall(_._1 != CustomFieldName))
                .map(_.flatMap(x => Option.when(x._1.nonEmpty)((
                  x._1,
                  Some(x._2),
                  Option.when(x._3.nonEmpty)(x._3),
                ))))
                --> onChange,
              error
                .combineWith($isCustomField)
                .map {
                  case (e, false) => Option.when(e.trim.nonEmpty)(e)
                  case _ => None
                }
                --> box.errors.writer,
              helper
                .combineWith($isCustomField)
                .map {
                  case (h, false) => Option.when(h.trim.nonEmpty)(h)
                  case _ => None
                }
                --> box.helper.writer,
              cls := "slds-grid slds-grid--vertical",
              children <-- box.$node,
              child.maybe <-- $isCustomField
                .map {
                  case true => Some(div(
                    cls := "input-width--medium",
                    material.Textarea(
                      _ => cls := "slds-col slds-size--1-of-1",
                      _.label := "Custom value",
                      _.required := true,
                      _.rows := 5,
                      _.value := originalValue.getOrElse[String](""),
                      _ => cls <-- error
                        .map(_.trim.nonEmpty)
                        .map {
                          case true => "with_error"
                          case _ => ""
                        },
                      _.helper <-- error
                        .combineWith(helper)
                        .map {
                          case (e, _) if e.trim.nonEmpty => e
                          case (_, h) if h.trim.nonEmpty => h
                          case _ => ""
                        },
                      _.helperPersistent := true,
                      _ => onInput.mapToValue
                        .map(txt => Option.when(txt.nonEmpty)((
                          txt,
                          Option.empty[MetadataModels.Field],
                          Option.empty[String],
                        )))
                        --> onChange
                    )
                  ))
                  case _ => None
                },
              required --> box.required.writer,
            ))
          case _ =>
            None
        }
    )
}
