package root_pages.aurinko_pages.app.virtual_api.components

import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.{Icon, Textfield}
import com.raquo.airstream.core.Observer
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.ui.mat_components_styles.MountContextOpps
import org.scalajs.dom
import org.scalajs.dom.html
import wvlet.log.Logger

case object SuggestBox {
  private val log = Logger.of[SuggestBox.type]

  object Seek {
    def contains[T](options: List[T], text: String, caseSensitive: Boolean = false): List[T] = {
      val txt: String = if (caseSensitive) text else text.toLowerCase()

      val res = options.filter(
        if (caseSensitive)
          _.toString.contains(txt)
        else
          _.toString.toLowerCase().contains(txt)
      )

      log.info(s"seek: $txt = ${res.length}")

      res
    }

    def containsOrEmpty[T](options: List[T], text: String, caseSensitive: Boolean = false): List[T] = {
      val txt: String = if (caseSensitive) text else text.toLowerCase()

      val res = options.filter(
        if (caseSensitive)
          txt.isEmpty || _.toString.contains(txt)
        else
          txt.isEmpty || _.toString.toLowerCase().contains(txt)
      )

      log.info(s"seek: $txt = ${res.length}")

      res
    }
  }

  private case object Strategies {
    type Merge[T] = (List[T], T) => List[T]

    def mergeMulti[T]: Merge[T] = (items: List[T], item: T) =>
      if (items.contains(item))
        items.filter(_ != item)
      else
        items.appended(item)

    def mergeSingle[T]: Merge[T] = (items: List[T], item: T) =>
      if (items.contains(item))
        List()
      else
        List[T](item)

    type Render[T] = (List[T], T => HtmlElement) => List[dom.html.Element]

    def renderMulti[T](click: EventBus[T]): Render[T] =
      (options: List[T], drawing: T => HtmlElement) => options
        .map(option => {
          val icon = Icon(
            _ => cursor := "pointer",
            _ => fontSize := "1rem",
            _ => marginLeft := "0.1rem",
            _ => color := "gray",

            _ => "cancel",
          )
          val res = div(
            display := "flex",
            alignItems := "center",
            marginRight := "1rem",
            backgroundColor := "lightgray",
            borderRadius := "24px",
            padding := "0 4px 0 8px",
            marginTop := "0.4rem",

            span(
              maxWidth := "100%",
              overflow := "hidden",
              textOverflow := "ellipsis",
              whiteSpace := "nowrap",

              drawing(option),
            ),

            icon,
          )

          icon.ref.onclick = (e: dom.MouseEvent) => {
            e.stopPropagation()
            click.emit(option)
          }
          res.ref.onclick = (e: dom.MouseEvent) => {
            e.stopPropagation()
          }

          res.ref
        })

    def renderSingle[T](click: EventBus[T]): Render[T] =
      (options: List[T], drawing: T => HtmlElement) => options
        .map(option => {
          val icon = Icon(
            _ => cursor := "pointer",
            _ => fontSize := "1rem",
            _ => marginLeft := "0.1rem",
            _ => color := "gray",

            _ => "cancel",
          )

          val res = div(
            display := "flex",
            alignItems := "center",
            marginRight := "1rem",
            width := "100%",

            span(
              width := "100%",
              overflow := "hidden",
              textOverflow := "ellipsis",
              whiteSpace := "nowrap",

              drawing(option),
            ),
            icon,
          )

          icon.ref.onclick = (e: dom.MouseEvent) => {
            e.stopPropagation()
            e.stopImmediatePropagation()
            click.emit(option)
          }
          res.ref.onclick = (e: dom.MouseEvent) => {
            e.stopPropagation()
            e.stopImmediatePropagation()
          }

          res.ref
        })

    type Validation[T] = (List[T], String) => Boolean

    def defaultValidation[T]: Validation[T] = (l: List[T], _: String) => l.nonEmpty

    def defaultRawValidation[T]: Validation[T] = (l: List[T], txt: String) => l.nonEmpty || txt.nonEmpty
  }

  case class Item[T](source: T, private val displayText: String) {
    override
    def toString: String = displayText
  }

  private class Value[T](
                          private val items: Var[List[T]] = Var[List[T]](Nil),
                          private val mergeStrategy: Strategies.Merge[T],
                          private val renderStrategy: Strategies.Render[T],
                        ) {
    def render(
                items: List[T],
                drawing: T => HtmlElement = (t: T) => span(t.toString),
              ): List[dom.html.Element] =
      renderStrategy(items, drawing)

    def signal: Signal[List[T]] = items.signal

    def merge: Observer[T] = writer.contramap((x: T) => mergeStrategy(items.now(), x))

    def remove: Observer[T] = writer.contramap((x: T) => items.now().filter(_ != x))

    def writer: Observer[List[T]] = items.writer
  }

  private class Controller[T](
                               private val textFieldCustomMods: Seq[Textfield.ModFunction],
                               private val loadOptions: String => Signal[List[T]],
                               private val value: Value[T],
                               private val inputDelay: Int,
                               private val maxOptions: Int,
                               private val required: Signal[Boolean],
                               private val selectPanel: dom.html.Element,
                               private val validation: Strategies.Validation[T],
                               private val minCharactersForSearch: Int = 2,
                               private val onRender: Signal[T] => HtmlElement,
                               private val onSelect: T => HtmlElement,
                             ) {
    val text: Var[String] = Var[String]("")
    private val searchTextBus: EventBus[String] = new EventBus[String]

    private val nativeLabelElement: Var[Option[dom.html.Element]] = Var(None)
    val nativeInputElement: Var[Option[dom.html.Element]] = Var(None)

    private val internalErrorMsg: Var[String] = Var("")
    private val externalErrorMsg: Var[String] = Var("")
    private val helper: Var[String] = Var[String]("")

    val isOpen: EventBus[Boolean] = new EventBus[Boolean]()

    val options: Var[List[T]] = Var(Nil)

    private val searchEl: material.Textfield.El =
      material.Textfield(textFieldCustomMods ++ Seq[material.Textfield.ModFunction](
        _.value <-- text.signal,
        _ => onInput.mapToValue --> Observer.combine(
          searchTextBus.writer,
          isOpen.writer.contramap((_: String) => true)
        ),
        _.helper <-- internalErrorMsg.signal
          .combineWith(externalErrorMsg.signal)
          .combineWith(helper.signal)
          .map {
            case (internalError, _, _) if internalError.trim.nonEmpty => internalError
            case (_, externalError, _) if externalError.trim.nonEmpty => externalError
            case (_, _, helper) if helper.trim.nonEmpty => helper
            case _ => ""
          },
        _ => cls <-- internalErrorMsg.signal
          .map(_.nonEmpty)
          .combineWith(required)
          .combineWith(externalErrorMsg.signal.map(_.nonEmpty))
          .map(x => (x._1 && x._2) || x._3)
          .changes
          .map {
            case true => "with_error"
            case false => ""
          },
        _.helperPersistent := true,
        _.required := false,
        _ => onClick.stopPropagation.mapTo(true) --> isOpen.writer,
        _.iconTrailing := "search",
        _ => onMountCallback(ctx => {
          ctx.findElementInShadowRoot("input", withDelay = true).foreach {
            case Some(element) =>
              element.parentElement.insertBefore(selectPanel, element)
              log.info(s"found native input element ${if (element != null) "is not null" else "is null"}")
              nativeInputElement.set(Some(element))
            case _ =>
              log.info(s"reset native input element")
              nativeInputElement.set(None)
          }(ctx.owner)
          ctx.findElementInShadowRoot(".mdc-floating-label", withDelay = true).foreach {
            case Some(element) =>
              log.info(s"reset native label element ${if (element != null) "is not null" else "is null"}")
              nativeLabelElement.set(Some(element))
            case _ =>
              log.info(s"reset native label element")
              nativeLabelElement.set(None)
          }(ctx.owner)
        }),
      ): _*)
    private val menuEl: material.Menu.El =
      material.Menu(
        _ => cls := "width-medium height-medium",
        _.open <-- isOpen.events,
        _.onOpened --> Observer[dom.Event](onNext = _ => searchEl.ref.focus),
        _.onClosed.mapTo(false) --> isOpen.writer,
        _ => child <-- options.signal
          .map(x => if (maxOptions > 0) x.take(maxOptions) else x)
          .split(_.hashCode())((_: Int, _: T, $item: Signal[T]) => ListItem(
            _.slots.default(onRender($item)),
            _ => composeEvents(onClick)(_.sample($item)) --> value.merge
          ))
          .map {
            case items if items.nonEmpty =>
              items
            case _ =>
              small(
                cls := "slds-show slds-m-vertical--small slds-p-left--medium slds-p-right--medium grey",
                child.text <-- text.signal.map {
                  case s if s.length < minCharactersForSearch && minCharactersForSearch > 0 => s"Type $minCharactersForSearch or more characters to search"
                  case _ => "No items found"
                }
              ) :: Nil
          }
          .map(options => div(maxHeight := "40vh", options))
      )

    def menu: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.suggest_box.controller.menu",
      cls := "slds-is-relative",
      menuEl,
    )

    def search: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.components.suggest_box.controller.search",

      searchTextBus.events
        .debounce(inputDelay)
        .filter(txt => txt.isEmpty || txt.length >= minCharactersForSearch)
        --> text.writer,

      text.signal
        .flatMap(txt => loadOptions(txt)
          .map(items => {
            log.info(s"search $txt: result ${items.length} items")
            items
          })
          .combineWith(value.signal)
          .map(x => x._1.filter(i => !x._2.contains(i))))
        --> options.writer,

      required
        .combineWith(nativeLabelElement.signal)
        --> Observer[(Boolean, Option[dom.html.Element])](onNext = x => {
        if (x._2.isDefined) {
          if (x._1)
            x._2.get.classList.add("mdc-floating-label--required")
          else
            x._2.get.classList.remove("mdc-floating-label--required")
        }
      }),

      value.signal
        .combineWith(nativeLabelElement.signal)
        .map(x => {
          val items = x._1
          val label = x._2

          log.info(s"selected: ${items.map(_.toString()).mkString(", ")}")

          // build selected elements (js dom elements)
          for (_ <- 0 until selectPanel.children.length) {
            selectPanel.removeChild(selectPanel.children.item(0))
          }

          for (i <- value.render(items, onSelect)) {
            selectPanel.appendChild(i)
          }

          // label up and down
          if (label.isDefined) {
            label.get.style.setProperty("transform", if (items.nonEmpty) "translateY(-180%) scale(1)" else "")
          }

          items
        })
        .combineWith(required)
        .combineWith(text.signal)
        .map(x => x._2 && !validation(x._1, x._3))
        .changes
        .map {
          case true => s"Please enter valid ${searchEl.ref.label.toLowerCase()}"
          case _ => ""
        }
        --> internalErrorMsg.writer,

      searchEl,
    )

    def tooltip: Observer[String] = helper.writer

    def error: Observer[String] = externalErrorMsg.writer
  }

  def multi[T](
                options: String => Signal[List[T]],
                onChange: Observer[List[T]] = Observer.empty,
                onRawValue: Observer[String] = Observer.empty,
                delay: Int = 500, // ms delay for input
                limit: Int = -1, // max options on ui
                required: Signal[Boolean] = Signal.fromValue(false),
                error: Signal[String] = Signal.fromValue(""),
                tooltip: Signal[String] = Signal.fromValue(""),
                value: Option[List[T]] = None,
                minCharactersForSearch: Int = 2,
                onRender: Signal[T] => HtmlElement = (s: Signal[T]) => span(child.text <-- s.map(_.toString)),
                onSelect: T => HtmlElement = (v: T) => span(v.toString),
              )(mods: Textfield.ModFunction*): ReactiveHtmlElement[html.Div] = {
    val removeBus = new EventBus[T]
    val selected = new Value(
      items = Var(value.getOrElse(Nil)),
      mergeStrategy = Strategies.mergeMulti[T],
      renderStrategy = Strategies.renderMulti[T](removeBus),
    )
    val controller = new Controller[T](
      textFieldCustomMods = mods,
      loadOptions = options,
      value = selected,
      inputDelay = delay,
      maxOptions = limit,
      required = required,
      selectPanel = div(display := "flex").ref,
      validation = if (onRawValue == Observer.empty) Strategies.defaultValidation[T] else Strategies.defaultRawValidation[T],
      minCharactersForSearch,
      onRender = onRender,
      onSelect = onSelect,
    )

    div(
      common.ui.Attribute.Selector := "data_mapper.components.suggest_box.multi",

      cls := "input-width--medium",

      removeBus --> Observer.combine(selected.remove, controller.isOpen.writer.contramap((_: T) => true)),

      selected.signal --> onChange,

      tooltip --> controller.tooltip,
      error --> controller.error,

      controller.text.signal
        .combineWith(selected.signal.map(_.isEmpty))
        .changes
        .filter(x => x._2)
        .map[String](_._1)
        --> onRawValue,

      controller.search,

      child.maybe <-- controller.options.signal
        .map(_.isEmpty)
        .map {
          case true if onRawValue != Observer.empty => None
          case _ => Some(controller.menu)
        }
    )
  }

  def apply[T](
                options: String => Signal[List[T]],
                onChange: Observer[Option[T]] = Observer.empty,
                onRawValue: Observer[String] = Observer.empty,
                delay: Int = 500, // ms delay for input
                limit: Int = -1, // max options on ui
                required: Signal[Boolean] = Signal.fromValue(false),
                error: Signal[String] = Signal.fromValue(""),
                tooltip: Signal[String] = Signal.fromValue(""),
                value: Option[T] = None,
                minCharactersForSearch: Int = 2,
                onRender: Signal[T] => HtmlElement = (s: Signal[T]) => span(child.text <-- s.map(_.toString)),
                onSelect: T => HtmlElement = (v: T) => span(v.toString),
              )(mods: Textfield.ModFunction*): HtmlElement = {
    val panel = div(display := "flex", width := "100%").ref
    val removeBus = new EventBus[T]
    val selected = new Value(
      items = Var(value.map(_ :: Nil).getOrElse(Nil)),
      mergeStrategy = Strategies.mergeSingle[T],
      renderStrategy = Strategies.renderSingle[T](removeBus),
    )
    val controller = new Controller[T](
      textFieldCustomMods = mods,
      loadOptions = options,
      value = selected,
      inputDelay = delay,
      maxOptions = limit,
      required = required,
      selectPanel = panel,
      validation = if (onRawValue == Observer.empty) Strategies.defaultValidation[T] else Strategies.defaultRawValidation[T],
      minCharactersForSearch,
      onRender = onRender,
      onSelect = onSelect,
    )

    val displayChange: (dom.html.Element, Boolean) => Unit = (input: dom.html.Element, hidden: Boolean) => {
      input.style = if (hidden) "display: none" else ""
      val tmp = input.parentElement.querySelectorAll("i.material-icons")
      if (tmp.length > 0) {
        val icon = tmp.item(0).asInstanceOf[dom.html.Element]
        icon.style = if (hidden) "display: none" else ""
      }
      panel.style = if (hidden) "display: flex; width: 100%" else "display: none"
    }

    div(
      common.ui.Attribute.Selector := "data_mapper.components.suggest_box",

      cls := "input-width--medium",

      controller.nativeInputElement.signal
        .withCurrentValueOf(selected.signal)
        --> Observer[(Option[dom.html.Element], List[T])](onNext = x => if (x._1.isDefined) displayChange(x._1.get, x._2.nonEmpty)),

      removeBus.events
        .withCurrentValueOf(controller.nativeInputElement.signal)
        .map(x => {
          if (x._2.isDefined) displayChange(x._2.get, false)
          x._1
        })
        --> Observer.combine(selected.remove, controller.isOpen.writer.contramap((_: T) => true)),

      selected
        .signal
        .withCurrentValueOf(controller.nativeInputElement.signal)
        .map {
          case (items, Some(el)) =>
            displayChange(el, items.nonEmpty)
            items
          case (items, _) =>
            items
        }
        .changes
        --> onChange.contramap((x: List[T]) => x.headOption),

      controller.text.signal.combineWith(selected.signal)
        .changes
        .filter(_._2.isEmpty)
        .map[String](_._1)
        --> onRawValue,

      tooltip --> controller.tooltip,
      error --> controller.error,

      controller.search,

      child.maybe <-- selected.signal
        .map(_.isEmpty)
        .map {
          case true => Some(controller.menu)
          case _ => None
        }
    )
  }
}
