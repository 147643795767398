package common.ui.notifications

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.{Button, Menu}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.{ReactiveElement, ReactiveHtmlElement}
import common.airstream_ops.{SeqSignalOps, SignalNestedOps, ValueToObservableOps}
import common.stayOpenOnBodyClick
import org.scalajs.dom
import org.scalajs.dom.html
import portal_router.BillingPage

case object PageFixedNotificationComponent {
  def popup(textElement: ReactiveElement[dom.html.Element], button: Signal[Option[ReactiveHtmlElement[dom.html.Element]]]) =  div(
    cls := "slds-size--1-of-1 slds-p-around--large border-box-sizing shadow-box",

      div(
        cls := "slds-grid slds-grid--vertical slds-grid--vertical-align-center",
        textElement,
        child.maybe <-- button.nestedMap(_.amend(cls := "slds-m-top--medium"))
    )

  )

  def apply(messageText: Signal[String],
            buttonLabel: Signal[Option[String]],
            buttonClickBinder: Option[Binder[ReactiveElement.Base]]): ReactiveHtmlElement[html.Div] = popup(
    p(child.text <-- messageText),
    buttonLabel.nestedMap(label => Button(
      _.label := label,
      _ => cls := "secondary",
      _.raised := true,
      _ => buttonClickBinder
    ))
  )
}

trait PageNotification {
  def component: ReactiveHtmlElement[html.Div]
}

class PageFixedNotifications(notifications: Signal[List[PageNotification]]) {

  def apply(): ReactiveHtmlElement[html.Div] = div(
    cls := "page-fixed-notifications-container",
    children <-- notifications.nestedMap(_.component)
  )

}

object BillingNotifications {
  case class PaymentMethodRequired(
                                    teamId: Int,
                                    trialDaysLeft: Int,
                                    navigateObserver: Observer[BillingPage]
                                       ) extends PageNotification {

    def component: ReactiveHtmlElement[html.Div] = PageFixedNotificationComponent(
      s"${Option.when(trialDaysLeft > 0) {s"Your trial period expires in $trialDaysLeft days."}.getOrElse("Your trial period ended.")} Please add payment method.".signaled,
      buttonLabel = "Add credit card".some.signaled,
      buttonClickBinder = (onClick.mapTo(BillingPage(teamId)) --> navigateObserver).some
    )
  }

  case class HasDebt (
                       teamId: Int,
                       navigateObserver: Observer[BillingPage]
                     ) extends PageNotification {
    def component: ReactiveHtmlElement[html.Div] = PageFixedNotificationComponent(
      s"We can't process your payment. Please review your payment details and check that there is money in your associated account.".signaled,
      buttonLabel = "Review payment details".some.signaled,
      buttonClickBinder =  (onClick.mapTo(BillingPage(teamId)) --> navigateObserver).some
    )
  }
}
