package common.ui.notifications

import com.github.uosis.laminar.webcomponents.material
import com.github.uosis.laminar.webcomponents.material.List.ListItem
import com.github.uosis.laminar.webcomponents.material.Menu
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.{SignalOps, ValueToObservableOps}
import common.{AppKey, AuthAccountResponse, CirceStringOps, PortalAccount, ServiceType, download, systemDefaultTZ}
import io.circe.generic.auto.exportDecoder
import org.scalajs.dom
import org.scalajs.dom.raw.BlobPropertyBag
import org.scalajs.dom.{Blob, URL, html}
import service.apis.portal_api.PortalApi
import service.aurinko_api.AurinkoApi
import service.aurinko_api.AurinkoModels._
import service.exception_handler.UnexpectedApiResponse
import service.portal_state.PortalState
import wvlet.log.Logger

import java.time.ZoneId
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.util.{Failure, Success}

class SchedulerTodoComponent(portalApi: PortalApi,
                             appClientId: Signal[String],
                             appKey: Signal[AppKey],
                             aurinkoApi: AurinkoApi,
                             apiOrigin: String,
                             portalState: PortalState
                            ) {

  private val log = Logger.of[SchedulerTodoComponent]

  private def createTestBook(accId: Int): EventStream[Unit] = (for {
    ak <- appKey.stream
    _ <- portalApi.createBook(ak, accId, testBook)
  } yield ()
    )
    .recoverToTry
    .map {
      case Success(_) => ()
      case Failure(exception) => log.warn(s"Failed to create test book: $exception")
    }

  private def buildSchedulerFile(appClientId: String): EventStream[String] = portalApi.getRelativePath[String]("/manifests/scheduler.html")
    .map(_.replaceAll("""\{\{YOUR_AURINKO_CLIENT_ID}}""", appClientId))
    .map(_.replaceAll("""\{\{YOUR_DEFAULT_PROFILE_NAME}}""", "test"))
    .map(file => new Blob(blobParts = file.toArray.map(_.asInstanceOf[js.Any]).toJSArray, options = BlobPropertyBag(`type` = Some("text/html").orUndefined)))
    .map(URL.createObjectURL)

  private val showServicesPopup: Var[Boolean] = Var(false)


  private val accountAuthorized = Var(false)
  private val checkAccount = for {
    ak <- appKey.stream
    account <- portalApi.accounts(ak)
      .map(_.records.find { acc => {
        (acc.serviceType == ServiceType.google || acc.serviceType == ServiceType.office365) && acc
          .tokens
          .exists(_
            .exists(_
              .scopes.exists(_.contains("Calendar.ReadWrite")))
          )
      }
      })
  } yield account

  //  val $allowDownload: Signal[Boolean] = accountAuthorized.signal
  //    .combineWith(accountsCount)
  //    .map{
  //      case (auth, accs) if auth || accs > 0 => true
  //      case _ => false
  //    }

  private val testBook: Book = Book(
    name = "test",
    availabilityStep = 30,
    durationMinutes = 30,
    timeAvailableFor = "P6D",
    subject = "My test meeting",
    description = "My test meeting",
    workHours = WeekWorkSchedule(
      daySchedules = DayOfWeek.workDays.map(day =>
        DayWorkSchedule(
          dayOfWeek = day,
          workingIntervals = WorkingInterval(start = "10:00:00", end = "18:00:00") :: Nil)
      ),
      timezone = systemDefaultTZ.toString
    ),
    startConference = true
  )

  private val serviceTypesMenu: Menu.El = Menu(
    _.absolute := true,
    _.x := 0,
    _.y := 3,
    _.open <-- showServicesPopup.signal,
    _.onClosed.mapTo(false) --> showServicesPopup.writer,

    _.slots.default(
      div(
        small("Select service type", cls := "gray slds-m-left--medium"),
        material.List(
          _ => (ServiceType.google :: ServiceType.office365 :: Nil).map(sType =>



            ListItem(
              _.value := sType.value,
              _.selected := false,
              _ => sType.label,
              _ => composeEvents(onClick)(_.sample(appKey)
                .map(ak => portalApi.accountAuthUrl(
                  ak,
                  sType,
                  daemon = false,
                  "Calendar.ReadWrite" :: Nil,
                  clientOrgId = "",
                  None
                ))) --> Observer[String](onNext = authUrl => dom.window.open(authUrl, "_blank", "width=800,height=600,resizable=1,scrollbars=1")),
            )
          )
        )
      )
    )
  )
  val node: ReactiveHtmlElement[html.Div] = div(
    checkAccount
      .flatMap {
        case Some(account) =>
          createTestBook(account.id).mapTo(true)
        case _ => EventStream.fromValue(false)
      } --> accountAuthorized,
    child.maybe <-- accountAuthorized.signal.mapOptWhenTrue(!_)(p(
      cls := "slds-m-bottom--medium",
      span(
        cls := "slds-is-relative",
        child <-- portalState.$me.map(_.verified).map {
          case true => span(
            "Authorize",
            cls := "blue clickable",
            onClick.mapTo(true) --> showServicesPopup
          )
          case _ => span(
            "Authorize",
            cls := "light blue",
            title := "Please verify your email.",
            cursor := "default"
          )
        },
        serviceTypesMenu,
      ),
      " access to a calendar."
    )),
    p(
      child <-- accountAuthorized.signal
        .combineWith(portalState.$me.map(_.verified)).map {
        case (true, true) => a(
          "Download",
          href <-- appClientId.flatMap(buildSchedulerFile),
          download := "scheduler.html"
        )

        case _ => span(
          "Download",
          cls := "light blue",
          title <-- portalState.$me.map(!_.verified).map {
            case true => "Please verify your email."
            case _ => "Please authorize account"
          },
          cursor := "default"
        )
      },
      span(" html file and open it in your browser.")
    ),

    windowEvents.onMessage
      .filter(_.data.isInstanceOf[String])
      .filter(_.origin == apiOrigin)
      .map(_.data)
      .map(_.asInstanceOf[String])
      .debugLog()
      .filter(r => r.startsWith("{") && r.endsWith("}"))
      .map(response => {
        log.info(s"Response $response")
        response.decodeAs[AuthAccountResponse]
      })
      .flatMap {
        case response if response.status == "success" && response.accountId.nonEmpty =>
          accountAuthorized.set(true)
          createTestBook(response.accountId.get)
        case response =>
          throw new Exception(s"Authorization failed: ${response.details.getOrElse("")}")
          EventStream.empty
      } --> Observer[Unit](onNext = _ => showServicesPopup.set(false)),

  )
}
