package common.ui.icons

import com.github.uosis.laminar.webcomponents.material.{Icon, IconButton}
import com.raquo.airstream.core.{EventStream, Observer}
import com.raquo.laminar.api.L._
import common.ui.icons.IconColor.IconColor
import common.ui.icons.IconType.IconType
import org.scalajs.dom
import com.github.uosis.laminar.webcomponents.material.IconButton.El
import com.raquo.domtypes.jsdom.defs.events.TypedTargetMouseEvent
import com.raquo.laminar.nodes.ReactiveElement
import common.ui.icons.IconShape.IconShape
import common.ui.icons.IconSize.IconSize
import common.ui.mat_components_styles.makeIconButtonOutlined


class IconButtonComponent(icon: String,
                          color: IconColor,
                          shape: Option[IconShape],
                          iconType: IconType,
                          size: IconSize,
                          onclickBinder: Binder[ReactiveElement.Base],
                          materialIconType: IconType //style of internal icon
                         ) {


  def apply(): El = IconButton(
    _.icon := icon,
    _ => cls.toggle("filled")(iconType == IconType.filled),
    _ => cls := color.name,
    _ => cls := size.name,
    _ => shape.map(sh => cls := sh.toString),

    _ => onclickBinder,
    _ => Option.when(materialIconType == IconType.outlined) {
      onMountCallback(makeIconButtonOutlined)
    }
  )
}

object IconButtonComponent {
  def simple(icon: String,
            color: IconColor,
            shape: Option[IconShape] = None,
            iconType: IconType,
            size: IconSize,
            clickObserver: Observer[dom.MouseEvent]
           ): El = new IconButtonComponent(
    icon,
    color,
    shape,
    iconType,
    size,
    onClick --> clickObserver,
    IconType.outlined
  )()

  def apply[T](
                icon: String,
                color: IconColor,
                shape: Option[IconShape] = None,
                iconType: IconType,
                size: IconSize,
                clickObserver: Observer[T],
                clickEffect: EventStream[T],
              ): El = new IconButtonComponent(
    icon,
    color,
    shape,
    iconType,
    size,
    composeEvents(onClick)(_.flatMap(_ => clickEffect)) --> clickObserver,
    IconType.outlined
  )()

}

object IconComponent {

  def apply(icon: String,
            color: IconColor,
            shape: Option[IconShape] = None,
            iconType: IconType = IconType.filled,
            size: IconSize = IconSize.medium,
            materialIconType: IconType = IconType.outlined
           ): Icon.El = {

    Icon(
      _ => icon,
      _ => cls.toggle("mat-outlined")(materialIconType == IconType.outlined),
      _ => cls.toggle("filled")(iconType == IconType.filled),
      _ => shape.map(sh => cls := sh.toString),
      _ => cls := color.name,
      _ => cls := size.name

    )

  }

}


object TrashIcon {
  private def IconElement(onclickBinder: Binder[ReactiveElement.Base]) = IconComponent(
    icon = MaterialIcons.delete,
    iconType = IconType.outlined,
    color = IconColor.`light-red`
  )
    .amend(
      cls := "clickable",
      onclickBinder
    )

  def apply[T](clickEffect: TypedTargetMouseEvent[dom.Element] => EventStream[T],
               clickObserver: Observer[T]): Icon.El = {
    IconElement(
      composeEvents(onClick)(_.flatMap(clickEffect)) --> clickObserver
    )
  }

  def apply(clickObserver: Observer[dom.MouseEvent]): Icon.El = {
    IconElement(
      onClick --> clickObserver
    )
  }

}

object IconColor extends Enumeration {
  case class Val(name: String) extends super.Val(name)

  type IconColor = Val

  val orange: IconColor = Val("orange")
  val `light-brown`: IconColor = Val("light-brown")
  val `lighter-brown`: IconColor = Val("lighter-brown")
  val `brown`: IconColor = Val("brown")
  val grey: IconColor = Val("grey")
  val blue: IconColor = Val("blue")
  val `light-red`: IconColor = Val("light-red")
  val red: IconColor = Val("red")
}

object IconShape extends Enumeration {
  type IconShape = Value

  val circle, square = Value
}

object MaterialIcons {
  val application = "widgets"
  val person = "person"
  val chat = "forum"
  val email = "email"
  val unfold = "unfold_more"
  val arrowRight = "navigate_next"
  val arrowBottom = "expand_more"
  val doubleArrowTop = "keyboard_double_arrow_up"
  val link = "open_in_new"
  val eye = "visibility"
  val copy = "content_copy"
  val add = "add"
  val sync = "sync"
  val settings = "settings"
  val delete = "delete"
  val edit = "edit"
  val done = "done"
  val exclPoint = "priority_high"
  val info = "info"
  val back = "arrow_back_ios"
  val teams = "group"
  val more = "more_horiz"
}

object IconType extends Enumeration {
  type IconType = Value

  val outlined, filled = Value
}

object IconSize extends Enumeration {
  case class Val(name: String) extends super.Val(name)

  type IconSize = Val


  val `x-small`: IconSize = Val("x-small")
  val small: IconSize = Val("small")
  val medium: IconSize = Val("medium")
  val large: IconSize = Val("large")
  val `x-large`: IconSize = Val("x-large")
  val `xx-large`: IconSize = Val("xx-large")
}
