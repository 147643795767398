package service.aurinko_api

import com.raquo.airstream.core.EventStream
import io.circe.generic.auto.{exportDecoder, exportEncoder}
import io.circe.parser
import io.circe.syntax.EncoderOps
import org.scalajs.dom
import service.aurinko_api.AurinkoModels.Book
import service.exception_handler.ApiNotFoundException
import service.http.ApiRequester
import service.ui.spinner.Spinner

class AurinkoApi(apiOrigin: String, spinnerService: Spinner) {

  def validateResponse(resp: dom.XMLHttpRequest): String = resp.status match {
    case c if c >= 200 && c < 300 => resp.responseText
    case 404 => throw parser.decode[ApiNotFoundException](resp.responseText).fold(throw _, v => v)
    case _ => throw parser.decode[AurinkoException](resp.responseText).fold(throw _, v => v)
  }

  val apiRequester: ApiRequester = ApiRequester(apiOrigin, validateResponse, spinnerService)

  def createBook(token: String, book: Book): EventStream[String] = apiRequester.post(
    path = "/book/profiles",
    body = Some(book.asJson.dropNullValues),
    headers = Map("Authorization" -> s"Bearer $token"),
    withCredentials = false
  )
}


case class AurinkoException(message: String, code: String) extends Throwable(message) {

  override
  def toString = s"$message"
}
