package root_pages.aurinko_pages.app.addin_setup

import com.raquo.laminar.api.L._
import portal_router.AddinSetupPage
import service.apis.portal_api.PortalApi

case class AddinSetupComponent($page: Signal[AddinSetupPage], portalApi: PortalApi) {
  val node: Div = div(
    cls := "headered-footered-section",
    p(cls := "header", "Addin setup")
  )
}
