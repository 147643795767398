package service

import com.raquo.laminar.api.L._
import org.scalajs.dom
import portal_router.PortalRouter
import service.clipboard_service.{ClipboardService, ClipboardServiceImp}
import service.local_storage.{LocalStorageService, LocalStorageServiceImp}
import service.scroll_ops.ScrollOps
import service.ui.messages.{Messages, MessagesImp}
import service.ui.spinner.{Spinner, SpinnerImp}

class UiServiceManager(document: dom.Document, portalRouter: PortalRouter) {

  private val spinner = SpinnerImp()
  private val messages = MessagesImp(portalRouter.router.$currentPage)
  private val clipboard = ClipboardServiceImp()
  private val localStorage = LocalStorageServiceImp()
  private val documentScrollOps = new ScrollOps(document.documentElement)

  val node: Div = div(
    spinner.node,
    messages.node,
    clipboard.area,
  )

  def Spinner: Spinner = spinner

  def Messages: Messages = messages

  def Clipboard: ClipboardService = clipboard

  def LocalStorage: LocalStorageService = localStorage

  def DocumentScrollOps: ScrollOps = documentScrollOps
}
