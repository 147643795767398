package root_pages.aurinko_pages.team

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import com.raquo.waypoint.SplitRender
import common.TeamMemberRole
import common.airstream_ops.{SignalNestedOps, ValueToObservableOps}
import org.scalajs.dom.html
import portal_router.{BillingPage, PortalRouter, TeamAppsPage, TeamPage, TeamSettingsPage}
import service.portal_state.{PortalState, TeamMemberAccess}
import service.scroll_ops.ScrollOps
import wvlet.log.Logger

class TeamRoot($route: Signal[TeamPage],
               portalState: PortalState,
               portalRouter: PortalRouter,
               childrenRender: Signal[TeamPage] => SplitRender[TeamPage, HtmlElement],
               documentScrollOps: ScrollOps
              ) {

  private val log = Logger.of[TeamRoot]


  private val eventBinders = List(
    $route.map(_.teamId.some) --> Observer[Option[Int]](portalState.updateTeamId)
  )

  val node: ReactiveHtmlElement[html.Div] = div(
    cls := "app-wrapper",
    child.maybe <-- portalState.$maybeTeam.nestedMap {t => {
      val teamMemberAccess = new TeamMemberAccess(t.signaled)
//      log.info(s"will render team ${t.id} ${t.name} root page")
      div(
        div(
          cls := "header-container",
          div(
            cls := "app-menu",
            cls <-- documentScrollOps.$scrolled.map { case true => "shadow" case false => "" },

            div(
              cls := "slds-grid slds-grid--vertical-align-center",

              a(
                cls := "menu-item primary",
                cls <-- $route.map { case TeamAppsPage(_) => "active" case _ => "" },
                "Apps",
                href := portalRouter.router.absoluteUrlForPage(TeamAppsPage(t.id))),

              child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin).andThen {
                a(
                  cls := "menu-item primary",
                  cls <-- $route.map { case TeamSettingsPage(_) => "active" case _ => "" },
                  "Team",
                  href := portalRouter.router.absoluteUrlForPage(TeamSettingsPage(t.id))
                )
              },

              child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin).andThen {
                a(
                  cls := "menu-item primary",
                  cls <-- $route.map { case BillingPage(_) => "active" case _ => "" },
                  "Billing",
                  href := portalRouter.router.absoluteUrlForPage(BillingPage(t.id))
                )
              }
            )
          )
        ),

        div(
          cls := "app-page-wrapper",
          child <-- childrenRender($route).$view

        )
      )
    }

    }
  ).amend(eventBinders)
}
