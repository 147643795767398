package common.ui.grid_configs

import com.raquo.laminar.api.L._

case class GridConfig3(ratio: (Int, Int, Int), total: Int = 12) {
  /* support total <= 12 */
  object classNames {
    val col_1: String = if (ratio._1 > 0) s"slds-size--${ratio._1}-of-$total" else "hidden"
    val col_2: String = if (ratio._2 > 0) s"slds-size--${ratio._2}-of-$total" else "hidden"
    val col_3: String = if (ratio._3 > 0) s"slds-size--${ratio._3}-of-$total" else "hidden"
  }

  object widthSetters {
    val col_1: String = if (ratio._1 > 0) s"${ratio._1.toDouble * 100 / total}%" else "0%"
    val col_2: String = if (ratio._2 > 0) s"${ratio._2.toDouble * 100 / total}%" else "0%"
    val col_3: String = if (ratio._3 > 0) s"${ratio._3.toDouble * 100 / total}%" else "0%"
  }
}

case class GridConfig4(ratio: (Int, Int, Int, Int), total: Int = 12) {
  /* support total <= 12 */
  object classNames {
    val col_1: String = if (ratio._1 > 0) s"slds-size--${ratio._1}-of-$total" else "hidden"
    val col_2: String = if (ratio._2 > 0) s"slds-size--${ratio._2}-of-$total" else "hidden"
    val col_3: String = if (ratio._3 > 0) s"slds-size--${ratio._3}-of-$total" else "hidden"
    val col_4: String = if (ratio._4 > 0) s"slds-size--${ratio._4}-of-$total" else "hidden"
  }

  object width {
    val col_1: String = if (ratio._1 > 0) s"${ratio._1.toDouble * 100 / total}%" else "0%"
    val col_2: String = if (ratio._2 > 0) s"${ratio._2.toDouble * 100 / total}%" else "0%"
    val col_3: String = if (ratio._3 > 0) s"${ratio._3.toDouble * 100 / total}%" else "0%"
    val col_4: String = if (ratio._4 > 0) s"${ratio._4.toDouble * 100 / total}%" else "0%"
  }
}

case class GridConfig5(ratio: (Int, Int, Int, Int, Int), total: Int = 12) {
  /* support total <= 12 */
  object classNames {
    val col_1: String = if (ratio._1 > 0) s"slds-size--${ratio._1}-of-$total" else "hidden"
    val col_2: String = if (ratio._2 > 0) s"slds-size--${ratio._2}-of-$total" else "hidden"
    val col_3: String = if (ratio._3 > 0) s"slds-size--${ratio._3}-of-$total" else "hidden"
    val col_4: String = if (ratio._4 > 0) s"slds-size--${ratio._4}-of-$total" else "hidden"
    val col_5: String = if (ratio._5 > 0) s"slds-size--${ratio._5}-of-$total" else "hidden"
  }

  object width {
    val col_1: String = if (ratio._1 > 0) s"${ratio._1.toDouble * 100 / total}%" else "0%"
    val col_2: String = if (ratio._2 > 0) s"${ratio._2.toDouble * 100 / total}%" else "0%"
    val col_3: String = if (ratio._3 > 0) s"${ratio._3.toDouble * 100 / total}%" else "0%"
    val col_4: String = if (ratio._4 > 0) s"${ratio._4.toDouble * 100 / total}%" else "0%"
    val col_5: String = if (ratio._5 > 0) s"${ratio._5.toDouble * 100 / total}%" else "0%"
  }
}

case class GridConfig6(ratio: (Int, Int, Int, Int, Int, Int), total: Int = 12) {
  /* support total <= 12 */
  object classNames {
    val col_1: String = if (ratio._1 > 0) s"slds-size--${ratio._1}-of-$total" else "hidden"
    val col_2: String = if (ratio._2 > 0) s"slds-size--${ratio._2}-of-$total" else "hidden"
    val col_3: String = if (ratio._3 > 0) s"slds-size--${ratio._3}-of-$total" else "hidden"
    val col_4: String = if (ratio._4 > 0) s"slds-size--${ratio._4}-of-$total" else "hidden"
    val col_5: String = if (ratio._5 > 0) s"slds-size--${ratio._5}-of-$total" else "hidden"
    val col_6: String = if (ratio._6 > 0) s"slds-size--${ratio._6}-of-$total" else "hidden"
  }

  object widthSetters {
    val col_1: String = if (ratio._1 > 0) s"${ratio._1.toDouble * 100 / total}%" else "0%"
    val col_2: String = if (ratio._2 > 0) s"${ratio._2.toDouble * 100 / total}%" else "0%"
    val col_3: String = if (ratio._3 > 0) s"${ratio._3.toDouble * 100 / total}%" else "0%"
    val col_4: String = if (ratio._4 > 0) s"${ratio._4.toDouble * 100 / total}%" else "0%"
    val col_5: String = if (ratio._5 > 0) s"${ratio._5.toDouble * 100 / total}%" else "0%"
    val col_6: String = if (ratio._6 > 0) s"${ratio._6.toDouble * 100 / total}%" else "0%"
  }
}
