package root_pages.aurinko_pages.app.settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Dialog.El
import com.raquo.laminar.api.L._
import com.raquo.airstream.core.Observer
import com.raquo.airstream.eventbus.EventBus
import com.raquo.laminar.api.eventPropToProcessor
import com.github.uosis.laminar.webcomponents.material.{Textfield, _}
import common.ui._
import common._
import common.ui.element_binders.DialogModifying
import common.airstream_ops.{EventStreamOps, OptionSignalOps, SignalNestedOps, SignalOps}
import common.forms.{ColorInputFormOps, FormsLocalExceptionHandling, ImageInputFormOps, TextareaFormOps, TextfieldFormOps}
import common.ui.buttons_pair.ButtonsPairComponent
import org.scalajs.dom
import org.scalajs.dom.MouseEvent
import portal_router.{PortalRouter, TeamAppsPage}
import service.apis.portal_api.PortalApi
import service.portal_state.{PortalState, TeamMemberAccess}

import scala.scalajs.js.timers.setTimeout

object ApplicationDetails {
  case class Handle(portalApi: PortalApi) {
    val portalApplicationBus: EventBus[PortalApplication] = new EventBus[PortalApplication]
    val editableModel: Var[Option[ApplicationModel]] = Var(None)
  }

  val showDeletePopup = Var(false)

  private def EditPopup(
                 portalApi: PortalApi,
                 portalState: PortalState,
                 inputData: Var[Option[ApplicationModel]],
                 outputData: EventBus[PortalApplication] = new EventBus[PortalApplication],
               ): Dialog.El = {

    Dialog(
      _.open <-- inputData.signal.map(_.isDefined),
      _ => cls := "width--medium",
      _.onClosing.mapTo(None) --> inputData,
      _.heading <-- portalState.$maybeApp.map(v => if (v.isDefined) v.get.name else ""),

      _ => child.maybe <-- inputData.signal.nestedMap { appEditableModel => {

        div(

          cls := "slds-grid slds-grid--vertical gap--large",

          FormsLocalExceptionHandling.errorView(appEditableModel.formError.signal),
          Textfield(
            _.outlined := true,
            _.label := "Name",
            _.required := true,
            _.pattern := nameFieldPattern,
            _ => controlled(
              value <-- appEditableModel.name,
              onInput.mapToValue --> appEditableModel.name
            )
          ).bindToForm(appEditableModel.formState),

          Textfield(
            _.outlined := true,
            _.label := "External name",
            _.helper := "What your users will see",
            _.helperPersistent := true,

            _.value <-- appEditableModel.externalName,
            _ => onInput.mapToValue --> appEditableModel.externalName,

          ).bindToForm(appEditableModel.formState),

          Textfield(
            _.outlined := true,
            _.label := "Website URL",
            _.value <-- appEditableModel.websiteUrl,
            _.pattern := "(http:\\/\\/|https:\\/\\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?",
            _ => onInput.mapToValue --> appEditableModel.websiteUrl
          ).bindToForm(appEditableModel.formState),

          Textarea(
            _.outlined := true,
            _.label := "Description",

            _ => controlled(
              value <-- appEditableModel.description,
              onInput.mapToValue --> appEditableModel.description
            ),

          ).bindToForm(appEditableModel.formState),

          LogoInputComponent(appEditableModel.logo)
            .bindToForm(appEditableModel.formState),

          ColorInput(
            colorVar = appEditableModel.primaryColor,
            fieldLabel = "Primary color",
            helpText = "A color to use as your primary theme's color. The value must be a valid HTML color code, for example #00ccff, rgb(0, 0, 1), green",
            spanCls = "mdc-theme--text-secondary-on-background"
          ).bindToForm(appEditableModel.formState),

          ColorInput(
            colorVar = appEditableModel.secondaryColor,
            fieldLabel = "Secondary color",
            helpText = "A color to use for buttons and text inputs. The value must be a valid HTML color code, for example #00ccff, rgb(0, 0, 1), green",
            spanCls = "mdc-theme--text-secondary-on-background"
          ).bindToForm(appEditableModel.formState),
        )
      }},


      _.slots.primaryAction(

        ButtonsPairComponent[(PortalApplication, PortalUser), dom.MouseEvent](
          primaryDisabled = inputData.signal
            .flatMap(_.$traverse(_.formState.$submitAllowed))
            .map(!_.contains(true)),

          primaryEffect = () =>
            inputData.signal.stream
              .collect { case Some(model) => model }
              .withCurrentValueOf(portalState.$teamApp)
              .flatMap { case (model, teamApp) => for {
                m <- portalApi.updateApiApplication(teamApp.appKey, model.toImmutableModel)
                me <- portalApi.getMe
              } yield (m, me)
              }.withErrorHandlingAndCollect(
                FormsLocalExceptionHandling
                  .handler(str => inputData.now.foreach(_.formError.set(str.some)))),

          primaryObserver = Observer[(PortalApplication, PortalUser)](onNext = v => {
            outputData.emit(v._1)
            inputData.set(None)
            portalState.updateApp(Some(v._1))
            portalState.updateMe(v._2)
          }),

          secondaryObserver = inputData.writer.contramap((_: dom.MouseEvent) => None)
        ).node,

      ),


    )
      .withPing(portalApi)
      .withFormCaching(portalState.FormCache,
        portalState.Session.$sessionExpiredEvents,
        inputData,
        portalState.$app.map(_.toEditModel),
        (m: ApplicationModel) => inputData.now.foreach(_.updateFromModel(m)),
        (cachedForm: FormModel) => cachedForm match {
          case v: ApplicationModel => v.some
          case _ => None
        },
        () => inputData.now.foreach(_.formState.validate()))
  }


  private def DeletePopup(portalApi: PortalApi, portalState: PortalState, portalRouter: PortalRouter): El = {
    val confirmText: Var[String] = Var("")

    val deleteField = AuFormField(Textfield(
      _.outlined := true,
      _.helper := "Confirm deletion",
      _.helperPersistent := true,
      _.required := true,
      _ => cls := "slds-col slds-size--9-of-12",
      _.value <-- confirmText,
      _ => onInput.mapToValue --> confirmText
    ))


    Dialog(
      _.open <-- showDeletePopup,
      _ => cls := "width--medium",
      _.heading := "Delete application",
      _.onClosing.mapTo(false) --> showDeletePopup,
      _.onClosing.mapTo("") --> confirmText,
      _ => PingTools.dialogBinders(portalApi),

      _.slots.default(
        div(
          p("Deleting your app is irreversible. If you delete this application, all connected accounts and associated resources such as subscriptions will be deleted."),
          p("Enter \"delete\" below to confirm you want to permanently delete it:", cls := "slds-m-vertical--small"),
          div(cls := "slds-grid", deleteField.node)
        )),
      _.slots.primaryAction(

        div(
          ButtonsPairComponent[(PortalUser, Int), MouseEvent](
            primaryButtonText = "Delete",
            primaryDisabled = confirmText.signal.map(_ != "delete"),
            primaryEffect = () => EventStream.fromValue(())
              .sample(portalState.$teamApp.map(_.appKey))
              .flatMap(p => for {
                _ <- portalApi.deleteApp(p)
                me <- portalApi.getMe
              } yield me -> p.teamId),

            primaryObserver = Observer[(PortalUser, Int)](onNext = {
              case (me, tId) =>
                showDeletePopup.set(false)
                setTimeout(0) {
                  portalState.updateMe(me)
                }
                portalRouter.navigate(TeamAppsPage(tId))

            }),

            secondaryObserver = Observer[dom.MouseEvent](onNext = _ => {
              showDeletePopup.set(false)
              confirmText.set("")
            })

          ).node,

        )))
  }

  def LeftSection(portalApi: PortalApi, portalState: PortalState, updateBus: EventBus[Unit], portalRouter: PortalRouter): Div = {

    val teamMemberAccess = new TeamMemberAccess(portalState.$team)
    val handle = Handle(portalApi)

    div(
      child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin).andThen(EditPopup(
        portalApi,
        portalState,
        handle.editableModel,
        handle.portalApplicationBus,
      )),

      child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin).andThen(
        DeletePopup(portalApi, portalState, portalRouter)
      ),

      div(
        cls := "left-section",

        child <-- portalState.$maybeApp.map(_.isDefined).map { //todo: why this check
          case true =>
            div(
              cls := "content",

              div(
                cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center caption",
                div(
                  cls := "slds-grid slds-grid--vertical-align-center",

                  child.maybe <-- portalState.$app.map(_.logo).map(_.map(logo => img(
                    src := logo.toImage,
                    cls := "slds-m-right--medium",
                    width := "1.5rem",
                    height := "1.5rem",
                  ))),

                  p(child.text <-- portalState.$app.map(_.name), cls := "slds-col title--level-1"),
                ),
                child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin).andThen(
                  Icon(
                    _ => cls := "light medium clickable",
                    _ => "edit",
                    _ => composeEvents(onClick)(_
                      .sample(portalState.$app)
                      .map(_.toEditModel)
                      .map(_.some)
                    ) --> handle.editableModel
                  )
                )
              ),
              div(
                cls := "content-container",
                div(
                  cls := "border-top--light content",

                  div(
                    cls := "slds-m-bottom_large slds-m-top--small gray",
                    span("Your app's branding details that may be presented to end users. " +
                      "These details will be used by our login forms such as MS Exchange or Google service account."),
                  ),

                  div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Id"),
                    span(child.text <-- portalState.$app.map(_.id)),
                  ),

                  child.maybe <-- portalState.$app.map(_.externalName).map(_.map(extName => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "External name"),
                    span(extName),
                  ))),

                  child.maybe <-- portalState.$app.map(_.websiteUrl).map(_.map(url => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Website URL"),
                    span(url),
                  ))),

                  child.maybe <-- portalState.$app.map(_.description).map(_.map(description => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Description"),
                    span(description),
                  ))),

                  child.maybe <-- portalState.$app.map(_.domainAlias).map(_.map(domainAlias => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Domain alias"),
                    span(domainAlias),
                  ))),

                  child.maybe <-- portalState.$app.map(_.primaryColor).map(_.map(primaryColor => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Primary color"),
                    span(
                      cls := "slds-grid slds-grid--vertical-align-center",
                      span(cls := "", primaryColor),
                      span(cls := "color-element-values", backgroundColor := primaryColor),
                    )
                  ))),

                  child.maybe <-- portalState.$app.map(_.secondaryColor).map(_.map(secondaryColor => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Secondary color"),
                    span(
                      cls := "slds-grid slds-grid--vertical-align-center",
                      span(cls := "l", secondaryColor),
                      span(cls := "color-element-values", backgroundColor := secondaryColor),
                    )
                  ))),

                  div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Created at"),
                    span(child.text <-- portalState.$app.map(_.createdAt.toPrettyLocalFormat)),
                  ),
                ),

                div(
                  cls := "border-top--light",
                  p(cls := "title--level-2 slds-m-vertical--small", "Application APIs"),
                  ApplicationCapabilities(portalApi, portalState, updateBus).node
                ),

                div(
                  cls := "border-top--light slds-p-top--small",
                  child.maybe <-- teamMemberAccess.minRoleCheck(TeamMemberRole.admin).andThen {
                    Button(
                      _.label := "Delete application",
                      _.outlined := true,
                      _ => cls := "red",
                      _ => onClick.mapTo(true) --> showDeletePopup,
                    )
                  }
                ),
              )
            )
          case _ => p(cls := "gray slds-grid slds-grid--align-center slds-m-around--large", "No details")
        }
      ),
    )
  }
}
