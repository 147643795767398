package common.ui.branded

import cats.implicits.catsSyntaxOptionId
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.ValueToObservableOps
import org.scalajs.dom.html
import wvlet.log.Logger

object Buttons {

  private val log = Logger.of[Buttons.type]

  def googleOAuthButtonLight($disabled: Signal[Boolean] = false.signaled): ReactiveHtmlElement[html.Image] =
    imageButton(
    "google_oauth_button.png", "google_disabled_oauth_button.png".some, $disabled
  )

  def officeOauthButtonLight($disabled: Signal[Boolean] = false.signaled): ReactiveHtmlElement[html.Image] =
    imageButton(activeButtonImage = "microsoft_outh_button.svg", None, $disabled)

  private def imageButton(activeButtonImage: String,
                          disabledButtonImage: Option[String],
                          $disabled: Signal[Boolean]
                         ) = img(
    src <-- $disabled.map {
      case true =>
        disabledButtonImage.getOrElse(activeButtonImage)
      case false =>
        activeButtonImage
    },
    cls := "custom-image-button",
    cls <-- $disabled.map{case true => "disabled" case _ => ""}
  )
}
