package root_pages.aurinko_pages.app.settings

import scala.scalajs.js.typedarray.{ArrayBuffer, TypedArrayBuffer}
import org.scalajs.dom
import org.scalajs.dom.raw.HTMLImageElement
import org.scalajs.dom.{FileList, FileReader, html}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import com.github.uosis.laminar.webcomponents.material.Button.El
import com.github.uosis.laminar.webcomponents.material.Button

import java.nio.ByteBuffer
import java.util.Base64
import common.ApplicationLogo
import common.ui.{ApplicationLogoComponent, AuFormCustomValidator, AuFormCustomValidatorExp}
import wvlet.log.Logger

case class LogoInputComponent(
                               logoVar: Var[Option[ApplicationLogo]],
                               className: String = "",
                               primaryButtonCls: String = "white",
                               primaryButtonNoLogoLabel: String = "Choose",
                               primaryButtonWithLogoLabel: String = "Change",
                               primaryButtonRaised: Boolean = true,
                               removeButtonCls: String = "slds-m-right_medium",
                               removeButtonLabel: String = "Remove",
                               removeButtonRaised: Boolean = false
                             ) {
  private val log = Logger.of[LogoInputComponent.type]
  private val initialLogoVar = logoVar.now()
  val validator: AuFormCustomValidator = AuFormCustomValidator()
  val validatorExp: AuFormCustomValidatorExp = AuFormCustomValidatorExp($dirty = logoVar.signal.map(_ != initialLogoVar))

  val hiddenLogoInput: ReactiveHtmlElement[html.Input] = input(
    typ := "file",
    accept := "image/png",
    display := "none",
    onInput.map(e => e.target.asInstanceOf[dom.html.Input].files) -->
      Observer[dom.FileList](onNext = encodeFileListToStringAndConvertToIcon))

  val removeButton: El = Button(
    _ => cls := removeButtonCls,
    _.label := removeButtonLabel,
    _.raised := removeButtonRaised,
    _ => onClick --> Observer[dom.MouseEvent](onNext = _ => logoVar.set(None))
  )

  val changeChooseButton: El = Button(
    _ => cls := primaryButtonCls,
    _.label <-- logoVar.signal.map(_.isDefined).map {
      case true => primaryButtonWithLogoLabel
      case _ => primaryButtonNoLogoLabel
    },
    _.raised := primaryButtonRaised,
    _ => onClick --> Observer[dom.MouseEvent](onNext = _ => hiddenLogoInput.ref.click())
  )

  val node: ReactiveHtmlElement[html.Div] = div(
    div(
      cls := className,
      paddingLeft := "16px",

      hiddenLogoInput,

      div(
        small(cls := "gray slds-m-top--medium", "Application logo")
      ),

      div(
        cls := "slds-grid slds-grid--vertical-align-center slds-m-vertical--xx-small",
        child <-- ApplicationLogoComponent(logoVar.signal),
        child.maybe <-- logoVar.signal.map {
          case None => None
          case Some(_) => Some(removeButton)
        },
        changeChooseButton,
      ),

      div(
        cls := "slds-m-bottom--medium",
        small(cls := "gray", fontSize := "0.75rem", "Supported image size - 64x64px")
      ),
    ),

    logoVar.signal.map(_ != initialLogoVar) --> validator.dirty.writer,
  )

  def encodeFileListToStringAndConvertToIcon(givenFile: FileList): Unit = {
    if (givenFile.length > 0) {
      val file = givenFile.item(0)

      if (file.`type` == "image/png") {
        val fileReader = new FileReader
        fileReader.onload = _ => {
          val res: ByteBuffer = TypedArrayBuffer.wrap(fileReader.result.asInstanceOf[ArrayBuffer])
          val array = Array.ofDim[Byte](res.remaining())
          res.get(array)

          val base64img = Base64.getEncoder.encodeToString(array)

          val imgSrc = s"data:${file.`type`};base64,$base64img"

          val image = dom.document.createElement("img").asInstanceOf[HTMLImageElement]
          image.src = imgSrc
          image.onload = _ => {
            if ((image.height <= 64) && (image.width <= 64))
              logoVar.set(Some(ApplicationLogo(base64img, file.`type`)))
            else
              log.warn(s"can't accept the ${image.height}x${image.width} size on logo")
          }
        }
        fileReader.readAsArrayBuffer(file)
        hiddenLogoInput.ref.value = ""
      } else {
        log.warn(s"can't accept the ${file.`type`} type on logo")
      }
    } else {
      log.warn(s"given file is empty")
    }
  }

}
