package root_pages

import com.github.uosis.laminar.webcomponents.material.Textfield.El
import com.github.uosis.laminar.webcomponents.material.{Button, Textfield}
import com.raquo.laminar.api.L._
import common.airstream_ops.SignalOps
import common.nameFieldPattern
import common.ui.mat_components_styles.setInputAutoCompleteAndId
import service.exception_handler.ApiException

import scala.util.{Failure, Success}

package object auth_pages {
  def AuthPagesLogo: Div =
    div(
      cls := "slds-grid slds-grid_vertical-align-center slds-grid--align-center",
      img(src := "aurinko_icon.svg", width := "31px", cls := "slds-p-around--xx-small"),
      img(src := "aurinko.svg", height := "20px", verticalAlign := "super", cls := "slds-p-around--xx-small")
    )

  def orLine: Div = div(
    cls := "slds-grid slds-grid_vertical-align-end mdc-theme--text-disabled-on-background",
    div(
      borderTop := "var(--light-border)",
      height := ".5rem",
      cls := "slds-size--3-of-7"
    ),
    span("Or", cls := "slds-size--1-of-7 slds-text-align--center"),
    div(
      borderTop := "var(--light-border)",
      height := ".5rem",
      cls := "slds-size--3-of-7"
    )
  )

  implicit final class ApiResponseOpps[T](private val $resp: EventStream[T]) extends AnyVal {
    def validateWithLocalError(error: Var[Option[String]]): EventStream[T] = $resp.recoverToTry.collect {
      case Failure(exception) if exception.isInstanceOf[ApiException] =>
        println(s"failure ${exception.asInstanceOf[ApiException].message}")
        error.set(Some(exception.asInstanceOf[ApiException].message))
        None
      case Success(value) =>
        println(s"success $value")
        error.set(None)
        Some(value)
      case Failure(exception) =>
        println(s"another failure $value")
        error.set(Some(exception.getMessage))
        None
    }.collect { case Some(v) => v }
  }

  def validateApiResponse[T]($stream: EventStream[T], error: Var[Option[String]]): EventStream[T] = $stream.recoverToTry.collect {
    case Failure(exception) if exception.isInstanceOf[ApiException] =>
      error.set(Some(exception.asInstanceOf[ApiException].message))
      None
    case Success(value) =>
      error.set(None)
      Some(value)
  }.collect { case Some(v) => v }

  val oauthButtons: Div = div(
    cls := "slds-align--absolute-center slds-p-vertical--small",
    div(
      cls := "slds-grid max-content-width",
      //    p("Connect with:", cls := "slds-p-vertical--xx-small"),
      Button(
        _.slots.icon(
          img(src := "google.png")
        ),
        _.label := "Login with Google",
        _ => cls := "red slds-size--1-of-2 slds-p-horizontal--medium oauth-button",
        _.raised := true
      ),
      Button(
        _.slots.icon(
          img(src := "microsoft.png")
        ),
        _.label := "Login with Microsoft",
        _ => cls := "blue slds-size--1-of-2 slds-p-horizontal--medium oauth-button",
        _.raised := true
      )
    )
  )
  def nameTextfield: El = Textfield(
    _ => cls := "slds-size--1-of-1 slds-p-vertical--x-small",
    _.outlined := true,
    _.label := "Name",
    _.`type`:= "text",
    _.required := true,
    _.validationMessage := "Please, enter a name",
    _ => autoComplete := "new-password",
    _.pattern := nameFieldPattern
  )

  def emailTextfield(rememberedEmail: Signal[Option[String]] = Signal.fromValue(None)): El = Textfield(
    _ => idAttr := "email",
    _.outlined := true,
    _ => cls := "slds-size--1-of-1 slds-p-vertical--x-small",
    _.outlined := true,
    _.label := "Email",
    _.`type`:= "email",
    _.value <-- rememberedEmail.stream.collect{ case Some(value) => value },
    _.required := true,
    _.validationMessage := "Please, enter a valid email",
    _ => onMountCallback(setInputAutoCompleteAndId("email", "email"))
  )
}
