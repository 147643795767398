package root_pages.aurinko_pages.app.virtual_api

import com.github.uosis.laminar.webcomponents.material
import com.raquo.airstream.state.Var
import com.raquo.laminar.api.L._
import portal_router._
import service.apis.dynamic_api.DataMapperApi.ApiException
import service.apis.dynamic_api.DataMapperModels._
import service.portal_state.PortalState
import common.{CirceStringOps, InstantOps}
import common.airstream_ops.ValueToObservableOps
import common.ui.breadcrumbs.BreadcrumbsItem
import root_pages.aurinko_pages.app.virtual_api.components.VirtualExpansionPanelComponent
import service.apis.portal_api.PortalApi
import service.scroll_ops.ScrollOps
import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Button
import common.ui.icons.{IconColor, IconComponent, IconSize, IconType, MaterialIcons}
import io.circe.generic.auto.exportDecoder
import io.circe.{Json, parser}
import org.scalajs.dom
import org.scalajs.dom.FileReader
import org.scalajs.dom.raw.FileList
import service.apis.dynamic_api.DataMapperApi
import wvlet.log.Logger
import scala.util.{Failure, Success}

case object ObjectsPage {
  private val log = Logger.of[ObjectsPage.type]

  class RelatedMappingsSelector(
                                 private val portalApi: PortalApi,
                                 private val dynamicApi: DataMapperApi.API,
                                 private val $appKey: Signal[common.AppKey],
                                 private val $metadata: Signal[Option[MetadataModels.Metadata]],
                                 private val canCreate: Signal[Boolean] = Signal.fromValue(false),
                                 private val portalState: PortalState,
                                 documentScrollOps: ScrollOps,
                                 portalRouter: PortalRouter
                               ) {
    private val $isVirtualMetadata: Signal[Boolean] = $metadata.signal.map(_.exists(_.serviceType.isEmpty))

    private val configurationIdBus: Var[Long] = Var[Long](0)

    val configuration: Var[Option[ConfigurationModels.Configuration]] = Var(None)
    val mapping: Var[Option[MappingModels.Mapping]] = Var(None)
    val providerMetadata: Var[Option[MetadataModels.Metadata]] = Var(None)

    private val readOnlyMode: Var[Boolean] = Var[Boolean](false) // read only mode for mapping

    // busses
    private val onUpdateConfiguration = new EventBus[ConfigurationModels.Configuration]
    private val onDeleteConfiguration = new EventBus[ConfigurationModels.Configuration]
    private val onUpdateMappingInput = new EventBus[MappingModels.Mapping]
    private val onUpdateMappingOutput = new EventBus[MappingModels.Mapping]

    // dialogs
    private val confirmationDialog = new components.dialogs.general.Confirmation(portalApi: PortalApi, portalState: PortalState)
    private val configurationDialog = new components.dialogs.configuration.Configuration(
      $appKey,
      dynamicApi,
      portalApi,
      onUpdateConfiguration.writer.contramap((x: (String, ConfigurationModels.Configuration)) => x._2),
      portalState
    )

    def $readOnlyMode: Signal[Boolean] = readOnlyMode.signal

    def mappingUpdater: Observer[MappingModels.Mapping] = onUpdateMappingInput.writer

    def mappingChanges: EventStream[MappingModels.Mapping] = onUpdateMappingOutput.events

    /**
     * Important! The dialogs position need to be placed outside of the left right panel
     */
    def dialogs: HtmlElement = div(
      common.ui.Attribute.Selector := "data_mapper.metadata_page.related_mappings.dialogs",

      // select
      configurationIdBus.signal
        .combineWith($isVirtualMetadata)
        .combineWith($appKey)
        .flatMap {
          case (configurationId, true, appKey) if configurationId > 0 =>
            dynamicApi.configuration(appKey).item(configurationId)
              .recoverToTry
              .map {
                case Success(c) =>
                  Some(c)
                case Failure(exception) =>
                  log.warn(s"wrong configuration id, ${exception.getMessage}")
                  None
              }
          case _ =>
            EventStream.fromValue(None)
        }
        --> configuration.writer,

      confirmationDialog.node,
      configurationDialog.node,
    )

    def node: HtmlElement = {
      val configurations = Var[List[ConfigurationModels.Configuration]](Nil)

      div(
        common.ui.Attribute.Selector := "data_mapper.metadata_page.related_mappings.node",

        // update mapping
        onUpdateMappingInput.events
          .withCurrentValueOf($isVirtualMetadata)
          .filter(x => x._2)
          .map(_._1)
          .flatMap(m => helpers.cache.mapping.SaveModel($appKey, dynamicApi, m))
          --> Observer.combine(mapping.writer.contramap((m: MappingModels.Mapping) => Some(m)), onUpdateMappingOutput.writer),

        // update configuration
        onUpdateConfiguration.events
          .withCurrentValueOf($isVirtualMetadata)
          .filter(x => x._2)
          .map(_._1)
          .flatMap(m =>
            if (m.mappingsId.getOrElse[Long](0) <= 0)
              helpers.cache.mapping.SaveModel($appKey, dynamicApi, MappingModels.Mapping(
                  name = Some(s"${m.name.getOrElse("")}.mapping"),
                  data = Some(MappingModels.ClassList()),
                ))
                .map(x => m.copy(mappingsId = x.id))
            else
              EventStream.fromValue(m)
          )
          .flatMap(c => helpers.cache.configuration.SaveModel($appKey, dynamicApi, c))
          --> Observer.combine(
          configuration.writer
            .contramap((c: ConfigurationModels.Configuration) => Some(c)),
          configurations.writer
            .contramap((c: ConfigurationModels.Configuration) => configurations.now()
              .filter(!_.id.contains(c.id.getOrElse[Long](0)))
              .appended(c)),
        ),

        // remove configuration and mapping by id and close page
        onDeleteConfiguration.events
          .withCurrentValueOf($isVirtualMetadata)
          .filter(x => x._1.id.isDefined && x._2)
          .map(_._1)
          .withCurrentValueOf($appKey)
          .flatMap(x => dynamicApi.configuration(x._2).delete(x._1.id.get).mapTo(x))
          .flatMap {
            case (c, appKey) if c.mappingsId.exists(_ > 0) =>
              dynamicApi.mapping(appKey).delete(c.mappingsId.get).mapTo(c)
            case x =>
              EventStream.fromValue(x._1)
          }
          --> Observer.combine(
          configuration.writer
            .contramap((c: ConfigurationModels.Configuration) =>
              configuration.now().flatMap {
                case x if x.id.contains(c.id.getOrElse[Long](0)) => None
                case x => Some(x)
              }),
          configurations.writer
            .contramap((c: ConfigurationModels.Configuration) => configurations.now()
              .filter(!_.id.contains(c.id.getOrElse[Long](0)))),
        ),

        // load mappings by configuration
        configuration.signal
          .map(_.flatMap(_.mappingsId))
          .flatMap {
            case Some(mappingsId) =>
              // TODO: rethink, reduce cache
              helpers.cache.mapping.LoadModel($appKey, mappingsId, dynamicApi)
                .recoverToTry
                .map {
                  case Success(model) =>
                    readOnlyMode.set(false)
                    Some(model)
                  case Failure(exception) =>
                    exception match {
                      case DataMapperApi.NotImplementedFields(fields, message, `object`: MappingModels.Mapping) =>
                        log.warn(s"incorrect mapping fields, $message")
                        readOnlyMode.set(fields.nonEmpty)
                        Some(`object`)
                      case e =>
                        readOnlyMode.set(true)
                        throw e
                    }
                }
            case _ =>
              EventStream.fromValue[Option[MappingModels.Mapping]](None)
          }
          --> mapping.writer,

        // load provider metadata by configuration
        configuration.signal
          .map(c => (c.flatMap(_.providerMetadataId), c.flatMap(_.providerMetadata), c.flatMap(_.configAccountId)))
          .withCurrentValueOf($appKey)
          .flatMap {
            case (Some(pmId), _, _, appKey) =>
              dynamicApi.metadata(appKey).item(pmId).map(Some(_))
            case (_, Some(pmName), aId, appKey) =>
              dynamicApi.metadata(appKey).standard.item(pmName, accountId = aId)
                .recoverToTry
                .map {
                  case Success(m) =>
                    Some(m.convertToMetadata)
                  case Failure(exception) =>
                    exception match {
                      case e: ApiException if aId.isDefined && e.status == 400 =>
                        log.info(s"$pmName standard metadata something went wrong, need re-authentication")
                        None
                      case e: ApiException if aId.isEmpty && e.status == 400 =>
                        log.info(s"$pmName standard metadata something went wrong, authenticate is required")
                        None
                      case e => throw e
                    }
                }
            case _ =>
              EventStream.fromValue[Option[MetadataModels.Metadata]](None)
          }
          --> providerMetadata.writer,

        // UI
        div(
          div(
            cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center slds-m-bottom_medium",
            p("Related mappings", cls := "title--level-2 "),
            child.maybe <-- canCreate.map {
              case true => Some(
                IconComponent(
                  icon = MaterialIcons.add,
                  color = IconColor.blue,
                  iconType = IconType.outlined,
                  size = IconSize.medium
                ).amend(
                  cursor := "pointer",
                  title := "Add new mapping",
                  composeEvents(onClick.stopPropagation)(_
                    .sample($metadata.map(d => (d.flatMap(_.id), d.flatMap(_.name).getOrElse(""))))
                    .map(d => ConfigurationModels.Configuration(
                      clientMetadataId = d._1,
                      clientMetadata = Option.when(d._1.isEmpty)(d._2),
                    )))
                    --> configurationDialog.writer)
              )
              case _ => None
            },
          ),
          components.TableComponent[ConfigurationModels.Configuration, Long](
            load = (_, _, _) => configurations.signal
              .changes
              .map(items => DataMapperApi.PageOf[ConfigurationModels.Configuration](
                records = items.sortBy(_.name.getOrElse(""))
              )),
            key = row => row.id.getOrElse(0),
            render = (_, $configuration) => {
              val $isActive: Signal[Boolean] = $configuration
                .map(_.id)
                .combineWith(configuration.signal.map(_.flatMap(_.id)))
                .map[Boolean](x => x._2.isDefined && x._1.contains(x._2.get))

              div(
                cls := "slds-grid slds-grid--vertical slds-m-bottom--small clicked slds-border_bottom",
                // click on the virtual metadata
                composeEvents(onClick.stopPropagation)(_
                  .sample($isVirtualMetadata)
                  .filter(_ == true)
                  .sample($configuration)
                  .withCurrentValueOf($isActive)
                  .map[Option[ConfigurationModels.Configuration]] {
                    case (c, false) => Some(c)
                    case _ => None
                  })
                  --> configuration.writer,
                a(
                  // click on the provider metadata
                  composeEvents(onClick)(_
                    .sample($isVirtualMetadata)
                    .filter(_ == false)
                    .sample($configuration.map(c => (c.clientMetadataId, c.clientMetadata, c.id)))
                    .withCurrentValueOf($appKey)
                    .map[Option[portal_router.Page]] {
                      case (Some(metadataId), _, configurationId, appKey) =>
                        Some(VirtualAPIsPage_CustomMetadata(appKey, metadataId, configurationId))
                      case (None, Some(metadataName), configurationId, appKey) =>
                        Some(VirtualAPIsPage_StandardMetadata(appKey, metadataName, configurationId))
                      case _ => None
                    }) --> portalRouter.gotoSome,
                  cls := "slds-grid brown slds-grid--align-spread slds-grid--vertical-align-center",
                  // radio-box & title & details
                  p(
                    cls := "slds-grid slds-grid--vertical-align-center",
                    div(
                      cls := "slds-grid slds-grid--vertical",
                      // title
                      div(child.text <-- $configuration.map(_.name.getOrElse[String](""))),
                      // details
                      child.maybe <-- $isVirtualMetadata
                        .map[(Boolean, Option[(String, Option[portal_router.Page])] => Option[HtmlElement])]((_, {
                          case Some((title, Some(_))) => Some(small(
                            cls := "gray slds-col text-xx-small slds-m-right--x-small",
                            title,
                          ))
                          case Some((title, _)) => Some(small(
                            cls := "gray slds-col text-xx-small slds-m-right--x-small",
                            title,
                          ))
                          case _ => None
                        }))
                        .map {
                          case (true, render) => Some(div(
                            // Provider service type
                            child.maybe <-- $configuration
                              .map(x => (
                                x.providerMetadataServiceType.map(_.value),
                                x.providerMetadataServiceType.map(_.label),
                                x.providerMetadata.orElse(x.providerMetadataName).getOrElse("").toLowerCase(),
                              ))
                              .map[Option[(String, Option[portal_router.Page])]] {
                                case (Some(pstValue), Some(pstLabel), pmName) if pstValue.toLowerCase() != pmName && pstLabel.toLowerCase() != pmName =>
                                  Some((pstLabel, None))
                                case _ =>
                                  None
                              }
                              .map(render),
                            // Provider api description
                            child.maybe <-- $configuration
                              .map(x => (
                                x.providerMetadataServiceType.map(_.value).getOrElse[String]("").toLowerCase(),
                                x.providerMetadataServiceType.map(_.label).getOrElse[String]("").toLowerCase(),
                                x.providerMetadata.orElse(x.providerMetadataName).getOrElse("").toLowerCase(),
                                x.providerDescription.orElse(x.providerDescriptionName).getOrElse(""),
                              ))
                              .map[Option[(String, Option[portal_router.Page])]] {
                                case (pstValue, pstLabel, pmName, pdName) if pstValue != pdName.toLowerCase() && pstLabel != pdName.toLowerCase() && pmName != pdName.toLowerCase() =>
                                  Some((pdName, None))
                                case _ =>
                                  None
                              }
                              .map(render),
                            // Provider account
                            child.maybe <-- $configuration
                              .map(x => (x.configAccountId, x.configAccountDescription))
                              .map[Option[(String, Option[portal_router.Page])]] {
                                case (Some(aId), aName) if aId > 0 =>
                                  Some(aName.getOrElse[String](""), None)
                                case _ =>
                                  None
                              }
                              .map(render),
                            // Provider metadata
                            child.maybe <-- $configuration
                              .map(x => (
                                x.providerMetadataId,
                                x.providerMetadataName,
                                x.providerMetadata,
                              ))
                              .combineWith($metadata.map(x => (x.flatMap(_.id), x.flatMap(_.name))))
                              .combineWith(configuration.signal.map(_.flatMap(_.id)))
                              .withCurrentValueOf($appKey)
                              .map[Option[(String, Option[portal_router.Page])]] {
                                case (_, _, Some(pmName), _, mName, configurationId, appKey) if !mName.contains(pmName) =>
                                  Some((pmName, Some(VirtualAPIsPage_StandardMetadata(appKey, pmName, configurationId = configurationId))))
                                case (Some(pmId), Some(pmName), _, mId, _, configurationId, appKey) if !mId.contains(pmId) =>
                                  Some((pmName, Some(VirtualAPIsPage_CustomMetadata(appKey, pmId, configurationId = configurationId))))
                                case (_, _, Some(pmName), _, mName, configurationId, appKey) if !mName.contains(pmName) =>
                                  Some((pmName, None))
                                case (Some(pmId), Some(pmName), _, mId, _, configurationId, appKey) if !mId.contains(pmId) =>
                                  Some((pmName, None))
                                case _ =>
                                  None
                              }
                              .map(render),
                          ))
                          case (_, render) => Some(div(
                            // Virtual metadata
                            child.maybe <-- $configuration
                              .map(x => (x.clientMetadataId, x.clientMetadataName, x.clientMetadata))
                              .combineWith($metadata.map(x => (x.flatMap(_.id), x.flatMap(_.name))))
                              .withCurrentValueOf(configuration.signal.map(_.flatMap(_.id)))
                              .withCurrentValueOf($appKey)
                              .map[Option[(String, Option[portal_router.Page])]] {
                                case (_, _, Some(cmName), _, mName, configurationId, appKey) if !mName.contains(cmName) =>
                                  Some((cmName, Some(VirtualAPIsPage_StandardMetadata(appKey, cmName, configurationId = configurationId))))
                                case (Some(cmId), Some(cmName), _, mId, _, configurationId, appKey) if !mId.contains(cmId) =>
                                  Some((cmName, Some(VirtualAPIsPage_CustomMetadata(appKey, cmId, configurationId = configurationId))))
                                case _ =>
                                  None
                              }
                              .map(render),
                          ))
                          case _ => None
                        },
                    ),
                  ),
                  // icons
                  div(
                    cls := "slds-grid slds-grid--vertical-align-center",
                    // provider metadata button in related mappings

                    child.maybe <-- $isActive.map {
                      case true =>
                        Some(material.Icon(
                          _ => cls <-- providerMetadata.signal
                            .map(_.isEmpty)
                            .map {
                              case true => "red slds-m-left_large clickable medium mat-outlined"
                              case _ => "light slds-m-left_large clickable medium mat-outlined"
                            },
                          _ => "edit",
                          _ => composeEvents(onClick.stopPropagation)(_
                            .sample(configuration)
                            .filter(_.isDefined)
                            .map(_.get))
                            --> configurationDialog.writer,
                        ))
                      case _ =>
                        None
                    },
                    // virtual metadata button in related mappings
                    div(
                      cls := "slds-grid slds-grid--vertical-align-center",

                      child.maybe <-- $isVirtualMetadata.map {
                        case true => Some(material.Icon(
                          _ => cls := "red light slds-m-left_large clickable medium mat-outlined",
                          _ => "delete_outline",
                          _ => composeEvents(onClick.stopPropagation)(_
                            .sample($configuration)
                            .map(c => confirmationDialog.mkHandle[ConfigurationModels.Configuration](
                              title = "Delete configuration",
                              message = s"Do you want to remove the ${c.name.getOrElse[String]("")} configuration?",
                              model = c,
                              onSuccess = onDeleteConfiguration.writer,
                            )))
                            --> confirmationDialog.writer[ConfigurationModels.Configuration],
                        ))
                        case _ => None
                      },

                      child.maybe <-- $isVirtualMetadata.map {
                        case true => Some(
                          material.Icon(
                            _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                            _ => child.text <-- $isActive.map {
                              case true => "close"
                              case _ => "chevron_right"
                            },
                            _ => composeEvents(onClick.stopPropagation)(_
                              .sample($configuration)
                              .withCurrentValueOf($isActive)
                              .map[Option[ConfigurationModels.Configuration]] {
                                case (c, false) => Some(c)
                                case _ => None
                              }) --> configuration.writer,
                          ),
                        )
                        case _ => None
                      },
                    )
                  )
                ),
              )
            },
            noRows = "No mappings",
            pageSize = Var(200), // TODO REVIEW: rethink
            documentScrollOps = documentScrollOps
          ),
          child.maybe <-- canCreate.map {
            case true => Some(material.Button(
              _ => cls := "nav-section_button slds-grid slds-grid--vertical-align-center blue",
              _.icon := "add",
              _.label := "New mapping",
              _ => composeEvents(onClick.stopPropagation)(_
                .sample($metadata.map(d => (d.flatMap(_.id), d.flatMap(_.name).getOrElse(""))))
                .map(d => ConfigurationModels.Configuration(
                  clientMetadataId = d._1,
                  clientMetadata = Option.when(d._1.isEmpty)(d._2),
                )))
                --> configurationDialog.writer,
            ))
            case _ => None
          },
        ),

        // make loading configurations (limit: 50)
        $metadata
          .withCurrentValueOf($appKey)
          .map(x => (x._1.flatMap(_.id), x._1.flatMap(_.name), x._1.flatMap(_.serviceType), x._1.map(_ => x._2)))
          .flatMap {
            case (mId, mName, Some(_), Some(appKey)) =>
              dynamicApi.configuration(appKey).items(
                pageLimit = 50,
                providerMetadata = mId.fold(mName)(_ => None),
                providerMetadataId = mId,
              ).map(_.records)
            case (mId, mName, None, Some(appKey)) =>
              dynamicApi.configuration(appKey).items(
                pageLimit = 50,
                clientMetadata = mId.fold(mName)(_ => None),
                clientMetadataId = mId,
              ).map(_.records)
            case _ =>
              EventStream.fromValue(Nil)
          }
          --> configurations.writer,
      )
    }

    def events: EventStream[Long] = configuration.signal
      .map(_.flatMap(_.id))
      .changes
      .map(_.getOrElse[Long](0))

    def writer: Observer[Long] = configurationIdBus.writer
  }

  /**
   * The byId method creates html page of the standard metadata
   */
  def byName($route: Signal[VirtualAPIsPage_StandardMetadata],
             portalApi: PortalApi,
             dynamicApi: DataMapperApi.API,
             documentScrollOps: ScrollOps,
             portalRouter: PortalRouter,
             portalState: PortalState,
            ): HtmlElement = {
    val model: Var[Option[MetadataModels.Standard]] = Var[Option[MetadataModels.Standard]](None)
    val accountHasError: Var[Boolean] = Var(false)

    val account: EventBus[Option[Option[(Int, String)]]] = new EventBus[Option[Option[(Int, String)]]]
    val onUpdateAccount: EventBus[Option[(Int, String)]] = new EventBus[Option[(Int, String)]]

    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_page.by_name",

      // UI
      ObjectsPage(
        portalApi,
        dynamicApi,
        $route.map(_.appKey),
        $route.map(_.configurationId.getOrElse[Long](0)),
        model.signal.map(_.map(_.convertToMetadata)),
        $account = account.events.toSignal(None).map(_.flatten),
        $accountHasError = accountHasError.signal,
        onChangeAccount = onUpdateAccount.writer,
        portalState = portalState,
        documentScrollOps = documentScrollOps,
        portalRouter = portalRouter
      ),

      // load standard metadata and default account
      child <-- $route
        .flatMap(route => for {
          m <- dynamicApi.metadata(route.appKey).standard.items(search = Some(route.metadataName)).map(_.records.find(_.name == route.metadataName))
          a <- m match {
            case Some(m) if m.requiresAuth.getOrElse(false) => portalApi.accounts(
                route.appKey,
                serviceType = m.serviceType,
                daemon = m.serviceType.exists(_.isDaemon),
                limit = 2,
                accountFilters = common.AccountFilters(hasValidToken = Var(Some(true))))
              .map(a => a.records.map(x => (x.id, x.name.getOrElse(""))))
              .map[Option[(Int, String)]](_.headOption)
              .map(Some(_))
            case Some(m) if !m.requiresAuth.getOrElse(true) => EventStream.fromValue(Some(None))
            case _ => EventStream.fromValue(None)
          }
        } yield (m, a, route))
        .map {
          case (defaultStandardMetadata, defaultAccount, route) =>
            if (defaultStandardMetadata.isDefined && route.metadataName != defaultStandardMetadata.get.name) {
              log.info(s"undefined ${route.metadataName} standard metadata")
              portalRouter.navigate(VirtualAPIsPage_ItemsOfProviderMetadata(route.appKey))
            }

            val accountsDialog = new components.dialogs.authorization.AccountList(
              route.appKey,
              portalApi,
              defaultStandardMetadata.flatMap(_.serviceType),
              account.writer.contramap((x: (Int, String)) => Some(Some(x))),
            )

            div(
              accountsDialog.node,

              onUpdateAccount.events --> accountsDialog.writer,

              // load standard metadata by account
              account.events
                .map(_.flatten.map(_._1))
                .filter(_.isDefined || !defaultStandardMetadata.flatMap(_.requiresAuth).getOrElse(true))
                .flatMap(x => dynamicApi.metadata(route.appKey).standard.item(route.metadataName, accountId = x)
                  .recoverToTry
                  .map {
                    case Success(x) =>
                      accountHasError.set(false)
                      Some(x)
                    case Failure(exception) =>
                      exception match {
                        case e: ApiException if x.isDefined && e.status == 400 =>
                          log.warn("wrong account id needs to re-authenticate")
                          accountHasError.set(true) // reauthorize if something went wrong
                          defaultStandardMetadata
                        case e: ApiException if x.isEmpty && e.status == 400 =>
                          log.warn("account id is required to load class list")
                          accountHasError.set(true)
                          portalRouter.navigate(VirtualAPIsPage_ItemsOfProviderMetadata(route.appKey))
                          None
                        case e =>
                          log.warn(e.getMessage)
                          throw e
                      }
                  })
                --> model.writer,

              onMountCallback(_ => {
                model.set(defaultStandardMetadata)
                accountHasError.set(defaultStandardMetadata.flatMap(_.requiresAuth).getOrElse(false) && defaultAccount.exists(_.isEmpty))
                account.emit(defaultAccount)
              })
            )
          case _ =>
            log.warn(s"undefined standard metadata")
            div($route.map(route => VirtualAPIsPage_ItemsOfVirtualMetadata(route.appKey)) --> portalRouter.goto)
        }
    )
  }

  /**
   * The byId method creates html page of the custom metadata
   */
  def byId($route: Signal[VirtualAPIsPage_CustomMetadata],
           portalApi: PortalApi,
           dynamicApi: DataMapperApi.API,
           documentScrollOps: ScrollOps,
           portalRouter: PortalRouter,
           portalState: PortalState,
          ): HtmlElement = {
    val model: Var[Option[MetadataModels.Metadata]] = Var[Option[MetadataModels.Metadata]](None)

    val onUpdateMetadata: EventBus[Option[MetadataModels.Metadata]] = new EventBus[Option[MetadataModels.Metadata]]
    val onCloneMetadata: EventBus[MetadataModels.Metadata] = new EventBus[MetadataModels.Metadata]
    val onDeleteMetadata: EventBus[MetadataModels.Metadata] = new EventBus[MetadataModels.Metadata]

    val wrongFields = Var[Seq[String]](Nil)
    val readOnlyModeSignal: Signal[Boolean] = wrongFields.signal.map[Boolean](_.nonEmpty)

    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_page.by_id",

      // update metadata
      onUpdateMetadata.events
        .withCurrentValueOf(readOnlyModeSignal)
        .filter(x => x._1.isDefined && !x._2)
        .map(_._1.get)
        .flatMap(d => helpers.cache.metadata.SaveModel($route.map(_.appKey), dynamicApi, d))
        .map(Some(_))
        --> model.writer,

      // remove metadata and close page
      onDeleteMetadata.events
        .withCurrentValueOf($route)
        .flatMap(x => dynamicApi.metadata(x._2.appKey).delete(x._2.metadataId) map (_ => (x._1.id, x._2)))
        .map(x => VirtualAPIsPage_ItemsOfProviderMetadata(x._2.appKey))
        --> portalRouter.goto,

      // clone metadata by id and open in new page
      onCloneMetadata.events
        .withCurrentValueOf($route)
        .flatMap(x => dynamicApi.metadata(x._2.appKey).clone(x._2.metadataId).map(d => (d, x._2)))
        .map(x => VirtualAPIsPage_CustomMetadata(x._2.appKey, x._1.id.getOrElse[Long](0), configurationId = x._2.configurationId))
        --> portalRouter.goto,

      // check metadata, if has a problem goto other page
      model.signal.changes
        .filter(_.isDefined)
        .map(_.get.id)
        .withCurrentValueOf($route)
        .filter(x => x._2.metadataId != x._1.getOrElse[Long](-1))
        .map(x => {
          log.info(s"undefined #${x._2.metadataId} metadata")
          x._1
            .map(VirtualAPIsPage_CustomMetadata(x._2.appKey, _, configurationId = x._2.configurationId))
            .getOrElse(VirtualAPIsPage_ItemsOfProviderMetadata(x._2.appKey))
        })
        --> portalRouter.goto,

      // UI
      ObjectsPage(
        portalApi,
        dynamicApi,
        $route.map(_.appKey),
        $route.map(_.configurationId.getOrElse[Long](0)),
        model.signal,
        readOnlyModeSignal,
        onUpdateMetadata = onUpdateMetadata.writer,
        onCloneMetadata = onCloneMetadata.writer,
        onDeleteMetadata = onDeleteMetadata.writer,
        portalState = portalState,
        documentScrollOps = documentScrollOps,
        portalRouter = portalRouter
      ),

      // load metadata by id
      $route
        .flatMap(_ => helpers.cache.metadata.LoadModel($route.map(_.appKey), $route.map(_.metadataId), dynamicApi))
        .recoverToTry
        .map {
          case Success(model) =>
            wrongFields.set(Nil)
            model
          case Failure(exception) =>
            exception match {
              case DataMapperApi.NotImplementedFields(fields, message, `object`: MetadataModels.Metadata) =>
                log.warn(s"incorrect fields, $message")
                wrongFields.set(fields)
                `object`
              case e => throw e
            }
        }
        .map(Some(_))
        --> model.writer,
    )
  }

  /**
   * The apply method creates html page of MetadataModels.Metadata
   */
  def apply(
             portalApi: PortalApi,
             dynamicApi: DataMapperApi.API,

             $appKey: Signal[common.AppKey],
             $defaultConfigurationId: Signal[Long],
             $metadata: Signal[Option[MetadataModels.Metadata]],

             $readOnlyMode: Signal[Boolean] = Signal.fromValue(true),

             onUpdateMetadata: Observer[Option[MetadataModels.Metadata]] = Observer.empty,
             onCloneMetadata: Observer[MetadataModels.Metadata] = Observer.empty,
             onDeleteMetadata: Observer[MetadataModels.Metadata] = Observer.empty,

             $account: Signal[Option[(Int, String)]] = Signal.fromValue(None),
             $accountHasError: Signal[Boolean] = Signal.fromValue(false),
             onChangeAccount: Observer[Option[(Int, String)]] = Observer.empty,
             portalState: PortalState,
             documentScrollOps: ScrollOps,
             portalRouter: PortalRouter
           ): HtmlElement = {
    val metadataDialog = new components.dialogs.metadata.Metadata(portalState, onUpdateMetadata.contramap((x: (String, MetadataModels.Metadata)) => Some(x._2.copy(data = None))))
    val metadataClassDialog = new components.dialogs.metadata.Class()
    val confirmationDialog = new components.dialogs.general.Confirmation(portalApi, portalState)
    val mappingClassDialog = new components.dialogs.mappingOld.Class()

    // configuration & mappings
    val relatedMappings = new RelatedMappingsSelector(
      portalApi,
      dynamicApi,
      $appKey,
      $metadata,
      canCreate = $metadata.map(_.exists(_.serviceType.isEmpty)),
      documentScrollOps = documentScrollOps,
      portalState = portalState,
      portalRouter = portalRouter
    )

    val onUpdateMappingClass = new EventBus[(String, Option[MappingModels.Class])]
    val gotoRouteByClassName = new EventBus[Option[String]]
    val searchText: Var[String] = Var("")

    div(
      common.ui.Attribute.Selector := "data_mapper.metadata_page",

      // dialogs
      metadataDialog.node,
      metadataClassDialog.node,
      mappingClassDialog.node,
      relatedMappings.dialogs,
      confirmationDialog.node,

      //   init mapping class dialog of provider metadata name
      relatedMappings.configuration.signal
        .map[String](_
          .flatMap(x => x.providerMetadataName
            .orElse(x.providerMetadata)
            .orElse(x.providerMetadataServiceType.map(_.label)))
          .getOrElse("-"))
        --> mappingClassDialog.providerName,

      // init metadata class dialog of class names
      $metadata
        .map(_.map(_.classes).getOrElse(Nil))
        --> metadataClassDialog
        .classes,

      // init configuration choice
      $defaultConfigurationId
        --> relatedMappings
        .writer,

      // init mapping class dialog of client classes
      $metadata
        .map(_.map(_.classes).getOrElse(Nil))
        --> mappingClassDialog
        .clientClasses,

      // init mapping class dialog of provider classes
      relatedMappings.providerMetadata.signal
        .map(_.map(_.classes).getOrElse(Nil))
        --> mappingClassDialog
        .providerClasses,

      // create/update/delete metadata class and open in new page
      metadataClassDialog.events
        .withCurrentValueOf($readOnlyMode)
        .withCurrentValueOf($metadata)
        .filter(x => !x._3 && x._4.isDefined)
        .map(x => (
          x._2,
          x._4.flatMap(_.classes.find(_.name == x._1)),
          x._4,
        ))
        .map[Option[(MetadataModels.Class, Option[MetadataModels.Class], String, Option[MetadataModels.Metadata])]] {
          case (newMetadataClass, oldMetadataClass, Some(metadata)) =>
            val name = oldMetadataClass.map(_.name).getOrElse(newMetadataClass.name)
            Some((
              newMetadataClass,
              oldMetadataClass,
              name,
              Some(helpers.extension.Merge.Metadata.classToMetadata(
                metadata,
                name,
                Some(newMetadataClass),
              )),
            ))
          case _ =>
            None
        }
        .flatMap {
          case Some((_, None, name, Some(metadata))) =>
            helpers.cache.metadata.SaveModel(
                $appKey,
                dynamicApi,
                metadata,
              )
              .mapTo[(Option[MetadataModels.Metadata], Option[String])]((None, Some(name)))
          case Some((_, Some(_), _, metadata)) if metadata.isDefined =>
            EventStream.fromValue[(Option[MetadataModels.Metadata], Option[String])]((metadata, None))
          case _ =>
            EventStream.fromValue[(Option[MetadataModels.Metadata], Option[String])]((None, None))
        }
        .filter(x => x._1.isDefined || x._2.isDefined)
        --> Observer
        .combine(
          gotoRouteByClassName.writer.contramap((x: (Option[MetadataModels.Metadata], Option[String])) => x._2),
          onUpdateMetadata.contramap((x: (Option[MetadataModels.Metadata], Option[String])) => x._1),
        ),

      // create/update/delete mapping class and open in new page
      mappingClassDialog.events
        .withCurrentValueOf(relatedMappings.$readOnlyMode)
        .withCurrentValueOf(relatedMappings.mapping.signal)
        .filter(_._3 == false)
        .map(x => (
          x._2,
          x._4.flatMap(_.classes.find(_.clientClass == x._1)),
          x._4,
        ))
        .flatMap {
          case (newMappingClass, oldMappingClass, Some(mapping)) =>
            val name = oldMappingClass.map(_.clientClass).getOrElse(newMappingClass.clientClass)
            val newMapping = helpers.extension.Merge.Mapping.classToMapping(
              mapping,
              name,
              Some(newMappingClass),
            )

            helpers.cache.mapping.SaveModel(
                $appKey,
                dynamicApi,
                newMapping,
              )
              .map(m => {
                relatedMappings.mapping.set(Some(m))
                m
              })
              .mapTo(Option.when(oldMappingClass.isEmpty)(name))
          case _ =>
            EventStream.fromValue(None)
        }
        --> gotoRouteByClassName
        .writer,

      // update mapping class
      onUpdateMappingClass.events
        .withCurrentValueOf(relatedMappings.$readOnlyMode)
        .filter(_._3 == false)
        .map(x => (x._2, x._1))
        .withCurrentValueOf(relatedMappings.mapping.signal)
        .map(x => (
          x._1,
          x._3.flatMap(_.classes.find(_.clientClass == x._2)),
          x._3,
        ))
        .map {
          case (newMappingClass, oldMappingClass, Some(mapping)) =>
            Some(helpers.extension.Merge.Mapping.classToMapping(
              mapping,
              oldMappingClass
                .map(_.clientClass)
                .getOrElse(newMappingClass.map(_.clientClass).getOrElse("")),
              newMappingClass,
            ))
          case _ =>
            None
        }
        .filter(_.isDefined)
        .map(_.get)
        --> relatedMappings
        .mappingUpdater
      ,

      // UI (left\right board)
      components.BoardComponent(
        name = Signal.fromValue(""),
        leftContext = div(
          // details
          VirtualExpansionPanelComponent(
            header = div(
              cls := "slds-grid slds-grid_vertical-align-center slds-grid_align-spread",
              p(
                cls := "slds-grid title--level-2 slds-m-bottom_small",
                child.text <-- $metadata.map(_.flatMap(_.name).getOrElse(""))
              ),
              child.maybe <-- $metadata.map(_.flatMap(d => d.id.flatMap {
                case x if x > 0 => Some(div(
                  cls := "slds-grid slds-grid_vertical-align-center slds-col_bump-left",
                  child.maybe <-- $readOnlyMode
                    .combineWith($metadata)
                    .map {
                      case (false, Some(m)) => metadataDialog.writer.contramap((_: Unit) => m).some
                      case _ => None
                    }
                    .map {
                      case Some(observer) if observer != Observer.empty => material.Icon(
                        _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                        _ => "edit",
                        _ => composeEvents(onClick.stopPropagation)(_.mapTo(())) --> observer
                      ).some
                      case _ => None
                    },
                  material.Icon(
                    _ => cls := "red light slds-m-left_large clickable medium mat-outlined",
                    // _ => marginRight := "24px",
                    _ => "delete_outline",
                    _ => onClick.stopPropagation
                      .mapTo(confirmationDialog.mkHandle[MetadataModels.Metadata](
                        title = "Delete metadata",
                        message = s"Do you want to remove the ${d.name.getOrElse[String]("")} metadata?",
                        model = d,
                        onSuccess = onDeleteMetadata,
                      )) --> confirmationDialog.writer[MetadataModels.Metadata],
                  ),
                  material.Icon(
                    _ => cls := "light slds-m-left_large clickable medium mat-outlined",
                    _ => "file_copy",
                    _ => onClick.stopPropagation
                      .mapTo(confirmationDialog.mkHandle[MetadataModels.Metadata](
                        title = "Cloning metadata",
                        message = s"Do you want to create copy of the ${d.name.getOrElse[String]("")} metadata?",
                        model = d,
                        onSuccess = onCloneMetadata,
                        primaryButtonText = "Create",
                        primaryButtonCls = "",
                      )) --> confirmationDialog.writer[MetadataModels.Metadata],
                  ),
                ))
                case _ => None
              }))
            ),
            body = $metadata
              .map(d => d.flatMap(_.id).isDefined || d.flatMap(_.serviceType).isDefined)
              .map {
                case true => div(
                  child.maybe <-- $metadata.map(_.flatMap(_.id.map(v => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Id"),
                    span(v),
                  )))),
                  child.maybe <-- $metadata.map(_.flatMap(_.serviceType.map(v => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Service type"),
                    span(v.label),
                  )))),
                  child.maybe <-- $metadata.map(_.flatMap(_.updatedAt.map(v => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Updated at"),
                    span(v.toPrettyLocalFormat),
                  )))),
                  child.maybe <-- $metadata.map(_.flatMap(_.createdAt.map(v => div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Created at"),
                    span(v.toPrettyLocalFormat),
                  )))),
                ).some
                case _ => None
              },
          ).node,
          // account
          child.maybe <-- $account
            .combineWith($accountHasError)
            .map {
              case (account, err) if account.isDefined || err => Some(div(
                // cls := "border-bottom--light",
                div(
                  cls := "slds-grid slds-grid--align-spread slds-grid--vertical-align-center slds-p-vertical--small",
                  p("Account", cls := "title--level-2"),
                  material.Icon(
                    _ => cls := s"medium clickable mat-outlined medium",
                    _ => cls <-- $accountHasError.map {
                      case true => "red"
                      case _ => "light"
                    },
                    _ => "edit",
                    _ => composeEvents(onClick.stopPropagation)(_.mapTo(account)) --> onChangeAccount,
                  )
                ),
                account.map {
                  case (accountId, accountName) if accountId > 0 => Some(div(
                    cls := "slds-grid slds-grid--vertical slds-m-vertical--medium",
                    small(cls := "gray", "Name"),
                    child <-- $appKey.map(appKey => a(
                      accountName,
                      href := portalRouter.link(AccountPage(appKey, accountId)),
                    ))
                  ))
                  case _ => None
                },
              ))
              case _ => None
            },
          // related mappings
          relatedMappings.node,
        ),
        rightContext = div(
          cls := "slds-grid slds-grid--vertical slds-m-bottom--xx-large",
          // metadata classes
          div(
            cls := "slds-col",
            child <-- $metadata
              .map(_.flatMap(_.data).getOrElse(MetadataModels.ClassList(classes = Nil)).classes)
              .combineWith(relatedMappings.mapping.signal.map(_.map(_.classes.map(_.clientClass))))
              .map {
                case (classes, Some(mClientClasses)) =>
                  mClientClasses
                    .filter(clientClass => !classes.exists(_.name == clientClass))
                    .map(clientClass => MetadataModels.Class(name = clientClass)) ++ classes
                case (classes, _) =>
                  classes
              }
              .map[String => EventStream[DataMapperApi.PageOf[MetadataModels.Class]]](classes => (txt: String) =>
                EventStream.fromValue(classes)
                  .map(_.filter(c => txt.isEmpty || c.name.toLowerCase.contains(txt) || c.pluralName.toLowerCase.contains(txt)))
                  .map(_.sortBy(_.name))
                  .map(items => DataMapperApi.PageOf[MetadataModels.Class](records = items)))
              .map(loadStream => components.TableComponent[MetadataModels.Class, String](
                searchText = searchText,
                load = (_, _, txt: String) => loadStream(txt.toLowerCase),
                key = row => row.name,
                render = (_, $row) => {
                  val $mappingClass: Signal[Option[MappingModels.Class]] = relatedMappings.mapping.signal
                    .combineWith($row.map(_.name))
                    .map(x => x._1.flatMap(_.classes.find(_.clientClass == x._2)))

                  a(
                    cls := "table-row clickable brown slds-border_bottom",
                    composeEvents(onClick)(_
                      .sample($row.map(_.name))
                      .withCurrentValueOf($metadata.map(x => (x.flatMap(_.id), x.flatMap(_.name))))
                      .withCurrentValueOf($appKey)
                      .withCurrentValueOf(relatedMappings.configuration.signal.map(_.flatMap(_.id)))
                      .map[Option[portal_router.Page]] {
                        case (className, Some(metadataId), _, appKey, cId) =>
                          Some(VirtualAPIsPage_ClassOfCustomMetadata(appKey, metadataId, className, configurationId = cId))
                        case (className, None, Some(metadataName), appKey, cId) =>
                          Some(VirtualAPIsPage_ClassOfStandardMetadata(appKey, metadataName, className, configurationId = cId))
                        case _ =>
                          None
                      }
                      .filter(_.isDefined)
                      .map(_.get))
                      --> portalRouter.goto,

                    div(
                      cls := "au-truncate",
                      cls <-- relatedMappings.mapping.signal
                        .map(_.isDefined)
                        .map {
                          case true => "slds-size--5-of-12"
                          case _ => "slds-size--8-of-12"
                        },
                      cls <-- $metadata
                        .map(_.map(_.classes.map(_.name)))
                        .combineWith($row.map(_.name))
                        .map(x => !x._1.exists(_.contains(x._2)))
                        .map {
                          case true => "red"
                          case _ => ""
                        },
                      child.text <-- $row.map(_.name),
                    ),
                    div(
                      cls := "slds-grid slds-grid--vertical-align-center",
                      cls <-- relatedMappings.mapping.signal
                        .map(_.isDefined)
                        .map {
                          case true => "slds-size--7-of-12 "
                          case _ => "slds-size--4-of-12 "
                        },

                      div(
                        cls := "slds-m-right--medium",
                        maxWidth := "7.4rem",
                        width := "100%",
                        child <-- relatedMappings.mapping.signal
                          .map(_.isDefined)
                          .combineWith($mappingClass)
                          .map {
                            case (true, Some(mappingClass)) => div(
                              cls := "slds-grid slds-grid--vertical-align-center",
                              width := "100%",
                              div(cls := "line"),
                              span(cls := "mapping-button connect", "Connect"),
                              div(cls := "line"),
                            )

                            case (true, _) => div(
                              cls := "slds-grid slds-grid--vertical-align-center",
                              cursor := "pointer",
                              width := "100%",
                              div(cls := "line"),
                              span(cls := "mapping-button", "Map"),
                              div(cls := "line disconnect"),
                              composeEvents(onClick.stopPropagation)(_
                                .sample($row.map(_.name))
                                .map(c => MappingModels.Class(clientClass = c)))
                                --> mappingClassDialog.writer,
                            )
                            case _ => div(cls := "slds-col")
                          }),

                      child <-- relatedMappings.mapping.signal
                        .map(_.isDefined)
                        .combineWith($mappingClass)
                        .map {
                          case (true, Some(mappingClass)) => div(
                            cls := "slds-col au-truncate slds-grid slds-grid--vertical-align-center",
                            mappingClass.providerClass
                              .map(pClassName => (pClassName, pClassName.split(":").headOption.getOrElse(pClassName)))
                              .map {
                                case (pOriginClassName, pClassName) => span(
                                  cls := "slds-col au-truncate slds-m-right--small",
                                  cls <-- relatedMappings.providerMetadata.signal
                                    .map(!_.exists(_.classes.exists(_.name == pClassName)))
                                    .map {
                                      case true => "red"
                                      case _ => ""
                                    },
                                  pOriginClassName,
                                )
                              },
                            mappingClass.virtual
                              .flatMap {
                                case virtual if virtual => Some(span(
                                  cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-right--small",
                                  small(cls := "badge", "virtual"),
                                ))
                                case _ => None
                              },
                            mappingClass.comment
                              .flatMap {
                                case comment if comment.nonEmpty => Some(span(
                                  cls := "slds-col slds-grid slds-grid--vertical-align-center slds-m-right--small",
                                  small(cls := "badge", "comment"),
                                ))
                                case _ => None
                              },
                          )
                          case _ => div(cls := "slds-col")
                        },
                    ),
                  )
                },
                header = div(
                  cls := "table-header slds-border_bottom",
                  div(
                    cls := "gray au-truncate",
                    cls <-- relatedMappings.mapping.signal
                      .map(_.isDefined)
                      .map {
                        case true => "slds-size--5-of-12"
                        case _ => "slds-size--8-of-10"
                      },
                    child.text <-- relatedMappings.mapping.signal
                      .map(_.isDefined)
                      .combineWith(relatedMappings.configuration.signal.map(c => (
                        c.flatMap(_.clientMetadataId),
                        c.flatMap(_.clientMetadataName),
                        c.flatMap(_.clientMetadata),
                      )))
                      .map {
                        case (true, Some(clientMetadataId), Some(clientMetadataName), _) if clientMetadataId > 0 =>
                          s"$clientMetadataName object name"
                        case (true, None, _, Some(clientMetadata)) if clientMetadata.nonEmpty =>
                          s"$clientMetadata object name"
                        case (true, None, _, Some(clientMetadata)) if clientMetadata.nonEmpty =>
                          "Virtual object name"
                        case _ =>
                          "Name"
                      },
                  ),
                  div(
                    cls := "slds-grid slds-grid--vertical-align-center",
                    cls <-- relatedMappings.mapping.signal
                      .map(_.isDefined)
                      .map {
                        case true => "slds-size--7-of-12 "
                        case _ => "slds-size--4-of-12 "
                      },

                    span(cls := "slds-m-right--medium", maxWidth := "7.4rem", width := "100%"),
                    child.maybe <-- relatedMappings.mapping.signal
                      .map(_.isDefined)
                      .map {
                        case true => Some(material.Icon(
                          _ => cls := "slds-m-right--small",
                          _ => styleAttr := "transform: rotate(90deg);  color: var(--mat-grey-500);",
                          _ => "merge",
                        ))
                        case _ => None
                      },
                    child <-- relatedMappings.mapping.signal
                      .map(_.isDefined)
                      .combineWith(relatedMappings.configuration.signal.map(c => (
                        c.flatMap(_.providerMetadataId),
                        c.flatMap(_.providerMetadataName),
                        c.flatMap(_.providerMetadata),
                      )))
                      .map {
                        case (true, Some(providerMetadataId), Some(providerMetadataName), _) if providerMetadataId > 0 =>
                          span(
                            cls := "slds-col au-truncate slds-grid slds-grid--vertical-align-center",
                            s"$providerMetadataName object name",
                          )
                        case (true, None, _, Some(providerMetadata)) if providerMetadata.nonEmpty =>
                          span(
                            cls := "slds-col au-truncate slds-grid slds-grid--vertical-align-center",
                            s"$providerMetadata object name",
                          )
                        case (true, _, _, _) =>
                          span(
                            cls := "slds-col au-truncate slds-grid slds-grid--vertical-align-center",
                            "Provider object name",
                          )
                        case _ => div(cls := "slds-col")
                      },
                    div(cls := "slds-col"),
                  ),
                ),
                noRows = "No objects",
                caption = relatedMappings.mapping.signal
                  .map(_.exists(_.id.isDefined))
                  .map[HtmlElement] {
                    case true => span(
                      cls := "slds-grid slds-grid--vertical-align-center",
                      "Objects",
                      child.maybe <-- relatedMappings.providerMetadata.signal
                        .combineWith(relatedMappings.configuration.signal
                          .map(_
                            .flatMap(x => x.providerMetadataName
                              .orElse(x.providerMetadata)
                              .orElse(x.providerMetadataServiceType.map(_.label)))
                            .getOrElse("-")))
                        .map(x => (x._1.flatMap(_.id), x._1.flatMap(_.name).getOrElse(x._2)))
                        .combineWith($defaultConfigurationId.map(x => Option.when(x > 0)(x)))
                        .withCurrentValueOf($appKey)
                        .map[Option[(String, portal_router.Page)]] {
                          case (Some(providerMetadataId), providerMetadataName, configurationId, appKey) if providerMetadataId > 0 => Some((
                            providerMetadataName,
                            VirtualAPIsPage_CustomMetadata(appKey, providerMetadataId, configurationId = configurationId),
                          ))
                          case (_, providerMetadataName, configurationId, appKey) => Some((
                            providerMetadataName,
                            VirtualAPIsPage_StandardMetadata(appKey, providerMetadataName, configurationId = configurationId),
                          ))
                          case _ =>
                            None
                        }
                        .map {
                          case Some((label, page)) => Some(a(
                            cls := "clickable blue",
                            label,
                            href := portalRouter.link(page),
                          ))
                          case _ => None
                        }
                        .map(_.map(label => small(cls := "slds-m-horizontal--x-small", s" [", label, " mapping]"))),
                    )
                    case _ => span("Objects")
                  }
                  .map[Option[HtmlElement]](Some(_)),
                documentScrollOps = documentScrollOps
              )),
          ),
        ),
        documentScrollOps = documentScrollOps,

        navigation = $metadata
          .map(d => (d.flatMap(_.name), d.flatMap(_.serviceType)))
          .combineWith($appKey)
          .map {
            case (Some(mName), mServiceType, appKey) => List(
              mServiceType.fold[BreadcrumbsItem]
                (BreadcrumbsItem("Virtual models".signaled, Some(portalRouter.link(VirtualAPIsPage_ItemsOfVirtualMetadata(appKey)).signaled)))
                (_ => BreadcrumbsItem("Provider models".signaled, Some(portalRouter.link(VirtualAPIsPage_ItemsOfProviderMetadata(appKey)).signaled))),
              BreadcrumbsItem(mName.signaled, None),
            )
            case _ => Nil
          },

        //        onEdit = $readOnlyMode
        //          .combineWith($metadata)
        //          .map {
        //            case (false, Some(m)) => Some(metadataDialog.writer.contramap(_ => m))
        //            case _ => None
        //          },
        hasSearch = true,
        searchText = searchText,
        searchDelay = 100,
        buttons = $readOnlyMode
          .combineWith(relatedMappings.mapping.signal.map(_.flatMap(_.id).exists(_ > 0)))
          .map {
            case (false, false) =>
              ("Add object", metadataClassDialog.writer.contramap((_: Unit) => MetadataModels.Class()), false) ::
                Nil

            case _ =>
              Nil
          },
        portalRouter = portalRouter
      ),

      // open this page with new configuration id
      relatedMappings.events
        .map[Option[Long]](x => Option.when(x > 0)(x))
        .withCurrentValueOf($metadata.map(x => (x.flatMap(_.id), x.flatMap(_.name))))
        .withCurrentValueOf($appKey)
        .map[Option[portal_router.Page]] {
          case (configurationId, Some(metadataId), _, appKey) if metadataId > 0 =>
            Some(VirtualAPIsPage_CustomMetadata(appKey, metadataId, configurationId = configurationId))
          case (configurationId, None, Some(metadataName), appKey) if metadataName.nonEmpty =>
            Some(VirtualAPIsPage_StandardMetadata(appKey, metadataName, configurationId = configurationId))
          case _ => None
        }
        --> portalRouter
        .gotoSome,

      // open class
      gotoRouteByClassName.events
        .filter(_.isDefined)
        .map(_.get)
        .withCurrentValueOf($metadata.map(x => (x.flatMap(_.id), x.flatMap(_.name))))
        .withCurrentValueOf($defaultConfigurationId.map(x => Option.when(x > 0)(x)))
        .withCurrentValueOf($appKey)
        .map[portal_router.Page] {
          case (className, Some(metadataId), _, configurationId, appKey) =>
            VirtualAPIsPage_ClassOfCustomMetadata(appKey, metadataId, className, configurationId = configurationId)
          case (className, None, Some(metadataName), configurationId, appKey) =>
            VirtualAPIsPage_ClassOfStandardMetadata(appKey, metadataName, className, configurationId = configurationId)
          case (_, _, _, _, appKey) =>
            VirtualAPIsPage_ItemsOfVirtualMetadata(appKey)
        }
        --> portalRouter
        .goto
      ,
    )
  }
}
