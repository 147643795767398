package service.aurinko_api

import common.JsonEnum
import service.aurinko_api.AurinkoModels.DayOfWeek.DayOfWeek

case object AurinkoModels {

  case class Book(
                 name: String,
                 availabilityStep: Int,
                 durationMinutes: Int,
                 timeAvailableFor: String,
                 subject: String,
                 description: String,
                 location: Option[String] = None,
                 workHours: WeekWorkSchedule,
                 context: Option[String] = None,
                 startConference: Boolean
                 )

  case class WeekWorkSchedule(
                             daySchedules: List[DayWorkSchedule],
                             timezone: String
                             )

  case class DayWorkSchedule(
                            dayOfWeek: DayOfWeek,
                            workingIntervals: List[WorkingInterval]
                            )

  case class WorkingInterval(
                            //time string, allowed format: "10:00:00"
                            start: String,
                            end: String
                            )

  object DayOfWeek extends Enumeration with JsonEnum {
    type DayOfWeek = Value
    type EnumValue = Value

    val monday, tuesday, wednesday, thursday, friday, saturday, sunday = Value

    val workDays: List[Value] = monday :: tuesday :: wednesday :: thursday :: friday :: Nil
  }
}
