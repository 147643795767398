package common.ui

import com.github.uosis.laminar.webcomponents.material.Icon.El
import com.github.uosis.laminar.webcomponents.material.Menu
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveHtmlElement
import common.airstream_ops.{SignalNestedOps, ValueToObservableOps}
import common.ui.icons.{IconColor, IconComponent, IconSize, IconType, MaterialIcons}
import org.scalajs.dom
import org.scalajs.dom.html.Paragraph
import org.scalajs.dom.raw.SVGTextElement
import org.scalajs.dom.{document, html}
import wvlet.log.Logger

package object notifications {

  private val log = Logger("common.ui.notifications")

  def SuperscriptWarningIcon(titleText: String): El = IconComponent(
    icon = MaterialIcons.exclPoint,
    color = IconColor.red,
    iconType = IconType.outlined,
    size = IconSize.`x-small`
  ).amend(title := titleText, cls := "slds-m-right--xxx-small slds-m-bottom--small")

//  def LocalErrorMessagesView($error: Signal[Option[String]]): ReactiveHtmlElement[html.Div] = div(
//    cls := "local-error-message",
//    cls <-- $error.map { case None => "" case Some(_) => "visible slds-m-top--small" },
//    inContext(animateVisibilityModifier($error.map(_.isDefined))),
//
//    lineHeight := "2.5rem", // fixed height to prevent height jumping
//    child.text <-- $error.map(_.getOrElse("field for message"))
//  ).
//    amendThis(th => $error.signal.map(_.isDefined) --> Observer[Boolean](er => if (er) th.ref.scrollIntoView()))

  def LocalMessagesView($message: Signal[Option[String]], $messageType: Signal[Option[String]] = Signal.fromValue(None), shouldScroll: Boolean = true): ReactiveHtmlElement[html.Div] = div(
    cls := "messages",
    cls <-- $messageType.map {
      case Some(msg) if (msg.contains("message")) => "local-message"
      case _ => "local-error-message"
    },
    cls <-- $message.map { case None => "" case Some(_) => "visible" },
    inContext(animateVisibilityModifier($message.map(_.isDefined))),

    lineHeight := "2.5rem", // fixed height to prevent height jumping
    child.text <-- $message.map(_.getOrElse("field for message"))
  ).
    amendThis(th => if (shouldScroll) $message.signal.map(_.isDefined) --> Observer[Boolean](er => if (er) th.ref.scrollIntoView())
    else None
    )

  object InfoTextComponent {


    def apply(textElement: ReactiveHtmlElement[dom.html.Element]): ReactiveHtmlElement[html.Div] = {
      val show: Var[Boolean] = Var(false)
      div(
        cls := "slds-is-relative slds-grid",
        IconComponent(
          icon = MaterialIcons.info,
          iconType = IconType.outlined,
          size = IconSize.small,
          color = IconColor.grey
        ).amend(
          cls := "clickable",
          onClick.mapTo(true) --> show
        ),
        inContext(parent => Menu(
          _ => cls := "info-popup",
          _.open <-- show,
          _.onClosed.mapTo(false) --> show,
          _ => top := "2em",

          _.slots.default(textElement),

          _ => onMountCallback(c => c.thisNode.amend(left := (
            if (parent
              .ref
              .getBoundingClientRect().left > (document.documentElement.clientWidth / 2)
            ) "calc (0 - var(--au-info-popup-width))"

            else "0"

            )))

        )))
    }

    def onlyTitle($text: Signal[String]) = IconComponent(
      icon = MaterialIcons.info,
      iconType = IconType.outlined,
      size = IconSize.small,
      color = IconColor.grey
    ).amend(title <-- $text, cursor := "default")

    def standard($text: Signal[String],
                 $docsLink: Signal[Option[String]] = None.signaled): ReactiveHtmlElement[html.Div] = apply(p(
      cls := "popup-content slds-p-around--medium",
      child.text <-- $text,
      child.maybe <-- $docsLink.nestedMap(link => a(
        " See the docs.",
        href := link,
        target := "_blank"
      ))
    ))

  }
}
