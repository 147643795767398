package root_pages.aurinko_pages.app.settings

import cats.implicits.catsSyntaxOptionId
import com.github.uosis.laminar.webcomponents.material.Formfield.El
import com.github.uosis.laminar.webcomponents.material._
import com.raquo.airstream.eventbus.EventBus
import com.raquo.laminar.api.L._
import com.raquo.laminar.api.eventPropToProcessor
import common.PortalAppCapability
import common._
import common.airstream_ops.EventStreamOps
import common.ui.mat_components_styles.MountContextOpps
import common.value_opps.ValueOpps
import org.scalajs.dom
import service.apis.portal_api.PortalApi
import service.portal_state.PortalState
import wvlet.log.Logger

case class ApplicationCapabilities(
                                    portalApi: PortalApi,
                                    portalState: PortalState,
                                    bus: EventBus[Unit]
                                  ) {

  val cancelBus: EventBus[dom.MouseEvent] = new EventBus[dom.MouseEvent]
  val saveBus: EventBus[dom.MouseEvent] = new EventBus[dom.MouseEvent]

  private val log = Logger.of[ApplicationCapabilities]

  case class CapabilityComponent(capability: PortalAppCapability, capabilities: Var[Set[PortalAppCapability]]) {
    val checkbox: Checkbox.El = Checkbox(

      _.checked <-- capabilities.signal.map(_.contains(capability)),

      _.disabled <-- (capability match {
        case PortalAppCapability.apiMailbox => capabilities.signal.map(_.contains(PortalAppCapability.scheduler))
        case _ => Signal.fromValue(false)
      }),

      _.onChange.mapToChecked.map {
        case true => capabilities.now() + capability
        case _ => capabilities.now() - capability
      } --> capabilities,
      _ => onMountCallback(ctx => ctx.addStyleToNestedShadowElement("mwc-ripple", ".mdc-ripple-surface", "display", "none"))
    )

    val node: El = Formfield(
      _ => cls := "",
      _.label := capability.label,

      _.slots.default(checkbox)
    )
  }


  val node: Div = div(
    child <-- portalState.$app.map(_.capabilities.getOrElse(Set())).map { apiList =>
      val capabilities: Var[Set[PortalAppCapability]] = Var(apiList)

      div(
        cls := "",

        capabilities.signal.changes
          .sample(portalState.$teamApp.combineWith(capabilities.signal))
          .flatMap {
            case (teamApp, caps) =>
              portalApi.updateAppSettings(
                teamApp.appKey,
                teamApp.app.copy(
                  capabilities = caps.condition(
                    _.contains(PortalAppCapability.scheduler),
                    caps => caps + PortalAppCapability.apiMailbox,
                    caps => caps
                  ).some
                )
              )
                .flatMap {
                  _ =>
                    portalApi.application(teamApp.appKey)
                }
          }
          .tap {
            app => portalState.updateApp(app.some)
          }
          --> bus.writer.contramap((_: PortalApplication) => ()),

        children <-- portalState.$capabilities
          .map {_.sortBy(_.weight)
              .map(CapabilityComponent(_, capabilities).node)
          },
      )
    }
  )
}
